import { jsx as p } from "react/jsx-runtime";
import { useMergeRefs as i } from "@floating-ui/react";
import { forwardRef as m, isValidElement as a, cloneElement as c } from "react";
import { useTooltipContext as g } from "./tooltipContext.js";
const d = m(function({ children: e, asChild: n = !1, ...t }, s) {
  const o = g(), f = e.ref, r = i([o.refs.setReference, s, f]);
  return n && a(e) ? c(
    e,
    o.getReferenceProps({
      ref: r,
      ...t,
      ...e.props,
      "data-state": o.open ? "open" : "closed"
    })
  ) : /* @__PURE__ */ p(
    "button",
    {
      ref: r,
      "data-state": o.open ? "open" : "closed",
      ...o.getReferenceProps(t),
      children: e
    }
  );
});
export {
  d as TooltipTrigger
};
