import { Typography } from '@analytical-alley/ui';
import { IconArrowDown, IconArrowUp, IconMinus } from '@tabler/icons-react';
import React from 'react';

export const PercentWidget = ({
    spendVsContributionPercent,
    spendPercent,
    contributionPercent,
}: {
    spendVsContributionPercent: number;
    spendPercent: number;
    contributionPercent: number;
}) => {
    if (spendVsContributionPercent > 0) {
        return (
            <div className="font-mono bg-slate-400 bg-opacity-30 shadow backdrop-blur-[30px] rounded-lg flex flex-col items-center p-6">
                <div className="flex flex-row items-center mb-1">
                    <IconArrowUp size={36} color="#3BE98B" />
                    <Typography
                        className="text-green-400 dark:text-green-400"
                        variant="titleXL"
                    >
                        {Math.abs(spendVsContributionPercent)}%
                    </Typography>
                </div>
                <div className="flex flex-row gap-4">
                    <div className="flex flex-col">
                        <Typography variant="bodyXS">Contribution</Typography>
                        <Typography
                            className="text-green-400 dark:text-green-400"
                            variant="bodyM"
                        >
                            {contributionPercent}%
                        </Typography>
                    </div>
                    <div className="flex flex-col">
                        <Typography variant="bodyXS">Spend</Typography>
                        <Typography
                            className="text-green-400 dark:text-green-400"
                            variant="bodyM"
                        >
                            {spendPercent}%
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }

    if (spendVsContributionPercent < 0) {
        return (
            <div className="font-mono bg-slate-400 bg-opacity-30 shadow backdrop-blur-[30px] rounded-lg flex flex-col items-center p-6">
                <div className="flex flex-row items-center mb-1">
                    <IconArrowDown size={36} color="#FF3766" />
                    <Typography
                        className="text-rose-500 dark:text-rose-500"
                        variant="titleXL"
                    >
                        {Math.abs(spendVsContributionPercent)}%
                    </Typography>
                </div>
                <div className="flex flex-row gap-4">
                    <div className="flex flex-col">
                        <Typography variant="bodyXS">Contribution</Typography>
                        <Typography
                            className="text-rose-500 dark:text-rose-500"
                            variant="bodyM"
                        >
                            {contributionPercent}%
                        </Typography>
                    </div>
                    <div className="flex flex-col">
                        <Typography variant="bodyXS">Spend</Typography>
                        <Typography
                            className="text-rose-500 dark:text-rose-500"
                            variant="bodyM"
                        >
                            {spendPercent}%
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="font-mono bg-slate-400 bg-opacity-30 shadow backdrop-blur-[30px] rounded-lg flex flex-col items-center p-6">
            <div className="flex flex-row items-center mb-1">
                <IconMinus size={36} color="#73EFEA" />
                <Typography
                    className="text-cyan-300 dark:text-cyan-300"
                    variant="titleXL"
                >
                    {Math.abs(spendVsContributionPercent)}%
                </Typography>
            </div>
            <div className="flex flex-row gap-4">
                <div className="flex flex-col">
                    <Typography variant="bodyXS">Contribution</Typography>
                    <Typography
                        className="text-cyan-300 dark:text-cyan-300"
                        variant="bodyM"
                    >
                        {contributionPercent}%
                    </Typography>
                </div>
                <div className="flex flex-col">
                    <Typography variant="bodyXS">Spend</Typography>
                    <Typography
                        className="text-cyan-300 dark:text-cyan-300"
                        variant="bodyM"
                    >
                        {spendPercent}%
                    </Typography>
                </div>
            </div>
        </div>
    );
};
