import { jsxs as a, Fragment as N, jsx as e } from "react/jsx-runtime";
import { HelperText as g } from "flowbite-react";
import { forwardRef as x } from "react";
import { t as r } from "../../../bundle-mjs-3rKE4aij.js";
import { textInputTheme as v } from "./theme.js";
const w = x(
  ({
    addon: t,
    className: l,
    color: d = "gray",
    helperText: o,
    icon: s,
    rightIcon: n,
    shadow: f,
    sizing: m = "md",
    type: c = "text",
    compact: p,
    ...h
  }, u) => {
    const i = v;
    return /* @__PURE__ */ a(N, { children: [
      /* @__PURE__ */ a("div", { className: r(i.base, l), children: [
        t && /* @__PURE__ */ e("span", { className: i.addon, children: t }),
        /* @__PURE__ */ a("div", { className: i.field.base, children: [
          s && /* @__PURE__ */ e("div", { className: i.field.icon.base, children: /* @__PURE__ */ e(s, { className: i.field.icon.svg }) }),
          n && /* @__PURE__ */ e(
            "div",
            {
              "data-testid": "right-icon",
              className: i.field.rightIcon.base,
              children: /* @__PURE__ */ e(
                n,
                {
                  className: i.field.rightIcon.svg
                }
              )
            }
          ),
          /* @__PURE__ */ e(
            "input",
            {
              className: r(
                i.field.input.base,
                i.field.input.colors[d],
                i.field.input.sizes[m],
                i.field.input.withIcon[s ? "on" : "off"],
                i.field.input.withRightIcon[n ? "on" : "off"],
                i.field.input.withAddon[t ? "on" : "off"],
                i.field.input.withShadow[f ? "on" : "off"],
                p ? "h-9 text-input" : void 0
              ),
              type: c,
              ...h,
              ref: u
            }
          )
        ] })
      ] }),
      o && /* @__PURE__ */ e(g, { color: d, children: o })
    ] });
  }
);
w.displayName = "TextInput";
export {
  w as TextInput
};
