import type { DBTable } from '@analytical-alley/duckdb-react/types';
import { z } from 'zod';

export const datasheetVariablesSchema = z.object({
    'Period name': z.string(),
    Market: z.string(),
    Variable: z.string(),
    Category: z.string(),
    Product: z.string(),
    Contribution: z.number(),
    Spend: z.number(),
    Cost: z.number(),
    Actual: z.number(),
    Raw: z.number(),
    Transformed: z.number(),
});

export const datasheetVariablesColumnsMap = {
    'Period name': 'period',
    Market: 'market',
    Variable: 'variable',
    Category: 'category',
    Product: 'product',
    Contribution: 'contribution',
    Spend: 'spend',
    Cost: 'cost',
    Actual: 'actual',
    Raw: 'raw',
    Transformed: 'transformed',
} as const;

type SchemaType = z.infer<typeof datasheetVariablesSchema>;

export type TDatasheetVariablesTableRow = DBTable<
    SchemaType,
    typeof datasheetVariablesColumnsMap
>;
