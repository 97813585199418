import { BudgetShareByCategory } from '@/dashboards/BudgetOptimization/components/BudgetShareByCategory';
import React from 'react';
import { BudgetChangeByVariable } from './BudgetChangeByVariable';
import { ContributionSummary } from './ContributionSummary';
import { ModifyBudget } from './ModifyBudget';
import { MonthlyMediaInvestment } from './MonthlyMediaInvestment';
import { ROICurves } from './ROICurves';
import { SuggestedBudgetTable } from './SuggestedBudgetTable';

export const BudgetOptimizationSection = () => {
    return (
        <div className="flex flex-wrap w-full max-2xl:gap-4">
            <div className="flex flex-col w-1/2 max-2xl:w-full max-2xl:pr-0 gap-4 pr-2">
                <ContributionSummary />
                <SuggestedBudgetTable />
                <ROICurves />
                <MonthlyMediaInvestment />
            </div>
            <div className="flex flex-col w-1/2 max-2xl:w-full max-2xl:pr-0 gap-4 pl-2">
                <ModifyBudget />
                <div className="glass-tile p-6 flex flex-col gap-4">
                    <BudgetShareByCategory />
                    <BudgetChangeByVariable />
                </div>
            </div>
        </div>
    );
};
