import { Typography } from '@analytical-alley/ui';
import React from 'react';

export type MarkPointData = { coord: [number, number]; name: string };
export const MarkPointTooltip = ({
    name,
    data,
    formatter,
}: {
    name: string;
    data: MarkPointData;
    formatter?: (value: string | undefined | number) => string;
}) => {
    return (
        <div className="tooltip-content-md">
            <Typography className="text-dark dark:text-dark">
                {name} -{' '}
                {data.name === 'simulated' ? 'Recommended' : 'Previous value'}
            </Typography>
            <table className="font-sans">
                <tbody>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Investment:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {formatter
                                    ? formatter(data.coord[0])
                                    : data.coord[0]}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Contribution:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {formatter
                                    ? formatter(data.coord[1])
                                    : data.coord[1]}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
