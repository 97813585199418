import { dashboardApi } from '@/api';
import { PeriodOption } from '@/config/model';
import { FeatureFlagWithStatus } from '@/features/Casl';
import { queryOptions } from '@tanstack/react-query';

interface ProjectInfoQueryArgs {
    projectId: string;
    dashboardId: string | undefined;
}

interface ProjectUser {
    displayName: string;
    email: string;
}

interface DashboardConfigDto {
    id: string;

    dashboardVariables: ProjectConfigDashboardVariableDto[];

    dataPeriodMaxDate: string;

    dataPeriodMinDate: string;

    yearlyBudgetOptimisationPeriod: string;

    featureFlags: FeatureFlagWithStatus[];
}

export enum MegastarFileType {
    input = 'input',
    output = 'output',
}

interface ProjectConfigDashboardVariableDto {
    variableName: string;
    fileType: MegastarFileType;
}

export type RoiType =
    | 'RETURN_RATIO'
    | 'TURNOVER_RATIO'
    | 'REVENUE_RATIO'
    | 'UNITS_SOLD'
    | 'UNITS_SOLD/1000'
    | 'UNITS_SOLD/100'
    | 'COST_PER_UNIT';

export interface ProjectDetailsDto {
    id: string;
    name: string;
    startDate: string;
    kpiDefinition: string;
    projectInfo: string;
    interval: PeriodOption;
    roiType: RoiType;
    frequency: string;
    status: string;
    dataScientist: ProjectUser;
    projectLead: ProjectUser;
}

export type ProjectInfoResponse = {
    project: ProjectDetailsDto;
    dashboard: DashboardConfigDto;
};

const getProjectInfo = async ({
    projectId,
    dashboardId,
}: ProjectInfoQueryArgs) => {
    return dashboardApi<ProjectInfoResponse>({
        method: 'GET',
        url: `/front-office/project/${projectId}`,
        params: {
            dashboardId,
        },
    });
};

export const getProjectInfoQueryOptions = (args: ProjectInfoQueryArgs) =>
    queryOptions({
        queryKey: ['project-info', args],
        queryFn: () => getProjectInfo(args),
        staleTime: Infinity,
    });
