import { DeepPartial } from '@/types';
import {
    Datepicker as UIDatepicker,
    type DatepickerProps,
    type DatepickerRef,
    type DatepickerTheme,
} from '@analytical-alley/ui';
import { ForwardedRef, forwardRef } from 'react';

const theme: DeepPartial<DatepickerTheme> = {
    popup: {
        root: {
            inner: 'inline-block rounded-lg bg-white p-4 shadow-lg glass',
        },
    },
};
export const Datepicker = forwardRef(
    (props: DatepickerProps, ref: ForwardedRef<DatepickerRef>) => {
        return <UIDatepicker ref={ref} {...props} theme={theme} />;
    },
);

Datepicker.displayName = 'Datepicker';
