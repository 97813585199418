import { useMemo, useReducer } from 'react';

import {
    TreeNode,
    TreeNodeLike,
    TreeSelectActionTypes,
    TreeSelectReducer,
    TreeSelectState,
    actionTypes,
} from './types';
import {
    addParentsAndIds,
    buildNodeIndex,
    checkAndUpdate,
    copyNodes,
    flatCollectCheckedNodes,
    treeToMap,
} from './utils';

const buildInitialState = (nodes: TreeNodeLike[]): TreeSelectState => {
    const preparedNodes: TreeNode[] = addParentsAndIds(
        copyNodes(nodes as unknown as TreeNode[]),
    );
    const initialState = treeToMap(preparedNodes, true, 'selected');
    const nodeIndex = buildNodeIndex(preparedNodes);
    const expanded = treeToMap(preparedNodes, false, 'expanded');
    return { checked: initialState, nodeIndex, expanded };
};

// If parent node is checked then all of its children should be checked recursively
const checkChildren = (
    nodeIndex: Record<string, TreeNode>,
    checked: Record<string, boolean>,
    id: string,
    newValue: boolean,
): Record<string, boolean> => {
    const node = nodeIndex[id];
    if (node?.children) {
        return node.children.reduce((acc, child) => {
            acc[child.id] = newValue;
            return checkChildren(nodeIndex, acc, child.id, newValue);
        }, checked);
    }
    return checked;
};

const validateChecked = (
    nodeIndex: Record<string, TreeNode>,
    checked: Record<string, boolean>,
): Record<string, boolean> => {
    let newChecked = { ...checked };
    for (const [id, isChecked] of Object.entries(checked)) {
        if (!nodeIndex[id]) {
            delete newChecked[id];
            continue;
        }
        if (isChecked) {
            newChecked = checkChildren(nodeIndex, newChecked, id, true);
        }
    }
    return newChecked;
};

export const treeSelectReducer = (
    state: TreeSelectState,
    action: TreeSelectActionTypes,
): TreeSelectState => {
    const { checked, nodeIndex } = state;
    switch (action.type) {
        case actionTypes.toggleNode: {
            const id = action.payload.id;
            const newValue = !checked[id];
            const updates = checkAndUpdate(nodeIndex, checked, id, newValue);
            const newCheckedState = {};
            for (const [nodeId, nodeChecked] of Object.entries(checked)) {
                newCheckedState[nodeId] =
                    updates[nodeId] !== undefined
                        ? updates[nodeId]
                        : nodeChecked;
            }
            return { ...state, nodeIndex, checked: newCheckedState };
        }
        case actionTypes.checkAll: {
            const allChecked = Object.keys(nodeIndex).reduce(
                (acc: Record<string, boolean>, id) => {
                    acc[id] = true;
                    return acc;
                },
                {},
            );
            return { ...state, nodeIndex, checked: allChecked };
        }
        case actionTypes.checkNone: {
            const noneChecked = Object.keys(nodeIndex).reduce(
                (acc: Record<string, boolean>, id) => {
                    acc[id] = false;
                    return acc;
                },
                {},
            );
            return { ...state, nodeIndex, checked: noneChecked };
        }
        case actionTypes.setNodes: {
            const newState = buildInitialState(action.payload.nodes);

            if (action.payload.checked) {
                // If parent node is checked but all children are not, we need to uncheck the parent
                const newCheckedState = validateChecked(
                    newState.nodeIndex,
                    action.payload.checked,
                );

                const inverseNewStateChecked = Object.keys(
                    newState.checked,
                ).reduce((acc, id) => {
                    acc[id] = false;
                    return acc;
                }, {});

                return {
                    ...newState,
                    checked: { ...inverseNewStateChecked, ...newCheckedState },
                };
            }

            return newState;
        }
        case actionTypes.toggleExpanded: {
            return {
                ...state,
                expanded: {
                    ...state.expanded,
                    [action.payload.id]: !state.expanded[action.payload.id],
                },
            };
        }
        case actionTypes.expandAll: {
            return {
                ...state,
                expanded: Object.keys(state.expanded).reduce(
                    (acc, id) => {
                        acc[id] = action.payload.expand;
                        return acc;
                    },
                    {} as Record<string, boolean>,
                ),
            };
        }
        case actionTypes.setChecked: {
            const newCheckedState = validateChecked(nodeIndex, action.payload);
            return { ...state, checked: { ...checked, ...newCheckedState } };
        }
    }
    return state;
};

export function useTreeSelect(
    rawNodes: TreeNodeLike[] = [],
    reducer: TreeSelectReducer = treeSelectReducer,
): {
    toggleChecked: (id: string) => void;
    state: TreeSelectState;
    selectAll: () => void;
    selectNone: () => void;
    setNodes: (
        nodes: TreeNodeLike[],
        checked?: Record<string, boolean>,
    ) => void;
    nodes: TreeNode[];
    isExpanded: (id: string) => boolean;
    getExpandButtonProps: (id: string) => {
        onClick: () => void;
    };
    getCheckboxProps: (id: string) => {
        checked: boolean;
        onChange: () => void;
        type: string;
    };
    simplifiedSelection: TreeNode[];
    isIndeterminate: (id: string) => boolean;
    expandAll: (expand: boolean) => void;
    setChecked: (checkedMap: Record<string, boolean>) => void;
} {
    const [state, dispatch] = useReducer<TreeSelectReducer, TreeNodeLike[]>(
        reducer,
        rawNodes,
        buildInitialState,
    );

    const isIndeterminate = (id: string): boolean => {
        const node = state.nodeIndex[id];
        if (node && node.children) {
            const isNodeChecked = state.checked[id];
            const isAnyDescendantChecked = (nodeId: string): boolean => {
                const node = state.nodeIndex[nodeId];
                if (state.checked[nodeId]) {
                    return true;
                }
                if (node?.children) {
                    return node.children.some((child) =>
                        isAnyDescendantChecked(child.id),
                    );
                }
                return false;
            };
            return (
                !isNodeChecked &&
                node.children.some((child) => isAnyDescendantChecked(child.id))
            );
        }
        return false;
    };

    const expandAll = (expand: boolean): void => {
        dispatch({ type: actionTypes.expandAll, payload: { expand } });
    };

    const toggleChecked = (id: string): void => {
        dispatch({ type: actionTypes.toggleNode, payload: { id: id } });
    };
    const selectAll = (): void => {
        dispatch({ type: actionTypes.checkAll });
    };
    const selectNone = (): void => {
        dispatch({ type: actionTypes.checkNone });
    };
    const setNodes = (
        nodes: TreeNodeLike[],
        checked?: Record<string, boolean>,
    ): void => {
        dispatch({ type: actionTypes.setNodes, payload: { nodes, checked } });
    };
    const toggleExpanded = (id: string): void => {
        dispatch({ type: actionTypes.toggleExpanded, payload: { id } });
    };

    const setChecked = (checkedMap: Record<string, boolean>) => {
        dispatch({
            type: actionTypes.setChecked,
            payload: checkedMap,
        });
    };

    const getCheckboxProps = (
        id: string,
    ): {
        checked: boolean;
        onChange: () => void;
        type: string;
    } => {
        return {
            checked: state.checked[id]!,
            onChange: (): void => toggleChecked(id),
            type: 'checkbox',
        };
    };
    const getExpandButtonProps = (
        id: string,
    ): {
        onClick: () => void;
    } => {
        return {
            onClick: (): void => {
                toggleExpanded(id);
            },
        };
    };
    const isExpanded = (id: string) => {
        return !!state.expanded[id];
    };

    const nodes = useMemo((): TreeNode[] => {
        return (Object.values(state.nodeIndex) as unknown as TreeNode[]).filter(
            (node: TreeNode) => node.parent === undefined,
        );
    }, [state.nodeIndex]);

    const simplifiedSelection = useMemo(
        () => flatCollectCheckedNodes(nodes, state.checked),
        [nodes, state.checked],
    );

    return {
        nodes,
        state,
        toggleChecked,
        selectAll,
        selectNone,
        setNodes,
        getCheckboxProps,
        getExpandButtonProps,
        isExpanded,
        simplifiedSelection,
        isIndeterminate,
        expandAll,
        setChecked,
    };
}
