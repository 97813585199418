import { jsx as m } from "react/jsx-runtime";
import { r as s } from "../utils-CqJKQhjA.js";
import { AuthProvider as h } from "react-oidc-context";
const P = ({
  children: o,
  onSigninCallback: r,
  ...e
}) => /* @__PURE__ */ m(
  h,
  {
    onSigninCallback: (t) => {
      s(), r == null || r(t);
    },
    ...e,
    children: o
  }
);
export {
  P as AuthProvider
};
