import { WithLoader } from '@/components/WithLoader';
import { CompetitorMediaEffectOnClientRevenueGraph } from '@/dashboards/CompetitorsMediaInvestment/components/CompetitorMediaEffectOnClientRevenueGraph';
import { useCompetitorsMediaInvestmentContext } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentContext';
import { useCompetitorsMediaInvestmentQuery } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentQuery';
import { Typography } from '@analytical-alley/ui';
import { IconArrowDown } from '@tabler/icons-react';
import React from 'react';

export const CompetitorMediaEffectOnClientRevenueSection = () => {
    const {
        competitorsMediaInvestmentData: {
            totalInvestment,
            totalInvestmentByVariable,
        },
    } = useCompetitorsMediaInvestmentContext();
    const {
        queryResult: { isLoading, isFetching },
    } = useCompetitorsMediaInvestmentQuery();

    const client = totalInvestmentByVariable.get('IKEA') || 0;
    const revenueEffectToClient = (totalInvestment - client) / client;

    return (
        <div className="glass-tile p-6">
            <div className="flex mb-4 gap-3">
                <Typography variant="titleS">
                    Total Competitor media effect on your revenue:
                </Typography>
                {!isLoading && !isFetching && (
                    <div className="font-mono flex flex-row items-center">
                        <IconArrowDown size={22} color="#FF3766" />
                        <Typography
                            className="text-rose-500 dark:text-rose-500"
                            variant="titleS"
                        >
                            {revenueEffectToClient.toFixed(2)}%
                        </Typography>
                    </div>
                )}
            </div>
            <WithLoader isLoading={isLoading} isFetching={isFetching}>
                <CompetitorMediaEffectOnClientRevenueGraph />
            </WithLoader>
        </div>
    );
};
