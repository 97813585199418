import { PeriodOption } from '@/config/model';
import moment from 'moment/moment';

export const makeDateFormatter = (period: PeriodOption | undefined) => {
    if (!period) {
        return undefined;
    }
    switch (period) {
        case 'daily':
            return (dateString: string) => dateString;
        case 'weekly':
            return (dateString: string) => {
                const date = moment.utc(dateString, 'DD-MM-YYYY');
                return `${date.isoWeek()}-${date.year()}`;
            };
        case 'monthly':
            return (dateString: string) => {
                return moment.utc(dateString, 'DD-MM-YYYY').format('MM-YYYY');
            };
        case 'quarterly':
            return (dateString: string) => {
                const date = moment.utc(dateString, 'DD-MM-YYYY'); // replace with your date
                const quarterNumber = date.quarter();

                return `Q${quarterNumber}-${date.year()}`;
            };
        case 'yearly':
            return (dateString: string) => {
                return moment.utc(dateString, 'DD-MM-YYYY').format('YYYY');
            };
    }
};
