import { useRefs } from '@/components/Table/hooks/useRefs';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export type TablePartLocation = 'left' | 'right' | 'center' | undefined;

export const useSyncRowHeights = ({ disabled }: { disabled?: boolean }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const { refs: leftRowRefs, setRefs: setLeftRowRefs } =
        useRefs<HTMLTableRowElement>();
    const { refs: centerRowRefs, setRefs: setCenterRefs } =
        useRefs<HTMLTableRowElement>();
    const { refs: rightRowRefs, setRefs: setRightRefs } =
        useRefs<HTMLTableRowElement>();

    const calculateAndSetRowHeights = useCallback(() => {
        const largestHeights = Object.entries(centerRowRefs.current).reduce(
            (acc, [key, row]) => {
                // Reset all row heights before calculating the new ones
                // This is necessary to ensure that the rows heights can go down as well
                if (leftRowRefs.current[key]) {
                    leftRowRefs.current[key]!.style.height = 'auto';
                }
                if (centerRowRefs.current[key]) {
                    centerRowRefs.current[key]!.style.height = 'auto';
                }
                if (rightRowRefs.current[key]) {
                    rightRowRefs.current[key]!.style.height = 'auto';
                }

                // Get the height of the row in each part
                const leftHeight = leftRowRefs.current?.[key]?.clientHeight;
                const rightHeight = rightRowRefs.current?.[key]?.clientHeight;
                const centerHeight = row?.clientHeight;

                const largestHeight = Math.max(
                    leftHeight || 0,
                    rightHeight || 0,
                    centerHeight || 0,
                );

                return {
                    ...acc,
                    [key]: largestHeight,
                };
            },
            {} as Record<string, number>,
        );

        Object.entries(largestHeights).forEach(([key, height]) => {
            if (leftRowRefs.current[key]) {
                leftRowRefs.current[key]!.style.height = `${height}px`;
            }

            if (centerRowRefs.current[key]) {
                centerRowRefs.current[key]!.style.height = `${height}px`;
            }

            if (rightRowRefs.current[key]) {
                rightRowRefs.current[key]!.style.height = `${height}px`;
            }
        });
    }, [centerRowRefs, leftRowRefs, rightRowRefs]);

    const resizeObserver = useMemo(
        () => new ResizeObserver(calculateAndSetRowHeights),
        [calculateAndSetRowHeights],
    );

    useEffect(() => {
        const container = containerRef.current;

        if (!disabled && container) {
            resizeObserver.observe(container);
        }

        return () => {
            if (!disabled && container) {
                resizeObserver.disconnect();
            }
        };
    }, [disabled, calculateAndSetRowHeights, resizeObserver]);

    const setRowRefs = useCallback(
        (
            location: TablePartLocation,
            id: string | number,
            el: HTMLTableRowElement | null,
        ) => {
            if (disabled) {
                return;
            }

            if (location === 'left') {
                setLeftRowRefs(String(id), el);
            } else if (location === 'center') {
                setCenterRefs(String(id), el);
            } else if (location === 'right') {
                setRightRefs(String(id), el);
            }
        },
        [disabled, setCenterRefs, setLeftRowRefs, setRightRefs],
    );

    return {
        calculateAndSetRowHeights,
        containerRef,
        setRowRefs,
    };
};
