import classNames from 'classnames';
import React, { forwardRef, MouseEventHandler, Ref } from 'react';

type MenuButtonType = {
    isOpen: boolean;
    toggleMenu: MouseEventHandler<HTMLButtonElement>;
};

export const MenuButton = forwardRef(
    (
        { isOpen, toggleMenu }: MenuButtonType,
        buttonRef: Ref<HTMLButtonElement>,
    ) => {
        return (
            <button
                className="glass-tile z-40 w-16 h-16 relative flex justify-center items-center ml-8 px-3 rounded-lg shadow-lg backdrop-blur-[15px] bg-white bg-opacity-10 hover:bg-opacity-30"
                onMouseDown={toggleMenu}
                ref={buttonRef}
            >
                <div className="block w-4 absolute left-6 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <span
                        className={classNames(
                            'block absolute h-0.5 w-8 bg-current transform transition duration-500 ease-in-out',
                            {
                                'rotate-45': isOpen,
                                ' -translate-y-2': !isOpen,
                            },
                        )}
                    ></span>
                    <span
                        className={classNames(
                            'block absolute h-0.5 w-8 text-dark dark:text-white bg-current transform transition duration-500 ease-in-out',
                            { 'opacity-0': isOpen },
                        )}
                    ></span>
                    <span
                        className={classNames(
                            'block absolute  h-0.5 w-8 text-dark dark:text-white bg-current transform  transition duration-500 ease-in-out',
                            {
                                '-rotate-45': isOpen,
                                ' translate-y-2': !isOpen,
                            },
                        )}
                    ></span>
                </div>
            </button>
        );
    },
);

MenuButton.displayName = 'MenuButton';
