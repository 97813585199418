import { FullScreenProps } from '@/components/FullScreen';
import { Table } from '@/components/Table';
import { useConfigContext } from '@/context/configContext';
import { SuggestedBudgetCellRender } from '@/dashboards/BudgetOptimization/components/SuggestedBudgetTable/SuggestedBudgetCellRender';
import { CampaignOptimizationLoader } from '@/dashboards/CampaignOptimization/components/CampaignOptimizationLoader';
import { MediaInvestmentCellRender } from '@/dashboards/CampaignOptimization/components/MediaInvestmentTable/MediaInvestmentCellRender';
import { useCampaignOptimizationContext } from '@/dashboards/CampaignOptimization/hooks';
import { roundToDecimals } from '@/utils/numberUtils';
import { Typography } from '@analytical-alley/ui';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';

export type MediaInvestmentTableData = {
    category: string;
    variable: string;
    previousPeriodInvestments: number;
    recommendedBudget: number;
    contribution: number;
};

const columnHelper = createColumnHelper<MediaInvestmentTableData>();

export const MediaInvestmentTableBase = ({
    fullScreenButton,
}: FullScreenProps) => {
    const {
        data: { suggestedBudgetData, simulatedInvestmentTotal },
    } = useCampaignOptimizationContext();
    const { formatNumber } = useConfigContext();

    const columns = useMemo(
        () => [
            columnHelper.accessor('category', {
                header: () => 'Category',
                cell: (info) => {
                    return <SuggestedBudgetCellRender {...info} disabled />;
                },
                footer: () => 'Total',
                meta: {
                    header: {
                        className: 'w-[1px]',
                    },
                    footer: {
                        className: 'text-right',
                    },
                },
            }),
            columnHelper.accessor('variable', {
                cell: (info) => {
                    return <SuggestedBudgetCellRender {...info} disabled />;
                },
                header: () => <span>Variable</span>,
            }),
            columnHelper.accessor('recommendedBudget', {
                header: 'Recommended budget',
                cell: (info) => {
                    const recommendedBudget = info.getValue();

                    return (
                        <SuggestedBudgetCellRender
                            {...info}
                            className="justify-center"
                            value={formatNumber(recommendedBudget)}
                        >
                            <div className="flex">
                                {formatNumber(recommendedBudget)}
                            </div>
                        </SuggestedBudgetCellRender>
                    );
                },
                meta: {
                    header: {
                        className: 'w-[1px] text-center',
                    },
                    cell: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
                footer: (info) => {
                    return (
                        <span>
                            {formatNumber(
                                info.table
                                    .getRowModel()
                                    .rows.reduce(
                                        (acc, row) =>
                                            acc +
                                            row.original.recommendedBudget,
                                        0,
                                    ),
                            )}
                        </span>
                    );
                },
            }),
            columnHelper.accessor('recommendedBudget', {
                id: 'recommendedBudgetShare',
                header: 'Recommended budget share',
                cell: (info) => {
                    const total = simulatedInvestmentTotal;
                    const recommendedBudget = info.getValue();
                    const percent = roundToDecimals(
                        (recommendedBudget / total) * 100,
                        2,
                    );

                    return (
                        <SuggestedBudgetCellRender
                            {...info}
                            className="justify-center"
                            value={`${percent}%`}
                        >
                            <div className="flex">{percent}%</div>
                        </SuggestedBudgetCellRender>
                    );
                },
                meta: {
                    header: {
                        className: 'w-[1px] text-center',
                    },
                    cell: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
                footer: () => {
                    return <span>100%</span>;
                },
            }),
            columnHelper.accessor('contribution', {
                header: () => 'Contribution',
                meta: {
                    header: {
                        className: 'w-[1px] text-center',
                    },
                    cell: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
                cell: (info) => {
                    return (
                        <MediaInvestmentCellRender
                            {...info}
                            className="justify-center"
                            value={formatNumber(info.getValue())}
                        >
                            {formatNumber(info.getValue())}
                        </MediaInvestmentCellRender>
                    );
                },
                footer: (info) => {
                    const totalContribution = info.table
                        .getRowModel()
                        .rows.reduce(
                            (acc, row) => acc + row.original.contribution,
                            0,
                        );
                    return (
                        <span className="text-center">
                            {formatNumber(totalContribution)}
                        </span>
                    );
                },
            }),
        ],
        [formatNumber, simulatedInvestmentTotal],
    );

    return (
        <CampaignOptimizationLoader>
            <div className="glass-tile p-6">
                <div className="flex items-center mb-4">
                    <Typography variant="titleS">
                        Media Investment Split
                    </Typography>
                    <span className="grow" />
                    {fullScreenButton}
                </div>

                <Table
                    className="overflow-x-auto"
                    data={suggestedBudgetData}
                    columns={columns}
                    shouldSpanRow
                    colSpan={{
                        footer: {
                            value: 2,
                            index: 0,
                        },
                    }}
                />
            </div>
        </CampaignOptimizationLoader>
    );
};
