const n = JSON.parse('"import_meta_env_placeholder"'), c = (t) => new Proxy(t, {
  get(e, r) {
    try {
      return n[r] || e[r];
    } catch {
      return "";
    }
  }
});
export {
  c as initEnv
};
