import { GetCategoryVariableColorMapResponse } from '@/api/dashboards/getCategoryVariableColorMap';
import {
    ChartOptions,
    ShareByCategoryData,
} from '@/dashboards/BudgetOptimization/components/BudgetShareByCategory/BudgetShareByCategoryChart';
import { BudgetSharePieTooltip } from '@/dashboards/BudgetOptimization/components/BudgetShareByCategory/BudgetSharePieTooltip';
import decreaseArrow from '@/svgs/decreaseArrow.svg';
import increaseArrow from '@/svgs/increaseArrow.svg';
import { jsxToHtml } from '@/utils/reactUtils';

export const budgetShareChartOptions = ({
    showPercent,
    tooltipTitle,
    data,
    formatNumber,
    colorMap,
}: {
    colorMap: GetCategoryVariableColorMapResponse;
    showPercent: boolean;
    tooltipTitle?: string;
    data: ShareByCategoryData[];
    formatNumber: (num: number) => string;
}): ChartOptions => {
    return {
        legend: {
            show: false,
        },
        animation: false,
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove|click',
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            type: 'item',
            formatter: (p) => {
                return jsxToHtml(
                    <BudgetSharePieTooltip
                        {...p}
                        showPercent={showPercent}
                        value={formatNumber(p.value)}
                        title={tooltipTitle}
                    />,
                );
            },
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '70%',
                width: '100%',
                color: '#fff',
                left: -0,
                label: {
                    show: true,
                    backgroundColor: 'transparent',
                    borderWidth: 1,
                    borderRadius: 5,
                    color: '#fff',
                    align: 'center',
                    rich: {
                        per: {
                            padding: [5, 0],
                            align: 'center',
                            color: '#eee',
                            fontSize: '0.875rem',
                        },
                        arrow: {
                            padding: [5, 0],
                            height: 12,
                            width: 13,
                            align: 'center',
                        },
                        num: {
                            fontSize: '0.875rem',
                            align: 'center',
                        },
                    },
                },
                data: data.map(({ name, budget, percentage }) => {
                    return {
                        value: budget,
                        name,
                        percentage,
                        itemStyle: {
                            color: colorMap.categoryColorMap[name],
                        },
                        label:
                            showPercent && percentage !== undefined
                                ? {
                                      formatter: `{per|{b}}\n{per|{d}%}\n{arrow| }{num|${percentage}%}`,
                                      rich: {
                                          arrow: {
                                              backgroundColor: {
                                                  image:
                                                      percentage < 0
                                                          ? decreaseArrow
                                                          : increaseArrow,
                                              },
                                          },
                                          num: {
                                              fontSize: 16,
                                              align: 'center',
                                              color:
                                                  percentage < 0
                                                      ? '#FF3766'
                                                      : '#3BE98B',
                                          },
                                      },
                                  }
                                : {
                                      formatter: `{per|{b}}\n{per|{d}%}`,
                                  },
                    };
                }),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
    };
};
