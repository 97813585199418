import { KEY_EVENT_KEYS } from '@/config/constants';
import { useConfigContext } from '@/context/configContext';
import Locked from '@/svgs/locked.svg?react';
import UnLocked from '@/svgs/unlocked.svg?react';
import { cn } from '@/utils';
import { CurrencyInput, Typography } from '@analytical-alley/ui';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BudgetSlider } from './BudgetSlider';
import { UseSplitDataConfig } from './useSplitData';

export interface BudgetRowValues {
    investment: number | null;
    locked: boolean;
}

export const ModifyBudgetRow = ({
    name,
    title,
    sliderConfig,
    formValues,
    onSubmit,
    active,
}: {
    active?: boolean;
    name: string;
    title?: string;
    formValues: BudgetRowValues;
    sliderConfig: UseSplitDataConfig;
    onSubmit: (key: string, data: BudgetRowValues) => void;
}) => {
    const { locale, currency } = useConfigContext();
    const methods = useForm<BudgetRowValues>({
        defaultValues: formValues,
        values: formValues,
    });

    const {
        reset,
        formState: { isDirty },
    } = methods;

    useEffect(() => {
        reset(formValues);
    }, [formValues, reset]);

    const submit = (force?: boolean) => {
        if (isDirty || force) {
            methods.handleSubmit((data) => onSubmit(name, data))();
        }
    };

    const { watch, control } = methods;

    const investmentValue = watch('investment');
    const isLocked = watch('locked');

    return (
        <div
            className={cn(
                'flex gap-2 items-center px-4 py-3 w-full rounded-2xl max-md:flex-wrap max-md:max-w-full flex-wrap',
                {
                    'bg-[#F7FCFF26]': active,
                },
            )}
        >
            <Typography
                ellipsisTooltip={
                    <div className="rounded-md bg-white text-dark text-sm p-3">
                        {title || name}
                    </div>
                }
                className="text-nowrap self-stretch my-auto text-sm leading-5 w-1/3"
            >
                {title || name}
            </Typography>

            <Controller
                control={control}
                render={({ field: { onChange, ...fieldProps } }) => (
                    <CurrencyInput
                        {...fieldProps}
                        disabled={!isLocked}
                        value={investmentValue == null ? '' : investmentValue}
                        intlConfig={{ locale, currency }}
                        className={cn(
                            'w-36 text-nowrap justify-center px-4 py-2 text-sm leading-5 rounded-lg shadow-lg backdrop-blur-[15px] bg-white bg-opacity-10 glass text-dark dark:text-white border-0 focus:ring-1 focus:ring-[#1C64F2] focus-visible:outline-none dark:shadow-sm-light',
                            { 'cursor-not-allowed': !isLocked },
                        )}
                        decimalScale={0}
                        onBlur={() => {
                            submit();
                        }}
                        onKeyDown={(e) => {
                            if (e.key === KEY_EVENT_KEYS.ENTER) {
                                submit();
                            }
                        }}
                        groupSeparator={' '}
                        onValueChange={(e, s, values) => {
                            const value = values?.float;

                            if (value == null) {
                                onChange(null);
                                return;
                            }

                            if (value < 0) {
                                onChange(0);
                                return;
                            }

                            onChange(value);
                        }}
                    />
                )}
                name="investment"
            />
            <div className="grow">
                <div className="flex min-w-72">
                    <BudgetSlider
                        value={investmentValue || 0}
                        config={sliderConfig}
                        tooltipContent={({
                            hoveredValue,
                            isCurrentValueHovered,
                        }) => {
                            const value = isCurrentValueHovered
                                ? hoveredValue
                                : hoveredValue.value;
                            const profitable = sliderConfig.startsWithProfit
                                ? value <= sliderConfig.mid
                                : value > sliderConfig.mid;

                            return (
                                <div className="tooltip-content">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">
                                                    <Typography className="text-black dark:text-black">
                                                        Variable:
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography className="text-dark dark:text-dark">
                                                        {name}
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">
                                                    <Typography className="text-black dark:text-black">
                                                        Investment:
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography className="text-dark dark:text-dark">
                                                        {value}
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">
                                                    <Typography className="text-black dark:text-black">
                                                        Profitable to invest:
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography className="text-dark dark:text-dark">
                                                        {profitable
                                                            ? 'Yes'
                                                            : 'No'}
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">
                                                    <Typography className="text-black dark:text-black">
                                                        Minimum recommended
                                                        budget:
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography className="text-dark dark:text-dark">
                                                        {sliderConfig.startsWithProfit
                                                            ? sliderConfig.min
                                                            : sliderConfig.mid}
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">
                                                    <Typography className="text-black dark:text-black">
                                                        Maximum recommended
                                                        budget:
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography className="text-dark dark:text-dark">
                                                        {sliderConfig.startsWithProfit
                                                            ? sliderConfig.mid
                                                            : sliderConfig.max}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            );
                        }}
                    />
                    <div className="flex">
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, value, ...fieldProps },
                            }) => (
                                <label
                                    htmlFor={`input-id-${name}`}
                                    className="inline-flex items-center cursor-pointer"
                                >
                                    <input
                                        {...fieldProps}
                                        type="checkbox"
                                        className="sr-only peer"
                                        id={`input-id-${name}`}
                                        checked={value}
                                        onChange={(e) => {
                                            onChange(e.target.checked);
                                            submit(true);
                                        }}
                                    />
                                    <div className="relative w-11 h-6 bg-[#C0C9E6] peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#1C64F2] rounded-full peer dark:bg-[#C0C9E6] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#3BE98B]"></div>
                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
                                </label>
                            )}
                            name="locked"
                        />
                        {isLocked ? (
                            <Locked className="self-center" />
                        ) : (
                            <UnLocked className="self-center" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
