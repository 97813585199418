import {
    duckDBSignedUrlQueryOptions,
    getProjectInfoQueryOptions,
} from '@/api/project';
import {
    COMPETITOR_MEDIA_INVESTMENT_ROUTE,
    MODEL_CONTRIBUTION_ROUTE,
    SPEND_SUMMARY_ROUTE,
    TITLE_DASHBOARD_ROUTE,
} from '@/config/routes';
import { DashboardGlobalProvider } from '@/dashboards/providers/dashboardGlobalContext';
import { validateDateAndPeriodSearchParams } from '@/dashboards/utils';
import {
    DB_NAME,
    DuckDBQueryContextProvider,
    useDuckDBConnection,
} from '@/duckdb';
import { useAbilityContext } from '@/features/Casl/hooks';
import { initOptimizer } from '@/optimizer';
import { useInitOptimizerServiceManager } from '@/optimizer/optimizerContext';
import { Route } from '@/routes/_org/project/$projectId/_dashboards';
import { FileConnectionEnum } from '@analytical-alley/duckdb-react';
import { FullPageLoader } from '@analytical-alley/ui';
import { useQuery } from '@tanstack/react-query';
import {
    Outlet,
    useMatches,
    useNavigate,
    useSearch,
} from '@tanstack/react-router';
import moment from 'moment';
import { useEffect, useMemo } from 'react';

const ROUTES_WITH_SEARCH_VALIDATION: string[] = [
    TITLE_DASHBOARD_ROUTE,
    MODEL_CONTRIBUTION_ROUTE,
    SPEND_SUMMARY_ROUTE,
    COMPETITOR_MEDIA_INVESTMENT_ROUTE,
];

export const DashboardsLayout = () => {
    const initOptimizerServiceManager = useInitOptimizerServiceManager();
    const { updateAbility, abilityInitialized } = useAbilityContext();
    const { projectId } = Route.useParams();
    const { dashboardId } = Route.useSearch();
    const navigate = useNavigate();

    const matches = useMatches();
    const shouldValidateSearch = useMemo(
        () =>
            matches.some((elem) =>
                ROUTES_WITH_SEARCH_VALIDATION.includes(elem.routeId),
            ),
        [matches],
    );

    const search = useSearch({ strict: false });

    const {
        data: projectData,
        isFetching,
        isLoading,
    } = useQuery(
        getProjectInfoQueryOptions({
            dashboardId,
            projectId,
        }),
    );

    const startDate = projectData?.dashboard.dataPeriodMinDate
        ? moment
              .utc(projectData.dashboard.dataPeriodMinDate)
              .format('DD-MM-YYYY')
        : '';
    const endDate = projectData?.dashboard.dataPeriodMaxDate
        ? moment
              .utc(projectData.dashboard.dataPeriodMaxDate)
              .format('DD-MM-YYYY')
        : '';

    useEffect(() => {
        if (!shouldValidateSearch || !projectData) {
            return;
        }
        const { newSearch, shouldRedirect } = validateDateAndPeriodSearchParams(
            {
                search,
                project: {
                    period: projectData.project.interval,
                    startDate,
                    endDate,
                },
            },
        );

        if (shouldRedirect) {
            navigate({
                search: newSearch,
                replace: true,
            });
        }
    }, [
        endDate,
        shouldValidateSearch,
        navigate,
        projectData,
        search,
        startDate,
    ]);

    const { data: urlData } = useQuery(
        duckDBSignedUrlQueryOptions({ projectId, dashboardId }),
    );

    const { useQueryHook, isLoading: isDuckDbLoading } = useDuckDBConnection(
        {
            url: urlData?.signedUrl,
            registerWithFileBuffer: true,
            type: FileConnectionEnum.DUCKDB,
            databaseName: DB_NAME,
        },
        {
            onSuccess: async (query) => {
                await initOptimizer(query, { consoleEnabled: false });
                initOptimizerServiceManager();
            },
            queryKey: 'duckdb-connection',
            throwOnError: true,
        },
    );

    useEffect(() => {
        if (!projectData) return;

        updateAbility(projectData.dashboard.featureFlags);
    }, [projectData, updateAbility]);

    if (isFetching || isLoading || isDuckDbLoading) {
        return <FullPageLoader />;
    }

    if (!abilityInitialized) {
        return <div>Couldn&apos;t read permissions!</div>;
    }

    if (!projectData) {
        return <div>Couldn&apos;t read project data!</div>;
    }

    return (
        <DuckDBQueryContextProvider useQueryHook={useQueryHook}>
            <DashboardGlobalProvider
                project={projectData.project}
                dashboard={projectData.dashboard}
                endDate={endDate}
                startDate={startDate}
            >
                <Outlet />
            </DashboardGlobalProvider>
        </DuckDBQueryContextProvider>
    );
};
