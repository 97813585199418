import { previewDashboardSearchSchema } from '@/config/commonSearch';
import { z } from 'zod';

export const modelContributionsSearchSchema =
    previewDashboardSearchSchema.extend({
        period: z
            .union([
                z.literal('daily'),
                z.literal('weekly'),
                z.literal('monthly'),
                z.literal('quarterly'),
                z.literal('yearly'),
            ])
            .optional()
            .catch(undefined),
        startDate: z.string().optional().catch(undefined),
        endDate: z.string().optional().catch(undefined),
        modelBreakdown: z
            .union([z.literal('variable'), z.literal('category')])
            .catch('category'),
        filter: z.array(z.string()).optional().catch(undefined),
    });

export type ModelContributionsSearch = z.infer<
    typeof modelContributionsSearchSchema
>;

export { ModelContributions } from './ModelContributions';
