import { useContext } from 'react';
import { AbilityContext, CaslAbilityContext } from '../context';

export const useAbilityContext = () => {
    const { updateAbility, abilityInitialized } = useContext(AbilityContext);
    const ability = useContext(CaslAbilityContext);

    return {
        abilityInitialized,
        updateAbility,
        ability,
    };
};
