import type { DBTable, GetColumns } from '@analytical-alley/duckdb-react/types';
import { z } from 'zod';

export const spendSummaryTableSchema = z.object({
    'Period name': z.string(),
    Variable: z.string(),
    Category: z.string(),
    Spend: z.number(),
    Cost: z.number(),
    Actual: z.number(),
    Raw: z.number(),
    Transformed: z.number(),
    Contribution: z.number(),
});

export const spendSummaryTableColumnsMap = {
    'Period name': 'periodName',
    Variable: 'variable',
    Category: 'category',
    Spend: 'spend',
    Cost: 'cost',
    Actual: 'actual',
    Raw: 'raw',
    Transformed: 'transformed',
    Contribution: 'contribution',
} as const;

type SchemaType = z.infer<typeof spendSummaryTableSchema>;

export type SpendSummaryColumns = GetColumns<
    typeof spendSummaryTableSchema,
    typeof spendSummaryTableColumnsMap
>;

export type TSpendSummaryTableRow = DBTable<
    SchemaType,
    typeof spendSummaryTableColumnsMap
>;
