import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { dashboardApi } from '@/api';
import {
    CompetitorsMediaInvestmentQueryArgs,
    GetCompetitorsMediaInvestmentResponse,
} from '@/dashboards/CompetitorsMediaInvestment/types';

export type CompetitorsMediaInvestmentDataKeys = {
    category: string;
    variable: string;
    investment: string;
    date: string;
};

const getCompetitorsMediaInvestmentDataKeys = (
    results: GetCompetitorsMediaInvestmentResponse,
) => {
    const keyMap: CompetitorsMediaInvestmentDataKeys = {
        category: '',
        variable: '',
        investment: '',
        date: '',
    };

    Object.entries(results.fields).forEach(([key, value]) => {
        if (value.name === 'period_name_day') {
            keyMap.date = key;
        } else {
            keyMap[value.name] = key;
        }
    });

    return keyMap;
};

const modifyCompetitorsMediaInvestmentResponse = (
    data: GetCompetitorsMediaInvestmentResponse,
) => {
    const keyMap = getCompetitorsMediaInvestmentDataKeys(data);

    return {
        fieldKeys: keyMap,
    };
};

export const fetchCompetitorsMediaInvestment = async ({
    projectId,
}: CompetitorsMediaInvestmentQueryArgs) => {
    const data = await dashboardApi<GetCompetitorsMediaInvestmentResponse>({
        method: 'GET',
        url: `/competitors-media-investment/${projectId}`,
    });

    return { ...data, ...modifyCompetitorsMediaInvestmentResponse(data) };
};

export const modelCompetitorsMediaInvestmentOptions = ({
    projectId,
}: CompetitorsMediaInvestmentQueryArgs) =>
    queryOptions({
        queryKey: ['competitorsMediaInvestment', projectId],
        queryFn: () => fetchCompetitorsMediaInvestment({ projectId }),
        placeholderData: keepPreviousData,
        staleTime: Infinity,
    });
