import { useLayoutEffect } from 'react';

export const useBodyClass = (className: string) => {
    useLayoutEffect(() => {
        const root = document.body;
        root.className = root.className.replace(/bg-[a-zA-Z0-9-]+/g, '');
        root.classList.add(className);

        return () => {
            root.classList.remove(className);
        };
    }, [className]);
};
