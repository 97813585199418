import { defineTable } from '@/duckdb';
import { spendSummaryTableColumnsMap, spendSummaryTableSchema } from './schema';

export { spendSummaryQueries } from './queries';
export { type TSpendSummaryTableRow } from './schema';
export const SpendSummaryTable = defineTable(
    'Spend Summary',
    spendSummaryTableSchema,
    spendSummaryTableColumnsMap,
);
