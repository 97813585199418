const e = {
  root: {
    base: "relative"
  },
  popup: {
    root: {
      base: "absolute top-10 z-50 block pt-2",
      inline: "relative top-0 z-auto",
      inner: "inline-block rounded-lg p-4 shadow-lg bg-glassCl"
    },
    header: {
      base: "",
      title: "px-2 py-3 text-center font-semibold text-gray-900 dark:text-white",
      selectors: {
        base: "flex justify-between mb-2",
        button: {
          base: "text-sm rounded-lg text-dark dark:text-white font-semibold py-2.5 px-5 hover:bg-gray-100 hover:glass focus:outline-none focus:ring-2 focus:ring-gray-200 view-switch",
          //base: 'text-sm rounded-lg text-gray-900 dark:text-white bg-white dark:bg-gray-700 font-semibold py-2.5 px-5 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-200 view-switch',
          prev: "",
          next: "",
          view: ""
        }
      }
    },
    view: {
      base: "p-1"
    },
    footer: {
      base: "flex mt-2 space-x-2",
      button: {
        base: "w-full text-dark dark:text-white rounded-lg px-5 py-2 text-center text-sm font-medium hover:bg-gray-100 hover:glass focus:outline-none focus:ring-2 focus:ring-gray-200 view-switch",
        today: "",
        clear: ""
      }
    }
  },
  views: {
    days: {
      header: {
        base: "grid grid-cols-7 mb-1",
        title: "dow h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400"
      },
      items: {
        base: "grid w-64 grid-cols-7",
        item: {
          base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-dark hover:bg-gray-100 dark:hover:bg-white dark:hover:bg-opacity-30 dark:text-white",
          selected: "bg-dark text-white dark:text-dark dark:bg-white hover:bg-dark hover:bg-opacity-80 dark:hover:bg-opacity-90",
          disabled: "text-gray-500 dark:text-gray-500 dark:hover:bg-transparent hover:bg-transparent cursor-not-allowed"
        }
      }
    },
    months: {
      items: {
        base: "grid w-64 grid-cols-4",
        item: {
          base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-dark hover:bg-gray-100 dark:hover:bg-white dark:hover:bg-opacity-30 dark:text-white",
          selected: "bg-dark text-white dark:text-dark dark:bg-white hover:bg-dark hover:bg-opacity-80 dark:hover:bg-opacity-90",
          disabled: "text-gray-500 dark:text-gray-500 dark:hover:bg-transparent hover:bg-transparent cursor-not-allowed"
        }
      }
    },
    years: {
      items: {
        base: "grid w-64 grid-cols-4",
        item: {
          base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-dark hover:bg-gray-100 dark:hover:bg-white dark:hover:bg-opacity-30 dark:text-white",
          selected: "bg-dark text-white dark:text-dark dark:bg-white hover:bg-dark hover:bg-opacity-80 dark:hover:bg-opacity-90",
          disabled: "text-gray-500 dark:text-gray-500 dark:hover:bg-transparent hover:bg-transparent cursor-not-allowed"
        }
      }
    },
    decades: {
      items: {
        base: "grid w-64 grid-cols-4",
        item: {
          base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-dark hover:bg-gray-100 dark:hover:bg-white dark:hover:bg-opacity-30 dark:text-white",
          selected: "bg-dark text-white dark:text-dark dark:bg-white hover:bg-dark hover:bg-opacity-80 dark:hover:bg-opacity-90",
          disabled: "text-gray-500 dark:text-gray-500 dark:hover:bg-transparent hover:bg-transparent cursor-not-allowed"
        }
      }
    }
  }
};
export {
  e as datePickerTheme
};
