import { withFullScreen } from '@/components/FullScreen';
import { CampaignSummary } from '@/dashboards/CampaignOptimization/components/CampaignSummary';
import { ContributionSeasonality } from '@/dashboards/CampaignOptimization/components/ContributionSeasonality/ContributionSeasonality';
import { MediaInvestmentTable } from '@/dashboards/CampaignOptimization/components/MediaInvestmentTable';
import { MediaSpendSeasonality } from '@/dashboards/CampaignOptimization/components/MediaSpendSeasonality';
import { NaturalDemandSeasonality } from '@/dashboards/CampaignOptimization/components/NaturalDemandSeasonality';
import React from 'react';
import { ContributionShare } from './ContributionShare';
import { ModifyBudget } from './ModifyBudget';

const ContributionSeasonalitySection = withFullScreen(
    'contribution-seasonality',
    ({ fullScreenButton }) => {
        return (
            <div className="glass-tile p-6">
                <ContributionSeasonality>
                    {fullScreenButton}
                </ContributionSeasonality>
                <MediaSpendSeasonality />
            </div>
        );
    },
);

export const CampaignOptimizationSection = () => {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-wrap w-full max-2xl:gap-4">
                <div className="flex flex-col w-1/2 max-2xl:w-full max-2xl:pr-0 gap-4 pr-2">
                    <CampaignSummary />
                    <MediaInvestmentTable />
                    <ContributionShare />
                </div>
                <div className="flex flex-col w-1/2 max-2xl:w-full max-2xl:pr-0 gap-4 pl-2">
                    <ModifyBudget />
                    <NaturalDemandSeasonality />
                </div>
            </div>
            <ContributionSeasonalitySection />
        </div>
    );
};
