import { Reducer } from 'react';

export type TreeSelectReducer = Reducer<TreeSelectState, TreeSelectActionTypes>;

export enum actionTypes {
    toggleNode = 'toggle_node',
    checkAll = 'check_all',
    expandAll = 'expand_all',
    checkNone = 'check_none',
    setNodes = 'set_nodes',
    setChecked = 'set_checked',
    toggleExpanded = 'toggle_expanded',
}

export interface ToggleNodeAction {
    type: actionTypes.toggleNode;
    payload: ToggleNodePayload;
}

export interface SetNodesAction {
    type: actionTypes.setNodes;
    payload: NodesPayload;
}

export interface CheckAllAction {
    type: typeof actionTypes.checkAll;
}
export interface ExpandAllAction {
    type: typeof actionTypes.expandAll;
    payload: { expand: boolean };
}

export interface CheckNoneAction {
    type: typeof actionTypes.checkNone;
}

export interface ToggleExpandedAction {
    type: typeof actionTypes.toggleExpanded;
    payload: ToggleNodePayload;
}

export interface SetCheckedAction {
    type: typeof actionTypes.setChecked;
    payload: Record<string, boolean>;
}

export type TreeSelectActionTypes =
    | ToggleNodeAction
    | CheckAllAction
    | ExpandAllAction
    | CheckNoneAction
    | SetNodesAction
    | ToggleExpandedAction
    | SetCheckedAction;

export interface NodesPayload {
    nodes: TreeNodeLike[];
    checked?: Record<string, boolean>;
}

export interface ToggleNodePayload {
    id: string;
}

export interface TreeSelectState {
    checked: Record<string, boolean>;
    expanded: Record<string, boolean>;
    nodeIndex: Record<string, TreeNode>;
}

export interface TreeNodeLike {
    label: string;
    id?: string;
    selected?: boolean;
    expanded?: boolean;
    children?: TreeNodeLike[];
}

export interface TreeNode {
    id: string;
    label: string;
    selected?: boolean;
    expanded?: boolean;
    children?: TreeNode[];
    parent?: TreeNode;
}
