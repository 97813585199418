import { RoiType } from '@/api/project';
import { SpendSummaryColumns } from '@/duckdb/spend-summary/schema';
import { roundToDecimals } from '@/utils/numberUtils';

type RoiCalculateArgs = {
    spend: number;
    contribution: number;
    contributionCostProduct: number;
};

type ROIConfigValue = {
    name: string;
    description: string;
    contributionProductSql: (column: SpendSummaryColumns) => string;
    calculate: (arg: RoiCalculateArgs) => number;
};

const roiRatioCommon: Pick<
    ROIConfigValue,
    'contributionProductSql' | 'name' | 'calculate'
> = {
    name: 'ROI',
    contributionProductSql: ({ contribution, cost }) =>
        `${contribution} * ${cost}`,
    calculate: ({ spend, contributionCostProduct }) => {
        if (spend === 0) {
            return 0;
        }
        return contributionCostProduct / spend;
    },
};

const ROI_CONFIG: {
    [key in RoiType]: ROIConfigValue;
} = {
    RETURN_RATIO: {
        ...roiRatioCommon,
        description: 'Return for each euro invested',
    },
    TURNOVER_RATIO: {
        ...roiRatioCommon,
        description: 'Turnover for each euro invested',
    },
    REVENUE_RATIO: {
        ...roiRatioCommon,
        description: 'Revenue for each euro invested',
    },
    UNITS_SOLD: {
        name: 'UPE',
        description: 'Number of units sold for each euro invested',
        contributionProductSql: ({ contribution }) => `${contribution}`,
        calculate: ({ spend, contribution }) => {
            if (spend === 0) {
                return 0;
            }
            return contribution / spend;
        },
    },
    'UNITS_SOLD/1000': {
        name: 'UPE',
        description: 'Number of units sold per 1,000 euros invested',
        contributionProductSql: ({ contribution }) => `${contribution} * 1000`,
        calculate: ({ spend, contributionCostProduct }) => {
            if (spend === 0) {
                return 0;
            }
            return contributionCostProduct / spend;
        },
    },
    'UNITS_SOLD/100': {
        name: 'UPE',
        description: 'Number of units sold per 100 euros invested',
        contributionProductSql: ({ contribution }) => `${contribution} * 100`,
        calculate: ({ spend, contributionCostProduct }) => {
            if (spend === 0) {
                return 0;
            }
            return contributionCostProduct / spend;
        },
    },
    COST_PER_UNIT: {
        name: 'CPU',
        description: 'Cost per unit',
        contributionProductSql: ({ contribution }) => `${contribution}`,
        calculate: ({ spend, contribution }) => {
            if (contribution === 0) {
                return 0;
            }
            return spend / contribution;
        },
    },
};

export const getROIConfig = (roiType: RoiType) => {
    const { description, name, calculate, contributionProductSql } =
        ROI_CONFIG[roiType];

    return {
        name,
        description,
        calculate: (arg: RoiCalculateArgs) => roundToDecimals(calculate(arg)),
        contributionProductSql,
    };
};
