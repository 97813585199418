import { Select } from '@/components/Select';
import { CampaignOptimizationLoader } from '@/dashboards/CampaignOptimization/components/CampaignOptimizationLoader';
import {
    ContributionSeasonalityBarChart,
    ContributionSeasonalityData,
} from '@/dashboards/CampaignOptimization/components/ContributionSeasonality/ContributionSeasonalityBarChart';
import { OptimizedPieChart } from '@/dashboards/CampaignOptimization/components/ContributionSeasonality/OptimizedPieChart';
import { useCampaignOptimizationContext } from '@/dashboards/CampaignOptimization/hooks';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { groupContributionData } from '@/dashboards/utils/data';
import { structureMediaSpendSeasonality } from '@/dashboards/utils/data/structureMediaSpendSeasonality';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { TabButtons, Typography } from '@analytical-alley/ui';
import React, { ReactNode, useMemo, useState } from 'react';

export const ContributionSeasonality = ({
    children,
}: {
    children: ReactNode;
}) => {
    const {
        colorMap: { variableColorMap, categoryColorMap },
    } = useDashboardGlobalContext();
    const {
        data: {
            budgetByCategoryData,
            contributionLastYear: {
                data: contributionData,
                isFetching,
                isLoading,
            },
            optimized,
            previous,
        },
        totalContributionGraphPeriod,
        setTotalContributionGraphPeriod,
    } = useCampaignOptimizationContext();
    const [modelBreakdown, setModelBreakdown] = useState<
        'variable' | 'category'
    >('category');

    const selectedIndex = totalContributionGraphPeriod === 'monthly' ? 1 : 0;

    const modelContributionData = useMemo(() => {
        return groupContributionData({
            rows: contributionData?.rows,
            variableColorMap,
            categoryColorMap,
            mediaOnly: true,
        });
    }, [categoryColorMap, contributionData?.rows, variableColorMap]);

    const structuredSeasonalityData = useMemo(() => {
        return structureMediaSpendSeasonality(totalContributionGraphPeriod, {
            variableColorMap,
            categoryColorMap,
            optimizedData: optimized,
            variablesAndCategories: previous?.roiCurves
                .variablesAndCategories || {
                byCategory: {},
                byVariable: {},
            },
        });
    }, [
        totalContributionGraphPeriod,
        variableColorMap,
        categoryColorMap,
        optimized,
        previous?.roiCurves.variablesAndCategories,
    ]);

    const seasonalityData: {
        previous: ContributionSeasonalityData;
        optimized: ContributionSeasonalityData;
    } = useMemo(() => {
        return {
            previous: {
                data:
                    modelBreakdown === 'category'
                        ? modelContributionData.dataByCategory
                        : modelContributionData.dataByVariable,
                dates: modelContributionData.dates,
                totalContributionByDate:
                    modelContributionData.totalContributionByDate,
            },
            optimized: {
                data:
                    modelBreakdown === 'category'
                        ? structuredSeasonalityData.dataByCategory
                        : structuredSeasonalityData.dataByVariable,
                dates: structuredSeasonalityData.dates,
                totalContributionByDate: {},
            },
        };
    }, [
        modelBreakdown,
        modelContributionData.dataByCategory,
        modelContributionData.dataByVariable,
        modelContributionData.dates,
        modelContributionData.totalContributionByDate,
        structuredSeasonalityData.dataByCategory,
        structuredSeasonalityData.dataByVariable,
        structuredSeasonalityData.dates,
    ]);

    return (
        <CampaignOptimizationLoader
            isLoading={isLoading}
            isFetching={isFetching}
        >
            <div className="flex items-center mb-4">
                <Typography variant="titleS">
                    Total Contribution vs Media Investments
                </Typography>
                <TabButtons
                    aria-label="Periodicity"
                    style="pills"
                    className="gap-2 mx-2"
                    onActiveTabChange={(activeTab) => {
                        setTotalContributionGraphPeriod(
                            activeTab === 0 ? 'weekly' : 'monthly',
                        );
                    }}
                    activeTab={selectedIndex}
                >
                    {['weekly', 'monthly'].map((periodName) => {
                        return (
                            <TabButtons.Item
                                key={periodName}
                                title={capitalizeFirstLetter(periodName)}
                            />
                        );
                    })}
                </TabButtons>
                <Select
                    className="min-w-48"
                    selectedValue={modelBreakdown}
                    onSelectChange={(value) => {
                        setModelBreakdown(value);
                    }}
                    dismissOnClick
                    label="Select..."
                    compact
                >
                    <Select.Option label={'By Category'} value={'category'} />
                    <Select.Option label={'By Variable'} value={'variable'} />
                </Select>
                <span className="grow" />
                {children ? <div className="ml-2">{children}</div> : null}
            </div>
            <div className="grid grid-cols-10 gap-5 h-[32rem] mt-6">
                <div className="col-start-1 col-end-8">
                    <ContributionSeasonalityBarChart
                        className="h-full"
                        isLoading={false}
                        data={seasonalityData}
                    />
                </div>
                <div className="col-start-8 col-end-11">
                    <OptimizedPieChart
                        data={budgetByCategoryData.simulated}
                        showPercent={false}
                    />
                </div>
            </div>
        </CampaignOptimizationLoader>
    );
};
