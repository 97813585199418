import { Datepicker } from '@/components/Datepicker';
import { dateToJSONLocal } from '@analytical-alley/ui/utils/datepicker';
import { Label } from 'flowbite-react';
import moment from 'moment';
import { useMemo } from 'react';

interface DateRangeProps {
    minDate?: Date;
    maxDate?: Date;
    searchStartDate: string | undefined;
    searchEndDate: string | undefined;
    onSetSearch: (search: {
        startDate?: string | undefined;
        endDate?: string | undefined;
    }) => void;
}

export const DateRange = ({
    maxDate,
    minDate,
    searchStartDate,
    searchEndDate,
    onSetSearch,
}: DateRangeProps) => {
    const startDate = useMemo(
        () =>
            searchStartDate
                ? moment.utc(searchStartDate, 'DD-MM-YYYY').toDate()
                : null,
        [searchStartDate],
    );
    const endDate = useMemo(
        () =>
            searchEndDate
                ? moment.utc(searchEndDate, 'DD-MM-YYYY').toDate()
                : null,
        [searchEndDate],
    );

    return (
        <>
            <div>
                <div className="mb-1 block">
                    <Label
                        className="font-mono font-normal"
                        htmlFor="startDate"
                        value="Start date"
                    />
                </div>
                <Datepicker
                    dateRangeType="min"
                    minDate={minDate}
                    maxDate={endDate || maxDate}
                    placeholder={'Select start date'}
                    onSelectedDateChanged={(val) => {
                        onSetSearch({
                            startDate: val
                                ? moment
                                      .utc(dateToJSONLocal(val), 'YYYY-MM-DD')
                                      .format('DD-MM-YYYY')
                                : undefined,
                        });
                    }}
                    value={startDate}
                    id="startDate"
                    shadow
                    compact
                    showClearButton={false}
                    showTodayButton={false}
                />
            </div>
            <div>
                <div className="mb-1 block">
                    <Label
                        className="font-mono font-normal"
                        htmlFor="endDate"
                        value="End date"
                    />
                </div>
                <Datepicker
                    dateRangeType="max"
                    minDate={startDate || minDate}
                    maxDate={maxDate}
                    placeholder={'Select end date'}
                    onSelectedDateChanged={(val) => {
                        onSetSearch({
                            endDate: val
                                ? moment
                                      .utc(dateToJSONLocal(val), 'YYYY-MM-DD')
                                      .format('DD-MM-YYYY')
                                : undefined,
                        });
                    }}
                    value={endDate}
                    id="endDate"
                    shadow
                    compact
                    showClearButton={false}
                    showTodayButton={false}
                />
            </div>
        </>
    );
};
