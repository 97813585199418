import { previewDashboardSearchSchema } from '@/config/commonSearch';
import { z } from 'zod';

export const campaignOptimizationSearchSchema =
    previewDashboardSearchSchema.extend({
        budget: z.number().optional(),
        startDate: z.string().optional().catch(undefined),
        endDate: z.string().optional().catch(undefined),
    });

export { CampaignOptimization } from './CampaignOptimization';
