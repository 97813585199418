var i = /* @__PURE__ */ ((e) => (e[e.Days = 0] = "Days", e[e.Months = 1] = "Months", e[e.Years = 2] = "Years", e[e.Decades = 3] = "Decades", e))(i || {}), c = /* @__PURE__ */ ((e) => (e[e.Sunday = 0] = "Sunday", e[e.Monday = 1] = "Monday", e[e.Tuesday = 2] = "Tuesday", e[e.Wednesday = 3] = "Wednesday", e[e.Thursday = 4] = "Thursday", e[e.Friday = 5] = "Friday", e[e.Saturday = 6] = "Saturday", e))(c || {});
const f = (e) => e instanceof Date && !isNaN(e.getTime()), D = (e) => {
  const t = new Date(e);
  return t.setMinutes(e.getMinutes() - e.getTimezoneOffset()), t.toJSON().slice(0, 10);
}, a = (e, t, n) => {
  if (!e || !f(e))
    return !1;
  const r = new Date(
    e.getFullYear(),
    e.getMonth(),
    e.getDate()
  ).getTime();
  if (t && n) {
    const o = new Date(
      t.getFullYear(),
      t.getMonth(),
      t.getDate()
    ).getTime(), s = new Date(
      n.getFullYear(),
      n.getMonth(),
      n.getDate()
    ).getTime();
    return r >= o && r <= s;
  }
  if (t) {
    const o = new Date(
      t.getFullYear(),
      t.getMonth(),
      t.getDate()
    ).getTime();
    return r >= o;
  }
  if (n) {
    const o = new Date(
      n.getFullYear(),
      n.getMonth(),
      n.getDate()
    ).getTime();
    return r <= o;
  }
  return !0;
}, y = (e, t) => !e || !t ? !1 : (e = new Date(e.getFullYear(), e.getMonth(), e.getDate()), t = new Date(
  t.getFullYear(),
  t.getMonth(),
  t.getDate()
), e.getTime() === t.getTime()), h = (e, t, n) => e ? (a(e, t, n) || (t && e < t ? e = t : n && e > n && (e = n)), e) : null, T = (e, t) => {
  const n = new Date(e.getFullYear(), e.getMonth(), 1);
  let o = n.getDay() - t;
  return o < 0 && (o += 7), u(n, -o);
}, Y = (e, t) => {
  const n = [], r = /* @__PURE__ */ new Date(0);
  r.setDate(r.getDate() - r.getDay() + t);
  const o = new Intl.DateTimeFormat(e, { weekday: "short" });
  for (let s = 0; s < 7; s++)
    n.push(o.format(u(r, s)));
  return n;
}, u = (e, t) => {
  const n = new Date(e);
  return n.setDate(n.getDate() + t), n;
}, F = (e, t) => {
  const n = new Date(e);
  return n.setMonth(n.getMonth() + t), n;
}, M = (e, t) => {
  const n = new Date(e);
  return n.setFullYear(n.getFullYear() + t), n;
}, w = (e, t, n) => {
  if (!t)
    return "";
  let r = {
    day: "numeric",
    month: "long",
    year: "numeric"
  };
  return n && (r = n), new Intl.DateTimeFormat(e, r).format(t);
}, m = (e, t) => {
  const n = e.getFullYear();
  return Math.floor(n / t) * t;
}, O = (e, t) => {
  const n = e.getFullYear(), r = t + 9;
  return n >= t && n <= r;
}, I = (e, t, n, r) => {
  if (!e || !t)
    return !1;
  const o = e.getFullYear(), s = t.getFullYear();
  if (n && r) {
    const g = a(
      new Date(o, 0, 1),
      new Date(n, 0, 1),
      new Date(r, 11, 31)
    ), l = a(
      new Date(s, 11, 31),
      new Date(n, 0, 1),
      new Date(r, 11, 31)
    );
    return g && l;
  }
  return !0;
};
export {
  i as Views,
  c as WeekStart,
  u as addDays,
  F as addMonths,
  M as addYears,
  D as dateToJSONLocal,
  h as getFirstDateInRange,
  T as getFirstDayOfTheMonth,
  w as getFormattedDate,
  Y as getWeekDays,
  y as isDateEqual,
  O as isDateInDecade,
  a as isDateInRange,
  I as isDateRangeInDecade,
  f as isValidDate,
  m as startOfYearPeriod
};
