import { env } from '@/utils/env';
import { AwsRumProvider } from 'aws-rum-react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { QueryClientProvider } from '@tanstack/react-query';

import * as duckdb from '@duckdb/duckdb-wasm';
import coi_pthread_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-coi.pthread.worker.js?url';
import coi_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-coi.worker.js?url';
import eh_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js?url';
import mvp_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js?url';
import duckdb_wasm_coi from '@duckdb/duckdb-wasm/dist/duckdb-coi.wasm?url';
import duckdb_wasm_eh from '@duckdb/duckdb-wasm/dist/duckdb-eh.wasm?url';
import duckdb_wasm from '@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm?url';

import '@analytical-alley/ui/index.css';
import './index.css';

import { App } from '@/App';
import { AuthProvider } from '@/features/Auth';
import { queryClient } from '@/queryClient';
import { DuckDBProvider } from '@analytical-alley/duckdb-react';

const container = document.getElementById('root');
if (!container) throw new Error('Root element not found!');

const root = createRoot(container);

const logger = new duckdb.ConsoleLogger(duckdb.LogLevel.DEBUG);

async function enableMocking() {
    /*if (process.env.NODE_ENV !== 'development') {
        return;
    }*/

    const { worker } = await import('./mocks/browser');

    return worker.start();
}

const DUCKDB_BUNDLES: duckdb.DuckDBBundles = {
    mvp: {
        mainModule: duckdb_wasm,
        mainWorker: mvp_worker,
    },
    eh: {
        mainModule: duckdb_wasm_eh,
        mainWorker: eh_worker,
    },
    coi: {
        mainModule: duckdb_wasm_coi,
        mainWorker: coi_worker,
        pthreadWorker: coi_pthread_worker,
    },
};

enableMocking().then(() =>
    root.render(
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <AwsRumProvider
                    allowCookies
                    enableXRay
                    id={env.VITE_RUM_APPLICATION_ID}
                    identityPoolId={env.VITE_RUM_IDENTITY_POOL_ID}
                    endpoint={env.VITE_RUM_ENDPOINT}
                    region={env.VITE_RUM_APPLICATION_REGION}
                    version={env.VITE_RUM_APPLICATION_VERSION}
                    sessionSampleRate={1}
                    telemetries={['performance', 'errors', 'http']}
                >
                    <DuckDBProvider logger={logger} bundles={DUCKDB_BUNDLES}>
                        <AuthProvider>
                            <App />
                        </AuthProvider>
                    </DuckDBProvider>
                </AwsRumProvider>
            </QueryClientProvider>
        </StrictMode>,
    ),
);
