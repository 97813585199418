import { useAuth } from '@/config/auth';
import { previewDashboardSearchSchema } from '@/config/commonSearch';
import { FullPageLoader } from '@analytical-alley/ui';
import { createFileRoute, Outlet } from '@tanstack/react-router';

const OrgLayout = () => {
    const { isLoading, isAuthenticated, error } = useAuth({
        tryAutoSignIn: true,
        redirectToLoginOnError: true,
    });

    if (isLoading) {
        return <FullPageLoader />;
    }

    if (error) {
        return <div>{error.message}</div>;
    }

    if (!isAuthenticated) {
        return <div>You have to be logged in to access this page!</div>;
    }

    return <Outlet />;
};

export const Route = createFileRoute('/_org')({
    component: OrgLayout,
    validateSearch: previewDashboardSearchSchema,
});
