import { FullScreenButton } from '@/components/FullScreen/FullScreenButton';
import { useFullScreen } from '@/components/FullScreen/FullScreenProvider';
import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useRef,
} from 'react';
import { createPortal } from 'react-dom';

export interface FullScreenProps {
    fullscreenEnabled: boolean;
    isFullScreen: boolean;
    setIsFullScreen: Dispatch<SetStateAction<boolean>>;
    fullScreenButton: ReactNode;
    sectionId: string;
}

interface WithFullScreenOptions {
    fullScreenDisabled?: boolean;
}

export function withFullScreen<T extends React.ComponentType<FullScreenProps>>(
    sectionId: string,
    WrappedComponent: T,
    options: WithFullScreenOptions = { fullScreenDisabled: false },
): React.FC<Omit<React.ComponentProps<T>, keyof FullScreenProps>> &
    FullScreenProps {
    // Maybe can be typed better but not important for usage at the moment
    // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
    return ((props: any) => {
        const {
            isSectionFullScreen,
            isFullScreen,
            setIsFullScreen,
            enabled,
            portalRef,
        } = useFullScreen(sectionId);

        const setFullScreenRef = useRef(setIsFullScreen);
        const wasFullScreenRef = useRef(isSectionFullScreen);

        useEffect(() => {
            wasFullScreenRef.current = isSectionFullScreen;
        }, [isSectionFullScreen]);

        useEffect(() => {
            setFullScreenRef.current = setIsFullScreen;
        }, [setIsFullScreen]);

        useEffect(() => {
            return () => {
                if (wasFullScreenRef.current) {
                    // Exit fullscreen mode when component unmounts
                    setFullScreenRef.current(false);
                }
            };
        }, []);

        const isFullScreenDisabled = options.fullScreenDisabled;

        const FullScreenButtonComponent = !isFullScreenDisabled ? (
            <FullScreenButton
                isFullScreen={isFullScreen}
                onClick={() => {
                    setIsFullScreen(!isFullScreen);
                }}
            />
        ) : null;

        const WrappedComponentWithProps = (
            <WrappedComponent
                {...props}
                fullScreenButton={FullScreenButtonComponent}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
            />
        );

        // If the portal ref is not set or full screen is disabled, just render the regular component
        if (!portalRef.current || (!enabled && options.fullScreenDisabled)) {
            return (
                <div id={sectionId} data-fs={!options.fullScreenDisabled}>
                    <WrappedComponent {...props} />
                </div>
            );
        }

        // If the section id is not the current full screen section and the section is not full screen, hide section
        // Not rendering null here because we can leverage react's diffing algorithm to avoid
        // re-rendering the component from scratch when full screen is toggled off
        if (!isSectionFullScreen && isFullScreen) {
            return (
                <div style={{ display: 'none' }} id={sectionId} data-fs={false}>
                    <WrappedComponent {...props} />
                </div>
            );
        }

        if (isSectionFullScreen) {
            return (
                <div id={sectionId} data-fs={true} className={'w-full'}>
                    {createPortal(WrappedComponentWithProps, portalRef.current)}
                </div>
            );
        }

        return (
            <div id={sectionId} data-fs={true}>
                {WrappedComponentWithProps}
            </div>
        );
    }) as unknown as React.FC<
        Omit<React.ComponentProps<T>, keyof FullScreenProps>
    > &
        FullScreenProps;
}
