import { getDbInstance as c } from "./instance.js";
import { arrowToArray as u } from "../utils/arrowToArray.js";
import { measureExecutionTime as s } from "../utils/measureExecutionTime.js";
const $ = async () => {
  const n = await c().connect();
  async function e(o, t = !1) {
    const a = await n.query(o);
    return t ? u(a) : a;
  }
  async function r() {
    await n.close();
  }
  return { query: e, close: r };
}, m = async (n, e) => {
  const r = await n.connect(), o = await r.query(e);
  return await r.close(), o;
}, y = async (n, e) => {
  const r = await n.connect(), o = await Promise.all(e.map((t) => r.query(t)));
  return await r.close(), o;
};
class i extends Error {
  constructor(e, r) {
    super(`Error running query:
        ------------------------
        ${r}
        ------------------------
        ${e.message}`), this.name = "QueryError";
  }
}
async function l(n, e) {
  try {
    const r = await s(m, {
      message: `Running query: 
        ------------------------
        ${n}
        ------------------------
        `,
      onTime: ({ timeMs: o }) => console.log(`Query 
> ${n} <
 took: ${o}ms`)
    })(c(), n);
    return s(u, {
      message: `Transforming 
        ------------------------
        ${n}
        ------------------------ 
        result`,
      onTime: ({ timeMs: o }) => console.log(
        `Transformation of query
                ------------------------
                ${n}
                ------------------------
                took: ${o}ms`
      )
    })(r, e);
  } catch (r) {
    throw new i(r, n);
  }
}
async function T(n, e = []) {
  try {
    const r = await s(y, {
      message: `Running queries 
------------------------ 
${n.join(`
`)} 
------------------------`,
      onTime: ({ timeMs: o }) => console.log(
        `Queries 
------------------------ 
${n.join(`
`)} 
------------------------ 
took: ${o}ms`
      )
    })(c(), n);
    return await Promise.all(
      r.map(
        (o, t) => s(u, {
          message: `Transforming query 
------------------------ 
${n[t]} 
------------------------ 
result`,
          onTime: ({ timeMs: a }) => console.log(
            `Transformation of query 
------------------------ 
${n[t]} 
------------------------ 
took: ${a}ms`
          )
        })(o, e[t])
      )
    );
  } catch (r) {
    throw new i(r, n.join(`
`));
  }
}
export {
  $ as createDBConnection,
  T as runQueries,
  l as runQuery
};
