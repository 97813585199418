import { ROICurvesToolipParams } from '@/dashboards/BudgetOptimization/components/ROICurves/tooltips/renderTooltip';
import { Typography } from '@analytical-alley/ui';
import React from 'react';

export const GlobalTooltip = ({
    params,
    formatter,
}: {
    params: ROICurvesToolipParams;
    formatter?: (value: string | undefined | number) => string;
}) => {
    return (
        <div className="tooltip-content-md">
            <div className="flex">
                <Typography className="text-black dark:text-black mr-2">
                    Investment:
                </Typography>
                <Typography className="text-dark dark:text-dark">
                    {formatter
                        ? formatter(params[0]?.axisValueLabel)
                        : params[0]?.axisValueLabel}
                </Typography>
            </div>
            <span className="h-0.5 w-full bg-gray-100 dark:bg-gray-300 opacity-50 my-2" />
            <table>
                <thead>
                    <tr>
                        <th className="text-start">
                            <Typography className="text-black dark:text-black mr-2">
                                Variable
                            </Typography>
                        </th>
                        <th className="text-start">
                            <Typography className="text-black dark:text-black mr-2">
                                Contribution
                            </Typography>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {params.map((param) => (
                        <tr key={param.value}>
                            <td className="pr-3">
                                <div className="flex items-center">
                                    <div
                                        style={{
                                            aspectRatio: '1',
                                            backgroundColor:
                                                param.color || 'transparent',
                                        }}
                                        className="w-2.5 h-2.5 rounded-full me-1.5"
                                    />
                                    <Typography className="text-black dark:text-black">
                                        {param.seriesName}
                                    </Typography>
                                </div>
                            </td>
                            <td>
                                <Typography className="text-dark dark:text-dark">
                                    {formatter
                                        ? formatter(param.value)
                                        : param.value}
                                </Typography>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
