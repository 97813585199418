import { jsx as o, jsxs as g } from "react/jsx-runtime";
import { forwardRef as A, useId as E, useMemo as R, Children as M, useRef as $, useState as j, useEffect as C, useImperativeHandle as L } from "react";
import { t as n } from "../../bundle-mjs-3rKE4aij.js";
import { tabsTheme as K } from "./theme.js";
const v = ({ children: i, className: l }) => /* @__PURE__ */ o("div", { className: l, children: i });
v.displayName = "TabButtons.Item";
const I = A(
  ({
    children: i,
    className: l,
    onActiveTabChange: f,
    style: u = "default",
    activeTab: b,
    isLoading: T,
    ...k
  }, N) => {
    const s = K, p = E(), h = R(
      () => M.map(
        M.toArray(i),
        ({ props: t }) => t
      ),
      [i]
    ), y = $([]), [a, r] = j(-1), m = (t) => {
      f && f(t);
    }, w = ({ target: t }) => {
      m(t), r(t);
    }, B = ({
      event: t,
      target: e
    }) => {
      t.key === "ArrowLeft" && r(Math.max(0, a - 1)), t.key === "ArrowRight" && r(Math.min(h.length - 1, a + 1)), t.key === "Enter" && (m(e), r(e));
    }, c = s.tablist.tabitem.styles[u];
    return C(() => {
      var t;
      (t = y.current[a]) == null || t.focus();
    }, [a]), L(N, () => ({
      setActiveTab: m
    })), /* @__PURE__ */ o("div", { className: n(s.base, l), children: /* @__PURE__ */ o(
      "div",
      {
        "aria-label": "Tabs",
        role: "tablist",
        className: n(
          s.tablist.base,
          s.tablist.styles[u],
          l
        ),
        ...k,
        children: h.map((t, e) => T ? /* @__PURE__ */ o(
          "div",
          {
            className: n(
              s.tablist.tabitem.base,
              c.base,
              "animate-pulse glass-tile"
            ),
            children: /* @__PURE__ */ o("span", { style: { visibility: "hidden" }, children: t.title })
          },
          e
        ) : /* @__PURE__ */ g(
          "button",
          {
            type: "button",
            "aria-controls": `${p}-tabpanel-${e}`,
            "aria-selected": e === b,
            className: n(
              s.tablist.tabitem.base,
              c.base,
              e === b && c.active.on,
              e !== b && !t.disabled && c.active.off
            ),
            disabled: t.disabled,
            id: `${p}-tab-${e}`,
            onClick: () => w({ target: e }),
            onKeyDown: (d) => B({ event: d, target: e }),
            onMouseEnter: t.onMouseEnter,
            onMouseLeave: t.onMouseLeave,
            ref: (d) => y.current[e] = d,
            role: "tab",
            tabIndex: e === a ? 0 : -1,
            style: { zIndex: e === a ? 2 : 1 },
            children: [
              t.icon && /* @__PURE__ */ o(
                t.icon,
                {
                  className: s.tablist.tabitem.icon
                }
              ),
              t.title
            ]
          },
          e
        ))
      }
    ) });
  }
);
I.displayName = "TabButtons";
const H = Object.assign(I, {
  Item: v
});
export {
  v as Button,
  H as TabButtons
};
