import { KEY_EVENT_KEYS } from '@/config/constants';
import { Typography } from '@analytical-alley/ui';

export type ToggleLegendItem = {
    name: string;
    selected: boolean;
    color?: string;
};

export const ToggleLegend = ({
    legendItems,
    onSelect,
}: {
    legendItems: ToggleLegendItem[];
    onSelect: (name: string, selected: boolean) => void;
}) => {
    return (
        <div className="flex align-middle flex-col justify-center">
            {legendItems.map((item) => {
                return (
                    <div
                        role="button"
                        tabIndex={0}
                        key={item.name}
                        onKeyDown={(e) => {
                            if (
                                e.key === KEY_EVENT_KEYS.SPACE ||
                                e.key === KEY_EVENT_KEYS.ENTER
                            ) {
                                onSelect(item.name, !item.selected);
                            }
                        }}
                        className="flex items-center cursor-pointer"
                        onClick={() => onSelect(item.name, !item.selected)}
                    >
                        <div
                            style={{
                                backgroundColor: item.selected
                                    ? item.color || 'transparent'
                                    : 'gray',
                            }}
                            className="w-2.5 h-2.5 rounded-full me-1.5"
                        />
                        <Typography className="m-0" variant="bodyXS">
                            {item.name}
                        </Typography>
                    </div>
                );
            })}
        </div>
    );
};
