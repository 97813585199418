import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { DEFAULT_GC_TIME } from '@/config';
import { DEFAULT_PERIOD } from '@/config/model';
import { modelVsActualQueries } from '@/duckdb/model-vs-actual';
import { statisticsIndicatorsQueries } from '@/duckdb/statistics-indicators';
import { getMinAndMax } from '@/utils/mathUtils';
import { GetModelVsActualData, ModelVsActualQueryArgs } from '../types';

export const fetchModelVsActual = async ({
    period = DEFAULT_PERIOD,
}: ModelVsActualQueryArgs): Promise<GetModelVsActualData> => {
    const [modelData, actualData] = await Promise.all([
        modelVsActualQueries.getRows({
            type: 'model',
            period,
        }),
        modelVsActualQueries.getRows({
            type: 'actual',
            period,
        }),
    ]);

    const { min, max } = getMinAndMax(modelData, 'residual');

    return {
        period: period,
        modelData,
        actualData,
        minResidual: min,
        maxResidual: max,
    };
};

export const modelVsActualQueryOptions = (args: ModelVsActualQueryArgs) => {
    const { period } = args;
    return queryOptions({
        queryKey: ['modelVsActual', period],
        queryFn: () => fetchModelVsActual(args),
        placeholderData: keepPreviousData,
        staleTime: Infinity,
        gcTime: DEFAULT_GC_TIME,
    });
};

export const fetchStatistics = async () => {
    return await statisticsIndicatorsQueries.getStatistics();
};

export const statisticsQueryOptions = () =>
    queryOptions({
        queryKey: ['statistics'],
        queryFn: () => fetchStatistics(),
    });
