import { TabButtons } from '@analytical-alley/ui';

import { useModelVsActual } from '@/dashboards/TitleDashboard/hooks';
import { capitalizeFirstLetter } from '@/utils/stringUtils';

import { WithLoader } from '@/components/WithLoader';

import { useMemo } from 'react';
import { ModelVsActualGraph } from '../components/ModelVsActualGraph';

export const ModelVsActual = () => {
    const {
        queryResult: { data: modelVsActualData, isLoading, isFetching },
        activeTabIndex,
        availablePeriodOptions,
        getMouseHandlers,
        onActiveTabChange,
    } = useModelVsActual();

    const residual = useMemo(() => {
        return {
            maxResidual: modelVsActualData?.maxResidual || 0,
            minResidual: modelVsActualData?.minResidual || 0,
            colors: {
                onlyPositive: {
                    max: '#02793c',
                    min: '#8bd7af',
                },
                onlyNegative: {
                    max: '#F93C5E',
                    min: '#b40424',
                },
                both: {
                    max: '#73EFAC',
                    min: '#F93C5E',
                },
            },
        };
    }, [modelVsActualData?.maxResidual, modelVsActualData?.minResidual]);

    return (
        <>
            <TabButtons
                aria-label="Periodicity"
                style="pills"
                className="gap-2 min-[1858px]:-translate-y-4 max-[1858px]:mt-2 px-2 mb-2"
                onActiveTabChange={onActiveTabChange}
                isLoading={isLoading}
                activeTab={activeTabIndex}
            >
                {availablePeriodOptions?.map((periodName) => {
                    return (
                        <TabButtons.Item
                            {...getMouseHandlers(periodName)}
                            key={periodName}
                            title={capitalizeFirstLetter(periodName)}
                            disabled={isLoading}
                        />
                    );
                })}
            </TabButtons>
            <WithLoader
                className="glass-tile relative backdrop-filter-none min-h-80 pl-6 pt-2 flex text-base rounded-2xl max-lg:max-w-full"
                isLoading={isLoading}
                isFetching={isFetching}
            >
                <ModelVsActualGraph
                    isLoading={isLoading}
                    className={isFetching ? 'opacity-50' : undefined}
                    residual={residual}
                    data={modelVsActualData}
                />
            </WithLoader>
        </>
    );
};
