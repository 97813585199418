import { createContext as o, useContext as e } from "react";
const n = o(null), p = () => {
  const t = e(n);
  if (t == null)
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  return t;
};
export {
  n as TooltipContext,
  p as useTooltipContext
};
