import { jsx as r } from "react/jsx-runtime";
import { DuckDBConnectionProvider as D } from "./connectionProvider.js";
import { DuckDBBaseProvider as c } from "./databaseProvider.js";
import { D as n } from "../platformProvider-OWqRxLhx.js";
const B = ({
  logger: o,
  children: i,
  bundles: e,
  enableProgress: m,
  config: t
}) => /* @__PURE__ */ r(n, { logger: o, bundles: e, children: /* @__PURE__ */ r(c, { config: t, enableProgress: m, children: /* @__PURE__ */ r(D, { children: i }) }) });
export {
  B as DuckDBProvider
};
