import {
    BaseChart,
    ChartMouseHandler,
    EChartsOption,
    useChart,
} from '@/components/ECharts';
import { useCompetitorsMediaInvestmentContext } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentContext';
import { CompetitorImpact } from '@/dashboards/CompetitorsMediaInvestment/providers/CompetitorsMediaInvestmentProvider';
import { formatNumber } from '@/utils/numberUtils';
import { jsxToHtml } from '@/utils/reactUtils';
import { Typography } from '@analytical-alley/ui';
import { BarSeriesOption } from 'echarts';
import EChartsReact from 'echarts-for-react';
import React, {
    Ref,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

interface GraphProps {
    chartRef?: Ref<EChartsReact | null>;
    selected?: string;
    onSelected?: (value: string) => void;
    getChartOptions?: (chartOptionSeries: ChartOptions['series']) => void;
}

type AreaTooltipProps = {
    seriesName: string;
    color: string;
    value: number;
    axisValueLabel: string;
}[];

type ChartOptions = EChartsOption<BarSeriesOption>;

const currencyFormat = Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'EUR',
    notation: 'compact',
});

const AreaTooltip = ({
    params,
    selectedSeriesName,
}: {
    params: AreaTooltipProps;
    selectedSeriesName?: string;
}) => {
    if (!selectedSeriesName) {
        return null;
    }

    const hoveredItem = params.find(
        (param) => param.seriesName === selectedSeriesName,
    );

    if (!hoveredItem) {
        return null;
    }

    return (
        <div className="tooltip-content">
            <Typography className="text-violet-950 dark:text-violet-950 font-medium font-sans">
                {params[0]?.axisValueLabel} effect on your revenue
            </Typography>
            <Typography className="text-violet-950 dark:text-violet-950 font-medium">
                {formatNumber(hoveredItem.value)} €
            </Typography>
        </div>
    );
};

const getGraphData = ({
    seriesData,
    xAxisData,
}: {
    xAxisData: string[];
    seriesData: {
        variable: string;
        category: string;
        color: string;
        competitorImpactToClient: CompetitorImpact;
        value: number | undefined;
    }[];
    period: string | undefined;
}): ChartOptions => {
    const totalInvestmentSum = seriesData.reduce(
        (acc, obj) => acc + Math.abs(obj.value!),
        0,
    );
    const series: ChartOptions['series'] = [
        {
            name: 'Competitor',
            type: 'bar',
            stack: 'Total',
            color: '',
            barMaxWidth: 200,
            data: seriesData.map(({ variable, value, color }) => ({
                name: variable,
                value,
                label: {
                    show: true,
                    position: value && value > 0 ? 'right' : 'left',
                    color: '#fff',
                    formatter: () =>
                        `${Math.round((value! / totalInvestmentSum) * 100)}%`,
                },
                itemStyle: {
                    color,
                    borderRadius:
                        value && value > 0 ? [0, 5, 5, 0] : [5, 0, 0, 5],
                },
            })),
        },
    ];

    return {
        animation: false,
        tooltip: {
            show: false,
            trigger: 'axis',
            triggerOn: 'mousemove|click',
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            type: 'item',
            confine: true,
            formatter: '',
        },
        legend: {
            show: false,
        },
        grid: { right: '2%', left: '10%' },
        xAxis: [
            {
                type: 'value',
                position: 'bottom',
                boundaryGap: true,
                show: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.5,
                    },
                },
                axisLabel: {
                    formatter: (value: number) =>
                        currencyFormat.format(Number(value)),
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'solid',
                        opacity: 0.5,
                    },
                },
            },
        ],
        yAxis: [
            {
                type: 'category',
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: false },
                data: xAxisData,
            },
        ],
        series: series,
    };
};

export const CompetitorMediaEffectOnClientRevenueGraph = memo(
    ({ selected }: GraphProps) => {
        const {
            competitorsMediaInvestmentData: {
                variableData,
                totalInvestmentByVariable,
            },
            period,
        } = useCompetitorsMediaInvestmentContext();

        const eChartsOptions = useMemo(() => {
            //TODO: Remove hardcoded client name from filter
            const effectToClientData = variableData.filter((obj) => {
                return (
                    (obj.competitorImpactToClient === 'negative' ||
                        obj.competitorImpactToClient === 'positive') &&
                    obj.variable !== 'IKEA'
                );
            });
            return getGraphData({
                seriesData: effectToClientData.map((obj) => ({
                    ...obj,
                    value:
                        obj.competitorImpactToClient === 'positive'
                            ? totalInvestmentByVariable.get(obj.variable)
                            : -totalInvestmentByVariable.get(obj.variable)!,
                })),
                xAxisData: effectToClientData.map((obj) => obj.variable),
                period: period,
            });
        }, [period, totalInvestmentByVariable, variableData]);

        const [hovered, setHovered] = useState('');

        const selectedRef = useRef(selected);
        const timeoutRef = useRef<number | null>(null);

        useEffect(() => {
            selectedRef.current = selected;
        }, [selected]);

        const onMouseOver: ChartMouseHandler = useCallback((context) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (context.seriesName) {
                setHovered(
                    selectedRef.current
                        ? selectedRef.current
                        : context.seriesName,
                );
            }
        }, []);

        const onMouseOut: ChartMouseHandler = useCallback(() => {
            if (selectedRef.current) {
                return;
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = window.setTimeout(() => {
                setHovered('');
            }, 0);
        }, []);

        const { ref, chartOptions, onEvents } = useChart({
            chartOptions: eChartsOptions,
            onMouseOver,
            onMouseOut,
        });

        useEffect(() => {
            const instance = ref.current?.getEchartsInstance();

            if (instance) {
                instance.setOption({
                    tooltip: {
                        showDelay: selected ? 0 : 50,
                        show: true,
                        formatter: (params: AreaTooltipProps) => {
                            return jsxToHtml(
                                <AreaTooltip
                                    params={params}
                                    selectedSeriesName={hovered}
                                />,
                            );
                        },
                    },
                });
            }
        }, [ref, hovered, selected]);

        return (
            <div className="relative w-full h-[47.5rem]">
                <BaseChart
                    $shouldExpand
                    option={chartOptions}
                    ref={ref}
                    onEvents={onEvents}
                />
            </div>
        );
    },
);

CompetitorMediaEffectOnClientRevenueGraph.displayName =
    'CompetitorMediaEffectOnClientRevenueGraph';
