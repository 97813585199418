import { jsx as d } from "react/jsx-runtime";
import { updateDBInstance as k } from "../db/instance.js";
import { Resolvable as p } from "../utils/resolvable.js";
import { u as w, a as D, O as x } from "../platformProvider-OWqRxLhx.js";
import t from "react";
const f = t.createContext(null), h = t.createContext(null), y = () => t.useContext(f), P = () => t.useContext(h), S = (o) => {
  const i = w(), m = D(), [v, u] = t.useState(new p()), c = t.useRef(null);
  t.useEffect(
    () => () => {
      c.current != null && (c.current.terminate(), c.current = null);
    },
    []
  );
  const l = t.useRef(null), g = t.useCallback(async () => l.current ? await l.current : (l.current = (async () => {
    const a = await m();
    if (a == null)
      return u((e) => e.failWith("Failed to resolve bundle")), null;
    let s, n;
    try {
      s = new Worker(a.mainWorker), n = new x(i, s);
    } catch (e) {
      return u((r) => r.failWith(e)), null;
    }
    try {
      await n.instantiate(
        a.mainModule,
        a.pthreadWorker,
        o.enableProgress ? (e) => {
          try {
            u((r) => r.updateRunning(e));
          } catch (r) {
            console.warn(
              `progress handler failed with error: ${r.toString()}`
            );
          }
        } : void 0
      ), o.config !== void 0 && await n.open(o.config);
    } catch (e) {
      return u((r) => r.failWith(e)), null;
    }
    return k(n), u((e) => e.completeWith(n)), n;
  })(), l.current), [i]);
  return /* @__PURE__ */ d(h.Provider, { value: g, children: /* @__PURE__ */ d(f.Provider, { value: v, children: o.children }) });
};
export {
  S as DuckDBBaseProvider,
  P as useDuckDBResolver,
  y as useDuckDBSetupContext
};
