import be from "axios";
import { useState as G, useEffect as Q } from "react";
import { useAuth as ke, hasAuthParams as Ee } from "react-oidc-context";
const Te = ({
  loginRoutePath: e,
  redirectSearchParamName: t,
  searchParamsToPersist: s,
  onNavigate: i
}) => (r) => {
  const n = ke(), [o, a] = G(!1), [c, l] = G(
    (r == null ? void 0 : r.tryAutoSignIn) || !1
  );
  return Q(() => {
    async function g() {
      r != null && r.tryAutoSignIn && !Ee() && !n.isAuthenticated && !n.activeNavigator && !n.isLoading && !o && !n.error && (await n.signinRedirect({
        redirect_uri: window.location.href
      }), a(!0));
    }
    g().then(() => {
      l(!1);
    });
  }, [n, o, r == null ? void 0 : r.tryAutoSignIn]), Q(() => {
    n.error && (r != null && r.redirectToLoginOnError) && i(
      pe({
        loginRoutePath: (r == null ? void 0 : r.loginRoutePath) || e,
        redirectSearchParamName: (r == null ? void 0 : r.redirectSearchParamName) || t,
        searchParamsToPersist: s
      })
    );
  }, [
    n.error,
    r == null ? void 0 : r.loginRoutePath,
    r == null ? void 0 : r.redirectSearchParamName,
    r == null ? void 0 : r.redirectToLoginOnError
  ]), {
    ...n,
    isLoading: n.isLoading || c
  };
};
class O extends Error {
}
O.prototype.name = "InvalidTokenError";
function Re(e) {
  return decodeURIComponent(atob(e).replace(/(.)/g, (t, s) => {
    let i = s.charCodeAt(0).toString(16).toUpperCase();
    return i.length < 2 && (i = "0" + i), "%" + i;
  }));
}
function Ie(e) {
  let t = e.replace(/-/g, "+").replace(/_/g, "/");
  switch (t.length % 4) {
    case 0:
      break;
    case 2:
      t += "==";
      break;
    case 3:
      t += "=";
      break;
    default:
      throw new Error("base64 string is not of the correct length");
  }
  try {
    return Re(t);
  } catch {
    return atob(t);
  }
}
function xe(e, t) {
  if (typeof e != "string")
    throw new O("Invalid token specified: must be a string");
  t || (t = {});
  const s = t.header === !0 ? 0 : 1, i = e.split(".")[s];
  if (typeof i != "string")
    throw new O(`Invalid token specified: missing part #${s + 1}`);
  let r;
  try {
    r = Ie(i);
  } catch (n) {
    throw new O(`Invalid token specified: invalid base64 for part #${s + 1} (${n.message})`);
  }
  try {
    return JSON.parse(r);
  } catch (n) {
    throw new O(`Invalid token specified: invalid json for part #${s + 1} (${n.message})`);
  }
}
var Ue = {
  debug: () => {
  },
  info: () => {
  },
  warn: () => {
  },
  error: () => {
  }
}, b, k, j = /* @__PURE__ */ ((e) => (e[e.NONE = 0] = "NONE", e[e.ERROR = 1] = "ERROR", e[e.WARN = 2] = "WARN", e[e.INFO = 3] = "INFO", e[e.DEBUG = 4] = "DEBUG", e))(j || {});
((e) => {
  function t() {
    b = 3, k = Ue;
  }
  e.reset = t;
  function s(r) {
    if (!(0 <= r && r <= 4))
      throw new Error("Invalid log level");
    b = r;
  }
  e.setLevel = s;
  function i(r) {
    k = r;
  }
  e.setLogger = i;
})(j || (j = {}));
var h = class y {
  constructor(t) {
    this._name = t;
  }
  /* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
  debug(...t) {
    b >= 4 && k.debug(y._format(this._name, this._method), ...t);
  }
  info(...t) {
    b >= 3 && k.info(y._format(this._name, this._method), ...t);
  }
  warn(...t) {
    b >= 2 && k.warn(y._format(this._name, this._method), ...t);
  }
  error(...t) {
    b >= 1 && k.error(y._format(this._name, this._method), ...t);
  }
  /* eslint-enable @typescript-eslint/no-unsafe-enum-comparison */
  throw(t) {
    throw this.error(t), t;
  }
  create(t) {
    const s = Object.create(this);
    return s._method = t, s.debug("begin"), s;
  }
  static createStatic(t, s) {
    const i = new y(`${t}.${s}`);
    return i.debug("begin"), i;
  }
  static _format(t, s) {
    const i = `[${t}]`;
    return s ? `${i} ${s}:` : i;
  }
  /* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
  // helpers for static class methods
  static debug(t, ...s) {
    b >= 4 && k.debug(y._format(t), ...s);
  }
  static info(t, ...s) {
    b >= 3 && k.info(y._format(t), ...s);
  }
  static warn(t, ...s) {
    b >= 2 && k.warn(y._format(t), ...s);
  }
  static error(t, ...s) {
    b >= 1 && k.error(y._format(t), ...s);
  }
  /* eslint-enable @typescript-eslint/no-unsafe-enum-comparison */
};
j.reset();
var Ce = "10000000-1000-4000-8000-100000000000", X = (e) => btoa([...new Uint8Array(e)].map((t) => String.fromCharCode(t)).join("")), A = class q {
  static _randomWord() {
    const t = new Uint32Array(1);
    return crypto.getRandomValues(t), t[0];
  }
  /**
   * Generates RFC4122 version 4 guid
   */
  static generateUUIDv4() {
    return Ce.replace(
      /[018]/g,
      (s) => (+s ^ q._randomWord() & 15 >> +s / 4).toString(16)
    ).replace(/-/g, "");
  }
  /**
   * PKCE: Generate a code verifier
   */
  static generateCodeVerifier() {
    return q.generateUUIDv4() + q.generateUUIDv4() + q.generateUUIDv4();
  }
  /**
   * PKCE: Generate a code challenge
   */
  static async generateCodeChallenge(t) {
    if (!crypto.subtle)
      throw new Error("Crypto.subtle is available only in secure contexts (HTTPS).");
    try {
      const i = new TextEncoder().encode(t), r = await crypto.subtle.digest("SHA-256", i);
      return X(r).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
    } catch (s) {
      throw h.error("CryptoUtils.generateCodeChallenge", s), s;
    }
  }
  /**
   * Generates a base64-encoded string for a basic auth header
   */
  static generateBasicAuth(t, s) {
    const r = new TextEncoder().encode([t, s].join(":"));
    return X(r);
  }
}, I = class {
  constructor(e) {
    this._name = e, this._logger = new h(`Event('${this._name}')`), this._callbacks = [];
  }
  addHandler(e) {
    return this._callbacks.push(e), () => this.removeHandler(e);
  }
  removeHandler(e) {
    const t = this._callbacks.lastIndexOf(e);
    t >= 0 && this._callbacks.splice(t, 1);
  }
  async raise(...e) {
    this._logger.debug("raise:", ...e);
    for (const t of this._callbacks)
      await t(...e);
  }
}, W = class {
  // IMPORTANT: doesn't validate the token
  static decode(e) {
    try {
      return xe(e);
    } catch (t) {
      throw h.error("JwtUtils.decode", t), t;
    }
  }
}, Y = class {
  /**
   * Populates a map of window features with a placement centered in front of
   * the current window. If no explicit width is given, a default value is
   * binned into [800, 720, 600, 480, 360] based on the current window's width.
   */
  static center({ ...e }) {
    var t, s, i;
    return e.width == null && (e.width = (t = [800, 720, 600, 480].find((r) => r <= window.outerWidth / 1.618)) != null ? t : 360), (s = e.left) != null || (e.left = Math.max(0, Math.round(window.screenX + (window.outerWidth - e.width) / 2))), e.height != null && ((i = e.top) != null || (e.top = Math.max(0, Math.round(window.screenY + (window.outerHeight - e.height) / 2)))), e;
  }
  static serialize(e) {
    return Object.entries(e).filter(([, t]) => t != null).map(([t, s]) => `${t}=${typeof s != "boolean" ? s : s ? "yes" : "no"}`).join(",");
  }
}, R = class N extends I {
  constructor() {
    super(...arguments), this._logger = new h(`Timer('${this._name}')`), this._timerHandle = null, this._expiration = 0, this._callback = () => {
      const t = this._expiration - N.getEpochTime();
      this._logger.debug("timer completes in", t), this._expiration <= N.getEpochTime() && (this.cancel(), super.raise());
    };
  }
  // get the time
  static getEpochTime() {
    return Math.floor(Date.now() / 1e3);
  }
  init(t) {
    const s = this._logger.create("init");
    t = Math.max(Math.floor(t), 1);
    const i = N.getEpochTime() + t;
    if (this.expiration === i && this._timerHandle) {
      s.debug("skipping since already initialized for expiration at", this.expiration);
      return;
    }
    this.cancel(), s.debug("using duration", t), this._expiration = i;
    const r = Math.min(t, 5);
    this._timerHandle = setInterval(this._callback, r * 1e3);
  }
  get expiration() {
    return this._expiration;
  }
  cancel() {
    this._logger.create("cancel"), this._timerHandle && (clearInterval(this._timerHandle), this._timerHandle = null);
  }
}, L = class {
  static readParams(e, t = "query") {
    if (!e)
      throw new TypeError("Invalid URL");
    const i = new URL(e, "http://127.0.0.1")[t === "fragment" ? "hash" : "search"];
    return new URLSearchParams(i.slice(1));
  }
}, F = ";", P = class extends Error {
  constructor(e, t) {
    var s, i, r;
    if (super(e.error_description || e.error || ""), this.form = t, this.name = "ErrorResponse", !e.error)
      throw h.error("ErrorResponse", "No error passed"), new Error("No error passed");
    this.error = e.error, this.error_description = (s = e.error_description) != null ? s : null, this.error_uri = (i = e.error_uri) != null ? i : null, this.state = e.userState, this.session_state = (r = e.session_state) != null ? r : null, this.url_state = e.url_state;
  }
}, z = class extends Error {
  constructor(e) {
    super(e), this.name = "ErrorTimeout";
  }
}, Pe = class {
  constructor(e) {
    this._logger = new h("AccessTokenEvents"), this._expiringTimer = new R("Access token expiring"), this._expiredTimer = new R("Access token expired"), this._expiringNotificationTimeInSeconds = e.expiringNotificationTimeInSeconds;
  }
  load(e) {
    const t = this._logger.create("load");
    if (e.access_token && e.expires_in !== void 0) {
      const s = e.expires_in;
      if (t.debug("access token present, remaining duration:", s), s > 0) {
        let r = s - this._expiringNotificationTimeInSeconds;
        r <= 0 && (r = 1), t.debug("registering expiring timer, raising in", r, "seconds"), this._expiringTimer.init(r);
      } else
        t.debug("canceling existing expiring timer because we're past expiration."), this._expiringTimer.cancel();
      const i = s + 1;
      t.debug("registering expired timer, raising in", i, "seconds"), this._expiredTimer.init(i);
    } else
      this._expiringTimer.cancel(), this._expiredTimer.cancel();
  }
  unload() {
    this._logger.debug("unload: canceling existing access token timers"), this._expiringTimer.cancel(), this._expiredTimer.cancel();
  }
  /**
   * Add callback: Raised prior to the access token expiring.
   */
  addAccessTokenExpiring(e) {
    return this._expiringTimer.addHandler(e);
  }
  /**
   * Remove callback: Raised prior to the access token expiring.
   */
  removeAccessTokenExpiring(e) {
    this._expiringTimer.removeHandler(e);
  }
  /**
   * Add callback: Raised after the access token has expired.
   */
  addAccessTokenExpired(e) {
    return this._expiredTimer.addHandler(e);
  }
  /**
   * Remove callback: Raised after the access token has expired.
   */
  removeAccessTokenExpired(e) {
    this._expiredTimer.removeHandler(e);
  }
}, Ae = class {
  constructor(e, t, s, i, r) {
    this._callback = e, this._client_id = t, this._intervalInSeconds = i, this._stopOnError = r, this._logger = new h("CheckSessionIFrame"), this._timer = null, this._session_state = null, this._message = (o) => {
      o.origin === this._frame_origin && o.source === this._frame.contentWindow && (o.data === "error" ? (this._logger.error("error message from check session op iframe"), this._stopOnError && this.stop()) : o.data === "changed" ? (this._logger.debug("changed message from check session op iframe"), this.stop(), this._callback()) : this._logger.debug(o.data + " message from check session op iframe"));
    };
    const n = new URL(s);
    this._frame_origin = n.origin, this._frame = window.document.createElement("iframe"), this._frame.style.visibility = "hidden", this._frame.style.position = "fixed", this._frame.style.left = "-1000px", this._frame.style.top = "0", this._frame.width = "0", this._frame.height = "0", this._frame.src = n.href;
  }
  load() {
    return new Promise((e) => {
      this._frame.onload = () => {
        e();
      }, window.document.body.appendChild(this._frame), window.addEventListener("message", this._message, !1);
    });
  }
  start(e) {
    if (this._session_state === e)
      return;
    this._logger.create("start"), this.stop(), this._session_state = e;
    const t = () => {
      !this._frame.contentWindow || !this._session_state || this._frame.contentWindow.postMessage(this._client_id + " " + this._session_state, this._frame_origin);
    };
    t(), this._timer = setInterval(t, this._intervalInSeconds * 1e3);
  }
  stop() {
    this._logger.create("stop"), this._session_state = null, this._timer && (clearInterval(this._timer), this._timer = null);
  }
}, ie = class {
  constructor() {
    this._logger = new h("InMemoryWebStorage"), this._data = {};
  }
  clear() {
    this._logger.create("clear"), this._data = {};
  }
  getItem(e) {
    return this._logger.create(`getItem('${e}')`), this._data[e];
  }
  setItem(e, t) {
    this._logger.create(`setItem('${e}')`), this._data[e] = t;
  }
  removeItem(e) {
    this._logger.create(`removeItem('${e}')`), delete this._data[e];
  }
  get length() {
    return Object.getOwnPropertyNames(this._data).length;
  }
  key(e) {
    return Object.getOwnPropertyNames(this._data)[e];
  }
}, B = class {
  constructor(e = [], t = null, s = {}) {
    this._jwtHandler = t, this._extraHeaders = s, this._logger = new h("JsonService"), this._contentTypes = [], this._contentTypes.push(...e, "application/json"), t && this._contentTypes.push("application/jwt");
  }
  async fetchWithTimeout(e, t = {}) {
    const { timeoutInSeconds: s, ...i } = t;
    if (!s)
      return await fetch(e, i);
    const r = new AbortController(), n = setTimeout(() => r.abort(), s * 1e3);
    try {
      return await fetch(e, {
        ...t,
        signal: r.signal
      });
    } catch (o) {
      throw o instanceof DOMException && o.name === "AbortError" ? new z("Network timed out") : o;
    } finally {
      clearTimeout(n);
    }
  }
  async getJson(e, {
    token: t,
    credentials: s
  } = {}) {
    const i = this._logger.create("getJson"), r = {
      Accept: this._contentTypes.join(", ")
    };
    t && (i.debug("token passed, setting Authorization header"), r.Authorization = "Bearer " + t), this.appendExtraHeaders(r);
    let n;
    try {
      i.debug("url:", e), n = await this.fetchWithTimeout(e, { method: "GET", headers: r, credentials: s });
    } catch (c) {
      throw i.error("Network Error"), c;
    }
    i.debug("HTTP response received, status", n.status);
    const o = n.headers.get("Content-Type");
    if (o && !this._contentTypes.find((c) => o.startsWith(c)) && i.throw(new Error(`Invalid response Content-Type: ${o ?? "undefined"}, from URL: ${e}`)), n.ok && this._jwtHandler && (o != null && o.startsWith("application/jwt")))
      return await this._jwtHandler(await n.text());
    let a;
    try {
      a = await n.json();
    } catch (c) {
      throw i.error("Error parsing JSON response", c), n.ok ? c : new Error(`${n.statusText} (${n.status})`);
    }
    if (!n.ok)
      throw i.error("Error from server:", a), a.error ? new P(a) : new Error(`${n.statusText} (${n.status}): ${JSON.stringify(a)}`);
    return a;
  }
  async postForm(e, {
    body: t,
    basicAuth: s,
    timeoutInSeconds: i,
    initCredentials: r
  }) {
    const n = this._logger.create("postForm"), o = {
      Accept: this._contentTypes.join(", "),
      "Content-Type": "application/x-www-form-urlencoded"
    };
    s !== void 0 && (o.Authorization = "Basic " + s), this.appendExtraHeaders(o);
    let a;
    try {
      n.debug("url:", e), a = await this.fetchWithTimeout(e, { method: "POST", headers: o, body: t, timeoutInSeconds: i, credentials: r });
    } catch (u) {
      throw n.error("Network error"), u;
    }
    n.debug("HTTP response received, status", a.status);
    const c = a.headers.get("Content-Type");
    if (c && !this._contentTypes.find((u) => c.startsWith(u)))
      throw new Error(`Invalid response Content-Type: ${c ?? "undefined"}, from URL: ${e}`);
    const l = await a.text();
    let g = {};
    if (l)
      try {
        g = JSON.parse(l);
      } catch (u) {
        throw n.error("Error parsing JSON response", u), a.ok ? u : new Error(`${a.statusText} (${a.status})`);
      }
    if (!a.ok)
      throw n.error("Error from server:", g), g.error ? new P(g, t) : new Error(`${a.statusText} (${a.status}): ${JSON.stringify(g)}`);
    return g;
  }
  appendExtraHeaders(e) {
    const t = this._logger.create("appendExtraHeaders"), s = Object.keys(this._extraHeaders), i = [
      "authorization",
      "accept",
      "content-type"
    ];
    s.length !== 0 && s.forEach((r) => {
      if (i.includes(r.toLocaleLowerCase())) {
        t.warn("Protected header could not be overridden", r, i);
        return;
      }
      const n = typeof this._extraHeaders[r] == "function" ? this._extraHeaders[r]() : this._extraHeaders[r];
      n && n !== "" && (e[r] = n);
    });
  }
}, Oe = class {
  constructor(e) {
    this._settings = e, this._logger = new h("MetadataService"), this._signingKeys = null, this._metadata = null, this._metadataUrl = this._settings.metadataUrl, this._jsonService = new B(
      ["application/jwk-set+json"],
      null,
      this._settings.extraHeaders
    ), this._settings.signingKeys && (this._logger.debug("using signingKeys from settings"), this._signingKeys = this._settings.signingKeys), this._settings.metadata && (this._logger.debug("using metadata from settings"), this._metadata = this._settings.metadata), this._settings.fetchRequestCredentials && (this._logger.debug("using fetchRequestCredentials from settings"), this._fetchRequestCredentials = this._settings.fetchRequestCredentials);
  }
  resetSigningKeys() {
    this._signingKeys = null;
  }
  async getMetadata() {
    const e = this._logger.create("getMetadata");
    if (this._metadata)
      return e.debug("using cached values"), this._metadata;
    if (!this._metadataUrl)
      throw e.throw(new Error("No authority or metadataUrl configured on settings")), null;
    e.debug("getting metadata from", this._metadataUrl);
    const t = await this._jsonService.getJson(this._metadataUrl, { credentials: this._fetchRequestCredentials });
    return e.debug("merging remote JSON with seed metadata"), this._metadata = Object.assign({}, this._settings.metadataSeed, t), this._metadata;
  }
  getIssuer() {
    return this._getMetadataProperty("issuer");
  }
  getAuthorizationEndpoint() {
    return this._getMetadataProperty("authorization_endpoint");
  }
  getUserInfoEndpoint() {
    return this._getMetadataProperty("userinfo_endpoint");
  }
  getTokenEndpoint(e = !0) {
    return this._getMetadataProperty("token_endpoint", e);
  }
  getCheckSessionIframe() {
    return this._getMetadataProperty("check_session_iframe", !0);
  }
  getEndSessionEndpoint() {
    return this._getMetadataProperty("end_session_endpoint", !0);
  }
  getRevocationEndpoint(e = !0) {
    return this._getMetadataProperty("revocation_endpoint", e);
  }
  getKeysEndpoint(e = !0) {
    return this._getMetadataProperty("jwks_uri", e);
  }
  async _getMetadataProperty(e, t = !1) {
    const s = this._logger.create(`_getMetadataProperty('${e}')`), i = await this.getMetadata();
    if (s.debug("resolved"), i[e] === void 0) {
      if (t === !0) {
        s.warn("Metadata does not contain optional property");
        return;
      }
      s.throw(new Error("Metadata does not contain property " + e));
    }
    return i[e];
  }
  async getSigningKeys() {
    const e = this._logger.create("getSigningKeys");
    if (this._signingKeys)
      return e.debug("returning signingKeys from cache"), this._signingKeys;
    const t = await this.getKeysEndpoint(!1);
    e.debug("got jwks_uri", t);
    const s = await this._jsonService.getJson(t);
    if (e.debug("got key set", s), !Array.isArray(s.keys))
      throw e.throw(new Error("Missing keys on keyset")), null;
    return this._signingKeys = s.keys, this._signingKeys;
  }
}, re = class {
  constructor({
    prefix: e = "oidc.",
    store: t = localStorage
  } = {}) {
    this._logger = new h("WebStorageStateStore"), this._store = t, this._prefix = e;
  }
  async set(e, t) {
    this._logger.create(`set('${e}')`), e = this._prefix + e, await this._store.setItem(e, t);
  }
  async get(e) {
    return this._logger.create(`get('${e}')`), e = this._prefix + e, await this._store.getItem(e);
  }
  async remove(e) {
    this._logger.create(`remove('${e}')`), e = this._prefix + e;
    const t = await this._store.getItem(e);
    return await this._store.removeItem(e), t;
  }
  async getAllKeys() {
    this._logger.create("getAllKeys");
    const e = await this._store.length, t = [];
    for (let s = 0; s < e; s++) {
      const i = await this._store.key(s);
      i && i.indexOf(this._prefix) === 0 && t.push(i.substr(this._prefix.length));
    }
    return t;
  }
}, qe = "code", Ne = "openid", Me = "client_secret_post", je = 60 * 15, D = class {
  constructor({
    // metadata related
    authority: e,
    metadataUrl: t,
    metadata: s,
    signingKeys: i,
    metadataSeed: r,
    // client related
    client_id: n,
    client_secret: o,
    response_type: a = qe,
    scope: c = Ne,
    redirect_uri: l,
    post_logout_redirect_uri: g,
    client_authentication: u = Me,
    // optional protocol
    prompt: d,
    display: _,
    max_age: f,
    ui_locales: S,
    acr_values: v,
    resource: x,
    response_mode: U,
    // behavior flags
    filterProtocolClaims: m = !0,
    loadUserInfo: p = !1,
    staleStateAgeInSeconds: E = je,
    mergeClaimsStrategy: T = { array: "replace" },
    disablePKCE: w = !1,
    // other behavior
    stateStore: C,
    revokeTokenAdditionalContentTypes: fe,
    fetchRequestCredentials: V,
    refreshTokenAllowedScope: we,
    // extra
    extraQueryParams: me = {},
    extraTokenParams: Se = {},
    extraHeaders: ve = {}
  }) {
    if (this.authority = e, t ? this.metadataUrl = t : (this.metadataUrl = e, e && (this.metadataUrl.endsWith("/") || (this.metadataUrl += "/"), this.metadataUrl += ".well-known/openid-configuration")), this.metadata = s, this.metadataSeed = r, this.signingKeys = i, this.client_id = n, this.client_secret = o, this.response_type = a, this.scope = c, this.redirect_uri = l, this.post_logout_redirect_uri = g, this.client_authentication = u, this.prompt = d, this.display = _, this.max_age = f, this.ui_locales = S, this.acr_values = v, this.resource = x, this.response_mode = U, this.filterProtocolClaims = m ?? !0, this.loadUserInfo = !!p, this.staleStateAgeInSeconds = E, this.mergeClaimsStrategy = T, this.disablePKCE = !!w, this.revokeTokenAdditionalContentTypes = fe, this.fetchRequestCredentials = V || "same-origin", C)
      this.stateStore = C;
    else {
      const ye = typeof window < "u" ? window.localStorage : new ie();
      this.stateStore = new re({ store: ye });
    }
    this.refreshTokenAllowedScope = we, this.extraQueryParams = me, this.extraTokenParams = Se, this.extraHeaders = ve;
  }
}, He = class {
  constructor(e, t) {
    this._settings = e, this._metadataService = t, this._logger = new h("UserInfoService"), this._getClaimsFromJwt = async (s) => {
      const i = this._logger.create("_getClaimsFromJwt");
      try {
        const r = W.decode(s);
        return i.debug("JWT decoding successful"), r;
      } catch (r) {
        throw i.error("Error parsing JWT response"), r;
      }
    }, this._jsonService = new B(
      void 0,
      this._getClaimsFromJwt,
      this._settings.extraHeaders
    );
  }
  async getClaims(e) {
    const t = this._logger.create("getClaims");
    e || this._logger.throw(new Error("No token passed"));
    const s = await this._metadataService.getUserInfoEndpoint();
    t.debug("got userinfo url", s);
    const i = await this._jsonService.getJson(s, {
      token: e,
      credentials: this._settings.fetchRequestCredentials
    });
    return t.debug("got claims", i), i;
  }
}, ne = class {
  constructor(e, t) {
    this._settings = e, this._metadataService = t, this._logger = new h("TokenClient"), this._jsonService = new B(
      this._settings.revokeTokenAdditionalContentTypes,
      null,
      this._settings.extraHeaders
    );
  }
  /**
   * Exchange code.
   *
   * @see https://www.rfc-editor.org/rfc/rfc6749#section-4.1.3
   */
  async exchangeCode({
    grant_type: e = "authorization_code",
    redirect_uri: t = this._settings.redirect_uri,
    client_id: s = this._settings.client_id,
    client_secret: i = this._settings.client_secret,
    ...r
  }) {
    const n = this._logger.create("exchangeCode");
    s || n.throw(new Error("A client_id is required")), t || n.throw(new Error("A redirect_uri is required")), r.code || n.throw(new Error("A code is required"));
    const o = new URLSearchParams({ grant_type: e, redirect_uri: t });
    for (const [g, u] of Object.entries(r))
      u != null && o.set(g, u);
    let a;
    switch (this._settings.client_authentication) {
      case "client_secret_basic":
        if (!i)
          throw n.throw(new Error("A client_secret is required")), null;
        a = A.generateBasicAuth(s, i);
        break;
      case "client_secret_post":
        o.append("client_id", s), i && o.append("client_secret", i);
        break;
    }
    const c = await this._metadataService.getTokenEndpoint(!1);
    n.debug("got token endpoint");
    const l = await this._jsonService.postForm(c, { body: o, basicAuth: a, initCredentials: this._settings.fetchRequestCredentials });
    return n.debug("got response"), l;
  }
  /**
   * Exchange credentials.
   *
   * @see https://www.rfc-editor.org/rfc/rfc6749#section-4.3.2
   */
  async exchangeCredentials({
    grant_type: e = "password",
    client_id: t = this._settings.client_id,
    client_secret: s = this._settings.client_secret,
    scope: i = this._settings.scope,
    ...r
  }) {
    const n = this._logger.create("exchangeCredentials");
    t || n.throw(new Error("A client_id is required"));
    const o = new URLSearchParams({ grant_type: e, scope: i });
    for (const [g, u] of Object.entries(r))
      u != null && o.set(g, u);
    let a;
    switch (this._settings.client_authentication) {
      case "client_secret_basic":
        if (!s)
          throw n.throw(new Error("A client_secret is required")), null;
        a = A.generateBasicAuth(t, s);
        break;
      case "client_secret_post":
        o.append("client_id", t), s && o.append("client_secret", s);
        break;
    }
    const c = await this._metadataService.getTokenEndpoint(!1);
    n.debug("got token endpoint");
    const l = await this._jsonService.postForm(c, { body: o, basicAuth: a, initCredentials: this._settings.fetchRequestCredentials });
    return n.debug("got response"), l;
  }
  /**
   * Exchange a refresh token.
   *
   * @see https://www.rfc-editor.org/rfc/rfc6749#section-6
   */
  async exchangeRefreshToken({
    grant_type: e = "refresh_token",
    client_id: t = this._settings.client_id,
    client_secret: s = this._settings.client_secret,
    timeoutInSeconds: i,
    ...r
  }) {
    const n = this._logger.create("exchangeRefreshToken");
    t || n.throw(new Error("A client_id is required")), r.refresh_token || n.throw(new Error("A refresh_token is required"));
    const o = new URLSearchParams({ grant_type: e });
    for (const [g, u] of Object.entries(r))
      Array.isArray(u) ? u.forEach((d) => o.append(g, d)) : u != null && o.set(g, u);
    let a;
    switch (this._settings.client_authentication) {
      case "client_secret_basic":
        if (!s)
          throw n.throw(new Error("A client_secret is required")), null;
        a = A.generateBasicAuth(t, s);
        break;
      case "client_secret_post":
        o.append("client_id", t), s && o.append("client_secret", s);
        break;
    }
    const c = await this._metadataService.getTokenEndpoint(!1);
    n.debug("got token endpoint");
    const l = await this._jsonService.postForm(c, { body: o, basicAuth: a, timeoutInSeconds: i, initCredentials: this._settings.fetchRequestCredentials });
    return n.debug("got response"), l;
  }
  /**
   * Revoke an access or refresh token.
   *
   * @see https://datatracker.ietf.org/doc/html/rfc7009#section-2.1
   */
  async revoke(e) {
    var t;
    const s = this._logger.create("revoke");
    e.token || s.throw(new Error("A token is required"));
    const i = await this._metadataService.getRevocationEndpoint(!1);
    s.debug(`got revocation endpoint, revoking ${(t = e.token_type_hint) != null ? t : "default token type"}`);
    const r = new URLSearchParams();
    for (const [n, o] of Object.entries(e))
      o != null && r.set(n, o);
    r.set("client_id", this._settings.client_id), this._settings.client_secret && r.set("client_secret", this._settings.client_secret), await this._jsonService.postForm(i, { body: r }), s.debug("got response");
  }
}, $e = class {
  constructor(e, t, s) {
    this._settings = e, this._metadataService = t, this._claimsService = s, this._logger = new h("ResponseValidator"), this._userInfoService = new He(this._settings, this._metadataService), this._tokenClient = new ne(this._settings, this._metadataService);
  }
  async validateSigninResponse(e, t) {
    const s = this._logger.create("validateSigninResponse");
    this._processSigninState(e, t), s.debug("state processed"), await this._processCode(e, t), s.debug("code processed"), e.isOpenId && this._validateIdTokenAttributes(e), s.debug("tokens validated"), await this._processClaims(e, t == null ? void 0 : t.skipUserInfo, e.isOpenId), s.debug("claims processed");
  }
  async validateCredentialsResponse(e, t) {
    const s = this._logger.create("validateCredentialsResponse");
    e.isOpenId && e.id_token && this._validateIdTokenAttributes(e), s.debug("tokens validated"), await this._processClaims(e, t, e.isOpenId), s.debug("claims processed");
  }
  async validateRefreshResponse(e, t) {
    var s, i;
    const r = this._logger.create("validateRefreshResponse");
    e.userState = t.data, (s = e.session_state) != null || (e.session_state = t.session_state), (i = e.scope) != null || (e.scope = t.scope), e.isOpenId && e.id_token && (this._validateIdTokenAttributes(e, t.id_token), r.debug("ID Token validated")), e.id_token || (e.id_token = t.id_token, e.profile = t.profile);
    const n = e.isOpenId && !!e.id_token;
    await this._processClaims(e, !1, n), r.debug("claims processed");
  }
  validateSignoutResponse(e, t) {
    const s = this._logger.create("validateSignoutResponse");
    if (t.id !== e.state && s.throw(new Error("State does not match")), s.debug("state validated"), e.userState = t.data, e.error)
      throw s.warn("Response was error", e.error), new P(e);
  }
  _processSigninState(e, t) {
    var s;
    const i = this._logger.create("_processSigninState");
    if (t.id !== e.state && i.throw(new Error("State does not match")), t.client_id || i.throw(new Error("No client_id on state")), t.authority || i.throw(new Error("No authority on state")), this._settings.authority !== t.authority && i.throw(new Error("authority mismatch on settings vs. signin state")), this._settings.client_id && this._settings.client_id !== t.client_id && i.throw(new Error("client_id mismatch on settings vs. signin state")), i.debug("state validated"), e.userState = t.data, e.url_state = t.url_state, (s = e.scope) != null || (e.scope = t.scope), e.error)
      throw i.warn("Response was error", e.error), new P(e);
    t.code_verifier && !e.code && i.throw(new Error("Expected code in response"));
  }
  async _processClaims(e, t = !1, s = !0) {
    const i = this._logger.create("_processClaims");
    if (e.profile = this._claimsService.filterProtocolClaims(e.profile), t || !this._settings.loadUserInfo || !e.access_token) {
      i.debug("not loading user info");
      return;
    }
    i.debug("loading user info");
    const r = await this._userInfoService.getClaims(e.access_token);
    i.debug("user info claims received from user info endpoint"), s && r.sub !== e.profile.sub && i.throw(new Error("subject from UserInfo response does not match subject in ID Token")), e.profile = this._claimsService.mergeClaims(e.profile, this._claimsService.filterProtocolClaims(r)), i.debug("user info claims received, updated profile:", e.profile);
  }
  async _processCode(e, t) {
    const s = this._logger.create("_processCode");
    if (e.code) {
      s.debug("Validating code");
      const i = await this._tokenClient.exchangeCode({
        client_id: t.client_id,
        client_secret: t.client_secret,
        code: e.code,
        redirect_uri: t.redirect_uri,
        code_verifier: t.code_verifier,
        ...t.extraTokenParams
      });
      Object.assign(e, i);
    } else
      s.debug("No code to process");
  }
  _validateIdTokenAttributes(e, t) {
    var s;
    const i = this._logger.create("_validateIdTokenAttributes");
    i.debug("decoding ID Token JWT");
    const r = W.decode((s = e.id_token) != null ? s : "");
    if (r.sub || i.throw(new Error("ID Token is missing a subject claim")), t) {
      const n = W.decode(t);
      r.sub !== n.sub && i.throw(new Error("sub in id_token does not match current sub")), r.auth_time && r.auth_time !== n.auth_time && i.throw(new Error("auth_time in id_token does not match original auth_time")), r.azp && r.azp !== n.azp && i.throw(new Error("azp in id_token does not match original azp")), !r.azp && n.azp && i.throw(new Error("azp not in id_token, but present in original id_token"));
    }
    e.profile = r;
  }
}, H = class K {
  constructor(t) {
    this.id = t.id || A.generateUUIDv4(), this.data = t.data, t.created && t.created > 0 ? this.created = t.created : this.created = R.getEpochTime(), this.request_type = t.request_type, this.url_state = t.url_state;
  }
  toStorageString() {
    return new h("State").create("toStorageString"), JSON.stringify({
      id: this.id,
      data: this.data,
      created: this.created,
      request_type: this.request_type,
      url_state: this.url_state
    });
  }
  static fromStorageString(t) {
    return h.createStatic("State", "fromStorageString"), Promise.resolve(new K(JSON.parse(t)));
  }
  static async clearStaleState(t, s) {
    const i = h.createStatic("State", "clearStaleState"), r = R.getEpochTime() - s, n = await t.getAllKeys();
    i.debug("got keys", n);
    for (let o = 0; o < n.length; o++) {
      const a = n[o], c = await t.get(a);
      let l = !1;
      if (c)
        try {
          const g = await K.fromStorageString(c);
          i.debug("got item from key:", a, g.created), g.created <= r && (l = !0);
        } catch (g) {
          i.error("Error parsing state for key:", a, g), l = !0;
        }
      else
        i.debug("no item in storage for key:", a), l = !0;
      l && (i.debug("removed item for key:", a), t.remove(a));
    }
  }
}, oe = class J extends H {
  constructor(t) {
    super(t), this.code_verifier = t.code_verifier, this.code_challenge = t.code_challenge, this.authority = t.authority, this.client_id = t.client_id, this.redirect_uri = t.redirect_uri, this.scope = t.scope, this.client_secret = t.client_secret, this.extraTokenParams = t.extraTokenParams, this.response_mode = t.response_mode, this.skipUserInfo = t.skipUserInfo;
  }
  static async create(t) {
    const s = t.code_verifier === !0 ? A.generateCodeVerifier() : t.code_verifier || void 0, i = s ? await A.generateCodeChallenge(s) : void 0;
    return new J({
      ...t,
      code_verifier: s,
      code_challenge: i
    });
  }
  toStorageString() {
    return new h("SigninState").create("toStorageString"), JSON.stringify({
      id: this.id,
      data: this.data,
      created: this.created,
      request_type: this.request_type,
      url_state: this.url_state,
      code_verifier: this.code_verifier,
      authority: this.authority,
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      scope: this.scope,
      client_secret: this.client_secret,
      extraTokenParams: this.extraTokenParams,
      response_mode: this.response_mode,
      skipUserInfo: this.skipUserInfo
    });
  }
  static fromStorageString(t) {
    h.createStatic("SigninState", "fromStorageString");
    const s = JSON.parse(t);
    return J.create(s);
  }
}, ae = class ce {
  constructor(t) {
    this.url = t.url, this.state = t.state;
  }
  static async create({
    // mandatory
    url: t,
    authority: s,
    client_id: i,
    redirect_uri: r,
    response_type: n,
    scope: o,
    // optional
    state_data: a,
    response_mode: c,
    request_type: l,
    client_secret: g,
    nonce: u,
    url_state: d,
    resource: _,
    skipUserInfo: f,
    extraQueryParams: S,
    extraTokenParams: v,
    disablePKCE: x,
    ...U
  }) {
    if (!t)
      throw this._logger.error("create: No url passed"), new Error("url");
    if (!i)
      throw this._logger.error("create: No client_id passed"), new Error("client_id");
    if (!r)
      throw this._logger.error("create: No redirect_uri passed"), new Error("redirect_uri");
    if (!n)
      throw this._logger.error("create: No response_type passed"), new Error("response_type");
    if (!o)
      throw this._logger.error("create: No scope passed"), new Error("scope");
    if (!s)
      throw this._logger.error("create: No authority passed"), new Error("authority");
    const m = await oe.create({
      data: a,
      request_type: l,
      url_state: d,
      code_verifier: !x,
      client_id: i,
      authority: s,
      redirect_uri: r,
      response_mode: c,
      client_secret: g,
      scope: o,
      extraTokenParams: v,
      skipUserInfo: f
    }), p = new URL(t);
    p.searchParams.append("client_id", i), p.searchParams.append("redirect_uri", r), p.searchParams.append("response_type", n), p.searchParams.append("scope", o), u && p.searchParams.append("nonce", u);
    let E = m.id;
    d && (E = `${E}${F}${d}`), p.searchParams.append("state", E), m.code_challenge && (p.searchParams.append("code_challenge", m.code_challenge), p.searchParams.append("code_challenge_method", "S256")), _ && (Array.isArray(_) ? _ : [_]).forEach((w) => p.searchParams.append("resource", w));
    for (const [T, w] of Object.entries({ response_mode: c, ...U, ...S }))
      w != null && p.searchParams.append(T, w.toString());
    return new ce({
      url: p.href,
      state: m
    });
  }
};
ae._logger = new h("SigninRequest");
var We = ae, Le = "openid", $ = class {
  constructor(e) {
    if (this.access_token = "", this.token_type = "", this.profile = {}, this.state = e.get("state"), this.session_state = e.get("session_state"), this.state) {
      const t = decodeURIComponent(this.state).split(F);
      this.state = t[0], t.length > 1 && (this.url_state = t.slice(1).join(F));
    }
    this.error = e.get("error"), this.error_description = e.get("error_description"), this.error_uri = e.get("error_uri"), this.code = e.get("code");
  }
  get expires_in() {
    if (this.expires_at !== void 0)
      return this.expires_at - R.getEpochTime();
  }
  set expires_in(e) {
    typeof e == "string" && (e = Number(e)), e !== void 0 && e >= 0 && (this.expires_at = Math.floor(e) + R.getEpochTime());
  }
  get isOpenId() {
    var e;
    return ((e = this.scope) == null ? void 0 : e.split(" ").includes(Le)) || !!this.id_token;
  }
}, Fe = class {
  constructor({
    url: e,
    state_data: t,
    id_token_hint: s,
    post_logout_redirect_uri: i,
    extraQueryParams: r,
    request_type: n,
    client_id: o
  }) {
    if (this._logger = new h("SignoutRequest"), !e)
      throw this._logger.error("ctor: No url passed"), new Error("url");
    const a = new URL(e);
    s && a.searchParams.append("id_token_hint", s), o && a.searchParams.append("client_id", o), i && (a.searchParams.append("post_logout_redirect_uri", i), t && (this.state = new H({ data: t, request_type: n }), a.searchParams.append("state", this.state.id)));
    for (const [c, l] of Object.entries({ ...r }))
      l != null && a.searchParams.append(c, l.toString());
    this.url = a.href;
  }
}, De = class {
  constructor(e) {
    this.state = e.get("state"), this.error = e.get("error"), this.error_description = e.get("error_description"), this.error_uri = e.get("error_uri");
  }
}, Ke = [
  "nbf",
  "jti",
  "auth_time",
  "nonce",
  "acr",
  "amr",
  "azp",
  "at_hash"
  // https://openid.net/specs/openid-connect-core-1_0.html#CodeIDToken
], Je = ["sub", "iss", "aud", "exp", "iat"], ze = class {
  constructor(e) {
    this._settings = e, this._logger = new h("ClaimsService");
  }
  filterProtocolClaims(e) {
    const t = { ...e };
    if (this._settings.filterProtocolClaims) {
      let s;
      Array.isArray(this._settings.filterProtocolClaims) ? s = this._settings.filterProtocolClaims : s = Ke;
      for (const i of s)
        Je.includes(i) || delete t[i];
    }
    return t;
  }
  mergeClaims(e, t) {
    const s = { ...e };
    for (const [i, r] of Object.entries(t))
      if (s[i] !== r)
        if (Array.isArray(s[i]) || Array.isArray(r))
          if (this._settings.mergeClaimsStrategy.array == "replace")
            s[i] = r;
          else {
            const n = Array.isArray(s[i]) ? s[i] : [s[i]];
            for (const o of Array.isArray(r) ? r : [r])
              n.includes(o) || n.push(o);
            s[i] = n;
          }
        else
          typeof s[i] == "object" && typeof r == "object" ? s[i] = this.mergeClaims(s[i], r) : s[i] = r;
    return s;
  }
}, Be = class {
  constructor(e, t) {
    this._logger = new h("OidcClient"), this.settings = e instanceof D ? e : new D(e), this.metadataService = t ?? new Oe(this.settings), this._claimsService = new ze(this.settings), this._validator = new $e(this.settings, this.metadataService, this._claimsService), this._tokenClient = new ne(this.settings, this.metadataService);
  }
  async createSigninRequest({
    state: e,
    request: t,
    request_uri: s,
    request_type: i,
    id_token_hint: r,
    login_hint: n,
    skipUserInfo: o,
    nonce: a,
    url_state: c,
    response_type: l = this.settings.response_type,
    scope: g = this.settings.scope,
    redirect_uri: u = this.settings.redirect_uri,
    prompt: d = this.settings.prompt,
    display: _ = this.settings.display,
    max_age: f = this.settings.max_age,
    ui_locales: S = this.settings.ui_locales,
    acr_values: v = this.settings.acr_values,
    resource: x = this.settings.resource,
    response_mode: U = this.settings.response_mode,
    extraQueryParams: m = this.settings.extraQueryParams,
    extraTokenParams: p = this.settings.extraTokenParams
  }) {
    const E = this._logger.create("createSigninRequest");
    if (l !== "code")
      throw new Error("Only the Authorization Code flow (with PKCE) is supported");
    const T = await this.metadataService.getAuthorizationEndpoint();
    E.debug("Received authorization endpoint", T);
    const w = await We.create({
      url: T,
      authority: this.settings.authority,
      client_id: this.settings.client_id,
      redirect_uri: u,
      response_type: l,
      scope: g,
      state_data: e,
      url_state: c,
      prompt: d,
      display: _,
      max_age: f,
      ui_locales: S,
      id_token_hint: r,
      login_hint: n,
      acr_values: v,
      resource: x,
      request: t,
      request_uri: s,
      extraQueryParams: m,
      extraTokenParams: p,
      request_type: i,
      response_mode: U,
      client_secret: this.settings.client_secret,
      skipUserInfo: o,
      nonce: a,
      disablePKCE: this.settings.disablePKCE
    });
    await this.clearStaleState();
    const C = w.state;
    return await this.settings.stateStore.set(C.id, C.toStorageString()), w;
  }
  async readSigninResponseState(e, t = !1) {
    const s = this._logger.create("readSigninResponseState"), i = new $(L.readParams(e, this.settings.response_mode));
    if (!i.state)
      throw s.throw(new Error("No state in response")), null;
    const r = await this.settings.stateStore[t ? "remove" : "get"](i.state);
    if (!r)
      throw s.throw(new Error("No matching state found in storage")), null;
    return { state: await oe.fromStorageString(r), response: i };
  }
  async processSigninResponse(e) {
    const t = this._logger.create("processSigninResponse"), { state: s, response: i } = await this.readSigninResponseState(e, !0);
    return t.debug("received state from storage; validating response"), await this._validator.validateSigninResponse(i, s), i;
  }
  async processResourceOwnerPasswordCredentials({
    username: e,
    password: t,
    skipUserInfo: s = !1,
    extraTokenParams: i = {}
  }) {
    const r = await this._tokenClient.exchangeCredentials({ username: e, password: t, ...i }), n = new $(new URLSearchParams());
    return Object.assign(n, r), await this._validator.validateCredentialsResponse(n, s), n;
  }
  async useRefreshToken({
    state: e,
    redirect_uri: t,
    resource: s,
    timeoutInSeconds: i,
    extraTokenParams: r
  }) {
    var n;
    const o = this._logger.create("useRefreshToken");
    let a;
    if (this.settings.refreshTokenAllowedScope === void 0)
      a = e.scope;
    else {
      const g = this.settings.refreshTokenAllowedScope.split(" ");
      a = (((n = e.scope) == null ? void 0 : n.split(" ")) || []).filter((d) => g.includes(d)).join(" ");
    }
    const c = await this._tokenClient.exchangeRefreshToken({
      refresh_token: e.refresh_token,
      // provide the (possible filtered) scope list
      scope: a,
      redirect_uri: t,
      resource: s,
      timeoutInSeconds: i,
      ...r
    }), l = new $(new URLSearchParams());
    return Object.assign(l, c), o.debug("validating response", l), await this._validator.validateRefreshResponse(l, {
      ...e,
      // override the scope in the state handed over to the validator
      // so it can set the granted scope to the requested scope in case none is included in the response
      scope: a
    }), l;
  }
  async createSignoutRequest({
    state: e,
    id_token_hint: t,
    client_id: s,
    request_type: i,
    post_logout_redirect_uri: r = this.settings.post_logout_redirect_uri,
    extraQueryParams: n = this.settings.extraQueryParams
  } = {}) {
    const o = this._logger.create("createSignoutRequest"), a = await this.metadataService.getEndSessionEndpoint();
    if (!a)
      throw o.throw(new Error("No end session endpoint")), null;
    o.debug("Received end session endpoint", a), !s && r && !t && (s = this.settings.client_id);
    const c = new Fe({
      url: a,
      id_token_hint: t,
      client_id: s,
      post_logout_redirect_uri: r,
      state_data: e,
      extraQueryParams: n,
      request_type: i
    });
    await this.clearStaleState();
    const l = c.state;
    return l && (o.debug("Signout request has state to persist"), await this.settings.stateStore.set(l.id, l.toStorageString())), c;
  }
  async readSignoutResponseState(e, t = !1) {
    const s = this._logger.create("readSignoutResponseState"), i = new De(L.readParams(e, this.settings.response_mode));
    if (!i.state) {
      if (s.debug("No state in response"), i.error)
        throw s.warn("Response was error:", i.error), new P(i);
      return { state: void 0, response: i };
    }
    const r = await this.settings.stateStore[t ? "remove" : "get"](i.state);
    if (!r)
      throw s.throw(new Error("No matching state found in storage")), null;
    return { state: await H.fromStorageString(r), response: i };
  }
  async processSignoutResponse(e) {
    const t = this._logger.create("processSignoutResponse"), { state: s, response: i } = await this.readSignoutResponseState(e, !0);
    return s ? (t.debug("Received state from storage; validating response"), this._validator.validateSignoutResponse(i, s)) : t.debug("No state from storage; skipping response validation"), i;
  }
  clearStaleState() {
    return this._logger.create("clearStaleState"), H.clearStaleState(this.settings.stateStore, this.settings.staleStateAgeInSeconds);
  }
  async revokeToken(e, t) {
    return this._logger.create("revokeToken"), await this._tokenClient.revoke({
      token: e,
      token_type_hint: t
    });
  }
}, Ve = class {
  constructor(e) {
    this._userManager = e, this._logger = new h("SessionMonitor"), this._start = async (t) => {
      const s = t.session_state;
      if (!s)
        return;
      const i = this._logger.create("_start");
      if (t.profile ? (this._sub = t.profile.sub, i.debug("session_state", s, ", sub", this._sub)) : (this._sub = void 0, i.debug("session_state", s, ", anonymous user")), this._checkSessionIFrame) {
        this._checkSessionIFrame.start(s);
        return;
      }
      try {
        const r = await this._userManager.metadataService.getCheckSessionIframe();
        if (r) {
          i.debug("initializing check session iframe");
          const n = this._userManager.settings.client_id, o = this._userManager.settings.checkSessionIntervalInSeconds, a = this._userManager.settings.stopCheckSessionOnError, c = new Ae(this._callback, n, r, o, a);
          await c.load(), this._checkSessionIFrame = c, c.start(s);
        } else
          i.warn("no check session iframe found in the metadata");
      } catch (r) {
        i.error("Error from getCheckSessionIframe:", r instanceof Error ? r.message : r);
      }
    }, this._stop = () => {
      const t = this._logger.create("_stop");
      if (this._sub = void 0, this._checkSessionIFrame && this._checkSessionIFrame.stop(), this._userManager.settings.monitorAnonymousSession) {
        const s = setInterval(async () => {
          clearInterval(s);
          try {
            const i = await this._userManager.querySessionStatus();
            if (i) {
              const r = {
                session_state: i.session_state,
                profile: i.sub ? {
                  sub: i.sub
                } : null
              };
              this._start(r);
            }
          } catch (i) {
            t.error("error from querySessionStatus", i instanceof Error ? i.message : i);
          }
        }, 1e3);
      }
    }, this._callback = async () => {
      const t = this._logger.create("_callback");
      try {
        const s = await this._userManager.querySessionStatus();
        let i = !0;
        s && this._checkSessionIFrame ? s.sub === this._sub ? (i = !1, this._checkSessionIFrame.start(s.session_state), t.debug("same sub still logged in at OP, session state has changed, restarting check session iframe; session_state", s.session_state), await this._userManager.events._raiseUserSessionChanged()) : t.debug("different subject signed into OP", s.sub) : t.debug("subject no longer signed into OP"), i ? this._sub ? await this._userManager.events._raiseUserSignedOut() : await this._userManager.events._raiseUserSignedIn() : t.debug("no change in session detected, no event to raise");
      } catch (s) {
        this._sub && (t.debug("Error calling queryCurrentSigninSession; raising signed out event", s), await this._userManager.events._raiseUserSignedOut());
      }
    }, e || this._logger.throw(new Error("No user manager passed")), this._userManager.events.addUserLoaded(this._start), this._userManager.events.addUserUnloaded(this._stop), this._init().catch((t) => {
      this._logger.error(t);
    });
  }
  async _init() {
    this._logger.create("_init");
    const e = await this._userManager.getUser();
    if (e)
      this._start(e);
    else if (this._userManager.settings.monitorAnonymousSession) {
      const t = await this._userManager.querySessionStatus();
      if (t) {
        const s = {
          session_state: t.session_state,
          profile: t.sub ? {
            sub: t.sub
          } : null
        };
        this._start(s);
      }
    }
  }
}, M = class le {
  constructor(t) {
    var s;
    this.id_token = t.id_token, this.session_state = (s = t.session_state) != null ? s : null, this.access_token = t.access_token, this.refresh_token = t.refresh_token, this.token_type = t.token_type, this.scope = t.scope, this.profile = t.profile, this.expires_at = t.expires_at, this.state = t.userState, this.url_state = t.url_state;
  }
  /** Computed number of seconds the access token has remaining. */
  get expires_in() {
    if (this.expires_at !== void 0)
      return this.expires_at - R.getEpochTime();
  }
  set expires_in(t) {
    t !== void 0 && (this.expires_at = Math.floor(t) + R.getEpochTime());
  }
  /** Computed value indicating if the access token is expired. */
  get expired() {
    const t = this.expires_in;
    if (t !== void 0)
      return t <= 0;
  }
  /** Array representing the parsed values from the `scope`. */
  get scopes() {
    var t, s;
    return (s = (t = this.scope) == null ? void 0 : t.split(" ")) != null ? s : [];
  }
  toStorageString() {
    return new h("User").create("toStorageString"), JSON.stringify({
      id_token: this.id_token,
      session_state: this.session_state,
      access_token: this.access_token,
      refresh_token: this.refresh_token,
      token_type: this.token_type,
      scope: this.scope,
      profile: this.profile,
      expires_at: this.expires_at
    });
  }
  static fromStorageString(t) {
    return h.createStatic("User", "fromStorageString"), new le(JSON.parse(t));
  }
}, Z = "oidc-client", de = class {
  constructor() {
    this._abort = new I("Window navigation aborted"), this._disposeHandlers = /* @__PURE__ */ new Set(), this._window = null;
  }
  async navigate(e) {
    const t = this._logger.create("navigate");
    if (!this._window)
      throw new Error("Attempted to navigate on a disposed window");
    t.debug("setting URL in window"), this._window.location.replace(e.url);
    const { url: s, keepOpen: i } = await new Promise((r, n) => {
      const o = (a) => {
        var c;
        const l = a.data, g = (c = e.scriptOrigin) != null ? c : window.location.origin;
        if (!(a.origin !== g || (l == null ? void 0 : l.source) !== Z)) {
          try {
            const u = L.readParams(l.url, e.response_mode).get("state");
            if (u || t.warn("no state found in response url"), a.source !== this._window && u !== e.state)
              return;
          } catch {
            this._dispose(), n(new Error("Invalid response from window"));
          }
          r(l);
        }
      };
      window.addEventListener("message", o, !1), this._disposeHandlers.add(() => window.removeEventListener("message", o, !1)), this._disposeHandlers.add(this._abort.addHandler((a) => {
        this._dispose(), n(a);
      }));
    });
    return t.debug("got response from window"), this._dispose(), i || this.close(), { url: s };
  }
  _dispose() {
    this._logger.create("_dispose");
    for (const e of this._disposeHandlers)
      e();
    this._disposeHandlers.clear();
  }
  static _notifyParent(e, t, s = !1, i = window.location.origin) {
    e.postMessage({
      source: Z,
      url: t,
      keepOpen: s
    }, i);
  }
}, ge = {
  location: !1,
  toolbar: !1,
  height: 640,
  closePopupWindowAfterInSeconds: -1
}, he = "_blank", Ge = 60, Qe = 2, ue = 10, Xe = class extends D {
  constructor(e) {
    const {
      popup_redirect_uri: t = e.redirect_uri,
      popup_post_logout_redirect_uri: s = e.post_logout_redirect_uri,
      popupWindowFeatures: i = ge,
      popupWindowTarget: r = he,
      redirectMethod: n = "assign",
      redirectTarget: o = "self",
      iframeNotifyParentOrigin: a = e.iframeNotifyParentOrigin,
      iframeScriptOrigin: c = e.iframeScriptOrigin,
      silent_redirect_uri: l = e.redirect_uri,
      silentRequestTimeoutInSeconds: g = ue,
      automaticSilentRenew: u = !0,
      validateSubOnSilentRenew: d = !0,
      includeIdTokenInSilentRenew: _ = !1,
      monitorSession: f = !1,
      monitorAnonymousSession: S = !1,
      checkSessionIntervalInSeconds: v = Qe,
      query_status_response_type: x = "code",
      stopCheckSessionOnError: U = !0,
      revokeTokenTypes: m = ["access_token", "refresh_token"],
      revokeTokensOnSignout: p = !1,
      includeIdTokenInSilentSignout: E = !1,
      accessTokenExpiringNotificationTimeInSeconds: T = Ge,
      userStore: w
    } = e;
    if (super(e), this.popup_redirect_uri = t, this.popup_post_logout_redirect_uri = s, this.popupWindowFeatures = i, this.popupWindowTarget = r, this.redirectMethod = n, this.redirectTarget = o, this.iframeNotifyParentOrigin = a, this.iframeScriptOrigin = c, this.silent_redirect_uri = l, this.silentRequestTimeoutInSeconds = g, this.automaticSilentRenew = u, this.validateSubOnSilentRenew = d, this.includeIdTokenInSilentRenew = _, this.monitorSession = f, this.monitorAnonymousSession = S, this.checkSessionIntervalInSeconds = v, this.stopCheckSessionOnError = U, this.query_status_response_type = x, this.revokeTokenTypes = m, this.revokeTokensOnSignout = p, this.includeIdTokenInSilentSignout = E, this.accessTokenExpiringNotificationTimeInSeconds = T, w)
      this.userStore = w;
    else {
      const C = typeof window < "u" ? window.sessionStorage : new ie();
      this.userStore = new re({ store: C });
    }
  }
}, ee = class _e extends de {
  constructor({
    silentRequestTimeoutInSeconds: t = ue
  }) {
    super(), this._logger = new h("IFrameWindow"), this._timeoutInSeconds = t, this._frame = _e.createHiddenIframe(), this._window = this._frame.contentWindow;
  }
  static createHiddenIframe() {
    const t = window.document.createElement("iframe");
    return t.style.visibility = "hidden", t.style.position = "fixed", t.style.left = "-1000px", t.style.top = "0", t.width = "0", t.height = "0", window.document.body.appendChild(t), t;
  }
  async navigate(t) {
    this._logger.debug("navigate: Using timeout of:", this._timeoutInSeconds);
    const s = setTimeout(() => void this._abort.raise(new z("IFrame timed out without a response")), this._timeoutInSeconds * 1e3);
    return this._disposeHandlers.add(() => clearTimeout(s)), await super.navigate(t);
  }
  close() {
    var t;
    this._frame && (this._frame.parentNode && (this._frame.addEventListener("load", (s) => {
      var i;
      const r = s.target;
      (i = r.parentNode) == null || i.removeChild(r), this._abort.raise(new Error("IFrame removed from DOM"));
    }, !0), (t = this._frame.contentWindow) == null || t.location.replace("about:blank")), this._frame = null), this._window = null;
  }
  static notifyParent(t, s) {
    return super._notifyParent(window.parent, t, !1, s);
  }
}, Ye = class {
  constructor(e) {
    this._settings = e, this._logger = new h("IFrameNavigator");
  }
  async prepare({
    silentRequestTimeoutInSeconds: e = this._settings.silentRequestTimeoutInSeconds
  }) {
    return new ee({ silentRequestTimeoutInSeconds: e });
  }
  async callback(e) {
    this._logger.create("callback"), ee.notifyParent(e, this._settings.iframeNotifyParentOrigin);
  }
}, Ze = 500, et = 1e3, te = class extends de {
  constructor({
    popupWindowTarget: e = he,
    popupWindowFeatures: t = {}
  }) {
    super(), this._logger = new h("PopupWindow");
    const s = Y.center({ ...ge, ...t });
    this._window = window.open(void 0, e, Y.serialize(s)), t.closePopupWindowAfterInSeconds && t.closePopupWindowAfterInSeconds > 0 && setTimeout(() => {
      if (!this._window || typeof this._window.closed != "boolean" || this._window.closed) {
        this._abort.raise(new Error("Popup blocked by user"));
        return;
      }
      this.close();
    }, t.closePopupWindowAfterInSeconds * et);
  }
  async navigate(e) {
    var t;
    (t = this._window) == null || t.focus();
    const s = setInterval(() => {
      (!this._window || this._window.closed) && this._abort.raise(new Error("Popup closed by user"));
    }, Ze);
    return this._disposeHandlers.add(() => clearInterval(s)), await super.navigate(e);
  }
  close() {
    this._window && (this._window.closed || (this._window.close(), this._abort.raise(new Error("Popup closed")))), this._window = null;
  }
  static notifyOpener(e, t) {
    if (!window.opener)
      throw new Error("No window.opener. Can't complete notification.");
    return super._notifyParent(window.opener, e, t);
  }
}, tt = class {
  constructor(e) {
    this._settings = e, this._logger = new h("PopupNavigator");
  }
  async prepare({
    popupWindowFeatures: e = this._settings.popupWindowFeatures,
    popupWindowTarget: t = this._settings.popupWindowTarget
  }) {
    return new te({ popupWindowFeatures: e, popupWindowTarget: t });
  }
  async callback(e, { keepOpen: t = !1 }) {
    this._logger.create("callback"), te.notifyOpener(e, t);
  }
}, st = class {
  constructor(e) {
    this._settings = e, this._logger = new h("RedirectNavigator");
  }
  async prepare({
    redirectMethod: e = this._settings.redirectMethod,
    redirectTarget: t = this._settings.redirectTarget
  }) {
    var s;
    this._logger.create("prepare");
    let i = window.self;
    t === "top" && (i = (s = window.top) != null ? s : window.self);
    const r = i.location[e].bind(i.location);
    let n;
    return {
      navigate: async (o) => {
        this._logger.create("navigate");
        const a = new Promise((c, l) => {
          n = l;
        });
        return r(o.url), await a;
      },
      close: () => {
        this._logger.create("close"), n == null || n(new Error("Redirect aborted")), i.stop();
      }
    };
  }
  async callback() {
  }
}, it = class extends Pe {
  constructor(e) {
    super({ expiringNotificationTimeInSeconds: e.accessTokenExpiringNotificationTimeInSeconds }), this._logger = new h("UserManagerEvents"), this._userLoaded = new I("User loaded"), this._userUnloaded = new I("User unloaded"), this._silentRenewError = new I("Silent renew error"), this._userSignedIn = new I("User signed in"), this._userSignedOut = new I("User signed out"), this._userSessionChanged = new I("User session changed");
  }
  async load(e, t = !0) {
    super.load(e), t && await this._userLoaded.raise(e);
  }
  async unload() {
    super.unload(), await this._userUnloaded.raise();
  }
  /**
   * Add callback: Raised when a user session has been established (or re-established).
   */
  addUserLoaded(e) {
    return this._userLoaded.addHandler(e);
  }
  /**
   * Remove callback: Raised when a user session has been established (or re-established).
   */
  removeUserLoaded(e) {
    return this._userLoaded.removeHandler(e);
  }
  /**
   * Add callback: Raised when a user session has been terminated.
   */
  addUserUnloaded(e) {
    return this._userUnloaded.addHandler(e);
  }
  /**
   * Remove callback: Raised when a user session has been terminated.
   */
  removeUserUnloaded(e) {
    return this._userUnloaded.removeHandler(e);
  }
  /**
   * Add callback: Raised when the automatic silent renew has failed.
   */
  addSilentRenewError(e) {
    return this._silentRenewError.addHandler(e);
  }
  /**
   * Remove callback: Raised when the automatic silent renew has failed.
   */
  removeSilentRenewError(e) {
    return this._silentRenewError.removeHandler(e);
  }
  /**
   * @internal
   */
  async _raiseSilentRenewError(e) {
    await this._silentRenewError.raise(e);
  }
  /**
   * Add callback: Raised when the user is signed in (when `monitorSession` is set).
   * @see {@link UserManagerSettings.monitorSession}
   */
  addUserSignedIn(e) {
    return this._userSignedIn.addHandler(e);
  }
  /**
   * Remove callback: Raised when the user is signed in (when `monitorSession` is set).
   */
  removeUserSignedIn(e) {
    this._userSignedIn.removeHandler(e);
  }
  /**
   * @internal
   */
  async _raiseUserSignedIn() {
    await this._userSignedIn.raise();
  }
  /**
   * Add callback: Raised when the user's sign-in status at the OP has changed (when `monitorSession` is set).
   * @see {@link UserManagerSettings.monitorSession}
   */
  addUserSignedOut(e) {
    return this._userSignedOut.addHandler(e);
  }
  /**
   * Remove callback: Raised when the user's sign-in status at the OP has changed (when `monitorSession` is set).
   */
  removeUserSignedOut(e) {
    this._userSignedOut.removeHandler(e);
  }
  /**
   * @internal
   */
  async _raiseUserSignedOut() {
    await this._userSignedOut.raise();
  }
  /**
   * Add callback: Raised when the user session changed (when `monitorSession` is set).
   * @see {@link UserManagerSettings.monitorSession}
   */
  addUserSessionChanged(e) {
    return this._userSessionChanged.addHandler(e);
  }
  /**
   * Remove callback: Raised when the user session changed (when `monitorSession` is set).
   */
  removeUserSessionChanged(e) {
    this._userSessionChanged.removeHandler(e);
  }
  /**
   * @internal
   */
  async _raiseUserSessionChanged() {
    await this._userSessionChanged.raise();
  }
}, rt = class {
  constructor(e) {
    this._userManager = e, this._logger = new h("SilentRenewService"), this._isStarted = !1, this._retryTimer = new R("Retry Silent Renew"), this._tokenExpiring = async () => {
      const t = this._logger.create("_tokenExpiring");
      try {
        await this._userManager.signinSilent(), t.debug("silent token renewal successful");
      } catch (s) {
        if (s instanceof z) {
          t.warn("ErrorTimeout from signinSilent:", s, "retry in 5s"), this._retryTimer.init(5);
          return;
        }
        t.error("Error from signinSilent:", s), await this._userManager.events._raiseSilentRenewError(s);
      }
    };
  }
  async start() {
    const e = this._logger.create("start");
    if (!this._isStarted) {
      this._isStarted = !0, this._userManager.events.addAccessTokenExpiring(this._tokenExpiring), this._retryTimer.addHandler(this._tokenExpiring);
      try {
        await this._userManager.getUser();
      } catch (t) {
        e.error("getUser error", t);
      }
    }
  }
  stop() {
    this._isStarted && (this._retryTimer.cancel(), this._retryTimer.removeHandler(this._tokenExpiring), this._userManager.events.removeAccessTokenExpiring(this._tokenExpiring), this._isStarted = !1);
  }
}, nt = class {
  constructor(e) {
    this.refresh_token = e.refresh_token, this.id_token = e.id_token, this.session_state = e.session_state, this.scope = e.scope, this.profile = e.profile, this.data = e.state;
  }
}, se = class {
  constructor(e, t, s, i) {
    this._logger = new h("UserManager"), this.settings = new Xe(e), this._client = new Be(e), this._redirectNavigator = t ?? new st(this.settings), this._popupNavigator = s ?? new tt(this.settings), this._iframeNavigator = i ?? new Ye(this.settings), this._events = new it(this.settings), this._silentRenewService = new rt(this), this.settings.automaticSilentRenew && this.startSilentRenew(), this._sessionMonitor = null, this.settings.monitorSession && (this._sessionMonitor = new Ve(this));
  }
  /**
   * Get object used to register for events raised by the `UserManager`.
   */
  get events() {
    return this._events;
  }
  /**
   * Get object used to access the metadata configuration of the identity provider.
   */
  get metadataService() {
    return this._client.metadataService;
  }
  /**
   * Load the `User` object for the currently authenticated user.
   *
   * @returns A promise
   */
  async getUser() {
    const e = this._logger.create("getUser"), t = await this._loadUser();
    return t ? (e.info("user loaded"), await this._events.load(t, !1), t) : (e.info("user not found in storage"), null);
  }
  /**
   * Remove from any storage the currently authenticated user.
   *
   * @returns A promise
   */
  async removeUser() {
    const e = this._logger.create("removeUser");
    await this.storeUser(null), e.info("user removed from storage"), await this._events.unload();
  }
  /**
   * Trigger a redirect of the current window to the authorization endpoint.
   *
   * @returns A promise
   *
   * @throws `Error` In cases of wrong authentication.
   */
  async signinRedirect(e = {}) {
    this._logger.create("signinRedirect");
    const {
      redirectMethod: t,
      ...s
    } = e, i = await this._redirectNavigator.prepare({ redirectMethod: t });
    await this._signinStart({
      request_type: "si:r",
      ...s
    }, i);
  }
  /**
   * Process the response (callback) from the authorization endpoint.
   * It is recommend to use {@link UserManager.signinCallback} instead.
   *
   * @returns A promise containing the authenticated `User`.
   *
   * @see {@link UserManager.signinCallback}
   */
  async signinRedirectCallback(e = window.location.href) {
    const t = this._logger.create("signinRedirectCallback"), s = await this._signinEnd(e);
    return s.profile && s.profile.sub ? t.info("success, signed in subject", s.profile.sub) : t.info("no subject"), s;
  }
  /**
   * Trigger the signin with user/password.
   *
   * @returns A promise containing the authenticated `User`.
   * @throws {@link ErrorResponse} In cases of wrong authentication.
   */
  async signinResourceOwnerCredentials({
    username: e,
    password: t,
    skipUserInfo: s = !1
  }) {
    const i = this._logger.create("signinResourceOwnerCredential"), r = await this._client.processResourceOwnerPasswordCredentials({ username: e, password: t, skipUserInfo: s, extraTokenParams: this.settings.extraTokenParams });
    i.debug("got signin response");
    const n = await this._buildUser(r);
    return n.profile && n.profile.sub ? i.info("success, signed in subject", n.profile.sub) : i.info("no subject"), n;
  }
  /**
   * Trigger a request (via a popup window) to the authorization endpoint.
   *
   * @returns A promise containing the authenticated `User`.
   * @throws `Error` In cases of wrong authentication.
   */
  async signinPopup(e = {}) {
    const t = this._logger.create("signinPopup"), {
      popupWindowFeatures: s,
      popupWindowTarget: i,
      ...r
    } = e, n = this.settings.popup_redirect_uri;
    n || t.throw(new Error("No popup_redirect_uri configured"));
    const o = await this._popupNavigator.prepare({ popupWindowFeatures: s, popupWindowTarget: i }), a = await this._signin({
      request_type: "si:p",
      redirect_uri: n,
      display: "popup",
      ...r
    }, o);
    return a && (a.profile && a.profile.sub ? t.info("success, signed in subject", a.profile.sub) : t.info("no subject")), a;
  }
  /**
   * Notify the opening window of response (callback) from the authorization endpoint.
   * It is recommend to use {@link UserManager.signinCallback} instead.
   *
   * @returns A promise
   *
   * @see {@link UserManager.signinCallback}
   */
  async signinPopupCallback(e = window.location.href, t = !1) {
    const s = this._logger.create("signinPopupCallback");
    await this._popupNavigator.callback(e, { keepOpen: t }), s.info("success");
  }
  /**
   * Trigger a silent request (via refresh token or an iframe) to the authorization endpoint.
   *
   * @returns A promise that contains the authenticated `User`.
   */
  async signinSilent(e = {}) {
    var t;
    const s = this._logger.create("signinSilent"), {
      silentRequestTimeoutInSeconds: i,
      ...r
    } = e;
    let n = await this._loadUser();
    if (n != null && n.refresh_token) {
      s.debug("using refresh token");
      const l = new nt(n);
      return await this._useRefreshToken({
        state: l,
        redirect_uri: r.redirect_uri,
        resource: r.resource,
        extraTokenParams: r.extraTokenParams,
        timeoutInSeconds: i
      });
    }
    const o = this.settings.silent_redirect_uri;
    o || s.throw(new Error("No silent_redirect_uri configured"));
    let a;
    n && this.settings.validateSubOnSilentRenew && (s.debug("subject prior to silent renew:", n.profile.sub), a = n.profile.sub);
    const c = await this._iframeNavigator.prepare({ silentRequestTimeoutInSeconds: i });
    return n = await this._signin({
      request_type: "si:s",
      redirect_uri: o,
      prompt: "none",
      id_token_hint: this.settings.includeIdTokenInSilentRenew ? n == null ? void 0 : n.id_token : void 0,
      ...r
    }, c, a), n && ((t = n.profile) != null && t.sub ? s.info("success, signed in subject", n.profile.sub) : s.info("no subject")), n;
  }
  async _useRefreshToken(e) {
    const t = await this._client.useRefreshToken({
      ...e,
      timeoutInSeconds: this.settings.silentRequestTimeoutInSeconds
    }), s = new M({ ...e.state, ...t });
    return await this.storeUser(s), await this._events.load(s), s;
  }
  /**
   *
   * Notify the parent window of response (callback) from the authorization endpoint.
   * It is recommend to use {@link UserManager.signinCallback} instead.
   *
   * @returns A promise
   *
   * @see {@link UserManager.signinCallback}
   */
  async signinSilentCallback(e = window.location.href) {
    const t = this._logger.create("signinSilentCallback");
    await this._iframeNavigator.callback(e), t.info("success");
  }
  /**
   * Process any response (callback) from the authorization endpoint, by dispatching the request_type
   * and executing one of the following functions:
   * - {@link UserManager.signinRedirectCallback}
   * - {@link UserManager.signinPopupCallback}
   * - {@link UserManager.signinSilentCallback}
   *
   * @throws `Error` If request_type is unknown or signout can not processed.
   */
  async signinCallback(e = window.location.href) {
    const { state: t } = await this._client.readSigninResponseState(e);
    switch (t.request_type) {
      case "si:r":
        return await this.signinRedirectCallback(e);
      case "si:p":
        return await this.signinPopupCallback(e);
      case "si:s":
        return await this.signinSilentCallback(e);
      default:
        throw new Error("invalid response_type in state");
    }
  }
  /**
   * Process any response (callback) from the end session endpoint, by dispatching the request_type
   * and executing one of the following functions:
   * - {@link UserManager.signoutRedirectCallback}
   * - {@link UserManager.signoutPopupCallback}
   * - {@link UserManager.signoutSilentCallback}
   *
   * @throws `Error` If request_type is unknown or signout can not processed.
   */
  async signoutCallback(e = window.location.href, t = !1) {
    const { state: s } = await this._client.readSignoutResponseState(e);
    if (s)
      switch (s.request_type) {
        case "so:r":
          await this.signoutRedirectCallback(e);
          break;
        case "so:p":
          await this.signoutPopupCallback(e, t);
          break;
        case "so:s":
          await this.signoutSilentCallback(e);
          break;
        default:
          throw new Error("invalid response_type in state");
      }
  }
  /**
   * Query OP for user's current signin status.
   *
   * @returns A promise object with session_state and subject identifier.
   */
  async querySessionStatus(e = {}) {
    const t = this._logger.create("querySessionStatus"), {
      silentRequestTimeoutInSeconds: s,
      ...i
    } = e, r = this.settings.silent_redirect_uri;
    r || t.throw(new Error("No silent_redirect_uri configured"));
    const n = await this._loadUser(), o = await this._iframeNavigator.prepare({ silentRequestTimeoutInSeconds: s }), a = await this._signinStart({
      request_type: "si:s",
      // this acts like a signin silent
      redirect_uri: r,
      prompt: "none",
      id_token_hint: this.settings.includeIdTokenInSilentRenew ? n == null ? void 0 : n.id_token : void 0,
      response_type: this.settings.query_status_response_type,
      scope: "openid",
      skipUserInfo: !0,
      ...i
    }, o);
    try {
      const c = await this._client.processSigninResponse(a.url);
      return t.debug("got signin response"), c.session_state && c.profile.sub ? (t.info("success for subject", c.profile.sub), {
        session_state: c.session_state,
        sub: c.profile.sub
      }) : (t.info("success, user not authenticated"), null);
    } catch (c) {
      if (this.settings.monitorAnonymousSession && c instanceof P)
        switch (c.error) {
          case "login_required":
          case "consent_required":
          case "interaction_required":
          case "account_selection_required":
            return t.info("success for anonymous user"), {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              session_state: c.session_state
            };
        }
      throw c;
    }
  }
  async _signin(e, t, s) {
    const i = await this._signinStart(e, t);
    return await this._signinEnd(i.url, s);
  }
  async _signinStart(e, t) {
    const s = this._logger.create("_signinStart");
    try {
      const i = await this._client.createSigninRequest(e);
      return s.debug("got signin request"), await t.navigate({
        url: i.url,
        state: i.state.id,
        response_mode: i.state.response_mode,
        scriptOrigin: this.settings.iframeScriptOrigin
      });
    } catch (i) {
      throw s.debug("error after preparing navigator, closing navigator window"), t.close(), i;
    }
  }
  async _signinEnd(e, t) {
    const s = this._logger.create("_signinEnd"), i = await this._client.processSigninResponse(e);
    return s.debug("got signin response"), await this._buildUser(i, t);
  }
  async _buildUser(e, t) {
    const s = this._logger.create("_buildUser"), i = new M(e);
    if (t) {
      if (t !== i.profile.sub)
        throw s.debug("current user does not match user returned from signin. sub from signin:", i.profile.sub), new P({ ...e, error: "login_required" });
      s.debug("current user matches user returned from signin");
    }
    return await this.storeUser(i), s.debug("user stored"), await this._events.load(i), i;
  }
  /**
   * Trigger a redirect of the current window to the end session endpoint.
   *
   * @returns A promise
   */
  async signoutRedirect(e = {}) {
    const t = this._logger.create("signoutRedirect"), {
      redirectMethod: s,
      ...i
    } = e, r = await this._redirectNavigator.prepare({ redirectMethod: s });
    await this._signoutStart({
      request_type: "so:r",
      post_logout_redirect_uri: this.settings.post_logout_redirect_uri,
      ...i
    }, r), t.info("success");
  }
  /**
   * Process response (callback) from the end session endpoint.
   * It is recommend to use {@link UserManager.signoutCallback} instead.
   *
   * @returns A promise containing signout response
   *
   * @see {@link UserManager.signoutCallback}
   */
  async signoutRedirectCallback(e = window.location.href) {
    const t = this._logger.create("signoutRedirectCallback"), s = await this._signoutEnd(e);
    return t.info("success"), s;
  }
  /**
   * Trigger a redirect of a popup window window to the end session endpoint.
   *
   * @returns A promise
   */
  async signoutPopup(e = {}) {
    const t = this._logger.create("signoutPopup"), {
      popupWindowFeatures: s,
      popupWindowTarget: i,
      ...r
    } = e, n = this.settings.popup_post_logout_redirect_uri, o = await this._popupNavigator.prepare({ popupWindowFeatures: s, popupWindowTarget: i });
    await this._signout({
      request_type: "so:p",
      post_logout_redirect_uri: n,
      // we're putting a dummy entry in here because we
      // need a unique id from the state for notification
      // to the parent window, which is necessary if we
      // plan to return back to the client after signout
      // and so we can close the popup after signout
      state: n == null ? void 0 : {},
      ...r
    }, o), t.info("success");
  }
  /**
   * Process response (callback) from the end session endpoint from a popup window.
   * It is recommend to use {@link UserManager.signoutCallback} instead.
   *
   * @returns A promise
   *
   * @see {@link UserManager.signoutCallback}
   */
  async signoutPopupCallback(e = window.location.href, t = !1) {
    const s = this._logger.create("signoutPopupCallback");
    await this._popupNavigator.callback(e, { keepOpen: t }), s.info("success");
  }
  async _signout(e, t) {
    const s = await this._signoutStart(e, t);
    return await this._signoutEnd(s.url);
  }
  async _signoutStart(e = {}, t) {
    var s;
    const i = this._logger.create("_signoutStart");
    try {
      const r = await this._loadUser();
      i.debug("loaded current user from storage"), this.settings.revokeTokensOnSignout && await this._revokeInternal(r);
      const n = e.id_token_hint || r && r.id_token;
      n && (i.debug("setting id_token_hint in signout request"), e.id_token_hint = n), await this.removeUser(), i.debug("user removed, creating signout request");
      const o = await this._client.createSignoutRequest(e);
      return i.debug("got signout request"), await t.navigate({
        url: o.url,
        state: (s = o.state) == null ? void 0 : s.id,
        scriptOrigin: this.settings.iframeScriptOrigin
      });
    } catch (r) {
      throw i.debug("error after preparing navigator, closing navigator window"), t.close(), r;
    }
  }
  async _signoutEnd(e) {
    const t = this._logger.create("_signoutEnd"), s = await this._client.processSignoutResponse(e);
    return t.debug("got signout response"), s;
  }
  /**
   * Trigger a silent request (via an iframe) to the end session endpoint.
   *
   * @returns A promise
   */
  async signoutSilent(e = {}) {
    var t;
    const s = this._logger.create("signoutSilent"), {
      silentRequestTimeoutInSeconds: i,
      ...r
    } = e, n = this.settings.includeIdTokenInSilentSignout ? (t = await this._loadUser()) == null ? void 0 : t.id_token : void 0, o = this.settings.popup_post_logout_redirect_uri, a = await this._iframeNavigator.prepare({ silentRequestTimeoutInSeconds: i });
    await this._signout({
      request_type: "so:s",
      post_logout_redirect_uri: o,
      id_token_hint: n,
      ...r
    }, a), s.info("success");
  }
  /**
   * Notify the parent window of response (callback) from the end session endpoint.
   * It is recommend to use {@link UserManager.signoutCallback} instead.
   *
   * @returns A promise
   *
   * @see {@link UserManager.signoutCallback}
   */
  async signoutSilentCallback(e = window.location.href) {
    const t = this._logger.create("signoutSilentCallback");
    await this._iframeNavigator.callback(e), t.info("success");
  }
  async revokeTokens(e) {
    const t = await this._loadUser();
    await this._revokeInternal(t, e);
  }
  async _revokeInternal(e, t = this.settings.revokeTokenTypes) {
    const s = this._logger.create("_revokeInternal");
    if (!e)
      return;
    const i = t.filter((r) => typeof e[r] == "string");
    if (!i.length) {
      s.debug("no need to revoke due to no token(s)");
      return;
    }
    for (const r of i)
      await this._client.revokeToken(
        e[r],
        // eslint-disable-line @typescript-eslint/no-non-null-assertion
        r
      ), s.info(`${r} revoked successfully`), r !== "access_token" && (e[r] = null);
    await this.storeUser(e), s.debug("user stored"), await this._events.load(e);
  }
  /**
   * Enables silent renew for the `UserManager`.
   */
  startSilentRenew() {
    this._logger.create("startSilentRenew"), this._silentRenewService.start();
  }
  /**
   * Disables silent renew for the `UserManager`.
   */
  stopSilentRenew() {
    this._silentRenewService.stop();
  }
  get _userStoreKey() {
    return `user:${this.settings.authority}:${this.settings.client_id}`;
  }
  async _loadUser() {
    const e = this._logger.create("_loadUser"), t = await this.settings.userStore.get(this._userStoreKey);
    return t ? (e.debug("user storageString loaded"), M.fromStorageString(t)) : (e.debug("no user storageString"), null);
  }
  async storeUser(e) {
    const t = this._logger.create("storeUser");
    if (e) {
      t.debug("storing user");
      const s = e.toStorageString();
      await this.settings.userStore.set(this._userStoreKey, s);
    } else
      this._logger.debug("removing user"), await this.settings.userStore.remove(this._userStoreKey);
  }
  /**
   * Removes stale state entries in storage for incomplete authorize requests.
   */
  async clearStaleState() {
    await this._client.clearStaleState();
  }
};
const pe = ({
  loginRoutePath: e,
  redirectSearchParamName: t,
  searchParamsToPersist: s = []
}) => {
  const i = new URLSearchParams(window.location.search), r = new URLSearchParams();
  r.set(
    t,
    window.location.pathname + window.location.search
  ), s.forEach((o) => {
    i.has(o) && r.set(o, i.get(o));
  });
  const n = new URL(window.location.origin + e);
  return n.search = r.toString(), n;
}, lt = () => {
  const e = new URL(window.location.href), t = new URLSearchParams(e.search);
  if (t.delete("code"), t.delete("iss"), t.delete("session_state"), t.delete("state"), !t.size) {
    window.history.replaceState({}, "", e.pathname);
    return;
  }
  window.history.replaceState({}, "", `${e.pathname}?${t}`);
}, dt = (e, {
  loginRoutePath: t,
  redirectSearchParamName: s = "redirect",
  searchParamsToPersist: i = [],
  onNavigate: r = (n) => {
    window.location.href = n.toString();
  }
}) => {
  let n = new se(e);
  const o = () => n, a = (d) => (n = new se(d), n), c = () => {
    const d = o(), _ = localStorage.getItem(
      `oidc.user:${d.settings.authority}:${d.settings.client_id}`
    );
    return _ ? M.fromStorageString(_) : null;
  }, l = () => {
    const d = c();
    return `Bearer ${d == null ? void 0 : d.access_token}`;
  }, g = async ({
    config: d
  }) => {
    const _ = o(), f = await _.getUser();
    if (f != null && f.expired)
      return await _.signinSilent(), await be.request({
        ...d,
        headers: {
          ...d.headers,
          Authorization: l()
        }
      });
    f || r(
      pe({
        loginRoutePath: t,
        redirectSearchParamName: s,
        searchParamsToPersist: i
      })
    );
  };
  return {
    axiosAuthInterceptor: [
      async (d) => {
        var _, f, S, v;
        return ((v = (S = (f = (_ = d == null ? void 0 : d.data) == null ? void 0 : _.errors) == null ? void 0 : f[0]) == null ? void 0 : S.extensions) == null ? void 0 : v.code) === "UNAUTHENTICATED" && await g({ config: d.config }) || d;
      },
      async (d) => {
        throw d.response.status === 401 && await g({ config: d.config }), d;
      }
    ],
    getUserFromStorage: c,
    getAuthorizationHeader: l,
    get userManager() {
      return o();
    },
    useAuth: Te({
      loginRoutePath: t,
      redirectSearchParamName: s,
      searchParamsToPersist: i,
      onNavigate: r
    }),
    updateUserManager: a
  };
};
export {
  dt as c,
  pe as g,
  Te as m,
  lt as r
};
