import { twCn } from '@/utils';
import { isObject } from 'lodash';
import React, { useLayoutEffect, useRef } from 'react';
import { useBarChartContext } from './context';

export const ScaleLines = () => {
    const {
        yAxis,
        showYAxis,
        containerRef,
        maxValue,
        axisLabelFormatter,
        options,
        yAxisTitle,
    } = useBarChartContext();

    const titleRef = useRef<HTMLDivElement | null>(null);

    const refs = React.useRef<{ [key: string]: HTMLDivElement | null }>({});

    const percentages = [0, 25, 50, 75, 100];
    const axisValues = isObject(yAxis)
        ? percentages.map((p) => yAxis[p])
        : percentages.map((p) => {
              const val = maxValue * (p / 100);
              return axisLabelFormatter ? axisLabelFormatter(val) : val;
          });

    const resizeObserver = useRef(
        new ResizeObserver((entries) => {
            if (containerRef?.current) {
                const largestWidth = entries.reduce((acc, el) => {
                    if (!el) {
                        return acc;
                    }
                    return Math.max(
                        acc,
                        (el.target as HTMLDivElement).offsetWidth,
                    );
                }, 0);

                const space = titleRef.current ? 35 : 15;

                containerRef.current.style.paddingLeft = `${largestWidth + space}px`;
                titleRef.current?.style.setProperty(
                    'left',
                    `-${largestWidth + 10 + space - titleRef.current?.getBoundingClientRect().width}px`,
                );
            }
        }),
    ).current;

    useLayoutEffect(() => {
        const references = Object.values(refs.current);

        references.forEach((ref) => {
            if (ref) {
                resizeObserver.observe(ref);
            }
        });
        return () => {
            references.forEach((ref) => {
                if (ref) {
                    resizeObserver.unobserve(ref);
                }
            });
        };
    }, [resizeObserver]);

    if (!showYAxis) {
        return null;
    }

    return (
        <div>
            {yAxisTitle && (
                <div
                    className="text-dark dark:text-white"
                    ref={titleRef}
                    style={{
                        position: 'absolute',
                        transform: `translateX(-50%)`,
                        top: `50%`,
                        rotate: '-90deg',
                        transformOrigin: 'left',
                    }}
                >
                    {yAxisTitle}
                </div>
            )}
            {percentages.map((percentage, index) => {
                if (percentage && axisValues[index] == null) {
                    return null;
                }

                return (
                    <div
                        key={percentage}
                        className="z-0"
                        style={{
                            position: 'absolute',
                            width: '100%',
                            left: '50%',
                            transform: `translateX(-50%)`,
                            top: `${100 - percentage}%`,
                        }}
                    >
                        <div className="flex">
                            <div
                                className={twCn(
                                    'w-0.5 border-b border-dark border-opacity-50 dark:border-blue-200 dark:border-opacity-20',
                                    {
                                        'w-[5px] bg-dark bg-opacity-50 dark:bg-blue-200 dark:bg-opacity-50 rounded-sm border-none':
                                            !percentage,
                                        'border-dotted':
                                            percentage &&
                                            options.yAxisLinesStyle ===
                                                'dotted',
                                        'border-solid':
                                            percentage &&
                                            options.yAxisLinesStyle === 'solid',
                                        'border-none':
                                            percentage &&
                                            options.yAxisLinesStyle === 'none',
                                    },
                                )}
                                style={{
                                    pointerEvents: 'none',
                                    position: 'absolute',
                                    width: '100%',
                                    height: '2px',
                                }}
                            />
                            <div
                                className="text-dark dark:text-white font-mono"
                                ref={(el) =>
                                    (refs.current[String(percentage)] = el)
                                }
                                style={{
                                    position: 'absolute',
                                    transform:
                                        'translateY(-50%) translateX(-100%)',
                                    left: '-10px',
                                }}
                            >
                                {!percentage
                                    ? axisValues[index] || 0
                                    : axisValues[index]}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
