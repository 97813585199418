import { modelCompetitorsMediaInvestmentOptions } from '@/dashboards/CompetitorsMediaInvestment/api';
import { useCompetitorsMediaInvestmentUrl } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentUrl';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { getPeriodOptionsForModel } from '@/utils/model';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

export const useCompetitorsMediaInvestmentQuery = () => {
    const { search, params } = useCompetitorsMediaInvestmentUrl();

    const { projectId } = params;

    const { period } = search;

    const navigate = useNavigate();

    const queryResult = useQuery(
        modelCompetitorsMediaInvestmentOptions({
            projectId,
        }),
    );

    const {
        project: { interval },
    } = useDashboardGlobalContext();

    const availablePeriodOptions = getPeriodOptionsForModel(interval);

    const activeTabIndex = period
        ? Math.max(availablePeriodOptions.indexOf(period) || 0, 0)
        : 0;

    const onActiveTabChange = (activeTab: number) => {
        navigate({
            search: {
                ...search,
                period: availablePeriodOptions[activeTab] || '',
            },
            params,
        });
    };

    return {
        queryResult,
        activeTabIndex,
        tabsToRender: availablePeriodOptions,
        onActiveTabChange,
    };
};
