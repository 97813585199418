import { defaultAbility } from '@/features/Casl/ability';
import { AppAbility, UpdateAbility } from '@/features/Casl/types';
import { ReactNode, createContext } from 'react';

export const CaslAbilityContext = createContext<AppAbility>(defaultAbility);

type AbilityContextType = {
    updateAbility: UpdateAbility;
    abilityInitialized: boolean;
};

export const AbilityContext = createContext<{
    updateAbility: UpdateAbility;
    abilityInitialized: boolean;
}>({
    updateAbility: () => defaultAbility,
    abilityInitialized: false,
});

type AbilityProviderProps = AbilityContextType & {
    ability: AppAbility;
    children: ReactNode;
};

export const AbilityProvider = ({
    children,
    updateAbility,
    abilityInitialized,
    ability,
}: AbilityProviderProps) => {
    return (
        <AbilityContext.Provider value={{ updateAbility, abilityInitialized }}>
            <CaslAbilityContext.Provider value={ability}>
                {children}
            </CaslAbilityContext.Provider>
        </AbilityContext.Provider>
    );
};
