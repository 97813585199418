import {
    BaseChart,
    ChartMouseHandler,
    EChartsOption,
    useChart,
} from '@/components/ECharts';
import { useCompetitorsMediaInvestmentContext } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentContext';
import { jsxToHtml } from '@/utils/reactUtils';
import { Typography } from '@analytical-alley/ui';
import { BarSeriesOption, LineSeriesOption } from 'echarts';
import EChartsReact from 'echarts-for-react';
import { isArray } from 'lodash';
import React, {
    Ref,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';

interface GraphProps {
    chartRef?: Ref<EChartsReact | null>;
    selected: string;
    onSelected: (value: string) => void;
    getChartOptions: (chartOptionSeries: ChartOptions['series']) => void;
}

type AreaTooltipProps = {
    seriesName: string;
    color: string;
    value: number;
    axisValueLabel: string;
}[];

type ChartOptions = EChartsOption<BarSeriesOption | LineSeriesOption>;

const currencyFormat = Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'EUR',
    notation: 'compact',
});

const AreaTooltip = ({
    params,
    selectedSeriesName,
}: {
    params: AreaTooltipProps;
    selectedSeriesName?: string;
}) => {
    if (!selectedSeriesName) {
        return null;
    }

    const hoveredItem = params.find(
        (param) => param.seriesName === selectedSeriesName,
    );

    if (!hoveredItem) {
        return null;
    }

    //TODO: Replace IKEA with real client name
    if (selectedSeriesName === 'IKEA') {
        return (
            <div className="tooltip-content">
                <table>
                    <tbody>
                        <tr>
                            <td className="pr-2">
                                <Typography className="text-black dark:text-black">
                                    Client Name:
                                </Typography>
                            </td>
                            <td>
                                <Typography className="text-dark dark:text-dark">
                                    {hoveredItem.seriesName}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-2">
                                <Typography className="text-black dark:text-black">
                                    Period:
                                </Typography>
                            </td>
                            <td>
                                <Typography className="text-dark dark:text-dark">
                                    {params[0]?.axisValueLabel}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-2">
                                <Typography className="text-black dark:text-black">
                                    Client KPI:
                                </Typography>
                            </td>
                            <td>
                                <Typography className="text-dark dark:text-dark">
                                    {currencyFormat.format(hoveredItem.value)}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="tooltip-content">
            <table>
                <tbody>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Competitor Name:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {hoveredItem.seriesName}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Period:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {params[0]?.axisValueLabel}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Competitor`s investment:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {currencyFormat.format(hoveredItem.value)}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const getGraphData = ({
    seriesData,
    xAxisData,
    period,
}: {
    xAxisData: string[];
    seriesData: {
        [key: string]: {
            data: { [key: string]: number };
            color: string;
        };
    };
    period: string | undefined;
}): ChartOptions => {
    const dates = xAxisData.filter((date, index) => {
        return xAxisData.indexOf(date) === index;
    });

    const series: ChartOptions['series'] = Object.entries(seriesData).map(
        ([key, { data, color }], index) => {
            //TODO: Use actual client name
            if (key === 'IKEA') {
                return {
                    name: key,
                    type: 'line',
                    yAxisIndex: 1,
                    color: color,
                    itemStyle: {
                        borderWidth: '4',
                    },
                    lineStyle: {
                        width: 3,
                    },
                    symbol: 'circle',
                    showAllSymbol: true,
                    data: Object.entries(data).map(([name, value]) => ({
                        name,
                        value,
                    })),
                    smooth: false,
                    symbolSize: 7,
                } as unknown as ChartOptions['series'][0];
            } else {
                return {
                    name: key,
                    type: 'bar',
                    stack: 'Total',
                    symbolSize: 2,
                    showSymbol: false,
                    showAllSymbol: false,
                    triggerLineEvent: true,
                    color: color,
                    barMaxWidth: 50,
                    itemStyle: {
                        borderRadius:
                            index === Object.keys(seriesData).length - 1
                                ? [5, 5, 0, 0]
                                : 0,
                    },
                    data: Object.entries(data).map(([name, value]) => ({
                        name,
                        value,
                    })),
                } as unknown as ChartOptions['series'][0];
            }
        },
    );

    return {
        animation: false,
        tooltip: {
            show: false,
            trigger: 'axis',
            triggerOn: 'mousemove|click',
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            type: 'item',
            confine: true,
            extraCssText: 'max-width: 600px; white-space: pre-wrap;',
            formatter: '',
        },
        legend: {
            show: false,
        },
        grid: {
            right: '2%',
            left: '7%',
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                show: true,
                axisLine: {
                    show: false,
                },
                data: dates,
                axisLabel: {
                    rotate: 90,
                    margin: period === 'yearly' ? 30 : 20,
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    formatter: (value: number) =>
                        currencyFormat.format(Number(value)),
                },
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.1,
                    },
                },
            },
            {
                type: 'value',
                show: false,
            },
        ],
        series: series,
    };
};

export const CompetitorsMediaSeasonalityGraph = memo(
    ({ chartRef, selected, onSelected, getChartOptions }: GraphProps) => {
        const {
            competitorsMediaInvestmentData,
            getVisibleInvestmentData,
            period,
        } = useCompetitorsMediaInvestmentContext();

        const eChartsOptions = useMemo(() => {
            return getGraphData({
                seriesData: getVisibleInvestmentData(),
                xAxisData: competitorsMediaInvestmentData.dates,
                period: period,
            });
        }, [
            competitorsMediaInvestmentData.dates,
            getVisibleInvestmentData,
            period,
        ]);

        const [hovered, setHovered] = useState('');

        const selectedRef = useRef(selected);
        const timeoutRef = useRef<number | null>(null);

        useEffect(() => {
            getChartOptions(eChartsOptions.series);
        }, [eChartsOptions, getChartOptions]);

        useEffect(() => {
            selectedRef.current = selected;
        }, [selected]);

        const onClick: ChartMouseHandler = useCallback(
            (context) => {
                if (context.seriesName) {
                    onSelected(context.seriesName);
                } else {
                    onSelected('');
                }
            },
            [onSelected],
        );

        const onMouseOver: ChartMouseHandler = useCallback((context) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (context.seriesName) {
                setHovered(
                    selectedRef.current
                        ? selectedRef.current
                        : context.seriesName,
                );
            }
        }, []);

        const onMouseOut: ChartMouseHandler = useCallback(() => {
            if (selectedRef.current) {
                return;
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = window.setTimeout(() => {
                setHovered('');
            }, 0);
        }, []);

        const { ref, chartOptions, onEvents } = useChart({
            chartOptions: eChartsOptions,
            onClick,
            onMouseOver,
            onMouseOut,
        });

        useEffect(() => {
            const instance = ref.current?.getEchartsInstance();

            if (instance) {
                instance.setOption({
                    tooltip: {
                        showDelay: selected ? 0 : 50,
                        show: true,
                        formatter: (params: AreaTooltipProps) => {
                            return jsxToHtml(
                                <AreaTooltip
                                    params={params}
                                    selectedSeriesName={hovered}
                                />,
                            );
                        },
                    },
                });
            }
        }, [ref, hovered, selected]);

        useEffect(() => {
            const instance = ref.current?.getEchartsInstance();

            const series =
                chartOptions?.series && isArray(chartOptions.series)
                    ? chartOptions.series
                    : [];

            if (instance) {
                instance.setOption({
                    series: series.map((seriesOption) => {
                        if (
                            selected &&
                            selected !== seriesOption.name &&
                            seriesOption.name !== 'IKEA' //TODO: Replace with the real client name
                        ) {
                            return {
                                ...seriesOption,
                                itemStyle: { opacity: 0.1 },
                                lineStyle: { opacity: 0 },
                                symbol: 'none',
                            };
                        }
                        return {
                            ...seriesOption,
                            itemStyle: { opacity: 0.8 },
                            lineStyle: { opacity: 0.6 },
                            symbol: 'circle',
                        };
                    }),
                });
            }
        }, [ref, chartOptions.series, selected]);

        useImperativeHandle(chartRef, () => ref.current, [ref]);

        return (
            <div className="relative w-full h-[47.2rem]">
                <div className="absolute top-[22rem] -left-[6.5rem]">
                    <Typography
                        className="-rotate-90 font-mono"
                        variant="bodyM"
                    >
                        Competitor’s investments
                    </Typography>
                </div>
                <div className="absolute top-[22rem] -right-3">
                    <Typography
                        className="-rotate-90 font-mono"
                        variant="bodyM"
                    >
                        KPI
                    </Typography>
                </div>
                <BaseChart
                    $shouldExpand
                    option={chartOptions}
                    ref={ref}
                    onEvents={onEvents}
                />
            </div>
        );
    },
);

CompetitorsMediaSeasonalityGraph.displayName =
    'CompetitorsMediaSeasonalityGraph';
