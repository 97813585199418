import { cn } from '@/utils';
import { Tooltip, Typography } from '@analytical-alley/ui';
import { CellContext } from '@tanstack/react-table';
import React, { ReactNode } from 'react';

import { SuggestedBudgetTableData } from './SuggestedBudgetTable';

export const SuggestedBudgetCellRender = <
    T extends SuggestedBudgetTableData,
    E extends ReactNode,
>({
    getValue,
    row,
    column: { id },
    table,
    className,
    children,
    disabled,
    value,
}: CellContext<T, E> & {
    className?: string;
    children?: ReactNode;
    disabled?: boolean;
    value?: number | string;
}) => {
    const header = table.getColumn(id)?.columnDef.header;

    if (disabled) {
        return (
            <span className={cn('flex px-3.5 py-2', className)}>
                {children || getValue()}
            </span>
        );
    }

    return (
        <>
            <Tooltip placement="bottom">
                <Tooltip.Trigger disabled asChild>
                    <span
                        className={cn('flex px-3.5 py-2', className, {
                            'cursor-pointer': !disabled,
                        })}
                    >
                        {children || getValue()}
                    </span>
                </Tooltip.Trigger>
                <Tooltip.Content className="tooltip-content">
                    <table className="font-sans">
                        <tbody>
                            <tr>
                                <td className="pr-2">
                                    <Typography className="text-black dark:text-black">
                                        Category:
                                    </Typography>
                                </td>
                                <td>
                                    <Typography className="text-dark dark:text-dark">
                                        {row.original.category}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className="pr-2">
                                    <Typography className="text-black dark:text-black">
                                        Variable:
                                    </Typography>
                                </td>
                                <td>
                                    <Typography className="text-dark dark:text-dark">
                                        {row.original.variable}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className="pr-2">
                                    <Typography className="text-black dark:text-black">
                                        {typeof header === 'function'
                                            ? header({} as never)
                                            : header}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography className="text-dark dark:text-dark">
                                        {value != null
                                            ? value
                                            : children || getValue()}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Tooltip.Content>
            </Tooltip>
        </>
    );
};
