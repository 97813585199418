import { ErrorScreen } from '@/components/Error/ErrorScreen';
import { useAbilityContext } from '@/features/Casl/hooks/useAbilityContext';
import { Button, FullPageLoader } from '@analytical-alley/ui';
import { useNavigate } from '@tanstack/react-router';
import React from 'react';
import { AbilityTuple } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withAuthorization = <T extends React.ComponentType<any>>(
    WrappedComponent: T,
    ...permission: AbilityTuple
) => {
    const Component = (props: React.ComponentProps<T>) => {
        const navigate = useNavigate();
        const { ability, abilityInitialized } = useAbilityContext();

        if (abilityInitialized) {
            if (ability.cannot(...permission)) {
                return (
                    <ErrorScreen
                        error={{
                            error: 'Unauthorized',
                            customMessage:
                                'You are not authorized to view this page!',
                        }}
                    >
                        <Button
                            size="sm"
                            color="blue"
                            onClick={() => {
                                navigate({ to: '/' });
                            }}
                        >
                            Go Home
                        </Button>
                    </ErrorScreen>
                );
            }
            return <WrappedComponent {...props} />;
        }

        return <FullPageLoader />;
    };
    Component.displayName = `withAuthorization(${WrappedComponent.displayName})`;

    return Component;
};
