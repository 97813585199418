import type { ComponentProps, FC } from 'react';
import { twMerge } from 'tailwind-merge';

import type { DeepPartial } from '@/types';

import { useSelectContext } from './SelectContext';

export interface SelectDividerTheme {
    divider: string;
}

export type SelectDividerProps = {
    theme?: DeepPartial<SelectDividerTheme>;
} & ComponentProps<'div'>;

export const SelectDivider: FC<SelectDividerProps> = ({
    className,
    theme: customTheme = {},
    ...props
}) => {
    const { theme: rootTheme } = useSelectContext();

    const theme = customTheme.divider ?? rootTheme.floating.divider;

    return <div className={twMerge(theme, className)} {...props} />;
};
