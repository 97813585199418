import { AppAbility, FeatureFlagWithStatus } from '@/features/Casl';
import { createMongoAbility } from '@casl/ability';

export function defineAbility(featureFlags?: FeatureFlagWithStatus[]) {
    return createMongoAbility<AppAbility>(
        featureFlags?.filter((flag) => flag.isEnabled),
    );
}

export const defaultAbility = defineAbility();
