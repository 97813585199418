import {
    BudgetOptimization,
    budgetOptimizationSearchSchema,
} from '@/dashboards/BudgetOptimization';
import { BudgetOptimizationRouteProvider } from '@/dashboards/BudgetOptimization/providers';
import {
    FEATURE_FLAG_ACTIONS,
    FEATURE_FLAG_SUBJECTS,
    withAuthorization,
} from '@/features/Casl';
import { createFileRoute } from '@tanstack/react-router';

const BudgetOptimizationRouteComponent = () => {
    return (
        <BudgetOptimizationRouteProvider routeId={Route.id}>
            <BudgetOptimization />
        </BudgetOptimizationRouteProvider>
    );
};

export const Route = createFileRoute(
    '/_org/project/$projectId/_dashboards/budget-optimization',
)({
    component: withAuthorization(
        BudgetOptimizationRouteComponent,
        FEATURE_FLAG_ACTIONS.VIEW,
        FEATURE_FLAG_SUBJECTS.BUDGET_OPTIMIZATION_DASHBOARD,
    ),
    validateSearch: budgetOptimizationSearchSchema,
});
