import {
    getPreviousData,
    getRoiCurves,
} from '@/dashboards/BudgetOptimization/api';

import { useBudgetOptimizationUrl } from '@/dashboards/BudgetOptimization/hooks/useBudgetOptimizationUrl';
import { useVariableData } from '@/dashboards/BudgetOptimization/hooks/useVariableData';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import {
    structureBudgetData,
    structureMonthlyMediaInvestmentData,
} from '@/dashboards/utils/data';
import { useDuckDBQuery } from '@/duckdb';
import { useOptimizer } from '@/optimizer/optimizerContext';
import { useCallback, useMemo } from 'react';

export const useBudgetOptimizationData = () => {
    const {
        search: { budget },
        setSearch,
    } = useBudgetOptimizationUrl();

    const {
        project: { interval },
    } = useDashboardGlobalContext();
    const { getInitialAllocation, getDiminishingPoints } = useOptimizer();

    const setBudget = useCallback(
        (budget: number) => setSearch({ budget }),
        [setSearch],
    );

    const {
        variableValues,
        setVariableValues,
        isLoading,
        isFetching,
        optimizedData,
    } = useVariableData({
        budget,
        setBudget,
    });

    const { startDate, endDate } = optimizedData || {};

    const {
        data: prevData,
        isFetching: prevDataIsFetching,
        isLoading: prevDataIsLoading,
    } = useDuckDBQuery({
        queryKey: ['prev-data'],
        queryFn: () =>
            getPreviousData({
                startDate,
                endDate,
                interval: interval as 'weekly' | 'daily',
            }),
        enabled: !!optimizedData && !!startDate && !!endDate,
    });

    const monthlyMediaInvestment = useMemo(
        () =>
            structureMonthlyMediaInvestmentData({
                optimizedData,
                rows: prevData?.rows || {},
            }),
        [optimizedData, prevData?.rows],
    );

    const structuredBudgetData = useMemo(
        () =>
            structureBudgetData({
                optimizedData,
                variablesAndCategories: prevData?.roiCurves
                    .variablesAndCategories || {
                    byCategory: {},
                    byVariable: {},
                },
                totals: prevData?.totals || {},
            }),
        [
            optimizedData,
            prevData?.roiCurves.variablesAndCategories,
            prevData?.totals,
        ],
    );

    const initialAllocation = useMemo(() => {
        return getInitialAllocation();
    }, [getInitialAllocation]);

    const diminishingPoints = useMemo(() => {
        return getDiminishingPoints();
    }, [getDiminishingPoints]);

    return {
        setVariableValues,
        variableValues,
        data: {
            optimized: optimizedData,
            previous: prevData,
            monthlyMediaInvestment,
            initialAllocation,
            diminishingPoints,
            ...structuredBudgetData,
        },
        isFetching: prevDataIsFetching || isFetching,
        isLoading: prevDataIsLoading || isLoading,
    };
};

export type UseBudgetOptimizationQueryResponse = ReturnType<
    typeof useBudgetOptimizationData
>;

export type RoiData = Awaited<ReturnType<typeof getRoiCurves>>;
