import { FullPageLoader, Typography } from '@analytical-alley/ui';
import { useMutation } from '@tanstack/react-query';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

const route = getRouteApi('/invite/accept/$inviteId/');

const acceptInvite = async (
    inviteId: string,
): Promise<{ projectId: string }> => {
    return fetch(`/v1/invite/${inviteId}/accept`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((data) => {
                    throw data;
                });
            }
            return response;
        })
        .then((response) => response.json());
};

export const InviteAcceptPage = () => {
    const { inviteId } = route.useParams();
    const navigate = useNavigate();
    const { mutate, isError, error } = useMutation({
        mutationFn: acceptInvite,
        mutationKey: ['acceptInvite', inviteId],
        onSuccess: (data) => {
            navigate({
                to: '/project/$projectId',
                params: {
                    projectId: data.projectId,
                },
                replace: true,
            });
        },
    });

    useEffect(() => {
        mutate(inviteId);
    }, [inviteId, mutate]);

    if (isError) {
        return (
            <div className="flex justify-center px-40 p-16 lg:p-36 pb-48 dark:text-white max-lg:px-5">
                <Typography variant="titleS" className="mb-4">
                    Error: {error.message}
                </Typography>
            </div>
        );
    }

    return <FullPageLoader />;
};
