import { env } from '@/utils/env';
import { useNavigate, useSearch } from '@tanstack/react-router';
import React, {
    ReactNode,
    useCallback,
    useLayoutEffect,
    useState,
} from 'react';

const defaultSetIsFullScreen = () => {};

const FullScreenContext = React.createContext<{
    enabled: boolean;
    fullScreenSectionId: string | null;
    isFullScreen: boolean;
    setIsFullScreen: (sectionId: string, fullScreen: boolean) => void;
    portalRef: React.RefObject<HTMLDivElement>;
}>({
    enabled: false,
    fullScreenSectionId: null,
    isFullScreen: false,
    setIsFullScreen: defaultSetIsFullScreen,
    portalRef: { current: null },
});

interface UseFullScreenReturn {
    isSectionFullScreen: boolean;
    isFullScreen: boolean;
    setIsFullScreen: (fullScreen: boolean) => void;
    enabled: boolean;
    portalRef: React.RefObject<HTMLDivElement>;
}

export const useFullScreen = (sectionId: string): UseFullScreenReturn => {
    const {
        fullScreenSectionId,
        isFullScreen,
        setIsFullScreen,
        enabled,
        portalRef,
    } = React.useContext(FullScreenContext);

    // Log out message, when DEV mode and hook is used outside of FullScreenProvider,
    if (env.DEV && setIsFullScreen === defaultSetIsFullScreen) {
        // eslint-disable-next-line no-console
        console.error(
            'useFullScreen can only be used within a FullScreenProvider',
        );
    }

    const setFullScreen = (fullScreen: boolean) => {
        setIsFullScreen(sectionId, fullScreen);
    };

    return {
        portalRef,
        isSectionFullScreen: fullScreenSectionId === sectionId && isFullScreen,
        isFullScreen,
        setIsFullScreen: setFullScreen,
        enabled,
    };
};

export const FullScreenProvider = ({
    children,
    enabled = true,
    portalRef,
}: {
    children: ReactNode;
    enabled?: boolean;
    portalRef: React.RefObject<HTMLDivElement>;
}) => {
    const search = useSearch({
        strict: false,
    }) as { fullscreen?: string };

    const navigate = useNavigate();

    const [isInitialized, setIsInitialized] = useState(false);

    const [fullScreen, setFullScreen] = useState(false);

    useLayoutEffect(() => {
        if (portalRef.current) {
            const fsElement = search.fullscreen
                ? document.getElementById(search.fullscreen)
                : null;

            if (fsElement) {
                setFullScreen(fsElement?.getAttribute('data-fs') === 'true');
            }
            setIsInitialized(true);
        }
        // No need to include search.fullscreen in the dependencies, as we need to set the fullScreen state only once on mount.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portalRef]);

    const setIsFullScreen = useCallback(
        (sectionId: string, fullScreen: boolean) => {
            if (fullScreen) {
                navigate({
                    search: { ...search, fullscreen: sectionId },
                    replace: true,
                });
            } else {
                navigate({
                    search: { ...search, fullscreen: undefined },
                    replace: true,
                });
            }
            setFullScreen(fullScreen);
        },
        [navigate, search],
    );

    const isFullScreen = fullScreen && !!search.fullscreen;

    return (
        <FullScreenContext.Provider
            value={{
                portalRef,
                enabled: isInitialized && enabled,
                fullScreenSectionId: search.fullscreen || null,
                isFullScreen,
                setIsFullScreen,
            }}
        >
            <div className={isFullScreen ? 'hidden' : undefined}>
                {children}
            </div>
        </FullScreenContext.Provider>
    );
};
