import { ChartRef } from '@/components/ECharts/useChart';
import { FullScreenProps } from '@/components/FullScreen';
import { WithLoader } from '@/components/WithLoader';
import { useBudgetOptimizationContext } from '@/dashboards/BudgetOptimization/context/BudgetOptimizationContext';
import { Typography } from '@analytical-alley/ui';
import React, { useMemo, useRef } from 'react';
import { ROICurvesChart, ROICurvesChartProps } from './ROICurvesChart';

export const ROICurvesBase = ({ fullScreenButton }: FullScreenProps) => {
    const chartRef = useRef<ChartRef>(null);
    const filterContainerRef = useRef<HTMLDivElement>(null);
    const {
        isLoading,
        isFetching,
        data: { previous, initialAllocation, optimized },
    } = useBudgetOptimizationContext();

    const points: ROICurvesChartProps['points'] = useMemo(() => {
        return {
            previousAllocation: initialAllocation,
            optimalAllocation: optimized?.optimalVariables,
        };
    }, [initialAllocation, optimized?.optimalVariables]);

    return (
        <div className="glass-tile p-6 flex flex-col gap-4">
            <WithLoader isLoading={isLoading} isFetching={isFetching}>
                <div className="flex items-center">
                    <Typography variant="titleS">
                        ROI Curves and simulated investments
                    </Typography>
                    <span className="grow" />
                    <div className="mx-2" ref={filterContainerRef} />
                    {fullScreenButton}
                </div>

                {previous ? (
                    <ROICurvesChart
                        filterContainerRef={filterContainerRef}
                        chartRef={chartRef}
                        data={previous.roiCurves}
                        points={points}
                        investmentStep={optimized?.investmentStep || 100}
                    />
                ) : (
                    <div className={isFetching ? 'opacity-50' : undefined}>
                        <div className="flex items-center justify-center w-full h-[24rem]" />
                    </div>
                )}
            </WithLoader>
        </div>
    );
};
