import { useCampaignOptimizationRoute } from '@/dashboards/CampaignOptimization/providers';
import { useRouteUrl } from '@/hooks';

export const useCampaignOptimizationUrl = () => {
    const { route } = useCampaignOptimizationRoute();

    return useRouteUrl(route);
};

export type UseCampaignOptimizationUrlReturn = ReturnType<
    typeof useCampaignOptimizationUrl
>;
