import { WithLoader } from '@/components/WithLoader';
import { MediaSpendVsContributionShareGraph } from '@/dashboards/SpendSummary/components/MediaSpendVsContributionShareGraph';
import { PercentWidget } from '@/dashboards/SpendSummary/components/PercentWidget';
import {
    useSpendSummaryContext,
    useSpendSummaryQuery,
} from '@/dashboards/SpendSummary/hooks';
import { roundToDecimals } from '@/utils/numberUtils';
import { Typography } from '@analytical-alley/ui';
import React, { useMemo } from 'react';

interface TableDataType {
    category: string | undefined;
    spendVsContributionPercentage: number;
    contributionSharePercentage: number;
    spendSharePercentage: number;
}

export const MediaSpendVsContributionShareSection = () => {
    const {
        queryResult: { isLoading, isFetching },
    } = useSpendSummaryQuery();
    const { modelBreakdown, spendSummaryData, selectedFilterNodes } =
        useSpendSummaryContext();

    const tableData: TableDataType[] = useMemo(
        () =>
            spendSummaryData.categoryData
                .filter((d) => {
                    return !!selectedFilterNodes[d.category];
                })
                .map((data) => {
                    const totalContributionByCategory =
                        spendSummaryData.totalContributionByCategory.get(
                            data.category,
                        )!;
                    const totalSpendByCategory =
                        spendSummaryData.totalSpendByCategory.get(
                            data.category,
                        )!;
                    const totalContribution =
                        spendSummaryData.totalContribution;
                    const totalSpend = spendSummaryData.totalSpend;

                    const contributionSharePercentage = roundToDecimals(
                        (totalContributionByCategory / totalContribution) * 100,
                        0,
                    );
                    const spendSharePercentage = roundToDecimals(
                        (totalSpendByCategory / totalSpend) * 100,
                        0,
                    );

                    const spendVsContributionPercentage = roundToDecimals(
                        contributionSharePercentage - spendSharePercentage,
                        0,
                    );

                    return {
                        category: data.category.split(' ')[0],
                        spendVsContributionPercentage,
                        contributionSharePercentage,
                        spendSharePercentage,
                    };
                }),
        [
            spendSummaryData.categoryData,
            spendSummaryData.totalContributionByCategory,
            spendSummaryData.totalSpendByCategory,
            spendSummaryData.totalContribution,
            spendSummaryData.totalSpend,
            selectedFilterNodes,
        ],
    );

    return (
        <div className="glass-tile flex flex-col p-8 max-lg:px-5 max-lg:mt-10 max-lg:max-w-full">
            <WithLoader isLoading={isLoading} isFetching={isFetching}>
                <div className="flex flex-row justify-between mb-5">
                    <Typography variant="titleS">
                        Media Spend vs Contribution Share
                    </Typography>
                    {modelBreakdown === 'variable' && (
                        <div>
                            <div className="flex items-center">
                                <div className="w-2.5 h-2.5 bg-[#2B67FF] rounded-full mr-1.5 mb-0.5" />
                                <Typography className="m-0" variant="bodyXS">
                                    Contribution
                                </Typography>
                            </div>
                            <div className="flex items-center">
                                <div className="w-2.5 h-2.5 bg-indigo-300 bg-opacity-50 rounded-full mr-1.5 mb-0.5" />
                                <Typography className="m-0" variant="bodyXS">
                                    Spend
                                </Typography>
                            </div>
                        </div>
                    )}
                </div>
                {modelBreakdown === 'category' ? (
                    <div className="flex flex-row gap-6 flex-wrap">
                        {tableData.map((item, index) => (
                            <div
                                key={index}
                                className="flex flex-col flex-1 gap-2.5 max-md:w-full"
                            >
                                <Typography variant="bodyL">
                                    {item.category}
                                </Typography>
                                <PercentWidget
                                    spendVsContributionPercent={
                                        item.spendVsContributionPercentage
                                    }
                                    contributionPercent={
                                        item.contributionSharePercentage
                                    }
                                    spendPercent={item.spendSharePercentage}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <MediaSpendVsContributionShareGraph />
                )}
            </WithLoader>
        </div>
    );
};
