function C(t, e, c) {
  switch (e) {
    case ">":
    case "<":
    case "=":
    case ">=":
    case "<=":
    case "<>":
      return [
        `"${t}"`,
        e,
        typeof c == "string" ? `'${c}'` : c
      ].join(" ");
    case "LIKE":
      return [`"${t}"`, e, `%${c}%`].join(" ");
    case "IN":
      return [`"${t}"`, e, `(${c})`].join(" ");
    default:
      throw new Error(`Unsupported operator: ${e}`);
  }
}
function h(t, e, c, i, E, d) {
  const O = E[e] || e;
  let y = C(O, c, i);
  if (t === "whereIn") {
    const Q = typeof i[0] == "string" ? i.map((u) => `'${u}'`) : i;
    y = [`"${O}"`, "IN", `(${Q.join(", ")})`].join(" ");
  }
  if (t === "orWhere") {
    const Q = d.pop();
    if (Q)
      Q.type === "OR" && Q.query.slice(0, -1).concat(" OR ", y, ")"), d.push({
        type: "OR",
        query: "(".concat(Q.query.concat(" OR ", y, ")"))
      });
    else
      throw new Error("No previous query to OR with");
  } else
    d.push({
      type: "AND",
      query: y
    });
}
function g(t, e, c) {
  function i(u) {
    return function(n) {
      return {
        sqlQuery: u,
        // @ts-ignore
        transform: n || ((o) => o),
        nameMap: c
      };
    };
  }
  function E(...u) {
    const r = t.makeQuery(({ table: f }) => `SELECT ${u[0] === "*" ? "*" : u.map((s) => `"${String(e[s] || s)}"`).join(", ")} FROM "${f}"`).sqlQuery, n = [];
    let o = "";
    const $ = {
      where: S,
      done: R,
      orWhere: w,
      whereIn: q,
      orderBy: j
    };
    function R(f) {
      return {
        sqlQuery: r + (n.length ? ` WHERE ${n.map((s) => s.query).join(" AND ")}` : "") + o,
        // @ts-ignore
        transform: f || ((s) => s),
        nameMap: c
      };
    }
    function S(f, s, a) {
      return h("where", f, s, a, e, n), $;
    }
    function q(f, s) {
      return h(
        "whereIn",
        f,
        "IN",
        s,
        e,
        n
      ), { done: R };
    }
    function w(f, s, a) {
      return h("orWhere", f, s, a, e, n), $;
    }
    function j(f, s = "ASC") {
      const a = e[f];
      return o = `${o} ORDER BY "${a}" ${s}`, { done: R };
    }
    return { where: S, done: R, whereIn: q, orderBy: j };
  }
  function d(u, r) {
    const n = t.makeQuery(
      ({ table: o }) => `SELECT SUM(${e[u]}) as ${r} FROM "${o}"`
    ).sqlQuery;
    return { done: i(n) };
  }
  function O(u) {
    let r = t.makeQuery(
      ({ table: o }) => `SELECT DISTINCT "${e[u]}" FROM "${o}"`
    ).sqlQuery;
    function n(o, $ = "ASC") {
      const R = e[o];
      return r = `${r} ORDER BY "${R}" ${$}`, {
        done: i(r)
      };
    }
    return {
      done: i(r),
      orderBy: n,
      nameMap: c
    };
  }
  function y(u) {
    let r = t.makeQuery(
      ({ table: o }) => `SELECT ${u.map(($) => `"${String(e[$] || $)}"`).join(", ")} FROM "${o}" GROUP BY ${u.map(($) => `"${String(e[$] || $)}"`).join(", ")}`
    ).sqlQuery;
    function n(o, $) {
      const R = r.split(" FROM ");
      return r = R[0] + `, SUM("${String(e[o])}") as ${$} FROM ` + R[1], {
        done: i(r)
      };
    }
    return {
      done: i(r),
      sum: n
    };
  }
  function Q(u, r) {
    const n = t.makeQuery(u, r);
    return n.nameMap = c, n;
  }
  return { select: E, distinct: O, sum: d, groupBy: y, rawQuery: Q };
}
function l(t, e, c, i) {
  const E = Object.keys(i).reduce((O, y) => (O[i[y]] = y, O), {});
  function d(O, y) {
    return {
      sqlQuery: `${t ? `USE "${t}"; ` : ""}${O({
        columns: E,
        table: e
      })}`,
      transform: y || ((Q) => Q)
    };
  }
  return g({ makeQuery: d }, E, i);
}
export {
  l as defineDuckDBTable
};
