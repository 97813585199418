import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

const dashboardPreviewSearchSchema = z.object({
    dashboardId: z.string(),
    projectId: z.string(),
});

export const Route = createFileRoute('/preview/')({
    beforeLoad: ({ search }) => {
        throw redirect({
            to: '/project/$projectId',
            params: {
                projectId: search.projectId,
            },
            search: { dashboardId: search.dashboardId },
        });
    },
    loaderDeps: ({ search }) => search,
    validateSearch: dashboardPreviewSearchSchema,
});
