/* eslint-disable no-console */
import {
    createOptimizerServiceManager,
    OptimizerServiceManagerReturn,
} from '@/optimizer/createOptimizerServiceManager';
import { runScripts } from '@/optimizer/scripts';
import { env } from '@/utils/env';
import { type PinoLogger } from '@analytical-alley/common/utils/pinoLogger';
import { type RunQuery } from '@analytical-alley/duckdb-react';
import { GeneralMegastarOutputLoaderService } from '@analytical-alley/roi-optimizer/optimizer/megastarOutputLoader/general.service';
import { OptimizerService } from '@analytical-alley/roi-optimizer/optimizer/optimizer.service';
import { DuckDBDataSourceLoaderService } from './duckdb.service';

export let optimizerService: OptimizerService;
export let optimizerServiceManager: OptimizerServiceManagerReturn;

type ConsoleMethods = 'log' | 'error' | 'warn' | 'debug' | 'trace' | 'info';

const createConsole = (
    enabled: boolean,
): Record<ConsoleMethods, (...args: any[]) => void> => {
    return {
        log: enabled ? console.log.bind(console) : () => {},
        error: enabled ? console.error.bind(console) : () => {},
        warn: enabled ? console.warn.bind(console) : () => {},
        debug: enabled ? console.debug.bind(console) : () => {},
        trace: enabled ? console.trace.bind(console) : () => {},
        info: enabled ? console.info.bind(console) : () => {},
    };
};

interface InitOptimizerOptions {
    consoleEnabled: boolean;
}

export async function initOptimizer(
    runQuery: RunQuery,
    { consoleEnabled }: InitOptimizerOptions = {
        consoleEnabled: env.DEV,
    },
) {
    const customConsole = createConsole(consoleEnabled);

    const logger: PinoLogger = {
        logger: {} as unknown as any,
        ...customConsole,
        fatal: customConsole.error,
        setContext() {},
        assign() {},
    };

    if (optimizerService) {
        logger.warn('Optimizer service is already initialized!');
        return;
    }

    const duckdbService = await DuckDBDataSourceLoaderService.create(
        logger,
        runQuery,
    );

    const megastarOutputLoaderService =
        await GeneralMegastarOutputLoaderService.create(logger, duckdbService);

    const optimizer = await OptimizerService.create(
        logger,
        megastarOutputLoaderService,
    );

    optimizerService = optimizer;
    optimizerServiceManager = createOptimizerServiceManager(optimizer);

    await runScripts();
}
