import { Select } from '@/components/Select';
import { CampaignOptimizationLoader } from '@/dashboards/CampaignOptimization/components/CampaignOptimizationLoader';
import { NaturalDemandSeasonalityChart } from '@/dashboards/CampaignOptimization/components/NaturalDemandSeasonality/NaturalDemandSeasonalityChart';
import { useCampaignOptimizationContext } from '@/dashboards/CampaignOptimization/hooks';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { getPeriodOptionsForModel } from '@/utils/model';
import { Typography } from '@analytical-alley/ui';
import React, { useMemo, useState } from 'react';

export type StructuredSeasonalityData = {
    period: number;
    contributionIndex: number;
};

const OPTION_LABELS = {
    daily: 'Weekdays',
    weekly: 'Weekly',
    monthly: 'Monthly',
};

export const NaturalDemandSeasonality = () => {
    const {
        data: { optimized },
    } = useCampaignOptimizationContext();

    const {
        project: { interval },
    } = useDashboardGlobalContext();

    const availablePeriodOptions = getPeriodOptionsForModel(interval, [
        'daily',
        'weekly',
        'monthly',
    ]);

    const [selectedOption, setSelectedOption] = useState(
        availablePeriodOptions[0],
    );

    const data = useMemo(() => {
        if (optimized) {
            switch (selectedOption) {
                case 'daily':
                    return {
                        plan: optimized.weekDayPlan,
                        average: optimized.weekDayAverage,
                    };
                case 'monthly':
                    return {
                        plan: optimized.monthlyPlan,
                        average: optimized.monthlyAverage,
                    };
                case 'weekly':
                    return {
                        plan: optimized.weeklyPlan,
                        average: optimized.weeklyAverage,
                    };
            }
        }
        return {
            plan: [],
            average: 0,
        };
    }, [selectedOption, optimized]);

    const structuredSeasonalityData: StructuredSeasonalityData[] =
        useMemo(() => {
            return data.plan.map((item) => {
                return {
                    period: item.periodIndex,
                    contributionIndex: item.coefficient / data.average,
                };
            });
        }, [data.average, data.plan]);

    return (
        <CampaignOptimizationLoader>
            <div className="dark:bg-white dark:bg-opacity-10 rounded-3xl shadow-lg p-6">
                <div className="flex mb-4">
                    <Typography variant="titleS">
                        Natural Demand Seasonality
                    </Typography>
                    <span className="grow" />
                    <Select
                        id={'periodicity'}
                        className="w-36"
                        selectedValue={selectedOption}
                        onSelectChange={(value) => {
                            setSelectedOption(value);
                        }}
                        dismissOnClick
                        label="Periodicity"
                        compact
                    >
                        {availablePeriodOptions.map((periodName) => {
                            return (
                                <Select.Option
                                    key={periodName}
                                    label={OPTION_LABELS[periodName]}
                                    value={periodName}
                                />
                            );
                        })}
                    </Select>
                </div>

                <div className="relative">
                    <div className="flex justify-between">
                        <NaturalDemandSeasonalityChart
                            key={selectedOption}
                            data={structuredSeasonalityData}
                        />
                    </div>
                </div>
            </div>
        </CampaignOptimizationLoader>
    );
};
