import { Header } from '@/components/Header';
import { MediaSpendVsContributionGraphSection } from '@/dashboards/SpendSummary/MediaSpendVsContributionGraphSection';
import { MediaSpendVsContributionShareSection } from '@/dashboards/SpendSummary/MediaSpendVsContributionShareSection';
import { SpendSummaryTableSection } from '@/dashboards/SpendSummary/SpendSummaryTableSection';
import { SpendSummaryActionBar } from '@/dashboards/SpendSummary/components/SpendSummaryActionBar';
import { SpendSummaryProvider } from '@/dashboards/SpendSummary/providers';

export type FilterNode = {
    name: string;
    type: 'category' | 'variable';
};

export const SpendSummary = () => {
    return (
        <SpendSummaryProvider>
            <Header className="pb-6 px-16 pt-6" title="Spend summary">
                <SpendSummaryActionBar />
            </Header>
            <div className="pb-8 px-16">
                <div className="flex flex-row flex-wrap gap-4 w-full h-full justify-stretch">
                    <div className="flex flex-col gap-4 flex-1 w-1/2 max-sm:w-full">
                        <SpendSummaryTableSection />
                        <MediaSpendVsContributionShareSection />
                    </div>
                    <div className="h-full flex-1 w-1/2 max-sm:w-full">
                        <MediaSpendVsContributionGraphSection />
                    </div>
                </div>
            </div>
        </SpendSummaryProvider>
    );
};
