import { jsx as r } from "react/jsx-runtime";
import { Flowbite as d } from "flowbite-react";
import { createContext as a, useState as n, useEffect as i } from "react";
const c = a({
  isDarkMode: !0,
  setDarkMode: () => {
  }
}), m = (o = !0) => {
  const e = n(o);
  return i(() => {
    const t = window.document.documentElement;
    t.classList.remove(e[0] ? "light" : "dark"), t.classList.add(e[0] ? "dark" : "light");
  }, [e]), e;
}, M = ({
  children: o,
  initialDarkMode: e
}) => {
  const [t, s] = m(e);
  return /* @__PURE__ */ r(c.Provider, { value: { isDarkMode: t, setDarkMode: s }, children: /* @__PURE__ */ r(d, { children: o }) });
};
export {
  c as ThemeContext,
  M as ThemeProvider
};
