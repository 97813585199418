import { jsxToHtml } from '@/utils/reactUtils';
import React from 'react';
import { BarTooltip } from './BarTooltip';
import { KpiTooltip } from './KpiTooltip';

export type ContributionSeasonalityTooltipParams = {
    seriesName: string;
    color: string;
    value: number;
    axisValueLabel: string;
    seriesId: string;
    name: string;
    data: {
        name: string;
        value: number;
        isOptimized?: boolean;
    };
};

export type GetTooltipDataResp = {
    optimizedKpi: number | undefined;
    actualKpi: number | undefined;
};

export const renderTooltip =
    ({
        selected,
        formatNumber,
    }: {
        selected: string;
        formatNumber: (value: string | undefined | number) => string;
    }) =>
    (
        params:
            | ContributionSeasonalityTooltipParams
            | ContributionSeasonalityTooltipParams[],
    ) => {
        const selectedData = Array.isArray(params)
            ? params.find((param) => param.seriesName === selected)
            : params;

        if (selectedData?.seriesId === 'kpi') {
            return jsxToHtml(
                <KpiTooltip
                    selected={selectedData}
                    formatter={(value) => {
                        return formatNumber(Number(value));
                    }}
                />,
            );
        }

        return jsxToHtml(
            <BarTooltip
                selected={selectedData}
                formatter={(value) => {
                    return formatNumber(Number(value));
                }}
            />,
        );
    };
