import { jsx as m } from "react/jsx-runtime";
import { getDBConnectionMap as o, addDBConnectionListener as r, setDBConnection as B, removeDBConnection as d } from "../db/instance.js";
import { createContext as v, useContext as x, useState as p, useEffect as f } from "react";
const s = v({
  get: () => {
  },
  set: () => {
  },
  remove: () => {
  }
}), P = () => {
  const e = x(s);
  if (!e)
    throw new Error(
      "useDataBaseContext must be used within a DataBaseProvider"
    );
  return e;
}, b = ({
  children: e
}) => {
  const [c, n] = p(o()), a = (t, D, C) => {
    B(t, D, C), n(o());
  }, i = (t) => {
    d(t), n(o());
  }, u = (t) => c[t];
  return f(() => {
    const t = () => {
      n({ ...o() });
    };
    return r(t), () => {
      r(t);
    };
  }, []), /* @__PURE__ */ m(s.Provider, { value: { get: u, set: a, remove: i }, children: e });
};
export {
  b as DuckDBConnectionProvider,
  P as useDuckDBConnectionContext
};
