import { FloatingFocusManager } from '@floating-ui/react';
import { ButtonProps } from 'flowbite-react';

import React, {
    ComponentProps,
    FC,
    ReactNode,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    HiOutlineChevronDown,
    HiOutlineChevronLeft,
    HiOutlineChevronRight,
    HiOutlineChevronUp,
} from 'react-icons/hi';
import { twMerge } from 'tailwind-merge';

import { dropdownTheme } from '@/components/Dropdown/theme';
import { TreeOptions } from '@/components/TreeSelect/TreeOptions';
import { useBaseFloating, useFloatingInteractions } from '@/hooks/useFloating';

import type { FloatingProps } from '../Floating';

import { Trigger } from '@/components/Dropdown/Trigger';
import { createPortal } from 'react-dom';
import { TreeNode, TreeNodeLike } from './useTreeSelect/types';

export interface TreeSelectProps
    extends Pick<FloatingProps, 'placement' | 'trigger'>,
        Omit<ButtonProps, 'theme'> {
    arrowIcon?: boolean;
    floatingArrow?: boolean;
    inline?: boolean;
    label: ReactNode;
    'data-testid'?: string;
    compact?: boolean;
    dataNodes?: TreeNodeLike[];
    onCheckedChange: (
        selectedNodes: TreeNode[],
        checked: Record<string, boolean>,
    ) => void;
}

const icons: Record<string, FC<ComponentProps<'svg'>>> = {
    top: HiOutlineChevronUp,
    right: HiOutlineChevronRight,
    bottom: HiOutlineChevronDown,
    left: HiOutlineChevronLeft,
};

export type TreeSelectRef = TreeOptionsRef & {
    focus: () => void;
};

export type TreeOptionsRef = {
    hasNodes?: boolean;
    setChecked?: (checked: Record<string, boolean>) => void;
    setNodes?: (
        nodes: TreeNodeLike[],
        checked?: Record<string, boolean>,
    ) => void;
};

export const TreeSelect = forwardRef(
    (
        {
            children,
            className,
            compact,
            dataNodes,
            onCheckedChange: onCheckedChangeProp,
            ...props
        }: TreeSelectProps,
        ref: React.Ref<TreeSelectRef>,
    ) => {
        const treeSelectRef = useRef<TreeOptionsRef | null>(null);
        const onCheckedChangeCbRef = useRef(onCheckedChangeProp);

        const onCheckedChange = useCallback(
            (nodes: TreeNode[], checked: Record<string, boolean>) => {
                if (Object.keys(checked).length === 0) {
                    return;
                }
                onCheckedChangeCbRef.current(nodes, checked);
            },
            [],
        );

        useEffect(() => {
            onCheckedChangeCbRef.current = onCheckedChangeProp;
        }, [onCheckedChangeProp]);

        const [open, setOpen] = useState(false);
        const [buttonWidth, setButtonWidth] = useState<number | undefined>(
            undefined,
        );

        const theme = dropdownTheme;
        const theirProps = props as Omit<TreeSelectProps, 'theme'>;
        const dataTestId = props['data-testid'] || 'dropdown-target';
        const {
            placement = props.inline ? 'bottom-start' : 'bottom',
            trigger = 'click',
            label,
            inline,
            arrowIcon = true,
            ...buttonProps
        } = theirProps;

        const disabled =
            buttonProps.disabled || !treeSelectRef.current?.hasNodes;

        const { context, floatingStyles, refs } =
            useBaseFloating<HTMLButtonElement>({
                open,
                setOpen,
                placement,
            });

        const { getReferenceProps, getFloatingProps } = useFloatingInteractions(
            {
                context,
                role: 'menu',
                trigger,
            },
        );

        useImperativeHandle(ref, () => ({
            hasNodes: treeSelectRef.current?.hasNodes,
            setChecked: treeSelectRef.current?.setChecked,
            setNodes: treeSelectRef.current?.setNodes,
            focus: () => {
                refs.domReference.current?.click();
            },
        }));

        const Icon = useMemo(() => {
            const [p] = placement.split('-');
            return icons[p!] ?? HiOutlineChevronDown;
        }, [placement]);

        return (
            <>
                <Trigger
                    {...buttonProps}
                    disabled={disabled}
                    refs={refs}
                    inline={inline}
                    theme={theme}
                    buttonTheme={{
                        base: 'flex flex-col text-nowrap overflow-ellipsis',
                        color: {
                            dark: 'glass text-dark dark:text-white focus:ring-1 focus:ring-[#1C64F2] focus-visible:outline-none shadow-sm dark:shadow-sm-light',
                        },
                        size: {
                            md: 'text-sm pl-3 pr-3 py-3',
                        },
                        inner: {
                            base: 'h-full w-full flex items-center grow justify-between',
                        },
                    }}
                    data-testid={dataTestId}
                    className={twMerge(
                        theme.floating.target,
                        buttonProps.className,
                        compact ? 'h-9' : undefined,
                    )}
                    setButtonWidth={setButtonWidth}
                    getReferenceProps={getReferenceProps}
                >
                    {label}
                    {arrowIcon && <Icon className={theme.arrowIcon} />}
                </Trigger>

                <FloatingFocusManager context={context} modal={false}>
                    {createPortal(
                        <div
                            ref={refs.setFloating}
                            style={{ ...floatingStyles, minWidth: buttonWidth }}
                            data-testid="dropdown"
                            aria-expanded={open}
                            {...getFloatingProps({
                                className: twMerge(
                                    theme.floating.base,
                                    theme.floating.animation,
                                    'duration-100',
                                    !open && theme.floating.hidden,
                                    theme.floating.style.auto,
                                    className,
                                ),
                            })}
                        >
                            <TreeOptions
                                ref={treeSelectRef}
                                dataNodes={dataNodes}
                                className={theme.content}
                                onChange={onCheckedChange}
                            />
                        </div>,
                        document.body,
                    )}
                </FloatingFocusManager>
            </>
        );
    },
);

TreeSelect.displayName = 'TreeSelect';
