import { cn as x } from "../../utils/cn.js";
const e = {
  inherit: "text-inherit",
  current: "text-current",
  black: "text-black",
  white: "text-white",
  dark: "text-dark",
  gray: "text-gray-300"
}, d = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "lead",
  "p",
  "small"
], t = {
  base: "block antialiased font-sans",
  headingBase: ""
};
t.headingBase = x(t.base, "font-semibold");
const o = {
  styles: {
    base: "text-dark dark:text-white",
    variants: {
      titleXXL: "text-7xl",
      titleXL: "text-5xl leading-tight",
      titleL: "text-4xl leading-[1.3]",
      titleM: "text-3xl leading-snug",
      titleS: "text-2xl leading-snug",
      bodyXL: "text-[1.25rem] font-normal leading-relaxed",
      bodyL: "text-xl font-normal leading-relaxed",
      bodyM: "text-base font-normal leading-relaxed",
      bodyS: "text-sm font-normal leading-normal",
      bodyXS: "text-xs font-normal leading-normal",
      boldXL: "text-4xl font-bold leading-[1.3]",
      boldL: "text-3xl font-bold leading-snug",
      boldM: "text-2xl font-bold leading-snug",
      boldS: "text-xl font-bold leading-snug",
      boldXS: "text-base font-bold leading-relaxed",
      inherit: "text-inherit"
    },
    responsive: {
      titleXXL: "xl:text-7xl lg:text-6xl md:text-5xl text-4xl",
      titleXL: "xl:text-5xl lg:text-4xl md:text-3xl text-2xl",
      titleL: "xl:text-4xl lg:text-3xl md:text-2xl text-xl",
      titleM: "xl:text-3xl lg:text-2xl md:text-xl text-lg",
      titleS: "xl:text-2xl lg:text-xl md:text-lg text-base",
      bodyXL: "xl:text-xl lg:text-lg md:text-base text-sm",
      bodyL: "xl:text-lg lg:text-base md:text-sm text-xs",
      bodyM: "xl:text-base lg:text-sm md:text-xs text-xs",
      bodyS: "xl:text-sm lg:text-xs md:text-xs text-xs",
      bodyXS: "xl:text-xs lg:text-xs md:text-xs text-xs",
      boldXL: "xl:text-4xl lg:text-3xl md:text-2xl text-xl",
      boldL: "xl:text-3xl lg:text-2xl md:text-xl text-base",
      boldM: "xl:text-2xl lg:text-xl md:text-base text-sm",
      boldS: "xl:text-xl lg:text-base md:text-sm text-xs",
      boldXS: "xl:text-base lg:text-sm md:text-xs text-xs",
      inherit: "text-inherit"
    },
    colors: e
  }
};
export {
  d as tags,
  o as typographyTheme
};
