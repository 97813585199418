import { KEY_EVENT_KEYS } from '@/config/constants';
import { cn } from '@/utils';
import { Typography } from '@analytical-alley/ui';
import { ReactNode } from 'react';

export type HighlightLegendItem = {
    name: string;
    highlighted: boolean;
    color?: string;
};

export const HighlightItem = ({
    name,
    highlighted,
    color,
    onClick,
    icon,
}: HighlightLegendItem & {
    onClick?: (name: string) => void;
    icon?: ReactNode;
}) => {
    return (
        <div
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
                if (
                    e.key === KEY_EVENT_KEYS.SPACE ||
                    e.key === KEY_EVENT_KEYS.ENTER
                ) {
                    onClick?.(name);
                }
            }}
            className={cn('flex items-center', {
                'opacity-50': !highlighted,
                'cursor-pointer': !!onClick,
                'cursor-default': !onClick,
            })}
            onClick={() => onClick?.(name)}
        >
            {icon || (
                <div
                    style={{
                        aspectRatio: '1',
                        backgroundColor: color || 'transparent',
                    }}
                    className="w-2.5 h-2.5 rounded-full me-1.5"
                />
            )}
            <Typography className="m-0" variant="bodyXS">
                {name}
            </Typography>
        </div>
    );
};

export const HighlightLegend = ({
    highlighted,
    legendItems,
    onClick,
}: {
    highlighted: string;
    legendItems: HighlightLegendItem[];
    onClick: (name: string) => void;
}) => {
    return (
        <div className="flex justify-center flex-wrap gap-2">
            {legendItems.map((item) => {
                return (
                    <HighlightItem
                        key={item.name}
                        {...item}
                        onClick={onClick}
                        highlighted={!highlighted || item.name === highlighted}
                    />
                );
            })}
        </div>
    );
};
