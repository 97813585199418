import { Tooltip as o } from "./Tooltip.js";
import { TooltipContent as t } from "./TooltipContent.js";
import { TooltipTrigger as i } from "./TooltipTrigger.js";
const e = Object.assign(o, {
  Content: t,
  Trigger: i
});
export {
  e as Tooltip
};
