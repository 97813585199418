import { jsxs as o, Fragment as s, jsx as m } from "react/jsx-runtime";
import "react";
import { cn as c } from "../../utils/cn.js";
import "flowbite-react";
import "../Button/ButtonBase.js";
import "../Checkbox/Checkbox.js";
import "../Modal/index.js";
import { Typography as d } from "../Typography/index.js";
import "../CurrencyInput/CurrencyInput.js";
import "../Datepicker/Datepicker.js";
import "../Dropdown/index.js";
import "../FileInput/index.js";
import "../Tooltip/index.js";
import "../../index-CkWvbrVT.js";
import "../TextInput/index.js";
import "../Select/Select.js";
import "../TabButtons/index.js";
import "../Table/index.js";
import "../Tabs/index.js";
import "../Textarea/index.js";
import "../Timeline/index.js";
import "../ToggleSwitch/index.js";
import "../../config/theme/ThemeProvider.js";
import "../../hooks/useMatchMedia.js";
import { G as g } from "../../iconBase-DkR9t2l_.js";
function u(r) {
  return g({ tag: "svg", attr: { viewBox: "0 0 512 512" }, child: [{ tag: "path", attr: { d: "m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z" }, child: [] }] })(r);
}
const L = ({
  breadcrumb: r,
  title: t,
  header: p,
  onClose: i,
  className: a,
  children: e,
  ref: n,
  onScroll: l
}) => /* @__PURE__ */ o(
  "div",
  {
    onScroll: l,
    className: c("glass-tile p-8 rounded-3xl", a),
    ref: n,
    children: [
      (t || i || p) && /* @__PURE__ */ o(s, { children: [
        r,
        /* @__PURE__ */ o("div", { className: "flex items-center justify-between mx-auto gap-2", children: [
          t && /* @__PURE__ */ m(d, { variant: "titleS", children: t }),
          p,
          i && /* @__PURE__ */ m(
            "button",
            {
              onClick: i,
              className: "dark:bg-glassFill dark:bg-opacity-15 p-2 focus:outline-none rounded-sm",
              children: /* @__PURE__ */ m(u, { size: 16 })
            }
          )
        ] })
      ] }),
      e
    ]
  }
);
export {
  L as Card
};
