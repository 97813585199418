import {
    TitleDashboard,
    titleDashboardSearchSchema,
} from '@/dashboards/TitleDashboard';
import { TitleDashboardRouteProvider } from '@/dashboards/TitleDashboard/providers';
import {
    FEATURE_FLAG_ACTIONS,
    FEATURE_FLAG_SUBJECTS,
    withAuthorization,
} from '@/features/Casl';
import { createFileRoute } from '@tanstack/react-router';

const TitleDashboardRouteComponent = () => {
    return (
        <TitleDashboardRouteProvider routeId={Route.id}>
            <TitleDashboard />
        </TitleDashboardRouteProvider>
    );
};

export const Route = createFileRoute('/_org/project/$projectId/_dashboards/')({
    component: withAuthorization(
        TitleDashboardRouteComponent,
        FEATURE_FLAG_ACTIONS.VIEW,
        FEATURE_FLAG_SUBJECTS.TITLE_DASHBOARD,
    ),
    validateSearch: titleDashboardSearchSchema,
});
