import { useListItem, useMergeRefs } from '@floating-ui/react';
import React, {
    cloneElement,
    forwardRef,
    type ElementType,
    type RefCallback,
} from 'react';

import { Link } from '@tanstack/react-router';
import { twMerge } from 'tailwind-merge';

import {
    ButtonBase,
    type ButtonBaseProps,
} from '@analytical-alley/ui/components/Button';
import {
    PolymorphicComponentPropWithRef,
    PolymorphicRef,
} from '@analytical-alley/ui/types';

import { useSelectContext } from './SelectContext';

export interface SelectOptionTheme {
    container: string;
    base: string;
    icon: string;
}

export type SelectOptionProps<
    T extends ElementType = 'button',
    V extends string | number | null | undefined = undefined,
> = PolymorphicComponentPropWithRef<
    T,
    {
        href?: string;
        onClick?: () => void;
        value: V;
        label: string;
    }
>;

type SelectOptionComponentType = (<
    C extends ElementType = 'button',
    V extends string | number | null | undefined = undefined,
>(
    props: SelectOptionProps<C, V>,
) => React.JSX.Element) & {
    displayName?: string;
};

export const SelectOption = forwardRef(
    <
        T extends ElementType = 'button',
        V extends string | number | null | undefined = undefined,
    >(
        {
            children,
            className,
            onClick,
            value,
            label,
            ...props
        }: SelectOptionProps<T, V>,
        forwardedRef: PolymorphicRef<T>,
    ) => {
        const { ref: listItemRef, index } = useListItem({
            label: typeof children === 'string' ? children : undefined,
        });
        const ref = useMergeRefs([forwardedRef, listItemRef]);
        const {
            value: selectedValue,
            onSelectChange,
            theme: rootTheme,
            activeIndex,
            dismissOnClick,
            getItemProps,
            handleSelect,
        } = useSelectContext();

        const isActiveOption =
            selectedValue != null && value != null && selectedValue === value;

        const isActive = activeIndex === index;
        const theme = rootTheme.floating.item;

        const theirProps = props as ButtonBaseProps<T>;

        const commonProps = {
            className: twMerge(
                theme.base,
                isActiveOption ? theme.active : '',
                isActive
                    ? isActiveOption
                        ? 'bg-white bg-opacity-50'
                        : 'bg-white bg-opacity-10'
                    : '',
                'block',
                theirProps.disabled
                    ? 'cursor-not-allowed bg-transparent hover:bg-transparent'
                    : 'cursor-pointer',
            ),
            ref: ref as unknown as RefCallback<ElementType>,
            ...theirProps,
            ...getItemProps({
                onClick: () => {
                    onClick && onClick();
                    onSelectChange?.(value);
                    dismissOnClick && handleSelect(null);
                },
            }),
            tabIndex: isActive || theirProps.disabled ? 0 : -1,
        };

        return (
            <li role="menuitem" className={theme.container}>
                {children &&
                typeof children === 'object' &&
                'type' in children &&
                children.type === Link ? (
                    cloneElement(children, commonProps)
                ) : (
                    <ButtonBase {...commonProps}>
                        {children || label}
                    </ButtonBase>
                )}
            </li>
        );
    },
) as SelectOptionComponentType;

SelectOption.displayName = 'SelectOption';
