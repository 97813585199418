import type { DBTable } from '@analytical-alley/duckdb-react/types';
import { z } from 'zod';

export const spendsHistorySchema = z.object({
    Month: z.number(),
    Weekday: z.number(),
});

export const spendsHistoryColumnsMap = {
    Month: 'month',
    Weekday: 'weekday',
} as const;

type SchemaType = z.infer<typeof spendsHistorySchema>;

export type TSpendsHistoryTableRow = DBTable<
    SchemaType,
    typeof spendsHistoryColumnsMap
>;
