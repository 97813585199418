import { SCREENS as m } from "../constants/screens.js";
import * as f from "react";
function l(e, t) {
  if (t === e)
    return !1;
  const r = Object.values(t), n = Object.values(e);
  if (r.length !== n.length || r.some((o, c) => o !== n[c]))
    return !0;
  const s = Object.keys(e);
  return Object.keys(t).some((o, c) => o !== s[c]);
}
function O(e, t) {
  return e.matches[t] = !1, e.mediaQueries[t] = {}, e;
}
function y(e) {
  const t = Object.keys(e);
  return typeof window > "u" ? t.reduce(O, {
    mediaQueries: {},
    matches: {}
  }) : t.reduce((r, n) => {
    const s = window.matchMedia(e[n]);
    return r.mediaQueries[n] = s, r.matches[n] = s.matches, r;
  }, {
    mediaQueries: {},
    matches: {}
  });
}
function j(e, t) {
  function r(n, s) {
    return n[s] = e.mediaQueries[s].matches, n;
  }
  switch (t.type) {
    case "updateMatches":
      return {
        matches: Object.keys(e.mediaQueries).reduce(r, {}),
        mediaQueries: e.mediaQueries
      };
    case "setQueries":
      return y(t.queries);
  }
}
function Q(e) {
  const t = f.useRef(e), [r, n] = f.useReducer(j, e, y);
  f.useEffect(() => {
    l(e, t.current) && (n({
      type: "setQueries",
      queries: e
    }), t.current = e);
  }, [e]);
  function s() {
    return n({
      type: "updateMatches"
    });
  }
  function o(i) {
    const u = s;
    return typeof i.addListener < "u" ? i.addListener(u) : i.addEventListener("change", u), u;
  }
  f.useEffect(() => {
    const i = Object.values(r.mediaQueries), u = i.map(o);
    function b(d, h) {
      typeof d.addListener < "u" ? d.removeListener(u[h]) : d.removeEventListener("change", u[h]);
    }
    return () => {
      i.forEach(b);
    };
  }, [r.mediaQueries]);
  const {
    matches: c
  } = r, a = f.useMemo(() => Object.values(c), [c]);
  return {
    matches: c,
    matchesAny: a.some(Boolean),
    matchesAll: a.length > 0 && a.every(Boolean)
  };
}
function g(e) {
  return `(min-width: ${typeof e == "string" ? e : `${e}px`})`;
}
const $ = (e) => {
  if (typeof e == "number")
    return e - 0.02;
  const t = parseFloat(e);
  if (!isNaN(t))
    return `${t - 0.02}${e.split(String(t))[1]}`;
};
function w(e, t) {
  if (e = typeof e == "string" ? e : `${e}px`, !t)
    return `(min-width: ${e})`;
  let r = $(t);
  return r = typeof r == "string" ? r : `${r}px`, `(min-width: ${e}) and (max-width: ${r})`;
}
const E = Object.keys(m), p = Object.fromEntries(
  Object.entries(m).map(([e, t], r) => {
    const n = `${e[0].toUpperCase()}${e.slice(1)}`;
    return [
      e,
      {
        [`is${n}Only`]: w(
          t,
          m[E[r + 1]]
        ),
        [`is${n}`]: g(t)
      }
    ];
  })
), x = (e) => !e || !e.length ? Object.entries(p).reduce(
  (t, [, r]) => Object.assign(t, r),
  {}
) : Object.entries(p).filter(([t]) => e.includes(t)).reduce(
  (t, [, r]) => Object.assign(t, r),
  {}
), M = (e) => {
  const { matches: t } = Q(x(e));
  return t;
};
export {
  M as useMatchMedia
};
