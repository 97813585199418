import { CurrencyBlock } from '@/components/CurrencyBlock';
import { useConfigContext } from '@/context/configContext';
import { CampaignOptimizationLoader } from '@/dashboards/CampaignOptimization/components/CampaignOptimizationLoader';
import { useCampaignOptimizationContext } from '@/dashboards/CampaignOptimization/hooks';
import React from 'react';

export const CampaignSummary = () => {
    const {
        data: {
            contributionTotal,
            simulatedInvestmentTotal,
            contributionTotalWithNewMedia,
        },
    } = useCampaignOptimizationContext();
    const { formatNumber } = useConfigContext();

    return (
        <div className="glass-tile p-6 flex-wrap">
            <CampaignOptimizationLoader>
                <div className="flex justify-center gap-6 flex-wrap">
                    <CurrencyBlock
                        className="grow"
                        title="Media investments"
                        value={formatNumber(simulatedInvestmentTotal)}
                    />
                    <CurrencyBlock
                        className="grow"
                        title="Media Contribution"
                        value={formatNumber(contributionTotal || 0)}
                    />
                    <CurrencyBlock
                        className="grow"
                        title="Total Contribution"
                        value={formatNumber(contributionTotalWithNewMedia)}
                    />
                </div>
            </CampaignOptimizationLoader>
        </div>
    );
};
