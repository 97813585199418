import { roundToDecimals } from '@/utils/numberUtils';
import { duckDB } from '@analytical-alley/duckdb-react';
import { SpendsHistoryTable, TSpendsHistoryTableRow } from './index';

async function getRows({
    period,
    interval,
    endDate,
    startDate,
}: {
    period: 'month' | 'weekday';
    interval: 'daily' | 'weekly';
    startDate?: string;
    endDate?: string;
}) {
    const query = SpendsHistoryTable.rawQuery(
        ({ table }) => {
            return `SELECT * FROM "${table}"('${period}', '${interval}', ${startDate ? `'${startDate}'` : 'NULL'}, ${endDate ? `'${endDate}'` : 'NULL'});`;
        },
        (data: (TSpendsHistoryTableRow & { [key: string]: number })[]) => {
            const columns: Record<number, { [key: string]: number }> = {};

            data.forEach(({ month, weekday, ...variables }) => {
                if (period === 'month') {
                    columns[month] = variables;
                } else {
                    columns[weekday] = variables;
                }
            });

            return columns;
        },
    );
    return await duckDB.query(query);
}

async function getTotals({
    interval,
    startDate,
    endDate,
}: {
    interval: 'daily' | 'weekly';
    startDate?: string;
    endDate?: string;
}) {
    const query = SpendsHistoryTable.rawQuery(
        ({ table, columns: { weekday, month } }) => {
            return `SELECT
                        list_aggregate(
                                list(COLUMNS(* EXCLUDE ("${weekday}", "${month}"))),
                                'sum'
                        )
                    FROM "${table}"('month', '${interval}', ${startDate ? `'${startDate}'` : 'NULL'}, ${endDate ? `'${endDate}'` : 'NULL'});`;
        },
        (data: { [key: string]: number }[]) => {
            return Object.fromEntries(
                Object.entries(data[0]!).map(([key, value]) => [
                    key,
                    roundToDecimals(value),
                ]),
            );
        },
    );
    return await duckDB.query(query);
}

export const spendsHistoryQueries = {
    getRows,
    getTotals,
};
