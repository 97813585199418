import { AnalyticalAlleyLogo, Typography } from '@analytical-alley/ui';
import { useMatchMedia } from '@analytical-alley/ui/hooks';
import { createFileRoute } from '@tanstack/react-router';

const Home = () => {
    const { isLg } = useMatchMedia(['lg']);

    return (
        <div className="relative flex flex-col justify-center items-start px-40 p-16 lg:p-36 pb-48 dark:text-white max-lg:px-5">
            <AnalyticalAlleyLogo
                size={isLg ? 'large' : 'normal'}
                className="absolute top-5 left-5 lg:top-11 lg:left-11"
            >
                <Typography className="font-light" variant="bodyL">
                    hello@analyticalalley.com
                </Typography>

                <span className="p-1 bg-red-400 font-bold rounded-md absolute -top-20 left-40 rotate-12">
                    Beta
                </span>
            </AnalyticalAlleyLogo>
            <div className="self-center mt-0 w-full max-w-[1600px] max-lg:max-w-full">
                <div className="flex gap-16 max-2xl:flex-col max-lg:gap-0">
                    <div className="flex w-full flex-col mt-5 max-lg:mt-10 max-lg:max-w-full">
                        <div className="flex flex-col w-full p-8 mt-11 max-lg:px-5 max-lg:mt-10">
                            <Typography
                                variant="titleXL"
                                className="self-center"
                            >
                                Welcome to Analytical Alley platform!
                            </Typography>
                            <div className="glass-tile p-8 mt-4">
                                <p>
                                    This platform provides access to your
                                    organization`s marketing mix model,
                                    specifically trained with your business data
                                    to meet your unique needs.
                                </p>
                                <br />
                                <p>Have questions or need assistance?</p>
                                <br />
                                <p>
                                    <b>
                                        Contact your personal customer strategy
                                        manager.
                                    </b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Route = createFileRoute('/')({
    component: Home,
});
