import { DEFAULT_GC_TIME } from '@/config';
import { queryClient } from '@/queryClient';
import {
    defineDuckDBTable,
    makeDuckDbQueryContext,
} from '@analytical-alley/duckdb-react';
import { z } from 'zod';

export const DB_NAME = 'analytical-alley';

export const defineTable = <
    TableName extends string,
    TableSchema extends z.ZodObject<any, any>,
    ColumnMap extends { [key in keyof z.infer<TableSchema>]: string },
>(
    tableName: TableName,
    schema: TableSchema,
    columnsMap: ColumnMap,
) => {
    return defineDuckDBTable<string, TableName, TableSchema, ColumnMap>(
        '',
        tableName,
        schema,
        columnsMap,
    );
};

export const {
    useQuery: useDuckDBQuery,
    useDBConnection: useDuckDBConnection,
    Context: DuckDBQueryContext,
    Provider: DuckDBQueryContextProvider,
} = makeDuckDbQueryContext({
    queryClient,
    options: {
        gcTime: DEFAULT_GC_TIME,
    },
});
