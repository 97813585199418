import { AnalyticalAlleyLogo } from '@analytical-alley/ui';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useRef } from 'react';
import { MenuButton } from './MenuButton';

export type HamburgerMenuProps = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    children: ReactNode | ReactNode[];
};

export function HamburgerMenu({
    children,
    open,
    onOpenChange,
}: HamburgerMenuProps) {
    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const toggleMenu = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        event.stopPropagation();
        onOpenChange(!open);
    };

    useEffect(() => {
        if (open) {
            window.scrollTo(0, 0);
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [open]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            event.stopPropagation();

            if (
                menuRef.current &&
                event.target instanceof Node &&
                !menuRef.current.contains(event.target) &&
                event.target !== buttonRef.current
            ) {
                onOpenChange(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onOpenChange]);

    return (
        <>
            <nav className="relative z-50">
                {open && (
                    <div className="glass fixed inset-0 z-40 ease-in-out">
                        <AnalyticalAlleyLogo className="absolute top-6 left-16" />
                    </div>
                )}
                <MenuButton
                    isOpen={open}
                    toggleMenu={toggleMenu}
                    ref={buttonRef}
                />
                <div
                    className={classNames(
                        'lg:w-fix lg:w-auto absolute top-20 right-0 z-50',
                        {
                            hidden: !open,
                        },
                    )}
                    id="navbar-default"
                    ref={menuRef}
                >
                    {children}
                </div>
            </nav>
        </>
    );
}
