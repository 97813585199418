import { Suspense } from 'react';

import type { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';

import { useTheme } from '@analytical-alley/ui/hooks';

import { ChartsThemeProvider } from '@/context/chartsThemeContext';
import { ConfigProvider } from '@/context/configContext';
import { env } from '@/utils/env';
import { showDevTools } from '@/utils/showDevTools';

const Main = () => {
    const { isDarkMode, setDarkMode } = useTheme();

    return (
        <>
            {env.DEV && (
                /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
                <div
                    className="z-50 absolute top-4 left-4 cursor-pointer dark:text-white text-black p-2"
                    onClick={() => setDarkMode((prev) => !prev)}
                >
                    {isDarkMode ? 'Light' : 'Dark'}
                </div>
            )}
            <div className="h-full font-sans dark:text-white">
                <ConfigProvider>
                    <ChartsThemeProvider>
                        <Suspense
                            fallback={
                                <div className="self-center">Loading...</div>
                            }
                        >
                            <Outlet />
                        </Suspense>
                    </ChartsThemeProvider>
                </ConfigProvider>
            </div>
            {showDevTools() && <TanStackRouterDevtools />}
        </>
    );
};

export const Route = createRootRouteWithContext<{
    auth: {
        isAuthenticated: boolean;
    };
    queryClient: QueryClient;
}>()({
    component: Main,
});
