import { BaseChart, ChartMouseHandler, useChart } from '@/components/ECharts';
import {
    EChartsOption,
    ResizedHandler,
    SelectedChangeHandler,
} from '@/components/ECharts/useChart';
import { useConfigContext } from '@/context/configContext';
import { ContributionAndSpendByPeriod } from '@/dashboards/types';
import { BarSeriesOption, LineSeriesOption } from 'echarts';
import { FC, memo, useCallback, useMemo } from 'react';
import { renderTooltip } from './tooltips/renderTooltip';

const currencyFormat = Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'EUR',
    notation: 'compact',
});

type SeriesOption = LineSeriesOption | BarSeriesOption;

type ChartOptions = EChartsOption<SeriesOption>;

const getEChartsOptions = ({
    seriesData,
    xAxisData,
    totalContributionByDate,
    optTotalContributionByDate,
    optSeriesData,
    optXAxisData,
}: {
    xAxisData: string[];
    optXAxisData: string[];
    seriesData: ContributionAndSpendByPeriod;
    optSeriesData: ContributionAndSpendByPeriod;
    totalContributionByDate: Record<string, number>;
    optTotalContributionByDate: Record<string, number>;
}): ChartOptions => {
    const emptyData = Object.keys(seriesData).length
        ? new Array(5).fill('')
        : [];

    const dates = [...xAxisData, ...emptyData, ...optXAxisData];

    const series: ChartOptions['series'] = Object.entries(seriesData).map(
        ([name, { data, color }], index) => {
            return {
                name,
                type: 'bar',
                stack: 'Total',
                symbolSize: 2,
                showSymbol: false,
                showAllSymbol: false,
                triggerLineEvent: true,
                color: color,
                barMaxWidth: 50,
                itemStyle: {
                    borderRadius:
                        index === Object.keys(seriesData).length - 1
                            ? [5, 5, 0, 0]
                            : 0,
                },
                tooltip: {
                    valueFormatter: (value) =>
                        currencyFormat.format(Number(value)),
                },
                data: Object.entries(data).map(([name, { spend }]) => ({
                    name,
                    value: spend,
                })),
            } as unknown as ChartOptions['series'][0];
        },
    );

    const blankSpace = Object.keys(seriesData).length
        ? new Array(xAxisData.length ? xAxisData.length + 5 : 0).fill({})
        : [];

    const seriesOpt: ChartOptions['series'] = Object.entries(optSeriesData).map(
        ([name, { data, color }], index) => {
            return {
                name,
                type: 'bar',
                stack: 'Total',
                symbolSize: 2,
                showSymbol: false,
                showAllSymbol: false,
                triggerLineEvent: true,
                color: color,
                barMaxWidth: 50,
                itemStyle: {
                    borderRadius:
                        index === Object.keys(optSeriesData).length - 1
                            ? [5, 5, 0, 0]
                            : 0,
                },
                data: [
                    ...blankSpace,
                    ...Object.entries(data).map(([period, { spend }]) => {
                        return {
                            name: period,
                            value: spend,
                            isOptimized: true,
                        };
                    }),
                ],
            } as unknown as ChartOptions['series'][0];
        },
    );

    return {
        animation: false,
        tooltip: {
            show: false,
            trigger: 'axis',
            triggerOn: 'mousemove|click',
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            type: 'item',
            confine: true,
            extraCssText: 'max-width: 600px; white-space: pre-wrap;',
            formatter: '',
        },
        legend: {
            show: true,
            showCustom: true,
        },
        grid: {
            top: '5%',
            left: '4%',
            right: '4%',
            containLabel: true,
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: dates,
                axisLabel: {
                    show: true,
                    rotate: 90,
                    formatter: (value: string) => {
                        if (value === '') {
                            return '';
                        }
                        return value;
                    },
                },
                axisLine: {
                    lineStyle: {
                        opacity: 0.3,
                    },
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Media Investment',
                nameLocation: 'middle',
                nameGap: 60,
                nameTextStyle: {
                    fontSize: '1rem',
                },
                axisLabel: {
                    formatter: (value: number) =>
                        currencyFormat.format(Number(value)),
                },
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2,
                    },
                },
            },
            {
                type: 'value',
                name: 'KPI',
                nameGap: 60,
                nameLocation: 'middle',
                nameTextStyle: {
                    fontSize: '1rem',
                },
                axisLabel: {
                    formatter: (value: number) =>
                        currencyFormat.format(Number(value)),
                },
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2,
                    },
                },
            },
        ],
        series: [
            ...series,
            ...seriesOpt,
            {
                id: 'kpi',
                color: '#FFF',
                name: 'KPI',
                type: 'line',
                yAxisIndex: 1,
                lineStyle: {
                    width: 3,
                },
                triggerLineEvent: true,
                data: [
                    ...Object.values(totalContributionByDate).map((value) => {
                        return { value, isOptimized: false };
                    }),
                    ...emptyData,
                    ...Object.values(optTotalContributionByDate).map(
                        (value) => {
                            return { value, isOptimized: true };
                        },
                    ),
                ],
            },
        ],
    };
};

export interface ContributionSeasonalityData {
    data: ContributionAndSpendByPeriod;
    dates: string[];
    totalContributionByDate: Record<string, number>;
}

interface ContributionSeasonalityProps {
    className?: string;
    isLoading: boolean;
    data: {
        previous: ContributionSeasonalityData;
        optimized: ContributionSeasonalityData;
    };
}

export const ContributionSeasonalityBarChart: FC<ContributionSeasonalityProps> =
    memo(({ className, isLoading, data }) => {
        const { formatNumber } = useConfigContext();

        // Get the highest value of the optimized contribution bars / KPI line to position the title correctly
        const optimizedHighestValue = useMemo(() => {
            let highest = 0;
            let type = 'bar';
            if (data?.optimized?.data) {
                Object.values(data?.optimized?.data).reduce((acc, { data }) => {
                    Object.entries(data).map(([period, contribution]) => {
                        if (!acc[period]) {
                            acc[period] = 0;
                        }
                        acc[period] += contribution;
                        if (!highest || acc[period] > highest) {
                            highest = acc[period];
                        }
                    });
                    return acc;
                }, {});
                Object.values(data?.optimized?.totalContributionByDate).map(
                    (value) => {
                        if (value > highest) {
                            type = 'line';
                            highest = value;
                        }
                    },
                );
            }
            return { type, value: highest };
        }, [data]);

        const onResize: ResizedHandler = useCallback(
            (instance) => {
                if (data?.optimized?.dates) {
                    const firstOptimizedDate = data.optimized.dates[0];
                    const lastOptimizedDate =
                        data.optimized.dates[data.optimized.dates.length - 1];

                    if (!firstOptimizedDate || !lastOptimizedDate) {
                        return;
                    }

                    const firstOptimizedBarCoords = instance.convertToPixel(
                        optimizedHighestValue.type === 'line'
                            ? { seriesId: 'kpi' }
                            : { seriesIndex: 0 },
                        [firstOptimizedDate, optimizedHighestValue.value],
                    );
                    const lastOptimizedBarCoords = instance.convertToPixel(
                        optimizedHighestValue.type === 'line'
                            ? { seriesId: 'kpi' }
                            : { seriesIndex: 0 },
                        [lastOptimizedDate, optimizedHighestValue.value],
                    );

                    if (firstOptimizedBarCoords && lastOptimizedBarCoords) {
                        // Approximate Optimized contribution text width
                        const textWidth = 170;
                        // Leave some space between the title and the top of the chart
                        const topSpacing = 25;

                        const leftX = firstOptimizedBarCoords[0] || 0;
                        const rightX = lastOptimizedBarCoords[0] || 0;

                        const titleTopPosition =
                            (firstOptimizedBarCoords[1] || 0) - topSpacing;

                        // Width of the optimized contribution bars
                        const width = rightX - leftX;

                        // If the width of the bars is smaller than the text width, shift the text a bit
                        const offset =
                            width < textWidth ? (textWidth - width) / 2 : 0;

                        const titleLeftPosition = leftX - offset;

                        instance.setOption({
                            graphic: [
                                {
                                    type: 'text',
                                    z: 100,
                                    left: titleLeftPosition,
                                    top: titleTopPosition,
                                    style: {
                                        backgroundColor: 'transparent',
                                        fill: '#FFF',
                                        width: width,
                                        text: 'Optimized contribution',
                                        font: '0.8125rem DM Mono',
                                        textAlign: 'center',
                                    },
                                },
                            ],
                        });
                    }
                }
            },
            [data, optimizedHighestValue],
        );

        const onClick: ChartMouseHandler = useCallback(
            (context, { setSelected }) => {
                if (context.seriesName) {
                    setSelected((prev) => {
                        if (prev || prev === context.seriesName) {
                            return '';
                        }
                        return context.seriesName;
                    });
                } else {
                    setSelected('');
                }
            },
            [],
        );

        const onSelectedChange: SelectedChangeHandler<typeof eChartsOptions> =
            useCallback(
                ({ selected, instance, series }) => {
                    instance.setOption({
                        tooltip: {
                            show: true,
                            trigger: selected ? 'axis' : 'item',
                            formatter: renderTooltip({
                                selected,
                                formatNumber: (value) =>
                                    formatNumber(Number(value)),
                            }),
                        },
                        series: series.map((seriesOption) => {
                            if (selected && selected !== seriesOption.name) {
                                return {
                                    ...seriesOption,
                                    markPoint: {
                                        itemStyle: {
                                            opacity: 0.1,
                                        },
                                    },
                                    itemStyle: { opacity: 0.1 },
                                    lineStyle: { opacity: 0.1 },
                                };
                            }
                            return {
                                ...seriesOption,
                                markPoint: {
                                    itemStyle: {
                                        opacity: 1,
                                    },
                                },
                                itemStyle: { opacity: 1 },
                                lineStyle: { opacity: 1 },
                            };
                        }),
                    });
                },
                [formatNumber],
            );

        const eChartsOptions = useMemo(() => {
            return getEChartsOptions({
                seriesData: data?.previous?.data ?? {},
                xAxisData: data?.previous?.dates ?? [],
                totalContributionByDate:
                    data?.previous?.totalContributionByDate ?? {},
                optSeriesData: data?.optimized?.data ?? {},
                optXAxisData: data?.optimized?.dates ?? [],
                optTotalContributionByDate:
                    data?.optimized?.totalContributionByDate ?? {},
            });
        }, [
            data?.optimized?.data,
            data?.optimized?.dates,
            data?.optimized?.totalContributionByDate,
            data?.previous?.data,
            data?.previous?.dates,
            data?.previous?.totalContributionByDate,
        ]);

        const {
            ref: chartRef,
            chartOptions,
            onEvents,
        } = useChart({
            chartOptions: eChartsOptions,
            onResize,
            onSelectedChange,
            onClick,
        });

        if (isLoading) {
            return (
                <div className={className}>
                    <div className="flex items-center justify-center w-full h-full" />
                </div>
            );
        }

        return (
            <div className={className}>
                <div className="relative w-full h-full">
                    <BaseChart
                        $shouldExpand
                        className="pt-3"
                        ref={chartRef}
                        option={chartOptions}
                        onEvents={onEvents}
                    />
                </div>
            </div>
        );
    });

ContributionSeasonalityBarChart.displayName = 'ContributionSeasonalityBarChart';
