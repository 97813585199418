import { HighlightLegend } from '@/components/ECharts';
import { ToggleSelect } from '@/components/ToggleSelect';
import { WithLoader } from '@/components/WithLoader';
import { CompetitorMediaEffectGraph } from '@/dashboards/CompetitorsMediaInvestment/components/CompetitorMediaEffectGraph';
import { useCompetitorsMediaInvestmentContext } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentContext';
import { useCompetitorsMediaInvestmentQuery } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentQuery';
import { Typography } from '@analytical-alley/ui';
import EChartsReact from 'echarts-for-react';
import React, { useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';

type SeriesData = {
    name: string;
    color: string;
};

type ChartOptions = Array<SeriesData>;

export const CompetitorMediaEffectSection = () => {
    const filterContainerRef = useRef<HTMLDivElement>(null);

    const {
        queryResult: { isLoading, isFetching },
    } = useCompetitorsMediaInvestmentQuery();
    const {
        competitorsMediaInvestmentData: { variableData },
        selectedCompetitorFilter,
        setSelectedCompetitor,
    } = useCompetitorsMediaInvestmentContext();

    const chartRef = useRef<EChartsReact>(null);
    const [selected, setSelected] = React.useState<string>('');
    const [seriesData, setSeriesData] = React.useState<SeriesData[]>([]);

    const onSelected = (value: string) => {
        setSelected((prev: string) => {
            if (prev || prev === value) {
                return '';
            }
            return value;
        });
    };

    const getChartOptions = (chartOptions: ChartOptions) => {
        setSeriesData(chartOptions);
    };

    const competitorFiltersWithImpact = useMemo(() => {
        const impactSet = new Set(
            variableData
                .filter(
                    (obj) =>
                        obj.competitorImpactToClient === 'positive' ||
                        obj.competitorImpactToClient === 'negative' ||
                        obj.variable === 'IKEA',
                )
                .map((obj) => obj.variable),
        );

        return selectedCompetitorFilter.filter((obj) =>
            impactSet.has(obj.name),
        );
    }, [selectedCompetitorFilter, variableData]);

    return (
        <div className="glass-tile p-6">
            <WithLoader isLoading={isLoading} isFetching={isFetching}>
                <div className="flex justify-between mb-4">
                    <Typography variant="titleS">
                        Competitor Media Effect
                    </Typography>
                    <div ref={filterContainerRef} />
                    {filterContainerRef.current
                        ? createPortal(
                              <ToggleSelect
                                  items={competitorFiltersWithImpact?.map(
                                      (item) => ({
                                          label: item.name,
                                          value: item.name,
                                          checked: item.selected,
                                      }),
                                  )}
                                  placement={'bottom-end'}
                                  compact
                                  label={'Competitors'}
                                  showAllCheckbox={true}
                                  onChange={(name, checked) => {
                                      setSelectedCompetitor(name, checked);
                                  }}
                              />,
                              filterContainerRef.current,
                          )
                        : null}
                </div>
                <CompetitorMediaEffectGraph
                    chartRef={chartRef}
                    selected={selected}
                    onSelected={onSelected}
                    getChartOptions={getChartOptions}
                />
                <div className="relative mt-5 pl-14 pr-4 z-20">
                    <HighlightLegend
                        highlighted={selected}
                        legendItems={seriesData?.map((s) => ({
                            name: String(s.name),
                            highlighted: true,
                            color: s.color,
                        }))}
                        onClick={(name) => {
                            if (selected === name) {
                                setSelected('');
                                return;
                            }
                            setSelected(name);
                        }}
                    />
                </div>
            </WithLoader>
        </div>
    );
};
