import { cn } from '@/utils';
import { Spinner } from '@analytical-alley/ui';
import React from 'react';

export interface WithLoaderProps {
    isLoading: boolean;
    isFetching: boolean;
    className?: string;
    children: React.ReactNode;
}

export const WithLoader = ({
    children,
    isLoading,
    isFetching,
    className,
}: WithLoaderProps) => {
    const loading = isLoading || isFetching;

    return (
        <div
            className={cn(className, {
                'animate-pulse': loading,
            })}
        >
            {loading && (
                <div className="absolute rounded-2xl top-0 left-0 w-full h-full z-30">
                    <div className="flex items-center flex-col justify-center h-full max-h-[100vh]">
                        <Spinner size="xl" />
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};
