import { Row } from '@/components/HorizontalBarGraph/Row';
import React, { Children, ReactNode } from 'react';

import { cn } from '@/utils';
import { leftColumnClassName, rightColumnClassName } from './consts';

const HorizontalBarGraphBase = ({ children }: { children: ReactNode }) => {
    const hasChildren = Children.count(children) > 0;

    return (
        <div>
            {hasChildren ? (
                <div className="relative flex flex-col text-xs text-white whitespace-nowrap w-full gap-3 mb-[1.5rem]">
                    <div className="absolute flex h-full flex-row align-middle w-full">
                        <span className={cn(leftColumnClassName)}></span>
                        <div className={cn('relative', rightColumnClassName)}>
                            {/*<ScaleLines />*/}
                        </div>
                    </div>
                    {children}
                </div>
            ) : null}
        </div>
    );
};

export const HorizontalBarGraph = Object.assign(HorizontalBarGraphBase, {
    Row,
});
