import { getDBConnection as y, removeDBConnection as f, setDBConnection as p, getDbInstance as m } from "./db/instance.js";
import { runQueries as w, runQuery as s } from "./db/query.js";
var A = /* @__PURE__ */ ((r) => (r.PARQUET = "parquet", r.DUCKDB = "duckdb", r))(A || {});
async function i(r) {
  var o;
  if (Array.isArray(r)) {
    const t = await w(
      r.map((n) => n.sqlQuery),
      r.map((n) => n.nameMap)
    );
    for (let n = 0; n < t.length; n++)
      (o = r[n]) != null && o.transform && (t[n] = r[n].transform(t[n]));
    return t;
  }
  const e = await s(r.sqlQuery, r.nameMap);
  return r != null && r.transform ? r == null ? void 0 : r.transform(e) : e;
}
const E = async ({
  url: r,
  databaseName: e,
  type: o,
  tableName: t,
  registerWithFileBuffer: n
}) => {
  if (!r)
    return {
      error: "URL is required",
      success: !1
    };
  if (y(e))
    return {
      success: !0
    };
  const a = o || "duckdb";
  if (n) {
    const c = m();
    try {
      const u = await fetch(r);
      if (!u.ok)
        return {
          error: `Network response for getting DuckDB binary from '${r}' was not ok. Status: ${u.status}`,
          success: !1
        };
      await c.registerFileBuffer(
        e,
        new Uint8Array(await u.arrayBuffer())
      );
    } catch (u) {
      return f(e), {
        error: `Download error: ${u}`,
        success: !1
      };
    }
  }
  try {
    switch (a) {
      case "parquet":
        await s(`CREATE SCHEMA IF NOT EXISTS ${e};`), await s(
          `CREATE TABLE ${e}.${t} AS SELECT * FROM read_parquet('${r}');`
        );
        break;
      case "duckdb":
        await s(
          `ATTACH DATABASE '${n ? e : r}' AS "${e}" (READ_ONLY);`
        );
        break;
      default:
        return {
          error: `Invalid connection type: ${a}`,
          success: !1
        };
    }
    return p(e, r, ""), {
      success: !0
    };
  } catch (c) {
    return f(e), {
      error: (c == null ? void 0 : c.message) || "Something went wrong",
      success: !1
    };
  }
};
function Q(r, e) {
  return {
    ...r,
    transform: e
  };
}
function D(r, e, o) {
  return o ? {
    sqlQuery: r,
    transform: e || ((t) => t),
    nameMap: o
  } : typeof e == "function" ? {
    sqlQuery: r,
    transform: e,
    nameMap: o
  } : {
    sqlQuery: r,
    transform: (t) => t,
    nameMap: e
  };
}
function k(r) {
  const e = Object.entries(r).map(([o, t]) => ({
    key: o,
    value: t
  }));
  return async () => {
    const o = await i(e.map(({ value: t }) => t.toSQL()));
    return Object.fromEntries(
      e.map(({ key: t }, n) => [t, o[n]])
    );
  };
}
function S(r) {
  const e = Object.entries(r).map(([o, t]) => ({
    key: o,
    value: t
  }));
  return async () => {
    const o = await i(e.map(({ value: t }) => t));
    return Object.fromEntries(
      e.map(({ key: t }, n) => [t, o[n]])
    );
  };
}
const B = {
  query: i,
  initializeFromUrl: E,
  makeQuery: D,
  transformQuery: Q,
  combineQueries: S,
  combineSqlQueries: k
};
export {
  A as FileConnectionEnum,
  B as duckDB
};
