import React from 'react';

import { ContributionByCategory } from '@/dashboards/ModelContributions/components/ContributionByCategory';
import { ContributionsTable } from '@/dashboards/ModelContributions/components/ContributionsTable';
import { useModelContributionContext } from '@/dashboards/ModelContributions/hooks';
import { ContributionGraphSection } from './ContributionGraphSection';

export const ContributionsSection = () => {
    const {
        search: { modelBreakdown },
    } = useModelContributionContext();

    return (
        <div className="flex flex-row gap-8 h-full">
            <div className="flex flex-col gap-4 w-full xl:flex-row">
                <div className="flex-col h-full w-6/12 max-2xl:w-full">
                    <ContributionGraphSection />
                </div>
                <div className="flex-col w-6/12 max-2xl:w-full">
                    {modelBreakdown === 'category' ? (
                        <ContributionByCategory />
                    ) : (
                        <ContributionsTable />
                    )}
                </div>
            </div>
        </div>
    );
};
