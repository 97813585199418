import { defineTable } from '@/duckdb';
import { modelVsActualColumnsMap, modelVsActualSchema } from './schema';

export { modelVsActualQueries } from './queries';
export { type ModelOrActual, type TModelVsActualTableRow } from './schema';

export const ModelVsActualTable = defineTable(
    'Model VS Actual',
    modelVsActualSchema,
    modelVsActualColumnsMap,
);
