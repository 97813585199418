import { Table } from '@/components/Table';
import { WithLoader } from '@/components/WithLoader';
import { useConfigContext } from '@/context/configContext';
import { useModelContributionContext } from '@/dashboards/ModelContributions/hooks';
import { cn } from '@/utils';
import { Tooltip, Typography } from '@analytical-alley/ui';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import React, { ReactNode, useMemo } from 'react';

export interface TableData {
    category: string;
    variable: string;
    contribution: number;
    percentOfTotalContribution: number;
    contributionPercentTotal: number;
}

const columnHelper = createColumnHelper<TableData>();

interface VariableData {
    category: string;
    variable: string;
    contribution: number;
    percentOfTotalContribution: number;
    contributionPercentTotal: number;
}

const CellRender = <T extends VariableData, E extends ReactNode>({
    getValue,
    row,
    column: { id },
    table,
    className,
    children,
    disabled,
}: CellContext<T, E> & {
    className?: string;
    children?: ReactNode;
    disabled?: boolean;
}) => {
    const header = table.getColumn(id)?.columnDef.header;

    if (disabled) {
        return (
            <span className={cn('flex px-3.5 py-2', className)}>
                {children || getValue()}
            </span>
        );
    }

    return (
        <>
            <Tooltip placement="bottom">
                <Tooltip.Trigger disabled asChild>
                    <span
                        className={cn('flex px-3.5 py-2', className, {
                            'cursor-pointer': !disabled,
                        })}
                    >
                        {children || getValue()}
                    </span>
                </Tooltip.Trigger>
                <Tooltip.Content>
                    <div className="tooltip-content">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="pr-2">
                                        <Typography className="text-black dark:text-black">
                                            Category:
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography className="text-dark dark:text-dark">
                                            {row.original.category}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pr-2">
                                        <Typography className="text-black dark:text-black">
                                            Variable:
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography className="text-dark dark:text-dark">
                                            {row.original.variable}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pr-2">
                                        <Typography className="text-black dark:text-black">
                                            {typeof header === 'function'
                                                ? header({} as never)
                                                : header}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography className="text-dark dark:text-dark">
                                            {children || getValue()}
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Tooltip.Content>
            </Tooltip>
        </>
    );
};

export const ContributionsTable = () => {
    const { formatNumber } = useConfigContext();

    const {
        queryResult: { isLoading, isFetching },
        contributionData,
        selected,
        handleSelectedChange,
        selectedFilterNodes,
    } = useModelContributionContext();

    const columns = useMemo(
        () => [
            columnHelper.accessor('category', {
                header: () => 'Category',
                cell: (info) => {
                    return <CellRender {...info} disabled />;
                },
                footer: () => 'Total',
                meta: {
                    header: {
                        className: 'w-[1px]',
                    },
                    footer: {
                        className: 'text-right',
                    },
                },
            }),
            columnHelper.accessor('variable', {
                cell: (info) => {
                    return <CellRender {...info} disabled />;
                },
                header: () => <span>Variable</span>,
            }),
            columnHelper.accessor('contribution', {
                header: () => 'Contribution',
                meta: {
                    header: {
                        className: 'w-[1px]',
                    },
                    cell: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
                cell: (info) => {
                    return (
                        <CellRender {...info} className="justify-center">
                            {formatNumber(info.getValue())}
                        </CellRender>
                    );
                },
                footer: (info) => {
                    const totalContribution = info.table
                        .getRowModel()
                        .rows.reduce(
                            (acc, row) => acc + row.original.contribution,
                            0,
                        );
                    return (
                        <span className="text-center">
                            {formatNumber(totalContribution)}
                        </span>
                    );
                },
            }),
            columnHelper.accessor('percentOfTotalContribution', {
                header: () => <span>Contribution %</span>,
                cell: (info) => {
                    return (
                        <CellRender {...info} className="justify-center">
                            {info.getValue().toFixed(2)}%
                        </CellRender>
                    );
                },
                meta: {
                    header: {
                        className: 'w-[1px]',
                    },
                    cell: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
                footer: (info) => {
                    const totalContribution = info.table
                        .getRowModel()
                        .rows.reduce(
                            (acc, row) => acc + row.original.contribution,
                            0,
                        );

                    return (
                        <span className="text-center">
                            {info.table
                                .getRowModel()
                                .rows.reduce(
                                    (acc, row) =>
                                        acc +
                                        (row.original.contribution /
                                            totalContribution) *
                                            100,
                                    0,
                                )
                                .toFixed(2)}
                            %
                        </span>
                    );
                },
            }),
            columnHelper.accessor('contributionPercentTotal', {
                header: '% of Total Contribution',
                cell: (info) => {
                    return (
                        <CellRender {...info} className="justify-center">
                            {info.getValue().toFixed(2)}%
                        </CellRender>
                    );
                },
                meta: {
                    header: {
                        className: 'w-[1px]',
                    },
                    cell: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
                footer: (info) => {
                    return (
                        <span className="text-center">
                            {info.table
                                .getRowModel()
                                .rows.reduce(
                                    (acc, row) =>
                                        acc +
                                        row.original.contributionPercentTotal,
                                    0,
                                )
                                .toFixed(2)}
                            %
                        </span>
                    );
                },
            }),
        ],
        [],
    );

    const contributionForAllSelectedVariables = useMemo(
        () =>
            contributionData.variableData.reduce((acc, row) => {
                if (
                    !!(
                        selectedFilterNodes[row.category] ||
                        selectedFilterNodes[row.variable]
                    )
                ) {
                    acc += contributionData.totalContributionByVariable.get(
                        row.variable,
                    )!;
                }

                return acc;
            }, 0),
        [
            contributionData.totalContributionByVariable,
            contributionData.variableData,
            selectedFilterNodes,
        ],
    );

    const tableData = useMemo(
        () =>
            contributionData.variableData
                .filter((d) => {
                    return !!(
                        selectedFilterNodes[d.category] ||
                        selectedFilterNodes[d.variable]
                    );
                })
                .map((data) => ({
                    category: data.category,
                    variable: data.variable,
                    contribution:
                        contributionData.totalContributionByVariable.get(
                            data.variable,
                        )!,
                    percentOfTotalContribution: Number(
                        (contributionData.totalContributionByVariable.get(
                            data.variable,
                        )! /
                            contributionForAllSelectedVariables) *
                            100,
                    ),
                    contributionPercentTotal: Number(
                        (contributionData.totalContributionByVariable.get(
                            data.variable,
                        )! /
                            contributionData.totalContribution) *
                            100,
                    ),
                }))
                .sort((a, b) => {
                    const totalContributionA =
                        contributionData.totalContributionByCategory.get(
                            a.category,
                        )!;
                    const totalContributionB =
                        contributionData.totalContributionByCategory.get(
                            b.category,
                        )!;
                    if (totalContributionA < totalContributionB) {
                        return 1;
                    }
                    if (totalContributionA > totalContributionB) {
                        return -1;
                    }

                    return b.contribution - a.contribution;
                }),
        [
            contributionData.totalContribution,
            contributionData.totalContributionByCategory,
            contributionData.totalContributionByVariable,
            contributionData.variableData,
            contributionForAllSelectedVariables,
            selectedFilterNodes,
        ],
    );

    const isActiveRow = (row: TableData) => row.variable === selected;

    const onRowClick = (row: TableData) => {
        handleSelectedChange(selected === row.variable ? '' : row.variable);
    };

    return (
        <div className="glass-tile p-6">
            <div className="flex mb-4">
                <Typography variant="titleS">
                    Model contribution by variable
                </Typography>
            </div>
            <WithLoader
                className={isLoading ? 'min-h-96' : undefined}
                isLoading={isLoading}
                isFetching={isFetching}
            >
                <Table
                    isLoading={isLoading}
                    data={tableData}
                    columns={columns}
                    shouldSpanRow
                    onRowClick={onRowClick}
                    isActiveRow={isActiveRow}
                    colSpan={{ footer: { value: 2, index: 0 } }}
                />
            </WithLoader>
        </div>
    );
};
