import { duckDB } from '@analytical-alley/duckdb-react';
import { StatisticsIndicatorsTable } from './index';

async function getStatistics() {
    const query = StatisticsIndicatorsTable.select('*').done();
    const res = await duckDB.query(query);
    return res[0];
}

export const statisticsIndicatorsQueries = {
    getStatistics,
};
