import { RoiType } from '@/api/project';
import { DEFAULT_PERIOD } from '@/config/model';
import { SpendSummaryQueryArgs } from '@/dashboards/SpendSummary/types';
import { getROIConfig } from '@/dashboards/SpendSummary/utils';
import { duckDB } from '@analytical-alley/duckdb-react';
import moment from 'moment';
import { SpendSummaryTable, TSpendSummaryTableRow } from './index';

async function getRows({
    period = DEFAULT_PERIOD,
    startDate,
    endDate,
}: SpendSummaryQueryArgs) {
    const startDateArg = startDate
        ? moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : 'NULL';
    const endDateArg = endDate
        ? moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : 'NULL';

    const query = SpendSummaryTable.rawQuery<() => TSpendSummaryTableRow[]>(
        ({ table }) =>
            `SELECT * FROM "${table}"('${period}', '${startDateArg}', '${endDateArg}')`,
    );
    return await duckDB.query(query);
}

async function getRowsWithContributionProduct({
    period = DEFAULT_PERIOD,
    startDate,
    endDate,
    roiType,
}: SpendSummaryQueryArgs & {
    roiType: RoiType;
}) {
    const { contributionProductSql } = getROIConfig(roiType);

    const startDateArg = startDate
        ? moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : 'NULL';
    const endDateArg = endDate
        ? moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : 'NULL';

    const periodFormatSql = () => {
        switch (period) {
            case 'daily':
                return `strftime('%d-%m-%Y', "Period name")`;
            case 'weekly':
                return `strftime('%W-%Y', "Period name")`;
            case 'monthly':
                return `strftime('%m-%Y', "Period name")`;
            case 'quarterly':
                return `'Q' || (extract(quarter from "Period name")::text) || '-' || (extract(year from "Period name")::text)`;
            case 'yearly':
                return `strftime('%Y', "Period name")`;
            default:
                throw new Error('Invalid period format');
        }
    };

    const dateRangeSql = () => {
        if (startDate && endDate) {
            return `WHERE "Period name" >= '${startDateArg}' AND "Period name" <= '${endDateArg}'`;
        }
        if (startDate) {
            return `WHERE "Period name" >= '${startDateArg}'`;
        }
        if (endDate) {
            return `WHERE "Period name" <= '${endDateArg}'`;
        }
        return '';
    };

    const query = SpendSummaryTable.rawQuery<
        () => (TSpendSummaryTableRow & {
            contributionProduct: number;
        })[]
    >(
        ({ columns }) =>
            `WITH formatted_period AS (
                    WITH spend_summary_with_roi AS (
                        SELECT
                            ${contributionProductSql(columns)} AS contributionProduct,
                            * EXCLUDE ("execution_id", "Product", "Market")
                        FROM output_t_dataset_variables
                        WHERE "Spend" > 0
                        ORDER BY "Period name"
                    )
                    SELECT
                        (${periodFormatSql()}) AS "Period name",
                        *
                    FROM spend_summary_with_roi
                    ${dateRangeSql()}
                )
                SELECT
                    "Period name",
                    "Variable",
                    "Category",
                    SUM("contributionProduct") AS "contributionProduct",
                    SUM("Contribution") AS Contribution,
                    SUM("Spend") AS Spend
                FROM formatted_period
                GROUP BY "Period name", "Variable", "Category";`,
    );

    return await duckDB.query(query);
}

export const spendSummaryQueries = {
    getRows,
    getRowsWithContributionProduct,
};
