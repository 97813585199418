import { SpendSummaryQueryArgs } from '@/dashboards/SpendSummary/types';
import { spendSummaryQueries } from '@/duckdb/spend-summary';
import { keepPreviousData, queryOptions } from '@tanstack/react-query';

export const fetchSpendSummary = async (args: SpendSummaryQueryArgs) => {
    const rows = await spendSummaryQueries.getRowsWithContributionProduct(args);

    return { rows };
};

export const modelSpendSummaryQueryOptions = (args: SpendSummaryQueryArgs) => {
    return queryOptions({
        queryKey: [
            'spendSummary',
            args.projectId,
            args.period,
            args.endDate,
            args.startDate,
        ],
        queryFn: () => fetchSpendSummary(args),
        placeholderData: keepPreviousData,
        staleTime: Infinity,
        enabled: !!args.endDate && !!args.startDate,
    });
};
