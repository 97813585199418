import {
    CampaignOptimization,
    campaignOptimizationSearchSchema,
} from '@/dashboards/CampaignOptimization';
import { CampaignOptimizationRouteProvider } from '@/dashboards/CampaignOptimization/providers';
import {
    FEATURE_FLAG_ACTIONS,
    FEATURE_FLAG_SUBJECTS,
    withAuthorization,
} from '@/features/Casl';
import { createFileRoute } from '@tanstack/react-router';

const CampaignOptimizationRouteComponent = () => {
    return (
        <CampaignOptimizationRouteProvider routeId={Route.id}>
            <CampaignOptimization />
        </CampaignOptimizationRouteProvider>
    );
};

export const Route = createFileRoute(
    '/_org/project/$projectId/_dashboards/campaign-optimization',
)({
    component: withAuthorization(
        CampaignOptimizationRouteComponent,
        FEATURE_FLAG_ACTIONS.VIEW,
        FEATURE_FLAG_SUBJECTS.CAMPAIGN_OPTIMIZATION_DASHBOARD,
    ),
    validateSearch: campaignOptimizationSearchSchema,
});
