import { Button, ButtonProps, FlowbiteButtonTheme } from 'flowbite-react';
import {
    Dispatch,
    HTMLProps,
    MutableRefObject,
    ReactElement,
    SetStateAction,
    cloneElement,
    useEffect,
} from 'react';

import { DropdownTheme } from '@/components/Dropdown/theme';
import type { DeepPartial } from '@/types';
import { ExtendedRefs } from '@floating-ui/react';

export interface TriggerProps extends Omit<ButtonProps, 'theme'> {
    refs: ExtendedRefs<HTMLElement>;
    inline?: boolean;
    theme: DropdownTheme;
    setButtonWidth?: Dispatch<SetStateAction<number | undefined>>;
    getReferenceProps: (
        userProps?: HTMLProps<Element> | undefined,
    ) => Record<string, unknown>;
    renderTrigger?: (theme: DropdownTheme) => ReactElement;
    buttonTheme?: DeepPartial<FlowbiteButtonTheme>;
}

export const Trigger = ({
    refs,
    children,
    inline,
    theme,
    disabled,
    setButtonWidth,
    getReferenceProps,
    renderTrigger,
    buttonTheme,
    ...buttonProps
}: TriggerProps) => {
    const ref = refs.reference as MutableRefObject<HTMLElement>;
    const a11yProps = getReferenceProps();

    useEffect(() => {
        if (ref.current) {
            setButtonWidth?.(ref.current.clientWidth);
        }
    }, [ref, setButtonWidth]);

    if (renderTrigger) {
        const triggerElement = renderTrigger(theme);
        return cloneElement(triggerElement, {
            ref: refs.setReference,
            disabled,
            ...a11yProps,
            ...triggerElement.props,
        });
    }

    return (
        <Button
            color={'dark'}
            {...buttonProps}
            theme={buttonTheme}
            disabled={disabled}
            type="button"
            ref={refs.setReference}
            {...a11yProps}
        >
            {children}
        </Button>
    );
};
