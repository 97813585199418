import { OptimizerServiceManagerReturn } from '@/optimizer/createOptimizerServiceManager';
import { optimizerServiceManager } from '@/optimizer/index';
import { ReactNode, useCallback, useState } from 'react';
import { createContext, useContext } from 'use-context-selector';

const DEFAULT_OPTIMIZER_SERVICE_MANAGER: OptimizerServiceManagerReturn = {
    getDiminishingPoints: () => {
        return {};
    },
    getInitialAllocation: () => {
        return {};
    },
    getOptimizedPlan: async () => {
        return {} as any;
    },
    endDate: '',
    startDate: '',
};

const OptimizerContext = createContext<{
    optimizer: OptimizerServiceManagerReturn;
    initOptimizerServiceManager: () => void;
}>({
    optimizer: DEFAULT_OPTIMIZER_SERVICE_MANAGER,
    initOptimizerServiceManager: () => {},
});

export const useOptimizer = () => {
    const context = useContext(OptimizerContext);

    if (!context) {
        throw new Error(
            'useOptimizer must be used within a OptimizerContextProvider',
        );
    }

    return context.optimizer!;
};

export const useInitOptimizerServiceManager = () => {
    const context = useContext(OptimizerContext);

    if (!context) {
        throw new Error(
            'useInitOptimizerServiceManager must be used within a OptimizerContextProvider',
        );
    }

    return context.initOptimizerServiceManager;
};

export const OptimizerContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [optimizerManager, setOptimizerServiceManager] =
        useState<OptimizerServiceManagerReturn>(
            DEFAULT_OPTIMIZER_SERVICE_MANAGER,
        );

    const initOptimizerServiceManager = useCallback(() => {
        setOptimizerServiceManager(optimizerServiceManager);
    }, []);

    return (
        <OptimizerContext.Provider
            value={{ optimizer: optimizerManager, initOptimizerServiceManager }}
        >
            {children}
        </OptimizerContext.Provider>
    );
};
