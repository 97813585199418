import { GetPreviousDataReturn } from '@/dashboards/BudgetOptimization/api';
import { UseVariableDataReturn } from '@/dashboards/BudgetOptimization/hooks/useVariableData';
import { PeriodPlan } from '@/optimizer/createOptimizerServiceManager';

export type StructureMediaBudgetPeriod = 'daily' | 'monthly' | 'weekly';

export const NO_PERIOD_KEYS = ['category', 'variable', 'total'];
export const isPeriodKey = (key: string) => !NO_PERIOD_KEYS.includes(key);

export type StructureMediaBudgetReturn = {
    category: string;
    variable: string;
    total: number;
} & { [key: number]: number };

export const structureMediaBudget = (
    period: StructureMediaBudgetPeriod,
    {
        variablesAndCategories,
        optimizedData,
    }: Pick<UseVariableDataReturn, 'optimizedData'> &
        Pick<GetPreviousDataReturn['roiCurves'], 'variablesAndCategories'>,
) => {
    const data: StructureMediaBudgetReturn[] = [];

    Object.entries(variablesAndCategories.byCategory).forEach(
        ([categoryName, variables]) => {
            variables.forEach((variable) => {
                const obj: { [key: number]: number } = {};
                let total = 0;

                let planToIterateOn: PeriodPlan[] = [];

                if (period === 'daily') {
                    planToIterateOn = optimizedData?.dailyPlan || [];
                } else if (period === 'monthly') {
                    planToIterateOn = optimizedData?.monthlyPlan || [];
                } else if (period === 'weekly') {
                    planToIterateOn = optimizedData?.weeklyPlan || [];
                }

                planToIterateOn.forEach((plan) => {
                    obj[
                        period === 'daily'
                            ? plan['periodIndex']
                            : `${plan['periodIndex']}-${plan['Year']}`
                    ] = plan[variable]!;
                    total += plan[variable]!;
                });
                data.push({
                    category: categoryName,
                    variable,
                    total,
                    ...obj,
                });
            });
        },
    );

    return data;
};
