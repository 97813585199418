import { previewDashboardSearchSchema } from '@/config/commonSearch';
import { z } from 'zod';

export const competitorsMediaInvestmentSchema =
    previewDashboardSearchSchema.extend({
        period: z
            .union([
                z.literal('daily'),
                z.literal('weekly'),
                z.literal('monthly'),
                z.literal('quarterly'),
                z.literal('yearly'),
            ])
            .catch('monthly'),
        startDate: z.string().optional().catch(undefined),
        endDate: z.string().optional().catch(undefined),
    });

export type CompetitorsMediaInvestmentSearch = z.infer<
    typeof competitorsMediaInvestmentSchema
>;

export { CompetitorsMediaInvestment } from './CompetitorsMediaInvestment';
