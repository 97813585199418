import { PercentItem } from '@/components/PercentItem';
import { cn } from '@/utils';
import { Typography } from '@analytical-alley/ui';
import React from 'react';

export const CurrencyBlock = ({
    percentage,
    value,
    title,
    className,
}: {
    title: string;
    value: number | string;
    percentage?: number | undefined;
    className?: string;
}) => {
    return (
        <div
            className={cn(
                'self-stretch h-[8rem] flex flex-col items-center justify-around rounded-lg dark:bg-[#FBFEFF21] px-6 py-4',
                className,
            )}
        >
            <Typography
                variant="titleS"
                responsive={false}
                className="text-[1.437rem] text-nowrap"
            >
                {title}
            </Typography>
            <Typography
                variant="titleM"
                responsive={false}
                className="text-[2rem] font-mono"
            >
                {value}
            </Typography>
            {typeof percentage === 'number' && (
                <span>
                    <PercentItem percent={percentage} />
                </span>
            )}
        </div>
    );
};
