import { roundToDecimals } from '@/utils/numberUtils';
import {
    Optimal,
    OptimizedPlans,
    OptimizerService,
} from '@analytical-alley/roi-optimizer/optimizer/optimizer.service';
import { toJSON } from 'danfojs';
import moment from 'moment/moment';

export type OptimizerServiceManagerReturn = ReturnType<
    typeof createOptimizerServiceManager
>;

export type PeriodPlan = {
    periodIndex: number;
    coefficient: number;
    [key: string]: number;
};

export const createOptimizerServiceManager = (
    optimizerService: OptimizerService,
) => {
    if (!optimizerService) {
        throw new Error('Optimizer service is not initialized');
    }

    const startDate = moment(
        optimizerService.recentYearSpends.column('Period name').head(1)
            .values[0] as string,
    ).format('DD-MM-YYYY');

    const endDate = moment(
        optimizerService.recentYearSpends.column('Period name').tail(1)
            .values[0] as string,
    ).format('DD-MM-YYYY');

    const checkBudget = (
        budget: number | undefined,
    ): { budget: number; budgetError: string | undefined } => {
        if (!budget) {
            return {
                budget: optimizerService.recentYearMediumTotalSpends.sum(),
                budgetError: undefined,
            };
        }

        if (budget < optimizerService.lowestBudget) {
            return {
                budget: optimizerService.lowestBudget,
                budgetError: `Entered budget was too low. Changed it to lowest possible witch is ${optimizerService.lowestBudget}.`,
            };
        }

        if (budget > optimizerService.highestBudget) {
            return {
                budget: optimizerService.highestBudget,
                budgetError: `Entered budget was too high. Changed it to highest possible which is ${optimizerService.highestBudget}.`,
            };
        }

        return {
            budget: optimizerService.refineSpend(budget),
            budgetError: undefined,
        };
    };

    const createFixedSpends = (
        variables: Record<string, number> | undefined,
    ) => {
        if (!variables) {
            return undefined;
        }

        const fixedVariables = new Map<
            string,
            { minimum: number; maximum: number }
        >();

        Object.entries(variables).forEach(([variable, value]) => {
            fixedVariables.set(variable, {
                minimum: value,
                maximum: value,
            });
        });

        return fixedVariables;
    };

    const getInitialAllocation = () => {
        return Object.fromEntries(
            optimizerService.medias.map((medium, index) => {
                return [medium, optimizerService.allocation[index]!];
            }),
        );
    };

    const getDiminishingPoints = () => {
        return Object.fromEntries(
            optimizerService.medias.map((medium) => [
                medium,
                optimizerService.diminishingPoint(
                    optimizerService.roiDataFrame.column(medium),
                ),
            ]),
        );
    };

    const formatOptimal = (optimal: Optimal) => {
        const optimalVariables: Record<
            string,
            {
                maxSpend: number;
                minSpend: number;
                optimalSpend: number;
                kpi: number;
            }
        > = {};

        optimal.mediums.forEach((medium, index) => {
            const maxSpend = optimal.maximumSpends[index]!;
            const minSpend = optimal.minimumSpends[index]!;
            const optimalSpend = optimal.spends[index]!;
            const kpi = roundToDecimals(optimal.kpis[index]!);

            optimalVariables[medium] = {
                maxSpend,
                minSpend,
                optimalSpend,
                kpi,
            };
        });

        return {
            totalKpi: optimal.currentKpi,
            optimalVariables,
        };
    };

    const getOptimizedPlan = async ({
        budget: budgetArg,
        variables,
        startDate: startDateArg,
        endDate: endDateArg,
    }: {
        budget: number | undefined;
        variables?: Record<string, number>;
        startDate?: string;
        endDate?: string;
    }) => {
        const fixedVariables = createFixedSpends(variables);

        const { budget, budgetError } = checkBudget(budgetArg);

        let plan: OptimizedPlans;

        if (startDateArg && endDateArg) {
            plan = await optimizerService.createOptimizedPlans(
                budget,
                moment(startDateArg, 'DD-MM-YYYY').toDate(),
                moment(endDateArg, 'DD-MM-YYYY').toDate(),
                fixedVariables,
            );
        } else {
            plan = await optimizerService.createOptimizedPlans(
                budget,
                fixedVariables,
            );
        }

        const naturalDemand = await optimizerService.getNaturalDemandData();

        const formattedPlan = formatOptimal(plan.optimal);

        return {
            ...formattedPlan,
            monthly: toJSON(plan.monthly.sum({ axis: 1 }))[0] as number[],
            monthlyAverage: plan.monthly.column('Coefficient').mean(),
            monthlyPlan: toJSON(
                plan.monthly.rename({
                    Month: 'periodIndex',
                    Coefficient: 'coefficient',
                }),
            ) as PeriodPlan[],
            dailyPlan: toJSON(
                plan.optimizedPlan
                    .loc({
                        columns: ['Period name', ...optimizerService.medias],
                    })
                    .rename({
                        'Period name': 'periodIndex',
                    }),
            ) as PeriodPlan[],
            weekDayPlan: toJSON(
                plan.weekDay.rename({
                    'ISO Weekday': 'periodIndex',
                    Coefficient: 'coefficient',
                }),
            ) as PeriodPlan[],
            weekDayAverage: plan.weekDay.column('Coefficient').mean(),
            weeklyPlan: toJSON(
                plan.weekly.rename({
                    'ISO Week of Year': 'periodIndex',
                    Coefficient: 'coefficient',
                }),
            ) as PeriodPlan[],
            weeklyAverage: plan.weekly.column('Coefficient').mean(),
            recentYearMediumTotalSpends: toJSON(
                optimizerService.recentYearMediumTotalSpends,
            ),
            recentYearSpends: toJSON(optimizerService.recentYearSpends),
            budget,
            budgetError,
            startDate,
            endDate,
            investmentStep: optimizerService.investmentStep,
            naturalDemand,
        };
    };

    return {
        getOptimizedPlan,
        getDiminishingPoints,
        getInitialAllocation,
        startDate,
        endDate,
    };
};
