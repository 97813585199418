import { FullScreenProvider } from '@/components/FullScreen';
import { Header } from '@/components/Header';
import { ActionBar } from '@/dashboards/ModelContributions/components/ActionBar';
import { ModelContributionProvider } from '@/dashboards/ModelContributions/providers/ModelContributionProvider';
import { useBodyClass } from '@/hooks/useBodyClass';
import { useRef } from 'react';
import { ContributionsSection } from './ContributionsSection';

export const ModelContributions = () => {
    useBodyClass('!bg-dashboard-title');
    const portalRef = useRef<HTMLDivElement>(null);

    return (
        <ModelContributionProvider>
            <Header className="pb-6 px-16 pt-6" title="Model Contributions">
                <ActionBar />
            </Header>
            <div className="pb-8 px-16">
                <div className="transition-all duration-300" ref={portalRef} />

                <FullScreenProvider portalRef={portalRef}>
                    <ContributionsSection />
                </FullScreenProvider>
            </div>
        </ModelContributionProvider>
    );
};
