import { Label } from 'flowbite-react';
import React, { useEffect, useRef } from 'react';

import { SelectRef } from '@/components/Select/Select';
import { KEY_EVENT_KEYS } from '@/config/constants';
import { useConfigContext } from '@/context/configContext';
import { useBudgetOptimizationUrl } from '@/dashboards/BudgetOptimization/hooks/useBudgetOptimizationUrl';
import { CurrencyInput } from '@analytical-alley/ui';

export const ActionBar = () => {
    const {
        search: { budget },
        setSearch,
    } = useBudgetOptimizationUrl();

    const { locale, currency } = useConfigContext();

    const [estimatedBudget, setEstimatedBudget] = React.useState<
        number | undefined
    >(0);

    useEffect(() => {
        setEstimatedBudget(budget);
    }, [budget]);

    const selectRef = useRef<SelectRef>(null);

    const handleSetSearch = () => {
        if (budget !== estimatedBudget) {
            setSearch({ budget: estimatedBudget });
        }
    };

    return (
        <>
            <div className="flex gap-4">
                {/*:TODO This was removed from MVP - Add back when needed!*/}
                {/*<div>
                    <div className="mb-1 block">
                        <Label
                            onClick={() => {
                                selectRef?.current?.click?.();
                            }}
                            className="font-mono font-normal text-nowrap truncate"
                            htmlFor="optimizationBase"
                            value="Choose optimization base"
                        />
                    </div>
                    <Select
                        id={'optimizationBase'}
                        triggerRef={selectRef}
                        className="w-full"
                        selectedValue={optimizationBase}
                        onSelectChange={(value) => {
                            setOptimizationBase(value);
                        }}
                        dismissOnClick
                        label="Choose optimization base"
                        compact
                    >
                        <Select.Option label={'Budget'} value={'budget'} />
                        <Select.Option
                            label={'Media KPI'}
                            value={'media_kpi'}
                        />
                        <Select.Option
                            label={'Total KPI'}
                            value={'total_kpi'}
                        />
                    </Select>
                </div>*/}
                <div>
                    <div className="mb-1 block">
                        <Label
                            onClick={() => {
                                selectRef?.current?.click?.();
                            }}
                            className="font-mono font-normal text-nowrap truncate"
                            htmlFor="budget"
                            value="Enter an estimated budget"
                        />
                    </div>
                    <CurrencyInput
                        id="budget"
                        className="w-full self-center text-nowrap justify-center px-4 py-2 text-sm leading-5 rounded-lg shadow-lg backdrop-blur-[15px] bg-white bg-opacity-10 glass text-dark dark:text-white border-0 focus:ring-1 focus:ring-[#1C64F2] focus-visible:outline-none dark:shadow-sm-light"
                        intlConfig={{ locale, currency }}
                        decimalScale={0}
                        value={estimatedBudget == null ? '' : estimatedBudget}
                        onBlur={handleSetSearch}
                        groupSeparator={' '}
                        onKeyDown={(e) => {
                            if (e.key === KEY_EVENT_KEYS.ENTER) {
                                handleSetSearch();
                            }
                        }}
                        onValueChange={(_, _1, values) => {
                            const value = values?.float;

                            if (value == null) {
                                setEstimatedBudget(0);
                                return;
                            }

                            if (value < 0) {
                                setEstimatedBudget(0);
                                return;
                            }

                            setEstimatedBudget(value);
                        }}
                    />
                </div>
            </div>
        </>
    );
};
