import {
    ShadowBar,
    ShadowBarChart,
    ShadowBarGroup,
} from '@/components/ShadowBarChart';
import { useConfigContext } from '@/context/configContext';
import { useCampaignOptimizationContext } from '@/dashboards/CampaignOptimization/hooks';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { roundToDecimals, roundToNearest } from '@/utils/numberUtils';
import { Typography } from '@analytical-alley/ui';
import React, { useMemo } from 'react';
import { CampaignOptimizationLoader } from '../CampaignOptimizationLoader';

export const ContributionShare = () => {
    const { formatNumber } = useConfigContext();
    const { colorMap } = useDashboardGlobalContext();
    const {
        data: {
            suggestedBudgetData,
            previous: { contributionsByCategory },
            contributionTotalWithNewMedia,
        },
    } = useCampaignOptimizationContext();

    const { data, highestValue } = useMemo(() => {
        let highestValue = 0;

        const suggestedBudget = suggestedBudgetData.reduce(
            (acc, item) => {
                acc[item.category] = item.recommendedBudget;
                return acc;
            },
            {} as {
                [key: string]: number;
            },
        );

        const formattedData: {
            name: string;
            value: number;
            color: string;
            isOptimized: boolean;
        }[] = [];

        Object.entries(
            Object.keys(contributionsByCategory).length
                ? contributionsByCategory
                : suggestedBudget,
        ).forEach(([category, contribution]) => {
            const isOptimized = suggestedBudget[category] != null;
            const value = isOptimized
                ? suggestedBudget[category]!
                : contribution;
            highestValue = Math.max(highestValue, value);

            formattedData.push({
                name: category,
                value:
                    suggestedBudget[category] != null
                        ? suggestedBudget[category]!
                        : contribution,
                color: colorMap.categoryColorMap[category]!,
                isOptimized,
            });
        });

        return {
            data: formattedData.sort((a, b) => b.value - a.value),
            highestValue,
        };
    }, [
        colorMap.categoryColorMap,
        contributionsByCategory,
        suggestedBudgetData,
    ]);

    const height = 250;
    const scale = roundToNearest(highestValue);

    const barRef = React.useRef();

    return (
        <CampaignOptimizationLoader>
            <div className="dark:bg-white dark:bg-opacity-10 rounded-3xl shadow-lg p-6">
                <div className="flex mb-4">
                    <Typography variant="titleS">Contribution Share</Typography>
                    <span className="grow" />
                </div>

                <div className="relative">
                    <div className="flex justify-between">
                        <ShadowBarChart
                            shadowBarRef={barRef}
                            showTickMarks
                            yAxis={'auto'}
                            axisLabelFormatter={formatNumber}
                            height={height}
                            maxValue={scale}
                            yAxisTitle={'Contribution'}
                            options={{
                                yAxisLinesStyle: 'solid',
                                labelVariant: 'rotated',
                            }}
                        >
                            <ShadowBarChart.Content>
                                {data.map(
                                    (
                                        { value, color, isOptimized, name },
                                        index,
                                    ) => {
                                        return (
                                            <ShadowBarGroup
                                                activeDotColor={color}
                                                active={isOptimized}
                                                key={index}
                                                title={name}
                                                tooltipContent={
                                                    <div className="tooltip-content">
                                                        <Typography className="tooltip-header dark:text-dark">
                                                            {name}
                                                        </Typography>
                                                        <table className="font-sans">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pr-2">
                                                                        <Typography className="text-black dark:text-black">
                                                                            Contribution
                                                                            amount:
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography className="text-dark dark:text-dark">
                                                                            {formatNumber(
                                                                                value,
                                                                            )}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="pr-2">
                                                                        <Typography className="text-black dark:text-black">
                                                                            Contribution
                                                                            %:
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography className="text-dark dark:text-dark">
                                                                            {roundToDecimals(
                                                                                Math.max(
                                                                                    (value /
                                                                                        contributionTotalWithNewMedia) *
                                                                                        100,
                                                                                    0,
                                                                                ),
                                                                                2,
                                                                            )}
                                                                            %
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            >
                                                <ShadowBar
                                                    ref={barRef}
                                                    height={value}
                                                    backgroundColor={
                                                        color || '#2B67FF'
                                                    }
                                                >
                                                    <ShadowBar.Title className="text-dark dark:text-white">
                                                        {formatNumber(value)}
                                                    </ShadowBar.Title>
                                                </ShadowBar>
                                            </ShadowBarGroup>
                                        );
                                    },
                                )}
                            </ShadowBarChart.Content>
                        </ShadowBarChart>
                    </div>
                </div>
            </div>
        </CampaignOptimizationLoader>
    );
};
