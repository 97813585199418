import { jsxs as b, Fragment as f, jsx as s } from "react/jsx-runtime";
import { t as k } from "../../../bundle-mjs-3rKE4aij.js";
import { getWeekDays as w, getFirstDayOfTheMonth as v, addDays as x, getFormattedDate as N, isDateEqual as F, isDateInRange as S } from "../../../utils/datepicker.js";
import { useDatePickerContext as j } from "../DatepickerContext.js";
const A = () => {
  const {
    theme: c,
    weekStart: i,
    minDate: o,
    maxDate: d,
    viewDate: l,
    selectedDate: D,
    changeSelectedDate: h,
    language: n
  } = j(), e = c.views.days, u = w(n, i), p = v(l, i);
  return /* @__PURE__ */ b(f, { children: [
    /* @__PURE__ */ s("div", { className: e.header.base, children: u.map((m, t) => /* @__PURE__ */ s("span", { className: e.header.title, children: m }, t)) }),
    /* @__PURE__ */ s("div", { className: e.items.base, children: [...Array(42)].map((m, t) => {
      const a = x(p, t), y = N(n, a, {
        day: "numeric"
      }), g = F(D, a), r = !S(
        a,
        o,
        d
      );
      return /* @__PURE__ */ s(
        "button",
        {
          disabled: r,
          type: "button",
          className: k(
            e.items.item.base,
            g && e.items.item.selected,
            r && e.items.item.disabled
          ),
          onClick: () => {
            r || h(a, !0);
          },
          children: y
        },
        t
      );
    }) })
  ] });
};
export {
  A as DatepickerViewsDays
};
