import { jsx as c } from "react/jsx-runtime";
import { t as p } from "../../../bundle-mjs-3rKE4aij.js";
import { startOfYearPeriod as w, addYears as m, isDateInDecade as b, isDateInRange as u, Views as h } from "../../../utils/datepicker.js";
import { useDatePickerContext as y } from "../DatepickerContext.js";
const C = () => {
  const {
    theme: n,
    selectedDate: i,
    viewDate: e,
    setViewDate: d,
    setView: l
  } = y(), t = n.views.decades;
  return /* @__PURE__ */ c("div", { className: t.items.base, children: [...Array(12)].map((V, r) => {
    const s = w(e, 100) - 10 + r * 10, o = new Date(s, 0, 1), D = m(o, 9), f = b(e, s), a = !u(
      e,
      o,
      D
    );
    return /* @__PURE__ */ c(
      "button",
      {
        disabled: a,
        type: "button",
        className: p(
          t.items.item.base,
          f && t.items.item.selected,
          a && t.items.item.disabled
        ),
        onClick: () => {
          a || !i || (d(
            m(
              e,
              s - i.getFullYear()
            )
          ), l(h.Years));
        },
        children: s
      },
      r
    );
  }) });
};
export {
  C as DatepickerViewsDecades
};
