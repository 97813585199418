import { ModelContributionQueryArgs } from '@/dashboards/ModelContributions/types';

import { DEFAULT_PERIOD } from '@/config/model';
import {
    ModelContributionsTable,
    TModelContributionRow,
} from '@/duckdb/model-contributions';
import { duckDB } from '@analytical-alley/duckdb-react';

async function getRows({
    period = DEFAULT_PERIOD,
    startDate,
    endDate,
    onlyMedia,
}: ModelContributionQueryArgs) {
    const query = ModelContributionsTable.rawQuery<
        () => TModelContributionRow[]
    >(({ table, columns: { category } }) => {
        let query = `SELECT * FROM "${table}"('${period}', ${startDate ? `'${startDate}'` : 'NULL'}, ${endDate ? `'${endDate}'` : 'NULL'})`;
        query = onlyMedia
            ? `${query} WHERE regexp_matches("${category}", '^(Offline media|Online media|Partner|Store media)')`
            : `${query};`;

        return query;
    });
    return await duckDB.query(query);
}

async function getTotalContribution({
    period = DEFAULT_PERIOD,
    startDate,
    endDate,
    onlyMedia = false,
}: ModelContributionQueryArgs & {
    onlyMedia?: boolean;
}) {
    const query = ModelContributionsTable.rawQuery(
        ({ table, columns: { contribution, category } }) => {
            let query = `SELECT SUM("${contribution}") as totalContribution FROM "${table}"('${period}', ${startDate ? `'${startDate}'` : 'NULL'}, ${endDate ? `'${endDate}'` : 'NULL'})`;

            query = onlyMedia
                ? `${query} WHERE regexp_matches("${category}", '^(Offline media|Online media|Partner|Store media)')`
                : `${query};`;

            return query;
        },
        (data: { totalContribution: number }[]) => {
            return data[0]!.totalContribution;
        },
    );
    return await duckDB.query(query);
}

async function getContributionsByCategory(dates?: {
    startDate?: string;
    endDate?: string;
}) {
    const query = ModelContributionsTable.rawQuery(
        ({ columns: { category, contribution, periodName }, table }) => {
            return `SELECT
                    "${category}",
                    SUM("${contribution}") AS "${contribution}"
                FROM "${table}"
                ${dates?.endDate && dates?.startDate ? `WHERE "${periodName}" >= '${dates.startDate}' AND "${periodName}" <= '${dates.endDate}'` : ''}
                GROUP BY "${category}"
                ORDER BY "${contribution}" DESC`;
        },
        (data: Pick<TModelContributionRow, 'contribution' | 'category'>[]) => {
            return data.reduce(
                (acc, row) => {
                    acc[row.category] = row.contribution;
                    return acc;
                },
                {} as Record<string, number>,
            );
        },
    );
    return await duckDB.query(query);
}

async function getContributionsByVariable(dates?: {
    startDate?: string;
    endDate?: string;
}) {
    const query = ModelContributionsTable.rawQuery(
        ({ columns: { variable, contribution, periodName }, table }) => {
            return `SELECT
                    "${variable}",
                    SUM("${contribution}") AS "${contribution}",
                    SUM("Spend") AS "Spend"
                FROM "${table}"
                ${dates?.endDate && dates?.startDate ? `WHERE "${periodName}" >= '${dates.startDate}' AND "${periodName}" <= '${dates.endDate}'` : ''}
                GROUP BY "${variable}"
                ORDER BY "${contribution}" DESC`;
        },
        (data: Pick<TModelContributionRow, 'contribution' | 'variable'>[]) => {
            return data.reduce(
                (acc, row) => {
                    acc[row.variable] = row.contribution;
                    return acc;
                },
                {} as Record<string, number>,
            );
        },
    );
    return await duckDB.query(query);
}

export const modelContributionsQueries = {
    getRows,
    getContributionsByVariable,
    getContributionsByCategory,
    getTotalContribution,
};
