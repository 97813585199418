import { BudgetRowValues } from '@/components/ModifyBudgetRow/ModifyBudgetRow';
import { getBudgetOptimization } from '@/dashboards/BudgetOptimization/api';
import { useDuckDBQuery } from '@/duckdb';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

export type UseVariableDataReturn = ReturnType<typeof useVariableData>;

export const useVariableData = ({
    setBudget,
    budget,
    endDate,
    startDate,
    enabled,
}: {
    budget: number | undefined;
    setBudget: (budget: number) => void;
    startDate?: string;
    endDate?: string;
    enabled?: boolean;
}) => {
    const {
        data: initialData,
        isLoading,
        isFetching,
    } = useDuckDBQuery({
        queryKey: ['bud-opt', budget, startDate, endDate],
        queryFn: () => getBudgetOptimization({ budget, startDate, endDate }),
        enabled,
    });

    useEffect(() => {
        if (initialData) {
            setBudget(initialData.budget);
        }
    }, [initialData, setBudget]);

    const [variableValues, setVariableValuesState] = useState<{
        [key: string]: BudgetRowValues;
    }>({});

    const { data, mutate, isPending, reset } = useMutation({
        mutationFn: (variables: { [key: string]: BudgetRowValues }) =>
            getBudgetOptimization({
                startDate,
                endDate,
                budget,
                variables,
            }),
        onSuccess: (data) => {
            setVariableValuesState((prevState) =>
                Object.entries(data.optimalVariables).reduce(
                    (acc, [key, value]) => {
                        acc[key] = {
                            investment: value.optimalSpend,
                            locked: prevState[key]?.locked ?? true,
                        };
                        return acc;
                    },
                    {} as {
                        [key: string]: BudgetRowValues;
                    },
                ),
            );
        },
    });

    useEffect(() => {
        if (initialData) {
            reset();
            setVariableValuesState(
                Object.entries(initialData.optimalVariables).reduce(
                    (acc, [key, value]) => {
                        acc[key] = {
                            investment: value.optimalSpend,
                            locked: false,
                        };
                        return acc;
                    },
                    {} as {
                        [key: string]: BudgetRowValues;
                    },
                ),
            );
        }
    }, [initialData, reset]);

    const setVariableValues = useCallback(
        (fn: (prev: typeof variableValues) => typeof variableValues) => {
            setVariableValuesState((prev) => {
                const next = fn(prev);
                mutate(next);
                return next;
            });
        },
        [mutate],
    );

    return {
        variableValues,
        setVariableValues,
        initialData,
        optimizedData: data || initialData,
        isLoading: isLoading || isPending,
        isFetching: isFetching || isPending,
    };
};
