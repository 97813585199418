import { PeriodOption } from '@/config/model';
import { duckDB } from '@analytical-alley/duckdb-react';
import {
    ModelOrActual,
    ModelVsActualTable,
    TModelVsActualTableRow,
} from './index';

async function getRows({
    period,
    type,
}: {
    type: ModelOrActual;
    period: PeriodOption;
}) {
    const query = ModelVsActualTable.rawQuery<() => TModelVsActualTableRow[]>(
        ({ table }) => {
            return `SELECT * FROM "${table}"('${type}', '${period}');`;
        },
    );
    return await duckDB.query(query);
}

async function getStartAndEndDates() {
    const query = ModelVsActualTable.rawQuery(
        ({ table }) => {
            return `SELECT MIN("Period name") as startDate, MAX("Period name") as endDate FROM "${table}"`;
        },
        (data: { startDate: string; endDate: string }[]) => {
            return data[0]!;
        },
    );
    return await duckDB.query(query);
}

export const modelVsActualQueries = {
    getRows,
    getStartAndEndDates,
};
