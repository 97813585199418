import { previewSearchParamName } from '@/config/commonSearch';
import { env } from '@/utils/env';
import { createUserAuthManager } from '@analytical-alley/ui/auth';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

const baseUserManagerSettings = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    redirect_uri: env.VITE_CLIENT_URL,
    post_logout_redirect_uri: env.VITE_CLIENT_URL,
    response_type: 'code',
    scope: 'openid profile email roles',
};

export const frontofficeUserManagerSettings: UserManagerSettings = {
    ...baseUserManagerSettings,
    authority: `${env.VITE_OIDC_URL}/realms/${env.VITE_FRONTOFFICE_REALM}`,
    client_id: env.VITE_FRONTOFFICE_CLIENT_ID,
};

export const backofficeUserManagerSettings: UserManagerSettings = {
    ...baseUserManagerSettings,
    authority: `${env.VITE_OIDC_URL}/realms/${env.VITE_BACKOFFICE_REALM}`,
    client_id: env.VITE_BACKOFFICE_CLIENT_ID,
};

const authManagerObj = createUserAuthManager(frontofficeUserManagerSettings, {
    loginRoutePath: '/auth/login',
    redirectSearchParamName: 'redirect',
    searchParamsToPersist: [previewSearchParamName],
});

export const getAuthorizationHeader = authManagerObj.getAuthorizationHeader;
export const axiosAuthInterceptor = authManagerObj.axiosAuthInterceptor;
export const useAuth = authManagerObj.useAuth;
export const updateUserManager = authManagerObj.updateUserManager;
