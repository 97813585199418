import { LegendComponentOption } from 'echarts';
import EChartsReact from 'echarts-for-react';
import { isArray } from 'lodash';
import {
    Dispatch,
    RefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useReducer,
    useRef,
    useState,
} from 'react';

type MakeChartsOption<Series> = Omit<echarts.EChartsOption, 'series'> & {
    legend: LegendOption;
    series: (Series & { name: string; color: string })[];
};

export type EChartsOption<Series = Pick<echarts.EChartsOption, 'series'>> =
    MakeChartsOption<Series>;

export type LegendClickEvent = {
    selected: {
        [name: string]: boolean;
    };
};

type LegendOption = LegendComponentOption & { showCustom?: boolean };

type ToggleLegend = {
    type: 'TOGGLE_LEGEND';
    payload: { name: string; value?: boolean };
};

type SetData = {
    type: 'SET_DATA';
    payload: Partial<GraphState>;
};

type GraphState = {
    legend: { name: string; selected: boolean; color?: string }[];
};

const graphReducer = (state: GraphState, action: ToggleLegend | SetData) => {
    switch (action.type) {
        case 'TOGGLE_LEGEND':
            return {
                ...state,
                legend: state.legend.map((item) => {
                    if (item.name === action.payload.name) {
                        return {
                            ...item,
                            selected:
                                action.payload.value !== undefined
                                    ? action.payload.value
                                    : !item.selected,
                        };
                    }
                    return item;
                }),
            };
        case 'SET_DATA':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

function getLegendItems<
    T extends EChartsOption<{ color?: string; name: string }>,
>(chartsOption: T) {
    const { legend, series } = chartsOption;

    const legendData: {
        name: string;
        selected: boolean;
        color?: string;
    }[] = [];

    if (legend && Array.isArray(series)) {
        if (legend.showCustom)
            series.forEach((s) => {
                if (s.name) {
                    legendData.push({
                        name: s.name,
                        selected: true,
                        color: s.color,
                    });
                }
            });
    }

    return legendData;
}

type MouseHandlerContextOld = {
    seriesName: string;
};

export type ChartMouseHandlerOld = (
    context: MouseHandlerContextOld,
    eChartsInstance: echarts.ECharts,
) => void;

//: TODO Remove this once all the components are migrated to the new useChart (Spend Summary)
export const useChartOld = <
    T extends MakeChartsOption<{ color: string; name: string }>,
>({
    chartOptions,
    onClick,
    onMouseOver,
    onMouseOut,
}: {
    chartOptions: T;
    onClick?: ChartMouseHandlerOld;
    onMouseOver?: ChartMouseHandlerOld;
    onMouseOut?: ChartMouseHandlerOld;
}) => {
    const chartRef = useRef<EChartsReact>(null);

    const [{ legend }, dispatch] = useReducer(graphReducer, {
        legend: [],
    });

    const toggleLegend = useCallback((name: string) => {
        chartRef.current?.getEchartsInstance()?.dispatchAction({
            type: 'legendToggleSelect',
            name,
        });
    }, []);

    const onLegendChange = useCallback((params: LegendClickEvent) => {
        Object.entries(params.selected).forEach(([name, value]) => {
            dispatch({
                type: 'TOGGLE_LEGEND',
                payload: {
                    name,
                    value,
                },
            });
        });
    }, []);

    const onEvents = useMemo(() => {
        return {
            ...(onClick ? { click: onClick } : {}),
            ...(onMouseOver ? { mouseover: onMouseOver } : {}),
            ...(onMouseOut ? { mouseout: onMouseOut } : {}),
        };
    }, [onClick, onMouseOut, onMouseOver]);

    useEffect(() => {
        dispatch({
            type: 'SET_DATA',
            payload: {
                legend: getLegendItems(chartOptions),
            },
        });
    }, [chartOptions]);

    useEffect(() => {
        const listener = () => {
            const eCharts = chartRef.current?.getEchartsInstance();
            eCharts?.resize();
        };

        window.addEventListener('resize', listener);

        return () => window.removeEventListener('resize', listener);
    });

    const combinedChartOptions = useMemo(() => {
        return {
            ...chartOptions,
            legend: {
                ...chartOptions.legend,
                show: chartOptions.legend.showCustom
                    ? false
                    : chartOptions.legend.show,
            },
        };
    }, [chartOptions]);

    return {
        ref: chartRef,
        legendItems: legend,
        toggleLegend,
        chartOptions: combinedChartOptions,
        onLegendChange,
        onEvents,
    };
};

export type ChartRef = {
    getEchartsInstance: () => echarts.ECharts | undefined;
    getLegendItems: () => { name: string; selected: boolean; color?: string }[];
    toggleLegend: (name: string, markSelected?: boolean) => void;
};

type MouseHandlerContext = {
    seriesName: string;
    componentType: string;
    seriesIndex: number;
    seriesId?: string;
    data: any;
};

export type ChartMouseHandler = (
    context: MouseHandlerContext,
    state: {
        instance: echarts.ECharts;
        selected: string;
        setSelected: Dispatch<SetStateAction<string>>;
    },
) => void;

export type SelectedChangeHandler<
    T extends MakeChartsOption<{ color: string; name: string }> = any,
> = (state: {
    selected: string;
    instance: echarts.ECharts;
    series: T[];
}) => void;

export type ResizedHandler = (instance: echarts.ECharts) => void;

export type GlobalOutHandler = (instance: echarts.ECharts) => void;

export const useChart = <
    T extends MakeChartsOption<{ color: string; name: string }>,
>({
    chartRef,
    chartOptions,
    onClick,
    onMouseOver,
    onMouseOut,
    onGlobalOut,
    onSelectedChange,
    onResize,
}: {
    chartRef?: RefObject<ChartRef>;
    chartOptions: T;
    onClick?: ChartMouseHandler;
    onMouseOver?: ChartMouseHandler;
    onMouseOut?: ChartMouseHandler;
    onGlobalOut?: GlobalOutHandler;
    onSelectedChange?: SelectedChangeHandler;
    onResize?: ResizedHandler;
}) => {
    const ref = useRef<EChartsReact>(null);
    const [selected, setSelected] = useState<string>('');
    const selectedRef = useRef(selected);

    useEffect(() => {
        selectedRef.current = selected;
    }, [selected]);

    const [{ legend }, dispatch] = useReducer(graphReducer, {
        legend: [],
    });

    const manualToggleLegend = useCallback(
        (name: string, selected: boolean) => {
            dispatch({
                type: 'TOGGLE_LEGEND',
                payload: {
                    name,
                    value: selected,
                },
            });
        },
        [],
    );

    const onLegendChange = useCallback((params: LegendClickEvent) => {
        Object.entries(params.selected).forEach(([name, value]) => {
            dispatch({
                type: 'TOGGLE_LEGEND',
                payload: {
                    name,
                    value,
                },
            });
        });
    }, []);

    useEffect(() => {
        const instance = ref.current?.getEchartsInstance();

        const handler = () => {
            if (instance && onGlobalOut) onGlobalOut(instance);
        };

        if (instance && onGlobalOut) {
            instance.on('globalout', handler);
        }

        return () => {
            if (instance) {
                instance.off('globalout', handler);
            }
        };
    }, [ref, onGlobalOut]);

    useEffect(() => {
        if (!onSelectedChange) return;
        const eCharts = ref.current?.getEchartsInstance();

        const series =
            chartOptions?.series && isArray(chartOptions.series)
                ? chartOptions.series
                : [];

        if (eCharts) {
            onSelectedChange({ selected, series, instance: eCharts });
        }
    }, [chartOptions.series, onSelectedChange, selected]);

    const toggleLegend = useCallback((name: string, markSelected?: boolean) => {
        if (markSelected !== undefined) {
            if (!markSelected && selectedRef.current === name) {
                setSelected('');
            }
            dispatch({
                type: 'TOGGLE_LEGEND',
                payload: {
                    name,
                    value: markSelected,
                },
            });
            ref.current?.getEchartsInstance()?.dispatchAction({
                type: markSelected ? 'legendSelect' : 'legendUnSelect',
                name,
            });
            return;
        }

        ref.current?.getEchartsInstance()?.dispatchAction({
            type: 'legendToggleSelect',
            name,
        });
    }, []);

    const createHandler = useCallback((handler: ChartMouseHandler) => {
        return (context: MouseHandlerContext, instance: echarts.ECharts) =>
            handler(context, {
                instance,
                selected: selectedRef.current,
                setSelected,
            });
    }, []);

    const onEvents = useMemo(() => {
        return {
            ...(onClick ? { click: createHandler(onClick) } : {}),
            ...(onMouseOver ? { mouseover: createHandler(onMouseOver) } : {}),
            ...(onMouseOut ? { mouseout: createHandler(onMouseOut) } : {}),
            legendselectchanged: onLegendChange,
        };
    }, [createHandler, onClick, onLegendChange, onMouseOut, onMouseOver]);

    useEffect(() => {
        dispatch({
            type: 'SET_DATA',
            payload: {
                legend: getLegendItems(chartOptions),
            },
        });
    }, [chartOptions]);

    useEffect(() => {
        const instance = ref.current?.getEchartsInstance();

        const zr = instance?.getZr();

        const handler = (e: { target: unknown | undefined }) => {
            if (selectedRef.current && !e.target) setSelected('');
        };

        if (zr) {
            zr.on('click', handler);
        }

        return () => {
            if (zr) {
                zr.off('click', handler);
            }
        };
    }, [ref]);

    useEffect(() => {
        const listener = () => {
            const eCharts = ref.current?.getEchartsInstance();
            eCharts?.resize();
            if (onResize && eCharts) onResize(eCharts);
        };

        if (onResize) listener();

        window.addEventListener('resize', listener);

        return () => window.removeEventListener('resize', listener);
    }, [onResize, ref]);

    const combinedChartOptions = useMemo(() => {
        return {
            ...chartOptions,
            legend: {
                ...chartOptions.legend,
                show: chartOptions.legend?.showCustom
                    ? false
                    : chartOptions.legend?.show,
            },
        };
    }, [chartOptions]);

    useImperativeHandle(chartRef, () => {
        return {
            getEchartsInstance: () => ref.current?.getEchartsInstance(),
            getLegendItems: () => legend,
            toggleLegend,
        };
    }, [legend, toggleLegend]);

    return {
        ref,
        manualToggleLegend,
        legendItems: legend,
        toggleLegend,
        chartOptions: combinedChartOptions,
        onLegendChange,
        onEvents,
        selected,
        setSelected,
    };
};
