import React from 'react';

import { useBudgetOptimizationContext } from '@/dashboards/BudgetOptimization/context/BudgetOptimizationContext';
import { Typography } from '@analytical-alley/ui';
import { BudgetShareByCategoryChart } from './BudgetShareByCategoryChart';

export const BudgetShareByCategory = () => {
    const {
        data: { budgetByCategoryData },
    } = useBudgetOptimizationContext();

    return (
        <>
            <Typography variant="titleS">Budget share by category</Typography>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Typography className="mb-2" variant="bodyL">
                        Previous
                    </Typography>
                    <BudgetShareByCategoryChart
                        showPercent={false}
                        data={budgetByCategoryData.previous}
                        tooltipTitle="Previous Budget"
                    />
                </div>
                <div>
                    <Typography className="mb-2" variant="bodyL">
                        Optimized
                    </Typography>
                    <BudgetShareByCategoryChart
                        showPercent={true}
                        data={budgetByCategoryData.simulated}
                        tooltipTitle="Recommended Budget"
                    />
                </div>
            </div>
        </>
    );
};

BudgetShareByCategoryChart.displayName = 'BudgetShareByCategoryChart';
