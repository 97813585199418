import React, { RefObject, useMemo } from 'react';

import { Table, TableRef } from '@/components/Table';
import { useConfigContext } from '@/context/configContext';
import {
    StructureMediaBudgetReturn,
    isPeriodKey,
} from '@/dashboards/utils/data/structureMediaBudget';
import { createColumnHelper } from '@tanstack/react-table';

type ColumnValue = {
    category: string;
    variable: string;
    total: number;
    [key: string]: number | string;
};

const columnHelper = createColumnHelper<ColumnValue>();

export interface MediaSpendSeasonalityTableProps {
    tableRef: RefObject<TableRef>;
    data: StructureMediaBudgetReturn[];
    formatPeriodHeader: (period: string) => string;
}

export const MediaSpendSeasonalityTable = ({
    tableRef,
    data,
    formatPeriodHeader,
}: MediaSpendSeasonalityTableProps) => {
    const { formatNumber } = useConfigContext();

    const periodKeys = useMemo(
        () => Object.keys(data[0] || {}).filter(isPeriodKey),
        [data],
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor('category', {
                cell: (info) => <span className="p-2">{info.getValue()}</span>,
                header: '',
                meta: {
                    cell: {
                        className: 'px-3.5 py-2 text-center',
                    },
                    footer: {
                        className: 'rounded-br-none',
                    },
                },
            }),
            columnHelper.accessor('variable', {
                id: 'variable',
                cell: (info) => info.getValue(),
                header: '',
                footer: () => 'Total',
                meta: {
                    cell: {
                        className: 'px-3.5 py-2 text-center',
                    },
                    footer: {
                        className: 'rounded-br-none text-right',
                    },
                },
            }),
            ...periodKeys.map((column) =>
                columnHelper.accessor(column, {
                    id: column,
                    cell: (info) => formatNumber(Number(info.getValue())),
                    header: () => (
                        <span className="flex justify-center text-nowrap">
                            {formatPeriodHeader(column)}
                        </span>
                    ),
                    meta: {
                        cell: {
                            className: 'px-3.5 py-2 text-center',
                        },
                        footer: {
                            className:
                                'rounded-bl-none rounded-br-none text-center',
                        },
                    },
                    footer: (props) => {
                        const total = props.table
                            .getRowModel()
                            .rows.reduce((acc, row) => {
                                return acc + (row.original[column] as number);
                            }, 0);
                        return (
                            <span className="text-right">
                                {formatNumber(total)}
                            </span>
                        );
                    },
                }),
            ),
            columnHelper.accessor('total', {
                cell: (info) => formatNumber(info.getValue()),
                header: () => <span className="">Total</span>,
                meta: {
                    cell: {
                        className: 'px-3.5 py-2 text-center',
                    },
                    footer: {
                        className: 'rounded-bl-none h-12',
                    },
                },
                footer: (props) => {
                    const total = props.table
                        .getRowModel()
                        .rows.reduce((acc, row) => {
                            return acc + row.original.total;
                        }, 0);
                    return (
                        <span className="text-right">
                            {formatNumber(total)}
                        </span>
                    );
                },
            }),
        ],
        [formatNumber, formatPeriodHeader, periodKeys],
    );

    if (!data.length || !periodKeys.length) {
        return null;
    }

    return (
        <Table
            tableRef={tableRef}
            data={data}
            columns={columns}
            shouldSpanRow
            footerClassName="h-12"
            pinnedLeftCount={2}
            pinnedRightCount={1}
            colSpan={{
                header: {
                    value: 2,
                    index: 0,
                },
                footer: {
                    value: 2,
                    index: 0,
                },
            }}
        />
    );
};
