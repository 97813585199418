import { PERIOD_OPTIONS, PeriodOption } from '@/config/model';

export const getPeriodOptionsForModel = <
    T extends PeriodOption[] | undefined = undefined,
>(
    period: PeriodOption,
    pick?: T,
): T extends PeriodOption[] ? T : PeriodOption[] => {
    const periodOptions = PERIOD_OPTIONS.slice(
        PERIOD_OPTIONS.findIndex((p) => p === period),
    );

    if (pick) {
        return pick.filter((p) => periodOptions.includes(p)) as any;
    }

    return periodOptions as any;
};
