import { ErrorScreen } from '@/components/Error/ErrorScreen';
import { queryClient } from '@/queryClient';
import { routeTree } from '@/routeTree.gen';
import { createRouter } from '@tanstack/react-router';

export const router = createRouter({
    routeTree,
    context: {
        queryClient,
        auth: {
            isAuthenticated: false,
        },
    },
    defaultPreloadStaleTime: 0,
    defaultNotFoundComponent: () => <div>Not Found!</div>,
    defaultErrorComponent: ErrorScreen,
});

export type Router = typeof router;

declare module '@tanstack/react-router' {
    interface Register {
        router: Router;
    }
}
