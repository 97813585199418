import { PercentItem } from '@/components/PercentItem';
import {
    ShadowBar,
    ShadowBarChart,
    ShadowBarGroup,
} from '@/components/ShadowBarChart';
import { useConfigContext } from '@/context/configContext';
import { useBudgetOptimizationContext } from '@/dashboards/BudgetOptimization/context/BudgetOptimizationContext';
import { useBudgetOptimizationUrl } from '@/dashboards/BudgetOptimization/hooks/useBudgetOptimizationUrl';
import { twCn } from '@/utils';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { Typography } from '@analytical-alley/ui';
import React from 'react';

export const BudgetChangeByVariable = () => {
    const {
        search: { budget },
    } = useBudgetOptimizationUrl();
    const {
        data: { budgetByVariableData, highestVariableValue },
    } = useBudgetOptimizationContext();

    const { formatNumber } = useConfigContext();

    const height = 300;
    const scale = highestVariableValue;

    const dataLength = Object.keys(budgetByVariableData).length;

    return (
        <>
            <div className="flex mb-4 mt-2">
                <Typography variant="titleS">
                    Budget change by variable
                </Typography>
                <span className="grow" />
                <div>
                    <div className="flex items-center">
                        <div className="w-2.5 h-2.5 bg-[#2B67FF] rounded-full mr-1.5 mb-0.5" />
                        <Typography className="m-0" variant="bodyXS">
                            Recommended budget
                        </Typography>
                    </div>
                    <div className="flex items-center">
                        <div className="w-2.5 h-2.5 bg-indigo-300 bg-opacity-50 rounded-full mr-1.5 mb-0.5" />
                        <Typography className="m-0" variant="bodyXS">
                            Previous budget
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="relative">
                <div
                    className={twCn(
                        'grid grid-cols-1 flex-wrap justify-around gap-3',
                        {
                            'grid-cols-2': dataLength === 2 || dataLength > 3,
                            'grid-cols-3': dataLength === 3,
                        },
                    )}
                >
                    {Object.entries(budgetByVariableData).map(
                        ([key, entry]) => {
                            return (
                                <div key={key} className="grow">
                                    <ShadowBarChart
                                        height={height}
                                        maxValue={scale}
                                    >
                                        <ShadowBarChart.Title>
                                            {capitalizeFirstLetter(key)}
                                        </ShadowBarChart.Title>
                                        <ShadowBarChart.Content>
                                            {entry.map(
                                                (
                                                    {
                                                        variable,
                                                        simulatedBudget,
                                                        previousBudget,
                                                        category,
                                                    },
                                                    index,
                                                ) => {
                                                    const isNegative =
                                                        simulatedBudget <
                                                        previousBudget;
                                                    const simulatedBudgetVsActualPercent =
                                                        Math.round(
                                                            (simulatedBudget *
                                                                100) /
                                                                previousBudget,
                                                        );
                                                    const simulatedBudgetVsActualPercentDisplay =
                                                        isNegative
                                                            ? -(
                                                                  100 -
                                                                  simulatedBudgetVsActualPercent
                                                              )
                                                            : simulatedBudgetVsActualPercent;
                                                    return (
                                                        <ShadowBarGroup
                                                            key={index}
                                                            title={variable}
                                                            tooltipContent={
                                                                <div className="tooltip-content">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="pr-2">
                                                                                    <Typography className="text-black dark:text-black">
                                                                                        Category:
                                                                                    </Typography>
                                                                                </td>
                                                                                <td>
                                                                                    <Typography className="text-dark dark:text-dark">
                                                                                        {
                                                                                            category
                                                                                        }
                                                                                    </Typography>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="pr-2">
                                                                                    <Typography className="text-black dark:text-black">
                                                                                        Variable:
                                                                                    </Typography>
                                                                                </td>
                                                                                <td>
                                                                                    <Typography className="text-dark dark:text-dark">
                                                                                        {
                                                                                            variable
                                                                                        }
                                                                                    </Typography>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="pr-2">
                                                                                    <Typography className="text-black dark:text-black">
                                                                                        Recommended
                                                                                        media
                                                                                        budget:
                                                                                    </Typography>
                                                                                </td>
                                                                                <td>
                                                                                    <Typography className="text-dark dark:text-dark">
                                                                                        {formatNumber(
                                                                                            simulatedBudget,
                                                                                        )}
                                                                                    </Typography>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="pr-2">
                                                                                    <Typography className="text-black dark:text-black">
                                                                                        Previous
                                                                                        media
                                                                                        budget:
                                                                                    </Typography>
                                                                                </td>
                                                                                <td>
                                                                                    <Typography className="text-dark dark:text-dark">
                                                                                        {formatNumber(
                                                                                            previousBudget,
                                                                                        )}
                                                                                    </Typography>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="pr-2">
                                                                                    <Typography className="text-black dark:text-black">
                                                                                        Recommended
                                                                                        budget
                                                                                        change:
                                                                                    </Typography>
                                                                                </td>
                                                                                <td>
                                                                                    <Typography className="text-dark dark:text-dark">
                                                                                        {
                                                                                            simulatedBudgetVsActualPercentDisplay
                                                                                        }

                                                                                        %
                                                                                    </Typography>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="pr-2">
                                                                                    <Typography className="text-black dark:text-black">
                                                                                        Share
                                                                                        of
                                                                                        the
                                                                                        total
                                                                                        budget:
                                                                                    </Typography>
                                                                                </td>
                                                                                <td>
                                                                                    <Typography className="text-dark dark:text-dark">
                                                                                        {budget
                                                                                            ? Math.round(
                                                                                                  (simulatedBudget *
                                                                                                      100) /
                                                                                                      budget,
                                                                                              )
                                                                                            : '-'}

                                                                                        %
                                                                                    </Typography>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            }
                                                        >
                                                            <ShadowBar
                                                                height={
                                                                    previousBudget
                                                                }
                                                                variant="secondary"
                                                            />
                                                            <ShadowBar
                                                                height={
                                                                    simulatedBudget
                                                                }
                                                                variant="primary"
                                                            >
                                                                <ShadowBar.Title>
                                                                    <PercentItem
                                                                        percent={
                                                                            simulatedBudgetVsActualPercentDisplay
                                                                        }
                                                                    />
                                                                </ShadowBar.Title>
                                                            </ShadowBar>
                                                        </ShadowBarGroup>
                                                    );
                                                },
                                            )}
                                        </ShadowBarChart.Content>
                                    </ShadowBarChart>
                                </div>
                            );
                        },
                    )}
                </div>
            </div>
        </>
    );
};
