import { ROICurvesToolipParams } from '@/dashboards/BudgetOptimization/components/ROICurves/tooltips/renderTooltip';
import { Typography } from '@analytical-alley/ui';
import React from 'react';

export const SelectedTooltip = ({
    params,
    selectedSeriesName,
    formatter,
}: {
    params: ROICurvesToolipParams;
    selectedSeriesName?: string;
    formatter?: (value: string | undefined | number) => string;
}) => {
    if (!selectedSeriesName) {
        return null;
    }

    const hoveredItem = params.find(
        (param) => param.seriesName === selectedSeriesName,
    );

    if (!hoveredItem) {
        return null;
    }

    return (
        <div className="tooltip-content-md">
            <Typography className="text-dark dark:text-dark">
                {hoveredItem.seriesName}
            </Typography>
            <table className="font-sans">
                <tbody>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Investment:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {formatter
                                    ? formatter(params[0]?.axisValueLabel)
                                    : params[0]?.axisValueLabel}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Contribution:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {formatter
                                    ? formatter(hoveredItem.value)
                                    : hoveredItem.value}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
