import { defineTable } from '@/duckdb';
import {
    statisticsIndicatorsTableColumnsMap,
    statisticsIndicatorsTableSchema,
} from './schema';

export { statisticsIndicatorsQueries } from './queries';

export const StatisticsIndicatorsTable = defineTable(
    'Statistics (Indicators)',
    statisticsIndicatorsTableSchema,
    statisticsIndicatorsTableColumnsMap,
);
