import { useAuth } from '@/config/auth';
import { Button, Card, FullPageLoader } from '@analytical-alley/ui';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

export const LoginPage = () => {
    const { redirect } = Route.useSearch();

    const auth = useAuth({ tryAutoSignIn: true });

    if (auth.isLoading) {
        return <FullPageLoader />;
    }

    return (
        <main className="flex justify-center align-middle items-center h-svh">
            <Card title="Welcome to Analytical Alley">
                <div className="flex flex-col gap-4 justify-center pt-6">
                    <Button
                        size="sm"
                        color="light"
                        onClick={async () =>
                            await auth.signinRedirect(
                                redirect
                                    ? {
                                          redirect_uri:
                                              window.location.origin + redirect,
                                      }
                                    : undefined,
                            )
                        }
                    >
                        <img
                            className="h-5 w-5"
                            src="https://www.keycloak.org/resources/images/icon.svg"
                            alt="Keycloak Icon"
                        />
                        Sign in to continue
                    </Button>
                </div>
            </Card>
        </main>
    );
};

const loginSearchSchema = z.object({
    redirect: z.string().optional(),
});

export const Route = createFileRoute('/auth/login')({
    component: LoginPage,
    loaderDeps: ({ search }) => ({ search }),
    loader: ({ context: { auth }, deps: { search } }) => {
        if (auth.isAuthenticated) {
            throw redirect({
                to: search.redirect || '/',
            });
        }
    },
    validateSearch: loginSearchSchema,
});
