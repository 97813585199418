import { BudgetRowValues } from '@/components/ModifyBudgetRow/ModifyBudgetRow';
import {
    useBudgetOptimizationData,
    UseBudgetOptimizationQueryResponse,
} from '@/dashboards/BudgetOptimization/hooks/useBudgetOptimizationData';
import { ReactNode, useCallback, useState } from 'react';
import { createContext, useContext } from 'use-context-selector';

interface BudgetOptimizationContextValue {
    margin: number;
    onSubmit: SubmitFunc;
    variableValues: { [key: string]: BudgetRowValues };
    optimizationBase: 'media_kpi' | 'total_kpi' | 'budget';
    setOptimizationBase: (value: 'media_kpi' | 'total_kpi' | 'budget') => void;
    isLoading: boolean;
    isFetching: boolean;
}

const BudgetOptimizationContext = createContext<
    BudgetOptimizationContextValue &
        Pick<UseBudgetOptimizationQueryResponse, 'data'>
>({
    margin: 100,
    onSubmit: () => {},
    variableValues: {},
    optimizationBase: 'budget',
    setOptimizationBase: () => {},
    isLoading: false,
    isFetching: false,
    data: {
        previous: undefined,
        optimized: undefined,
        highestVariableValue: 0,
        highestCategoryValue: 0,
        suggestedBudgetData: [],
        budgetByCategoryData: {
            previous: [],
            simulated: [],
        },
        monthlyMediaInvestment: {
            monthlyMediaData: [],
            highestValue: 0,
        },
        initialAllocation: {},
        budgetByVariableData: {},
        contributionTotal: 0,
        previousPeriodInvestmentTotal: 0,
        simulatedInvestmentTotal: 0,
        diminishingPoints: {},
    },
});

export const useBudgetOptimizationContext = () => {
    const context = useContext(BudgetOptimizationContext);

    if (!context) {
        throw new Error(
            'useBudgetOptimizationContext must be used within a BudgetOptimizationContextProvider',
        );
    }

    return context;
};

interface SubmitFunc {
    (key: string, data: BudgetRowValues): void;
    (key: 'margin', data: number | null): void;
}

export const BudgetOptimizationContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [optimizationBase, setOptimizationBaseState] = useState<
        'media_kpi' | 'total_kpi' | 'budget'
    >('budget');

    const setOptimizationBase = useCallback(
        (value: 'media_kpi' | 'total_kpi' | 'budget') => {
            setOptimizationBaseState(value);
        },
        [],
    );

    const { data, isLoading, isFetching, variableValues, setVariableValues } =
        useBudgetOptimizationData();

    const [margin, setMargin] = useState<number>(100);

    const onSubmit: SubmitFunc = useCallback(
        (key, data) => {
            if (key === 'margin') {
                setMargin(data);
                return;
            }
            setVariableValues((prev) => ({
                ...prev,
                [key]: data,
            }));
        },
        [setVariableValues],
    );

    return (
        <BudgetOptimizationContext.Provider
            value={{
                margin,
                onSubmit,
                data,
                isLoading,
                isFetching,
                variableValues,
                optimizationBase,
                setOptimizationBase,
            }}
        >
            {children}
        </BudgetOptimizationContext.Provider>
    );
};
