import { useRouteUrl } from '@/hooks';
import { getRouteApi } from '@tanstack/react-router';

const route = getRouteApi(
    '/_org/project/$projectId/_dashboards/competitors-media-investment',
);

export const useCompetitorsMediaInvestmentUrl = () => {
    return useRouteUrl(route);
};
