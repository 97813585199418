import { useAuth } from '@/config/auth';
import { AbilityProvider, UpdateAbility } from '@/features/Casl';
import { defaultAbility, defineAbility } from '@/features/Casl/ability';
import { queryClient } from '@/queryClient';
import { router } from '@/router';
import { showDevTools } from '@/utils/showDevTools';
import { FullPageLoader, ThemeProvider } from '@analytical-alley/ui';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from '@tanstack/react-router';
import { useCallback, useState } from 'react';
import { CookieConsent } from 'react-cookie-consent';

export const App = () => {
    const [ability, setAbility] = useState(defaultAbility);
    const [abilityInitialized, setAbilityInitialized] = useState(false);

    const updateAbility: UpdateAbility = useCallback((featureFlags) => {
        const newAbility = defineAbility(featureFlags);
        setAbility(newAbility);
        setAbilityInitialized(true);
        return newAbility;
    }, []);

    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return <FullPageLoader />;
    }

    return (
        <ThemeProvider>
            <AbilityProvider
                abilityInitialized={abilityInitialized}
                ability={ability}
                updateAbility={updateAbility}
            >
                <RouterProvider
                    router={router}
                    context={{
                        auth: { isAuthenticated },
                        queryClient,
                    }}
                />
                <CookieConsent
                    acceptOnScroll={true}
                    disableButtonStyles={true}
                    buttonClasses="group flex font-sans items-center justify-center py-1 px-2 mr-5 text-center text-base relative focus:z-10 focus:outline-none transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] dark:text-dark dark:bg-[#DCE6FF] border dark:border-gray-300 enabled:dark:hover:bg-white focus:ring-4 focus:dark:ring-dark bg-dark text-white border-gray-600 enabled:hover:bg-gray-700 rounded-lg"
                >
                    This website uses cookies to enhance the user experience.
                </CookieConsent>
            </AbilityProvider>
            {showDevTools() && <ReactQueryDevtools />}
        </ThemeProvider>
    );
};
