import { WithLoader } from '@/components/WithLoader';
import { WithLoaderProps } from '@/components/WithLoader/WithLoader';
import { useCampaignOptimizationContext } from '@/dashboards/CampaignOptimization/hooks';
import { ReactNode } from 'react';

export const CampaignOptimizationLoader = ({
    loadingClassName,
    children,
    ...props
}: {
    loadingClassName?: string;
    children: ReactNode;
} & Partial<Omit<WithLoaderProps, 'className'>>) => {
    const { isLoading, isFetching } = useCampaignOptimizationContext();

    return (
        <div className="relative">
            <WithLoader
                className={isLoading ? loadingClassName : undefined}
                isLoading={isLoading || !!props.isLoading}
                isFetching={isFetching || !!props.isFetching}
            >
                {children}
            </WithLoader>
        </div>
    );
};
