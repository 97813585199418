import { BaseChart, EChartsOption, useChart } from '@/components/ECharts';
import { colors } from '@/components/ECharts/colors';
import { StructuredSeasonalityData } from '@/dashboards/CampaignOptimization/components/NaturalDemandSeasonality/NaturalDemandSeasonality';
import { roundToDecimals } from '@/utils/numberUtils';
import { jsxToHtml } from '@/utils/reactUtils';
import { Typography } from '@analytical-alley/ui';
import { BarSeriesOption, LineSeriesOption } from 'echarts';
import React, { useMemo } from 'react';

type SeriesOption = LineSeriesOption | BarSeriesOption;

type ChartOptions = EChartsOption<SeriesOption>;

function getChartOptions(data: StructuredSeasonalityData[]): ChartOptions {
    const shouldRotateLabel = data.length > 14;

    const labelPosition = shouldRotateLabel
        ? {
              rotate: 90,
              offset: [25, 5],
          }
        : {};

    return {
        tooltip: {
            trigger: 'item',
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            axisPointer: {
                type: 'none',
            },
        },
        legend: {
            show: false,
        },
        grid: {
            top: '5%',
            left: '8%',
            right: '5%',
        },
        yAxis: {
            axisLine: {
                show: false,
            },
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.2,
                },
            },
            axisLabel: {
                formatter: (value) => `${value * 100}%`,
            },
            name: 'Contribution index',
            nameLocation: 'middle',
            nameGap: 50,
            nameTextStyle: {
                fontSize: '0.75rem',
            },
        },
        xAxis: {
            type: 'category',
            data: data.map((item) => item.period),
        },
        series: [
            {
                name: 'Contribution index',
                type: 'bar',
                data: data.map((item) => {
                    return {
                        name: item.period,
                        value: item.contributionIndex,
                    };
                }),
                color: colors[0]!,
                itemStyle: {
                    borderRadius: [5, 5, 0, 0],
                },
                tooltip: {
                    formatter: (params) => {
                        return jsxToHtml(
                            <div className="tooltip-content">
                                <Typography className="text-dark dark:text-dark">
                                    {params.seriesName}
                                </Typography>
                                <table className="font-sans">
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">
                                                <Typography className="text-black dark:text-black">
                                                    Month:
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography className="text-dark dark:text-dark">
                                                    {params.name}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">
                                                <Typography className="text-black dark:text-black">
                                                    Contribution index:
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography className="text-dark dark:text-dark">
                                                    {roundToDecimals(
                                                        params.value * 100,
                                                    )}
                                                    %
                                                </Typography>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>,
                        );
                    },
                },
                label: {
                    overflow: 'break',
                    show: true,
                    position: 'top',
                    ...labelPosition,
                    color: '#FFF',
                    formatter: (params) => {
                        return `${roundToDecimals((params.value as number) * 100)}%`;
                    },
                },
            },
        ],
    };
}

export const NaturalDemandSeasonalityChart = ({
    data,
}: {
    data: StructuredSeasonalityData[];
}) => {
    const naturalDemandChartOptions = useMemo(() => {
        return getChartOptions(data);
    }, [data]);

    const { chartOptions, onEvents } = useChart({
        chartOptions: naturalDemandChartOptions,
    });

    return (
        <div className="w-full h-[24rem]">
            <BaseChart
                option={chartOptions}
                onEvents={onEvents}
                $shouldExpand
            />
        </div>
    );
};
