export const colors = [
    '#2b67ff',
    '#73efea',
    '#f93c5e',
    '#ffce2d',
    '#7031e5',
    '#73efac',
    '#ed9fb2',
    '#129f53',
    '#ff652d',
    '#050075',
    '#f9f835',
    '#a50fa9',
    '#d4fbd3',
    '#73b6ef',
    '#ffffbc',
    '#ab8a77',
    '#f2daff',
    '#856597',
    '#4258a6',
    '#7e2924',
    '#dce6ff',
    '#105b2e',
    '#8c88d7',
    '#d4c95c',
    '#e76cef',
    '#b00029',
    '#020b39',
    '#90a65d',
];

export function getColor(index: number) {
    return colors[index % colors.length]!;
}
