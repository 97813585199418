import {
    SpendSummary,
    spendSummarySearchSchema,
} from '@/dashboards/SpendSummary';
import {
    FEATURE_FLAG_ACTIONS,
    FEATURE_FLAG_SUBJECTS,
    withAuthorization,
} from '@/features/Casl';
import { FullPageLoader } from '@analytical-alley/ui';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
    '/_org/project/$projectId/_dashboards/spend-summary',
)({
    component: withAuthorization(
        SpendSummary,
        FEATURE_FLAG_ACTIONS.VIEW,
        FEATURE_FLAG_SUBJECTS.SPEND_SUMMARY_DASHBOARD,
    ),
    pendingComponent: FullPageLoader,
    validateSearch: spendSummarySearchSchema,
});
