import { defineTable } from '@/duckdb';
import { spendsHistoryColumnsMap, spendsHistorySchema } from './schema';

export { spendsHistoryQueries } from './queries';
export { type TSpendsHistoryTableRow } from './schema';

export const SpendsHistoryTable = defineTable(
    'Spends History',
    spendsHistorySchema,
    spendsHistoryColumnsMap,
);
