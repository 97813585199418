export const KEY_EVENT_KEYS = {
    ESC: 'Escape',
    TAB: 'Tab',
    SPACE: ' ',
    ARROW_UP: 'ArrowUp',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_DOWN: 'ArrowDown',
    ARROW_LEFT: 'ArrowLeft',
    ENTER: 'Enter',
} as const;

export const MEDIA_REGEX = /^(Offline media|Online media|Partner|Store media)$/;
