import { GlobalTooltip } from '@/dashboards/BudgetOptimization/components/ROICurves/tooltips/GlobalTooltip';
import { SelectedTooltip } from '@/dashboards/BudgetOptimization/components/ROICurves/tooltips/SelectedTooltip';
import { jsxToHtml } from '@/utils/reactUtils';
import React from 'react';

export type ROICurvesToolipParams = {
    seriesName: string;
    color: string;
    value: number;
    axisValueLabel: string;
}[];

export const renderTooltip =
    ({
        selected,
        formatNumber,
    }: {
        selected: string;
        formatNumber: (value: string | undefined | number) => string;
    }) =>
    (params: ROICurvesToolipParams) => {
        if (selected) {
            return jsxToHtml(
                <SelectedTooltip
                    params={params}
                    selectedSeriesName={selected}
                    formatter={(value) => {
                        return formatNumber(Number(value));
                    }}
                />,
            );
        }

        return jsxToHtml(
            <GlobalTooltip
                params={params}
                formatter={(value) => {
                    return formatNumber(Number(value));
                }}
            />,
        );
    };
