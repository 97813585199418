import { downloadCSV } from '@/utils/csv';
import { RowData } from '@tanstack/react-table';
import { Table as TanTable } from '@tanstack/table-core';
import { useCallback } from 'react';

export type DownloadCsvHandle = (
    fileName: string,
    options?: { addDate?: boolean },
) => void;

export const useTableExport = <TData extends RowData>({
    table,
    onRowExport,
}: {
    table: TanTable<TData>;
    onRowExport?: (row: TData) => Record<string, unknown>;
}) => {
    const getExportData = useCallback(() => {
        const headers = table.getHeaderGroups().map((headerGroup) => {
            return headerGroup.headers.map((header) => {
                if (typeof header.column.columnDef.header === 'function') {
                    const headerNode = header.column.columnDef.header(
                        header.getContext(),
                    );

                    if (typeof headerNode === 'string') {
                        return {
                            key: header.column.id,
                            label: headerNode,
                        };
                    }
                    return {
                        key: header.column.id,
                        label: headerNode.props.children,
                    };
                }
                return {
                    key: header.column.id,
                    label: header.column.columnDef.header,
                };
            });
        });

        const rows = table.getRowModel().rows.map((row) => {
            return onRowExport
                ? (onRowExport(row.original) as Record<string, unknown>)
                : (row.original as Record<string, unknown>);
        });

        return { headers: headers[0]!, rows };
    }, [onRowExport, table]);

    const downloadCsv: DownloadCsvHandle = useCallback(
        (fileName: string, options) => {
            const [name] = fileName.split('.');

            if (!name) {
                throw new Error('Invalid file name');
            }

            const validFileName = name.replace(/[^a-z0-9-]/gi, '_');

            const fileNameWithExtension = options?.addDate
                ? `${validFileName}_${new Date().toISOString()}.csv`
                : `${validFileName}.csv`;

            downloadCSV(getExportData(), fileNameWithExtension);
        },
        [getExportData],
    );

    return { downloadCsv, getExportData };
};
