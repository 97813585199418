import {
    getCategoryVariableColorMap,
    GetCategoryVariableColorMapResponse,
} from '@/api/dashboards/getCategoryVariableColorMap';
import { ProjectInfoResponse } from '@/api/project';
import { useDuckDBQuery } from '@/duckdb';
import { ReactNode } from 'react';
import { createContext, useContext } from 'use-context-selector';

type DashboardGlobalContextType = {
    project: ProjectInfoResponse['project'];
    dashboard: ProjectInfoResponse['dashboard'];
    startDate: string;
    endDate: string;
    colorMap: GetCategoryVariableColorMapResponse;
};

const DashboardGlobalContext = createContext<DashboardGlobalContextType>(
    null as unknown as DashboardGlobalContextType,
);

const DashboardGlobalProvider = ({
    project,
    dashboard,
    endDate,
    startDate,
    children,
}: Omit<DashboardGlobalContextType, 'colorMap'> & { children: ReactNode }) => {
    const { data } = useDuckDBQuery({
        queryKey: ['variable-color-map'],
        queryFn: () => getCategoryVariableColorMap(),
    });

    return (
        <DashboardGlobalContext.Provider
            value={{
                project,
                dashboard,
                endDate,
                startDate,
                colorMap: data || {
                    variableColorMap: {},
                    categoryColorMap: {},
                },
            }}
        >
            {children}
        </DashboardGlobalContext.Provider>
    );
};

const useDashboardGlobalContext = () => {
    const context = useContext(DashboardGlobalContext);

    if (!context) {
        throw new Error(
            'useDashboardGlobalContext must be used within a DashboardGlobalProvider',
        );
    }

    return context;
};

export {
    DashboardGlobalContext,
    DashboardGlobalProvider,
    useDashboardGlobalContext,
};
