import { RouteId } from '@/types/routes';
import { getRouteApi, RouteApi, useSearch } from '@tanstack/react-router';
import { ReactNode } from 'react';
import { createContext, useContext } from 'use-context-selector';

type RouteContext<Route extends RouteId> = {
    route: RouteApi<Route>;
    isPreviewRoute: boolean;
};

export const createRouteContext = <Route extends RouteId>() => {
    const WithRouteContext = createContext<RouteContext<Route>>(
        null as unknown as RouteContext<Route>,
    );

    const RouteProvider = ({
        routeId,
        children,
    }: {
        children: ReactNode;
        routeId: Route;
    }) => {
        const route = getRouteApi(routeId);

        const { dashboardId } = useSearch({ strict: false });

        return (
            <WithRouteContext.Provider
                value={{ route, isPreviewRoute: !!dashboardId }}
            >
                {children}
            </WithRouteContext.Provider>
        );
    };

    const useRouteContext = () => {
        const context = useContext(WithRouteContext);

        if (!context) {
            throw new Error(
                'useRouteContext must be used within a RouteProvider',
            );
        }

        return useContext(WithRouteContext);
    };

    return { RouteProvider, useRouteContext };
};
