import { forwardRef as m, createElement as r } from "react";
const p = m(
  ({
    children: o,
    as: e,
    href: t,
    type: n = "button",
    ...a
  }, s) => r(
    e || (t ? "a" : "button"),
    { ref: s, href: t, type: n, ...a },
    o
  )
);
p.displayName = "ButtonBaseComponent";
export {
  p as ButtonBase
};
