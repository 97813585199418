import { ThemeContext as t } from "../config/theme/ThemeProvider.js";
import { useContext as o } from "react";
const n = () => {
  const e = o(t);
  if (!e)
    throw new Error("useTheme must be used within a ThemeProvider");
  return e;
};
export {
  n as useTheme
};
