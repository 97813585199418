import { DB_NAME } from '@/duckdb';
import { LoggerAwareAsyncConstructable } from '@analytical-alley/common/helpers/loggerAwareAsyncConstructable';
import { type PinoLogger } from '@analytical-alley/common/utils/pinoLogger';
import { type RunQuery } from '@analytical-alley/duckdb-react';
import { DataSourceLoaderService } from '@analytical-alley/roi-optimizer/optimizer/dataSource/service.interface';
import { DataFrame } from 'danfojs';

export class DuckDBDataSourceLoaderService
    extends LoggerAwareAsyncConstructable<typeof DuckDBDataSourceLoaderService>
    implements DataSourceLoaderService<typeof DuckDBDataSourceLoaderService>
{
    async hasTable(tableName: string, columns?: string[]) {
        const wrap = (wrapChar: string) => {
            return (value: string) => value && wrapChar + value + wrapChar;
        };
        const selectColumns = columns?.map(wrap('"')).join(', ') || '*';

        try {
            return !!(await this.query(
                `SELECT ${selectColumns} FROM "${tableName}" LIMIT 0`,
            ));
        } catch (ex) {
            if (tableName === 'ROI response curves') {
                try {
                    return !!(await this.query(
                        `SELECT ${selectColumns} FROM "Response Curves" LIMIT 0`,
                    ));
                } catch {
                    return false;
                }
            }
            return false;
        }
    }

    private duckDbQuery!: RunQuery;

    constructor(logger: PinoLogger, _query: RunQuery) {
        super(logger, Promise.resolve());
        this.duckDbQuery = _query;
    }

    query(sql: string) {
        return this.duckDbQuery(`USE "${DB_NAME}"; ${sql}`);
    }

    async loadDataFrame(tableName: string): Promise<DataFrame> {
        if (!tableName?.trim()) {
            throw new Error('`tableName` argument required');
        }
        if (!(await this.hasTable(tableName))) {
            throw new Error(
                `Table "${tableName}" does not exist in the DuckDB database`,
            );
        }

        try {
            const result = await this.query(
                `SELECT * EXCLUDE ("execution_id") FROM "${tableName}"`,
            );
            return new DataFrame(result);
        } catch (ex) {
            if (tableName === 'ROI response curves') {
                try {
                    const result = await this.query(
                        `SELECT * EXCLUDE ("execution_id") FROM "Response Curves"`,
                    );
                    return new DataFrame(result);
                } catch (e) {
                    throw e;
                }
            }
            throw ex;
        }
    }

    static async isApplicable(): Promise<boolean> {
        return true;
    }
    async close() {}
}
