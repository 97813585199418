import { previewDashboardSearchSchema } from '@/config/commonSearch';
import { DashboardsLayout } from '@/dashboards/DashboardsLayout';
import { OptimizerContextProvider } from '@/optimizer/optimizerContext';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_org/project/$projectId/_dashboards')({
    component: () => (
        <OptimizerContextProvider>
            <DashboardsLayout />
        </OptimizerContextProvider>
    ),
    validateSearch: previewDashboardSearchSchema,
});
