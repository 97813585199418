import { defineTable } from '@/duckdb';
import {
    datasheetVariablesColumnsMap,
    datasheetVariablesSchema,
} from './schema';

export { datasheetVariablesQueries } from './queries';
export { type TDatasheetVariablesTableRow } from './schema';

export const DatasheetVariablesTable = defineTable(
    'T datasheet variables',
    datasheetVariablesSchema,
    datasheetVariablesColumnsMap,
);
