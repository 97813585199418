import { TreeNodeLike } from '@/components/TreeSelect/useTreeSelect/types';
import { SpendSummarySearch } from '@/dashboards/SpendSummary';
import { FilterNode } from '@/dashboards/SpendSummary/SpendSummary';
import {
    useSpendSummaryQuery,
    useSpendSummaryUrl,
} from '@/dashboards/SpendSummary/hooks';
import { GetSpendSummaryWithRoiResponse } from '@/dashboards/SpendSummary/types';

import { GetCategoryVariableColorMapResponse } from '@/api/dashboards/getCategoryVariableColorMap';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { createContext } from 'use-context-selector';

export type FormattedSpendSummaryData = {
    contributionDataByVariable: Map<
        string,
        {
            data: { [key: string]: number };
            color: string;
        }
    >;
    contributionDataByCategory: Map<
        string,
        {
            data: { [key: string]: number };
            color: string;
        }
    >;
    spendDataByVariable: Map<
        string,
        {
            data: { [key: string]: number };
            color: string;
        }
    >;
    spendDataByCategory: Map<
        string,
        {
            data: { [key: string]: number };
            color: string;
        }
    >;
    totalContributionProductByVariable: Map<string, number>;
    totalContributionProductByCategory: Map<string, number>;
    totalContribution: number;
    totalSpend: number;
    totalContributionProduct: number;
    totalContributionByCategory: Map<string, number>;
    totalContributionByVariable: Map<string, number>;
    totalSpendByCategory: Map<string, number>;
    totalSpendByVariable: Map<string, number>;
    variableToCategory: Map<string, string>;
    treeNodes: TreeNodeLike[];
    variableData: {
        variable: string;
        category: string;
        color: string;
    }[];
    categoryData: {
        category: string;
        color: string;
    }[];
    dates: string[];
};

export const SpendSummaryContext = createContext<{
    spendSummaryData: FormattedSpendSummaryData;
    handleSelectedChange: Dispatch<SetStateAction<string>>;
    setSelectedNodes: (nodes: FilterNode[]) => void;
    modelBreakdown: SpendSummarySearch['modelBreakdown'];
    selectedFilterNodes: { [key: string]: FilterNode };
    getVisibleContributionData: () => {
        [key: string]: {
            data: { [key: string]: number };
            color: string;
        };
    };
    getVisibleSpendData: () => {
        [key: string]: {
            data: { [key: string]: number };
            color: string;
        };
    };
    selected: string;
    period: SpendSummarySearch['period'];
}>({
    spendSummaryData: {} as FormattedSpendSummaryData,
    handleSelectedChange: () => {},
    setSelectedNodes: () => {},
    modelBreakdown: 'category',
    selectedFilterNodes: {},
    getVisibleContributionData: () => ({}),
    getVisibleSpendData: () => ({}),
    selected: '',
    period: 'weekly',
});

const groupSpendSummaryData = ({
    rows,
    variableColorMap,
    categoryColorMap,
}: GetSpendSummaryWithRoiResponse &
    GetCategoryVariableColorMapResponse): FormattedSpendSummaryData => {
    const treeMap: Record<string, TreeNodeLike> = {};

    const categoryData: FormattedSpendSummaryData['categoryData'] = [];
    const variableData: FormattedSpendSummaryData['variableData'] = [];
    const dates: Set<string> = new Set();

    const usedCategoryVariableCombinations = new Set<string>();

    const data = rows.reduce(
        (
            acc,
            {
                variable,
                category,
                periodName: dateString,
                spend,
                contribution,
                contributionProduct,
            },
        ) => {
            const date = dateString;

            dates.add(date);

            if (!acc.contributionDataByVariable.has(variable)) {
                const color = variableColorMap[variable]!;
                variableData.push({
                    variable,
                    category,
                    color,
                });
                acc.contributionDataByVariable.set(variable, {
                    data: {},
                    color,
                });
            }

            if (!acc.contributionDataByCategory.has(category)) {
                const color = categoryColorMap[category]!;
                categoryData.push({
                    category,
                    color,
                });
                acc.contributionDataByCategory.set(category, {
                    data: {},
                    color,
                });
                acc.totalContributionByCategory.set(category, 0);
            }

            acc.contributionDataByVariable.get(variable)!.data[date] =
                (acc.contributionDataByVariable.get(variable)!.data[date] ||
                    0) + contribution || contribution;

            acc.contributionDataByCategory.get(category)!.data[date] =
                (acc.contributionDataByCategory.get(category)!.data[date] ||
                    0) + contribution || contribution;

            acc.totalContribution += contribution;
            acc.totalContributionByCategory.set(
                category,
                acc.totalContributionByCategory.get(category)! + contribution,
            );
            acc.totalContributionByVariable.set(
                variable,
                (acc.totalContributionByVariable.get(variable) || 0) +
                    contribution,
            );

            if (!acc.spendDataByVariable.has(variable)) {
                const color = variableColorMap[variable]!;
                acc.spendDataByVariable.set(variable, {
                    data: {},
                    color,
                });
            }

            if (!acc.spendDataByCategory.has(category)) {
                const color = categoryColorMap[category]!;
                acc.spendDataByCategory.set(category, {
                    data: {},
                    color,
                });
                acc.totalSpendByCategory.set(category, 0);
            }

            acc.spendDataByVariable.get(variable)!.data[date] =
                (acc.spendDataByVariable.get(variable)!.data[date] || 0) +
                    spend || spend;

            acc.spendDataByCategory.get(category)!.data[date] =
                (acc.spendDataByCategory.get(category)!.data[date] || 0) +
                    spend || spend;

            acc.totalSpend += spend;
            acc.totalSpendByCategory.set(
                category,
                (acc.totalSpendByCategory.get(category) || 0) + spend,
            );
            acc.totalSpendByVariable.set(
                variable,
                (acc.totalSpendByVariable.get(variable) || 0) + spend,
            );

            acc.totalContributionProduct += contributionProduct;
            acc.totalContributionProductByCategory.set(
                category,
                (acc.totalContributionProductByCategory.get(category) || 0) +
                    contributionProduct,
            );
            acc.totalContributionProductByVariable.set(
                variable,
                (acc.totalContributionProductByVariable.get(variable) || 0) +
                    contributionProduct,
            );

            acc.variableToCategory.set(variable, category);

            if (
                !usedCategoryVariableCombinations.has(`${category}-${variable}`)
            ) {
                usedCategoryVariableCombinations.add(`${category}-${variable}`);
                if (!treeMap[category]) {
                    treeMap[category] = {
                        id: category,
                        label: category,
                        children: [],
                    };
                }
                treeMap[category]!.children!.push({
                    id: variable,
                    label: variable,
                });
            }

            return acc;
        },
        {
            contributionDataByVariable: new Map<
                string,
                {
                    data: { [key: string]: number };
                    color: string;
                }
            >(),
            contributionDataByCategory: new Map<
                string,
                {
                    data: { [key: string]: number };
                    color: string;
                }
            >(),
            spendDataByVariable: new Map<
                string,
                {
                    data: { [key: string]: number };
                    color: string;
                }
            >(),
            spendDataByCategory: new Map<
                string,
                {
                    data: { [key: string]: number };
                    color: string;
                }
            >(),
            totalContributionProductByVariable: new Map<string, number>(),
            totalContributionProductByCategory: new Map<string, number>(),
            totalContribution: 0,
            totalSpend: 0,
            totalContributionProduct: 0,
            totalContributionByCategory: new Map<string, number>(),
            totalContributionByVariable: new Map<string, number>(),
            totalSpendByCategory: new Map<string, number>(),
            totalSpendByVariable: new Map<string, number>(),
            variableToCategory: new Map<string, string>(),
            variableData: [],
            categoryData: [],
            treeNodes: [] as TreeNodeLike[],
        },
    );

    return {
        ...data,
        variableData,
        categoryData,
        dates: Array.from(dates),
        treeNodes: Object.values(treeMap),
    };
};

export const SpendSummaryProvider = ({ children }: { children: ReactNode }) => {
    const {
        colorMap: { categoryColorMap, variableColorMap },
    } = useDashboardGlobalContext();
    const [selected, setSelected] = useState<string>('');
    const [selectedFilterNodes, setSelectedFilterNodes] = useState<{
        [key: string]: FilterNode;
    }>({});
    const { search } = useSpendSummaryUrl();

    const { queryResult } = useSpendSummaryQuery();

    const { data } = queryResult;

    const spendSummaryData = useMemo(() => {
        if (!data?.rows) {
            return {
                contributionDataByVariable: new Map(),
                contributionDataByCategory: new Map(),
                spendDataByVariable: new Map(),
                spendDataByCategory: new Map(),
                totalContribution: 0,
                totalSpend: 0,
                totalContributionProduct: 0,
                totalContributionByCategory: new Map(),
                totalContributionByVariable: new Map(),
                totalSpendByCategory: new Map(),
                totalSpendByVariable: new Map(),
                totalContributionProductByCategory: new Map(),
                totalContributionProductByVariable: new Map(),
                variableToCategory: new Map(),
                treeNodes: [],
                variableData: [],
                categoryData: [],
                dates: [],
            };
        }
        return groupSpendSummaryData({
            rows: data.rows,
            categoryColorMap,
            variableColorMap,
        });
    }, [categoryColorMap, data, variableColorMap]);

    const {
        contributionDataByVariable,
        variableToCategory,
        contributionDataByCategory,
        spendDataByCategory,
        spendDataByVariable,
    } = spendSummaryData;

    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setSelected('');
    }, [search.modelBreakdown]);

    // Maybe clear cache on unmount
    /*useEffect(() => {
        return () => {
            groupContributionData.cache.clear?.();
        };
    }, []);*/

    function setSelectedNodes(nodes: FilterNode[]) {
        const nodesMap = nodes.reduce((acc, node) => {
            acc[node.name] = node;
            return acc;
        }, {});

        if (!nodesMap[selected]) {
            setSelected('');
        }

        setSelectedFilterNodes(nodesMap);
    }

    const getVisibleContributionData = useCallback(() => {
        return Object.fromEntries(
            Array.from(
                search.modelBreakdown === 'category'
                    ? contributionDataByCategory
                    : contributionDataByVariable,
            ).filter(([name, _]) => {
                if (search.modelBreakdown === 'category')
                    return selectedFilterNodes[name];

                return (
                    selectedFilterNodes[name] ||
                    selectedFilterNodes[variableToCategory.get(name)!]
                );
            }),
        );
    }, [
        contributionDataByCategory,
        contributionDataByVariable,
        search.modelBreakdown,
        selectedFilterNodes,
        variableToCategory,
    ]);

    const getVisibleSpendData = useCallback(() => {
        return Object.fromEntries(
            Array.from(
                search.modelBreakdown === 'category'
                    ? spendDataByCategory
                    : spendDataByVariable,
            ).filter(([name, _]) => {
                if (search.modelBreakdown === 'category')
                    return selectedFilterNodes[name];

                return (
                    selectedFilterNodes[name] ||
                    selectedFilterNodes[variableToCategory.get(name)!]
                );
            }),
        );
    }, [
        search.modelBreakdown,
        selectedFilterNodes,
        spendDataByCategory,
        spendDataByVariable,
        variableToCategory,
    ]);

    return (
        <SpendSummaryContext.Provider
            value={{
                getVisibleContributionData,
                getVisibleSpendData,
                modelBreakdown: search.modelBreakdown,
                spendSummaryData,
                selected,
                handleSelectedChange: setSelected,
                setSelectedNodes,
                selectedFilterNodes,
                period: search.period,
            }}
        >
            {children}
        </SpendSummaryContext.Provider>
    );
};
