import { CurrencyBlock } from '@/components/CurrencyBlock';
import { useConfigContext } from '@/context/configContext';
import { useBudgetOptimizationContext } from '@/dashboards/BudgetOptimization/context/BudgetOptimizationContext';
import { useBudgetOptimizationData } from '@/dashboards/BudgetOptimization/hooks/useBudgetOptimizationData';
import React from 'react';

export const ContributionSummary = () => {
    const {
        data: {
            previousPeriodInvestmentTotal,
            simulatedInvestmentTotal,
            previous,
        },
    } = useBudgetOptimizationData();
    const {
        data: { contributionTotal },
    } = useBudgetOptimizationContext();
    const { formatNumber } = useConfigContext();

    const { totalContribution, mediaContribution } = previous || {};

    const diff = simulatedInvestmentTotal - previousPeriodInvestmentTotal;
    const percentage = Math.round((diff / previousPeriodInvestmentTotal) * 100);

    const mediaDiff = contributionTotal - (mediaContribution || 0);
    const mediaPercentage = mediaContribution
        ? Math.round((mediaDiff / mediaContribution) * 100)
        : 0;

    const totalContributionWithNewMedia =
        (totalContribution || 0) - (mediaContribution || 0) + contributionTotal;

    const contributionDiff =
        totalContributionWithNewMedia - (totalContribution || 0);

    const contributionPercentage = totalContribution
        ? Math.round((contributionDiff / totalContribution) * 100)
        : 0;

    return (
        <div className="flex w-full justify-center glass-tile p-6 gap-6 flex-wrap">
            <CurrencyBlock
                className="grow"
                title="Media investments"
                value={formatNumber(simulatedInvestmentTotal)}
                percentage={percentage}
            />
            <CurrencyBlock
                className="grow"
                title="Media Contribution"
                value={formatNumber(contributionTotal || 0)}
                percentage={mediaPercentage}
            />
            <CurrencyBlock
                className="grow"
                title="Total Contribution"
                value={formatNumber(totalContributionWithNewMedia)}
                percentage={contributionPercentage}
            />
        </div>
    );
};
