import { cn } from '@/utils';
import { env } from '@/utils/env';
import { Typography } from '@analytical-alley/ui';
import React, { Children, ReactNode, isValidElement } from 'react';
import { ScaleLines } from './ScaleLines';
import { BarGroupProps } from './ShadowBarGroup';
import { ShadowBarGroupTitle } from './ShadowBarGroupTitle';
import { useBarChartContext } from './context';

export const ShadowBarChartContent = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => {
    const parentContRef = React.useRef<HTMLDivElement>(null);
    const contentContainerRef = React.useRef<HTMLDivElement>(null);

    const titleContainerRef = React.useRef<HTMLDivElement>(null);
    const barContainerRef = React.useRef<HTMLDivElement>(null);

    const [titleWidth, setTitleWidth] = React.useState<number>(0);

    const observer = React.useMemo(() => {
        return new ResizeObserver(() => {
            if (contentContainerRef.current) {
                const width = contentContainerRef.current.scrollWidth;

                if (titleContainerRef.current && barContainerRef.current) {
                    barContainerRef.current.style.width = `${titleContainerRef.current?.scrollWidth}px`;
                }

                const spaceForOneChild =
                    width / (Children.count(children) || 1);

                setTitleWidth(spaceForOneChild);
            }
        });
    }, [children]);

    React.useEffect(() => {
        const containerReference = parentContRef.current;

        if (containerReference) {
            observer.observe(containerReference);
        }

        return () => {
            if (containerReference) {
                observer.unobserve(containerReference);
            }
        };
    }, [observer]);

    const { height, showYAxis, options } = useBarChartContext();

    const areLabelsRotated = options.labelVariant === 'rotated';

    const shouldAddPadding =
        areLabelsRotated &&
        (Children.toArray(children)[0] as { props: BarGroupProps })?.props
            ?.active;

    return (
        <div className="relative" ref={parentContRef}>
            <div
                style={{ height, top: 25 }}
                className={cn(
                    'absolute gap-0 px-5 mt-2 w-full tracking-tight font-mono',
                )}
            >
                <ScaleLines />
            </div>
            <div
                ref={contentContainerRef}
                style={{
                    paddingTop: 25,
                }}
                className="overflow-x-auto overflow-y-hidden"
            >
                <div
                    ref={barContainerRef}
                    style={{
                        height,
                        paddingLeft: shouldAddPadding ? 20 : undefined,
                    }}
                    className={cn(
                        'relative flex gap-0 justify-around pl-4 pr-2 mt-2 tracking-tight font-mono',
                        className,
                        {
                            'border-b-2 border-solid border-b-[rgba(190,212,255,0.50)]':
                                !showYAxis,
                        },
                    )}
                >
                    {Children.map(children, (child) => {
                        if (
                            env.DEV &&
                            isValidElement(child) &&
                            (child.type as unknown as any).displayName !==
                                'ShadowBarGroup'
                        ) {
                            // eslint-disable-next-line no-console
                            console.error(
                                `ShadowBarChart.Content only accepts ShadowBarGroup components as children!`,
                            );
                        }
                        if (!child || isValidElement(child)) {
                            return child;
                        }

                        return null;
                    })}
                </div>
                <div
                    ref={titleContainerRef}
                    style={{
                        height: areLabelsRotated ? 100 : undefined,
                        paddingLeft: shouldAddPadding ? 20 : undefined,
                    }}
                    className="relative flex gap-0 justify-around pl-4 pr-2 tracking-tight font-mono mt-2"
                >
                    {Children.map(children, (child) => {
                        if (
                            env.DEV &&
                            isValidElement(child) &&
                            (child.type as unknown as any).displayName !==
                                'ShadowBarGroup'
                        ) {
                            // eslint-disable-next-line no-console
                            console.error(
                                `ShadowBarChart.Content only accepts ShadowBarGroup components as children!`,
                            );
                        }
                        if (isValidElement(child)) {
                            const props = child?.props as BarGroupProps;
                            return (
                                <ShadowBarGroupTitle
                                    width={titleWidth}
                                    {...props}
                                >
                                    {props.title}
                                </ShadowBarGroupTitle>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};
