/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { Children, ReactNode, useState } from 'react';

import { cn, twCn } from '@/utils';

import { PercentItem } from '@/dashboards/CompetitorsMediaInvestment/components/CompetitorsHorizontalBarGraph/PercentItem';
import { CompetitorImpact } from '@/dashboards/CompetitorsMediaInvestment/providers/CompetitorsMediaInvestmentProvider';
import {
    DetectOverflowCallback,
    useDetectOverflow,
} from '@/hooks/useDetectOverflow';
import { Tooltip, Typography } from '@analytical-alley/ui';

const leftColumnClassName = 'w-1/3 min-w-56 2xl:min-w-26 md:w-1/4';
const rightColumnClassName = 'w-2/3 md:w-3/4';

const generatePercentageArray = (maxValue: number) => {
    const roundedMaxValue = Math.ceil(maxValue / 5) * 5;
    const steps = 5; // Number of elements in the array
    const gap = roundedMaxValue / (steps - 1); // Calculate the gap and ensure it is an integer
    const percentageArray: number[] = [];

    for (let i = 0; i < steps; i++) {
        percentageArray.push(i * gap);
    }

    // Ensure the last element is exactly maxValue
    percentageArray[steps - 1] = roundedMaxValue;

    return percentageArray;
};

const ImpactDot = ({ impact }: { impact: CompetitorImpact }) => {
    if (!impact) return;
    return (
        <span
            className={cn('w-4 h-4 block rounded-full cursor-pointer', {
                'bg-rose-500': impact === 'negative',
                'bg-green-400': impact === 'positive',
            })}
        />
    );
};

const Row = ({
    title,
    percent,
    color,
    activeTitle,
    impact,
    maxValue,
    value,
}: {
    activeTitle: string | undefined;
    title: string;
    percent: number;
    color: string;
    impact: CompetitorImpact;
    maxValue: number;
    value: ReactNode;
}) => {
    const isNegative = percent <= 0;
    const scaledPercent = (percent / (Math.ceil(maxValue / 5) * 5)) * 100;
    const [overflow, setOverflow] = useState(false);
    const onTextOverflow: DetectOverflowCallback = (
        isOverflowing: boolean,
        { contentWidth, targetContentElement },
    ) => {
        if (isOverflowing) {
            targetContentElement.style.left = isNegative
                ? `calc(${scaledPercent}% + ${contentWidth}px)`
                : `calc(${scaledPercent}% - ${contentWidth}px)`;
        } else {
            targetContentElement.style.left = `${scaledPercent}%`;
        }
        setOverflow(isOverflowing);
    };

    const { containerRef, mainContentRef, targetContentRef } =
        useDetectOverflow(onTextOverflow, { throttleWait: 0 }, [scaledPercent]);

    const left = scaledPercent >= 0 ? '0%' : 'auto';
    const right = scaledPercent < 0 ? '100%' : 'auto';

    const barWidth = Math.abs(scaledPercent);

    return (
        <div
            className={cn(
                'flex flex-row h-12 align-middle w-full bg-gray-100 dark:bg-white dark:bg-opacity-10 rounded-lg',
                {
                    'bg-opacity-20 dark:bg-opacity-30': activeTitle === title,
                },
            )}
        >
            <span
                ref={isNegative ? containerRef : undefined}
                className={cn(
                    'flex self-center justify-between items-center h-full pl-4 pr-4 font-mono text-dark dark:text-white text-base leading-normal',
                    leftColumnClassName,
                )}
            >
                <Typography
                    ref={isNegative ? mainContentRef : undefined}
                    variant="bodyM"
                    className="inline-block z-20 self-center text-wrap"
                >
                    {title}
                </Typography>
                <Tooltip placement={'right-end'}>
                    <Tooltip.Trigger className="z-20">
                        <ImpactDot impact={impact} />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        <div className="tooltip-content">
                            <Typography className="text-violet-950 dark:text-violet-950 font-medium">
                                {title} Media Investment {value} €
                            </Typography>
                            <Typography
                                className={cn('', {
                                    'text-rose-500 dark:text-rose-500':
                                        impact === 'negative',
                                    'text-green-500 dark:text-green-500':
                                        impact === 'positive',
                                })}
                            >
                                {`Had a ${impact} effect on your revenue of ${impact === 'negative' ? '-' : '+'} 520 €`}
                            </Typography>
                        </div>
                    </Tooltip.Content>
                </Tooltip>
            </span>

            <div
                ref={!isNegative ? containerRef : undefined}
                className={cn('relative', rightColumnClassName)}
            >
                <Tooltip placement={'right-end'}>
                    <Tooltip.Trigger>
                        <span
                            ref={!isNegative ? mainContentRef : undefined}
                            className="absolute h-full cursor-pointer"
                            style={{
                                opacity: 0.9,
                                backgroundColor: color,
                                width: `${Math.max(barWidth, 0.5)}%`,
                                top: 0,
                                left,
                                right,
                            }}
                        />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        <div className="tooltip-content">
                            <Typography className="text-violet-950 dark:text-violet-950 font-medium font-sans">
                                {title} Media Investment
                            </Typography>
                            <Typography className="text-violet-950 dark:text-violet-950 font-medium font-mono">
                                {value} €
                            </Typography>
                        </div>
                    </Tooltip.Content>
                </Tooltip>
                <span
                    ref={targetContentRef}
                    className={cn(
                        'flex z-10 h-full absolute text-white top-0 text-nowrap px-4 cursor-pointer',
                        {
                            '-translate-x-full': scaledPercent <= 0,
                        },
                    )}
                >
                    <div className="flex gap-1 font-mono z-10 relative">
                        <PercentItem percent={percent} impact={impact} />
                    </div>
                </span>
            </div>
        </div>
    );
};

const ScaleLines = ({ maxValue }: { maxValue: number }) => {
    const percentages = generatePercentageArray(maxValue);
    const scaleGaps = [0, 25, 50, 75, 100];

    return (
        <>
            {percentages.map((percentage, index) => {
                return (
                    <div
                        key={percentage}
                        className="z-10"
                        style={{
                            position: 'absolute',
                            height: '100%',
                            left: `${scaleGaps[index]}%`,
                        }}
                    >
                        <div
                            className={twCn(
                                'w-0.5 border-l -translate-x-1/2 border-dark border-opacity-50 dark:border-blue-200 dark:border-opacity-50 border-dotted',
                                {
                                    'w-[5px] bg-dark bg-opacity-50 dark:bg-blue-200 dark:bg-opacity-50 rounded-sm border-none':
                                        !percentage,
                                },
                            )}
                            style={{
                                position: 'absolute',
                                height: '100%',
                            }}
                        />
                        <div
                            className="text-dark dark:text-white font-mono"
                            style={{
                                position: 'absolute',
                                bottom: '-1.5rem',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            {percentage}%
                        </div>
                    </div>
                );
            })}
        </>
    );
};
const CompetitorsHorizontalBarGraphBase = ({
    children,
    maxValue,
}: {
    children: ReactNode;
    maxValue: number;
}) => {
    const hasChildren = Children.count(children) > 0;

    return (
        <div>
            {hasChildren ? (
                <div className="relative flex flex-col text-xs text-white whitespace-nowrap w-full gap-3 mb-[1.5rem]">
                    <div className="absolute flex h-full flex-row align-middle w-full">
                        <span className={cn(leftColumnClassName)}></span>
                        <div className={cn('relative', rightColumnClassName)}>
                            <ScaleLines maxValue={maxValue} />
                        </div>
                    </div>
                    {children}
                </div>
            ) : null}
        </div>
    );
};

export const CompetitorsHorizontalBarGraph = Object.assign(
    CompetitorsHorizontalBarGraphBase,
    {
        Row,
    },
);
