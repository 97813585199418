import { FullScreenProvider } from '@/components/FullScreen';
import { Header } from '@/components/Header';
import React, { useRef } from 'react';

import { ActionBar } from '@/dashboards/BudgetOptimization/components/ActionBar/ActionBar';
import { BudgetOptimizationSection } from '@/dashboards/BudgetOptimization/components/BudgetOptimizationSection';
import { BudgetOptimizationContextProvider } from '@/dashboards/BudgetOptimization/context';

export const BudgetOptimization = () => {
    const portalRef = useRef<HTMLDivElement>(null);

    return (
        <BudgetOptimizationContextProvider>
            <Header
                className="pb-6 px-16 pt-6"
                title="Yearly Budget Optimization"
            >
                <ActionBar />
            </Header>
            <div className="pb-8 px-16">
                <div className="transition-all duration-300" ref={portalRef} />
                <FullScreenProvider portalRef={portalRef}>
                    <BudgetOptimizationSection />
                </FullScreenProvider>
            </div>
        </BudgetOptimizationContextProvider>
    );
};
