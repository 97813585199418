import { jsx as n } from "react/jsx-runtime";
import { useMergeRefs as l, FloatingPortal as s } from "@floating-ui/react";
import { forwardRef as f } from "react";
import { useTooltipContext as u } from "./tooltipContext.js";
const d = f(function({ style: e, ...o }, r) {
  const t = u(), i = l([t.refs.setFloating, r]);
  return t.isMounted ? /* @__PURE__ */ n(s, { children: t.isMounted && (o.open == null || o.open) && /* @__PURE__ */ n(
    "div",
    {
      ref: i,
      style: {
        ...t.floatingStyles,
        ...e,
        ...t.transitionStyles
      },
      ...t.getFloatingProps(o)
    }
  ) }) : null;
});
export {
  d as TooltipContent
};
