import {
    ModelContributions,
    modelContributionsSearchSchema,
} from '@/dashboards/ModelContributions';
import { ModelContributionRouteProvider } from '@/dashboards/ModelContributions/providers';
import {
    FEATURE_FLAG_ACTIONS,
    FEATURE_FLAG_SUBJECTS,
    withAuthorization,
} from '@/features/Casl';
import { FullPageLoader } from '@analytical-alley/ui';
import { createFileRoute } from '@tanstack/react-router';

const ModelContributionsRouteComponent = () => {
    return (
        <ModelContributionRouteProvider routeId={Route.id}>
            <ModelContributions />
        </ModelContributionRouteProvider>
    );
};

export const Route = createFileRoute(
    '/_org/project/$projectId/_dashboards/model-contributions',
)({
    component: withAuthorization(
        ModelContributionsRouteComponent,
        FEATURE_FLAG_ACTIONS.VIEW,
        FEATURE_FLAG_SUBJECTS.MODEL_CONTRIBUTION_DASHBOARD,
    ),
    pendingComponent: FullPageLoader,
    validateSearch: modelContributionsSearchSchema,
});
