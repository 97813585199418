import { FloatingFocusManager } from '@floating-ui/react';
import { ButtonProps, Label } from 'flowbite-react';

import React, {
    ComponentProps,
    FC,
    ReactNode,
    forwardRef,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';

import {
    HiOutlineChevronDown,
    HiOutlineChevronLeft,
    HiOutlineChevronRight,
    HiOutlineChevronUp,
} from 'react-icons/hi';
import { twMerge } from 'tailwind-merge';

import { useBaseFloating, useFloatingInteractions } from '@/hooks/useFloating';
import type { DeepPartial } from '@/types';
import { merge } from 'lodash';

import type { FloatingProps } from '../Floating';

import { Trigger } from '@/components/Dropdown/Trigger';
import { DropdownTheme, dropdownTheme } from '@/components/Dropdown/theme';
import { twCn } from '@/utils';
import { Checkbox } from '@analytical-alley/ui';
import { createPortal } from 'react-dom';

export type SelectItem = {
    label: string;
    value: string;
    checked: boolean;
    id?: string;
};

export interface ToggleSelectProps
    extends Pick<FloatingProps, 'placement' | 'trigger'>,
        Omit<ButtonProps, 'theme' | 'onChange'> {
    arrowIcon?: boolean;
    floatingArrow?: boolean;
    inline?: boolean;
    label: ReactNode;
    compact?: boolean;
    items?: SelectItem[];
    theme?: DeepPartial<DropdownTheme>;
    onChange: (label: string, checked: boolean) => void;
    showAllCheckbox?: boolean;
}

const icons: Record<string, FC<ComponentProps<'svg'>>> = {
    top: HiOutlineChevronUp,
    right: HiOutlineChevronRight,
    bottom: HiOutlineChevronDown,
    left: HiOutlineChevronLeft,
};

export type ToggleSelectRef = {
    focus: () => void;
};

export const ToggleSelect = forwardRef(
    (
        {
            children,
            className,
            compact,
            items,
            showAllCheckbox,
            theme: themeProp,
            ...props
        }: ToggleSelectProps,
        ref: React.Ref<ToggleSelectRef>,
    ) => {
        const [open, setOpen] = useState(false);
        const [buttonWidth, setButtonWidth] = useState<number | undefined>(
            undefined,
        );

        const theme = merge(dropdownTheme, themeProp || {});
        const theirProps = props as Omit<ToggleSelectProps, 'theme'>;
        const {
            placement = props.inline ? 'bottom-start' : 'bottom',
            trigger = 'click',
            label,
            inline,
            arrowIcon = true,
            onChange,
            ...buttonProps
        } = theirProps;

        const disabled = buttonProps.disabled || !items || items.length === 0;

        const { context, floatingStyles, refs } =
            useBaseFloating<HTMLButtonElement>({
                open,
                setOpen,
                placement,
            });

        const { getReferenceProps, getFloatingProps } = useFloatingInteractions(
            {
                context,
                role: 'menu',
                trigger,
            },
        );

        useImperativeHandle(ref, () => ({
            focus: () => {
                refs.domReference.current?.click();
            },
        }));

        const Icon = useMemo(() => {
            const [p] = placement.split('-');
            return icons[p!] ?? HiOutlineChevronDown;
        }, [placement]);

        const allSelected = items?.every((item) => item.checked);

        const toggleCheckAll = () => {
            onChange('all', !allSelected);
        };

        return (
            <>
                <Trigger
                    {...buttonProps}
                    disabled={disabled}
                    refs={refs}
                    inline={inline}
                    theme={theme}
                    buttonTheme={{
                        base: 'flex flex-col text-nowrap overflow-ellipsis',
                        color: {
                            dark: 'glass text-dark dark:text-white focus:ring-1 focus:ring-[#1C64F2] focus-visible:outline-none shadow-sm dark:shadow-sm-light',
                        },
                        size: {
                            md: 'text-sm pl-3 pr-3 py-3',
                        },
                        inner: {
                            base: 'h-full w-full flex items-center grow justify-between',
                        },
                    }}
                    className={twMerge(
                        theme.floating.target,
                        buttonProps.className,
                        compact ? 'h-9' : undefined,
                    )}
                    setButtonWidth={setButtonWidth}
                    getReferenceProps={getReferenceProps}
                >
                    {label}
                    {arrowIcon && <Icon className={theme.arrowIcon} />}
                </Trigger>

                <FloatingFocusManager context={context} modal={false}>
                    {createPortal(
                        <div
                            ref={refs.setFloating}
                            style={{ ...floatingStyles, minWidth: buttonWidth }}
                            data-testid="dropdown"
                            aria-expanded={open}
                            {...getFloatingProps({
                                className: twMerge(
                                    theme.floating.base,
                                    theme.floating.animation,
                                    'duration-100',
                                    !open && theme.floating.hidden,
                                    theme.floating.style.auto,
                                    className,
                                ),
                            })}
                        >
                            <ul className={twCn(className, 'mb-2')}>
                                {showAllCheckbox && (
                                    <li className="flex items-center mt-2 ml-1 border-b border-white border-opacity-20 pb-2">
                                        <Checkbox
                                            id="select-all"
                                            checked={allSelected}
                                            onChange={toggleCheckAll}
                                        />
                                        <Label
                                            className="text-dark dark:text-white font-sans ml-2"
                                            htmlFor="select-all"
                                        >
                                            All
                                        </Label>
                                    </li>
                                )}
                                {items?.map((selectItem) => {
                                    return (
                                        <li
                                            key={selectItem.value}
                                            className={twMerge(
                                                'mt-2 ml-1',
                                                className,
                                            )}
                                        >
                                            <div className="flex items-center">
                                                <Checkbox
                                                    id={
                                                        selectItem.id ||
                                                        selectItem.value
                                                    }
                                                    checked={selectItem.checked}
                                                    onChange={() =>
                                                        onChange(
                                                            selectItem.value,
                                                            !selectItem.checked,
                                                        )
                                                    }
                                                />
                                                <Label
                                                    className="text-dark dark:text-white font-sans ml-2 mr-1"
                                                    htmlFor={
                                                        selectItem.id ||
                                                        selectItem.value
                                                    }
                                                >
                                                    {selectItem.label}
                                                </Label>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>,
                        document.body,
                    )}
                </FloatingFocusManager>
            </>
        );
    },
);

ToggleSelect.displayName = 'ToggleSelect';
