import { WithLoader } from '@/components/WithLoader';
import { CompetitorMediaInvestmentGraph } from '@/dashboards/CompetitorsMediaInvestment/components/CompetitorMediaInvestmentGraph';
import { useCompetitorsMediaInvestmentQuery } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentQuery';
import { Typography } from '@analytical-alley/ui';
import React, { useRef } from 'react';

export const CompetitorMediaInvestmentSection = () => {
    const filterContainerRef = useRef<HTMLDivElement>(null);
    const {
        queryResult: { isLoading, isFetching },
    } = useCompetitorsMediaInvestmentQuery();

    return (
        <div className="glass-tile p-6">
            <WithLoader isLoading={isLoading} isFetching={isFetching}>
                <div className="flex justify-between mb-4">
                    <Typography variant="titleS">
                        Competitor Media Investment
                    </Typography>
                    <div ref={filterContainerRef} />
                </div>
                <CompetitorMediaInvestmentGraph
                    filterContainerRef={filterContainerRef}
                />
            </WithLoader>
        </div>
    );
};
