import { ModifyBudgetRow } from '@/components/ModifyBudgetRow';
import { WithLoader } from '@/components/WithLoader';
import { useBudgetOptimizationContext } from '@/dashboards/BudgetOptimization/context/BudgetOptimizationContext';
import {
    Can,
    FEATURE_FLAG_ACTIONS,
    FEATURE_FLAG_SUBJECTS,
} from '@/features/Casl';
import { CurrencyInput, Typography } from '@analytical-alley/ui';
import { Label } from 'flowbite-react';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';

export const ModifyBudget = () => {
    const {
        data: { previous, optimized },
        onSubmit,
        variableValues,
        margin,
        isLoading,
        isFetching,
    } = useBudgetOptimizationContext();

    const [marginInput, setMarginInput] = React.useState<number | null>(margin);

    const debouncedSubmit = useCallback(debounce(onSubmit, 1000), [onSubmit]);

    const handleMarginChange = useCallback(
        (value: number | null) => {
            setMarginInput(value);
            debouncedSubmit('margin', value);
        },
        [debouncedSubmit],
    );

    return (
        <div className="p-3 glass-tile">
            <WithLoader
                className={isLoading ? 'min-h-96' : undefined}
                isLoading={isLoading}
                isFetching={isFetching}
            >
                <div className="flex justify-between items-center py-3 pr-2 pl-3">
                    <Typography variant="titleM">Modify budget</Typography>
                    <Can
                        I={FEATURE_FLAG_ACTIONS.EDIT}
                        a={
                            FEATURE_FLAG_SUBJECTS.BUDGET_OPTIMIZATION_DASHBOARD_MARGIN
                        }
                    >
                        <div className="flex flex-col">
                            <div className="mb-1 block">
                                <Label
                                    className="font-mono font-normal"
                                    htmlFor="margin"
                                    value="Enter margin"
                                />
                            </div>

                            <CurrencyInput
                                id="margin"
                                className="w-20 self-center text-nowrap justify-center px-4 py-2 text-sm leading-5 rounded-lg shadow-lg backdrop-blur-[15px] bg-white bg-opacity-10 glass text-dark dark:text-white border-0 focus:ring-1 focus:ring-[#1C64F2] focus-visible:outline-none dark:shadow-sm-light"
                                decimalScale={0}
                                min={0}
                                max={100}
                                value={marginInput == null ? '' : marginInput}
                                suffix={'%'}
                                onValueChange={(e, s, values) => {
                                    const value = values?.float;

                                    if (value == null) {
                                        handleMarginChange(null);
                                        return;
                                    }

                                    if (value < 0) {
                                        handleMarginChange(0);
                                        return;
                                    }

                                    if (value > 100) {
                                        handleMarginChange(100);
                                        return;
                                    }

                                    handleMarginChange(value);
                                }}
                            />
                        </div>
                    </Can>
                </div>

                {Object.entries(
                    previous?.roiCurves.variablesAndCategories.byCategory || {},
                ).map(([categoryName, variables], index) => {
                    const showSeparator =
                        index <
                        Object.keys(
                            previous?.roiCurves.variablesAndCategories
                                .byCategory || {},
                        ).length -
                            1;

                    return (
                        <div key={categoryName}>
                            <div className="flex">
                                <div className="text-nowrap my-auto text-sm leading-5 text-white -rotate-90">
                                    {categoryName}
                                </div>
                                <div className="grow">
                                    {variables.map((variable) => {
                                        const opt =
                                            optimized?.optimalVariables[
                                                variable
                                            ];

                                        const mid = opt?.optimalSpend;

                                        const min =
                                            previous?.roiCurves.xAxis[0] || 0;

                                        return (
                                            <ModifyBudgetRow
                                                key={`${categoryName}-${variable}`}
                                                name={variable}
                                                sliderConfig={{
                                                    min: min,
                                                    mid: mid || 0,
                                                    max: optimized?.budget || 0,
                                                    startsWithProfit: true,
                                                    investmentStep:
                                                        optimized?.investmentStep,
                                                }}
                                                formValues={
                                                    variableValues[
                                                        variable
                                                    ] || {
                                                        locked: false,
                                                        investment: 0,
                                                    }
                                                }
                                                onSubmit={onSubmit}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            {showSeparator && (
                                <div className="h-1 bg-white bg-opacity-10 my-4 rounded-sm" />
                            )}
                        </div>
                    );
                })}
            </WithLoader>
        </div>
    );
};
