export function getMinAndMax<
    Obj extends Record<string, unknown>,
    Key extends keyof Obj,
>(
    arr: Obj[],
    key: Key,
): Obj[Key] extends number ? { min: Obj[Key]; max: Obj[Key] } : never {
    if (arr.length === 0) {
        throw new Error('Array is empty');
    }

    let min = arr[0]![key];
    let max = arr[0]![key];

    for (let i = 1; i < arr.length; i++) {
        const value = arr[i]![key];
        if (value < min) {
            min = value;
        } else if (value > max) {
            max = value;
        }
    }

    return { min, max } as Obj[Key] extends number
        ? { min: Obj[Key]; max: Obj[Key] }
        : never;
}
