import { TableRef } from '@/components/Table';
import { CampaignOptimizationLoader } from '@/dashboards/CampaignOptimization/components/CampaignOptimizationLoader';
import {
    MediaSpendSeasonalityTable,
    MediaSpendSeasonalityTableProps,
} from '@/dashboards/CampaignOptimization/components/MediaSpendSeasonality/MediaSpendSeasonalityTable';
import { useCampaignOptimizationContext } from '@/dashboards/CampaignOptimization/hooks';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { getPeriodOptionsForModel } from '@/utils/model';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { TabButtons, Typography } from '@analytical-alley/ui';
import { IconDownload } from '@tabler/icons-react';
import moment from 'moment';
import React, { useCallback, useMemo, useRef, useState } from 'react';

export const MediaSpendSeasonality = () => {
    const {
        data: { getMediaBudgetTableData },
    } = useCampaignOptimizationContext();

    const {
        project: { interval },
    } = useDashboardGlobalContext();

    const availablePeriodOptions = getPeriodOptionsForModel(interval, [
        'daily',
        'weekly',
        'monthly',
    ]);

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const selectedOption = availablePeriodOptions[activeTabIndex];

    const formatPeriodHeader: MediaSpendSeasonalityTableProps['formatPeriodHeader'] =
        useCallback(
            (period) => {
                switch (selectedOption) {
                    case 'daily':
                        return moment(period).format('MMM D, YY');
                    case 'monthly':
                        return moment(period, 'MM-YYYY').format('MMM, YY');
                    case 'weekly': {
                        const [week, year] = period.split('-');
                        return `Week ${week}, ${year}`;
                    }
                    default:
                        return '';
                }
            },
            [selectedOption],
        );

    const tableData = useMemo(() => {
        if (selectedOption) {
            return getMediaBudgetTableData(selectedOption);
        }
        return [];
    }, [getMediaBudgetTableData, selectedOption]);

    const tableRef = useRef<TableRef>(null);

    const downloadCsv = () => {
        tableRef.current?.downloadCsv('media-spend-seasonality');
    };

    return (
        <CampaignOptimizationLoader loadingClassName="min-h-96">
            <div className="flex items-center mb-4">
                <Typography variant="titleS">
                    Media Budget Recommendations
                </Typography>
                <TabButtons
                    aria-label="Periodicity"
                    style="pills"
                    className="gap-2 ml-2"
                    onActiveTabChange={(activeTab) => {
                        setActiveTabIndex(activeTab);
                    }}
                    activeTab={activeTabIndex}
                >
                    {availablePeriodOptions.map((periodName) => {
                        return (
                            <TabButtons.Item
                                key={periodName}
                                title={capitalizeFirstLetter(periodName)}
                            />
                        );
                    })}
                </TabButtons>
                <button
                    className="glass-tile ml-2 w-8 h-8 flex justify-center items-center p-2 rounded-md shadow-lg hover:bg-opacity-30"
                    onClick={downloadCsv}
                >
                    <IconDownload />
                </button>
            </div>
            <MediaSpendSeasonalityTable
                formatPeriodHeader={formatPeriodHeader}
                data={tableData}
                tableRef={tableRef}
            />
        </CampaignOptimizationLoader>
    );
};
