import { jsx as Ge } from "react/jsx-runtime";
import be, { forwardRef as we, useMemo as $e, useState as H, useRef as Me, useImperativeHandle as Te, useEffect as ye } from "react";
var u = function() {
  return u = Object.assign || function(r) {
    for (var a, t = 1, i = arguments.length; t < i; t++) {
      a = arguments[t];
      for (var n in a)
        Object.prototype.hasOwnProperty.call(a, n) && (r[n] = a[n]);
    }
    return r;
  }, u.apply(this, arguments);
};
function We(e, r) {
  var a = {};
  for (var t in e)
    Object.prototype.hasOwnProperty.call(e, t) && r.indexOf(t) < 0 && (a[t] = e[t]);
  if (e != null && typeof Object.getOwnPropertySymbols == "function")
    for (var i = 0, t = Object.getOwnPropertySymbols(e); i < t.length; i++)
      r.indexOf(t[i]) < 0 && Object.prototype.propertyIsEnumerable.call(e, t[i]) && (a[t[i]] = e[t[i]]);
  return a;
}
function V(e, r, a) {
  if (a || arguments.length === 2)
    for (var t = 0, i = r.length, n; t < i; t++)
      (n || !(t in r)) && (n || (n = Array.prototype.slice.call(r, 0, t)), n[t] = r[t]);
  return e.concat(n || Array.prototype.slice.call(r));
}
var N = function(e) {
  return e.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
}, He = { k: 1e3, m: 1e6, b: 1e9 }, qe = function(e, r) {
  r === void 0 && (r = ".");
  var a = new RegExp("(\\d+(".concat(N(r), "\\d*)?)([kmb])$"), "i"), t = e.match(a);
  if (t) {
    var i = t[1], n = t[3], o = He[n.toLowerCase()];
    return Number(i.replace(r, ".")) * o;
  }
}, ze = function(e, r) {
  r === void 0 && (r = ",");
  var a = new RegExp(N(r), "g");
  return e.replace(a, "");
}, Je = function(e, r) {
  var a = N(r.join("")), t = new RegExp("[^\\d".concat(a, "]"), "gi");
  return e.replace(t, "");
}, le = function(e) {
  var r = e.value, a = e.groupSeparator, t = a === void 0 ? "," : a, i = e.decimalSeparator, n = i === void 0 ? "." : i, o = e.allowDecimals, c = o === void 0 ? !0 : o, l = e.decimalsLimit, E = l === void 0 ? 2 : l, R = e.allowNegativeValue, d = R === void 0 ? !0 : R, v = e.disableAbbreviations, I = v === void 0 ? !1 : v, p = e.prefix, f = p === void 0 ? "" : p, m = e.transformRawValue, k = m === void 0 ? function($) {
    return $;
  } : m, w = k(r);
  if (w === "-")
    return w;
  var O = I ? [] : ["k", "m", "b"], K = new RegExp("((^|\\D)-\\d)|(-".concat(N(f), ")")), ae = K.test(w), P = RegExp("(\\d+)-?".concat(N(f))).exec(r) || [], D = P[0], z = P[1], J = f ? D ? w.replace(D, "").concat(z) : w.replace(f, "") : w, Q = ze(J, t), A = Je(Q, V([
    t,
    n
  ], O, !0)), j = A;
  if (!I) {
    if (O.some(function($) {
      return $ === A.toLowerCase().replace(n, "");
    }))
      return "";
    var X = qe(A, n);
    X && (j = String(X));
  }
  var B = ae && d ? "-" : "";
  if (n && j.includes(n)) {
    var L = A.split(n), Y = L[0], U = L[1], G = E && U ? U.slice(0, E) : U, Z = c ? "".concat(n).concat(G) : "";
    return "".concat(B).concat(Y).concat(Z);
  }
  return "".concat(B).concat(j);
}, Qe = function(e, r, a) {
  if (a !== void 0 && e.length > 1) {
    if (a === 0)
      return e.replace(r, "");
    if (e.includes(r)) {
      var t = e.split(r), i = t[0], n = t[1];
      if (n.length === a)
        return e;
      if (n.length > a)
        return "".concat(i).concat(r).concat(n.slice(0, a));
    }
    var o = e.length > a ? new RegExp("(\\d+)(\\d{".concat(a, "})")) : new RegExp("(\\d)(\\d+)"), c = e.match(o);
    if (c) {
      var i = c[1], n = c[2];
      return "".concat(i).concat(r).concat(n);
    }
  }
  return e;
}, xe = function(e, r) {
  var a = r.groupSeparator, t = a === void 0 ? "," : a, i = r.decimalSeparator, n = i === void 0 ? "." : i, o = new RegExp("\\d([^".concat(N(t)).concat(N(n), "0-9]+)")), c = e.match(o);
  return c ? c[1] : void 0;
}, q = function(e) {
  var r = e.value, a = e.decimalSeparator, t = e.intlConfig, i = e.decimalScale, n = e.prefix, o = n === void 0 ? "" : n, c = e.suffix, l = c === void 0 ? "" : c;
  if (r === "" || r === void 0)
    return "";
  if (r === "-")
    return "-";
  var E = new RegExp("^\\d?-".concat(o ? "".concat(N(o), "?") : "", "\\d")).test(r), R = a !== "." ? Xe(r, a, E) : r;
  a && a !== "-" && R.startsWith(a) && (R = "0" + R);
  var d = {
    minimumFractionDigits: i || 0,
    maximumFractionDigits: 20
  }, v = t ? new Intl.NumberFormat(t.locale, t.currency ? u(u({}, d), { style: "currency", currency: t.currency }) : d) : new Intl.NumberFormat(void 0, d), I = v.formatToParts(Number(R)), p = Ye(I, e), f = xe(p, u({}, e)), m = r.slice(-1) === a ? a : "", k = R.match(RegExp("\\d+\\.(\\d+)")) || [], w = k[1];
  return i === void 0 && w && a && (p.includes(a) ? p = p.replace(RegExp("(\\d+)(".concat(N(a), ")(\\d+)"), "g"), "$1$2".concat(w)) : f && !l ? p = p.replace(f, "".concat(a).concat(w).concat(f)) : p = "".concat(p).concat(a).concat(w)), l && m ? "".concat(p).concat(m).concat(l) : f && m ? p.replace(f, "".concat(m).concat(f)) : f && l ? p.replace(f, "".concat(m).concat(l)) : [p, m, l].join("");
}, Xe = function(e, r, a) {
  var t = e;
  return r && r !== "." && (t = t.replace(RegExp(N(r), "g"), "."), a && r === "-" && (t = "-".concat(t.slice(1)))), t;
}, Ye = function(e, r) {
  var a = r.prefix, t = r.groupSeparator, i = r.decimalSeparator, n = r.decimalScale, o = r.disableGroupSeparators, c = o === void 0 ? !1 : o;
  return e.reduce(function(l, E, R) {
    var d = E.type, v = E.value;
    return R === 0 && a ? d === "minusSign" ? [v, a] : d === "currency" ? V(V([], l, !0), [a], !1) : [a, v] : d === "currency" ? a ? l : V(V([], l, !0), [v], !1) : d === "group" ? c ? l : V(V([], l, !0), [t !== void 0 ? t : v], !1) : d === "decimal" ? n !== void 0 && n === 0 ? l : V(V([], l, !0), [i !== void 0 ? i : v], !1) : d === "fraction" ? V(V([], l, !0), [n !== void 0 ? v.slice(0, n) : v], !1) : V(V([], l, !0), [v], !1);
  }, [""]).join("");
}, Ze = {
  currencySymbol: "",
  groupSeparator: "",
  decimalSeparator: "",
  prefix: "",
  suffix: ""
}, _e = function(e) {
  var r = e || {}, a = r.locale, t = r.currency, i = a ? new Intl.NumberFormat(a, t ? { currency: t, style: "currency" } : void 0) : new Intl.NumberFormat();
  return i.formatToParts(1000.1).reduce(function(n, o, c) {
    return o.type === "currency" ? c === 0 ? u(u({}, n), { currencySymbol: o.value, prefix: o.value }) : u(u({}, n), { currencySymbol: o.value, suffix: o.value }) : o.type === "group" ? u(u({}, n), { groupSeparator: o.value }) : o.type === "decimal" ? u(u({}, n), { decimalSeparator: o.value }) : n;
  }, Ze);
}, he = function(e) {
  return RegExp(/\d/, "gi").test(e);
}, er = function(e, r, a) {
  if (r === void 0 && (r = "."), a === void 0 || e === "" || e === void 0)
    return e;
  if (!e.match(/\d/g))
    return "";
  var t = e.split(r), i = t[0], n = t[1];
  if (a === 0)
    return i;
  var o = n || "";
  if (o.length < a)
    for (; o.length < a; )
      o += "0";
  else
    o = o.slice(0, a);
  return "".concat(i).concat(r).concat(o);
}, rr = function(e) {
  var r = e.selectionStart, a = e.value, t = e.lastKeyStroke, i = e.stateValue, n = e.groupSeparator, o = r, c = a;
  if (i && o) {
    var l = a.split("");
    return t === "Backspace" && i[o] === n && (l.splice(o - 1, 1), o -= 1), t === "Delete" && i[o] === n && (l.splice(o, 1), o += 1), c = l.join(""), { modifiedValue: c, cursorPosition: o };
  }
  return { modifiedValue: c, cursorPosition: r };
}, Ve = we(function(e, r) {
  var a = e.allowDecimals, t = a === void 0 ? !0 : a, i = e.allowNegativeValue, n = i === void 0 ? !0 : i, o = e.id, c = e.name, l = e.className, E = e.customInput, R = e.decimalsLimit, d = e.defaultValue, v = e.disabled, I = v === void 0 ? !1 : v, p = e.maxLength, f = e.value, m = e.onValueChange, k = e.fixedDecimalLength, w = e.placeholder, O = e.decimalScale, K = e.prefix, ae = e.suffix, P = e.intlConfig, D = e.step, z = e.min, J = e.max, Q = e.disableGroupSeparators, A = Q === void 0 ? !1 : Q, j = e.disableAbbreviations, X = j === void 0 ? !1 : j, B = e.decimalSeparator, L = e.groupSeparator, Y = e.onChange, U = e.onFocus, G = e.onBlur, Z = e.onKeyDown, $ = e.onKeyUp, Re = e.transformRawValue, ce = e.formatValueOnBlur, Ce = ce === void 0 ? !0 : ce, Ee = We(e, ["allowDecimals", "allowNegativeValue", "id", "name", "className", "customInput", "decimalsLimit", "defaultValue", "disabled", "maxLength", "value", "onValueChange", "fixedDecimalLength", "placeholder", "decimalScale", "prefix", "suffix", "intlConfig", "step", "min", "max", "disableGroupSeparators", "disableAbbreviations", "decimalSeparator", "groupSeparator", "onChange", "onFocus", "onBlur", "onKeyDown", "onKeyUp", "transformRawValue", "formatValueOnBlur"]);
  if (B && he(B))
    throw new Error("decimalSeparator cannot be a number");
  if (L && he(L))
    throw new Error("groupSeparator cannot be a number");
  var _ = $e(function() {
    return _e(P);
  }, [P]), g = B || _.decimalSeparator || "", M = L || _.groupSeparator || "";
  if (g && M && g === M && A === !1)
    throw new Error("decimalSeparator cannot be the same as groupSeparator");
  var T = {
    decimalSeparator: g,
    groupSeparator: M,
    disableGroupSeparators: A,
    intlConfig: P,
    prefix: K || _.prefix,
    suffix: ae
  }, te = {
    decimalSeparator: g,
    groupSeparator: M,
    allowDecimals: t,
    decimalsLimit: R || k || 2,
    allowNegativeValue: n,
    disableAbbreviations: X,
    prefix: K || _.prefix,
    transformRawValue: Re
  }, fe = H(function() {
    return d != null ? q(u(u({}, T), { decimalScale: O, value: String(d) })) : f != null ? q(u(u({}, T), { decimalScale: O, value: String(f) })) : "";
  }), b = fe[0], W = fe[1], se = H(!1), ne = se[0], Ne = se[1], pe = H(0), oe = pe[0], ie = pe[1], de = H(0), ve = de[0], Oe = de[1], me = H(null), De = me[0], Fe = me[1], F = Me(null);
  Te(r, function() {
    return F.current;
  });
  var ge = function(s, y) {
    Ne(!0);
    var S = rr({
      selectionStart: y,
      value: s,
      lastKeyStroke: De,
      stateValue: b,
      groupSeparator: M
    }), h = S.modifiedValue, x = S.cursorPosition, C = le(u({ value: h }, te));
    if (!(p && C.replace(/-/g, "").length > p)) {
      if (C === "" || C === "-" || C === g) {
        m && m(void 0, c, { float: null, formatted: "", value: "" }), W(C), ie(1);
        return;
      }
      var ee = g ? C.replace(g, ".") : C, Le = parseFloat(ee), ue = q(u({ value: C }, T));
      if (x != null) {
        var re = x + (ue.length - s.length);
        re = re <= 0 ? K ? K.length : 0 : re, ie(re), Oe(ve + 1);
      }
      if (W(ue), m) {
        var Ue = {
          float: Le,
          formatted: ue,
          value: C
        };
        m(C, c, Ue);
      }
    }
  }, ke = function(s) {
    var y = s.target, S = y.value, h = y.selectionStart;
    ge(S, h), Y && Y(s);
  }, Ae = function(s) {
    return U && U(s), b ? b.length : 0;
  }, Ie = function(s) {
    var y = s.target.value, S = le(u({ value: y }, te));
    if (S === "-" || S === g || !S) {
      W(""), G && G(s);
      return;
    }
    var h = Qe(S, g, k), x = er(h, g, O !== void 0 ? O : k), C = parseFloat(x.replace(g, ".")), ee = q(u(u({}, T), { value: x }));
    m && Ce && m(x, c, {
      float: C,
      formatted: ee,
      value: x
    }), W(ee), G && G(s);
  }, Ke = function(s) {
    var y = s.key;
    if (Fe(y), D && (y === "ArrowUp" || y === "ArrowDown")) {
      s.preventDefault(), ie(b.length);
      var S = parseFloat(f != null ? String(f).replace(g, ".") : le(u({ value: b }, te))) || 0, h = y === "ArrowUp" ? S + D : S - D;
      if (z !== void 0 && h < Number(z) || J !== void 0 && h > Number(J))
        return;
      var x = String(D).includes(".") ? Number(String(D).split(".")[1].length) : void 0;
      ge(String(x ? h.toFixed(x) : h).replace(".", g));
    }
    Z && Z(s);
  }, Pe = function(s) {
    var y = s.key, S = s.currentTarget.selectionStart;
    if (y !== "ArrowUp" && y !== "ArrowDown" && b !== "-") {
      var h = xe(b, { groupSeparator: M, decimalSeparator: g });
      if (h && S && S > b.length - h.length && F.current) {
        var x = b.length - h.length;
        F.current.setSelectionRange(x, x);
      }
    }
    $ && $(s);
  };
  ye(function() {
    f == null && d == null && W("");
  }, [d, f]), ye(function() {
    ne && b !== "-" && F.current && document.activeElement === F.current && F.current.setSelectionRange(oe, oe);
  }, [b, oe, F, ne, ve]);
  var je = function() {
    return f != null && b !== "-" && (!g || b !== g) ? q(u(u({}, T), { decimalScale: ne ? void 0 : O, value: String(f) })) : b;
  }, Se = u({ type: "text", inputMode: "decimal", id: o, name: c, className: l, onChange: ke, onBlur: Ie, onFocus: Ae, onKeyDown: Ke, onKeyUp: Pe, placeholder: w, disabled: I, value: je(), ref: F }, Ee);
  if (E) {
    var Be = E;
    return be.createElement(Be, u({}, Se));
  }
  return be.createElement("input", u({}, Se));
});
Ve.displayName = "CurrencyInput";
const ar = (e, r) => /* @__PURE__ */ Ge(Ve, { ...e, ref: r }), or = we(ar);
export {
  or as CurrencyInput
};
