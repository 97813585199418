import { createContext as e, useContext as o } from "react";
const r = e(void 0);
function i() {
  const t = o(r);
  if (!t)
    throw new Error(
      "useDatePickerContext should be used within the DatePickerContext provider!"
    );
  return t;
}
export {
  r as DatepickerContext,
  i as useDatePickerContext
};
