import {
    CompetitorsMediaInvestment,
    competitorsMediaInvestmentSchema,
} from '@/dashboards/CompetitorsMediaInvestment';
import {
    FEATURE_FLAG_ACTIONS,
    FEATURE_FLAG_SUBJECTS,
    withAuthorization,
} from '@/features/Casl';
import { FullPageLoader } from '@analytical-alley/ui';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
    '/_org/project/$projectId/_dashboards/competitors-media-investment',
)({
    component: withAuthorization(
        CompetitorsMediaInvestment,
        FEATURE_FLAG_ACTIONS.VIEW,
        FEATURE_FLAG_SUBJECTS.COMPETITORS_MEDIA_DASHBOARD,
    ),
    pendingComponent: FullPageLoader,
    validateSearch: competitorsMediaInvestmentSchema,
});
