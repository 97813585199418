import { FullScreenProps, withFullScreen } from '@/components/FullScreen';
import { WithLoader } from '@/components/WithLoader';
import { ContributionAreaChart } from '@/dashboards/ModelContributions/components/ContributionAreaGraph';
import { useModelContributionContext } from '@/dashboards/ModelContributions/hooks';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { TabButtons, Typography } from '@analytical-alley/ui';
import React from 'react';

const ContributionGraphSectionBase = ({
    fullScreenButton,
}: FullScreenProps) => {
    const {
        queryResult: { isLoading, isFetching },
        tabsToRender,
        onActiveTabChange,
        activeTabIndex,
    } = useModelContributionContext();

    return (
        <div className={'glass-tile p-6'}>
            <div className="flex -mb-6 relative z-10">
                <Typography variant="titleS">Contribution</Typography>
                <span className="grow" />
                <TabButtons
                    aria-label="Periodicity"
                    style="pills"
                    className="gap-2 px-2"
                    onActiveTabChange={onActiveTabChange}
                    isLoading={isLoading}
                    activeTab={activeTabIndex}
                >
                    {tabsToRender?.map((periodName) => {
                        return (
                            <TabButtons.Item
                                key={periodName}
                                title={capitalizeFirstLetter(periodName)}
                                disabled={isLoading}
                            />
                        );
                    })}
                </TabButtons>
                {fullScreenButton}
            </div>
            <WithLoader isLoading={isLoading} isFetching={isFetching}>
                <ContributionAreaChart
                    isLoading={isLoading}
                    className={isFetching ? 'opacity-50' : undefined}
                />
            </WithLoader>
        </div>
    );
};

export const ContributionGraphSection = withFullScreen(
    'contribution-graph',
    ContributionGraphSectionBase,
);

ContributionGraphSection.displayName = 'ContributionGraphSection';
