import { PercentItem } from '@/components/PercentItem';
import { Typography } from '@analytical-alley/ui';

export const BudgetSharePieTooltip = ({
    data,
    percent,
    value,
    showPercent,
    title = 'Budget',
}: {
    data: { value: number; name: string; percentage?: number };
    percent: number;
    value: string;
    showPercent: boolean;
    title?: string;
}) => {
    const { percentage: changePercent } = data;

    return (
        <div className="tooltip-content">
            <table className="font-sans">
                <tbody>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                {data.name}:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {percent}%
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                {title}:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {value}
                            </Typography>
                        </td>
                    </tr>
                    {showPercent && changePercent !== undefined && (
                        <tr>
                            <td className="pr-2">
                                <Typography className="text-black dark:text-black">
                                    Recommended budget change:
                                </Typography>
                            </td>
                            <td>
                                <Typography className="text-dark dark:text-dark">
                                    <PercentItem
                                        percent={changePercent}
                                        variant="bodyL"
                                    />
                                </Typography>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
