import { TreeNodeLike } from '@/components/TreeSelect/useTreeSelect/types';
import { FilterNode } from '@/dashboards/ModelContributions/components/ActionBar/Filter';
import {
    useModelContributionQuery,
    useModelContributionUrl,
} from '@/dashboards/ModelContributions/hooks';
import { UseModelContributionQueryReturn } from '@/dashboards/ModelContributions/hooks/useModelContributionQuery';
import { UseModelContributionUrlReturn } from '@/dashboards/ModelContributions/hooks/useModelContributionUrl';
import { GetModelContributionResponse } from '@/dashboards/ModelContributions/types';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { ContributionAndSpendByPeriod } from '@/dashboards/types';
import { groupContributionData } from '@/dashboards/utils/data';
import { DefaultError, UseQueryResult } from '@tanstack/react-query';
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { createContext } from 'use-context-selector';

export type FormattedContributionData = {
    dataByVariable: ContributionAndSpendByPeriod;
    dataByCategory: ContributionAndSpendByPeriod;
    totalSpend: number;
    totalSpendByCategory: Map<string, number>;
    totalSpendByVariable: Map<string, number>;
    totalContribution: number;
    totalContributionByCategory: Map<string, number>;
    totalContributionByVariable: Map<string, number>;
    variableToCategory: Map<string, string>;
    treeNodes: TreeNodeLike[];
    variableData: {
        variable: string;
        category: string;
        color: string;
    }[];
    categoryData: {
        category: string;
        color: string;
    }[];
    dates: string[];
    totalContributionByDate: Record<string, number>;
};

export const ModelContributionContext = createContext<
    {
        contributionData: FormattedContributionData;
        handleSelectedChange: Dispatch<SetStateAction<string>>;
        setSelectedNodes: (nodes: FilterNode[]) => void;
        selectedFilterNodes: { [key: string]: FilterNode };
        getVisibleData: () => Record<
            string,
            {
                data: {
                    [key: string]: { contribution: number; spend: number };
                };
                color: string;
            }
        >;
        selected: string;
        queryResult: UseQueryResult<GetModelContributionResponse, DefaultError>;
    } & UseModelContributionQueryReturn &
        UseModelContributionUrlReturn
>({
    contributionData: {} as FormattedContributionData,
    handleSelectedChange: () => {},
    setSelectedNodes: () => {},
    selectedFilterNodes: {},
    getVisibleData: () => ({}),
    selected: '',
    queryResult: {} as UseQueryResult<
        GetModelContributionResponse,
        DefaultError
    >,
    activeTabIndex: 0,
    tabsToRender: [],
    onActiveTabChange: () => {},
    search: {
        modelBreakdown: 'category',
        period: 'monthly',
    },
    params: {} as UseModelContributionUrlReturn['params'],
    setSearch: () => {},
});

export const ModelContributionProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const {
        colorMap: { variableColorMap, categoryColorMap },
    } = useDashboardGlobalContext();
    const [selected, setSelected] = useState<string>('');
    const [selectedFilterNodes, setSelectedFilterNodes] = useState<{
        [key: string]: FilterNode;
    }>({});
    const { search, params, setSearch } = useModelContributionUrl();

    const { queryResult, ...tabActions } = useModelContributionQuery();

    const { data } = queryResult;

    const modelContributionData = useMemo(() => {
        return groupContributionData({
            rows: data?.rows,
            variableColorMap,
            categoryColorMap,
        });
    }, [categoryColorMap, data, variableColorMap]);

    const { dataByVariable, variableToCategory, dataByCategory } =
        modelContributionData;

    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setSelected('');
    }, [search.modelBreakdown]);

    function setSelectedNodes(nodes: FilterNode[]) {
        const nodesMap = nodes.reduce((acc, node) => {
            acc[node.name] = node;
            return acc;
        }, {});

        if (!nodesMap[selected]) {
            setSelected('');
        }

        setSelectedFilterNodes(nodesMap);
    }

    const getVisibleData = useCallback(() => {
        return Object.fromEntries(
            Object.entries(
                search.modelBreakdown === 'category'
                    ? dataByCategory
                    : dataByVariable,
            ).filter(([name, _]) => {
                if (search.modelBreakdown === 'category')
                    return selectedFilterNodes[name];

                return (
                    selectedFilterNodes[name] ||
                    selectedFilterNodes[variableToCategory.get(name)!]
                );
            }),
        );
    }, [
        dataByCategory,
        dataByVariable,
        search.modelBreakdown,
        selectedFilterNodes,
        variableToCategory,
    ]);

    return (
        <ModelContributionContext.Provider
            value={{
                getVisibleData,
                contributionData: modelContributionData,
                selected,
                handleSelectedChange: setSelected,
                setSelectedNodes,
                selectedFilterNodes,
                search,
                params,
                queryResult,
                setSearch,
                ...tabActions,
            }}
        >
            {children}
        </ModelContributionContext.Provider>
    );
};
