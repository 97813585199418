import { useFloating as S, autoUpdate as v, offset as C, flip as M, shift as O, useClientPoint as b, useHover as w, useFocus as A, useDismiss as D, useRole as F, useTransitionStyles as T, useInteractions as U } from "@floating-ui/react";
import { useState as k, useMemo as E } from "react";
function P({
  initialOpen: r = !1,
  placement: i = "top",
  open: o,
  onOpenChange: p,
  disabled: l
} = {}) {
  const [d, f] = k(r), s = o ?? d, t = p ?? f, n = S({
    placement: i,
    open: s,
    onOpenChange: t,
    whileElementsMounted: v,
    middleware: [
      C(15),
      M({
        crossAxis: i.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5
      }),
      O({ padding: 5 })
    ]
  }), e = n.context, m = b(e), g = w(e, {
    move: !1,
    enabled: o == null && !l,
    delay: {
      open: 250,
      close: 0
    }
  }), h = A(e, {
    enabled: o == null && !l
  }), x = D(e), y = F(e, { role: "tooltip" }), { isMounted: c, styles: u } = T(e, {
    duration: 250
  }), a = U([
    m,
    g,
    h,
    x,
    y
  ]);
  return E(
    () => ({
      open: s,
      setOpen: t,
      transitionStyles: u,
      isMounted: c,
      ...a,
      ...n
    }),
    [s, t, u, c, a, n]
  );
}
export {
  P as useTooltip
};
