import { modelContributionsQueries } from '@/duckdb/model-contributions';
import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import moment from 'moment/moment';
import {
    GetModelContributionResponse,
    ModelContributionQueryArgs,
} from '../types';

export const fetchModelContribution = async ({
    startDate: startDateArg,
    endDate: endDateArg,
    ...args
}: ModelContributionQueryArgs): Promise<GetModelContributionResponse> => {
    const startDate = startDateArg
        ? moment(startDateArg, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : '';
    const endDate = endDateArg
        ? moment(endDateArg, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : '';

    const response = await modelContributionsQueries.getRows({
        startDate,
        endDate,
        ...args,
    });

    return {
        rows: response,
    };
};

export const modelContributionQueryOptions = (
    args: ModelContributionQueryArgs,
    options?: { enabled?: boolean },
) => {
    const { period, startDate, endDate, onlyMedia } = args;
    return queryOptions({
        queryKey: ['modelContribution', period, startDate, endDate, onlyMedia],
        queryFn: () => fetchModelContribution(args),
        placeholderData: keepPreviousData,
        staleTime: Infinity,
        enabled:
            (options?.enabled == null || options?.enabled) &&
            !!startDate &&
            !!endDate,
    });
};
