import { SelectRef } from '@/components/Select/Select';
import { KEY_EVENT_KEYS } from '@/config/constants';
import { useConfigContext } from '@/context/configContext';
import { useCampaignOptimizationContext } from '@/dashboards/CampaignOptimization/hooks';
import { useCampaignOptimizationUrl } from '@/dashboards/CampaignOptimization/hooks/useCampaignOptimizationUrl';
import { DateRange } from '@/dashboards/ModelContributions/components/ActionBar/DateRange';
import { CurrencyInput } from '@analytical-alley/ui';
import { Label } from 'flowbite-react';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';

export const ActionBar = () => {
    const {
        search: { budget, endDate, startDate },
        setSearch,
    } = useCampaignOptimizationUrl();

    const { minDate, maxDate } = useCampaignOptimizationContext();

    const { locale, currency } = useConfigContext();
    const [estimatedBudget, setEstimatedBudget] = React.useState<
        number | undefined
    >(0);

    useEffect(() => {
        setEstimatedBudget(budget);
    }, [budget]);

    const selectRef = useRef<SelectRef>(null);

    const minDateObj = moment(minDate, 'DD-MM-YYYY').toDate();
    const tempMaxDateObj = moment(startDate, 'DD-MM-YYYY').add(6, 'months');

    const maxDateObj = tempMaxDateObj.isAfter(moment(maxDate, 'DD-MM-YYYY'))
        ? moment(maxDate, 'DD-MM-YYYY').toDate()
        : tempMaxDateObj.toDate();

    const handleSetSearch = () => {
        if (budget !== estimatedBudget) {
            setSearch({ budget: estimatedBudget });
        }
    };

    return (
        <>
            <div className="flex gap-4">
                <div>
                    <div className="mb-1 block">
                        <Label
                            onClick={() => {
                                selectRef?.current?.click?.();
                            }}
                            className="font-mono font-normal text-nowrap truncate"
                            htmlFor="media-budget"
                            value="Media budget"
                        />
                    </div>
                    <CurrencyInput
                        id="budget"
                        className="w-full self-center text-nowrap justify-center px-4 py-2 text-sm leading-5 rounded-lg shadow-lg backdrop-blur-[15px] bg-white bg-opacity-10 glass text-dark dark:text-white border-0 focus:ring-1 focus:ring-[#1C64F2] focus-visible:outline-none dark:shadow-sm-light"
                        intlConfig={{ locale, currency }}
                        decimalScale={0}
                        value={estimatedBudget == null ? '' : estimatedBudget}
                        onBlur={handleSetSearch}
                        groupSeparator={' '}
                        onKeyDown={(e) => {
                            if (e.key === KEY_EVENT_KEYS.ENTER) {
                                handleSetSearch();
                            }
                        }}
                        onValueChange={(_, _1, values) => {
                            const value = values?.float;

                            if (value == null) {
                                setEstimatedBudget(0);
                                return;
                            }

                            if (value < 0) {
                                setEstimatedBudget(0);
                                return;
                            }

                            setEstimatedBudget(value);
                        }}
                    />
                </div>
                <DateRange
                    searchStartDate={startDate}
                    searchEndDate={endDate}
                    maxDate={maxDateObj}
                    minDate={minDateObj}
                    onSetSearch={setSearch}
                />
            </div>
        </>
    );
};
