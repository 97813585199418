var ut = (e, t, s) => {
  if (!t.has(e))
    throw TypeError("Cannot " + s);
};
var r = (e, t, s) => (ut(e, t, "read from private field"), s ? s.call(e) : t.get(e)), u = (e, t, s) => {
  if (t.has(e))
    throw TypeError("Cannot add the same private member more than once");
  t instanceof WeakSet ? t.add(e) : t.set(e, s);
}, a = (e, t, s, i) => (ut(e, t, "write to private field"), i ? i.call(e, s) : t.set(e, s), s);
var v = (e, t, s) => (ut(e, t, "access private method"), s);
import { jsx as jt } from "react/jsx-runtime";
import { runQuery as Bt } from "../db/query.js";
import { duckDB as At } from "../duckDB.js";
import { useDuckDB as Nt } from "../hooks/useDuckDB.js";
import { useDuckDBConnectionContext as zt } from "../providers/connectionProvider.js";
import { ResolvableStatus as ct } from "../utils/resolvable.js";
import * as E from "react";
import { createContext as _t, useContext as Ht } from "react";
var mt = class {
  constructor() {
    this.listeners = /* @__PURE__ */ new Set(), this.subscribe = this.subscribe.bind(this);
  }
  subscribe(e) {
    return this.listeners.add(e), this.onSubscribe(), () => {
      this.listeners.delete(e), this.onUnsubscribe();
    };
  }
  hasListeners() {
    return this.listeners.size > 0;
  }
  onSubscribe() {
  }
  onUnsubscribe() {
  }
}, it = typeof window > "u" || "Deno" in globalThis;
function Vt() {
}
function wt(e) {
  return typeof e == "number" && e >= 0 && e !== 1 / 0;
}
function qt(e, t) {
  return Math.max(e + (t || 0) - Date.now(), 0);
}
function xt(e, t) {
  if (e === t)
    return e;
  const s = Ct(e) && Ct(t);
  if (s || Et(e) && Et(t)) {
    const i = s ? e : Object.keys(e), n = i.length, h = s ? t : Object.keys(t), c = h.length, o = s ? [] : {};
    let y = 0;
    for (let g = 0; g < c; g++) {
      const p = s ? g : h[g];
      (!s && i.includes(p) || s) && e[p] === void 0 && t[p] === void 0 ? (o[p] = void 0, y++) : (o[p] = xt(e[p], t[p]), o[p] === e[p] && e[p] !== void 0 && y++);
    }
    return n === c && y === n ? e : o;
  }
  return t;
}
function ht(e, t) {
  if (!t || Object.keys(e).length !== Object.keys(t).length)
    return !1;
  for (const s in e)
    if (e[s] !== t[s])
      return !1;
  return !0;
}
function Ct(e) {
  return Array.isArray(e) && e.length === Object.keys(e).length;
}
function Et(e) {
  if (!Rt(e))
    return !1;
  const t = e.constructor;
  if (t === void 0)
    return !0;
  const s = t.prototype;
  return !(!Rt(s) || !s.hasOwnProperty("isPrototypeOf") || Object.getPrototypeOf(e) !== Object.prototype);
}
function Rt(e) {
  return Object.prototype.toString.call(e) === "[object Object]";
}
function St(e, t, s) {
  return typeof s.structuralSharing == "function" ? s.structuralSharing(e, t) : s.structuralSharing !== !1 ? xt(e, t) : t;
}
var P, D, B, Lt, Kt = (Lt = class extends mt {
  constructor() {
    super();
    u(this, P, void 0);
    u(this, D, void 0);
    u(this, B, void 0);
    a(this, B, (t) => {
      if (!it && window.addEventListener) {
        const s = () => t();
        return window.addEventListener("visibilitychange", s, !1), () => {
          window.removeEventListener("visibilitychange", s);
        };
      }
    });
  }
  onSubscribe() {
    r(this, D) || this.setEventListener(r(this, B));
  }
  onUnsubscribe() {
    var t;
    this.hasListeners() || ((t = r(this, D)) == null || t.call(this), a(this, D, void 0));
  }
  setEventListener(t) {
    var s;
    a(this, B, t), (s = r(this, D)) == null || s.call(this), a(this, D, t((i) => {
      typeof i == "boolean" ? this.setFocused(i) : this.onFocus();
    }));
  }
  setFocused(t) {
    r(this, P) !== t && (a(this, P, t), this.onFocus());
  }
  onFocus() {
    const t = this.isFocused();
    this.listeners.forEach((s) => {
      s(t);
    });
  }
  isFocused() {
    var t;
    return typeof r(this, P) == "boolean" ? r(this, P) : ((t = globalThis.document) == null ? void 0 : t.visibilityState) !== "hidden";
  }
}, P = new WeakMap(), D = new WeakMap(), B = new WeakMap(), Lt), Wt = new Kt(), A, Q, N, It, Gt = (It = class extends mt {
  constructor() {
    super();
    u(this, A, !0);
    u(this, Q, void 0);
    u(this, N, void 0);
    a(this, N, (t) => {
      if (!it && window.addEventListener) {
        const s = () => t(!0), i = () => t(!1);
        return window.addEventListener("online", s, !1), window.addEventListener("offline", i, !1), () => {
          window.removeEventListener("online", s), window.removeEventListener("offline", i);
        };
      }
    });
  }
  onSubscribe() {
    r(this, Q) || this.setEventListener(r(this, N));
  }
  onUnsubscribe() {
    var t;
    this.hasListeners() || ((t = r(this, Q)) == null || t.call(this), a(this, Q, void 0));
  }
  setEventListener(t) {
    var s;
    a(this, N, t), (s = r(this, Q)) == null || s.call(this), a(this, Q, t(this.setOnline.bind(this)));
  }
  setOnline(t) {
    r(this, A) !== t && (a(this, A, t), this.listeners.forEach((i) => {
      i(t);
    }));
  }
  isOnline() {
    return r(this, A);
  }
}, A = new WeakMap(), Q = new WeakMap(), N = new WeakMap(), It), Jt = new Gt();
function Xt(e) {
  return (e ?? "online") === "online" ? Jt.isOnline() : !0;
}
function Yt() {
  let e = [], t = 0, s = (l) => {
    l();
  }, i = (l) => {
    l();
  }, n = (l) => setTimeout(l, 0);
  const h = (l) => {
    n = l;
  }, c = (l) => {
    let b;
    t++;
    try {
      b = l();
    } finally {
      t--, t || g();
    }
    return b;
  }, o = (l) => {
    t ? e.push(l) : n(() => {
      s(l);
    });
  }, y = (l) => (...b) => {
    o(() => {
      l(...b);
    });
  }, g = () => {
    const l = e;
    e = [], l.length && n(() => {
      i(() => {
        l.forEach((b) => {
          s(b);
        });
      });
    });
  };
  return {
    batch: c,
    batchCalls: y,
    schedule: o,
    setNotifyFunction: (l) => {
      s = l;
    },
    setBatchNotifyFunction: (l) => {
      i = l;
    },
    setScheduler: h
  };
}
var Tt = Yt();
function Zt(e, t) {
  return {
    fetchFailureCount: 0,
    fetchFailureReason: null,
    fetchStatus: Xt(t.networkMode) ? "fetching" : "paused",
    ...e === void 0 && {
      error: null,
      status: "pending"
    }
  };
}
var O, f, W, m, x, z, R, G, _, H, T, U, L, V, k, K, J, lt, X, ft, Y, dt, Z, yt, $, pt, tt, bt, et, vt, nt, Ut, Pt, $t = (Pt = class extends mt {
  constructor(t, s) {
    super();
    u(this, k);
    u(this, J);
    u(this, X);
    u(this, Y);
    u(this, Z);
    u(this, $);
    u(this, tt);
    u(this, et);
    u(this, nt);
    u(this, O, void 0);
    u(this, f, void 0);
    u(this, W, void 0);
    u(this, m, void 0);
    u(this, x, void 0);
    u(this, z, void 0);
    u(this, R, void 0);
    u(this, G, void 0);
    u(this, _, void 0);
    // This property keeps track of the last query with defined data.
    // It will be used to pass the previous data and query to the placeholder function between renders.
    u(this, H, void 0);
    u(this, T, void 0);
    u(this, U, void 0);
    u(this, L, void 0);
    u(this, V, /* @__PURE__ */ new Set());
    this.options = s, a(this, O, t), a(this, R, null), this.bindMethods(), this.setOptions(s);
  }
  bindMethods() {
    this.refetch = this.refetch.bind(this);
  }
  onSubscribe() {
    this.listeners.size === 1 && (r(this, f).addObserver(this), Ft(r(this, f), this.options) ? v(this, k, K).call(this) : this.updateResult(), v(this, Z, yt).call(this));
  }
  onUnsubscribe() {
    this.hasListeners() || this.destroy();
  }
  shouldFetchOnReconnect() {
    return gt(
      r(this, f),
      this.options,
      this.options.refetchOnReconnect
    );
  }
  shouldFetchOnWindowFocus() {
    return gt(
      r(this, f),
      this.options,
      this.options.refetchOnWindowFocus
    );
  }
  destroy() {
    this.listeners = /* @__PURE__ */ new Set(), v(this, $, pt).call(this), v(this, tt, bt).call(this), r(this, f).removeObserver(this);
  }
  setOptions(t, s) {
    const i = this.options, n = r(this, f);
    if (this.options = r(this, O).defaultQueryOptions(t), this.options.enabled !== void 0 && typeof this.options.enabled != "boolean")
      throw new Error("Expected enabled to be a boolean");
    v(this, et, vt).call(this), r(this, f).setOptions(this.options), i._defaulted && !ht(this.options, i) && r(this, O).getQueryCache().notify({
      type: "observerOptionsUpdated",
      query: r(this, f),
      observer: this
    });
    const h = this.hasListeners();
    h && Dt(
      r(this, f),
      n,
      this.options,
      i
    ) && v(this, k, K).call(this), this.updateResult(s), h && (r(this, f) !== n || this.options.enabled !== i.enabled || this.options.staleTime !== i.staleTime) && v(this, J, lt).call(this);
    const c = v(this, X, ft).call(this);
    h && (r(this, f) !== n || this.options.enabled !== i.enabled || c !== r(this, L)) && v(this, Y, dt).call(this, c);
  }
  getOptimisticResult(t) {
    const s = r(this, O).getQueryCache().build(r(this, O), t), i = this.createResult(s, t);
    return ee(this, i) && (a(this, m, i), a(this, z, this.options), a(this, x, r(this, f).state)), i;
  }
  getCurrentResult() {
    return r(this, m);
  }
  trackResult(t, s) {
    const i = {};
    return Object.keys(t).forEach((n) => {
      Object.defineProperty(i, n, {
        configurable: !1,
        enumerable: !0,
        get: () => (this.trackProp(n), s == null || s(n), t[n])
      });
    }), i;
  }
  trackProp(t) {
    r(this, V).add(t);
  }
  getCurrentQuery() {
    return r(this, f);
  }
  refetch({ ...t } = {}) {
    return this.fetch({
      ...t
    });
  }
  fetchOptimistic(t) {
    const s = r(this, O).defaultQueryOptions(t), i = r(this, O).getQueryCache().build(r(this, O), s);
    return i.isFetchingOptimistic = !0, i.fetch().then(() => this.createResult(i, s));
  }
  fetch(t) {
    return v(this, k, K).call(this, {
      ...t,
      cancelRefetch: t.cancelRefetch ?? !0
    }).then(() => (this.updateResult(), r(this, m)));
  }
  createResult(t, s) {
    var I;
    const i = r(this, f), n = this.options, h = r(this, m), c = r(this, x), o = r(this, z), g = t !== i ? t.state : r(this, W), { state: p } = t;
    let d = { ...p }, l = !1, b;
    if (s._optimisticResults) {
      const w = this.hasListeners(), at = !w && Ft(t, s), Mt = w && Dt(t, i, s, n);
      (at || Mt) && (d = {
        ...d,
        ...Zt(p.data, t.options)
      }), s._optimisticResults === "isRestoring" && (d.fetchStatus = "idle");
    }
    let { error: M, errorUpdatedAt: st, status: C } = d;
    if (s.select && d.data !== void 0)
      if (h && d.data === (c == null ? void 0 : c.data) && s.select === r(this, G))
        b = r(this, _);
      else
        try {
          a(this, G, s.select), b = s.select(d.data), b = St(h == null ? void 0 : h.data, b, s), a(this, _, b), a(this, R, null);
        } catch (w) {
          a(this, R, w);
        }
    else
      b = d.data;
    if (s.placeholderData !== void 0 && b === void 0 && C === "pending") {
      let w;
      if (h != null && h.isPlaceholderData && s.placeholderData === (o == null ? void 0 : o.placeholderData))
        w = h.data;
      else if (w = typeof s.placeholderData == "function" ? s.placeholderData(
        (I = r(this, H)) == null ? void 0 : I.state.data,
        r(this, H)
      ) : s.placeholderData, s.select && w !== void 0)
        try {
          w = s.select(w), a(this, R, null);
        } catch (at) {
          a(this, R, at);
        }
      w !== void 0 && (C = "success", b = St(
        h == null ? void 0 : h.data,
        w,
        s
      ), l = !0);
    }
    r(this, R) && (M = r(this, R), b = r(this, _), st = Date.now(), C = "error");
    const F = d.fetchStatus === "fetching", q = C === "pending", j = C === "error", S = q && F, rt = b !== void 0;
    return {
      status: C,
      fetchStatus: d.fetchStatus,
      isPending: q,
      isSuccess: C === "success",
      isError: j,
      isInitialLoading: S,
      isLoading: S,
      data: b,
      dataUpdatedAt: d.dataUpdatedAt,
      error: M,
      errorUpdatedAt: st,
      failureCount: d.fetchFailureCount,
      failureReason: d.fetchFailureReason,
      errorUpdateCount: d.errorUpdateCount,
      isFetched: d.dataUpdateCount > 0 || d.errorUpdateCount > 0,
      isFetchedAfterMount: d.dataUpdateCount > g.dataUpdateCount || d.errorUpdateCount > g.errorUpdateCount,
      isFetching: F,
      isRefetching: F && !q,
      isLoadingError: j && !rt,
      isPaused: d.fetchStatus === "paused",
      isPlaceholderData: l,
      isRefetchError: j && rt,
      isStale: Ot(t, s),
      refetch: this.refetch
    };
  }
  updateResult(t) {
    const s = r(this, m), i = this.createResult(r(this, f), this.options);
    if (a(this, x, r(this, f).state), a(this, z, this.options), r(this, x).data !== void 0 && a(this, H, r(this, f)), ht(i, s))
      return;
    a(this, m, i);
    const n = {}, h = () => {
      if (!s)
        return !0;
      const { notifyOnChangeProps: c } = this.options, o = typeof c == "function" ? c() : c;
      if (o === "all" || !o && !r(this, V).size)
        return !0;
      const y = new Set(
        o ?? r(this, V)
      );
      return this.options.throwOnError && y.add("error"), Object.keys(r(this, m)).some((g) => {
        const p = g;
        return r(this, m)[p] !== s[p] && y.has(p);
      });
    };
    (t == null ? void 0 : t.listeners) !== !1 && h() && (n.listeners = !0), v(this, nt, Ut).call(this, { ...n, ...t });
  }
  onQueryUpdate() {
    this.updateResult(), this.hasListeners() && v(this, Z, yt).call(this);
  }
}, O = new WeakMap(), f = new WeakMap(), W = new WeakMap(), m = new WeakMap(), x = new WeakMap(), z = new WeakMap(), R = new WeakMap(), G = new WeakMap(), _ = new WeakMap(), H = new WeakMap(), T = new WeakMap(), U = new WeakMap(), L = new WeakMap(), V = new WeakMap(), k = new WeakSet(), K = function(t) {
  v(this, et, vt).call(this);
  let s = r(this, f).fetch(
    this.options,
    t
  );
  return t != null && t.throwOnError || (s = s.catch(Vt)), s;
}, J = new WeakSet(), lt = function() {
  if (v(this, $, pt).call(this), it || r(this, m).isStale || !wt(this.options.staleTime))
    return;
  const s = qt(
    r(this, m).dataUpdatedAt,
    this.options.staleTime
  ) + 1;
  a(this, T, setTimeout(() => {
    r(this, m).isStale || this.updateResult();
  }, s));
}, X = new WeakSet(), ft = function() {
  return (typeof this.options.refetchInterval == "function" ? this.options.refetchInterval(r(this, f)) : this.options.refetchInterval) ?? !1;
}, Y = new WeakSet(), dt = function(t) {
  v(this, tt, bt).call(this), a(this, L, t), !(it || this.options.enabled === !1 || !wt(r(this, L)) || r(this, L) === 0) && a(this, U, setInterval(() => {
    (this.options.refetchIntervalInBackground || Wt.isFocused()) && v(this, k, K).call(this);
  }, r(this, L)));
}, Z = new WeakSet(), yt = function() {
  v(this, J, lt).call(this), v(this, Y, dt).call(this, v(this, X, ft).call(this));
}, $ = new WeakSet(), pt = function() {
  r(this, T) && (clearTimeout(r(this, T)), a(this, T, void 0));
}, tt = new WeakSet(), bt = function() {
  r(this, U) && (clearInterval(r(this, U)), a(this, U, void 0));
}, et = new WeakSet(), vt = function() {
  const t = r(this, O).getQueryCache().build(r(this, O), this.options);
  if (t === r(this, f))
    return;
  const s = r(this, f);
  a(this, f, t), a(this, W, t.state), this.hasListeners() && (s == null || s.removeObserver(this), t.addObserver(this));
}, nt = new WeakSet(), Ut = function(t) {
  Tt.batch(() => {
    t.listeners && this.listeners.forEach((s) => {
      s(r(this, m));
    }), r(this, O).getQueryCache().notify({
      query: r(this, f),
      type: "observerResultsUpdated"
    });
  });
}, Pt);
function te(e, t) {
  return t.enabled !== !1 && e.state.data === void 0 && !(e.state.status === "error" && t.retryOnMount === !1);
}
function Ft(e, t) {
  return te(e, t) || e.state.data !== void 0 && gt(e, t, t.refetchOnMount);
}
function gt(e, t, s) {
  if (t.enabled !== !1) {
    const i = typeof s == "function" ? s(e) : s;
    return i === "always" || i !== !1 && Ot(e, t);
  }
  return !1;
}
function Dt(e, t, s, i) {
  return (e !== t || i.enabled === !1) && (!s.suspense || e.state.status !== "error") && Ot(e, s);
}
function Ot(e, t) {
  return t.enabled !== !1 && e.isStaleByTime(t.staleTime);
}
function ee(e, t) {
  return !ht(e.getCurrentResult(), t);
}
var se = E.createContext(
  void 0
), re = (e) => {
  const t = E.useContext(se);
  if (e)
    return e;
  if (!t)
    throw new Error("No QueryClient set, use QueryClientProvider to set one");
  return t;
}, kt = E.createContext(!1), ie = () => E.useContext(kt);
kt.Provider;
function ne() {
  let e = !1;
  return {
    clearReset: () => {
      e = !1;
    },
    reset: () => {
      e = !0;
    },
    isReset: () => e
  };
}
var oe = E.createContext(ne()), ae = () => E.useContext(oe);
function ue(e, t) {
  return typeof e == "function" ? e(...t) : !!e;
}
var ce = (e, t) => {
  (e.suspense || e.throwOnError) && (t.isReset() || (e.retryOnMount = !1));
}, he = (e) => {
  E.useEffect(() => {
    e.clearReset();
  }, [e]);
}, le = ({
  result: e,
  errorResetBoundary: t,
  throwOnError: s,
  query: i
}) => e.isError && !t.isReset() && !e.isFetching && i && ue(s, [e.error, i]), fe = (e) => {
  e.suspense && typeof e.staleTime != "number" && (e.staleTime = 1e3);
}, de = (e, t) => (e == null ? void 0 : e.suspense) && t.isPending, ye = (e, t, s) => t.fetchOptimistic(e).catch(() => {
  s.clearReset();
});
function pe(e, t, s) {
  if (process.env.NODE_ENV !== "production" && (typeof e != "object" || Array.isArray(e)))
    throw new Error(
      'Bad argument type. Starting with v5, only the "Object" form is allowed when calling query related functions. Please use the error stack to find the culprit call. More info here: https://tanstack.com/query/latest/docs/react/guides/migrating-to-v5#supports-a-single-signature-one-object'
    );
  const i = re(s), n = ie(), h = ae(), c = i.defaultQueryOptions(e);
  c._optimisticResults = n ? "isRestoring" : "optimistic", fe(c), ce(c, h), he(h);
  const [o] = E.useState(
    () => new t(
      i,
      c
    )
  ), y = o.getOptimisticResult(c);
  if (E.useSyncExternalStore(
    E.useCallback(
      (g) => {
        const p = n ? () => {
        } : o.subscribe(Tt.batchCalls(g));
        return o.updateResult(), p;
      },
      [o, n]
    ),
    () => o.getCurrentResult(),
    () => o.getCurrentResult()
  ), E.useEffect(() => {
    o.setOptions(c, { listeners: !1 });
  }, [c, o]), de(c, y))
    throw ye(c, o, h);
  if (le({
    result: y,
    errorResetBoundary: h,
    throwOnError: c.throwOnError,
    query: i.getQueryCache().get(c.queryHash)
  }))
    throw y.error;
  return c.notifyOnChangeProps ? y : o.trackResult(y);
}
function Qt(e, t) {
  return pe(e, $t, t);
}
const be = {
  staleTime: 1 / 0
}, ve = async (e, t) => {
  var i;
  const s = await Promise.all(
    e.map((n) => At.initializeFromUrl(n))
  );
  if (s.some((n) => n.error))
    throw new Error((i = s.find((n) => n.error)) == null ? void 0 : i.error);
  return t.onSuccess && await t.onSuccess(Bt), {
    success: !0
  };
}, Fe = ({
  queryClient: e,
  options: t
}) => {
  const s = {
    ...be,
    ...t
  }, i = (o, y) => {
    const g = Array.isArray(o) ? o : [o], p = g.every((S) => !!S.url), { enabled: d, queryKey: l, throwOnError: b } = y, { status: M } = Nt(), { get: st } = zt(), C = g.every(
      (S) => !st(S.databaseName)
    ), { data: F, isLoading: q } = Qt(
      {
        queryKey: [l],
        queryFn: () => ve(g, y),
        enabled: (d || d === void 0) && p && M === ct.COMPLETED && C,
        staleTime: 1 / 0,
        gcTime: 1 / 0,
        throwOnError: b
      },
      e
    );
    function j({
      enabled: S = !0,
      ...rt
    }) {
      const ot = !!(F != null && F.success) && M === ct.COMPLETED && S && !C, I = Qt(
        {
          ...rt,
          ...s,
          enabled: S && ot
        },
        e
      );
      return {
        ...I,
        isLoading: q || (ot ? I == null ? void 0 : I.isLoading : !0)
      };
    }
    return !F || C || M !== ct.COMPLETED ? {
      isLoading: !0,
      success: !1,
      error: void 0,
      useQueryHook: j
    } : {
      isLoading: !1,
      success: !0,
      error: void 0,
      useQueryHook: j
    };
  }, n = _t(() => {
  });
  return {
    useDBConnection: i,
    Context: n,
    Provider: ({
      useQueryHook: o,
      children: y
    }) => /* @__PURE__ */ jt(n.Provider, { value: o, children: y }),
    useQuery: (o) => {
      const y = Ht(n);
      if (!y)
        throw new Error(
          "useDuckDBQuery Context must be used within a QueryContext Provider"
        );
      return y(o);
    }
  };
};
export {
  Fe as makeDuckDbQueryContext
};
