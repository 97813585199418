import { EChartsOption, LegendClickEvent } from '@/components/ECharts/useChart';
import { useChartsContext } from '@/context/chartsThemeContext';
import EChartsReact from 'echarts-for-react';
import { EChartsReactProps, Opts } from 'echarts-for-react/lib/types';
import { ForwardedRef, forwardRef, memo, useMemo, useRef } from 'react';

type BaseChartProps = Omit<EChartsReactProps, 'option'> & {
    $shouldExpand?: boolean;
    className?: string;
    'data-testid'?: string;
};

export const BaseChart = memo(
    forwardRef(
        (
            {
                onLegendChange,
                ...props
            }: BaseChartProps & {
                onLegendChange?: (params: LegendClickEvent) => void;
                option: EChartsOption;
            },
            ref: ForwardedRef<EChartsReact | null>,
        ) => {
            const chartRef = useRef<EChartsReact | null>(null);
            const { theme } = useChartsContext();

            const opts = useMemo<Opts>(() => ({ renderer: 'svg' }), []);

            return (
                <EChartsReact
                    theme={theme}
                    notMerge
                    data-testid={props['data-testid']}
                    className={props.className}
                    style={
                        props.$shouldExpand
                            ? {
                                  minHeight: 'inherit',
                                  height: '100%',
                                  width: '100%',
                              }
                            : {
                                  minHeight: 'inherit',
                                  // height defaults to 300px
                                  height: '300px',
                                  width: '100%',
                              }
                    }
                    ref={(a) => {
                        chartRef.current = a;
                        if (typeof ref === 'function') {
                            ref(a);
                        } else if (ref) {
                            ref.current = a;
                        }
                    }}
                    opts={opts}
                    {...props}
                />
            );
        },
    ),
);
