import { Label } from 'flowbite-react';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
} from 'react';
import { HiOutlineChevronDown, HiOutlineChevronRight } from 'react-icons/hi';
import { twMerge } from 'tailwind-merge';

import {
    TreeOptionsRef,
    TreeSelectProps,
} from '@/components/TreeSelect/TreeSelect';
import { twCn } from '@/utils';
import { Checkbox } from '@analytical-alley/ui';

import { useTreeSelect } from './useTreeSelect';
import { TreeNode, TreeNodeLike } from './useTreeSelect/types';

const searchTree = (nodes: TreeNode[], query?: string): TreeNode[] => {
    if (!query) {
        return nodes;
    }
    return nodes.reduce((acc, node) => {
        if (node.label.toLowerCase().includes(query.toLowerCase())) {
            return [
                ...acc,
                {
                    ...node,
                    children: node.children
                        ? searchTree(node.children, query)
                        : undefined,
                },
            ];
        } else if (node.children) {
            const children = searchTree(node.children, query);
            if (children.length > 0) {
                return [
                    ...acc,
                    {
                        ...node,
                        children,
                    },
                ];
            }
        }
        return acc;
    }, [] as TreeNode[]);
};

export const TreeOptions = forwardRef(
    (
        {
            dataNodes,
            searchQuery,
            className,
            onChange,
        }: {
            dataNodes: TreeNodeLike[] | undefined;
            searchQuery?: string;
            className?: string;
            onChange: TreeSelectProps['onCheckedChange'];
        },
        ref: React.Ref<TreeOptionsRef>,
    ) => {
        const {
            state,
            nodes,
            getCheckboxProps,
            getExpandButtonProps,
            isExpanded,
            isIndeterminate,
            setChecked,
            setNodes,
            simplifiedSelection,
            selectAll,
            selectNone,
        } = useTreeSelect(dataNodes);

        const allChecked = Object.values(state.checked).every((v) => v);

        const toggleCheckAll = () => {
            if (allChecked) {
                selectNone();
            } else {
                selectAll();
            }
        };

        useEffect(() => {
            onChange(simplifiedSelection, state.checked);
        }, [simplifiedSelection, onChange, state.checked]);

        useImperativeHandle(
            ref,
            () => ({
                hasNodes: nodes.length > 0,
                setChecked,
                setNodes,
            }),
            [nodes.length, setChecked, setNodes],
        );

        const TreeSelectNode = ({
            isParent,
            node,
            className,
        }: {
            isParent?: boolean;
            node: TreeNode;
            className?: string;
        }) => {
            const ref = React.useRef<HTMLInputElement>(null);

            useLayoutEffect(() => {
                if (ref.current && (node.children?.length || 0) > 0) {
                    ref.current.indeterminate = isIndeterminate(node.id);
                }
            }, [isParent, node.children, node.id]);

            return (
                <li className={twMerge('mt-2 ml-1', className)}>
                    <div className="flex items-center">
                        <Checkbox
                            ref={ref}
                            id={node.id}
                            {...getCheckboxProps(node.id)}
                        />
                        <Label
                            className="text-dark dark:text-white font-sans ml-2 mr-1"
                            htmlFor={node.id}
                        >
                            {node.label}
                        </Label>
                        {node.children?.length ? (
                            <button
                                {...getExpandButtonProps(node.id)}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                }}
                                type="button"
                                disabled={!!searchQuery}
                            >
                                {isExpanded(node.id) ? (
                                    <HiOutlineChevronDown />
                                ) : (
                                    <HiOutlineChevronRight />
                                )}
                            </button>
                        ) : null}
                    </div>
                    {node.children &&
                        (!!searchQuery || isExpanded(node.id)) && (
                            <ul>
                                {node.children.map((node) => {
                                    return (
                                        <TreeSelectNode
                                            className="ml-4"
                                            key={node.id}
                                            node={node}
                                        />
                                    );
                                })}
                            </ul>
                        )}
                </li>
            );
        };

        const filteredNodes = searchTree(nodes, searchQuery);

        return (
            <ul className={twCn(className, 'mb-2')}>
                <li className="flex items-center mt-2 ml-1 border-b border-white border-opacity-20 pb-2">
                    <Checkbox
                        id="select-all"
                        checked={allChecked}
                        onChange={toggleCheckAll}
                    />
                    <Label
                        className="text-dark dark:text-white font-sans ml-2"
                        htmlFor="select-all"
                    >
                        All
                    </Label>
                </li>
                {filteredNodes.map((node) => {
                    return <TreeSelectNode key={node.id} node={node} />;
                })}
            </ul>
        );
    },
);

TreeOptions.displayName = 'TreeOptions';
