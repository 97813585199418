import { useMemo } from 'react';

export type UseSplitDataConfig = {
    min: number;
    mid: number;
    max: number;
    startsWithProfit: boolean;
    investmentStep?: number;
};

function roundNumberToStep(number: number, step: number = 100) {
    return Math.round(number / step) * step;
}

function splitToSidesArray({
    min,
    mid,
    max,
    startsWithProfit,
    investmentStep,
}: UseSplitDataConfig) {
    const percentage = (mid - min) / (max - min);
    const leftSideData = Array.from({
        length: Math.round(percentage * 100) + 1,
    }).map((_, i) => {
        return {
            value: roundNumberToStep(
                Math.round(
                    min + (mid - min) * (i / Math.round(percentage * 100)),
                ),
                investmentStep,
            ),
            isProfitable: startsWithProfit,
        };
    });
    const rightSideData = Array.from({
        length: 100 - leftSideData.length - 1,
    }).map((_, i) => {
        return {
            value: roundNumberToStep(
                Math.round(
                    mid + (max - mid) * ((i - 1) / (100 - leftSideData.length)),
                ),
                investmentStep,
            ),
            isProfitable: !startsWithProfit,
        };
    });

    rightSideData.push({
        value: max,
        isProfitable: !startsWithProfit,
    });

    return { leftSideData, rightSideData };
}

type UseSplitReturn = {
    valueIndex: number;
    isOverFlowing: boolean;
    rightSideData: { value: number; isProfitable: boolean }[];
    leftSideData: { value: number; isProfitable: boolean }[];
    splitIndex: number;
};

export function useSplitData(
    value: number,
    config: UseSplitDataConfig,
): UseSplitReturn {
    const { leftSideData, rightSideData, splitPercentageIndex, maxValue } =
        useMemo(() => {
            const { leftSideData, rightSideData } = splitToSidesArray(config);

            if (config.mid === config.max) {
                return {
                    leftSideData: leftSideData,
                    rightSideData: [],
                    splitPercentageIndex: leftSideData.length,
                    maxValue: config.max,
                };
            }

            if (config.min === config.mid) {
                return {
                    leftSideData: [],
                    rightSideData: rightSideData,
                    splitPercentageIndex: 0,
                    maxValue: config.max,
                };
            }

            return {
                leftSideData: leftSideData,
                rightSideData: rightSideData,
                splitPercentageIndex: leftSideData.length,
                maxValue: rightSideData[rightSideData.length - 1]!.value,
            };
        }, [config]);

    const valueIndex = useMemo(() => {
        if (leftSideData.length === 0 && rightSideData.length === 0) {
            return -1;
        }

        const leftSideLastItem = leftSideData[leftSideData.length - 1] || {
            value: -Infinity,
            isProfitable: !config.startsWithProfit,
        };

        if (value <= (leftSideData[0]?.value || 0)) {
            return 0;
        }

        if (
            leftSideLastItem &&
            value > leftSideLastItem.value &&
            rightSideData.length > 0
        ) {
            const closest = rightSideData.reduce(
                (acc, dataItem, currentIndex) => {
                    return dataItem.value > value && acc.value < value
                        ? { value, index: currentIndex - 1 }
                        : (acc as { value: number; index: number });
                },
                { value: 0, index: rightSideData.length - 1 },
            );
            return closest.index >= 0
                ? closest.index + leftSideData.length
                : Infinity;
        }

        const closest = leftSideData.reduce(
            (acc, dataItem, currentIndex) => {
                return dataItem.value > value && acc.value < value
                    ? { value, index: currentIndex }
                    : (acc as { value: number; index: number });
            },
            { value: 0, index: leftSideData.length - 1 },
        );

        return closest.index;
    }, [config.startsWithProfit, leftSideData, rightSideData, value]);

    const isOverFlowing = value > maxValue;

    return {
        valueIndex,
        isOverFlowing,
        leftSideData,
        rightSideData,
        splitIndex: splitPercentageIndex,
    };
}
