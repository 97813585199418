import { useBudgetOptimizationRoute } from '@/dashboards/BudgetOptimization/providers';
import { useRouteUrl } from '@/hooks';

export const useBudgetOptimizationUrl = () => {
    const { route } = useBudgetOptimizationRoute();

    return useRouteUrl(route);
};

export type UseBudgetOptimizationUrlReturn = ReturnType<
    typeof useBudgetOptimizationUrl
>;
