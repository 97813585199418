export const PERIOD_OPTIONS = [
    'daily',
    'weekly',
    'monthly',
    'quarterly',
    'yearly',
] as const;

export type PeriodOption = (typeof PERIOD_OPTIONS)[number];

export const DEFAULT_PERIOD: PeriodOption = 'monthly';
