export const isSafari = (): boolean =>
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const jsons2arrays = (
    jsons: Record<string, unknown>[],
    headers: { label: string; key: string }[],
) => {
    const headerLabels: string[] = [];
    const headerKeys: string[] = [];

    headers.forEach((header) => {
        headerLabels.push(header.label);
        headerKeys.push(header.key);
    });

    const data = jsons.map((object) =>
        headerKeys.map((header) => getHeaderValue(header, object)),
    );
    return [headerLabels, ...data];
};

export const getHeaderValue = (
    property: string,
    obj: Record<string, unknown>,
) => {
    const foundValue = property
        .replace(/\[([^\]]+)]/g, '.$1')
        .split('.')
        .reduce((o, p, i, arr) => {
            const value = o[p];
            if (value === undefined || value === null) {
                arr.splice(1);
            } else {
                return value;
            }
        }, obj as any);
    return foundValue === undefined
        ? property in obj
            ? obj[property]
            : ''
        : foundValue;
};

export const joiner = (
    data: string[][],
    separator = ',',
    enclosingCharacter = '"',
): string => {
    return data
        .filter((e) => e)
        .map((row) =>
            row
                .map(
                    (column) =>
                        `${enclosingCharacter}${column == null ? '' : column}${enclosingCharacter}`,
                )
                .join(separator),
        )
        .join(`\n`);
};

export const toCSV = (
    data: Record<string, unknown>[],
    headers: { label: string; key: string }[],
    separator?: string,
    enclosingCharacter?: string,
): string => joiner(jsons2arrays(data, headers), separator, enclosingCharacter);

export const buildCSVURI = (
    data: Record<string, unknown>[],
    headers: { label: string; key: string }[],
    options: {
        separator?: string;
        enclosingCharacter?: string;
    } = { enclosingCharacter: '"', separator: ',' },
): string => {
    const csv = toCSV(
        data,
        headers,
        options.separator,
        options.enclosingCharacter,
    );
    const type = isSafari() ? 'application/csv' : 'text/csv';

    // Prefix with BOM for Excel compatibility
    const blob = new Blob(['\uFEFF', csv], { type });
    const dataURI = `data:${type};charset=utf-8,\uFEFF${csv}`;

    const URL = window.URL || window.webkitURL;

    return typeof URL.createObjectURL === 'undefined'
        ? dataURI
        : URL.createObjectURL(blob);
};

export const downloadCSV = (
    data: {
        headers: { key: string; label: string }[];
        rows: Record<string, unknown>[];
    },
    fileName: string,
) => {
    const uri = buildCSVURI(data.rows, data.headers);

    const link = document.createElement('a');
    link.href = uri;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
