import {
    TablePartLocation,
    useSyncRowHeights,
    useTableExport,
    type DownloadCsvHandle,
} from '@/components/Table/hooks';
import { getCustomRowModel } from '@/components/Table/utils';
import { cn, twCn } from '@/utils';
import {
    ColumnDef,
    RowData,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useImperativeHandle, useState } from 'react';

declare module '@tanstack/react-table' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface ColumnMeta<TData extends RowData, TValue> {
        footer?: {
            className?: string;
        };
        cell?: {
            className?: string;
        };
        header?: {
            className?: string;
        };
    }

    interface TableMeta<TData extends RowData> {
        setRowIndex: (rowIndex: string) => void;
        onRowClick?: (row: TData) => void;
        isActiveRow?: (row: TData) => boolean;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface CoreRow<TData extends RowData> {
        rowSpan?: number;
        isRowSpanned?: boolean;
    }
}

interface ColSpanProps {
    header?: {
        index: number;
        value: number;
    };
    footer?: {
        index: number;
        value: number;
    };
}

interface TableProps<T extends RowData> {
    data: T[];
    columns: ColumnDef<T, any>[];
    isLoading?: boolean;
    colSpan?: ColSpanProps;
}

export type TableRef = {
    getTableCsv: () => { headers: any; rows: any };
    downloadCsv: DownloadCsvHandle;
};

export type OnRowExport<T> = (row: T) => { [key in keyof T]: number | string };

export const Table = <T extends RowData>({
    data,
    columns,
    shouldSpanRow,
    className,
    onRowClick,
    isActiveRow,
    isLoading,
    colSpan,
    tableRef,
    onRowExport,
    wrapperClassName,
    footerClassName,
    pinnedLeftCount,
    pinnedRightCount,
}: TableProps<T> & {
    shouldSpanRow?: boolean;
    className?: string;
    wrapperClassName?: string;
    footerClassName?: string;
    isActiveRow?: (row: T) => boolean;
    onRowClick?: (row: T) => void;
    colSpan?: ColSpanProps;
    tableRef?: React.Ref<TableRef>;
    onRowExport?: OnRowExport<T>;
    pinnedLeftCount?: number;
    pinnedRightCount?: number;
}) => {
    const [activeIndex, setActiveIndex] = useState('');

    const isPinned = pinnedLeftCount || pinnedRightCount;

    const { containerRef, setRowRefs, calculateAndSetRowHeights } =
        useSyncRowHeights({
            disabled: !isPinned,
        });

    const table = useReactTable<T>({
        autoResetAll: true,
        data,
        columns,
        getCoreRowModel: shouldSpanRow
            ? getCustomRowModel()
            : getCoreRowModel(),
        meta: {
            setRowIndex: (rowId: string) => {
                setActiveIndex(rowId);
            },
            isActiveRow,
            onRowClick,
        },
    });

    const { downloadCsv, getExportData } = useTableExport({
        table,
        onRowExport,
    });

    useImperativeHandle(
        tableRef,
        () => ({
            getTableCsv: getExportData,
            downloadCsv,
        }),
        [downloadCsv, getExportData],
    );

    useEffect(() => {
        if (isPinned) {
            // Calculate row heights again on props change
            calculateAndSetRowHeights();
        }
    });

    function shouldRenderPinned(
        location: TablePartLocation,
        index: number,
        totalCount: number,
    ) {
        if (!isPinned || !location) {
            return true;
        }

        if (pinnedLeftCount && location === 'left') {
            return index < pinnedLeftCount;
        }

        if (pinnedRightCount && location === 'right') {
            return index >= totalCount - pinnedRightCount;
        }

        if (pinnedLeftCount && pinnedRightCount && location === 'center') {
            return (
                index >= pinnedLeftCount &&
                index < totalCount - pinnedRightCount
            );
        }
        return true;
    }

    const TableComponent = ({ location }: { location?: TablePartLocation }) => {
        return (
            <div
                className={cn(wrapperClassName, {
                    'overflow-x-auto relative w-full':
                        !isPinned || location === 'center',
                })}
            >
                <table
                    className={cn(
                        'w-full text-left rounded-b-md mb-1',
                        className,
                        {
                            'border-l border-l-divider': location === 'right',
                            'border-r border-r-divider': location === 'left',
                        },
                    )}
                >
                    <thead className="text-sm font-sans">
                        {table.getHeaderGroups().map((headerGroup, index) => (
                            <tr
                                ref={(el) => {
                                    if (index === 0)
                                        setRowRefs?.(location, index, el);
                                }}
                                key={headerGroup.id}
                            >
                                {headerGroup.headers.map((header, index) => {
                                    if (
                                        !shouldRenderPinned(
                                            location,
                                            index,
                                            headerGroup.headers.length,
                                        )
                                    ) {
                                        return null;
                                    }

                                    if (
                                        colSpan?.header &&
                                        index === colSpan.header.index + 1
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <th
                                            colSpan={
                                                colSpan?.header &&
                                                index === colSpan?.header.index
                                                    ? colSpan?.header?.value
                                                    : undefined
                                            }
                                            key={header.id}
                                            className={cn(
                                                `px-3.5 py-2 border-divider`,
                                                {
                                                    'border-x':
                                                        index !== 0 &&
                                                        index !==
                                                            headerGroup.headers
                                                                .length -
                                                                1,
                                                },
                                                header.column.columnDef.meta
                                                    ?.header?.className,
                                            )}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody className="text-sm font-mono">
                        {table.getRowModel().rows.map((row, index) => {
                            const isRowActive = isActiveRow?.(row.original);
                            return (
                                <tr
                                    ref={(el) => {
                                        setRowRefs?.(location, index + 1, el);
                                    }}
                                    key={row.id}
                                    className={cn(
                                        'group',
                                        row.id === activeIndex
                                            ? 'bg-[#FBFEFF4C]'
                                            : undefined,
                                    )}
                                >
                                    {row
                                        .getVisibleCells()
                                        .map((cell, index) => {
                                            if (
                                                !shouldRenderPinned(
                                                    location,
                                                    index,
                                                    row.getVisibleCells()
                                                        .length,
                                                )
                                            ) {
                                                return null;
                                            }

                                            if (row.isRowSpanned && index === 0)
                                                return null;

                                            return (
                                                <td
                                                    onClick={
                                                        index !== 0
                                                            ? () => {
                                                                  onRowClick?.(
                                                                      row.original,
                                                                  );
                                                              }
                                                            : undefined
                                                    }
                                                    key={cell.id}
                                                    rowSpan={
                                                        index === 0 &&
                                                        row.rowSpan
                                                            ? row.rowSpan
                                                            : undefined
                                                    }
                                                    className={cn(
                                                        `border-t border-divider`,
                                                        cell.column.columnDef
                                                            .meta?.cell
                                                            ?.className,
                                                        {
                                                            'cursor-pointer':
                                                                index !== 0 &&
                                                                onRowClick,
                                                            'bg-[#F7FCFF26]':
                                                                index !== 0 &&
                                                                isRowActive,
                                                            'border-x':
                                                                index !== 0 &&
                                                                index !==
                                                                    row.getVisibleCells()
                                                                        .length -
                                                                        1,
                                                        },
                                                    )}
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext(),
                                                    )}
                                                </td>
                                            );
                                        })}
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot className={cn('text-sm font-mono', footerClassName)}>
                        {table.getFooterGroups().map((footerGroup) => (
                            <tr
                                ref={(el) => {
                                    setRowRefs?.(
                                        location,
                                        table.getRowModel().rows.length + 2,
                                        el,
                                    );
                                }}
                                key={footerGroup.id}
                            >
                                {footerGroup.headers.map((header, index) => {
                                    if (
                                        !shouldRenderPinned(
                                            location,
                                            index,
                                            footerGroup.headers.length,
                                        )
                                    ) {
                                        return null;
                                    }

                                    if (
                                        colSpan?.footer &&
                                        index === colSpan.footer.index + 1
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <th
                                            colSpan={
                                                colSpan?.footer &&
                                                index === colSpan?.footer.index
                                                    ? colSpan?.footer?.value
                                                    : undefined
                                            }
                                            key={header.id}
                                            className={twCn(
                                                'border-t border-divider bg-[#F7FCFF26] py-4 px-3',
                                                {
                                                    'rounded-bl-2xl':
                                                        index === 0,
                                                    'rounded-br-2xl':
                                                        index ===
                                                        footerGroup.headers
                                                            .length -
                                                            1,
                                                    'border-x':
                                                        index !== 0 &&
                                                        index !==
                                                            footerGroup.headers
                                                                .length -
                                                                1,
                                                },
                                                header.column.columnDef.meta
                                                    ?.footer?.className,
                                            )}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef
                                                          .footer,
                                                      header.getContext(),
                                                  )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </tfoot>
                </table>
            </div>
        );
    };

    if (isLoading) {
        return null;
    }

    /*
     * Use three tables and make it look like a single table, to be able to have nice looking fixed/pinned columns and scrollable middle part.
     * Single table solution is not possible because of the glassy/transparent designs as fixed/pinned, the scrollable part would be
     * visible through the fixed columns which looks bad.
     * */
    if (isPinned) {
        return (
            <div className="flex" ref={containerRef}>
                {pinnedLeftCount && <TableComponent location="left" />}
                <TableComponent location="center" />
                {pinnedRightCount && <TableComponent location="right" />}
            </div>
        );
    }

    return <TableComponent />;
};
