import { useDuckDBSetupContext as r, useDuckDBResolver as n } from "../providers/databaseProvider.js";
import { ResolvableStatus as s } from "../utils/resolvable.js";
import a from "react";
const u = (t) => {
  switch (t) {
    case s.NONE:
      return "none";
    case s.RUNNING:
      return "running";
    case s.FAILED:
      return "failed";
    case s.COMPLETED:
      return "completed";
  }
}, f = () => {
  const t = r(), e = n();
  return a.useEffect(() => {
    !(t != null && t.resolving()) && e && e();
  }, [t, e]), t == null ? {
    db: null,
    status: s.NONE,
    statusText: "none"
  } : t.status !== s.COMPLETED ? {
    db: null,
    status: t.status,
    statusText: u(t.status)
  } : {
    db: t.value,
    status: t.status,
    statusText: u(t.status)
  };
};
export {
  f as useDuckDB
};
