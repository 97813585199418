import React, {
    ComponentProps,
    Dispatch,
    FC,
    HTMLProps,
    MutableRefObject,
    ReactElement,
    ReactNode,
    Ref,
    SetStateAction,
    cloneElement,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    ExtendedRefs,
    FloatingFocusManager,
    FloatingList,
    useListNavigation,
    useTypeahead,
} from '@floating-ui/react';
import { Button, ButtonProps, FlowbiteButtonTheme } from 'flowbite-react';

import {
    HiOutlineChevronDown,
    HiOutlineChevronLeft,
    HiOutlineChevronRight,
    HiOutlineChevronUp,
} from 'react-icons/hi';
import { twMerge } from 'tailwind-merge';

import { selectTheme } from '@/components/Select/theme';
import { useBaseFloating, useFloatingInteractions } from '@/hooks/useFloating';
import type { DeepPartial } from '@/types';

import { createPortal } from 'react-dom';
import type { FloatingProps, FloatingTheme } from '../Floating';
import { SelectContext } from './SelectContext';
import { SelectDivider, type SelectDividerTheme } from './SelectDivider';
import { SelectOption, type SelectOptionTheme } from './SelectOption';

export interface SelectFloatingTheme extends FloatingTheme, SelectDividerTheme {
    item: SelectOptionTheme;
}

export interface SelectTheme {
    floating: SelectFloatingTheme;
    content: string;
    inlineWrapper: string;
    arrowIcon: string;
}

export interface SelectPropsBase
    extends Pick<FloatingProps, 'placement' | 'trigger'>,
        Omit<ButtonProps, 'theme' | 'ref'> {
    arrowIcon?: boolean;
    dismissOnClick?: boolean;
    floatingArrow?: boolean;
    inline?: boolean;
    label: ReactNode;
    renderTrigger?: (theme: SelectTheme) => ReactElement;
    'data-testid'?: string;
    compact?: boolean;
}

export type SelectProps<
    T extends string | number | null | undefined = undefined,
> = Omit<SelectPropsBase, 'children'> & {
    selectedValue?: T;
    onSelectChange?: (value: T) => void;
    children: ReactNode;
    triggerRef?: Ref<SelectRef>;
};

const icons: Record<string, FC<ComponentProps<'svg'>>> = {
    top: HiOutlineChevronUp,
    right: HiOutlineChevronRight,
    bottom: HiOutlineChevronDown,
    left: HiOutlineChevronLeft,
};

export interface TriggerProps extends Omit<ButtonProps, 'theme'> {
    refs: ExtendedRefs<HTMLElement>;
    inline?: boolean;
    theme: SelectTheme;
    setButtonWidth?: Dispatch<SetStateAction<number | undefined>>;
    getReferenceProps: (
        userProps?: HTMLProps<Element> | undefined,
    ) => Record<string, unknown>;
    renderTrigger?: (theme: SelectTheme) => ReactElement;
    buttonTheme?: DeepPartial<FlowbiteButtonTheme>;
}

const Trigger = ({
    refs,
    children,
    inline,
    theme,
    disabled,
    setButtonWidth,
    getReferenceProps,
    renderTrigger,
    buttonTheme,
    ...buttonProps
}: TriggerProps) => {
    const ref = refs.reference as MutableRefObject<HTMLElement>;
    const a11yProps = getReferenceProps();

    useEffect(() => {
        if (ref.current) {
            setButtonWidth?.(ref.current.clientWidth);
        }
    }, [ref, setButtonWidth]);

    if (renderTrigger) {
        const triggerElement = renderTrigger(theme);
        return cloneElement(triggerElement, {
            ref: refs.setReference,
            disabled,
            ...a11yProps,
            ...triggerElement.props,
        });
    }

    return (
        <Button
            color={'dark'}
            {...buttonProps}
            theme={buttonTheme}
            disabled={disabled}
            type="button"
            ref={refs.setReference}
            {...a11yProps}
        >
            {children}
        </Button>
    );
};

export type SelectRef = {
    click?: () => void;
};

export const SelectComponent = <
    T extends string | null | number | undefined = undefined,
>({
    triggerRef,
    children,
    className,
    dismissOnClick = true,
    renderTrigger,
    compact,
    selectedValue,
    onSelectChange,
    ...props
}: SelectProps<T>) => {
    const [open, setOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [buttonWidth, setButtonWidth] = useState<number | undefined>(
        undefined,
    );
    const elementsRef = useRef<Array<HTMLElement | null>>([]);
    const labelsRef = useRef<Array<string | null>>([]);

    const theme = selectTheme;
    const theirProps = props as Omit<SelectProps, 'theme'>;
    const dataTestId = props['data-testid'] || 'dropdown-target';
    const {
        placement = props.inline ? 'bottom-start' : 'bottom',
        trigger = 'click',
        label,
        inline,
        arrowIcon = true,
        ...buttonProps
    } = theirProps;

    const handleSelect = useCallback((index: number | null) => {
        setSelectedIndex(index);
        setOpen(false);
    }, []);

    const handleTypeaheadMatch = useCallback(
        (index: number | null) => {
            if (open) {
                setActiveIndex(index);
            } else {
                handleSelect(index);
            }
        },
        [open, handleSelect],
    );

    const { context, floatingStyles, refs } =
        useBaseFloating<HTMLButtonElement>({
            open,
            setOpen,
            placement,
        });

    useImperativeHandle(triggerRef, () => ({
        click: () => {
            refs.domReference.current?.click();
        },
    }));

    const listNav = useListNavigation(context, {
        listRef: elementsRef,
        activeIndex,
        selectedIndex,
        onNavigate: setActiveIndex,
    });

    const typeahead = useTypeahead(context, {
        listRef: labelsRef,
        activeIndex,
        selectedIndex,
        onMatch: handleTypeaheadMatch,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } =
        useFloatingInteractions({
            context,
            role: 'menu',
            trigger,
            interactions: [listNav, typeahead],
        });

    const Icon = useMemo(() => {
        const [p] = placement.split('-');
        return icons[p!] ?? HiOutlineChevronDown;
    }, [placement]);

    const selectedChild = useMemo(() => {
        if (selectedValue == null) {
            return null;
        }

        return React.Children.toArray(children).find(
            (child) => (child as ReactElement)?.props?.value === selectedValue,
        );
    }, [selectedValue, children]);

    return (
        <SelectContext.Provider
            value={{
                value: selectedValue,
                onSelectChange: onSelectChange as NonNullable<
                    SelectProps['onSelectChange']
                >,
                theme,
                activeIndex,
                dismissOnClick,
                getItemProps,
                handleSelect,
            }}
        >
            <Trigger
                {...buttonProps}
                refs={refs}
                inline={inline}
                theme={theme}
                data-testid={dataTestId}
                className={twMerge(className, compact ? 'h-9' : undefined)}
                setButtonWidth={setButtonWidth}
                getReferenceProps={getReferenceProps}
                renderTrigger={renderTrigger}
                buttonTheme={{
                    base: 'flex flex-col text-nowrap overflow-ellipsis',
                    color: {
                        dark: 'glass text-dark dark:text-white focus:ring-1 focus:ring-[#1C64F2] focus-visible:outline-none shadow-sm dark:shadow-sm-light',
                    },
                    size: {
                        md: 'text-sm pl-3 pr-3 py-3',
                    },
                    inner: {
                        base: 'h-full w-full flex items-center grow justify-between',
                    },
                }}
            >
                {React.isValidElement(selectedChild)
                    ? (selectedChild.props as unknown as { label: string })
                          ?.label
                    : label}
                {arrowIcon && <Icon className={theme.arrowIcon} />}
            </Trigger>

            <FloatingFocusManager context={context} modal={false}>
                {createPortal(
                    <div
                        ref={refs.setFloating}
                        style={{ ...floatingStyles, minWidth: buttonWidth }}
                        data-testid="dropdown"
                        aria-expanded={open}
                        {...getFloatingProps({
                            className: twMerge(
                                theme.floating.base,
                                theme.floating.animation,
                                'duration-100',
                                !open && theme.floating.hidden,
                                theme.floating.style.auto,
                            ),
                        })}
                    >
                        <FloatingList
                            elementsRef={elementsRef}
                            labelsRef={labelsRef}
                        >
                            <ul className={theme.content} tabIndex={-1}>
                                {children}
                            </ul>
                        </FloatingList>
                    </div>,
                    document.body,
                )}
            </FloatingFocusManager>
        </SelectContext.Provider>
    );
};
SelectComponent.displayName = 'Select';

export const Select = Object.assign(SelectComponent, {
    Divider: SelectDivider,
    Option: SelectOption,
});
