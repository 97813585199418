import {
    FC,
    Ref,
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from 'react';

import * as echarts from 'echarts';
import EChartsReact from 'echarts-for-react';
import { EChartsReactProps, Opts } from 'echarts-for-react/lib/types';

import { Typography } from '@analytical-alley/ui';

import { useChartsContext } from '@/context/chartsThemeContext';
import { twCn } from '@/utils';
import { getColorFromRange } from '@/utils/colorUtils';
import { jsxToHtml } from '@/utils/reactUtils';

import { ToggleLegend } from '@/components/ECharts';
import { NumberFormatter, useConfigContext } from '@/context/configContext';
import { GetModelVsActualData } from '@/dashboards/TitleDashboard/types';
import type { LegendComponentOption } from 'echarts';

type LegendOption = LegendComponentOption & { showCustom?: boolean };

type EChartsOption = echarts.EChartsOption & {
    legend: LegendOption;
};

type CustomTooltipProps = {
    seriesName: string;
    color: string;
    value: number;
    axisValueLabel: string;
}[];

const CustomTooltip = ({
    params,
    formatNumber,
}: {
    params: CustomTooltipProps;
    formatNumber: NumberFormatter;
}) => {
    return (
        <div className="tooltip-content max-w-[342px]">
            <Typography className="tooltip-header dark:text-dark text-white">
                {params[0]?.axisValueLabel}
            </Typography>
            <div className="flex gap-2 leading-4">
                <div className="flex flex-col space-y-2 text-neutral-800">
                    {params.map((param) => {
                        return (
                            <div key={param.seriesName} className="flex">
                                <span
                                    style={{
                                        backgroundColor: param.color,
                                    }}
                                    className="flex w-2.5 h-2.5 rounded-full me-1.5 flex-shrink-0 self-center"
                                />
                                {param.seriesName}:
                            </div>
                        );
                    })}
                </div>
                <div className="flex flex-col space-y-2 flex-1 font-medium text-violet-950">
                    {params.map((param) => {
                        return (
                            <div
                                key={`${param.seriesName}-${param.value}`}
                                className="flex"
                            >
                                {formatNumber(param.value)}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const getEChartsOptions = ({
    data,
    residual: residualConfig,
    formatNumber,
}: {
    formatNumber: NumberFormatter;
    data: GetModelVsActualData | undefined;
    residual: Omit<ModelVsActualGraphProps['residual'], 'colors'> & {
        colors: {
            min: string;
            max: string;
        };
    };
}): EChartsOption => {
    if (!data) {
        return {
            legend: {
                show: true,
                showCustom: true,
                orient: 'vertical',
                align: 'left',
                icon: 'circle',
                data: ['Model', 'Actual'],
                left: 100,
            },
        };
    }

    return {
        legend: {
            show: true,
            showCustom: true,
            orient: 'vertical',
            align: 'left',
            icon: 'circle',
            data: ['Model', 'Actual'],
            left: 100,
        },
        tooltip: [
            {
                trigger: 'axis',
                triggerOn: 'mousemove|click',
                backgroundColor: 'transparent',
                borderWidth: 0,
                padding: 0,
                formatter: (params: any) => {
                    return jsxToHtml(
                        <CustomTooltip
                            params={params}
                            formatNumber={formatNumber}
                        />,
                    );
                },
            },
        ],
        xAxis: [
            {
                show: true,
                data: data.modelData.map((d) => d.period),
                type: 'category',
                splitLine: { show: false },
                tooltip: {
                    show: false,
                },
                axisPointer: {
                    type: 'shadow',
                    z: 100,
                },
                axisLabel: {
                    fontSize: 14,
                    fontWeight: 'lighter',
                    margin: 20,
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    formatter: (value) => {
                        return formatNumber(value, {
                            locale: 'en-US',
                            notation: 'compact',
                        });
                    },
                    fontSize: 16,
                    fontWeight: 'lighter',
                },
                axisTick: {
                    show: true,
                },
            },
        ],
        series: [
            {
                name: 'Model',
                type: 'line',
                yAxisIndex: 0,
                itemStyle: {
                    color: '#73EFEA',
                },
                lineStyle: {
                    width: 2,
                },
                showAllSymbol: true,
                data: data.modelData.map((d) => d.kpi),
                smooth: false,
                symbolSize: 4,
            },
            {
                name: 'Actual',
                type: 'line',
                yAxisIndex: 0,
                showAllSymbol: true,
                itemStyle: {
                    color: '#2B67FF',
                },
                lineStyle: {
                    width: 2,
                },
                smooth: false,
                data: data.actualData.map((d) => d.kpi),
                symbolSize: 4,
            },
            {
                name: 'Residual',
                type: 'bar',
                stack: 'residual',
                tooltip: {
                    valueFormatter: function (value: any) {
                        return formatNumber(value);
                    },
                },
                data: data.modelData.map(({ residual }) => {
                    return {
                        value: residual,
                        itemStyle: {
                            color: getColorFromRange(residual, {
                                color: {
                                    start: residualConfig.colors.min,
                                    end: residualConfig.colors.max,
                                    steps: 50,
                                },
                                rule: {
                                    min: residualConfig.minResidual || 0,
                                    max: residualConfig.maxResidual || 0,
                                },
                            }),
                        },
                    };
                }),
            },
        ],
    };
};

type LegendClickEvent = {
    selected: {
        [name: string]: boolean;
    };
};

type SimpleChartProps = Omit<EChartsReactProps, 'option'> & {
    $shouldExpand?: boolean;
    className?: string;
    'data-testid'?: string;
};

type ToggleLegend = {
    type: 'TOGGLE_LEGEND';
    payload: { name: string; value?: boolean };
};

type SetData = {
    type: 'SET_DATA';
    payload: Partial<GraphState>;
};

type GraphState = {
    legend: { name: string; selected: boolean; color?: string }[];
};

const graphReducer = (state: GraphState, action: ToggleLegend | SetData) => {
    switch (action.type) {
        case 'TOGGLE_LEGEND':
            return {
                ...state,
                legend: state.legend.map((item) => {
                    if (item.name === action.payload.name) {
                        return {
                            ...item,
                            selected:
                                action.payload.value !== undefined
                                    ? action.payload.value
                                    : !item.selected,
                        };
                    }
                    return item;
                }),
            };
        case 'SET_DATA':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

const GradientLegend = ({
    title,
    minValue,
    maxValue,
    gradient,
}: {
    title?: string;
    minValue: string | number;
    maxValue: string | number;
    gradient: { minColor: string; maxColor: string };
}) => {
    return (
        <div className="flex flex-col align-center text-center">
            {title ? (
                <Typography variant="bodyXS" className="mb-1">
                    {title}
                </Typography>
            ) : null}
            <div
                style={{
                    backgroundImage: `linear-gradient(to right, ${gradient?.minColor}, ${gradient?.maxColor})`,
                }}
                className={'h-5 rounded-2xl'}
            ></div>
            <div className="flex justify-between mt-1 dark:text-white text-dark">
                <div className="text-xs -translate-x-2">{minValue}</div>
                <div className="text-xs translate-x-2">{maxValue}</div>
            </div>
        </div>
    );
};

function getLegendItems(chartsOption: EChartsOption) {
    const { legend, series } = chartsOption;

    const legendData: {
        name: string;
        selected: boolean;
        color?: string;
    }[] = [];

    if (legend && Array.isArray(series)) {
        if (legend.show && legend.data)
            legend.data.forEach((name) => {
                const parsedName = typeof name === 'string' ? name : name.name;
                const foundSeries = series.find((s) => s.name === parsedName);
                const color = (foundSeries as unknown as any)?.itemStyle?.color;
                if (parsedName) {
                    legendData.push({
                        name: parsedName,
                        selected: true,
                        color: typeof color === 'string' ? color : undefined,
                    });
                }
            });
    }

    return legendData;
}

const useChart = ({ chartOptions }: { chartOptions: EChartsOption }) => {
    const chartRef = useRef<EChartsReact>(null);

    const [{ legend }, dispatch] = useReducer(graphReducer, {
        legend: [],
    });

    const toggleLegend = useCallback((name: string) => {
        chartRef.current?.getEchartsInstance()?.dispatchAction({
            type: 'legendToggleSelect',
            name,
        });
    }, []);

    const onLegendChange = useCallback((params: LegendClickEvent) => {
        Object.entries(params.selected).forEach(([name, value]) => {
            dispatch({
                type: 'TOGGLE_LEGEND',
                payload: {
                    name,
                    value,
                },
            });
        });
    }, []);

    useEffect(() => {
        dispatch({
            type: 'SET_DATA',
            payload: {
                legend: getLegendItems(chartOptions),
            },
        });
    }, [chartOptions]);

    useEffect(() => {
        const listener = () => {
            const eCharts = chartRef.current?.getEchartsInstance();
            eCharts?.resize();
        };

        window.addEventListener('resize', listener);

        return () => window.removeEventListener('resize', listener);
    });

    const combinedChartOptions = useMemo(() => {
        return {
            ...chartOptions,
            legend: {
                ...chartOptions.legend,
                show: chartOptions.legend.showCustom
                    ? false
                    : chartOptions.legend.show,
            },
        };
    }, [chartOptions]);

    return {
        ref: chartRef,
        legendItems: legend,
        toggleLegend,
        chartOptions: combinedChartOptions,
        onLegendChange,
    };
};

const BaseChart = memo(
    forwardRef(
        (
            {
                onLegendChange,
                theme: theirTheme,
                ...props
            }: SimpleChartProps & {
                onLegendChange?: (params: LegendClickEvent) => void;
                option: EChartsOption;
                theme?: Record<string, any>;
            },
            ref: Ref<EChartsReact>,
        ) => {
            const { theme } = useChartsContext();
            return (
                <EChartsReact
                    theme={{ ...theme, ...(theirTheme ?? {}) }}
                    data-testid={props['data-testid']}
                    className={props.className}
                    style={
                        props.$shouldExpand
                            ? {
                                  minHeight: 'inherit',
                                  height: '100%',
                                  width: '100%',
                              }
                            : {
                                  minHeight: 'inherit',
                                  // height defaults to 300px
                                  height: '300px',
                                  width: '100%',
                              }
                    }
                    ref={ref}
                    onEvents={
                        onLegendChange
                            ? {
                                  legendselectchanged: onLegendChange,
                              }
                            : undefined
                    }
                    notMerge
                    {...props}
                />
            );
        },
    ),
);

interface ModelVsActualGraphProps<
    T extends GetModelVsActualData | undefined =
        | GetModelVsActualData
        | undefined,
> {
    isLoading?: boolean;
    className?: string;
    residual: {
        maxResidual: number | undefined;
        minResidual: number | undefined;
        colors: {
            onlyPositive: {
                max: string;
                min: string;
            };
            onlyNegative: {
                max: string;
                min: string;
            };
            both: {
                max: string;
                min: string;
            };
        };
    };

    data: T;
}

// Pick the color based on the min and max residual values
const pickColors = ({
    minValue,
    maxValue,
    colors,
}: {
    minValue: number | undefined;
    maxValue: number | undefined;
    colors: ModelVsActualGraphProps['residual']['colors'];
}) => {
    if (minValue && minValue > 0) {
        return {
            onlyPositive: true,
            onlyNegative: false,
            max: colors.onlyPositive.max,
            min: colors.onlyPositive.min,
        };
    }
    if (maxValue && maxValue < 0) {
        return {
            onlyPositive: false,
            onlyNegative: true,
            max: colors.onlyNegative.max,
            min: colors.onlyNegative.min,
        };
    }
    return {
        onlyPositive: false,
        onlyNegative: false,
        max: colors.both.max,
        min: colors.both.min,
    };
};

export const ModelVsActualGraph: FC<ModelVsActualGraphProps> = memo(
    ({ className, isLoading, data, residual }) => {
        const { formatNumber } = useConfigContext();

        const colors = useMemo(() => {
            return pickColors({
                minValue: residual.minResidual,
                maxValue: residual.maxResidual,
                colors: residual.colors,
            });
        }, [residual]);

        const eChartsOptions = useMemo(() => {
            return getEChartsOptions({
                data,
                formatNumber,
                residual: {
                    ...residual,
                    colors,
                },
            });
        }, [colors, data, residual, formatNumber]);

        const {
            ref: chartRef,
            legendItems,
            toggleLegend,
            chartOptions,
            onLegendChange,
        } = useChart({
            chartOptions: eChartsOptions,
        });

        const opts = useMemo<Opts>(() => ({ renderer: 'svg' }), []);

        if (isLoading) {
            return null;
        }

        return (
            <div className={twCn('relative w-full', className)}>
                <div className="absolute z-10 right-5 flex flex-row gap-4 align-center justify-center align-middle">
                    <ToggleLegend
                        legendItems={legendItems}
                        onSelect={toggleLegend}
                    />
                    <GradientLegend
                        title="Residual"
                        minValue={formatNumber(residual.minResidual || 0)}
                        maxValue={formatNumber(residual.maxResidual || 0)}
                        gradient={{
                            minColor: colors.onlyNegative
                                ? colors.max
                                : colors.min,
                            maxColor: colors.onlyNegative
                                ? colors.min
                                : colors.max,
                        }}
                    />
                </div>
                <BaseChart
                    $shouldExpand
                    className="pt-3"
                    ref={chartRef}
                    option={chartOptions}
                    onLegendChange={onLegendChange}
                    opts={opts}
                    theme={{
                        line: {
                            itemStyle: {
                                borderWidth: '4',
                            },
                            lineStyle: {
                                width: '3',
                            },
                            symbolSize: '0',
                            symbol: 'circle',
                            smooth: true,
                        },
                    }}
                />
            </div>
        );
    },
);

ModelVsActualGraph.displayName = 'ModelVsActualGraph';
