import { DEFAULT_PERIOD, PeriodOption } from '@/config/model';
import { getPeriodOptionsForModel } from '@/utils/model';
import moment from 'moment';

const validatePeriodParam = (
    periodFromSearch: string | undefined,
    fallBackPeriod: PeriodOption,
    availablePeriods: PeriodOption[],
): PeriodOption | undefined => {
    if (
        !periodFromSearch ||
        !availablePeriods.includes(periodFromSearch as PeriodOption)
    ) {
        if (availablePeriods.includes(DEFAULT_PERIOD)) {
            return DEFAULT_PERIOD;
        } else {
            return fallBackPeriod;
        }
    }
};

const validateDateParam = (date: string | undefined) => {
    const shouldReturnBoundaryDate =
        !date || !moment.utc(date, 'DD-MM-YYYY').isValid();

    return {
        shouldBeBefore: (boundaryDate: moment.Moment) => {
            if (
                shouldReturnBoundaryDate ||
                boundaryDate.isBefore(moment.utc(date, 'DD-MM-YYYY'))
            )
                return boundaryDate.format('DD-MM-YYYY');
        },
        shouldBeAfter: (boundaryDate: moment.Moment) => {
            if (
                shouldReturnBoundaryDate ||
                boundaryDate.isAfter(moment.utc(date, 'DD-MM-YYYY'))
            )
                return boundaryDate.format('DD-MM-YYYY');
        },
    };
};

type SearchParams = {
    startDate?: string;
    endDate?: string;
    period?: PeriodOption;
};

export const validateDateAndPeriodSearchParams = ({
    search,
    project,
}: {
    search: SearchParams;
    project: {
        period: PeriodOption;
        startDate: string;
        endDate: string;
    };
}): { shouldRedirect: boolean; newSearch: SearchParams } => {
    const availablePeriods = getPeriodOptionsForModel(project.period);

    const period = validatePeriodParam(
        search.period,
        project.period,
        availablePeriods,
    );

    let shouldRedirect = false;
    const newSearch: SearchParams = { ...search };

    if (period) {
        shouldRedirect = true;
        newSearch.period = period;
    }

    const validatedDates = validateDateParams({
        boundaryStartDate: project.startDate,
        boundaryEndDate: project.endDate,
        endDate: search.endDate,
        startDate: search.startDate,
    });

    if (validatedDates) {
        shouldRedirect = true;
        newSearch.startDate = validatedDates.startDate;
        newSearch.endDate = validatedDates.endDate;
    }

    return { shouldRedirect, newSearch };
};

export const validateDateParams = ({
    boundaryStartDate,
    boundaryEndDate,
    endDate,
    startDate,
}: {
    startDate?: string | undefined;
    endDate?: string | undefined;
    boundaryStartDate: string;
    boundaryEndDate: string;
}) => {
    const projectStartDate = moment.utc(boundaryStartDate, 'DD-MM-YYYY');
    const projectEndDate = moment.utc(boundaryEndDate, 'DD-MM-YYYY');

    const momentEnd = moment(endDate, 'DD-MM-YYYY');

    const startDateBeforeEndDate = moment(startDate, 'DD-MM-YYYY').isSame(
        momentEnd,
    )
        ? undefined
        : validateDateParam(startDate).shouldBeBefore(momentEnd);

    const startDateAfter =
        validateDateParam(startDate).shouldBeAfter(projectStartDate);

    const startDateBefore =
        validateDateParam(startDate).shouldBeBefore(projectEndDate);

    const endDateBefore =
        validateDateParam(endDate).shouldBeBefore(projectEndDate);

    const endDateAfter =
        validateDateParam(endDate).shouldBeAfter(projectStartDate);

    if (
        startDateBeforeEndDate ||
        startDateAfter ||
        endDateBefore ||
        startDateBefore ||
        endDateAfter
    ) {
        return {
            startDate: boundaryStartDate,
            endDate: boundaryEndDate,
        };
    }

    return undefined;
};
