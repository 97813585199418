import { Typography } from '@analytical-alley/ui';
import React from 'react';

export const PercentItem = ({
    percent,
    impact,
}: {
    percent: number;
    impact: string;
}) => {
    if (impact === 'positive') {
        return (
            <div className="z-40 flex items-center text-xs font-semibold text-green-500 dark:text-green-500 text-center">
                <Typography
                    className="text-green-400 dark:text-green-400"
                    variant="bodyXS"
                >
                    {percent}%
                </Typography>
            </div>
        );
    }

    if (impact === 'negative') {
        return (
            <div className="flex items-center font-semibold text-center text-red-600 dark:text-red-400">
                <Typography
                    className="text-red-600 dark:text-red-400"
                    variant="bodyXS"
                >
                    {Math.abs(percent)}%
                </Typography>
            </div>
        );
    }

    return (
        <div className="flex flex-1 items-center justify-end font-bold">
            <Typography className="text-black dark:text-white" variant="bodyXS">
                {percent}%
            </Typography>
        </div>
    );
};
