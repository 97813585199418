import { ShadowBarTitle } from '@/components/ShadowBarChart/ShadowBarTitle';
import { twCn } from '@/utils';
import React, { forwardRef, ReactNode } from 'react';
import { useBarChartContext } from './context';

type ShadowBarProps = { height: number; children?: ReactNode } & (
    | {
          variant: 'primary' | 'secondary';
          backgroundColor?: never;
      }
    | { backgroundColor: string; variant?: never }
);

const ShadowBarBase = forwardRef(
    (
        { height, variant, children, backgroundColor }: ShadowBarProps,
        ref: any,
    ) => {
        const { maxValue, height: chartHeight } = useBarChartContext();

        const multiplier = chartHeight / maxValue;

        return (
            <div
                ref={(r) => {
                    if (ref?.current) {
                        return;
                    }
                    if (ref) ref.current = r;
                }}
                className={twCn(
                    'absolute bottom-0 transition-all duration-300',
                    {
                        'left-2 right-2 group-hover:left-1 group-hover:right-1':
                            variant === 'primary' || backgroundColor,
                        'left-0 right-0 group-hover:-left-1 group-hover:-right-1':
                            variant === 'secondary',
                    },
                )}
            >
                {children}
                <div
                    style={{
                        height: height * multiplier,
                        backgroundColor: backgroundColor,
                    }}
                    className={twCn('rounded-t-md', {
                        'bg-[#2B67FF]': variant === 'primary',
                        'bg-indigo-300 bg-opacity-20 group-hover:bg-opacity-40':
                            variant === 'secondary',
                    })}
                ></div>
            </div>
        );
    },
);

ShadowBarBase.displayName = 'ShadowBar';

export const ShadowBar = Object.assign(ShadowBarBase, {
    Title: ShadowBarTitle,
});
