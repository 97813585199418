import { jsx as i } from "react/jsx-runtime";
import { useTooltip as p } from "./useTooltip.js";
import { TooltipContext as e } from "./tooltipContext.js";
function f({
  children: o,
  ...t
}) {
  const r = p(t);
  return /* @__PURE__ */ i(e.Provider, { value: r, children: o });
}
export {
  f as Tooltip
};
