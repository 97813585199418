import {
    BaseChart,
    ChartMouseHandler,
    EChartsOption,
    useChart,
} from '@/components/ECharts';
import { NumberFormatter, useConfigContext } from '@/context/configContext';
import { useSpendSummaryContext } from '@/dashboards/SpendSummary/hooks';
import { jsxToHtml } from '@/utils/reactUtils';
import { Typography } from '@analytical-alley/ui';
import {
    Interval,
    eachDayOfInterval,
    eachMonthOfInterval,
    eachQuarterOfInterval,
    eachWeekOfInterval,
    eachYearOfInterval,
    format,
    parse,
} from 'date-fns';
import { BarSeriesOption } from 'echarts';
import EChartsReact from 'echarts-for-react';
import { isArray } from 'lodash';
import React, {
    Ref,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';

interface GraphProps {
    title: string;
    isInversed?: boolean;
    chartRef?: Ref<EChartsReact | null>;
    selected: string;
    onSelected: (value: string) => void;
    onSeriesData?: (chartOptionSeries: ChartOptions['series']) => void;
}

type AreaTooltipProps = {
    seriesName: string;
    color: string;
    value: number;
    axisValueLabel: string;
}[];

type ChartOptions = EChartsOption<BarSeriesOption>;

type Period = 'yearly' | 'quarterly' | 'monthly' | 'weekly' | 'daily';

const AreaTooltip = ({
    modelBreakdown,
    params,
    selectedSeriesName,
    graphTitle,
    formatNumber,
}: {
    modelBreakdown: 'category' | 'variable';
    params: AreaTooltipProps;
    selectedSeriesName?: string;
    graphTitle: string;
    formatNumber: NumberFormatter;
}) => {
    if (!selectedSeriesName) {
        return null;
    }

    const hoveredItem = params.find(
        (param) => param.seriesName === selectedSeriesName,
    );

    if (!hoveredItem) {
        return null;
    }

    const totalValue = params.reduce((sum, param) => sum + param.value, 0);

    return (
        <div className="tooltip-content">
            <table>
                <tbody>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                {modelBreakdown === 'category'
                                    ? 'Category'
                                    : 'Variable'}
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {hoveredItem.seriesName}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Chosen period:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {params[0]?.axisValueLabel}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                {`${graphTitle} Share:`}
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {`${totalValue > 0 ? Math.round((hoveredItem.value / totalValue) * 100) : hoveredItem.value}%`}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                {`${graphTitle}:`}
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {formatNumber(hoveredItem.value)}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const getPeriodDateFormat = (
    period: Period,
): {
    format: string;
    intervalGeneratorFn: (interval: Interval) => Date[];
    parserFn: (date: string) => Date;
} => {
    switch (period) {
        case 'yearly':
            return {
                format: 'yyyy',
                intervalGeneratorFn: eachYearOfInterval,
                parserFn: (date) => parse(date, 'yyyy', new Date()),
            };
        case 'quarterly':
            return {
                format: 'QQQ-yyyy',
                intervalGeneratorFn: eachQuarterOfInterval,
                parserFn: (date) => parse(date, 'QQQ-yyyy', new Date()),
            };
        case 'monthly':
            return {
                format: 'MM-yyyy',
                intervalGeneratorFn: eachMonthOfInterval,
                parserFn: (date) => parse(date, 'MM-yyyy', new Date()),
            };
        case 'daily':
            return {
                format: 'dd-MM-yyyy',
                intervalGeneratorFn: eachDayOfInterval,
                parserFn: (date) => parse(date, 'dd-MM-yyyy', new Date()),
            };
        default: //weekly
            return {
                format: 'ww-yyyy',
                intervalGeneratorFn: eachWeekOfInterval,
                parserFn: (date: string) => {
                    const [week, year] = date.split('-');
                    const weekDate = parse(week as string, 'ww', new Date());
                    weekDate.setFullYear(parseInt(year as string, 10));

                    return weekDate;
                },
            };
    }
};

const fillDateGaps = (dates: string[], period: Period): string[] => {
    if (dates.length === 0) return dates;

    const periodDateFormat = getPeriodDateFormat(period);
    const parsedDates = dates.map((date: string) =>
        periodDateFormat.parserFn(date).getTime(),
    );

    const minDate = new Date(Math.min(...parsedDates));
    const maxDate = new Date(Math.max(...parsedDates));

    const allDates = periodDateFormat.intervalGeneratorFn({
        start: minDate,
        end: maxDate,
    });

    return allDates.map((date) => format(date, periodDateFormat.format));
};

const getGraphData = ({
    isInversed,
    seriesData,
    xAxisData,
    period,
    formatNumber,
}: {
    isInversed: boolean;
    xAxisData: string[];
    seriesData: {
        [key: string]: {
            data: { [key: string]: number };
            color: string;
        };
    };
    period: string | undefined;
    formatNumber: NumberFormatter;
}): ChartOptions => {
    const dates = xAxisData.filter((date, index) => {
        return xAxisData.indexOf(date) === index;
    });
    const datesWithGaps = fillDateGaps(dates, period as Period);

    const series: ChartOptions['series'] = Object.entries(seriesData).map(
        ([key, { data, color }], index) => {
            return {
                name: key,
                type: 'bar',
                stack: 'Total',
                symbolSize: 2,
                showSymbol: false,
                showAllSymbol: false,
                triggerLineEvent: true,
                color: color,
                barMaxWidth: 60,
                barMinWidth: 2,
                itemStyle: {
                    borderRadius:
                        index === Object.keys(seriesData).length - 1
                            ? isInversed
                                ? [0, 0, 5, 5]
                                : [5, 5, 0, 0]
                            : 0,
                },
                data: datesWithGaps.map((date) => ({
                    name: date,
                    value: data[date] || 0,
                })),
            } as unknown as ChartOptions['series'][0];
        },
    );

    return {
        animation: false,
        tooltip: {
            show: false,
            trigger: 'axis',
            triggerOn: 'mousemove|click',
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 10,
            type: 'item',
            confine: true,
            extraCssText: 'max-width: 600px; white-space: pre-wrap;',
            formatter: '',
        },
        legend: {
            show: false,
        },
        grid: {
            right: '1%',
            left: '7%',
            top: isInversed ? '5%' : '15%',
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                show: !isInversed,
                axisLine: {
                    show: false,
                },
                data: datesWithGaps,
                axisLabel: {
                    rotate: 90,
                    margin: period === 'yearly' ? 30 : 20,
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                inverse: isInversed,
                axisLabel: {
                    formatter: (value: number) =>
                        formatNumber(value, {
                            locale: 'en-US',
                            notation: 'compact',
                        }),
                },
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.1,
                    },
                },
            },
        ],
        series: series,
    };
};

export const MediaSpendVsContributionGraph = memo(
    ({
        title,
        isInversed = false,
        chartRef,
        selected,
        onSelected,
        onSeriesData,
    }: GraphProps) => {
        const { formatNumber } = useConfigContext();
        const {
            spendSummaryData,
            getVisibleContributionData,
            getVisibleSpendData,
            modelBreakdown,
            period,
        } = useSpendSummaryContext();

        const eChartsOptions = useMemo(() => {
            return getGraphData({
                isInversed,
                seriesData:
                    title === 'Spend'
                        ? getVisibleSpendData()
                        : getVisibleContributionData(),
                xAxisData: spendSummaryData.dates,
                period: period,
                formatNumber,
            });
        }, [
            formatNumber,
            getVisibleContributionData,
            getVisibleSpendData,
            isInversed,
            period,
            spendSummaryData.dates,
            title,
        ]);

        const [hovered, setHovered] = useState('');

        const selectedRef = useRef(selected);
        const timeoutRef = useRef<number | null>(null);

        useEffect(() => {
            onSeriesData?.(eChartsOptions.series);
        }, [eChartsOptions, onSeriesData]);

        useEffect(() => {
            selectedRef.current = selected;
        }, [selected]);

        const onClick: ChartMouseHandler = useCallback(
            (context) => {
                if (context.seriesName) {
                    onSelected(context.seriesName);
                } else {
                    onSelected('');
                }
            },
            [onSelected],
        );

        const onMouseOver: ChartMouseHandler = useCallback((context) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (context.seriesName) {
                setHovered(
                    selectedRef.current
                        ? selectedRef.current
                        : context.seriesName,
                );
            }
        }, []);

        const onMouseOut: ChartMouseHandler = useCallback(() => {
            if (selectedRef.current) {
                return;
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = window.setTimeout(() => {
                setHovered('');
            }, 0);
        }, []);

        const { ref, chartOptions, onEvents } = useChart({
            chartOptions: eChartsOptions,
            onClick,
            onMouseOver,
            onMouseOut,
        });

        useEffect(() => {
            const instance = ref.current?.getEchartsInstance();

            if (instance) {
                instance.setOption({
                    tooltip: {
                        showDelay: selected ? 0 : 50,
                        show: true,
                        formatter: (params: AreaTooltipProps) => {
                            return jsxToHtml(
                                <AreaTooltip
                                    modelBreakdown={modelBreakdown}
                                    params={params}
                                    selectedSeriesName={hovered}
                                    graphTitle={title}
                                    formatNumber={formatNumber}
                                />,
                            );
                        },
                    },
                });
            }
        }, [ref, hovered, selected, title, modelBreakdown, formatNumber]);

        useEffect(() => {
            const instance = ref.current?.getEchartsInstance();

            const series =
                chartOptions?.series && isArray(chartOptions.series)
                    ? chartOptions.series
                    : [];

            if (instance) {
                instance.setOption({
                    series: series.map((seriesOption) => {
                        if (selected && selected !== seriesOption.name) {
                            return {
                                ...seriesOption,
                                areaStyle: { opacity: 0.1 },
                                itemStyle: { opacity: 0.1 },
                                lineStyle: { opacity: 0 },
                                symbol: 'none',
                            };
                        }
                        return {
                            ...seriesOption,
                            areaStyle: { opacity: 0.8 },
                            itemStyle: { opacity: 0.8 },
                            lineStyle: { opacity: 0.6 },
                            symbol: 'circle',
                        };
                    }),
                });
            }
        }, [ref, chartOptions.series, selected]);

        useImperativeHandle(chartRef, () => ref.current, [ref]);

        return (
            <div className="relative w-full grid grid-cols-[2rem_1fr]">
                <div className="self-center">
                    <Typography className="-rotate-90" variant="bodyM">
                        {title}
                    </Typography>
                </div>
                <div className="h-[22.5rem] w-full">
                    <BaseChart
                        $shouldExpand
                        option={chartOptions}
                        ref={ref}
                        onEvents={onEvents}
                    />
                </div>
            </div>
        );
    },
);

MediaSpendVsContributionGraph.displayName = 'MediaSpendVsContributionGraph';
