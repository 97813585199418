import { HighlightLegend } from '@/components/ECharts';
import { WithLoader } from '@/components/WithLoader';
import { MediaSpendVsContributionGraph } from '@/dashboards/SpendSummary/components/MediaSpendVsContributionGraph';
import { useSpendSummaryQuery } from '@/dashboards/SpendSummary/hooks';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { TabButtons, Typography } from '@analytical-alley/ui';
import EChartsReact from 'echarts-for-react';
import React, { useCallback, useRef } from 'react';

type SeriesData = {
    name: string;
    color: string;
};

export const MediaSpendVsContributionGraphSection = () => {
    const {
        queryResult: { isLoading, isFetching },
        tabsToRender,
        onActiveTabChange,
        activeTabIndex,
    } = useSpendSummaryQuery();

    const chartRef = useRef<EChartsReact>(null);
    const [selected, setSelected] = React.useState<string>('');
    const [seriesData, setSeriesData] = React.useState<SeriesData[]>([]);

    const onSelected = useCallback((value: string) => {
        setSelected((prev: string) => {
            if (prev || prev === value) {
                return '';
            }
            return value;
        });
    }, []);

    return (
        <div className="glass-tile p-6">
            <div className="flex flex-col">
                <div className="flex flex-row">
                    <Typography variant="titleS">
                        <span className="flex flex-row flex-wrap">
                            <span>Media spend&nbsp;</span>
                            <span>vs contribution by category</span>
                        </span>
                    </Typography>
                    <span className="grow" />
                    <TabButtons
                        aria-label="Periodicity"
                        style="pills"
                        className="gap-2 px-2"
                        onActiveTabChange={onActiveTabChange}
                        isLoading={isLoading}
                        activeTab={activeTabIndex}
                    >
                        {tabsToRender?.map((periodName) => {
                            return (
                                <TabButtons.Item
                                    key={periodName}
                                    title={capitalizeFirstLetter(periodName)}
                                    disabled={isLoading}
                                />
                            );
                        })}
                    </TabButtons>
                </div>
                <WithLoader isLoading={isLoading} isFetching={isFetching}>
                    <MediaSpendVsContributionGraph
                        chartRef={chartRef}
                        selected={selected}
                        onSelected={onSelected}
                        onSeriesData={setSeriesData}
                        title="Spend"
                    />
                    <MediaSpendVsContributionGraph
                        chartRef={chartRef}
                        selected={selected}
                        onSelected={onSelected}
                        title="Contribution"
                        isInversed={true}
                    />
                    <div className="relative pl-14 pr-4 z-20 -mt-7">
                        <HighlightLegend
                            highlighted={selected}
                            legendItems={seriesData?.map((s) => ({
                                name: String(s.name),
                                highlighted: true,
                                color: s.color,
                            }))}
                            onClick={(name) => {
                                if (selected === name) {
                                    setSelected('');
                                    return;
                                }
                                setSelected(name);
                            }}
                        />
                    </div>
                </WithLoader>
            </div>
        </div>
    );
};
