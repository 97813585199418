import { jsx as c } from "react/jsx-runtime";
import { isDateInRange as M, Views as b } from "../../../utils/datepicker.js";
import f from "moment";
import { t as p } from "../../../bundle-mjs-3rKE4aij.js";
import { useDatePickerContext as d } from "../DatepickerContext.js";
const Y = () => {
  const {
    theme: l,
    minDate: r,
    maxDate: a,
    selectedDate: t,
    viewDate: h,
    setViewDate: w,
    setView: D
  } = d(), s = l.views.months;
  return /* @__PURE__ */ c("div", { className: s.items.base, children: [...Array(12)].map((T, n) => {
    const e = new Date(h.getTime());
    e.setDate(1), e.setMonth(n);
    const u = f().month(n).format("MMM"), g = (t == null ? void 0 : t.getFullYear()) === e.getFullYear() && (t == null ? void 0 : t.getMonth()) === e.getMonth(), o = r ? new Date(r.getTime()) : null, m = a ? new Date(a.getTime()) : null;
    o == null || o.setDate(1), m == null || m.setDate(1);
    const i = !M(
      e,
      o,
      m
    );
    return /* @__PURE__ */ c(
      "button",
      {
        disabled: i,
        type: "button",
        className: p(
          s.items.item.base,
          g && s.items.item.selected,
          i && s.items.item.disabled
        ),
        onClick: () => {
          i || (w(e), D(b.Days));
        },
        children: u
      },
      n
    );
  }) });
};
export {
  Y as DatepickerViewsMonth
};
