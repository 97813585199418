import { ReactNode } from 'react';
import { createContext, useContext } from 'use-context-selector';

export interface ConfigContextValue {
    formatNumber: (
        value: number,
        options?: Intl.NumberFormatOptions & { locale?: string | string[] },
    ) => string;
    locale: string;
    currency: string;
}

export type NumberFormatter = ConfigContextValue['formatNumber'];

const ConfigContext = createContext<ConfigContextValue>({
    formatNumber: (value) => value.toString(),
    locale: 'et-ET',
    currency: 'EUR',
});

export const useConfigContext = () => {
    const context = useContext(ConfigContext);

    if (!context) {
        throw new Error(
            'useConfigContext must be used within a ConfigProvider',
        );
    }

    return context;
};

const DEFAULT_OPTIONS: Intl.NumberFormatOptions = {
    style: 'currency',
    maximumFractionDigits: 0,
};

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
    const locale = 'et-ET';
    const currency = 'EUR';

    const numberFormat = new Intl.NumberFormat(locale, {
        currency,
        ...DEFAULT_OPTIONS,
    });

    const formatNumber: ConfigContextValue['formatNumber'] = (
        value,
        options?,
    ) => {
        if (options) {
            return new Intl.NumberFormat(options.locale || locale, {
                ...DEFAULT_OPTIONS,
                currency,
                ...options,
            }).format(value);
        }

        return numberFormat.format(value);
    };

    return (
        <ConfigContext.Provider value={{ formatNumber, locale, currency }}>
            {children}
        </ConfigContext.Provider>
    );
};
