/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FEATURE_FLAG_ACTIONS, FEATURE_FLAG_SUBJECTS } from '@/features/Casl';
import { useAbilityContext } from '@/features/Casl/hooks';
import { Router } from '@/router';
import { dashboardRootRoute, getRoutePath, toPath } from '@/utils/routes';
import { Prettify } from '@analytical-alley/ui/types';
import {
    AnyRouter,
    Link,
    LinkComponent,
    LinkProps,
    ToPathOption,
    useParams,
    useSearch,
} from '@tanstack/react-router';
import * as React from 'react';
import { ReactNode, useCallback, useMemo } from 'react';

type DashLinkProps<
    TRouter extends AnyRouter = Router,
    TFrom extends string = string,
    TTo extends string = '',
> = Omit<Parameters<LinkComponent<'a'>>[0], 'children'> & {
    children:
        | ((
              state: Prettify<
                  Parameters<
                      // @ts-ignore
                      Exclude<
                          Pick<Parameters<LinkComponent<'a'>>[0], 'children'>,
                          ReactNode
                      >['children']
                  >[0] & {
                      to: ToPathOption<TRouter, TFrom, TTo>;
                  }
              >,
          ) => ReactNode)
        | ReactNode;
};

type DashLink<
    TRouter extends AnyRouter = Router,
    TFrom extends string = string,
    TTo extends string = '',
> = (props: DashLinkProps<TRouter, TFrom, TTo>) => ReactNode;

type DashboardLinkObj<
    TRouter extends AnyRouter = Router,
    TFrom extends string = string,
    TTo extends string = '',
> = {
    title: string;
    accessible: boolean;
    Link: DashLink<TRouter, TFrom, TTo>;
};

type DashboardsLinkMap<
    TRouter extends AnyRouter = Router,
    TFrom extends string = string,
    TTo extends string = '',
> = {
    titleDashboard: DashboardLinkObj<TRouter, TFrom, TTo>;
    modelContributions: DashboardLinkObj<TRouter, TFrom, TTo>;
    spendSummary: DashboardLinkObj<TRouter, TFrom, TTo>;
    budgetOptimization: DashboardLinkObj<TRouter, TFrom, TTo>;
    campaignOptimization: DashboardLinkObj<TRouter, TFrom, TTo>;
    competitorsMediaInvestment: DashboardLinkObj<TRouter, TFrom, TTo>;
};

const DashboardLink = <
    TRouter extends AnyRouter = Router,
    TFrom extends string = string,
    TTo extends string = '',
    TMaskFrom extends string = TFrom,
    TMaskTo extends string = '',
>({
    to,
    ...linkProps
}: LinkProps<TRouter, TFrom, TTo, TMaskFrom, TMaskTo>) => {
    const LinkComponent = ({
        ...props
    }: DashLinkProps<TRouter, TFrom, TTo>) => {
        const children =
            typeof props.children === 'function'
                ? (linkState) =>
                      // @ts-ignore
                      props.children({
                          ...linkState,
                          to,
                      })
                : props.children;
        return (
            <Link to={to} {...(linkProps as any)} {...props}>
                {children}
            </Link>
        );
    };

    LinkComponent.displayName = `MatchLink(LinkComponent)`;

    return LinkComponent;
};

export const useDashboardLinks = () => {
    const { ability } = useAbilityContext();
    const { dashboardId } = useSearch({ strict: false });
    const { projectId } = useParams({ strict: false });

    const dashboardLinksMap: DashboardsLinkMap = useMemo(() => {
        if (!projectId || (!projectId && !dashboardId)) {
            return {} as DashboardsLinkMap;
        }

        const params = { projectId };

        return {
            titleDashboard: {
                title: 'Title Dashboard',
                accessible: ability.can(
                    FEATURE_FLAG_ACTIONS.VIEW,
                    FEATURE_FLAG_SUBJECTS.TITLE_DASHBOARD,
                ),
                Link: DashboardLink({
                    to: dashboardRootRoute,
                    params,
                    search: {
                        dashboardId,
                    },
                }),
            },
            modelContributions: {
                title: 'Model Contributions',
                accessible: ability.can(
                    FEATURE_FLAG_ACTIONS.VIEW,
                    FEATURE_FLAG_SUBJECTS.MODEL_CONTRIBUTION_DASHBOARD,
                ),
                Link: DashboardLink({
                    to: toPath(dashboardRootRoute, '/model-contributions'),
                    params,
                    search: {
                        dashboardId,
                        modelBreakdown: 'category',
                    },
                }),
            },
            spendSummary: {
                title: 'Spend Summary',
                accessible: ability.can(
                    FEATURE_FLAG_ACTIONS.VIEW,
                    FEATURE_FLAG_SUBJECTS.SPEND_SUMMARY_DASHBOARD,
                ),
                Link: DashboardLink({
                    to: toPath(dashboardRootRoute, '/spend-summary'),
                    params,
                    search: {
                        dashboardId,
                        modelBreakdown: 'category',
                    },
                }),
            },
            budgetOptimization: {
                title: 'Yearly Budget Optimization',
                accessible: ability.can(
                    FEATURE_FLAG_ACTIONS.VIEW,
                    FEATURE_FLAG_SUBJECTS.BUDGET_OPTIMIZATION_DASHBOARD,
                ),
                Link: DashboardLink({
                    to: toPath(dashboardRootRoute, '/budget-optimization'),
                    params,
                    search: {
                        dashboardId,
                    },
                }),
            },
            campaignOptimization: {
                title: 'Campaign Optimization',
                accessible: ability.can(
                    FEATURE_FLAG_ACTIONS.VIEW,
                    FEATURE_FLAG_SUBJECTS.CAMPAIGN_OPTIMIZATION_DASHBOARD,
                ),
                Link: DashboardLink({
                    to: toPath(dashboardRootRoute, '/campaign-optimization'),
                    params,
                    search: {
                        dashboardId,
                    },
                }),
            },
            competitorsMediaInvestment: {
                title: 'Competitors Media Investment',
                accessible: ability.can(
                    FEATURE_FLAG_ACTIONS.VIEW,
                    FEATURE_FLAG_SUBJECTS.COMPETITORS_MEDIA_DASHBOARD,
                ),
                Link: DashboardLink({
                    to: getRoutePath(
                        toPath(
                            dashboardRootRoute,
                            '/competitors-media-investment',
                        ),
                    ),
                    params,
                    search: {
                        dashboardId,
                        period: 'monthly',
                    },
                }),
            },
        } as DashboardsLinkMap;
    }, [projectId, dashboardId, ability]);

    const getFirstAccessibleLink = useCallback(
        (omit: (keyof DashboardsLinkMap)[] = []) => {
            const firstLink = Object.entries(dashboardLinksMap).find(
                ([key, { accessible }]) =>
                    accessible &&
                    !omit.includes(key as keyof DashboardsLinkMap),
            );

            if (firstLink) {
                return firstLink[1];
            }
        },
        [dashboardLinksMap],
    );

    return {
        getFirstAccessibleLink,
        allLinks: Object.values(dashboardLinksMap),
        allAccessibleLinks: Object.values(dashboardLinksMap).filter(
            ({ accessible }) => accessible,
        ),
    };
};
