import { FC, memo, useMemo } from 'react';

import { BaseChart, EChartsOption, useChart } from '@/components/ECharts';
import { useChartsContext } from '@/context/chartsThemeContext';
import { useConfigContext } from '@/context/configContext';
import { budgetShareChartOptions } from '@/dashboards/BudgetOptimization/components/BudgetShareByCategory/budgetShareChartOptions';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { PieSeriesOption } from 'echarts';

export type ShareByCategoryData = {
    name: string;
    budget: number;
    percentage?: number;
    color?: string;
};

export type ChartOptions = EChartsOption<PieSeriesOption>;

interface BudgetShareByCategoryChartProps {
    className?: string;
    isLoading?: boolean;
    tooltipTitle?: string;
    data: ShareByCategoryData[];
    showPercent: boolean;
}

export const BudgetShareByCategoryChart: FC<BudgetShareByCategoryChartProps> =
    memo(({ className, isLoading, data, showPercent, tooltipTitle }) => {
        const { theme } = useChartsContext();
        const { formatNumber } = useConfigContext();
        const { colorMap } = useDashboardGlobalContext();

        const eChartsOptions = useMemo(() => {
            return budgetShareChartOptions({
                data,
                showPercent,
                tooltipTitle,
                formatNumber,
                colorMap,
            });
            // Should include theme in the dependency array because when theme changes, the chart options should be re-set
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [theme, data, showPercent, formatNumber, colorMap]);

        const {
            ref: chartRef,
            chartOptions,
            onEvents,
        } = useChart({
            chartOptions: eChartsOptions,
        });

        if (isLoading) {
            return (
                <div className={className}>
                    <div className="flex items-center justify-center w-full h-[42rem]" />
                </div>
            );
        }

        return (
            <div className="bg-[#FBFEFF21] shadow shadow-[#FDFDFF19] rounded-2xl relative w-full h-[13rem] bg-white bg-opacity-10 border-white">
                <BaseChart
                    $shouldExpand
                    ref={chartRef}
                    option={chartOptions}
                    onEvents={onEvents}
                />
            </div>
        );
    });

BudgetShareByCategoryChart.displayName = 'BudgetShareByCategoryChart';
