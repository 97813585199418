import { Select } from '@/components/Select';
import { SelectRef } from '@/components/Select/Select';
import { ModelContributionsSearch } from '@/dashboards/ModelContributions';
import { DateRange } from '@/dashboards/ModelContributions/components/ActionBar/DateRange';
import {
    Filter,
    FilterNode,
} from '@/dashboards/ModelContributions/components/ActionBar/Filter';
import { useModelContributionContext } from '@/dashboards/ModelContributions/hooks';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { Label } from 'flowbite-react';
import moment from 'moment/moment';
import { useMemo, useRef } from 'react';

export const ActionBar = () => {
    const { search, setSearch } = useModelContributionContext();

    const { startDate, endDate } = useDashboardGlobalContext();

    const onSetSearch = (search: Partial<ModelContributionsSearch>) => {
        setSearch(search);
    };

    const { contributionData, setSelectedNodes } =
        useModelContributionContext();
    const selectRef = useRef<SelectRef>(null);

    const onSetSelectedNodes = (nodes: FilterNode[]) => {
        setSelectedNodes(nodes);
    };

    const nodes = useMemo(() => {
        return search.modelBreakdown === 'category'
            ? contributionData.treeNodes.map((node) => ({
                  ...node,
                  children: [],
              }))
            : contributionData.treeNodes;
    }, [contributionData.treeNodes, search.modelBreakdown]);

    const minDate = moment.utc(startDate, 'DD-MM-YYYY').toDate();
    const maxDate = moment.utc(endDate, 'DD-MM-YYYY').toDate();

    return (
        <>
            <div className="flex gap-4">
                <DateRange
                    minDate={minDate}
                    maxDate={maxDate}
                    searchStartDate={search.startDate}
                    searchEndDate={search.endDate}
                    onSetSearch={onSetSearch}
                />
            </div>
            <div className="flex gap-4">
                <div>
                    <div className="mb-1 block">
                        <Label
                            onClick={() => {
                                selectRef?.current?.click?.();
                            }}
                            className="font-mono font-normal"
                            htmlFor="modelBreakdown"
                            value="Model breakdown"
                        />
                    </div>
                    <Select
                        triggerRef={selectRef}
                        className="min-w-32"
                        selectedValue={search.modelBreakdown}
                        onSelectChange={(value) => {
                            setSearch({
                                modelBreakdown: value,
                            });
                        }}
                        dismissOnClick
                        label="Select..."
                        compact
                    >
                        <Select.Option
                            label={'By Category'}
                            value={'category'}
                        />
                        <Select.Option
                            label={'By Variable'}
                            value={'variable'}
                        />
                    </Select>
                </div>
                <div className="self-end">
                    <Filter
                        nodes={nodes}
                        onSetSelectedNodes={onSetSelectedNodes}
                        searchFilter={search.filter}
                        onSetSearch={onSetSearch}
                    />
                </div>
            </div>
        </>
    );
};
