import { ReactNode } from 'react';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

export const jsxToHtml = (children: ReactNode) => {
    const div = document.createElement('div');
    const root = createRoot(div);
    flushSync(() => {
        root.render(children);
    });

    return div.innerHTML;
};
