import { twCn } from '@/utils';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

type ShadowBarTitleProps = DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

export const ShadowBarTitle = ({
    children,
    className,
    ...props
}: ShadowBarTitleProps) => {
    return (
        <div
            className={twCn(
                'flex flex-col text-nowrap text-center items-center text mb-1',
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
};
