import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useTheme } from '@analytical-alley/ui/hooks';

import { getChartsTheme } from '@/config/chartsTheme';

const ChartsThemeContext = createContext<{
    theme: Record<string, any>;
    mode: 'dark' | 'light';
}>({
    theme: getChartsTheme({ mode: 'dark' }),
    mode: 'dark',
});

export const ChartsThemeProvider = ({ children }: { children: ReactNode }) => {
    const { isDarkMode } = useTheme();

    const theme = useMemo(
        () => getChartsTheme({ mode: isDarkMode ? 'dark' : 'light' }),
        [isDarkMode],
    );

    return (
        <ChartsThemeContext.Provider
            value={{ theme, mode: isDarkMode ? 'dark' : 'light' }}
        >
            {children}
        </ChartsThemeContext.Provider>
    );
};

export const useChartsContext = () => {
    const context = useContext(ChartsThemeContext);
    if (!context) {
        throw new Error(
            'useChartsTheme must be used within a ChartsThemeProvider',
        );
    }
    return context;
};
