import { defineTable } from '@/duckdb';
import {
    modelContributionsColumnMap,
    modelContributionsSchema,
} from './schema';

export { modelContributionsQueries } from './queries';
export { type TModelContributionRow } from './schema';

export const ModelContributionsTable = defineTable(
    'Model Contributions',
    modelContributionsSchema,
    modelContributionsColumnMap,
);
