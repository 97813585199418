import { jsx as e } from "react/jsx-runtime";
import { cn as o } from "../../utils/cn.js";
const b = {
  sm: "h-6 w-6 border-t-2 border-b-2",
  md: "h-12 w-12 border-t-4 border-b-4",
  lg: "h-24 w-24 border-t-8 border-b-8",
  xl: "h-36 w-36 border-t-8 border-b-8"
}, t = ({ size: r = "md" }) => /* @__PURE__ */ e(
  "div",
  {
    className: o(
      "animate-spin rounded-full border-blue-500",
      b[r]
    )
  }
);
export {
  t as Spinner
};
