import { Typography } from '@analytical-alley/ui';

export const OptimizedPieTooltip = ({
    data,
    percent,
    value,
}: {
    data: { value: number; name: string };
    percent: number;
    value: string;
}) => {
    return (
        <div className="tooltip-content">
            <table>
                <tbody>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                {data.name}:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {percent}%
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Budget:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {value}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
