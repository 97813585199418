import { jsxs as c, jsx as n, Fragment as C } from "react/jsx-runtime";
import t from "react";
import { t as T } from "../../bundle-mjs-3rKE4aij.js";
import { Tooltip as a } from "../Tooltip/index.js";
import { cn as y } from "../../utils/cn.js";
import { typographyTheme as j } from "./theme.js";
import { useEllipsis as _ } from "./useEllipsis.js";
const F = t.forwardRef(
  ({
    color: p,
    as: m = "p",
    className: e,
    children: r,
    responsive: d = !0,
    variant: l = "inherit",
    ellipsisTooltip: o,
    ...s
  }, f) => {
    const { targetElementRef: h, measureRef: g, isOverflowing: u } = _(), { styles: v } = j, { colors: b, responsive: w, variants: E, base: R } = v;
    e = p ? e : T(R, e);
    const x = b[p ?? "inherit"], i = y(
      x,
      E[l],
      {
        [w[l]]: d
      },
      e
    );
    return o ? /* @__PURE__ */ c(a, { disabled: !u, children: [
      /* @__PURE__ */ n(a.Trigger, { asChild: !0, children: t.createElement(
        m,
        {
          ...s,
          ref: h,
          className: y(
            "overflow-hidden overflow-ellipsis",
            i
          )
        },
        /* @__PURE__ */ c(C, { children: [
          /* @__PURE__ */ n(
            "span",
            {
              style: {
                position: "absolute",
                visibility: "hidden"
              },
              ref: g,
              children: t.createElement(
                "span",
                {
                  ...s,
                  ref: f,
                  className: i
                },
                r
              )
            }
          ),
          r
        ] })
      ) }),
      /* @__PURE__ */ n(a.Content, { children: typeof o == "boolean" ? r : o })
    ] }) : t.createElement(
      m,
      {
        ...s,
        ref: f,
        className: i
      },
      r
    );
  }
);
F.displayName = "Typography";
export {
  F as Typography,
  F as default
};
