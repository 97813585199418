var Xe;
(function(o) {
  o[o.NONE = 0] = "NONE", o[o.Null = 1] = "Null", o[o.Int = 2] = "Int", o[o.Float = 3] = "Float", o[o.Binary = 4] = "Binary", o[o.Utf8 = 5] = "Utf8", o[o.Bool = 6] = "Bool", o[o.Decimal = 7] = "Decimal", o[o.Date = 8] = "Date", o[o.Time = 9] = "Time", o[o.Timestamp = 10] = "Timestamp", o[o.Interval = 11] = "Interval", o[o.List = 12] = "List", o[o.Struct = 13] = "Struct", o[o.Union = 14] = "Union", o[o.FixedSizeBinary = 15] = "FixedSizeBinary", o[o.FixedSizeList = 16] = "FixedSizeList", o[o.Map = 17] = "Map", o[o.Duration = 18] = "Duration", o[o.LargeBinary = 19] = "LargeBinary", o[o.LargeUtf8 = 20] = "LargeUtf8", o[o.Dictionary = -1] = "Dictionary", o[o.Int8 = -2] = "Int8", o[o.Int16 = -3] = "Int16", o[o.Int32 = -4] = "Int32", o[o.Int64 = -5] = "Int64", o[o.Uint8 = -6] = "Uint8", o[o.Uint16 = -7] = "Uint16", o[o.Uint32 = -8] = "Uint32", o[o.Uint64 = -9] = "Uint64", o[o.Float16 = -10] = "Float16", o[o.Float32 = -11] = "Float32", o[o.Float64 = -12] = "Float64", o[o.DateDay = -13] = "DateDay", o[o.DateMillisecond = -14] = "DateMillisecond", o[o.TimestampSecond = -15] = "TimestampSecond", o[o.TimestampMillisecond = -16] = "TimestampMillisecond", o[o.TimestampMicrosecond = -17] = "TimestampMicrosecond", o[o.TimestampNanosecond = -18] = "TimestampNanosecond", o[o.TimeSecond = -19] = "TimeSecond", o[o.TimeMillisecond = -20] = "TimeMillisecond", o[o.TimeMicrosecond = -21] = "TimeMicrosecond", o[o.TimeNanosecond = -22] = "TimeNanosecond", o[o.DenseUnion = -23] = "DenseUnion", o[o.SparseUnion = -24] = "SparseUnion", o[o.IntervalDayTime = -25] = "IntervalDayTime", o[o.IntervalYearMonth = -26] = "IntervalYearMonth", o[o.DurationSecond = -27] = "DurationSecond", o[o.DurationMillisecond = -28] = "DurationMillisecond", o[o.DurationMicrosecond = -29] = "DurationMicrosecond", o[o.DurationNanosecond = -30] = "DurationNanosecond";
})(Xe || (Xe = {}));
var ps;
(function(o) {
  o[o.OFFSET = 0] = "OFFSET", o[o.DATA = 1] = "DATA", o[o.VALIDITY = 2] = "VALIDITY", o[o.TYPE = 3] = "TYPE";
})(ps || (ps = {}));
var bi = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function xi(o) {
  return o && o.__esModule && Object.prototype.hasOwnProperty.call(o, "default") ? o.default : o;
}
function Ni(o) {
  throw new Error('Could not dynamically require "' + o + '". Please configure the dynamicRequireTargets or/and ignoreDynamicRequires option of @rollup/plugin-commonjs appropriately for this require call to work.');
}
var bs = { exports: {} };
(function(o, Ke) {
  (function(oe, u) {
    o.exports = u();
  })(bi, function() {
    var oe;
    function u() {
      return oe.apply(null, arguments);
    }
    function Pe(e) {
      oe = e;
    }
    function N(e) {
      return e instanceof Array || Object.prototype.toString.call(e) === "[object Array]";
    }
    function A(e) {
      return e != null && Object.prototype.toString.call(e) === "[object Object]";
    }
    function _(e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }
    function Me(e) {
      if (Object.getOwnPropertyNames)
        return Object.getOwnPropertyNames(e).length === 0;
      var t;
      for (t in e)
        if (_(e, t))
          return !1;
      return !0;
    }
    function O(e) {
      return e === void 0;
    }
    function I(e) {
      return typeof e == "number" || Object.prototype.toString.call(e) === "[object Number]";
    }
    function re(e) {
      return e instanceof Date || Object.prototype.toString.call(e) === "[object Date]";
    }
    function De(e, t) {
      var s = [], r, a = e.length;
      for (r = 0; r < a; ++r)
        s.push(t(e[r], r));
      return s;
    }
    function F(e, t) {
      for (var s in t)
        _(t, s) && (e[s] = t[s]);
      return _(t, "toString") && (e.toString = t.toString), _(t, "valueOf") && (e.valueOf = t.valueOf), e;
    }
    function P(e, t, s, r) {
      return rs(e, t, s, r, !0).utc();
    }
    function et() {
      return {
        empty: !1,
        unusedTokens: [],
        unusedInput: [],
        overflow: -2,
        charsLeftOver: 0,
        nullInput: !1,
        invalidEra: null,
        invalidMonth: null,
        invalidFormat: !1,
        userInvalidated: !1,
        iso: !1,
        parsedDateParts: [],
        era: null,
        meridiem: null,
        rfc2822: !1,
        weekdayMismatch: !1
      };
    }
    function c(e) {
      return e._pf == null && (e._pf = et()), e._pf;
    }
    var H;
    Array.prototype.some ? H = Array.prototype.some : H = function(e) {
      var t = Object(this), s = t.length >>> 0, r;
      for (r = 0; r < s; r++)
        if (r in t && e.call(this, t[r], r, t))
          return !0;
      return !1;
    };
    function le(e) {
      var t = null, s = !1, r = e._d && !isNaN(e._d.getTime());
      if (r && (t = c(e), s = H.call(t.parsedDateParts, function(a) {
        return a != null;
      }), r = t.overflow < 0 && !t.empty && !t.invalidEra && !t.invalidMonth && !t.invalidWeekday && !t.weekdayMismatch && !t.nullInput && !t.invalidFormat && !t.userInvalidated && (!t.meridiem || t.meridiem && s), e._strict && (r = r && t.charsLeftOver === 0 && t.unusedTokens.length === 0 && t.bigHour === void 0)), Object.isFrozen == null || !Object.isFrozen(e))
        e._isValid = r;
      else
        return r;
      return e._isValid;
    }
    function We(e) {
      var t = P(NaN);
      return e != null ? F(c(t), e) : c(t).userInvalidated = !0, t;
    }
    var Wt = u.momentProperties = [], tt = !1;
    function st(e, t) {
      var s, r, a, n = Wt.length;
      if (O(t._isAMomentObject) || (e._isAMomentObject = t._isAMomentObject), O(t._i) || (e._i = t._i), O(t._f) || (e._f = t._f), O(t._l) || (e._l = t._l), O(t._strict) || (e._strict = t._strict), O(t._tzm) || (e._tzm = t._tzm), O(t._isUTC) || (e._isUTC = t._isUTC), O(t._offset) || (e._offset = t._offset), O(t._pf) || (e._pf = c(t)), O(t._locale) || (e._locale = t._locale), n > 0)
        for (s = 0; s < n; s++)
          r = Wt[s], a = t[r], O(a) || (e[r] = a);
      return e;
    }
    function ke(e) {
      st(this, e), this._d = new Date(e._d != null ? e._d.getTime() : NaN), this.isValid() || (this._d = /* @__PURE__ */ new Date(NaN)), tt === !1 && (tt = !0, u.updateOffset(this), tt = !1);
    }
    function L(e) {
      return e instanceof ke || e != null && e._isAMomentObject != null;
    }
    function Ft(e) {
      u.suppressDeprecationWarnings === !1 && typeof console < "u" && console.warn && console.warn("Deprecation warning: " + e);
    }
    function R(e, t) {
      var s = !0;
      return F(function() {
        if (u.deprecationHandler != null && u.deprecationHandler(null, e), s) {
          var r = [], a, n, i, d = arguments.length;
          for (n = 0; n < d; n++) {
            if (a = "", typeof arguments[n] == "object") {
              a += `
[` + n + "] ";
              for (i in arguments[0])
                _(arguments[0], i) && (a += i + ": " + arguments[0][i] + ", ");
              a = a.slice(0, -2);
            } else
              a = arguments[n];
            r.push(a);
          }
          Ft(
            e + `
Arguments: ` + Array.prototype.slice.call(r).join("") + `
` + new Error().stack
          ), s = !1;
        }
        return t.apply(this, arguments);
      }, t);
    }
    var Rt = {};
    function Tt(e, t) {
      u.deprecationHandler != null && u.deprecationHandler(e, t), Rt[e] || (Ft(t), Rt[e] = !0);
    }
    u.suppressDeprecationWarnings = !1, u.deprecationHandler = null;
    function V(e) {
      return typeof Function < "u" && e instanceof Function || Object.prototype.toString.call(e) === "[object Function]";
    }
    function xs(e) {
      var t, s;
      for (s in e)
        _(e, s) && (t = e[s], V(t) ? this[s] = t : this["_" + s] = t);
      this._config = e, this._dayOfMonthOrdinalParseLenient = new RegExp(
        (this._dayOfMonthOrdinalParse.source || this._ordinalParse.source) + "|" + /\d{1,2}/.source
      );
    }
    function rt(e, t) {
      var s = F({}, e), r;
      for (r in t)
        _(t, r) && (A(e[r]) && A(t[r]) ? (s[r] = {}, F(s[r], e[r]), F(s[r], t[r])) : t[r] != null ? s[r] = t[r] : delete s[r]);
      for (r in e)
        _(e, r) && !_(t, r) && A(e[r]) && (s[r] = F({}, s[r]));
      return s;
    }
    function at(e) {
      e != null && this.set(e);
    }
    var nt;
    Object.keys ? nt = Object.keys : nt = function(e) {
      var t, s = [];
      for (t in e)
        _(e, t) && s.push(t);
      return s;
    };
    var Ns = {
      sameDay: "[Today at] LT",
      nextDay: "[Tomorrow at] LT",
      nextWeek: "dddd [at] LT",
      lastDay: "[Yesterday at] LT",
      lastWeek: "[Last] dddd [at] LT",
      sameElse: "L"
    };
    function Ps(e, t, s) {
      var r = this._calendar[e] || this._calendar.sameElse;
      return V(r) ? r.call(t, s) : r;
    }
    function G(e, t, s) {
      var r = "" + Math.abs(e), a = t - r.length, n = e >= 0;
      return (n ? s ? "+" : "" : "-") + Math.pow(10, Math.max(0, a)).toString().substr(1) + r;
    }
    var it = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|N{1,5}|YYYYYY|YYYYY|YYYY|YY|y{2,4}|yo?|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g, Fe = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, ot = {}, ue = {};
    function f(e, t, s, r) {
      var a = r;
      typeof r == "string" && (a = function() {
        return this[r]();
      }), e && (ue[e] = a), t && (ue[t[0]] = function() {
        return G(a.apply(this, arguments), t[1], t[2]);
      }), s && (ue[s] = function() {
        return this.localeData().ordinal(
          a.apply(this, arguments),
          e
        );
      });
    }
    function Ws(e) {
      return e.match(/\[[\s\S]/) ? e.replace(/^\[|\]$/g, "") : e.replace(/\\/g, "");
    }
    function Fs(e) {
      var t = e.match(it), s, r;
      for (s = 0, r = t.length; s < r; s++)
        ue[t[s]] ? t[s] = ue[t[s]] : t[s] = Ws(t[s]);
      return function(a) {
        var n = "", i;
        for (i = 0; i < r; i++)
          n += V(t[i]) ? t[i].call(a, e) : t[i];
        return n;
      };
    }
    function Re(e, t) {
      return e.isValid() ? (t = Ut(t, e.localeData()), ot[t] = ot[t] || Fs(t), ot[t](e)) : e.localeData().invalidDate();
    }
    function Ut(e, t) {
      var s = 5;
      function r(a) {
        return t.longDateFormat(a) || a;
      }
      for (Fe.lastIndex = 0; s >= 0 && Fe.test(e); )
        e = e.replace(
          Fe,
          r
        ), Fe.lastIndex = 0, s -= 1;
      return e;
    }
    var Rs = {
      LTS: "h:mm:ss A",
      LT: "h:mm A",
      L: "MM/DD/YYYY",
      LL: "MMMM D, YYYY",
      LLL: "MMMM D, YYYY h:mm A",
      LLLL: "dddd, MMMM D, YYYY h:mm A"
    };
    function Ts(e) {
      var t = this._longDateFormat[e], s = this._longDateFormat[e.toUpperCase()];
      return t || !s ? t : (this._longDateFormat[e] = s.match(it).map(function(r) {
        return r === "MMMM" || r === "MM" || r === "DD" || r === "dddd" ? r.slice(1) : r;
      }).join(""), this._longDateFormat[e]);
    }
    var Us = "Invalid date";
    function Is() {
      return this._invalidDate;
    }
    var Ls = "%d", Cs = /\d{1,2}/;
    function Es(e) {
      return this._ordinal.replace("%d", e);
    }
    var As = {
      future: "in %s",
      past: "%s ago",
      s: "a few seconds",
      ss: "%d seconds",
      m: "a minute",
      mm: "%d minutes",
      h: "an hour",
      hh: "%d hours",
      d: "a day",
      dd: "%d days",
      w: "a week",
      ww: "%d weeks",
      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years"
    };
    function Hs(e, t, s, r) {
      var a = this._relativeTime[s];
      return V(a) ? a(e, t, s, r) : a.replace(/%d/i, e);
    }
    function Vs(e, t) {
      var s = this._relativeTime[e > 0 ? "future" : "past"];
      return V(s) ? s(t) : s.replace(/%s/i, t);
    }
    var It = {
      D: "date",
      dates: "date",
      date: "date",
      d: "day",
      days: "day",
      day: "day",
      e: "weekday",
      weekdays: "weekday",
      weekday: "weekday",
      E: "isoWeekday",
      isoweekdays: "isoWeekday",
      isoweekday: "isoWeekday",
      DDD: "dayOfYear",
      dayofyears: "dayOfYear",
      dayofyear: "dayOfYear",
      h: "hour",
      hours: "hour",
      hour: "hour",
      ms: "millisecond",
      milliseconds: "millisecond",
      millisecond: "millisecond",
      m: "minute",
      minutes: "minute",
      minute: "minute",
      M: "month",
      months: "month",
      month: "month",
      Q: "quarter",
      quarters: "quarter",
      quarter: "quarter",
      s: "second",
      seconds: "second",
      second: "second",
      gg: "weekYear",
      weekyears: "weekYear",
      weekyear: "weekYear",
      GG: "isoWeekYear",
      isoweekyears: "isoWeekYear",
      isoweekyear: "isoWeekYear",
      w: "week",
      weeks: "week",
      week: "week",
      W: "isoWeek",
      isoweeks: "isoWeek",
      isoweek: "isoWeek",
      y: "year",
      years: "year",
      year: "year"
    };
    function T(e) {
      return typeof e == "string" ? It[e] || It[e.toLowerCase()] : void 0;
    }
    function lt(e) {
      var t = {}, s, r;
      for (r in e)
        _(e, r) && (s = T(r), s && (t[s] = e[r]));
      return t;
    }
    var Gs = {
      date: 9,
      day: 11,
      weekday: 11,
      isoWeekday: 11,
      dayOfYear: 4,
      hour: 13,
      millisecond: 16,
      minute: 14,
      month: 8,
      quarter: 7,
      second: 15,
      weekYear: 1,
      isoWeekYear: 1,
      week: 5,
      isoWeek: 5,
      year: 1
    };
    function js(e) {
      var t = [], s;
      for (s in e)
        _(e, s) && t.push({ unit: s, priority: Gs[s] });
      return t.sort(function(r, a) {
        return r.priority - a.priority;
      }), t;
    }
    var Lt = /\d/, W = /\d\d/, Ct = /\d{3}/, ut = /\d{4}/, Te = /[+-]?\d{6}/, g = /\d\d?/, Et = /\d\d\d\d?/, At = /\d\d\d\d\d\d?/, Ue = /\d{1,3}/, dt = /\d{1,4}/, Ie = /[+-]?\d{1,6}/, de = /\d+/, Le = /[+-]?\d+/, zs = /Z|[+-]\d\d:?\d\d/gi, Ce = /Z|[+-]\d\d(?::?\d\d)?/gi, Zs = /[+-]?\d+(\.\d{1,3})?/, ge = /[0-9]{0,256}['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFF07\uFF10-\uFFEF]{1,256}|[\u0600-\u06FF\/]{1,256}(\s*?[\u0600-\u06FF]{1,256}){1,2}/i, he = /^[1-9]\d?/, ht = /^([1-9]\d|\d)/, Ee;
    Ee = {};
    function h(e, t, s) {
      Ee[e] = V(t) ? t : function(r, a) {
        return r && s ? s : t;
      };
    }
    function $s(e, t) {
      return _(Ee, e) ? Ee[e](t._strict, t._locale) : new RegExp(qs(e));
    }
    function qs(e) {
      return z(
        e.replace("\\", "").replace(
          /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
          function(t, s, r, a, n) {
            return s || r || a || n;
          }
        )
      );
    }
    function z(e) {
      return e.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
    function U(e) {
      return e < 0 ? Math.ceil(e) || 0 : Math.floor(e);
    }
    function y(e) {
      var t = +e, s = 0;
      return t !== 0 && isFinite(t) && (s = U(t)), s;
    }
    var ft = {};
    function k(e, t) {
      var s, r = t, a;
      for (typeof e == "string" && (e = [e]), I(t) && (r = function(n, i) {
        i[t] = y(n);
      }), a = e.length, s = 0; s < a; s++)
        ft[e[s]] = r;
    }
    function Se(e, t) {
      k(e, function(s, r, a, n) {
        a._w = a._w || {}, t(s, a._w, a, n);
      });
    }
    function Bs(e, t, s) {
      t != null && _(ft, e) && ft[e](t, s._a, s, e);
    }
    function Ae(e) {
      return e % 4 === 0 && e % 100 !== 0 || e % 400 === 0;
    }
    var b = 0, Z = 1, j = 2, p = 3, C = 4, $ = 5, ae = 6, Js = 7, Qs = 8;
    f("Y", 0, 0, function() {
      var e = this.year();
      return e <= 9999 ? G(e, 4) : "+" + e;
    }), f(0, ["YY", 2], 0, function() {
      return this.year() % 100;
    }), f(0, ["YYYY", 4], 0, "year"), f(0, ["YYYYY", 5], 0, "year"), f(0, ["YYYYYY", 6, !0], 0, "year"), h("Y", Le), h("YY", g, W), h("YYYY", dt, ut), h("YYYYY", Ie, Te), h("YYYYYY", Ie, Te), k(["YYYYY", "YYYYYY"], b), k("YYYY", function(e, t) {
      t[b] = e.length === 2 ? u.parseTwoDigitYear(e) : y(e);
    }), k("YY", function(e, t) {
      t[b] = u.parseTwoDigitYear(e);
    }), k("Y", function(e, t) {
      t[b] = parseInt(e, 10);
    });
    function ve(e) {
      return Ae(e) ? 366 : 365;
    }
    u.parseTwoDigitYear = function(e) {
      return y(e) + (y(e) > 68 ? 1900 : 2e3);
    };
    var Ht = fe("FullYear", !0);
    function Xs() {
      return Ae(this.year());
    }
    function fe(e, t) {
      return function(s) {
        return s != null ? (Vt(this, e, s), u.updateOffset(this, t), this) : Ye(this, e);
      };
    }
    function Ye(e, t) {
      if (!e.isValid())
        return NaN;
      var s = e._d, r = e._isUTC;
      switch (t) {
        case "Milliseconds":
          return r ? s.getUTCMilliseconds() : s.getMilliseconds();
        case "Seconds":
          return r ? s.getUTCSeconds() : s.getSeconds();
        case "Minutes":
          return r ? s.getUTCMinutes() : s.getMinutes();
        case "Hours":
          return r ? s.getUTCHours() : s.getHours();
        case "Date":
          return r ? s.getUTCDate() : s.getDate();
        case "Day":
          return r ? s.getUTCDay() : s.getDay();
        case "Month":
          return r ? s.getUTCMonth() : s.getMonth();
        case "FullYear":
          return r ? s.getUTCFullYear() : s.getFullYear();
        default:
          return NaN;
      }
    }
    function Vt(e, t, s) {
      var r, a, n, i, d;
      if (!(!e.isValid() || isNaN(s))) {
        switch (r = e._d, a = e._isUTC, t) {
          case "Milliseconds":
            return void (a ? r.setUTCMilliseconds(s) : r.setMilliseconds(s));
          case "Seconds":
            return void (a ? r.setUTCSeconds(s) : r.setSeconds(s));
          case "Minutes":
            return void (a ? r.setUTCMinutes(s) : r.setMinutes(s));
          case "Hours":
            return void (a ? r.setUTCHours(s) : r.setHours(s));
          case "Date":
            return void (a ? r.setUTCDate(s) : r.setDate(s));
          case "FullYear":
            break;
          default:
            return;
        }
        n = s, i = e.month(), d = e.date(), d = d === 29 && i === 1 && !Ae(n) ? 28 : d, a ? r.setUTCFullYear(n, i, d) : r.setFullYear(n, i, d);
      }
    }
    function Ks(e) {
      return e = T(e), V(this[e]) ? this[e]() : this;
    }
    function er(e, t) {
      if (typeof e == "object") {
        e = lt(e);
        var s = js(e), r, a = s.length;
        for (r = 0; r < a; r++)
          this[s[r].unit](e[s[r].unit]);
      } else if (e = T(e), V(this[e]))
        return this[e](t);
      return this;
    }
    function tr(e, t) {
      return (e % t + t) % t;
    }
    var Y;
    Array.prototype.indexOf ? Y = Array.prototype.indexOf : Y = function(e) {
      var t;
      for (t = 0; t < this.length; ++t)
        if (this[t] === e)
          return t;
      return -1;
    };
    function ct(e, t) {
      if (isNaN(e) || isNaN(t))
        return NaN;
      var s = tr(t, 12);
      return e += (t - s) / 12, s === 1 ? Ae(e) ? 29 : 28 : 31 - s % 7 % 2;
    }
    f("M", ["MM", 2], "Mo", function() {
      return this.month() + 1;
    }), f("MMM", 0, 0, function(e) {
      return this.localeData().monthsShort(this, e);
    }), f("MMMM", 0, 0, function(e) {
      return this.localeData().months(this, e);
    }), h("M", g, he), h("MM", g, W), h("MMM", function(e, t) {
      return t.monthsShortRegex(e);
    }), h("MMMM", function(e, t) {
      return t.monthsRegex(e);
    }), k(["M", "MM"], function(e, t) {
      t[Z] = y(e) - 1;
    }), k(["MMM", "MMMM"], function(e, t, s, r) {
      var a = s._locale.monthsParse(e, r, s._strict);
      a != null ? t[Z] = a : c(s).invalidMonth = e;
    });
    var sr = "January_February_March_April_May_June_July_August_September_October_November_December".split(
      "_"
    ), Gt = "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), jt = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?/, rr = ge, ar = ge;
    function nr(e, t) {
      return e ? N(this._months) ? this._months[e.month()] : this._months[(this._months.isFormat || jt).test(t) ? "format" : "standalone"][e.month()] : N(this._months) ? this._months : this._months.standalone;
    }
    function ir(e, t) {
      return e ? N(this._monthsShort) ? this._monthsShort[e.month()] : this._monthsShort[jt.test(t) ? "format" : "standalone"][e.month()] : N(this._monthsShort) ? this._monthsShort : this._monthsShort.standalone;
    }
    function or(e, t, s) {
      var r, a, n, i = e.toLocaleLowerCase();
      if (!this._monthsParse)
        for (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = [], r = 0; r < 12; ++r)
          n = P([2e3, r]), this._shortMonthsParse[r] = this.monthsShort(
            n,
            ""
          ).toLocaleLowerCase(), this._longMonthsParse[r] = this.months(n, "").toLocaleLowerCase();
      return s ? t === "MMM" ? (a = Y.call(this._shortMonthsParse, i), a !== -1 ? a : null) : (a = Y.call(this._longMonthsParse, i), a !== -1 ? a : null) : t === "MMM" ? (a = Y.call(this._shortMonthsParse, i), a !== -1 ? a : (a = Y.call(this._longMonthsParse, i), a !== -1 ? a : null)) : (a = Y.call(this._longMonthsParse, i), a !== -1 ? a : (a = Y.call(this._shortMonthsParse, i), a !== -1 ? a : null));
    }
    function lr(e, t, s) {
      var r, a, n;
      if (this._monthsParseExact)
        return or.call(this, e, t, s);
      for (this._monthsParse || (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = []), r = 0; r < 12; r++) {
        if (a = P([2e3, r]), s && !this._longMonthsParse[r] && (this._longMonthsParse[r] = new RegExp(
          "^" + this.months(a, "").replace(".", "") + "$",
          "i"
        ), this._shortMonthsParse[r] = new RegExp(
          "^" + this.monthsShort(a, "").replace(".", "") + "$",
          "i"
        )), !s && !this._monthsParse[r] && (n = "^" + this.months(a, "") + "|^" + this.monthsShort(a, ""), this._monthsParse[r] = new RegExp(n.replace(".", ""), "i")), s && t === "MMMM" && this._longMonthsParse[r].test(e))
          return r;
        if (s && t === "MMM" && this._shortMonthsParse[r].test(e))
          return r;
        if (!s && this._monthsParse[r].test(e))
          return r;
      }
    }
    function zt(e, t) {
      if (!e.isValid())
        return e;
      if (typeof t == "string") {
        if (/^\d+$/.test(t))
          t = y(t);
        else if (t = e.localeData().monthsParse(t), !I(t))
          return e;
      }
      var s = t, r = e.date();
      return r = r < 29 ? r : Math.min(r, ct(e.year(), s)), e._isUTC ? e._d.setUTCMonth(s, r) : e._d.setMonth(s, r), e;
    }
    function Zt(e) {
      return e != null ? (zt(this, e), u.updateOffset(this, !0), this) : Ye(this, "Month");
    }
    function ur() {
      return ct(this.year(), this.month());
    }
    function dr(e) {
      return this._monthsParseExact ? (_(this, "_monthsRegex") || $t.call(this), e ? this._monthsShortStrictRegex : this._monthsShortRegex) : (_(this, "_monthsShortRegex") || (this._monthsShortRegex = rr), this._monthsShortStrictRegex && e ? this._monthsShortStrictRegex : this._monthsShortRegex);
    }
    function hr(e) {
      return this._monthsParseExact ? (_(this, "_monthsRegex") || $t.call(this), e ? this._monthsStrictRegex : this._monthsRegex) : (_(this, "_monthsRegex") || (this._monthsRegex = ar), this._monthsStrictRegex && e ? this._monthsStrictRegex : this._monthsRegex);
    }
    function $t() {
      function e(m, w) {
        return w.length - m.length;
      }
      var t = [], s = [], r = [], a, n, i, d;
      for (a = 0; a < 12; a++)
        n = P([2e3, a]), i = z(this.monthsShort(n, "")), d = z(this.months(n, "")), t.push(i), s.push(d), r.push(d), r.push(i);
      t.sort(e), s.sort(e), r.sort(e), this._monthsRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._monthsShortRegex = this._monthsRegex, this._monthsStrictRegex = new RegExp(
        "^(" + s.join("|") + ")",
        "i"
      ), this._monthsShortStrictRegex = new RegExp(
        "^(" + t.join("|") + ")",
        "i"
      );
    }
    function fr(e, t, s, r, a, n, i) {
      var d;
      return e < 100 && e >= 0 ? (d = new Date(e + 400, t, s, r, a, n, i), isFinite(d.getFullYear()) && d.setFullYear(e)) : d = new Date(e, t, s, r, a, n, i), d;
    }
    function Oe(e) {
      var t, s;
      return e < 100 && e >= 0 ? (s = Array.prototype.slice.call(arguments), s[0] = e + 400, t = new Date(Date.UTC.apply(null, s)), isFinite(t.getUTCFullYear()) && t.setUTCFullYear(e)) : t = new Date(Date.UTC.apply(null, arguments)), t;
    }
    function He(e, t, s) {
      var r = 7 + t - s, a = (7 + Oe(e, 0, r).getUTCDay() - t) % 7;
      return -a + r - 1;
    }
    function qt(e, t, s, r, a) {
      var n = (7 + s - r) % 7, i = He(e, r, a), d = 1 + 7 * (t - 1) + n + i, m, w;
      return d <= 0 ? (m = e - 1, w = ve(m) + d) : d > ve(e) ? (m = e + 1, w = d - ve(e)) : (m = e, w = d), {
        year: m,
        dayOfYear: w
      };
    }
    function pe(e, t, s) {
      var r = He(e.year(), t, s), a = Math.floor((e.dayOfYear() - r - 1) / 7) + 1, n, i;
      return a < 1 ? (i = e.year() - 1, n = a + q(i, t, s)) : a > q(e.year(), t, s) ? (n = a - q(e.year(), t, s), i = e.year() + 1) : (i = e.year(), n = a), {
        week: n,
        year: i
      };
    }
    function q(e, t, s) {
      var r = He(e, t, s), a = He(e + 1, t, s);
      return (ve(e) - r + a) / 7;
    }
    f("w", ["ww", 2], "wo", "week"), f("W", ["WW", 2], "Wo", "isoWeek"), h("w", g, he), h("ww", g, W), h("W", g, he), h("WW", g, W), Se(
      ["w", "ww", "W", "WW"],
      function(e, t, s, r) {
        t[r.substr(0, 1)] = y(e);
      }
    );
    function cr(e) {
      return pe(e, this._week.dow, this._week.doy).week;
    }
    var mr = {
      dow: 0,
      // Sunday is the first day of the week.
      doy: 6
      // The week that contains Jan 6th is the first week of the year.
    };
    function _r() {
      return this._week.dow;
    }
    function yr() {
      return this._week.doy;
    }
    function wr(e) {
      var t = this.localeData().week(this);
      return e == null ? t : this.add((e - t) * 7, "d");
    }
    function Mr(e) {
      var t = pe(this, 1, 4).week;
      return e == null ? t : this.add((e - t) * 7, "d");
    }
    f("d", 0, "do", "day"), f("dd", 0, 0, function(e) {
      return this.localeData().weekdaysMin(this, e);
    }), f("ddd", 0, 0, function(e) {
      return this.localeData().weekdaysShort(this, e);
    }), f("dddd", 0, 0, function(e) {
      return this.localeData().weekdays(this, e);
    }), f("e", 0, 0, "weekday"), f("E", 0, 0, "isoWeekday"), h("d", g), h("e", g), h("E", g), h("dd", function(e, t) {
      return t.weekdaysMinRegex(e);
    }), h("ddd", function(e, t) {
      return t.weekdaysShortRegex(e);
    }), h("dddd", function(e, t) {
      return t.weekdaysRegex(e);
    }), Se(["dd", "ddd", "dddd"], function(e, t, s, r) {
      var a = s._locale.weekdaysParse(e, r, s._strict);
      a != null ? t.d = a : c(s).invalidWeekday = e;
    }), Se(["d", "e", "E"], function(e, t, s, r) {
      t[r] = y(e);
    });
    function Dr(e, t) {
      return typeof e != "string" ? e : isNaN(e) ? (e = t.weekdaysParse(e), typeof e == "number" ? e : null) : parseInt(e, 10);
    }
    function kr(e, t) {
      return typeof e == "string" ? t.weekdaysParse(e) % 7 || 7 : isNaN(e) ? null : e;
    }
    function mt(e, t) {
      return e.slice(t, 7).concat(e.slice(0, t));
    }
    var gr = "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), Bt = "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"), Sr = "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), vr = ge, Yr = ge, Or = ge;
    function pr(e, t) {
      var s = N(this._weekdays) ? this._weekdays : this._weekdays[e && e !== !0 && this._weekdays.isFormat.test(t) ? "format" : "standalone"];
      return e === !0 ? mt(s, this._week.dow) : e ? s[e.day()] : s;
    }
    function br(e) {
      return e === !0 ? mt(this._weekdaysShort, this._week.dow) : e ? this._weekdaysShort[e.day()] : this._weekdaysShort;
    }
    function xr(e) {
      return e === !0 ? mt(this._weekdaysMin, this._week.dow) : e ? this._weekdaysMin[e.day()] : this._weekdaysMin;
    }
    function Nr(e, t, s) {
      var r, a, n, i = e.toLocaleLowerCase();
      if (!this._weekdaysParse)
        for (this._weekdaysParse = [], this._shortWeekdaysParse = [], this._minWeekdaysParse = [], r = 0; r < 7; ++r)
          n = P([2e3, 1]).day(r), this._minWeekdaysParse[r] = this.weekdaysMin(
            n,
            ""
          ).toLocaleLowerCase(), this._shortWeekdaysParse[r] = this.weekdaysShort(
            n,
            ""
          ).toLocaleLowerCase(), this._weekdaysParse[r] = this.weekdays(n, "").toLocaleLowerCase();
      return s ? t === "dddd" ? (a = Y.call(this._weekdaysParse, i), a !== -1 ? a : null) : t === "ddd" ? (a = Y.call(this._shortWeekdaysParse, i), a !== -1 ? a : null) : (a = Y.call(this._minWeekdaysParse, i), a !== -1 ? a : null) : t === "dddd" ? (a = Y.call(this._weekdaysParse, i), a !== -1 || (a = Y.call(this._shortWeekdaysParse, i), a !== -1) ? a : (a = Y.call(this._minWeekdaysParse, i), a !== -1 ? a : null)) : t === "ddd" ? (a = Y.call(this._shortWeekdaysParse, i), a !== -1 || (a = Y.call(this._weekdaysParse, i), a !== -1) ? a : (a = Y.call(this._minWeekdaysParse, i), a !== -1 ? a : null)) : (a = Y.call(this._minWeekdaysParse, i), a !== -1 || (a = Y.call(this._weekdaysParse, i), a !== -1) ? a : (a = Y.call(this._shortWeekdaysParse, i), a !== -1 ? a : null));
    }
    function Pr(e, t, s) {
      var r, a, n;
      if (this._weekdaysParseExact)
        return Nr.call(this, e, t, s);
      for (this._weekdaysParse || (this._weekdaysParse = [], this._minWeekdaysParse = [], this._shortWeekdaysParse = [], this._fullWeekdaysParse = []), r = 0; r < 7; r++) {
        if (a = P([2e3, 1]).day(r), s && !this._fullWeekdaysParse[r] && (this._fullWeekdaysParse[r] = new RegExp(
          "^" + this.weekdays(a, "").replace(".", "\\.?") + "$",
          "i"
        ), this._shortWeekdaysParse[r] = new RegExp(
          "^" + this.weekdaysShort(a, "").replace(".", "\\.?") + "$",
          "i"
        ), this._minWeekdaysParse[r] = new RegExp(
          "^" + this.weekdaysMin(a, "").replace(".", "\\.?") + "$",
          "i"
        )), this._weekdaysParse[r] || (n = "^" + this.weekdays(a, "") + "|^" + this.weekdaysShort(a, "") + "|^" + this.weekdaysMin(a, ""), this._weekdaysParse[r] = new RegExp(n.replace(".", ""), "i")), s && t === "dddd" && this._fullWeekdaysParse[r].test(e))
          return r;
        if (s && t === "ddd" && this._shortWeekdaysParse[r].test(e))
          return r;
        if (s && t === "dd" && this._minWeekdaysParse[r].test(e))
          return r;
        if (!s && this._weekdaysParse[r].test(e))
          return r;
      }
    }
    function Wr(e) {
      if (!this.isValid())
        return e != null ? this : NaN;
      var t = Ye(this, "Day");
      return e != null ? (e = Dr(e, this.localeData()), this.add(e - t, "d")) : t;
    }
    function Fr(e) {
      if (!this.isValid())
        return e != null ? this : NaN;
      var t = (this.day() + 7 - this.localeData()._week.dow) % 7;
      return e == null ? t : this.add(e - t, "d");
    }
    function Rr(e) {
      if (!this.isValid())
        return e != null ? this : NaN;
      if (e != null) {
        var t = kr(e, this.localeData());
        return this.day(this.day() % 7 ? t : t - 7);
      } else
        return this.day() || 7;
    }
    function Tr(e) {
      return this._weekdaysParseExact ? (_(this, "_weekdaysRegex") || _t.call(this), e ? this._weekdaysStrictRegex : this._weekdaysRegex) : (_(this, "_weekdaysRegex") || (this._weekdaysRegex = vr), this._weekdaysStrictRegex && e ? this._weekdaysStrictRegex : this._weekdaysRegex);
    }
    function Ur(e) {
      return this._weekdaysParseExact ? (_(this, "_weekdaysRegex") || _t.call(this), e ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex) : (_(this, "_weekdaysShortRegex") || (this._weekdaysShortRegex = Yr), this._weekdaysShortStrictRegex && e ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex);
    }
    function Ir(e) {
      return this._weekdaysParseExact ? (_(this, "_weekdaysRegex") || _t.call(this), e ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex) : (_(this, "_weekdaysMinRegex") || (this._weekdaysMinRegex = Or), this._weekdaysMinStrictRegex && e ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex);
    }
    function _t() {
      function e(x, K) {
        return K.length - x.length;
      }
      var t = [], s = [], r = [], a = [], n, i, d, m, w;
      for (n = 0; n < 7; n++)
        i = P([2e3, 1]).day(n), d = z(this.weekdaysMin(i, "")), m = z(this.weekdaysShort(i, "")), w = z(this.weekdays(i, "")), t.push(d), s.push(m), r.push(w), a.push(d), a.push(m), a.push(w);
      t.sort(e), s.sort(e), r.sort(e), a.sort(e), this._weekdaysRegex = new RegExp("^(" + a.join("|") + ")", "i"), this._weekdaysShortRegex = this._weekdaysRegex, this._weekdaysMinRegex = this._weekdaysRegex, this._weekdaysStrictRegex = new RegExp(
        "^(" + r.join("|") + ")",
        "i"
      ), this._weekdaysShortStrictRegex = new RegExp(
        "^(" + s.join("|") + ")",
        "i"
      ), this._weekdaysMinStrictRegex = new RegExp(
        "^(" + t.join("|") + ")",
        "i"
      );
    }
    function yt() {
      return this.hours() % 12 || 12;
    }
    function Lr() {
      return this.hours() || 24;
    }
    f("H", ["HH", 2], 0, "hour"), f("h", ["hh", 2], 0, yt), f("k", ["kk", 2], 0, Lr), f("hmm", 0, 0, function() {
      return "" + yt.apply(this) + G(this.minutes(), 2);
    }), f("hmmss", 0, 0, function() {
      return "" + yt.apply(this) + G(this.minutes(), 2) + G(this.seconds(), 2);
    }), f("Hmm", 0, 0, function() {
      return "" + this.hours() + G(this.minutes(), 2);
    }), f("Hmmss", 0, 0, function() {
      return "" + this.hours() + G(this.minutes(), 2) + G(this.seconds(), 2);
    });
    function Jt(e, t) {
      f(e, 0, 0, function() {
        return this.localeData().meridiem(
          this.hours(),
          this.minutes(),
          t
        );
      });
    }
    Jt("a", !0), Jt("A", !1);
    function Qt(e, t) {
      return t._meridiemParse;
    }
    h("a", Qt), h("A", Qt), h("H", g, ht), h("h", g, he), h("k", g, he), h("HH", g, W), h("hh", g, W), h("kk", g, W), h("hmm", Et), h("hmmss", At), h("Hmm", Et), h("Hmmss", At), k(["H", "HH"], p), k(["k", "kk"], function(e, t, s) {
      var r = y(e);
      t[p] = r === 24 ? 0 : r;
    }), k(["a", "A"], function(e, t, s) {
      s._isPm = s._locale.isPM(e), s._meridiem = e;
    }), k(["h", "hh"], function(e, t, s) {
      t[p] = y(e), c(s).bigHour = !0;
    }), k("hmm", function(e, t, s) {
      var r = e.length - 2;
      t[p] = y(e.substr(0, r)), t[C] = y(e.substr(r)), c(s).bigHour = !0;
    }), k("hmmss", function(e, t, s) {
      var r = e.length - 4, a = e.length - 2;
      t[p] = y(e.substr(0, r)), t[C] = y(e.substr(r, 2)), t[$] = y(e.substr(a)), c(s).bigHour = !0;
    }), k("Hmm", function(e, t, s) {
      var r = e.length - 2;
      t[p] = y(e.substr(0, r)), t[C] = y(e.substr(r));
    }), k("Hmmss", function(e, t, s) {
      var r = e.length - 4, a = e.length - 2;
      t[p] = y(e.substr(0, r)), t[C] = y(e.substr(r, 2)), t[$] = y(e.substr(a));
    });
    function Cr(e) {
      return (e + "").toLowerCase().charAt(0) === "p";
    }
    var Er = /[ap]\.?m?\.?/i, Ar = fe("Hours", !0);
    function Hr(e, t, s) {
      return e > 11 ? s ? "pm" : "PM" : s ? "am" : "AM";
    }
    var Xt = {
      calendar: Ns,
      longDateFormat: Rs,
      invalidDate: Us,
      ordinal: Ls,
      dayOfMonthOrdinalParse: Cs,
      relativeTime: As,
      months: sr,
      monthsShort: Gt,
      week: mr,
      weekdays: gr,
      weekdaysMin: Sr,
      weekdaysShort: Bt,
      meridiemParse: Er
    }, v = {}, be = {}, xe;
    function Vr(e, t) {
      var s, r = Math.min(e.length, t.length);
      for (s = 0; s < r; s += 1)
        if (e[s] !== t[s])
          return s;
      return r;
    }
    function Kt(e) {
      return e && e.toLowerCase().replace("_", "-");
    }
    function Gr(e) {
      for (var t = 0, s, r, a, n; t < e.length; ) {
        for (n = Kt(e[t]).split("-"), s = n.length, r = Kt(e[t + 1]), r = r ? r.split("-") : null; s > 0; ) {
          if (a = Ve(n.slice(0, s).join("-")), a)
            return a;
          if (r && r.length >= s && Vr(n, r) >= s - 1)
            break;
          s--;
        }
        t++;
      }
      return xe;
    }
    function jr(e) {
      return !!(e && e.match("^[^/\\\\]*$"));
    }
    function Ve(e) {
      var t = null, s;
      if (v[e] === void 0 && o && o.exports && jr(e))
        try {
          t = xe._abbr, s = Ni, s("./locale/" + e), ee(t);
        } catch {
          v[e] = null;
        }
      return v[e];
    }
    function ee(e, t) {
      var s;
      return e && (O(t) ? s = B(e) : s = wt(e, t), s ? xe = s : typeof console < "u" && console.warn && console.warn(
        "Locale " + e + " not found. Did you forget to load it?"
      )), xe._abbr;
    }
    function wt(e, t) {
      if (t !== null) {
        var s, r = Xt;
        if (t.abbr = e, v[e] != null)
          Tt(
            "defineLocaleOverride",
            "use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale See http://momentjs.com/guides/#/warnings/define-locale/ for more info."
          ), r = v[e]._config;
        else if (t.parentLocale != null)
          if (v[t.parentLocale] != null)
            r = v[t.parentLocale]._config;
          else if (s = Ve(t.parentLocale), s != null)
            r = s._config;
          else
            return be[t.parentLocale] || (be[t.parentLocale] = []), be[t.parentLocale].push({
              name: e,
              config: t
            }), null;
        return v[e] = new at(rt(r, t)), be[e] && be[e].forEach(function(a) {
          wt(a.name, a.config);
        }), ee(e), v[e];
      } else
        return delete v[e], null;
    }
    function zr(e, t) {
      if (t != null) {
        var s, r, a = Xt;
        v[e] != null && v[e].parentLocale != null ? v[e].set(rt(v[e]._config, t)) : (r = Ve(e), r != null && (a = r._config), t = rt(a, t), r == null && (t.abbr = e), s = new at(t), s.parentLocale = v[e], v[e] = s), ee(e);
      } else
        v[e] != null && (v[e].parentLocale != null ? (v[e] = v[e].parentLocale, e === ee() && ee(e)) : v[e] != null && delete v[e]);
      return v[e];
    }
    function B(e) {
      var t;
      if (e && e._locale && e._locale._abbr && (e = e._locale._abbr), !e)
        return xe;
      if (!N(e)) {
        if (t = Ve(e), t)
          return t;
        e = [e];
      }
      return Gr(e);
    }
    function Zr() {
      return nt(v);
    }
    function Mt(e) {
      var t, s = e._a;
      return s && c(e).overflow === -2 && (t = s[Z] < 0 || s[Z] > 11 ? Z : s[j] < 1 || s[j] > ct(s[b], s[Z]) ? j : s[p] < 0 || s[p] > 24 || s[p] === 24 && (s[C] !== 0 || s[$] !== 0 || s[ae] !== 0) ? p : s[C] < 0 || s[C] > 59 ? C : s[$] < 0 || s[$] > 59 ? $ : s[ae] < 0 || s[ae] > 999 ? ae : -1, c(e)._overflowDayOfYear && (t < b || t > j) && (t = j), c(e)._overflowWeeks && t === -1 && (t = Js), c(e)._overflowWeekday && t === -1 && (t = Qs), c(e).overflow = t), e;
    }
    var $r = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, qr = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d|))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, Br = /Z|[+-]\d\d(?::?\d\d)?/, Ge = [
      ["YYYYYY-MM-DD", /[+-]\d{6}-\d\d-\d\d/],
      ["YYYY-MM-DD", /\d{4}-\d\d-\d\d/],
      ["GGGG-[W]WW-E", /\d{4}-W\d\d-\d/],
      ["GGGG-[W]WW", /\d{4}-W\d\d/, !1],
      ["YYYY-DDD", /\d{4}-\d{3}/],
      ["YYYY-MM", /\d{4}-\d\d/, !1],
      ["YYYYYYMMDD", /[+-]\d{10}/],
      ["YYYYMMDD", /\d{8}/],
      ["GGGG[W]WWE", /\d{4}W\d{3}/],
      ["GGGG[W]WW", /\d{4}W\d{2}/, !1],
      ["YYYYDDD", /\d{7}/],
      ["YYYYMM", /\d{6}/, !1],
      ["YYYY", /\d{4}/, !1]
    ], Dt = [
      ["HH:mm:ss.SSSS", /\d\d:\d\d:\d\d\.\d+/],
      ["HH:mm:ss,SSSS", /\d\d:\d\d:\d\d,\d+/],
      ["HH:mm:ss", /\d\d:\d\d:\d\d/],
      ["HH:mm", /\d\d:\d\d/],
      ["HHmmss.SSSS", /\d\d\d\d\d\d\.\d+/],
      ["HHmmss,SSSS", /\d\d\d\d\d\d,\d+/],
      ["HHmmss", /\d\d\d\d\d\d/],
      ["HHmm", /\d\d\d\d/],
      ["HH", /\d\d/]
    ], Jr = /^\/?Date\((-?\d+)/i, Qr = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|([+-]\d{4}))$/, Xr = {
      UT: 0,
      GMT: 0,
      EDT: -4 * 60,
      EST: -5 * 60,
      CDT: -5 * 60,
      CST: -6 * 60,
      MDT: -6 * 60,
      MST: -7 * 60,
      PDT: -7 * 60,
      PST: -8 * 60
    };
    function es(e) {
      var t, s, r = e._i, a = $r.exec(r) || qr.exec(r), n, i, d, m, w = Ge.length, x = Dt.length;
      if (a) {
        for (c(e).iso = !0, t = 0, s = w; t < s; t++)
          if (Ge[t][1].exec(a[1])) {
            i = Ge[t][0], n = Ge[t][2] !== !1;
            break;
          }
        if (i == null) {
          e._isValid = !1;
          return;
        }
        if (a[3]) {
          for (t = 0, s = x; t < s; t++)
            if (Dt[t][1].exec(a[3])) {
              d = (a[2] || " ") + Dt[t][0];
              break;
            }
          if (d == null) {
            e._isValid = !1;
            return;
          }
        }
        if (!n && d != null) {
          e._isValid = !1;
          return;
        }
        if (a[4])
          if (Br.exec(a[4]))
            m = "Z";
          else {
            e._isValid = !1;
            return;
          }
        e._f = i + (d || "") + (m || ""), gt(e);
      } else
        e._isValid = !1;
    }
    function Kr(e, t, s, r, a, n) {
      var i = [
        ea(e),
        Gt.indexOf(t),
        parseInt(s, 10),
        parseInt(r, 10),
        parseInt(a, 10)
      ];
      return n && i.push(parseInt(n, 10)), i;
    }
    function ea(e) {
      var t = parseInt(e, 10);
      return t <= 49 ? 2e3 + t : t <= 999 ? 1900 + t : t;
    }
    function ta(e) {
      return e.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").replace(/^\s\s*/, "").replace(/\s\s*$/, "");
    }
    function sa(e, t, s) {
      if (e) {
        var r = Bt.indexOf(e), a = new Date(
          t[0],
          t[1],
          t[2]
        ).getDay();
        if (r !== a)
          return c(s).weekdayMismatch = !0, s._isValid = !1, !1;
      }
      return !0;
    }
    function ra(e, t, s) {
      if (e)
        return Xr[e];
      if (t)
        return 0;
      var r = parseInt(s, 10), a = r % 100, n = (r - a) / 100;
      return n * 60 + a;
    }
    function ts(e) {
      var t = Qr.exec(ta(e._i)), s;
      if (t) {
        if (s = Kr(
          t[4],
          t[3],
          t[2],
          t[5],
          t[6],
          t[7]
        ), !sa(t[1], s, e))
          return;
        e._a = s, e._tzm = ra(t[8], t[9], t[10]), e._d = Oe.apply(null, e._a), e._d.setUTCMinutes(e._d.getUTCMinutes() - e._tzm), c(e).rfc2822 = !0;
      } else
        e._isValid = !1;
    }
    function aa(e) {
      var t = Jr.exec(e._i);
      if (t !== null) {
        e._d = /* @__PURE__ */ new Date(+t[1]);
        return;
      }
      if (es(e), e._isValid === !1)
        delete e._isValid;
      else
        return;
      if (ts(e), e._isValid === !1)
        delete e._isValid;
      else
        return;
      e._strict ? e._isValid = !1 : u.createFromInputFallback(e);
    }
    u.createFromInputFallback = R(
      "value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged. Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.",
      function(e) {
        e._d = /* @__PURE__ */ new Date(e._i + (e._useUTC ? " UTC" : ""));
      }
    );
    function ce(e, t, s) {
      return e ?? t ?? s;
    }
    function na(e) {
      var t = new Date(u.now());
      return e._useUTC ? [
        t.getUTCFullYear(),
        t.getUTCMonth(),
        t.getUTCDate()
      ] : [t.getFullYear(), t.getMonth(), t.getDate()];
    }
    function kt(e) {
      var t, s, r = [], a, n, i;
      if (!e._d) {
        for (a = na(e), e._w && e._a[j] == null && e._a[Z] == null && ia(e), e._dayOfYear != null && (i = ce(e._a[b], a[b]), (e._dayOfYear > ve(i) || e._dayOfYear === 0) && (c(e)._overflowDayOfYear = !0), s = Oe(i, 0, e._dayOfYear), e._a[Z] = s.getUTCMonth(), e._a[j] = s.getUTCDate()), t = 0; t < 3 && e._a[t] == null; ++t)
          e._a[t] = r[t] = a[t];
        for (; t < 7; t++)
          e._a[t] = r[t] = e._a[t] == null ? t === 2 ? 1 : 0 : e._a[t];
        e._a[p] === 24 && e._a[C] === 0 && e._a[$] === 0 && e._a[ae] === 0 && (e._nextDay = !0, e._a[p] = 0), e._d = (e._useUTC ? Oe : fr).apply(
          null,
          r
        ), n = e._useUTC ? e._d.getUTCDay() : e._d.getDay(), e._tzm != null && e._d.setUTCMinutes(e._d.getUTCMinutes() - e._tzm), e._nextDay && (e._a[p] = 24), e._w && typeof e._w.d < "u" && e._w.d !== n && (c(e).weekdayMismatch = !0);
      }
    }
    function ia(e) {
      var t, s, r, a, n, i, d, m, w;
      t = e._w, t.GG != null || t.W != null || t.E != null ? (n = 1, i = 4, s = ce(
        t.GG,
        e._a[b],
        pe(S(), 1, 4).year
      ), r = ce(t.W, 1), a = ce(t.E, 1), (a < 1 || a > 7) && (m = !0)) : (n = e._locale._week.dow, i = e._locale._week.doy, w = pe(S(), n, i), s = ce(t.gg, e._a[b], w.year), r = ce(t.w, w.week), t.d != null ? (a = t.d, (a < 0 || a > 6) && (m = !0)) : t.e != null ? (a = t.e + n, (t.e < 0 || t.e > 6) && (m = !0)) : a = n), r < 1 || r > q(s, n, i) ? c(e)._overflowWeeks = !0 : m != null ? c(e)._overflowWeekday = !0 : (d = qt(s, r, a, n, i), e._a[b] = d.year, e._dayOfYear = d.dayOfYear);
    }
    u.ISO_8601 = function() {
    }, u.RFC_2822 = function() {
    };
    function gt(e) {
      if (e._f === u.ISO_8601) {
        es(e);
        return;
      }
      if (e._f === u.RFC_2822) {
        ts(e);
        return;
      }
      e._a = [], c(e).empty = !0;
      var t = "" + e._i, s, r, a, n, i, d = t.length, m = 0, w, x;
      for (a = Ut(e._f, e._locale).match(it) || [], x = a.length, s = 0; s < x; s++)
        n = a[s], r = (t.match($s(n, e)) || [])[0], r && (i = t.substr(0, t.indexOf(r)), i.length > 0 && c(e).unusedInput.push(i), t = t.slice(
          t.indexOf(r) + r.length
        ), m += r.length), ue[n] ? (r ? c(e).empty = !1 : c(e).unusedTokens.push(n), Bs(n, r, e)) : e._strict && !r && c(e).unusedTokens.push(n);
      c(e).charsLeftOver = d - m, t.length > 0 && c(e).unusedInput.push(t), e._a[p] <= 12 && c(e).bigHour === !0 && e._a[p] > 0 && (c(e).bigHour = void 0), c(e).parsedDateParts = e._a.slice(0), c(e).meridiem = e._meridiem, e._a[p] = oa(
        e._locale,
        e._a[p],
        e._meridiem
      ), w = c(e).era, w !== null && (e._a[b] = e._locale.erasConvertYear(w, e._a[b])), kt(e), Mt(e);
    }
    function oa(e, t, s) {
      var r;
      return s == null ? t : e.meridiemHour != null ? e.meridiemHour(t, s) : (e.isPM != null && (r = e.isPM(s), r && t < 12 && (t += 12), !r && t === 12 && (t = 0)), t);
    }
    function la(e) {
      var t, s, r, a, n, i, d = !1, m = e._f.length;
      if (m === 0) {
        c(e).invalidFormat = !0, e._d = /* @__PURE__ */ new Date(NaN);
        return;
      }
      for (a = 0; a < m; a++)
        n = 0, i = !1, t = st({}, e), e._useUTC != null && (t._useUTC = e._useUTC), t._f = e._f[a], gt(t), le(t) && (i = !0), n += c(t).charsLeftOver, n += c(t).unusedTokens.length * 10, c(t).score = n, d ? n < r && (r = n, s = t) : (r == null || n < r || i) && (r = n, s = t, i && (d = !0));
      F(e, s || t);
    }
    function ua(e) {
      if (!e._d) {
        var t = lt(e._i), s = t.day === void 0 ? t.date : t.day;
        e._a = De(
          [t.year, t.month, s, t.hour, t.minute, t.second, t.millisecond],
          function(r) {
            return r && parseInt(r, 10);
          }
        ), kt(e);
      }
    }
    function da(e) {
      var t = new ke(Mt(ss(e)));
      return t._nextDay && (t.add(1, "d"), t._nextDay = void 0), t;
    }
    function ss(e) {
      var t = e._i, s = e._f;
      return e._locale = e._locale || B(e._l), t === null || s === void 0 && t === "" ? We({ nullInput: !0 }) : (typeof t == "string" && (e._i = t = e._locale.preparse(t)), L(t) ? new ke(Mt(t)) : (re(t) ? e._d = t : N(s) ? la(e) : s ? gt(e) : ha(e), le(e) || (e._d = null), e));
    }
    function ha(e) {
      var t = e._i;
      O(t) ? e._d = new Date(u.now()) : re(t) ? e._d = new Date(t.valueOf()) : typeof t == "string" ? aa(e) : N(t) ? (e._a = De(t.slice(0), function(s) {
        return parseInt(s, 10);
      }), kt(e)) : A(t) ? ua(e) : I(t) ? e._d = new Date(t) : u.createFromInputFallback(e);
    }
    function rs(e, t, s, r, a) {
      var n = {};
      return (t === !0 || t === !1) && (r = t, t = void 0), (s === !0 || s === !1) && (r = s, s = void 0), (A(e) && Me(e) || N(e) && e.length === 0) && (e = void 0), n._isAMomentObject = !0, n._useUTC = n._isUTC = a, n._l = s, n._i = e, n._f = t, n._strict = r, da(n);
    }
    function S(e, t, s, r) {
      return rs(e, t, s, r, !1);
    }
    var fa = R(
      "moment().min is deprecated, use moment.max instead. http://momentjs.com/guides/#/warnings/min-max/",
      function() {
        var e = S.apply(null, arguments);
        return this.isValid() && e.isValid() ? e < this ? this : e : We();
      }
    ), ca = R(
      "moment().max is deprecated, use moment.min instead. http://momentjs.com/guides/#/warnings/min-max/",
      function() {
        var e = S.apply(null, arguments);
        return this.isValid() && e.isValid() ? e > this ? this : e : We();
      }
    );
    function as(e, t) {
      var s, r;
      if (t.length === 1 && N(t[0]) && (t = t[0]), !t.length)
        return S();
      for (s = t[0], r = 1; r < t.length; ++r)
        (!t[r].isValid() || t[r][e](s)) && (s = t[r]);
      return s;
    }
    function ma() {
      var e = [].slice.call(arguments, 0);
      return as("isBefore", e);
    }
    function _a() {
      var e = [].slice.call(arguments, 0);
      return as("isAfter", e);
    }
    var ya = function() {
      return Date.now ? Date.now() : +/* @__PURE__ */ new Date();
    }, Ne = [
      "year",
      "quarter",
      "month",
      "week",
      "day",
      "hour",
      "minute",
      "second",
      "millisecond"
    ];
    function wa(e) {
      var t, s = !1, r, a = Ne.length;
      for (t in e)
        if (_(e, t) && !(Y.call(Ne, t) !== -1 && (e[t] == null || !isNaN(e[t]))))
          return !1;
      for (r = 0; r < a; ++r)
        if (e[Ne[r]]) {
          if (s)
            return !1;
          parseFloat(e[Ne[r]]) !== y(e[Ne[r]]) && (s = !0);
        }
      return !0;
    }
    function Ma() {
      return this._isValid;
    }
    function Da() {
      return E(NaN);
    }
    function je(e) {
      var t = lt(e), s = t.year || 0, r = t.quarter || 0, a = t.month || 0, n = t.week || t.isoWeek || 0, i = t.day || 0, d = t.hour || 0, m = t.minute || 0, w = t.second || 0, x = t.millisecond || 0;
      this._isValid = wa(t), this._milliseconds = +x + w * 1e3 + // 1000
      m * 6e4 + // 1000 * 60
      d * 1e3 * 60 * 60, this._days = +i + n * 7, this._months = +a + r * 3 + s * 12, this._data = {}, this._locale = B(), this._bubble();
    }
    function ze(e) {
      return e instanceof je;
    }
    function St(e) {
      return e < 0 ? Math.round(-1 * e) * -1 : Math.round(e);
    }
    function ka(e, t, s) {
      var r = Math.min(e.length, t.length), a = Math.abs(e.length - t.length), n = 0, i;
      for (i = 0; i < r; i++)
        y(e[i]) !== y(t[i]) && n++;
      return n + a;
    }
    function ns(e, t) {
      f(e, 0, 0, function() {
        var s = this.utcOffset(), r = "+";
        return s < 0 && (s = -s, r = "-"), r + G(~~(s / 60), 2) + t + G(~~s % 60, 2);
      });
    }
    ns("Z", ":"), ns("ZZ", ""), h("Z", Ce), h("ZZ", Ce), k(["Z", "ZZ"], function(e, t, s) {
      s._useUTC = !0, s._tzm = vt(Ce, e);
    });
    var ga = /([\+\-]|\d\d)/gi;
    function vt(e, t) {
      var s = (t || "").match(e), r, a, n;
      return s === null ? null : (r = s[s.length - 1] || [], a = (r + "").match(ga) || ["-", 0, 0], n = +(a[1] * 60) + y(a[2]), n === 0 ? 0 : a[0] === "+" ? n : -n);
    }
    function Yt(e, t) {
      var s, r;
      return t._isUTC ? (s = t.clone(), r = (L(e) || re(e) ? e.valueOf() : S(e).valueOf()) - s.valueOf(), s._d.setTime(s._d.valueOf() + r), u.updateOffset(s, !1), s) : S(e).local();
    }
    function Ot(e) {
      return -Math.round(e._d.getTimezoneOffset());
    }
    u.updateOffset = function() {
    };
    function Sa(e, t, s) {
      var r = this._offset || 0, a;
      if (!this.isValid())
        return e != null ? this : NaN;
      if (e != null) {
        if (typeof e == "string") {
          if (e = vt(Ce, e), e === null)
            return this;
        } else
          Math.abs(e) < 16 && !s && (e = e * 60);
        return !this._isUTC && t && (a = Ot(this)), this._offset = e, this._isUTC = !0, a != null && this.add(a, "m"), r !== e && (!t || this._changeInProgress ? us(
          this,
          E(e - r, "m"),
          1,
          !1
        ) : this._changeInProgress || (this._changeInProgress = !0, u.updateOffset(this, !0), this._changeInProgress = null)), this;
      } else
        return this._isUTC ? r : Ot(this);
    }
    function va(e, t) {
      return e != null ? (typeof e != "string" && (e = -e), this.utcOffset(e, t), this) : -this.utcOffset();
    }
    function Ya(e) {
      return this.utcOffset(0, e);
    }
    function Oa(e) {
      return this._isUTC && (this.utcOffset(0, e), this._isUTC = !1, e && this.subtract(Ot(this), "m")), this;
    }
    function pa() {
      if (this._tzm != null)
        this.utcOffset(this._tzm, !1, !0);
      else if (typeof this._i == "string") {
        var e = vt(zs, this._i);
        e != null ? this.utcOffset(e) : this.utcOffset(0, !0);
      }
      return this;
    }
    function ba(e) {
      return this.isValid() ? (e = e ? S(e).utcOffset() : 0, (this.utcOffset() - e) % 60 === 0) : !1;
    }
    function xa() {
      return this.utcOffset() > this.clone().month(0).utcOffset() || this.utcOffset() > this.clone().month(5).utcOffset();
    }
    function Na() {
      if (!O(this._isDSTShifted))
        return this._isDSTShifted;
      var e = {}, t;
      return st(e, this), e = ss(e), e._a ? (t = e._isUTC ? P(e._a) : S(e._a), this._isDSTShifted = this.isValid() && ka(e._a, t.toArray()) > 0) : this._isDSTShifted = !1, this._isDSTShifted;
    }
    function Pa() {
      return this.isValid() ? !this._isUTC : !1;
    }
    function Wa() {
      return this.isValid() ? this._isUTC : !1;
    }
    function is() {
      return this.isValid() ? this._isUTC && this._offset === 0 : !1;
    }
    var Fa = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/, Ra = /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
    function E(e, t) {
      var s = e, r = null, a, n, i;
      return ze(e) ? s = {
        ms: e._milliseconds,
        d: e._days,
        M: e._months
      } : I(e) || !isNaN(+e) ? (s = {}, t ? s[t] = +e : s.milliseconds = +e) : (r = Fa.exec(e)) ? (a = r[1] === "-" ? -1 : 1, s = {
        y: 0,
        d: y(r[j]) * a,
        h: y(r[p]) * a,
        m: y(r[C]) * a,
        s: y(r[$]) * a,
        ms: y(St(r[ae] * 1e3)) * a
        // the millisecond decimal point is included in the match
      }) : (r = Ra.exec(e)) ? (a = r[1] === "-" ? -1 : 1, s = {
        y: ne(r[2], a),
        M: ne(r[3], a),
        w: ne(r[4], a),
        d: ne(r[5], a),
        h: ne(r[6], a),
        m: ne(r[7], a),
        s: ne(r[8], a)
      }) : s == null ? s = {} : typeof s == "object" && ("from" in s || "to" in s) && (i = Ta(
        S(s.from),
        S(s.to)
      ), s = {}, s.ms = i.milliseconds, s.M = i.months), n = new je(s), ze(e) && _(e, "_locale") && (n._locale = e._locale), ze(e) && _(e, "_isValid") && (n._isValid = e._isValid), n;
    }
    E.fn = je.prototype, E.invalid = Da;
    function ne(e, t) {
      var s = e && parseFloat(e.replace(",", "."));
      return (isNaN(s) ? 0 : s) * t;
    }
    function os(e, t) {
      var s = {};
      return s.months = t.month() - e.month() + (t.year() - e.year()) * 12, e.clone().add(s.months, "M").isAfter(t) && --s.months, s.milliseconds = +t - +e.clone().add(s.months, "M"), s;
    }
    function Ta(e, t) {
      var s;
      return e.isValid() && t.isValid() ? (t = Yt(t, e), e.isBefore(t) ? s = os(e, t) : (s = os(t, e), s.milliseconds = -s.milliseconds, s.months = -s.months), s) : { milliseconds: 0, months: 0 };
    }
    function ls(e, t) {
      return function(s, r) {
        var a, n;
        return r !== null && !isNaN(+r) && (Tt(
          t,
          "moment()." + t + "(period, number) is deprecated. Please use moment()." + t + "(number, period). See http://momentjs.com/guides/#/warnings/add-inverted-param/ for more info."
        ), n = s, s = r, r = n), a = E(s, r), us(this, a, e), this;
      };
    }
    function us(e, t, s, r) {
      var a = t._milliseconds, n = St(t._days), i = St(t._months);
      e.isValid() && (r = r ?? !0, i && zt(e, Ye(e, "Month") + i * s), n && Vt(e, "Date", Ye(e, "Date") + n * s), a && e._d.setTime(e._d.valueOf() + a * s), r && u.updateOffset(e, n || i));
    }
    var Ua = ls(1, "add"), Ia = ls(-1, "subtract");
    function ds(e) {
      return typeof e == "string" || e instanceof String;
    }
    function La(e) {
      return L(e) || re(e) || ds(e) || I(e) || Ea(e) || Ca(e) || e === null || e === void 0;
    }
    function Ca(e) {
      var t = A(e) && !Me(e), s = !1, r = [
        "years",
        "year",
        "y",
        "months",
        "month",
        "M",
        "days",
        "day",
        "d",
        "dates",
        "date",
        "D",
        "hours",
        "hour",
        "h",
        "minutes",
        "minute",
        "m",
        "seconds",
        "second",
        "s",
        "milliseconds",
        "millisecond",
        "ms"
      ], a, n, i = r.length;
      for (a = 0; a < i; a += 1)
        n = r[a], s = s || _(e, n);
      return t && s;
    }
    function Ea(e) {
      var t = N(e), s = !1;
      return t && (s = e.filter(function(r) {
        return !I(r) && ds(e);
      }).length === 0), t && s;
    }
    function Aa(e) {
      var t = A(e) && !Me(e), s = !1, r = [
        "sameDay",
        "nextDay",
        "lastDay",
        "nextWeek",
        "lastWeek",
        "sameElse"
      ], a, n;
      for (a = 0; a < r.length; a += 1)
        n = r[a], s = s || _(e, n);
      return t && s;
    }
    function Ha(e, t) {
      var s = e.diff(t, "days", !0);
      return s < -6 ? "sameElse" : s < -1 ? "lastWeek" : s < 0 ? "lastDay" : s < 1 ? "sameDay" : s < 2 ? "nextDay" : s < 7 ? "nextWeek" : "sameElse";
    }
    function Va(e, t) {
      arguments.length === 1 && (arguments[0] ? La(arguments[0]) ? (e = arguments[0], t = void 0) : Aa(arguments[0]) && (t = arguments[0], e = void 0) : (e = void 0, t = void 0));
      var s = e || S(), r = Yt(s, this).startOf("day"), a = u.calendarFormat(this, r) || "sameElse", n = t && (V(t[a]) ? t[a].call(this, s) : t[a]);
      return this.format(
        n || this.localeData().calendar(a, this, S(s))
      );
    }
    function Ga() {
      return new ke(this);
    }
    function ja(e, t) {
      var s = L(e) ? e : S(e);
      return this.isValid() && s.isValid() ? (t = T(t) || "millisecond", t === "millisecond" ? this.valueOf() > s.valueOf() : s.valueOf() < this.clone().startOf(t).valueOf()) : !1;
    }
    function za(e, t) {
      var s = L(e) ? e : S(e);
      return this.isValid() && s.isValid() ? (t = T(t) || "millisecond", t === "millisecond" ? this.valueOf() < s.valueOf() : this.clone().endOf(t).valueOf() < s.valueOf()) : !1;
    }
    function Za(e, t, s, r) {
      var a = L(e) ? e : S(e), n = L(t) ? t : S(t);
      return this.isValid() && a.isValid() && n.isValid() ? (r = r || "()", (r[0] === "(" ? this.isAfter(a, s) : !this.isBefore(a, s)) && (r[1] === ")" ? this.isBefore(n, s) : !this.isAfter(n, s))) : !1;
    }
    function $a(e, t) {
      var s = L(e) ? e : S(e), r;
      return this.isValid() && s.isValid() ? (t = T(t) || "millisecond", t === "millisecond" ? this.valueOf() === s.valueOf() : (r = s.valueOf(), this.clone().startOf(t).valueOf() <= r && r <= this.clone().endOf(t).valueOf())) : !1;
    }
    function qa(e, t) {
      return this.isSame(e, t) || this.isAfter(e, t);
    }
    function Ba(e, t) {
      return this.isSame(e, t) || this.isBefore(e, t);
    }
    function Ja(e, t, s) {
      var r, a, n;
      if (!this.isValid())
        return NaN;
      if (r = Yt(e, this), !r.isValid())
        return NaN;
      switch (a = (r.utcOffset() - this.utcOffset()) * 6e4, t = T(t), t) {
        case "year":
          n = Ze(this, r) / 12;
          break;
        case "month":
          n = Ze(this, r);
          break;
        case "quarter":
          n = Ze(this, r) / 3;
          break;
        case "second":
          n = (this - r) / 1e3;
          break;
        case "minute":
          n = (this - r) / 6e4;
          break;
        case "hour":
          n = (this - r) / 36e5;
          break;
        case "day":
          n = (this - r - a) / 864e5;
          break;
        case "week":
          n = (this - r - a) / 6048e5;
          break;
        default:
          n = this - r;
      }
      return s ? n : U(n);
    }
    function Ze(e, t) {
      if (e.date() < t.date())
        return -Ze(t, e);
      var s = (t.year() - e.year()) * 12 + (t.month() - e.month()), r = e.clone().add(s, "months"), a, n;
      return t - r < 0 ? (a = e.clone().add(s - 1, "months"), n = (t - r) / (r - a)) : (a = e.clone().add(s + 1, "months"), n = (t - r) / (a - r)), -(s + n) || 0;
    }
    u.defaultFormat = "YYYY-MM-DDTHH:mm:ssZ", u.defaultFormatUtc = "YYYY-MM-DDTHH:mm:ss[Z]";
    function Qa() {
      return this.clone().locale("en").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    }
    function Xa(e) {
      if (!this.isValid())
        return null;
      var t = e !== !0, s = t ? this.clone().utc() : this;
      return s.year() < 0 || s.year() > 9999 ? Re(
        s,
        t ? "YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYYYY-MM-DD[T]HH:mm:ss.SSSZ"
      ) : V(Date.prototype.toISOString) ? t ? this.toDate().toISOString() : new Date(this.valueOf() + this.utcOffset() * 60 * 1e3).toISOString().replace("Z", Re(s, "Z")) : Re(
        s,
        t ? "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYY-MM-DD[T]HH:mm:ss.SSSZ"
      );
    }
    function Ka() {
      if (!this.isValid())
        return "moment.invalid(/* " + this._i + " */)";
      var e = "moment", t = "", s, r, a, n;
      return this.isLocal() || (e = this.utcOffset() === 0 ? "moment.utc" : "moment.parseZone", t = "Z"), s = "[" + e + '("]', r = 0 <= this.year() && this.year() <= 9999 ? "YYYY" : "YYYYYY", a = "-MM-DD[T]HH:mm:ss.SSS", n = t + '[")]', this.format(s + r + a + n);
    }
    function en(e) {
      e || (e = this.isUtc() ? u.defaultFormatUtc : u.defaultFormat);
      var t = Re(this, e);
      return this.localeData().postformat(t);
    }
    function tn(e, t) {
      return this.isValid() && (L(e) && e.isValid() || S(e).isValid()) ? E({ to: this, from: e }).locale(this.locale()).humanize(!t) : this.localeData().invalidDate();
    }
    function sn(e) {
      return this.from(S(), e);
    }
    function rn(e, t) {
      return this.isValid() && (L(e) && e.isValid() || S(e).isValid()) ? E({ from: this, to: e }).locale(this.locale()).humanize(!t) : this.localeData().invalidDate();
    }
    function an(e) {
      return this.to(S(), e);
    }
    function hs(e) {
      var t;
      return e === void 0 ? this._locale._abbr : (t = B(e), t != null && (this._locale = t), this);
    }
    var fs = R(
      "moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.",
      function(e) {
        return e === void 0 ? this.localeData() : this.locale(e);
      }
    );
    function cs() {
      return this._locale;
    }
    var $e = 1e3, me = 60 * $e, qe = 60 * me, ms = (365 * 400 + 97) * 24 * qe;
    function _e(e, t) {
      return (e % t + t) % t;
    }
    function _s(e, t, s) {
      return e < 100 && e >= 0 ? new Date(e + 400, t, s) - ms : new Date(e, t, s).valueOf();
    }
    function ys(e, t, s) {
      return e < 100 && e >= 0 ? Date.UTC(e + 400, t, s) - ms : Date.UTC(e, t, s);
    }
    function nn(e) {
      var t, s;
      if (e = T(e), e === void 0 || e === "millisecond" || !this.isValid())
        return this;
      switch (s = this._isUTC ? ys : _s, e) {
        case "year":
          t = s(this.year(), 0, 1);
          break;
        case "quarter":
          t = s(
            this.year(),
            this.month() - this.month() % 3,
            1
          );
          break;
        case "month":
          t = s(this.year(), this.month(), 1);
          break;
        case "week":
          t = s(
            this.year(),
            this.month(),
            this.date() - this.weekday()
          );
          break;
        case "isoWeek":
          t = s(
            this.year(),
            this.month(),
            this.date() - (this.isoWeekday() - 1)
          );
          break;
        case "day":
        case "date":
          t = s(this.year(), this.month(), this.date());
          break;
        case "hour":
          t = this._d.valueOf(), t -= _e(
            t + (this._isUTC ? 0 : this.utcOffset() * me),
            qe
          );
          break;
        case "minute":
          t = this._d.valueOf(), t -= _e(t, me);
          break;
        case "second":
          t = this._d.valueOf(), t -= _e(t, $e);
          break;
      }
      return this._d.setTime(t), u.updateOffset(this, !0), this;
    }
    function on(e) {
      var t, s;
      if (e = T(e), e === void 0 || e === "millisecond" || !this.isValid())
        return this;
      switch (s = this._isUTC ? ys : _s, e) {
        case "year":
          t = s(this.year() + 1, 0, 1) - 1;
          break;
        case "quarter":
          t = s(
            this.year(),
            this.month() - this.month() % 3 + 3,
            1
          ) - 1;
          break;
        case "month":
          t = s(this.year(), this.month() + 1, 1) - 1;
          break;
        case "week":
          t = s(
            this.year(),
            this.month(),
            this.date() - this.weekday() + 7
          ) - 1;
          break;
        case "isoWeek":
          t = s(
            this.year(),
            this.month(),
            this.date() - (this.isoWeekday() - 1) + 7
          ) - 1;
          break;
        case "day":
        case "date":
          t = s(this.year(), this.month(), this.date() + 1) - 1;
          break;
        case "hour":
          t = this._d.valueOf(), t += qe - _e(
            t + (this._isUTC ? 0 : this.utcOffset() * me),
            qe
          ) - 1;
          break;
        case "minute":
          t = this._d.valueOf(), t += me - _e(t, me) - 1;
          break;
        case "second":
          t = this._d.valueOf(), t += $e - _e(t, $e) - 1;
          break;
      }
      return this._d.setTime(t), u.updateOffset(this, !0), this;
    }
    function ln() {
      return this._d.valueOf() - (this._offset || 0) * 6e4;
    }
    function un() {
      return Math.floor(this.valueOf() / 1e3);
    }
    function dn() {
      return new Date(this.valueOf());
    }
    function hn() {
      var e = this;
      return [
        e.year(),
        e.month(),
        e.date(),
        e.hour(),
        e.minute(),
        e.second(),
        e.millisecond()
      ];
    }
    function fn() {
      var e = this;
      return {
        years: e.year(),
        months: e.month(),
        date: e.date(),
        hours: e.hours(),
        minutes: e.minutes(),
        seconds: e.seconds(),
        milliseconds: e.milliseconds()
      };
    }
    function cn() {
      return this.isValid() ? this.toISOString() : null;
    }
    function mn() {
      return le(this);
    }
    function _n() {
      return F({}, c(this));
    }
    function yn() {
      return c(this).overflow;
    }
    function wn() {
      return {
        input: this._i,
        format: this._f,
        locale: this._locale,
        isUTC: this._isUTC,
        strict: this._strict
      };
    }
    f("N", 0, 0, "eraAbbr"), f("NN", 0, 0, "eraAbbr"), f("NNN", 0, 0, "eraAbbr"), f("NNNN", 0, 0, "eraName"), f("NNNNN", 0, 0, "eraNarrow"), f("y", ["y", 1], "yo", "eraYear"), f("y", ["yy", 2], 0, "eraYear"), f("y", ["yyy", 3], 0, "eraYear"), f("y", ["yyyy", 4], 0, "eraYear"), h("N", pt), h("NN", pt), h("NNN", pt), h("NNNN", xn), h("NNNNN", Nn), k(
      ["N", "NN", "NNN", "NNNN", "NNNNN"],
      function(e, t, s, r) {
        var a = s._locale.erasParse(e, r, s._strict);
        a ? c(s).era = a : c(s).invalidEra = e;
      }
    ), h("y", de), h("yy", de), h("yyy", de), h("yyyy", de), h("yo", Pn), k(["y", "yy", "yyy", "yyyy"], b), k(["yo"], function(e, t, s, r) {
      var a;
      s._locale._eraYearOrdinalRegex && (a = e.match(s._locale._eraYearOrdinalRegex)), s._locale.eraYearOrdinalParse ? t[b] = s._locale.eraYearOrdinalParse(e, a) : t[b] = parseInt(e, 10);
    });
    function Mn(e, t) {
      var s, r, a, n = this._eras || B("en")._eras;
      for (s = 0, r = n.length; s < r; ++s) {
        switch (typeof n[s].since) {
          case "string":
            a = u(n[s].since).startOf("day"), n[s].since = a.valueOf();
            break;
        }
        switch (typeof n[s].until) {
          case "undefined":
            n[s].until = 1 / 0;
            break;
          case "string":
            a = u(n[s].until).startOf("day").valueOf(), n[s].until = a.valueOf();
            break;
        }
      }
      return n;
    }
    function Dn(e, t, s) {
      var r, a, n = this.eras(), i, d, m;
      for (e = e.toUpperCase(), r = 0, a = n.length; r < a; ++r)
        if (i = n[r].name.toUpperCase(), d = n[r].abbr.toUpperCase(), m = n[r].narrow.toUpperCase(), s)
          switch (t) {
            case "N":
            case "NN":
            case "NNN":
              if (d === e)
                return n[r];
              break;
            case "NNNN":
              if (i === e)
                return n[r];
              break;
            case "NNNNN":
              if (m === e)
                return n[r];
              break;
          }
        else if ([i, d, m].indexOf(e) >= 0)
          return n[r];
    }
    function kn(e, t) {
      var s = e.since <= e.until ? 1 : -1;
      return t === void 0 ? u(e.since).year() : u(e.since).year() + (t - e.offset) * s;
    }
    function gn() {
      var e, t, s, r = this.localeData().eras();
      for (e = 0, t = r.length; e < t; ++e)
        if (s = this.clone().startOf("day").valueOf(), r[e].since <= s && s <= r[e].until || r[e].until <= s && s <= r[e].since)
          return r[e].name;
      return "";
    }
    function Sn() {
      var e, t, s, r = this.localeData().eras();
      for (e = 0, t = r.length; e < t; ++e)
        if (s = this.clone().startOf("day").valueOf(), r[e].since <= s && s <= r[e].until || r[e].until <= s && s <= r[e].since)
          return r[e].narrow;
      return "";
    }
    function vn() {
      var e, t, s, r = this.localeData().eras();
      for (e = 0, t = r.length; e < t; ++e)
        if (s = this.clone().startOf("day").valueOf(), r[e].since <= s && s <= r[e].until || r[e].until <= s && s <= r[e].since)
          return r[e].abbr;
      return "";
    }
    function Yn() {
      var e, t, s, r, a = this.localeData().eras();
      for (e = 0, t = a.length; e < t; ++e)
        if (s = a[e].since <= a[e].until ? 1 : -1, r = this.clone().startOf("day").valueOf(), a[e].since <= r && r <= a[e].until || a[e].until <= r && r <= a[e].since)
          return (this.year() - u(a[e].since).year()) * s + a[e].offset;
      return this.year();
    }
    function On(e) {
      return _(this, "_erasNameRegex") || bt.call(this), e ? this._erasNameRegex : this._erasRegex;
    }
    function pn(e) {
      return _(this, "_erasAbbrRegex") || bt.call(this), e ? this._erasAbbrRegex : this._erasRegex;
    }
    function bn(e) {
      return _(this, "_erasNarrowRegex") || bt.call(this), e ? this._erasNarrowRegex : this._erasRegex;
    }
    function pt(e, t) {
      return t.erasAbbrRegex(e);
    }
    function xn(e, t) {
      return t.erasNameRegex(e);
    }
    function Nn(e, t) {
      return t.erasNarrowRegex(e);
    }
    function Pn(e, t) {
      return t._eraYearOrdinalRegex || de;
    }
    function bt() {
      var e = [], t = [], s = [], r = [], a, n, i, d, m, w = this.eras();
      for (a = 0, n = w.length; a < n; ++a)
        i = z(w[a].name), d = z(w[a].abbr), m = z(w[a].narrow), t.push(i), e.push(d), s.push(m), r.push(i), r.push(d), r.push(m);
      this._erasRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._erasNameRegex = new RegExp("^(" + t.join("|") + ")", "i"), this._erasAbbrRegex = new RegExp("^(" + e.join("|") + ")", "i"), this._erasNarrowRegex = new RegExp(
        "^(" + s.join("|") + ")",
        "i"
      );
    }
    f(0, ["gg", 2], 0, function() {
      return this.weekYear() % 100;
    }), f(0, ["GG", 2], 0, function() {
      return this.isoWeekYear() % 100;
    });
    function Be(e, t) {
      f(0, [e, e.length], 0, t);
    }
    Be("gggg", "weekYear"), Be("ggggg", "weekYear"), Be("GGGG", "isoWeekYear"), Be("GGGGG", "isoWeekYear"), h("G", Le), h("g", Le), h("GG", g, W), h("gg", g, W), h("GGGG", dt, ut), h("gggg", dt, ut), h("GGGGG", Ie, Te), h("ggggg", Ie, Te), Se(
      ["gggg", "ggggg", "GGGG", "GGGGG"],
      function(e, t, s, r) {
        t[r.substr(0, 2)] = y(e);
      }
    ), Se(["gg", "GG"], function(e, t, s, r) {
      t[r] = u.parseTwoDigitYear(e);
    });
    function Wn(e) {
      return ws.call(
        this,
        e,
        this.week(),
        this.weekday() + this.localeData()._week.dow,
        this.localeData()._week.dow,
        this.localeData()._week.doy
      );
    }
    function Fn(e) {
      return ws.call(
        this,
        e,
        this.isoWeek(),
        this.isoWeekday(),
        1,
        4
      );
    }
    function Rn() {
      return q(this.year(), 1, 4);
    }
    function Tn() {
      return q(this.isoWeekYear(), 1, 4);
    }
    function Un() {
      var e = this.localeData()._week;
      return q(this.year(), e.dow, e.doy);
    }
    function In() {
      var e = this.localeData()._week;
      return q(this.weekYear(), e.dow, e.doy);
    }
    function ws(e, t, s, r, a) {
      var n;
      return e == null ? pe(this, r, a).year : (n = q(e, r, a), t > n && (t = n), Ln.call(this, e, t, s, r, a));
    }
    function Ln(e, t, s, r, a) {
      var n = qt(e, t, s, r, a), i = Oe(n.year, 0, n.dayOfYear);
      return this.year(i.getUTCFullYear()), this.month(i.getUTCMonth()), this.date(i.getUTCDate()), this;
    }
    f("Q", 0, "Qo", "quarter"), h("Q", Lt), k("Q", function(e, t) {
      t[Z] = (y(e) - 1) * 3;
    });
    function Cn(e) {
      return e == null ? Math.ceil((this.month() + 1) / 3) : this.month((e - 1) * 3 + this.month() % 3);
    }
    f("D", ["DD", 2], "Do", "date"), h("D", g, he), h("DD", g, W), h("Do", function(e, t) {
      return e ? t._dayOfMonthOrdinalParse || t._ordinalParse : t._dayOfMonthOrdinalParseLenient;
    }), k(["D", "DD"], j), k("Do", function(e, t) {
      t[j] = y(e.match(g)[0]);
    });
    var Ms = fe("Date", !0);
    f("DDD", ["DDDD", 3], "DDDo", "dayOfYear"), h("DDD", Ue), h("DDDD", Ct), k(["DDD", "DDDD"], function(e, t, s) {
      s._dayOfYear = y(e);
    });
    function En(e) {
      var t = Math.round(
        (this.clone().startOf("day") - this.clone().startOf("year")) / 864e5
      ) + 1;
      return e == null ? t : this.add(e - t, "d");
    }
    f("m", ["mm", 2], 0, "minute"), h("m", g, ht), h("mm", g, W), k(["m", "mm"], C);
    var An = fe("Minutes", !1);
    f("s", ["ss", 2], 0, "second"), h("s", g, ht), h("ss", g, W), k(["s", "ss"], $);
    var Hn = fe("Seconds", !1);
    f("S", 0, 0, function() {
      return ~~(this.millisecond() / 100);
    }), f(0, ["SS", 2], 0, function() {
      return ~~(this.millisecond() / 10);
    }), f(0, ["SSS", 3], 0, "millisecond"), f(0, ["SSSS", 4], 0, function() {
      return this.millisecond() * 10;
    }), f(0, ["SSSSS", 5], 0, function() {
      return this.millisecond() * 100;
    }), f(0, ["SSSSSS", 6], 0, function() {
      return this.millisecond() * 1e3;
    }), f(0, ["SSSSSSS", 7], 0, function() {
      return this.millisecond() * 1e4;
    }), f(0, ["SSSSSSSS", 8], 0, function() {
      return this.millisecond() * 1e5;
    }), f(0, ["SSSSSSSSS", 9], 0, function() {
      return this.millisecond() * 1e6;
    }), h("S", Ue, Lt), h("SS", Ue, W), h("SSS", Ue, Ct);
    var te, Ds;
    for (te = "SSSS"; te.length <= 9; te += "S")
      h(te, de);
    function Vn(e, t) {
      t[ae] = y(("0." + e) * 1e3);
    }
    for (te = "S"; te.length <= 9; te += "S")
      k(te, Vn);
    Ds = fe("Milliseconds", !1), f("z", 0, 0, "zoneAbbr"), f("zz", 0, 0, "zoneName");
    function Gn() {
      return this._isUTC ? "UTC" : "";
    }
    function jn() {
      return this._isUTC ? "Coordinated Universal Time" : "";
    }
    var l = ke.prototype;
    l.add = Ua, l.calendar = Va, l.clone = Ga, l.diff = Ja, l.endOf = on, l.format = en, l.from = tn, l.fromNow = sn, l.to = rn, l.toNow = an, l.get = Ks, l.invalidAt = yn, l.isAfter = ja, l.isBefore = za, l.isBetween = Za, l.isSame = $a, l.isSameOrAfter = qa, l.isSameOrBefore = Ba, l.isValid = mn, l.lang = fs, l.locale = hs, l.localeData = cs, l.max = ca, l.min = fa, l.parsingFlags = _n, l.set = er, l.startOf = nn, l.subtract = Ia, l.toArray = hn, l.toObject = fn, l.toDate = dn, l.toISOString = Xa, l.inspect = Ka, typeof Symbol < "u" && Symbol.for != null && (l[Symbol.for("nodejs.util.inspect.custom")] = function() {
      return "Moment<" + this.format() + ">";
    }), l.toJSON = cn, l.toString = Qa, l.unix = un, l.valueOf = ln, l.creationData = wn, l.eraName = gn, l.eraNarrow = Sn, l.eraAbbr = vn, l.eraYear = Yn, l.year = Ht, l.isLeapYear = Xs, l.weekYear = Wn, l.isoWeekYear = Fn, l.quarter = l.quarters = Cn, l.month = Zt, l.daysInMonth = ur, l.week = l.weeks = wr, l.isoWeek = l.isoWeeks = Mr, l.weeksInYear = Un, l.weeksInWeekYear = In, l.isoWeeksInYear = Rn, l.isoWeeksInISOWeekYear = Tn, l.date = Ms, l.day = l.days = Wr, l.weekday = Fr, l.isoWeekday = Rr, l.dayOfYear = En, l.hour = l.hours = Ar, l.minute = l.minutes = An, l.second = l.seconds = Hn, l.millisecond = l.milliseconds = Ds, l.utcOffset = Sa, l.utc = Ya, l.local = Oa, l.parseZone = pa, l.hasAlignedHourOffset = ba, l.isDST = xa, l.isLocal = Pa, l.isUtcOffset = Wa, l.isUtc = is, l.isUTC = is, l.zoneAbbr = Gn, l.zoneName = jn, l.dates = R(
      "dates accessor is deprecated. Use date instead.",
      Ms
    ), l.months = R(
      "months accessor is deprecated. Use month instead",
      Zt
    ), l.years = R(
      "years accessor is deprecated. Use year instead",
      Ht
    ), l.zone = R(
      "moment().zone is deprecated, use moment().utcOffset instead. http://momentjs.com/guides/#/warnings/zone/",
      va
    ), l.isDSTShifted = R(
      "isDSTShifted is deprecated. See http://momentjs.com/guides/#/warnings/dst-shifted/ for more information",
      Na
    );
    function zn(e) {
      return S(e * 1e3);
    }
    function Zn() {
      return S.apply(null, arguments).parseZone();
    }
    function ks(e) {
      return e;
    }
    var D = at.prototype;
    D.calendar = Ps, D.longDateFormat = Ts, D.invalidDate = Is, D.ordinal = Es, D.preparse = ks, D.postformat = ks, D.relativeTime = Hs, D.pastFuture = Vs, D.set = xs, D.eras = Mn, D.erasParse = Dn, D.erasConvertYear = kn, D.erasAbbrRegex = pn, D.erasNameRegex = On, D.erasNarrowRegex = bn, D.months = nr, D.monthsShort = ir, D.monthsParse = lr, D.monthsRegex = hr, D.monthsShortRegex = dr, D.week = cr, D.firstDayOfYear = yr, D.firstDayOfWeek = _r, D.weekdays = pr, D.weekdaysMin = xr, D.weekdaysShort = br, D.weekdaysParse = Pr, D.weekdaysRegex = Tr, D.weekdaysShortRegex = Ur, D.weekdaysMinRegex = Ir, D.isPM = Cr, D.meridiem = Hr;
    function Je(e, t, s, r) {
      var a = B(), n = P().set(r, t);
      return a[s](n, e);
    }
    function gs(e, t, s) {
      if (I(e) && (t = e, e = void 0), e = e || "", t != null)
        return Je(e, t, s, "month");
      var r, a = [];
      for (r = 0; r < 12; r++)
        a[r] = Je(e, r, s, "month");
      return a;
    }
    function xt(e, t, s, r) {
      typeof e == "boolean" ? (I(t) && (s = t, t = void 0), t = t || "") : (t = e, s = t, e = !1, I(t) && (s = t, t = void 0), t = t || "");
      var a = B(), n = e ? a._week.dow : 0, i, d = [];
      if (s != null)
        return Je(t, (s + n) % 7, r, "day");
      for (i = 0; i < 7; i++)
        d[i] = Je(t, (i + n) % 7, r, "day");
      return d;
    }
    function $n(e, t) {
      return gs(e, t, "months");
    }
    function qn(e, t) {
      return gs(e, t, "monthsShort");
    }
    function Bn(e, t, s) {
      return xt(e, t, s, "weekdays");
    }
    function Jn(e, t, s) {
      return xt(e, t, s, "weekdaysShort");
    }
    function Qn(e, t, s) {
      return xt(e, t, s, "weekdaysMin");
    }
    ee("en", {
      eras: [
        {
          since: "0001-01-01",
          until: 1 / 0,
          offset: 1,
          name: "Anno Domini",
          narrow: "AD",
          abbr: "AD"
        },
        {
          since: "0000-12-31",
          until: -1 / 0,
          offset: 1,
          name: "Before Christ",
          narrow: "BC",
          abbr: "BC"
        }
      ],
      dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
      ordinal: function(e) {
        var t = e % 10, s = y(e % 100 / 10) === 1 ? "th" : t === 1 ? "st" : t === 2 ? "nd" : t === 3 ? "rd" : "th";
        return e + s;
      }
    }), u.lang = R(
      "moment.lang is deprecated. Use moment.locale instead.",
      ee
    ), u.langData = R(
      "moment.langData is deprecated. Use moment.localeData instead.",
      B
    );
    var J = Math.abs;
    function Xn() {
      var e = this._data;
      return this._milliseconds = J(this._milliseconds), this._days = J(this._days), this._months = J(this._months), e.milliseconds = J(e.milliseconds), e.seconds = J(e.seconds), e.minutes = J(e.minutes), e.hours = J(e.hours), e.months = J(e.months), e.years = J(e.years), this;
    }
    function Ss(e, t, s, r) {
      var a = E(t, s);
      return e._milliseconds += r * a._milliseconds, e._days += r * a._days, e._months += r * a._months, e._bubble();
    }
    function Kn(e, t) {
      return Ss(this, e, t, 1);
    }
    function ei(e, t) {
      return Ss(this, e, t, -1);
    }
    function vs(e) {
      return e < 0 ? Math.floor(e) : Math.ceil(e);
    }
    function ti() {
      var e = this._milliseconds, t = this._days, s = this._months, r = this._data, a, n, i, d, m;
      return e >= 0 && t >= 0 && s >= 0 || e <= 0 && t <= 0 && s <= 0 || (e += vs(Nt(s) + t) * 864e5, t = 0, s = 0), r.milliseconds = e % 1e3, a = U(e / 1e3), r.seconds = a % 60, n = U(a / 60), r.minutes = n % 60, i = U(n / 60), r.hours = i % 24, t += U(i / 24), m = U(Ys(t)), s += m, t -= vs(Nt(m)), d = U(s / 12), s %= 12, r.days = t, r.months = s, r.years = d, this;
    }
    function Ys(e) {
      return e * 4800 / 146097;
    }
    function Nt(e) {
      return e * 146097 / 4800;
    }
    function si(e) {
      if (!this.isValid())
        return NaN;
      var t, s, r = this._milliseconds;
      if (e = T(e), e === "month" || e === "quarter" || e === "year")
        switch (t = this._days + r / 864e5, s = this._months + Ys(t), e) {
          case "month":
            return s;
          case "quarter":
            return s / 3;
          case "year":
            return s / 12;
        }
      else
        switch (t = this._days + Math.round(Nt(this._months)), e) {
          case "week":
            return t / 7 + r / 6048e5;
          case "day":
            return t + r / 864e5;
          case "hour":
            return t * 24 + r / 36e5;
          case "minute":
            return t * 1440 + r / 6e4;
          case "second":
            return t * 86400 + r / 1e3;
          case "millisecond":
            return Math.floor(t * 864e5) + r;
          default:
            throw new Error("Unknown unit " + e);
        }
    }
    function Q(e) {
      return function() {
        return this.as(e);
      };
    }
    var Os = Q("ms"), ri = Q("s"), ai = Q("m"), ni = Q("h"), ii = Q("d"), oi = Q("w"), li = Q("M"), ui = Q("Q"), di = Q("y"), hi = Os;
    function fi() {
      return E(this);
    }
    function ci(e) {
      return e = T(e), this.isValid() ? this[e + "s"]() : NaN;
    }
    function ie(e) {
      return function() {
        return this.isValid() ? this._data[e] : NaN;
      };
    }
    var mi = ie("milliseconds"), _i = ie("seconds"), yi = ie("minutes"), wi = ie("hours"), Mi = ie("days"), Di = ie("months"), ki = ie("years");
    function gi() {
      return U(this.days() / 7);
    }
    var X = Math.round, ye = {
      ss: 44,
      // a few seconds to seconds
      s: 45,
      // seconds to minute
      m: 45,
      // minutes to hour
      h: 22,
      // hours to day
      d: 26,
      // days to month/week
      w: null,
      // weeks to month
      M: 11
      // months to year
    };
    function Si(e, t, s, r, a) {
      return a.relativeTime(t || 1, !!s, e, r);
    }
    function vi(e, t, s, r) {
      var a = E(e).abs(), n = X(a.as("s")), i = X(a.as("m")), d = X(a.as("h")), m = X(a.as("d")), w = X(a.as("M")), x = X(a.as("w")), K = X(a.as("y")), se = n <= s.ss && ["s", n] || n < s.s && ["ss", n] || i <= 1 && ["m"] || i < s.m && ["mm", i] || d <= 1 && ["h"] || d < s.h && ["hh", d] || m <= 1 && ["d"] || m < s.d && ["dd", m];
      return s.w != null && (se = se || x <= 1 && ["w"] || x < s.w && ["ww", x]), se = se || w <= 1 && ["M"] || w < s.M && ["MM", w] || K <= 1 && ["y"] || ["yy", K], se[2] = t, se[3] = +e > 0, se[4] = r, Si.apply(null, se);
    }
    function Yi(e) {
      return e === void 0 ? X : typeof e == "function" ? (X = e, !0) : !1;
    }
    function Oi(e, t) {
      return ye[e] === void 0 ? !1 : t === void 0 ? ye[e] : (ye[e] = t, e === "s" && (ye.ss = t - 1), !0);
    }
    function pi(e, t) {
      if (!this.isValid())
        return this.localeData().invalidDate();
      var s = !1, r = ye, a, n;
      return typeof e == "object" && (t = e, e = !1), typeof e == "boolean" && (s = e), typeof t == "object" && (r = Object.assign({}, ye, t), t.s != null && t.ss == null && (r.ss = t.s - 1)), a = this.localeData(), n = vi(this, !s, r, a), s && (n = a.pastFuture(+this, n)), a.postformat(n);
    }
    var Pt = Math.abs;
    function we(e) {
      return (e > 0) - (e < 0) || +e;
    }
    function Qe() {
      if (!this.isValid())
        return this.localeData().invalidDate();
      var e = Pt(this._milliseconds) / 1e3, t = Pt(this._days), s = Pt(this._months), r, a, n, i, d = this.asSeconds(), m, w, x, K;
      return d ? (r = U(e / 60), a = U(r / 60), e %= 60, r %= 60, n = U(s / 12), s %= 12, i = e ? e.toFixed(3).replace(/\.?0+$/, "") : "", m = d < 0 ? "-" : "", w = we(this._months) !== we(d) ? "-" : "", x = we(this._days) !== we(d) ? "-" : "", K = we(this._milliseconds) !== we(d) ? "-" : "", m + "P" + (n ? w + n + "Y" : "") + (s ? w + s + "M" : "") + (t ? x + t + "D" : "") + (a || r || e ? "T" : "") + (a ? K + a + "H" : "") + (r ? K + r + "M" : "") + (e ? K + i + "S" : "")) : "P0D";
    }
    var M = je.prototype;
    M.isValid = Ma, M.abs = Xn, M.add = Kn, M.subtract = ei, M.as = si, M.asMilliseconds = Os, M.asSeconds = ri, M.asMinutes = ai, M.asHours = ni, M.asDays = ii, M.asWeeks = oi, M.asMonths = li, M.asQuarters = ui, M.asYears = di, M.valueOf = hi, M._bubble = ti, M.clone = fi, M.get = ci, M.milliseconds = mi, M.seconds = _i, M.minutes = yi, M.hours = wi, M.days = Mi, M.weeks = gi, M.months = Di, M.years = ki, M.humanize = pi, M.toISOString = Qe, M.toString = Qe, M.toJSON = Qe, M.locale = hs, M.localeData = cs, M.toIsoString = R(
      "toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)",
      Qe
    ), M.lang = fs, f("X", 0, 0, "unix"), f("x", 0, 0, "valueOf"), h("x", Le), h("X", Zs), k("X", function(e, t, s) {
      s._d = new Date(parseFloat(e) * 1e3);
    }), k("x", function(e, t, s) {
      s._d = new Date(y(e));
    });
    //! moment.js
    return u.version = "2.30.1", Pe(S), u.fn = l, u.min = ma, u.max = _a, u.now = ya, u.utc = P, u.unix = zn, u.months = $n, u.isDate = re, u.locale = ee, u.invalid = We, u.duration = E, u.isMoment = L, u.weekdays = Bn, u.parseZone = Zn, u.localeData = B, u.isDuration = ze, u.monthsShort = qn, u.weekdaysMin = Qn, u.defineLocale = wt, u.updateLocale = zr, u.locales = Zr, u.weekdaysShort = Jn, u.normalizeUnits = T, u.relativeTimeRounding = Yi, u.relativeTimeThreshold = Oi, u.calendarFormat = Ha, u.prototype = l, u.HTML5_FMT = {
      DATETIME_LOCAL: "YYYY-MM-DDTHH:mm",
      // <input type="datetime-local" />
      DATETIME_LOCAL_SECONDS: "YYYY-MM-DDTHH:mm:ss",
      // <input type="datetime-local" step="1" />
      DATETIME_LOCAL_MS: "YYYY-MM-DDTHH:mm:ss.SSS",
      // <input type="datetime-local" step="0.001" />
      DATE: "YYYY-MM-DD",
      // <input type="date" />
      TIME: "HH:mm",
      // <input type="time" />
      TIME_SECONDS: "HH:mm:ss",
      // <input type="time" step="1" />
      TIME_MS: "HH:mm:ss.SSS",
      // <input type="time" step="0.001" />
      WEEK: "GGGG-[W]WW",
      // <input type="week" />
      MONTH: "YYYY-MM"
      // <input type="month" />
    }, u;
  });
})(bs);
var Pi = bs.exports;
const Wi = /* @__PURE__ */ xi(Pi);
async function Fi(o, Ke, oe, u = 1e3) {
  let Pe = !1;
  const N = o.toArray(), A = [];
  for (let _ = 0; _ < N.length; _ += u) {
    const Me = N.slice(_, _ + u);
    await new Promise((O) => setTimeout(O, 0));
    try {
      const O = Me.map((I) => {
        const re = I.toArray(), De = Object.fromEntries(
          o.schema.fields.map(({ name: F, type: P }, et) => {
            const c = Ke && Ke[F] || F, H = re[et];
            if (H == null)
              return [c, null];
            if (P.typeId === Xe.Decimal) {
              const le = Number(H.toString());
              return [
                c,
                isNaN(le) ? 0 : le / Math.pow(10, P.scale)
              ];
            }
            return typeof H == "bigint" ? [c, Number(H.toString())] : P.typeId === Xe.Date ? [
              c,
              Wi.utc(H).format("YYYY-MM-DD")
            ] : [c, H];
          })
        );
        return oe && !Pe ? (Pe = !0, oe.parse(De)) : De;
      });
      A.push(...O);
    } catch (O) {
      return console.error(O), [];
    }
  }
  return A;
}
export {
  Fi as arrowToArray
};
