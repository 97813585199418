import { GetCategoryVariableColorMapResponse } from '@/api/dashboards/getCategoryVariableColorMap';
import { TreeNodeLike } from '@/components/TreeSelect/useTreeSelect/types';
import { MEDIA_REGEX } from '@/config/constants';
import { FormattedContributionData } from '@/dashboards/ModelContributions/providers/ModelContributionProvider';
import { GetModelContributionResponse } from '@/dashboards/ModelContributions/types';

const groupData = ({
    categoryColorMap,
    variableColorMap,
    rows,
    mediaOnly,
}: GetModelContributionResponse &
    GetCategoryVariableColorMapResponse & {
        mediaOnly?: boolean;
    }): FormattedContributionData => {
    const treeMap: Record<string, TreeNodeLike> = {};
    const categoryData: FormattedContributionData['categoryData'] = [];
    const variableData: FormattedContributionData['variableData'] = [];
    const dates: Set<string> = new Set();

    const usedCategoryVariableCombinations = new Set<string>();

    const totalContributionByDate: Record<string, number> = {};

    const data = rows.reduce(
        (
            acc,
            { variable, category, periodName: date, contribution, spend },
        ) => {
            totalContributionByDate[date] =
                (totalContributionByDate[date] || 0) + contribution;
            if (!mediaOnly || (mediaOnly && MEDIA_REGEX.test(category))) {
                dates.add(date);

                if (!acc.dataByVariable[variable]) {
                    const color = variableColorMap[variable]!;
                    variableData.push({
                        variable,
                        category,
                        color,
                    });
                    acc.dataByVariable[variable] = {
                        data: {},
                        color,
                    };
                }

                if (!acc.dataByCategory[category]) {
                    const color = categoryColorMap[category]!;
                    categoryData.push({
                        category,
                        color,
                    });
                    acc.dataByCategory[category] = {
                        data: {},
                        color,
                    };
                    acc.totalContributionByCategory.set(category, 0);
                    acc.totalSpendByCategory.set(category, 0);
                }

                if (!acc.dataByVariable[variable]!.data[date]) {
                    acc.dataByVariable[variable]!.data[date] = {
                        contribution: 0,
                        spend: 0,
                    };
                }

                if (!acc.dataByCategory[category]!.data[date]) {
                    acc.dataByCategory[category]!.data[date] = {
                        contribution: 0,
                        spend: 0,
                    };
                }

                acc.dataByVariable[variable]!.data[date]!.contribution =
                    (acc.dataByVariable[variable]!.data[date]!.contribution ||
                        0) + contribution || contribution;

                acc.dataByVariable[variable]!.data[date]!.spend =
                    (acc.dataByVariable[variable]!.data[date]!.spend || 0) +
                        spend || spend;

                acc.dataByCategory[category]!.data[date]!.contribution =
                    (acc.dataByCategory[category]!.data[date]!.contribution ||
                        0) + contribution || contribution;

                acc.dataByCategory[category]!.data[date]!.spend =
                    (acc.dataByCategory[category]!.data[date]!.spend || 0) +
                        spend || spend;

                acc.totalContribution += contribution;
                acc.totalContributionByCategory.set(
                    category,
                    acc.totalContributionByCategory.get(category)! +
                        contribution,
                );
                acc.totalContributionByVariable.set(
                    variable,
                    (acc.totalContributionByVariable.get(variable) || 0) +
                        contribution,
                );

                acc.totalSpend += spend;
                acc.totalSpendByCategory.set(
                    category,
                    acc.totalSpendByCategory.get(category)! + spend,
                );
                acc.totalSpendByVariable.set(
                    variable,
                    (acc.totalSpendByVariable.get(variable) || 0) + spend,
                );
            }

            acc.variableToCategory.set(variable, category);

            if (
                !usedCategoryVariableCombinations.has(`${category}-${variable}`)
            ) {
                usedCategoryVariableCombinations.add(`${category}-${variable}`);
                if (!treeMap[category]) {
                    treeMap[category] = {
                        id: category,
                        label: category,
                        children: [],
                    };
                }
                treeMap[category]!.children!.push({
                    id: variable,
                    label: variable,
                });
            }
            return acc;
        },
        {
            dataByVariable: {} as Record<
                string,
                {
                    data: {
                        [key: string]: { contribution: number; spend: number };
                    };
                    color: string;
                }
            >,
            dataByCategory: {} as Record<
                string,
                {
                    data: {
                        [key: string]: { contribution: number; spend: number };
                    };
                    color: string;
                }
            >,
            totalContribution: 0,
            totalContributionByCategory: new Map<string, number>(),
            totalContributionByVariable: new Map<string, number>(),
            totalSpend: 0,
            totalSpendByCategory: new Map<string, number>(),
            totalSpendByVariable: new Map<string, number>(),
            variableToCategory: new Map<string, string>(),
            variableData: [],
            categoryData: [],
            totalContributionByDate,
        },
    );

    return {
        ...data,
        variableData,
        categoryData,
        dates: Array.from(dates),
        treeNodes: Object.values(treeMap),
    };
};

export const groupContributionData = ({
    rows,
    variableColorMap,
    categoryColorMap,
    mediaOnly,
}: Partial<GetModelContributionResponse> &
    GetCategoryVariableColorMapResponse & {
        mediaOnly?: boolean;
    }): FormattedContributionData => {
    if (!rows) {
        return {
            dataByVariable: {},
            dataByCategory: {},
            totalSpend: 0,
            totalSpendByCategory: new Map(),
            totalSpendByVariable: new Map(),
            totalContribution: 0,
            totalContributionByCategory: new Map(),
            totalContributionByVariable: new Map(),
            variableToCategory: new Map(),
            treeNodes: [],
            variableData: [],
            categoryData: [],
            dates: [],
            totalContributionByDate: {},
        };
    }

    return groupData({
        rows,
        variableColorMap,
        categoryColorMap,
        mediaOnly,
    });
};
