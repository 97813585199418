import { useCallback, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { PeriodOption } from '@/config/model';
import { modelVsActualQueryOptions } from '@/dashboards/TitleDashboard/api';
import { useTitleDashboardRoute } from '@/dashboards/TitleDashboard/providers';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { useDuckDBQuery } from '@/duckdb';
import { usePrefetchMouseHandlers } from '@/hooks/usePrefetchMouseHandlers';
import { getPeriodOptionsForModel } from '@/utils/model';

export const useModelVsActual = () => {
    const { route } = useTitleDashboardRoute();
    const queryClient = useQueryClient();

    const params = route.useParams();
    const { period } = route.useSearch();

    const { projectId } = params;

    const navigate = useNavigate();

    const queryResult = useDuckDBQuery(
        modelVsActualQueryOptions({
            period,
        }),
    );
    const {
        project: { interval },
    } = useDashboardGlobalContext();

    const availablePeriodOptions = getPeriodOptionsForModel(interval);

    useEffect(() => {
        if (!period) {
            if (interval && interval !== period) {
                queryClient.setQueryData(
                    modelVsActualQueryOptions({
                        period: interval,
                    }).queryKey,
                    queryResult.data,
                );
            }
        }
    }, [
        projectId,
        period,
        queryClient,
        queryResult.data,
        queryResult.isSuccess,
        interval,
    ]);

    const prefetch = useCallback(
        (period: PeriodOption) => {
            queryClient.prefetchQuery(
                modelVsActualQueryOptions({
                    period,
                }),
            );
        },
        [queryClient],
    );

    const getMouseHandlers = usePrefetchMouseHandlers(prefetch);

    const { data: modelVsActualData, isFetched } = queryResult;

    const periodFromResponse = modelVsActualData?.period || '';

    useEffect(() => {
        if (isFetched && periodFromResponse && period !== periodFromResponse) {
            navigate({
                search: {
                    period: periodFromResponse,
                },
                params,
                replace: true,
            });
        }
    }, [isFetched, periodFromResponse, navigate, period, params]);

    const activeTabIndex = period
        ? Math.max(availablePeriodOptions.indexOf(period) || 0, 0)
        : 0;

    const onActiveTabChange = useCallback(
        (activeTab: number) => {
            navigate({
                search: {
                    period: availablePeriodOptions[activeTab] || '',
                },
                params,
            });
        },
        [availablePeriodOptions, navigate, params],
    );

    return {
        queryResult,
        activeTabIndex,
        availablePeriodOptions,
        getMouseHandlers,
        onActiveTabChange,
    };
};
