import {
    CampaignOptimizationRoute,
    CompetitorMediaInvestmentRoute,
    ModelContributionRoute,
    SpendSummaryRoute,
    TitleDashboardRoute,
} from '@/types/routes';

export const TITLE_DASHBOARD_ROUTE: TitleDashboardRoute =
    '/_org/project/$projectId/_dashboards/';
export const MODEL_CONTRIBUTION_ROUTE: ModelContributionRoute =
    '/_org/project/$projectId/_dashboards/model-contributions';

export const SPEND_SUMMARY_ROUTE: SpendSummaryRoute =
    '/_org/project/$projectId/_dashboards/spend-summary';
export const CAMPAIGN_OPTIMIZATION_ROUTE: CampaignOptimizationRoute =
    '/_org/project/$projectId/_dashboards/campaign-optimization';

export const COMPETITOR_MEDIA_INVESTMENT_ROUTE: CompetitorMediaInvestmentRoute =
    '/_org/project/$projectId/_dashboards/competitors-media-investment';
