import { HorizontalBarGraph } from '@/components/HorizontalBarGraph';
import { WithLoader } from '@/components/WithLoader';
import { useConfigContext } from '@/context/configContext';
import { useModelContributionContext } from '@/dashboards/ModelContributions/hooks';
import { roundToDecimals } from '@/utils/numberUtils';
import { Typography } from '@analytical-alley/ui';
import React from 'react';

export const ContributionByCategory = () => {
    const { formatNumber } = useConfigContext();
    const {
        queryResult: { isLoading, isFetching },
        selected,
        handleSelectedChange,
        contributionData: {
            totalContribution,
            totalContributionByCategory,
            categoryData,
        },
        selectedFilterNodes,
    } = useModelContributionContext();

    const sortedCategoryData = categoryData.sort(
        (a, b) =>
            totalContributionByCategory.get(b.category)! -
            totalContributionByCategory.get(a.category)!,
    );

    const dataToShow = sortedCategoryData.filter(
        (value) => selectedFilterNodes[value.category],
    );

    return (
        <div className="glass-tile p-6">
            <div className="flex mb-4">
                <Typography variant="titleS">
                    Model contribution by category
                </Typography>
            </div>
            <WithLoader
                className={isLoading ? 'min-h-96' : undefined}
                isLoading={isLoading}
                isFetching={isFetching}
            >
                <HorizontalBarGraph>
                    {dataToShow.map((value) => {
                        const contribution = totalContributionByCategory.get(
                            value.category,
                        )!;
                        const percent = roundToDecimals(
                            (contribution / totalContribution) * 100,
                        );

                        return (
                            <HorizontalBarGraph.Row
                                onSelect={() => {
                                    handleSelectedChange(
                                        selected === value.category
                                            ? ''
                                            : value.category,
                                    );
                                }}
                                activeTitle={selected}
                                color={value.color}
                                key={`${value.category}-${value.category}`}
                                title={value.category}
                                percent={percent}
                                value={formatNumber(contribution)}
                            />
                        );
                    })}
                </HorizontalBarGraph>
            </WithLoader>
        </div>
    );
};
