import { getColor } from '@/components/ECharts/colors';
import { MEDIA_REGEX } from '@/config/constants';
import { modelContributionsQueries } from '@/duckdb/model-contributions';

export type GetCategoryVariableColorMapResponse = {
    categoryColorMap: Record<string, string>;
    variableColorMap: Record<string, string>;
};

export const getCategoryVariableColorMap =
    async (): Promise<GetCategoryVariableColorMapResponse> => {
        const [contributionByCategory, contributionByVariable] =
            await Promise.all([
                modelContributionsQueries.getContributionsByCategory(),
                modelContributionsQueries.getContributionsByVariable(),
            ]);

        const variableColorMap = Object.keys(contributionByVariable).reduce(
            (acc, variable, index) => {
                acc[variable] = getColor(index);
                return acc;
            },
            {} as Record<string, string>,
        );

        const categoryColorMap = Object.keys(contributionByCategory)
            .sort((categoryName) => {
                return MEDIA_REGEX.test(categoryName) ? -1 : 1;
            })
            .reduce(
                (acc, category, index) => {
                    acc[category] = getColor(index);
                    return acc;
                },
                {} as Record<string, string>,
            );

        return {
            variableColorMap,
            categoryColorMap,
        };
    };
