let c = null;
const o = {}, r = (n) => {
  c = n;
};
function a() {
  return c;
}
const t = [], u = (n) => {
  t.push(n);
}, f = (n) => {
  const e = t.indexOf(n);
  e > -1 && t.splice(e, 1);
};
function D(n, e, i) {
  o[n] = { url: e, type: i }, t.forEach((s) => s());
}
function B(n) {
  return o[n];
}
function d(n) {
  delete o[n], t.forEach((e) => e());
}
function l() {
  return o;
}
export {
  u as addDBConnectionListener,
  c as dbInstance,
  B as getDBConnection,
  l as getDBConnectionMap,
  a as getDbInstance,
  d as removeDBConnection,
  f as removeDBConnectionListener,
  D as setDBConnection,
  r as updateDBInstance
};
