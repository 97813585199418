import { cn } from '@/utils';
import React, { ReactNode } from 'react';
import { ShadowBarChartContent } from './ShadowBarChartContent';
import { ShadowBarChartTitle } from './ShadowBarChartTitle';
import {
    BarChartContext,
    DEFAULT_OPTIONS,
    ShadowBarChartContext,
    ShadowBarOptions,
} from './context';

export const ShadowBarChartBase = ({
    children,
    options,
    ...context
}: {
    children: ReactNode;
    options?: Partial<ShadowBarOptions>;
} & ShadowBarChartContext) => {
    const { yAxis } = context;

    const mergedOptions = {
        ...DEFAULT_OPTIONS,
        ...options,
    };

    const containerRef = React.useRef<HTMLDivElement>(null);

    const showYAxis =
        !!yAxis && (yAxis === 'auto' || Object.keys(yAxis).length > 0);

    return (
        <BarChartContext.Provider
            value={{
                ...context,
                showYAxis,
                containerRef,
                options: mergedOptions,
            }}
        >
            <div
                ref={containerRef}
                className={cn(
                    'flex flex-col text-xs text-white whitespace-nowrap',
                    {
                        'w-full': showYAxis,
                    },
                )}
            >
                {children}
            </div>
        </BarChartContext.Provider>
    );
};

export const ShadowBarChart = Object.assign(ShadowBarChartBase, {
    Content: ShadowBarChartContent,
    Title: ShadowBarChartTitle,
});
