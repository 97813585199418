import React, { FC, memo, useMemo } from 'react';

import { BaseChart, EChartsOption, useChart } from '@/components/ECharts';
import { useConfigContext } from '@/context/configContext';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { Typography } from '@analytical-alley/ui';
import { PieSeriesOption } from 'echarts';
import { budgetShareChartOptions } from './optimizedPieChartOptions';

export type ChartOptions = EChartsOption<PieSeriesOption>;

export type CategoryData = {
    name: string;
    budget: number;
    percent?: number;
    color?: string;
};

interface OptimizedPieChartProps {
    className?: string;
    isLoading?: boolean;
    data: CategoryData[];
    showPercent: boolean;
}

export const OptimizedPieChart: FC<OptimizedPieChartProps> = memo(
    ({ className, isLoading, data, showPercent }) => {
        const { formatNumber } = useConfigContext();

        const { colorMap } = useDashboardGlobalContext();

        const eChartsOptions = useMemo(() => {
            return budgetShareChartOptions({
                data,
                showPercent,
                formatNumber,
                colorMap,
            });
        }, [data, formatNumber, showPercent, colorMap]);

        const {
            ref: chartRef,
            chartOptions,
            onEvents,
        } = useChart({
            chartOptions: eChartsOptions,
        });

        if (isLoading) {
            return (
                <div className={className}>
                    <div className="flex items-center justify-center w-full h-[42rem]" />
                </div>
            );
        }

        return (
            <div className="bg-[#FBFEFF21] shadow shadow-[#FDFDFF19] box-border rounded-2xl w-full bg-white bg-opacity-10 border-white p-4">
                <Typography className="text-center mb-3" variant="bodyL">
                    Optimised media investment
                </Typography>
                <div className="h-[20rem]">
                    <BaseChart
                        $shouldExpand
                        ref={chartRef}
                        option={chartOptions}
                        onEvents={onEvents}
                    />
                </div>
            </div>
        );
    },
);

OptimizedPieChart.displayName = 'OptimizedPieChart';
