import { BaseChart, ChartMouseHandler } from '@/components/ECharts';
import { HighlightItem } from '@/components/ECharts/HightlightLegend';
import {
    ChartRef,
    SelectedChangeHandler,
    useChart,
} from '@/components/ECharts/useChart';
import { ToggleSelect } from '@/components/ToggleSelect';
import { useConfigContext } from '@/context/configContext';
import {
    RoiData,
    UseBudgetOptimizationQueryResponse,
} from '@/dashboards/BudgetOptimization/hooks/useBudgetOptimizationData';
import Asterisk from '@/svgs/asterisk.svg?react';
import { jsxToHtml } from '@/utils/reactUtils';
import React, { RefObject, memo, useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { getEChartsOptions } from './roiCurvesChartOptions';
import { MarkPointTooltip, renderTooltip } from './tooltips';

export interface ROICurvesChartProps {
    className?: string;
    data: RoiData;
    investmentStep: number;
    points: {
        previousAllocation: { [key: string]: number };
        optimalAllocation:
            | NonNullable<
                  UseBudgetOptimizationQueryResponse['data']['optimized']
              >['optimalVariables']
            | undefined;
    };
    chartRef: RefObject<ChartRef>;
    filterContainerRef: RefObject<HTMLDivElement>;
}

export const ROICurvesChart = memo(
    ({
        className,
        data,
        filterContainerRef,
        points,
        investmentStep,
    }: ROICurvesChartProps) => {
        const { formatNumber } = useConfigContext();
        const eChartsOptions = useMemo(
            () =>
                getEChartsOptions(data, points, investmentStep, {
                    formatNumber,
                }),
            [data, points, investmentStep, formatNumber],
        );

        const onClick: ChartMouseHandler = useCallback(
            (context, { setSelected }) => {
                if (context.seriesName) {
                    setSelected((prev) => {
                        if (prev || prev === context.seriesName) {
                            return '';
                        }
                        return context.seriesName;
                    });
                } else {
                    setSelected('');
                }
            },
            [],
        );

        const onMouseOver: ChartMouseHandler = useCallback(
            (context, { instance, selected }) => {
                if (instance && context.componentType === 'markPoint') {
                    const seriesName =
                        eChartsOptions.series[context.seriesIndex]!.name;

                    if (selected && selected !== seriesName) {
                        return;
                    }

                    instance.setOption({
                        tooltip: {
                            formatter: () => {
                                return jsxToHtml(
                                    <MarkPointTooltip
                                        name={seriesName}
                                        data={context.data}
                                        formatter={(value) => {
                                            return formatNumber(Number(value));
                                        }}
                                    />,
                                );
                            },
                        },
                    });
                }
            },
            [eChartsOptions.series, formatNumber],
        );

        const onMouseOut: ChartMouseHandler = useCallback(
            (context, { instance, selected }) => {
                if (instance && context.componentType === 'markPoint') {
                    instance.setOption({
                        tooltip: {
                            formatter: renderTooltip({
                                selected,
                                formatNumber: (value) => {
                                    return formatNumber(Number(value));
                                },
                            }),
                        },
                    });
                }
            },
            [formatNumber],
        );

        const onSelectedChange: SelectedChangeHandler<typeof eChartsOptions> =
            useCallback(
                ({ selected, instance, series }) => {
                    instance.setOption({
                        tooltip: {
                            formatter: renderTooltip({
                                selected,
                                formatNumber: (value) => {
                                    return formatNumber(Number(value));
                                },
                            }),
                        },
                        series: series.map((seriesOption) => {
                            if (selected && selected !== seriesOption.name) {
                                return {
                                    ...seriesOption,
                                    markPoint: {
                                        itemStyle: {
                                            opacity: 0.1,
                                        },
                                    },
                                    itemStyle: { opacity: 0.1 },
                                    lineStyle: { opacity: 0.1 },
                                };
                            }
                            return {
                                ...seriesOption,
                                markPoint: {
                                    itemStyle: {
                                        opacity: 1,
                                    },
                                },
                                itemStyle: { opacity: 1 },
                                lineStyle: { opacity: 1 },
                            };
                        }),
                    });
                },
                [formatNumber],
            );

        const {
            ref,
            chartOptions,
            onEvents,
            legendItems,
            toggleLegend,
            setSelected,
            selected,
        } = useChart({
            chartOptions: eChartsOptions,
            onClick,
            onMouseOver,
            onMouseOut,
            onSelectedChange,
        });

        const filteredLegendItems = legendItems
            .filter((item) => item.selected)
            .map((legendItem) => ({
                name: legendItem.name,
                highlighted: true,
                color: legendItem.color,
            }));

        return (
            <>
                {filterContainerRef.current
                    ? createPortal(
                          <ToggleSelect
                              items={legendItems.map((item) => ({
                                  label: item.name,
                                  value: item.name,
                                  checked: item.selected,
                              }))}
                              placement={'bottom-end'}
                              compact
                              label={'Filter Variables'}
                              onChange={(name, checked) => {
                                  toggleLegend(name, checked);
                              }}
                          />,
                          filterContainerRef.current,
                      )
                    : null}
                <div className={className}>
                    <div className="relative w-full h-[24rem]">
                        <BaseChart
                            ref={ref}
                            $shouldExpand
                            className="pt-3"
                            option={chartOptions}
                            onEvents={onEvents}
                        />
                    </div>
                    <div className="relative pl-4 pr-4 z-20 -mt-7">
                        <div className="flex flex-wrap gap-2">
                            <HighlightItem
                                highlighted
                                name="Recommended"
                                icon={<Asterisk height={10} width={10} />}
                            />
                            <HighlightItem
                                highlighted
                                name="Previous value"
                                icon={
                                    <div className="w-2.5 h-2.5 rounded-full me-1.5 bg-white" />
                                }
                            />
                            {filteredLegendItems.map((item) => {
                                return (
                                    <HighlightItem
                                        key={item.name}
                                        {...item}
                                        onClick={(name) => {
                                            if (selected === name) {
                                                setSelected('');
                                                return;
                                            }
                                            setSelected(name);
                                        }}
                                        highlighted={
                                            !selected || item.name === selected
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    },
);

ROICurvesChart.displayName = 'ROICurvesChart';
