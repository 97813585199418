import { jsx as _, jsxs as F } from "react/jsx-runtime";
import { forwardRef as _t, useMemo as gt, useState as k, useEffect as be, useRef as se, useImperativeHandle as bt } from "react";
import { G as ce } from "../../iconBase-DkR9t2l_.js";
import { t as A } from "../../bundle-mjs-3rKE4aij.js";
import { datePickerTheme as ye } from "./theme.js";
import { TextInput as yt } from "./Input/TextInput.js";
import { Views as p, getFormattedDate as B, isDateEqual as $t, WeekStart as mt, getFirstDateInRange as Ot, startOfYearPeriod as U, addYears as z, addMonths as wt } from "../../utils/datepicker.js";
import { c as K, g as Tt } from "../../_commonjsHelpers-DaMA6jEr.js";
import Ct from "moment";
import { DatepickerContext as jt } from "./DatepickerContext.js";
import { DatepickerViewsDays as At } from "./Views/Days.js";
import { DatepickerViewsDecades as Dt } from "./Views/Decades.js";
import { DatepickerViewsMonth as St } from "./Views/Months.js";
import { DatepickerViewsYears as It } from "./Views/Years.js";
function Pt(e) {
  return ce({ tag: "svg", attr: { viewBox: "0 0 20 20", fill: "currentColor", "aria-hidden": "true" }, child: [{ tag: "path", attr: { fillRule: "evenodd", d: "M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z", clipRule: "evenodd" }, child: [] }] })(e);
}
function xt(e) {
  return ce({ tag: "svg", attr: { viewBox: "0 0 20 20", fill: "currentColor", "aria-hidden": "true" }, child: [{ tag: "path", attr: { fillRule: "evenodd", d: "M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z", clipRule: "evenodd" }, child: [] }] })(e);
}
function Mt(e) {
  return ce({ tag: "svg", attr: { viewBox: "0 0 20 20", fill: "currentColor", "aria-hidden": "true" }, child: [{ tag: "path", attr: { fillRule: "evenodd", d: "M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z", clipRule: "evenodd" }, child: [] }] })(e);
}
function Et() {
  this.__data__ = [], this.size = 0;
}
var Nt = Et;
function Lt(e, t) {
  return e === t || e !== e && t !== t;
}
var W = Lt, Gt = W;
function Ft(e, t) {
  for (var r = e.length; r--; )
    if (Gt(e[r][0], t))
      return r;
  return -1;
}
var X = Ft, Ht = X, Vt = Array.prototype, Rt = Vt.splice;
function kt(e) {
  var t = this.__data__, r = Ht(t, e);
  if (r < 0)
    return !1;
  var a = t.length - 1;
  return r == a ? t.pop() : Rt.call(t, r, 1), --this.size, !0;
}
var Bt = kt, Ut = X;
function zt(e) {
  var t = this.__data__, r = Ut(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var Kt = zt, qt = X;
function Yt(e) {
  return qt(this.__data__, e) > -1;
}
var Jt = Yt, Wt = X;
function Xt(e, t) {
  var r = this.__data__, a = Wt(r, e);
  return a < 0 ? (++this.size, r.push([e, t])) : r[a][1] = t, this;
}
var Zt = Xt, Qt = Nt, er = Bt, tr = Kt, rr = Jt, ar = Zt;
function I(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var a = e[t];
    this.set(a[0], a[1]);
  }
}
I.prototype.clear = Qt;
I.prototype.delete = er;
I.prototype.get = tr;
I.prototype.has = rr;
I.prototype.set = ar;
var Z = I, nr = Z;
function or() {
  this.__data__ = new nr(), this.size = 0;
}
var sr = or;
function ir(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var cr = ir;
function ur(e) {
  return this.__data__.get(e);
}
var lr = ur;
function fr(e) {
  return this.__data__.has(e);
}
var pr = fr, vr = typeof K == "object" && K && K.Object === Object && K, Fe = vr, dr = Fe, hr = typeof self == "object" && self && self.Object === Object && self, _r = dr || hr || Function("return this")(), P = _r, gr = P, br = gr.Symbol, He = br, $e = He, Ve = Object.prototype, yr = Ve.hasOwnProperty, $r = Ve.toString, H = $e ? $e.toStringTag : void 0;
function mr(e) {
  var t = yr.call(e, H), r = e[H];
  try {
    e[H] = void 0;
    var a = !0;
  } catch {
  }
  var n = $r.call(e);
  return a && (t ? e[H] = r : delete e[H]), n;
}
var Or = mr, wr = Object.prototype, Tr = wr.toString;
function Cr(e) {
  return Tr.call(e);
}
var jr = Cr, me = He, Ar = Or, Dr = jr, Sr = "[object Null]", Ir = "[object Undefined]", Oe = me ? me.toStringTag : void 0;
function Pr(e) {
  return e == null ? e === void 0 ? Ir : Sr : Oe && Oe in Object(e) ? Ar(e) : Dr(e);
}
var Q = Pr;
function xr(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var D = xr, Mr = Q, Er = D, Nr = "[object AsyncFunction]", Lr = "[object Function]", Gr = "[object GeneratorFunction]", Fr = "[object Proxy]";
function Hr(e) {
  if (!Er(e))
    return !1;
  var t = Mr(e);
  return t == Lr || t == Gr || t == Nr || t == Fr;
}
var ue = Hr, Vr = P, Rr = Vr["__core-js_shared__"], kr = Rr, ie = kr, we = function() {
  var e = /[^.]+$/.exec(ie && ie.keys && ie.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function Br(e) {
  return !!we && we in e;
}
var Ur = Br, zr = Function.prototype, Kr = zr.toString;
function qr(e) {
  if (e != null) {
    try {
      return Kr.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var Yr = qr, Jr = ue, Wr = Ur, Xr = D, Zr = Yr, Qr = /[\\^$.*+?()[\]{}|]/g, ea = /^\[object .+?Constructor\]$/, ta = Function.prototype, ra = Object.prototype, aa = ta.toString, na = ra.hasOwnProperty, oa = RegExp(
  "^" + aa.call(na).replace(Qr, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function sa(e) {
  if (!Xr(e) || Wr(e))
    return !1;
  var t = Jr(e) ? oa : ea;
  return t.test(Zr(e));
}
var ia = sa;
function ca(e, t) {
  return e == null ? void 0 : e[t];
}
var ua = ca, la = ia, fa = ua;
function pa(e, t) {
  var r = fa(e, t);
  return la(r) ? r : void 0;
}
var le = pa, va = le, da = P, ha = va(da, "Map"), Re = ha, _a = le, ga = _a(Object, "create"), ee = ga, Te = ee;
function ba() {
  this.__data__ = Te ? Te(null) : {}, this.size = 0;
}
var ya = ba;
function $a(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var ma = $a, Oa = ee, wa = "__lodash_hash_undefined__", Ta = Object.prototype, Ca = Ta.hasOwnProperty;
function ja(e) {
  var t = this.__data__;
  if (Oa) {
    var r = t[e];
    return r === wa ? void 0 : r;
  }
  return Ca.call(t, e) ? t[e] : void 0;
}
var Aa = ja, Da = ee, Sa = Object.prototype, Ia = Sa.hasOwnProperty;
function Pa(e) {
  var t = this.__data__;
  return Da ? t[e] !== void 0 : Ia.call(t, e);
}
var xa = Pa, Ma = ee, Ea = "__lodash_hash_undefined__";
function Na(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = Ma && t === void 0 ? Ea : t, this;
}
var La = Na, Ga = ya, Fa = ma, Ha = Aa, Va = xa, Ra = La;
function x(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var a = e[t];
    this.set(a[0], a[1]);
  }
}
x.prototype.clear = Ga;
x.prototype.delete = Fa;
x.prototype.get = Ha;
x.prototype.has = Va;
x.prototype.set = Ra;
var ka = x, Ce = ka, Ba = Z, Ua = Re;
function za() {
  this.size = 0, this.__data__ = {
    hash: new Ce(),
    map: new (Ua || Ba)(),
    string: new Ce()
  };
}
var Ka = za;
function qa(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var Ya = qa, Ja = Ya;
function Wa(e, t) {
  var r = e.__data__;
  return Ja(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var te = Wa, Xa = te;
function Za(e) {
  var t = Xa(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var Qa = Za, en = te;
function tn(e) {
  return en(this, e).get(e);
}
var rn = tn, an = te;
function nn(e) {
  return an(this, e).has(e);
}
var on = nn, sn = te;
function cn(e, t) {
  var r = sn(this, e), a = r.size;
  return r.set(e, t), this.size += r.size == a ? 0 : 1, this;
}
var un = cn, ln = Ka, fn = Qa, pn = rn, vn = on, dn = un;
function M(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var a = e[t];
    this.set(a[0], a[1]);
  }
}
M.prototype.clear = ln;
M.prototype.delete = fn;
M.prototype.get = pn;
M.prototype.has = vn;
M.prototype.set = dn;
var hn = M, _n = Z, gn = Re, bn = hn, yn = 200;
function $n(e, t) {
  var r = this.__data__;
  if (r instanceof _n) {
    var a = r.__data__;
    if (!gn || a.length < yn - 1)
      return a.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new bn(a);
  }
  return r.set(e, t), this.size = r.size, this;
}
var mn = $n, On = Z, wn = sr, Tn = cr, Cn = lr, jn = pr, An = mn;
function E(e) {
  var t = this.__data__ = new On(e);
  this.size = t.size;
}
E.prototype.clear = wn;
E.prototype.delete = Tn;
E.prototype.get = Cn;
E.prototype.has = jn;
E.prototype.set = An;
var Dn = E, Sn = le, In = function() {
  try {
    var e = Sn(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), ke = In, je = ke;
function Pn(e, t, r) {
  t == "__proto__" && je ? je(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
var fe = Pn, xn = fe, Mn = W;
function En(e, t, r) {
  (r !== void 0 && !Mn(e[t], r) || r === void 0 && !(t in e)) && xn(e, t, r);
}
var Be = En;
function Nn(e) {
  return function(t, r, a) {
    for (var n = -1, i = Object(t), c = a(t), o = c.length; o--; ) {
      var s = c[e ? o : ++n];
      if (r(i[s], s, i) === !1)
        break;
    }
    return t;
  };
}
var Ln = Nn, Gn = Ln, Fn = Gn(), Hn = Fn, q = { exports: {} };
q.exports;
(function(e, t) {
  var r = P, a = t && !t.nodeType && t, n = a && !0 && e && !e.nodeType && e, i = n && n.exports === a, c = i ? r.Buffer : void 0, o = c ? c.allocUnsafe : void 0;
  function s(l, d) {
    if (d)
      return l.slice();
    var g = l.length, m = o ? o(g) : new l.constructor(g);
    return l.copy(m), m;
  }
  e.exports = s;
})(q, q.exports);
var Vn = q.exports, Rn = P, kn = Rn.Uint8Array, Bn = kn, Ae = Bn;
function Un(e) {
  var t = new e.constructor(e.byteLength);
  return new Ae(t).set(new Ae(e)), t;
}
var zn = Un, Kn = zn;
function qn(e, t) {
  var r = t ? Kn(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var Yn = qn;
function Jn(e, t) {
  var r = -1, a = e.length;
  for (t || (t = Array(a)); ++r < a; )
    t[r] = e[r];
  return t;
}
var Wn = Jn, Xn = D, De = Object.create, Zn = /* @__PURE__ */ function() {
  function e() {
  }
  return function(t) {
    if (!Xn(t))
      return {};
    if (De)
      return De(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}(), Qn = Zn;
function eo(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var to = eo, ro = to, ao = ro(Object.getPrototypeOf, Object), Ue = ao, no = Object.prototype;
function oo(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || no;
  return e === r;
}
var ze = oo, so = Qn, io = Ue, co = ze;
function uo(e) {
  return typeof e.constructor == "function" && !co(e) ? so(io(e)) : {};
}
var lo = uo;
function fo(e) {
  return e != null && typeof e == "object";
}
var V = fo, po = Q, vo = V, ho = "[object Arguments]";
function _o(e) {
  return vo(e) && po(e) == ho;
}
var go = _o, Se = go, bo = V, Ke = Object.prototype, yo = Ke.hasOwnProperty, $o = Ke.propertyIsEnumerable, mo = Se(/* @__PURE__ */ function() {
  return arguments;
}()) ? Se : function(e) {
  return bo(e) && yo.call(e, "callee") && !$o.call(e, "callee");
}, qe = mo, Oo = Array.isArray, Ye = Oo, wo = 9007199254740991;
function To(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= wo;
}
var Je = To, Co = ue, jo = Je;
function Ao(e) {
  return e != null && jo(e.length) && !Co(e);
}
var pe = Ao, Do = pe, So = V;
function Io(e) {
  return So(e) && Do(e);
}
var Po = Io, Y = { exports: {} };
function xo() {
  return !1;
}
var Mo = xo;
Y.exports;
(function(e, t) {
  var r = P, a = Mo, n = t && !t.nodeType && t, i = n && !0 && e && !e.nodeType && e, c = i && i.exports === n, o = c ? r.Buffer : void 0, s = o ? o.isBuffer : void 0, l = s || a;
  e.exports = l;
})(Y, Y.exports);
var We = Y.exports, Eo = Q, No = Ue, Lo = V, Go = "[object Object]", Fo = Function.prototype, Ho = Object.prototype, Xe = Fo.toString, Vo = Ho.hasOwnProperty, Ro = Xe.call(Object);
function ko(e) {
  if (!Lo(e) || Eo(e) != Go)
    return !1;
  var t = No(e);
  if (t === null)
    return !0;
  var r = Vo.call(t, "constructor") && t.constructor;
  return typeof r == "function" && r instanceof r && Xe.call(r) == Ro;
}
var Bo = ko, Uo = Q, zo = Je, Ko = V, qo = "[object Arguments]", Yo = "[object Array]", Jo = "[object Boolean]", Wo = "[object Date]", Xo = "[object Error]", Zo = "[object Function]", Qo = "[object Map]", es = "[object Number]", ts = "[object Object]", rs = "[object RegExp]", as = "[object Set]", ns = "[object String]", os = "[object WeakMap]", ss = "[object ArrayBuffer]", is = "[object DataView]", cs = "[object Float32Array]", us = "[object Float64Array]", ls = "[object Int8Array]", fs = "[object Int16Array]", ps = "[object Int32Array]", vs = "[object Uint8Array]", ds = "[object Uint8ClampedArray]", hs = "[object Uint16Array]", _s = "[object Uint32Array]", u = {};
u[cs] = u[us] = u[ls] = u[fs] = u[ps] = u[vs] = u[ds] = u[hs] = u[_s] = !0;
u[qo] = u[Yo] = u[ss] = u[Jo] = u[is] = u[Wo] = u[Xo] = u[Zo] = u[Qo] = u[es] = u[ts] = u[rs] = u[as] = u[ns] = u[os] = !1;
function gs(e) {
  return Ko(e) && zo(e.length) && !!u[Uo(e)];
}
var bs = gs;
function ys(e) {
  return function(t) {
    return e(t);
  };
}
var $s = ys, J = { exports: {} };
J.exports;
(function(e, t) {
  var r = Fe, a = t && !t.nodeType && t, n = a && !0 && e && !e.nodeType && e, i = n && n.exports === a, c = i && r.process, o = function() {
    try {
      var s = n && n.require && n.require("util").types;
      return s || c && c.binding && c.binding("util");
    } catch {
    }
  }();
  e.exports = o;
})(J, J.exports);
var ms = J.exports, Os = bs, ws = $s, Ie = ms, Pe = Ie && Ie.isTypedArray, Ts = Pe ? ws(Pe) : Os, Ze = Ts;
function Cs(e, t) {
  if (!(t === "constructor" && typeof e[t] == "function") && t != "__proto__")
    return e[t];
}
var Qe = Cs, js = fe, As = W, Ds = Object.prototype, Ss = Ds.hasOwnProperty;
function Is(e, t, r) {
  var a = e[t];
  (!(Ss.call(e, t) && As(a, r)) || r === void 0 && !(t in e)) && js(e, t, r);
}
var Ps = Is, xs = Ps, Ms = fe;
function Es(e, t, r, a) {
  var n = !r;
  r || (r = {});
  for (var i = -1, c = t.length; ++i < c; ) {
    var o = t[i], s = a ? a(r[o], e[o], o, r, e) : void 0;
    s === void 0 && (s = e[o]), n ? Ms(r, o, s) : xs(r, o, s);
  }
  return r;
}
var Ns = Es;
function Ls(e, t) {
  for (var r = -1, a = Array(e); ++r < e; )
    a[r] = t(r);
  return a;
}
var Gs = Ls, Fs = 9007199254740991, Hs = /^(?:0|[1-9]\d*)$/;
function Vs(e, t) {
  var r = typeof e;
  return t = t ?? Fs, !!t && (r == "number" || r != "symbol" && Hs.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var et = Vs, Rs = Gs, ks = qe, Bs = Ye, Us = We, zs = et, Ks = Ze, qs = Object.prototype, Ys = qs.hasOwnProperty;
function Js(e, t) {
  var r = Bs(e), a = !r && ks(e), n = !r && !a && Us(e), i = !r && !a && !n && Ks(e), c = r || a || n || i, o = c ? Rs(e.length, String) : [], s = o.length;
  for (var l in e)
    (t || Ys.call(e, l)) && !(c && // Safari 9 has enumerable `arguments.length` in strict mode.
    (l == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    n && (l == "offset" || l == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    i && (l == "buffer" || l == "byteLength" || l == "byteOffset") || // Skip index properties.
    zs(l, s))) && o.push(l);
  return o;
}
var Ws = Js;
function Xs(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var Zs = Xs, Qs = D, ei = ze, ti = Zs, ri = Object.prototype, ai = ri.hasOwnProperty;
function ni(e) {
  if (!Qs(e))
    return ti(e);
  var t = ei(e), r = [];
  for (var a in e)
    a == "constructor" && (t || !ai.call(e, a)) || r.push(a);
  return r;
}
var oi = ni, si = Ws, ii = oi, ci = pe;
function ui(e) {
  return ci(e) ? si(e, !0) : ii(e);
}
var tt = ui, li = Ns, fi = tt;
function pi(e) {
  return li(e, fi(e));
}
var vi = pi, xe = Be, di = Vn, hi = Yn, _i = Wn, gi = lo, Me = qe, Ee = Ye, bi = Po, yi = We, $i = ue, mi = D, Oi = Bo, wi = Ze, Ne = Qe, Ti = vi;
function Ci(e, t, r, a, n, i, c) {
  var o = Ne(e, r), s = Ne(t, r), l = c.get(s);
  if (l) {
    xe(e, r, l);
    return;
  }
  var d = i ? i(o, s, r + "", e, t, c) : void 0, g = d === void 0;
  if (g) {
    var m = Ee(s), N = !m && yi(s), L = !m && !N && wi(s);
    d = s, m || N || L ? Ee(o) ? d = o : bi(o) ? d = _i(o) : N ? (g = !1, d = di(s, !0)) : L ? (g = !1, d = hi(s, !0)) : d = [] : Oi(s) || Me(s) ? (d = o, Me(o) ? d = Ti(o) : (!mi(o) || $i(o)) && (d = gi(s))) : g = !1;
  }
  g && (c.set(s, d), n(d, s, a, i, c), c.delete(s)), xe(e, r, d);
}
var ji = Ci, Ai = Dn, Di = Be, Si = Hn, Ii = ji, Pi = D, xi = tt, Mi = Qe;
function rt(e, t, r, a, n) {
  e !== t && Si(t, function(i, c) {
    if (n || (n = new Ai()), Pi(i))
      Ii(e, t, c, r, rt, a, n);
    else {
      var o = a ? a(Mi(e, c), i, c + "", e, t, n) : void 0;
      o === void 0 && (o = i), Di(e, c, o);
    }
  }, xi);
}
var Ei = rt;
function Ni(e) {
  return e;
}
var at = Ni;
function Li(e, t, r) {
  switch (r.length) {
    case 0:
      return e.call(t);
    case 1:
      return e.call(t, r[0]);
    case 2:
      return e.call(t, r[0], r[1]);
    case 3:
      return e.call(t, r[0], r[1], r[2]);
  }
  return e.apply(t, r);
}
var Gi = Li, Fi = Gi, Le = Math.max;
function Hi(e, t, r) {
  return t = Le(t === void 0 ? e.length - 1 : t, 0), function() {
    for (var a = arguments, n = -1, i = Le(a.length - t, 0), c = Array(i); ++n < i; )
      c[n] = a[t + n];
    n = -1;
    for (var o = Array(t + 1); ++n < t; )
      o[n] = a[n];
    return o[t] = r(c), Fi(e, this, o);
  };
}
var Vi = Hi;
function Ri(e) {
  return function() {
    return e;
  };
}
var ki = Ri, Bi = ki, Ge = ke, Ui = at, zi = Ge ? function(e, t) {
  return Ge(e, "toString", {
    configurable: !0,
    enumerable: !1,
    value: Bi(t),
    writable: !0
  });
} : Ui, Ki = zi, qi = 800, Yi = 16, Ji = Date.now;
function Wi(e) {
  var t = 0, r = 0;
  return function() {
    var a = Ji(), n = Yi - (a - r);
    if (r = a, n > 0) {
      if (++t >= qi)
        return arguments[0];
    } else
      t = 0;
    return e.apply(void 0, arguments);
  };
}
var Xi = Wi, Zi = Ki, Qi = Xi, ec = Qi(Zi), tc = ec, rc = at, ac = Vi, nc = tc;
function oc(e, t) {
  return nc(ac(e, t, rc), e + "");
}
var sc = oc, ic = W, cc = pe, uc = et, lc = D;
function fc(e, t, r) {
  if (!lc(r))
    return !1;
  var a = typeof t;
  return (a == "number" ? cc(r) && uc(t, r.length) : a == "string" && t in r) ? ic(r[t], e) : !1;
}
var pc = fc, vc = sc, dc = pc;
function hc(e) {
  return vc(function(t, r) {
    var a = -1, n = r.length, i = n > 1 ? r[n - 1] : void 0, c = n > 2 ? r[2] : void 0;
    for (i = e.length > 3 && typeof i == "function" ? (n--, i) : void 0, c && dc(r[0], r[1], c) && (i = n < 3 ? void 0 : i, n = 1), t = Object(t); ++a < n; ) {
      var o = r[a];
      o && e(t, o, a, i);
    }
    return t;
  });
}
var _c = hc, gc = Ei, bc = _c, yc = bc(function(e, t, r) {
  gc(e, t, r);
}), $c = yc;
const mc = /* @__PURE__ */ Tt($c), Oc = ({
  title: e,
  open: t,
  inline: r = !1,
  autoHide: a = !0,
  // Hide when selected the day
  showClearButton: n = !0,
  labelClearButton: i = "Clear",
  showTodayButton: c = !0,
  labelTodayButton: o = "Today",
  defaultDate: s = /* @__PURE__ */ new Date(),
  minDate: l,
  maxDate: d,
  language: g = "en",
  weekStart: m = mt.Sunday,
  className: N,
  onSelectedDateChanged: L,
  value: re,
  compact: nt,
  dateRangeType: ot,
  theme: ve,
  ...st
}, it) => {
  const v = ve ? mc(ye, ve) : ye, O = gt(
    () => re ? new Date(re) : null,
    [re]
  ), [de, y] = k(t), [w, ae] = k(p.Days), [ne, R] = k("");
  be(() => {
    R(
      O ? B(g, O) : ""
    );
  }, [g, O]);
  const [$, T] = k(O || /* @__PURE__ */ new Date()), j = se(null), G = se(null), oe = se({
    date: O,
    shouldUpdate: !0
  }), C = (f, h) => {
    const b = Ct.utc(f).toDate();
    if (!b) {
      R(""), L(null);
      return;
    }
    const S = Ot(b, l, d);
    T(S || /* @__PURE__ */ new Date()), R(B(g, S)), oe.current = { date: S, shouldUpdate: !0 }, L(S), a && w === p.Days && h && !r && y(!1);
  }, ct = () => {
    C(s, !0), s && T(s);
  };
  bt(it, () => ({
    focus() {
      var f;
      (f = j.current) == null || f.focus();
    },
    clear() {
      ct();
    }
  }));
  const ut = (f) => {
    switch (f) {
      case p.Decades:
        return /* @__PURE__ */ _(Dt, {});
      case p.Years:
        return /* @__PURE__ */ _(It, {});
      case p.Months:
        return /* @__PURE__ */ _(St, {});
      case p.Days:
      default:
        return /* @__PURE__ */ _(At, {});
    }
  }, lt = () => {
    switch (w) {
      case p.Days:
        return p.Months;
      case p.Months:
        return p.Years;
      case p.Years:
        return p.Days;
    }
    return w;
  }, ft = () => {
    switch (w) {
      case p.Decades:
        return `${U($, 100)} - ${U($, 100) + 90}`;
      case p.Years:
        return `${U($, 10)} - ${U($, 10) + 9}`;
      case p.Months:
        return B(g, $, {
          year: "numeric"
        });
      case p.Days:
      default:
        return B(g, $, {
          month: "long",
          year: "numeric"
        });
    }
  }, he = (f, h, b) => {
    switch (f) {
      case p.Days:
        return new Date(wt(h, b));
      case p.Months:
        return new Date(z(h, b));
      case p.Years:
        return new Date(z(h, b * 10));
      case p.Decades:
        return new Date(z(h, b * 100));
      default:
        return new Date(z(h, b * 10));
    }
  }, pt = (f) => {
    ae(p.Days);
    const h = new Date(f.target.value);
    if (R(f.target.value), !isNaN(h.getTime())) {
      y(!0), T(h);
      return;
    }
    y(!1);
  }, vt = (f) => {
    var h;
    f.key === "Enter" && ((h = j.current) == null || h.blur(), y(!1)), f.key === "Escape" && C(oe.current.date, !0);
  }, dt = () => {
    if (ne == null) {
      C(null, !0);
      return;
    }
    const f = new Date(ne);
    if (isNaN(f.getTime())) {
      C(oe.current.date, !0);
      return;
    }
    C(f, !1);
  };
  return be(() => {
    const f = (b) => {
      var _e, ge;
      const S = (_e = G == null ? void 0 : G.current) == null ? void 0 : _e.contains(
        b.target
      ), ht = (ge = j == null ? void 0 : j.current) == null ? void 0 : ge.contains(
        b.target
      );
      !S && !ht && y(!1);
    }, h = (b) => {
      b.key === "Escape" && y(!1);
    };
    return document.addEventListener("keydown", h), document.addEventListener("mousedown", f), () => {
      document.removeEventListener("keydown", h), document.removeEventListener("mousedown", f);
    };
  }, [j, G, y]), /* @__PURE__ */ _(
    jt.Provider,
    {
      value: {
        theme: v,
        language: g,
        minDate: l,
        maxDate: d,
        weekStart: m,
        isOpen: de,
        setIsOpen: y,
        view: w,
        setView: ae,
        viewDate: $,
        setViewDate: T,
        selectedDate: O,
        changeSelectedDate: C,
        dateRangeType: ot
      },
      children: /* @__PURE__ */ F("div", { className: A(v.root.base, N), children: [
        !r && /* @__PURE__ */ _(
          yt,
          {
            icon: Mt,
            ref: j,
            onClick: () => {
              y(!0);
            },
            onFocus: () => {
              $t($, O) || T(O || /* @__PURE__ */ new Date()), y(!0);
            },
            value: ne || "",
            onChange: pt,
            onBlur: dt,
            onKeyDown: vt,
            compact: nt,
            ...st
          }
        ),
        (de || r) && // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        /* @__PURE__ */ _(
          "div",
          {
            ref: G,
            onKeyDown: (f) => {
              f.key === "Escape" && y(!1);
            },
            className: A(
              v.popup.root.base,
              r && v.popup.root.inline
            ),
            children: /* @__PURE__ */ F("div", { className: v.popup.root.inner, children: [
              /* @__PURE__ */ F("div", { className: v.popup.header.base, children: [
                e && /* @__PURE__ */ _("div", { className: v.popup.header.title, children: e }),
                /* @__PURE__ */ F(
                  "div",
                  {
                    className: v.popup.header.selectors.base,
                    children: [
                      /* @__PURE__ */ _(
                        "button",
                        {
                          type: "button",
                          className: A(
                            v.popup.header.selectors.button.base,
                            v.popup.header.selectors.button.prev
                          ),
                          onClick: () => T(
                            he(
                              w,
                              $,
                              -1
                            )
                          ),
                          children: /* @__PURE__ */ _(Pt, {})
                        }
                      ),
                      /* @__PURE__ */ _(
                        "button",
                        {
                          type: "button",
                          className: A(
                            v.popup.header.selectors.button.base,
                            v.popup.header.selectors.button.view
                          ),
                          onClick: () => ae(lt()),
                          children: ft()
                        }
                      ),
                      /* @__PURE__ */ _(
                        "button",
                        {
                          type: "button",
                          className: A(
                            v.popup.header.selectors.button.base,
                            v.popup.header.selectors.button.next
                          ),
                          onClick: () => T(
                            he(
                              w,
                              $,
                              1
                            )
                          ),
                          children: /* @__PURE__ */ _(xt, {})
                        }
                      )
                    ]
                  }
                )
              ] }),
              /* @__PURE__ */ _("div", { className: v.popup.view.base, children: ut(w) }),
              (n || c) && /* @__PURE__ */ F("div", { className: v.popup.footer.base, children: [
                c && /* @__PURE__ */ _(
                  "button",
                  {
                    type: "button",
                    className: A(
                      v.popup.footer.button.base,
                      v.popup.footer.button.today
                    ),
                    onClick: () => {
                      C(/* @__PURE__ */ new Date(), !0);
                    },
                    children: o
                  }
                ),
                n && /* @__PURE__ */ _(
                  "button",
                  {
                    type: "button",
                    className: A(
                      v.popup.footer.button.base,
                      v.popup.footer.button.clear
                    ),
                    onClick: () => {
                      C(
                        s,
                        !0
                      ), s && T(s);
                    },
                    children: i
                  }
                )
              ] })
            ] })
          }
        )
      ] })
    }
  );
}, wc = _t(Oc);
wc.displayName = "Datepicker";
export {
  wc as Datepicker
};
