import { jsx as n } from "react/jsx-runtime";
import { t as Y } from "../../../bundle-mjs-3rKE4aij.js";
import { startOfYearPeriod as b, Views as h } from "../../../utils/datepicker.js";
import { useDatePickerContext as g } from "../DatepickerContext.js";
const x = () => {
  const {
    theme: l,
    selectedDate: a,
    minDate: t,
    maxDate: s,
    viewDate: o,
    setViewDate: c,
    setView: d
  } = g(), r = l.views.years;
  return /* @__PURE__ */ n("div", { className: r.items.base, children: [...Array(12)].map((y, f) => {
    const e = b(o, 10) - 1 + f, m = new Date(o.getTime());
    m.setFullYear(e);
    const u = (a == null ? void 0 : a.getFullYear()) === e;
    let i = !1;
    if (s && t) {
      const w = s == null ? void 0 : s.getFullYear(), p = t == null ? void 0 : t.getFullYear();
      i = e < p || e > w;
    }
    return /* @__PURE__ */ n(
      "button",
      {
        disabled: i,
        type: "button",
        className: Y(
          r.items.item.base,
          u && r.items.item.selected,
          i && r.items.item.disabled
        ),
        onClick: () => {
          i || (c(m), d(h.Months));
        },
        children: e
      },
      e
    );
  }) });
};
export {
  x as DatepickerViewsYears
};
