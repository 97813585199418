import { jsx as e, jsxs as t } from "react/jsx-runtime";
import { Spinner as r } from "./Spinner.js";
import { Typography as l } from "../Typography/index.js";
const c = () => /* @__PURE__ */ e("div", { className: "fixed top-0 left-0 w-full h-full bg-opacity-50 flex items-center justify-center", children: /* @__PURE__ */ t("div", { className: "flex flex-col items-center", children: [
  /* @__PURE__ */ e(l, { variant: "titleS", className: "mb-4 text-center", children: "Loading..." }),
  /* @__PURE__ */ e(r, {})
] }) });
export {
  c as FullPageLoader
};
