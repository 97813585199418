import { modelSpendSummaryQueryOptions } from '@/dashboards/SpendSummary/api';
import { useSpendSummaryUrl } from '@/dashboards/SpendSummary/hooks/useSpendSummaryUrl';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { getPeriodOptionsForModel } from '@/utils/model';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

export const useSpendSummaryQuery = () => {
    const {
        project: { roiType },
    } = useDashboardGlobalContext();
    const { search, params } = useSpendSummaryUrl();

    const { projectId } = params;

    const { period } = search;

    const navigate = useNavigate();

    const queryResult = useQuery(
        modelSpendSummaryQueryOptions({
            projectId,
            period,
            endDate: search.endDate,
            startDate: search.startDate,
            roiType,
        }),
    );

    const {
        project: { interval },
    } = useDashboardGlobalContext();

    const availablePeriodOptions = getPeriodOptionsForModel(interval);

    const activeTabIndex = period
        ? Math.max(availablePeriodOptions.indexOf(period) || 0, 0)
        : 0;

    const onActiveTabChange = (activeTab: number) => {
        navigate({
            search: {
                ...search,
                period: availablePeriodOptions[activeTab] || '',
            },
            params,
        });
    };

    return {
        queryResult,
        activeTabIndex,
        tabsToRender: availablePeriodOptions,
        onActiveTabChange,
    };
};
