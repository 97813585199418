import { sha256 } from 'js-sha256';

import { TreeNode } from './types';
export function flatCollectCheckedNodes(
    nodes: TreeNode[],
    checked: Record<string, boolean>,
    acc: TreeNode[] = [],
): TreeNode[] {
    return nodes.reduce((acc, node) => {
        if (checked[node.id]) {
            return [...acc, node];
        } else {
            if (node.children && node.children.length > 0) {
                return flatCollectCheckedNodes(node.children, checked, acc);
            } else {
                return acc;
            }
        }
    }, acc);
}

export function copyNodes(nodes: TreeNode[]) {
    return nodes.map((node) => ({
        ...node,
        children: node.children ? copyNodes(node.children) : undefined,
    }));
}

export function buildNodeIndex(
    nodes: TreeNode[],
    index: Record<string, TreeNode> = {},
) {
    nodes.forEach((node) => {
        index[node.id] = node;
        if (node.children) {
            index = buildNodeIndex(node.children, index);
        }
    });
    return index;
}

function updateParents(
    node: TreeNode,
    checkedState: Record<string, boolean>,
    updates: Record<string, boolean>,
) {
    if (node.parent) {
        const siblings = (node.parent.children || []).filter(
            (child) => child.id !== node.id,
        );
        const allChecked = siblings.every(
            (sibling) => checkedState[sibling.id],
        );
        updates[node.parent.id] = allChecked;
        if (allChecked) {
            updateParents(node.parent, checkedState, updates);
        } else {
            uncheckParents(node.parent, updates);
        }
    }
}

function uncheckParents(node: TreeNode, updates: Record<string, boolean>) {
    updates[node.id] = false;
    if (node.parent) {
        uncheckParents(node.parent, updates);
    }
}

function updateChildren(
    node: TreeNode,
    updates: Record<string, boolean>,
    value: boolean,
) {
    if (node.children) {
        for (const child of node.children) {
            updates[child.id] = value;
            updateChildren(child, updates, value);
        }
    }
}

export function checkAndUpdateTree(
    treeNodes: TreeNode[],
    checkedState: Record<string, boolean>,
) {
    let allChecked = true;
    treeNodes.forEach((treeNode) => {
        if (treeNode.children) {
            checkedState[treeNode.id] = checkAndUpdateTree(
                treeNode.children,
                checkedState,
            );
        }
        allChecked = allChecked && checkedState[treeNode.id]!;
    });
    return allChecked;
}

export function checkAndUpdate(
    nodeIndex: Record<string, TreeNode>,
    checkedState: Record<string, boolean>,
    id: string,
    newValue: boolean,
) {
    const updates = { [id]: newValue };
    updateChildren(nodeIndex[id]!, updates, newValue);
    if (!newValue) {
        if (nodeIndex[id]!.parent) {
            uncheckParents(nodeIndex[id]!, updates);
        }
    } else {
        updateParents(nodeIndex[id]!, checkedState, updates);
    }
    return updates;
}

function generateShortString(input: string) {
    const hash = sha256(input);
    return hash.slice(0, 8);
}
export function addParentsAndIds(nodes: TreeNode[], parent?: TreeNode) {
    for (const node of nodes) {
        if (parent) {
            node.parent = parent;
            node.id =
                parent.id + '-' + generateShortString(node.id || node.label);
        } else {
            node.id = generateShortString(node.id || node.label);
        }
        if (node.children) {
            node.children = addParentsAndIds(node.children, node);
        }
    }
    return nodes;
}

export function treeToMap(
    options: TreeNode[],
    value: boolean,
    key: keyof Pick<TreeNode, 'expanded' | 'selected'>,
    acc: Record<string, boolean> = {},
) {
    return options.reduce((acc, option) => {
        acc[option.id] = option[key] ?? value;
        treeToMap(option.children || [], value, key, acc);
        return acc;
    }, acc);
}
