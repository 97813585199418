import * as React from 'react';

import { PRE_FETCH_MOUSE_HOVER_DELAY } from '@/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePrefetchMouseHandlers = <T extends (...args: any[]) => any>(
    cb: T,
    options?: { timeoutMs?: number },
) => {
    const timeout = React.useRef<number | null>(null);

    const prefetch = (...args: Parameters<T>) => {
        timeout.current = window.setTimeout(() => {
            cb(...args);
        }, options?.timeoutMs || PRE_FETCH_MOUSE_HOVER_DELAY);
    };

    const onMouseEnter = (...args: Parameters<T>) => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        prefetch(...args);
    };

    const onMouseLeave = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };

    return (...args: Parameters<T>) => {
        return {
            onMouseEnter: () => onMouseEnter(...args),
            onMouseLeave,
        };
    };
};
