import { useAuth } from '@/config/auth';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { useEffect } from 'react';

const Logout = () => {
    const auth = useAuth();

    useEffect(() => {
        if (auth.isAuthenticated) {
            auth.signoutRedirect({
                post_logout_redirect_uri: window.location.origin,
            });
        }
    }, [auth]);

    return null;
};

export const Route = createFileRoute('/auth/logout')({
    component: Logout,
    beforeLoad: ({ context }) => {
        if (!context.auth.isAuthenticated) {
            throw redirect({
                to: '/',
            });
        }
    },
});
