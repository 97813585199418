import { GetPreviousDataReturn } from '@/dashboards/BudgetOptimization/api';
import { UseVariableDataReturn } from '@/dashboards/BudgetOptimization/hooks/useVariableData';

export const structureMonthlyMediaInvestmentData = ({
    optimizedData,
    rows,
}: Pick<UseVariableDataReturn, 'optimizedData'> &
    Pick<GetPreviousDataReturn, 'rows'>) => {
    let highestValue = 0;

    const prevDataMonthly = Object.entries(rows).reduce(
        (acc, [key, value]) => {
            const total = Object.values(value).reduce(
                (acc, value) => acc + value,
                0,
            );

            highestValue = Math.max(highestValue, total);

            acc[key] = total;
            return acc;
        },
        {} as Record<string, number>,
    );

    const monthlyMediaData = (optimizedData?.monthly || []).map(
        (value, index) => {
            const month = index + 1;
            highestValue = Math.max(highestValue, value);
            return {
                month,
                prevBudget: prevDataMonthly[String(month)] || 0,
                simulatedBudget: value,
            };
        },
    );

    return { highestValue, monthlyMediaData };
};
