import React, { ReactNode, useEffect, useState } from 'react';

import { AnalyticalAlleyLogo, Typography } from '@analytical-alley/ui';

import { HamburgerMenu } from '@/components/HamburgerMenu';
import { useAuth } from '@/config/auth';
import { useDashboardLinks } from '@/hooks/useDashboardLinks';
import LightningSvg from '@/svgs/lightning.svg?react';
import { cn } from '@/utils';
import { Link } from '@tanstack/react-router';

export const Header = ({
    title,
    children,
    className,
    sticky,
}: {
    title: ReactNode;
    children?: ReactNode;
    className?: string;
    sticky?: boolean;
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const { allAccessibleLinks } = useDashboardLinks();
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };
        if (sticky) window.addEventListener('scroll', handleScroll);

        return () => {
            if (sticky) window.removeEventListener('scroll', handleScroll);
        };
    }, [sticky]);

    return (
        <div
            className={cn('h-28 flex flex-row align-middle', className, {
                glass: isScrolled,
                'sticky top-0': sticky,
                'z-50': isMenuOpen || (sticky && isScrolled),
            })}
        >
            <div className="w-48 min-w-[200px] relative h-full">
                <AnalyticalAlleyLogo
                    className={cn('absolute opacity-100', {
                        'opacity-30': isMenuOpen,
                    })}
                >
                    <span className="p-1 bg-red-400 font-bold rounded-md absolute -top-16 left-16 rotate-12">
                        Beta
                    </span>
                </AnalyticalAlleyLogo>
            </div>
            <div className="inline-flex mx-4">
                <span className="mr-8">
                    <LightningSvg />
                </span>

                <Typography variant={'titleM'} className="self-center">
                    {title}
                </Typography>
            </div>
            <div className="grow" />
            <div className="flex gap-8">{children}</div>
            <HamburgerMenu open={isMenuOpen} onOpenChange={setIsMenuOpen}>
                <div className="grid gap-2.5 glass-tile rounded-xl p-3 max-w-full min-w-48">
                    <ul className="space-y-1">
                        {allAccessibleLinks.map(({ Link, title }) => {
                            return (
                                <li key={title}>
                                    <Link
                                        activeProps={{
                                            className:
                                                'bg-white bg-opacity-30 cursor-default flex p-2 rounded-md text-nowrap',
                                            href: '',
                                        }}
                                        inactiveProps={{
                                            className:
                                                'hover:bg-white hover:bg-opacity-20 cursor-pointer flex p-2 rounded-md text-nowrap',
                                        }}
                                        key={title}
                                        activeOptions={{
                                            exact: true,
                                            includeSearch: false,
                                        }}
                                    >
                                        {title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="h-px bg-white bg-opacity-20 my-1" />
                    {isAuthenticated && (
                        <Link
                            className="hover:bg-white hover:bg-opacity-20 cursor-pointer flex p-2 rounded-md text-nowrap"
                            to={'/auth/logout'}
                        >
                            Logout
                        </Link>
                    )}
                </div>
            </HamburgerMenu>
        </div>
    );
};
