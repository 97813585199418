import ArrowSvg from '@/svgs/arrow.svg?react';
import { cn } from '@/utils';
import { Tooltip } from '@analytical-alley/ui';
import * as React from 'react';
import { HTMLAttributes, ReactNode, useCallback } from 'react';
import { UseSplitDataConfig, useSplitData } from './useSplitData';

// I have min value, split value and max value, I want to split the data into two parts so that
// total of elements in both parts together is equal to 100. Data is distributed evenly but first and last element
// for left are min and split and for right are split and max.

const Arrow = ({
    variant = 'primary',
    direction,
    className,
    onHovered,
    ...props
}: {
    onHovered?: (hovered: boolean) => void;
    variant?: 'secondary' | 'primary' | 'danger' | 'success';
    direction: 'up' | 'down' | 'left' | 'right';
} & HTMLAttributes<HTMLSpanElement>) => {
    const arrowColor = {
        primary: '#1b7c98',
        secondary: '#a4a4a4',
        danger: '#FF475A',
        success: '#73EFAC',
    };

    const directionClasses = {
        up: '',
        down: 'rotate-180',
        left: '-rotate-90',
        right: 'rotate-90',
    };

    return (
        <span
            onMouseEnter={() => onHovered?.(true)}
            onMouseLeave={() => onHovered?.(false)}
            {...props}
            className={`absolute ${className}`}
        >
            <ArrowSvg
                fill={arrowColor[variant]}
                className={directionClasses[direction]}
            />
            {/*<ArrowSvg fill="#FF475A" className={directionClasses[direction]} />*/}
        </span>
    );
};

const Item = ({
    onHovered,
    className,
    ...props
}: {
    onHovered?: (hovered: boolean) => void;
} & HTMLAttributes<HTMLSpanElement>) => {
    return (
        <span
            {...props}
            onMouseEnter={() => onHovered?.(true)}
            onMouseLeave={() => onHovered?.(false)}
            className="z-10 w-[1%]"
        />
    );
};

type HoveredState<T> =
    | {
          hoveredValue: T;
          isCurrentValueHovered: false;
      }
    | {
          hoveredValue: number;
          isCurrentValueHovered: true;
      };

type TooltipContentCb<T> = ({
    hoveredValue,
    isCurrentValueHovered,
}: HoveredState<T>) => ReactNode;

const ArrowPointer = ({
    onHovered,
    direction,
    style,
}: {
    direction: 'up' | 'down';
    onHovered: (hovered: boolean) => void;
} & Pick<HTMLAttributes<HTMLSpanElement>, 'style'>) => {
    if (direction === 'up') {
        return (
            <Arrow
                onHovered={onHovered}
                variant="success"
                className="z-20 bottom-0 -translate-x-1/2"
                direction="up"
                style={style}
            />
        );
    }

    return (
        <Arrow
            onHovered={onHovered}
            className="z-20 -translate-x-1/2"
            variant="danger"
            direction="down"
            style={style}
        />
    );
};

export const BudgetSlider = ({
    value,
    config,
    tooltipContent,
}: {
    value: number;
    config: UseSplitDataConfig;
    tooltipContent: TooltipContentCb<{
        value: number;
        isProfitable: boolean;
    }>;
}) => {
    const { startsWithProfit } = config;

    const [hovered, setHovered] = React.useState<HoveredState<{
        value: number;
        isProfitable: boolean;
    }> | null>(null);

    const {
        valueIndex,
        isOverFlowing,
        splitIndex,
        leftSideData,
        rightSideData,
    } = useSplitData(value, config);

    const ValueArrow = useCallback(
        ({
            style,
            onHovered,
        }: { onHovered: (hovered: boolean) => void } & Pick<
            HTMLAttributes<HTMLSpanElement>,
            'style'
        >) => {
            if (rightSideData.length === 0) {
                return (
                    <ArrowPointer
                        onHovered={onHovered}
                        direction={startsWithProfit ? 'up' : 'down'}
                        style={style}
                    />
                );
            }

            if (valueIndex > splitIndex) {
                return (
                    <ArrowPointer
                        onHovered={onHovered}
                        direction={startsWithProfit ? 'down' : 'up'}
                        style={style}
                    />
                );
            }

            return (
                <ArrowPointer
                    onHovered={onHovered}
                    direction={startsWithProfit ? 'up' : 'down'}
                    style={style}
                />
            );
        },
        [rightSideData.length, valueIndex, splitIndex, startsWithProfit],
    );

    return (
        <Tooltip placement={'right-end'}>
            <Tooltip.Trigger asChild>
                <div className="flex w-full h-6 relative mx-5">
                    <span
                        className={cn(
                            'mt-2 h-2 absolute rounded-2xl opacity-80',
                            {
                                'bg-gradient-to-r from-[#FF2D5E8C] from-20% to-[#FF2D5E]':
                                    !startsWithProfit,
                                'bg-gradient-to-r from-[#73EFAC8C] from-20% to-[#73EFAC]':
                                    startsWithProfit,
                            },
                        )}
                        style={{
                            width: `${leftSideData.length}%`,
                        }}
                    />
                    <span
                        className={cn(
                            'right-0 mt-2 h-2 absolute rounded-2xl opacity-70',
                            {
                                'bg-gradient-to-r from-[#FF2D5E8C] from-20% to-[#FF2D5E]':
                                    startsWithProfit,
                                'bg-gradient-to-r from-[#73EFAC8C] from-20% to-[#73EFAC]':
                                    !startsWithProfit,
                            },
                        )}
                        style={{
                            width: `${rightSideData.length}%`,
                        }}
                    />
                    {leftSideData.map((val, i) => (
                        <Item
                            onHovered={(hovered) => {
                                setHovered(
                                    hovered
                                        ? {
                                              hoveredValue: val,
                                              isCurrentValueHovered: false,
                                          }
                                        : null,
                                );
                            }}
                            key={i}
                            data-testid={val.value}
                        />
                    ))}
                    {rightSideData.map((val, i) => (
                        <Item
                            onHovered={(hovered) => {
                                setHovered(
                                    hovered
                                        ? {
                                              hoveredValue: val,
                                              isCurrentValueHovered: false,
                                          }
                                        : null,
                                );
                            }}
                            data-testid={val.value}
                            key={i}
                        />
                    ))}
                    {valueIndex != -1 && (
                        <ValueArrow
                            onHovered={(hovered) => {
                                setHovered(
                                    hovered
                                        ? {
                                              hoveredValue: value,
                                              isCurrentValueHovered: true,
                                          }
                                        : null,
                                );
                            }}
                            style={{
                                left: isOverFlowing
                                    ? undefined
                                    : `${valueIndex}%`,
                                right: isOverFlowing ? -20 : undefined,
                            }}
                        />
                    )}
                </div>
            </Tooltip.Trigger>
            {hovered && (
                <Tooltip.Content>{tooltipContent(hovered)}</Tooltip.Content>
            )}
        </Tooltip>
    );
};
