import { useModelContributionRoute } from '@/dashboards/ModelContributions/providers';
import { useRouteUrl } from '@/hooks';

export const useModelContributionUrl = () => {
    const { route } = useModelContributionRoute();

    return useRouteUrl(route);
};

export type UseModelContributionUrlReturn = ReturnType<
    typeof useModelContributionUrl
>;
