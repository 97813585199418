import { Header } from '@/components/Header';
import { CompetitorMediaEffectOnClientRevenueSection } from '@/dashboards/CompetitorsMediaInvestment/CompetitorMediaEffectOnClientRevenueSection';
import { CompetitorMediaEffectSection } from '@/dashboards/CompetitorsMediaInvestment/CompetitorMediaEffectSection';
import { CompetitorMediaInvestmentSection } from '@/dashboards/CompetitorsMediaInvestment/CompetitorMediaInvestmentSection';
import { CompetitorsMediaSeasonalitySection } from '@/dashboards/CompetitorsMediaInvestment/CompetitorsMediaSeasonalitySection';
import { useCompetitorsMediaInvestmentQuery } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentQuery';
import { useCompetitorsMediaInvestmentUrl } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentUrl';
import { CompetitorsMediaInvestmentSearch } from '@/dashboards/CompetitorsMediaInvestment/index';
import { CompetitorsMediaInvestmentProvider } from '@/dashboards/CompetitorsMediaInvestment/providers';
import { DateRange } from '@/dashboards/ModelContributions/components/ActionBar/DateRange';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { TabButtons } from '@analytical-alley/ui';
import moment from 'moment';
import React from 'react';

export const CompetitorsMediaInvestment = () => {
    const {
        queryResult: { isLoading },
        tabsToRender,
        onActiveTabChange,
        activeTabIndex,
    } = useCompetitorsMediaInvestmentQuery();

    const { search, setSearch } = useCompetitorsMediaInvestmentUrl();

    const { endDate, startDate } = useDashboardGlobalContext();

    const onSetSearch = (search: Partial<CompetitorsMediaInvestmentSearch>) => {
        setSearch(search);
    };

    const minDate = moment.utc(startDate, 'DD-MM-YYYY').toDate();
    const maxDate = moment.utc(endDate, 'DD-MM-YYYY').toDate();

    return (
        <CompetitorsMediaInvestmentProvider>
            <Header
                className="pb-6 px-16 pt-6"
                title="Competitor's Media Investment"
            >
                <div className="flex gap-4">
                    <DateRange
                        minDate={minDate}
                        maxDate={maxDate}
                        searchStartDate={search.startDate}
                        searchEndDate={search.endDate}
                        onSetSearch={onSetSearch}
                    />
                </div>
                <TabButtons
                    aria-label="Periodicity"
                    style="pills"
                    className="gap-2 px-2 justify-center"
                    onActiveTabChange={onActiveTabChange}
                    isLoading={isLoading}
                    activeTab={activeTabIndex}
                >
                    {tabsToRender?.map((periodName) => {
                        return (
                            <TabButtons.Item
                                key={periodName}
                                title={capitalizeFirstLetter(periodName)}
                                disabled={isLoading}
                            />
                        );
                    })}
                </TabButtons>
            </Header>
            <div className="pb-8 px-16">
                <div className="flex flex-row h-full">
                    <div className="flex gap-4 w-full xl:flex-row">
                        <div className="w-6/12 max-2xl:w-full">
                            <CompetitorMediaInvestmentSection />
                            <div className="mt-6">
                                <CompetitorMediaEffectOnClientRevenueSection />
                            </div>
                        </div>
                        <div className="h-full w-6/12 max-2xl:w-full">
                            <CompetitorsMediaSeasonalitySection />
                            <div className="mt-6">
                                <CompetitorMediaEffectSection />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CompetitorsMediaInvestmentProvider>
    );
};
