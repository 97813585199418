import { PercentSimpleItem } from '@/components/PercentISimpletem';
import { roundToDecimals } from '@/utils/numberUtils';
import {
    FC,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { BaseChart, ChartMouseHandler, useChart } from '@/components/ECharts';
import { HighlightLegend } from '@/components/ECharts/HightlightLegend';
import { EChartsOption } from '@/components/ECharts/useChart';
import { useChartsContext } from '@/context/chartsThemeContext';
import { NumberFormatter, useConfigContext } from '@/context/configContext';
import { useModelContributionContext } from '@/dashboards/ModelContributions/hooks';
import { ContributionAndSpendByPeriod } from '@/dashboards/types';
import { jsxToHtml } from '@/utils/reactUtils';
import { Typography } from '@analytical-alley/ui';
import { BarSeriesOption, LineSeriesOption } from 'echarts';
import { isArray } from 'lodash';

type AreaTooltipProps = {
    seriesName: string;
    color: string;
    value: number;
    axisValueLabel: string;
}[];

type SeriesOption = LineSeriesOption | BarSeriesOption;

type ChartOptions = EChartsOption<SeriesOption>;

const AreaTooltip = ({
    modelBreakdown,
    params,
    selectedSeriesName,
    formatNumber,
}: {
    modelBreakdown: 'category' | 'variable';
    params: AreaTooltipProps;
    selectedSeriesName?: string;
    formatNumber: NumberFormatter;
}) => {
    if (!selectedSeriesName) {
        return null;
    }

    const hoveredItem = params.find(
        (param) => param.seriesName === selectedSeriesName,
    );

    if (!hoveredItem) {
        return null;
    }

    const totalSeriesContribution = params.reduce(
        (acc, { value }) => acc + value,
        0,
    );

    const contributionPercent = roundToDecimals(
        (hoveredItem.value / totalSeriesContribution) * 100,
    );

    return (
        <div className="tooltip-content">
            <table>
                <tbody>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                {modelBreakdown === 'category'
                                    ? 'Category'
                                    : 'Variable'}
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {hoveredItem.seriesName}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Chosen period:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {params[0]?.axisValueLabel}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Contribution:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {formatNumber(hoveredItem.value)}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Contribution %:
                            </Typography>
                        </td>
                        <td>
                            <PercentSimpleItem
                                percent={contributionPercent}
                                variant="bodyM"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const getEChartsOptions = ({
    seriesData,
    xAxisData,
    isAreaChart,
    formatNumber,
}: {
    xAxisData: string[];
    seriesData: ContributionAndSpendByPeriod;
    isAreaChart: boolean;
    formatNumber: NumberFormatter;
}): ChartOptions => {
    const dates = xAxisData.filter((date, index) => {
        return xAxisData.indexOf(date) === index;
    });

    const series: ChartOptions['series'] = Object.entries(seriesData).map(
        ([key, { data, color }], index) => {
            return {
                name: key,
                type: isAreaChart && dates.length > 1 ? 'line' : 'bar',
                stack: 'Total',
                symbolSize: 2,
                showSymbol: false,
                showAllSymbol: false,
                triggerLineEvent: true,
                color: color,
                barMaxWidth: 50,
                itemStyle: {
                    borderRadius:
                        index === Object.keys(seriesData).length - 1
                            ? [5, 5, 0, 0]
                            : 0,
                },
                data: Object.entries(data).map(([name, { contribution }]) => ({
                    name,
                    value: contribution,
                })),
            } as unknown as ChartOptions['series'][0];
        },
    );

    return {
        animation: false,
        tooltip: {
            trigger: 'axis',
            triggerOn: 'mousemove|click',
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            type: 'item',
            confine: true,
            textStyle: {
                fontSize: 12,
            },
            extraCssText: 'max-width: 600px; white-space: pre-wrap;',
            formatter: '',
        },
        /*dataZoom: [
            {
                id: 'dataZoomX',
                type: 'slider',
                xAxisIndex: [0],
                textStyle: { show: false },
                labelFormatter: '',
            },
        ],*/
        legend: {
            show: true,
            showCustom: true,
        },
        grid: {
            right: '5%',
            left: '10%',
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: !isAreaChart,
                data: dates,
            },
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    formatter: (value: number) =>
                        formatNumber(value, {
                            locale: 'en-US',
                            notation: 'compact',
                        }),
                },
            },
        ],
        series: series,
    };
};

interface ContributionAreaChartProps {
    className?: string;
    isLoading: boolean;
}

export const ContributionAreaChart: FC<ContributionAreaChartProps> = memo(
    ({ className, isLoading }) => {
        const { formatNumber } = useConfigContext();
        const {
            contributionData,
            selected,
            getVisibleData,
            handleSelectedChange,
            search: { period, modelBreakdown },
        } = useModelContributionContext();

        const { theme } = useChartsContext();
        const selectedRef = useRef(selected);
        const [hovered, setHovered] = useState('');

        useEffect(() => {
            selectedRef.current = selected;
        }, [selected]);

        const eChartsOptions = useMemo(() => {
            return getEChartsOptions({
                isAreaChart: period
                    ? ['daily', 'weekly'].includes(period)
                    : false,
                seriesData: getVisibleData(),
                xAxisData: contributionData.dates,
                formatNumber,
            });
            // Should include theme in the dependency array because when theme changes, the chart options should be re-set
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [theme, contributionData, getVisibleData, period, formatNumber]);

        const timeoutRef = useRef<number | null>(null);

        const onClick: ChartMouseHandler = useCallback(
            (context) => {
                if (context.seriesName) {
                    handleSelectedChange((prev) => {
                        if (prev || prev === context.seriesName) {
                            return '';
                        }
                        return context.seriesName;
                    });
                    setHovered(context.seriesName);
                } else {
                    handleSelectedChange('');
                }
            },
            [handleSelectedChange],
        );

        const onMouseOver: ChartMouseHandler = useCallback((context) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (context.seriesName) {
                setHovered(
                    selectedRef.current
                        ? selectedRef.current
                        : context.seriesName,
                );
            }
        }, []);

        const onMouseOut: ChartMouseHandler = useCallback(() => {
            if (selectedRef.current) {
                return;
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = window.setTimeout(() => {
                setHovered('');
            }, 0);
        }, []);

        const {
            ref: chartRef,
            chartOptions,
            onEvents,
        } = useChart({
            chartOptions: eChartsOptions,
            onClick,
            onMouseOver,
            onMouseOut,
        });

        useEffect(() => {
            const instance = chartRef.current?.getEchartsInstance();

            if (instance) {
                instance.setOption({
                    tooltip: {
                        showDelay: selected ? 0 : 50,
                        formatter: (params: AreaTooltipProps) => {
                            return jsxToHtml(
                                <AreaTooltip
                                    formatNumber={formatNumber}
                                    modelBreakdown={modelBreakdown}
                                    params={params}
                                    selectedSeriesName={hovered}
                                />,
                            );
                        },
                    },
                });
            }
        }, [
            chartRef,
            hovered,
            theme,
            modelBreakdown,
            getVisibleData,
            selected,
        ]);

        useEffect(() => {
            const instance = chartRef.current?.getEchartsInstance();

            const series =
                eChartsOptions?.series && isArray(eChartsOptions.series)
                    ? eChartsOptions.series
                    : [];

            if (instance) {
                instance.setOption({
                    series: series.map((seriesOption) => {
                        if (selected && selected !== seriesOption.name) {
                            return {
                                ...seriesOption,
                                areaStyle: { opacity: 0.1 },
                                itemStyle: { opacity: 0.1 },
                                lineStyle: { opacity: 0 },
                                symbol: 'none',
                            };
                        }
                        return {
                            ...seriesOption,
                            areaStyle: { opacity: 0.8 },
                            itemStyle: { opacity: 0.8 },
                            lineStyle: { opacity: 0.6 },
                            symbol: 'circle',
                        };
                    }),
                });
            }
            if (selected) {
                setHovered(selected);
            }
        }, [chartRef, eChartsOptions.series, selected]);

        if (isLoading) {
            return (
                <div className={className}>
                    <div className="flex items-center justify-center w-full h-[42rem]" />
                </div>
            );
        }

        return (
            <div className={className}>
                <div className="relative w-full h-[42rem]">
                    <BaseChart
                        $shouldExpand
                        className="pt-3"
                        ref={chartRef}
                        option={chartOptions}
                        onEvents={onEvents}
                    />
                </div>
                <div className="relative pl-14 pr-4 z-20 -mt-7">
                    <HighlightLegend
                        highlighted={selected}
                        legendItems={chartOptions.series.map((s) => ({
                            name: String(s.name),
                            highlighted: true,
                            color: s.color,
                        }))}
                        onClick={(name) => {
                            if (selected === name) {
                                handleSelectedChange('');
                                return;
                            }
                            handleSelectedChange(name);
                        }}
                    />
                </div>
            </div>
        );
    },
);

ContributionAreaChart.displayName = 'ContributionAreaChart';
