import { Ref, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { TreeSelect } from '@/components/TreeSelect';
import { TreeSelectRef } from '@/components/TreeSelect/TreeSelect';
import { TreeNodeLike } from '@/components/TreeSelect/useTreeSelect/types';
import { ModelContributionsSearch } from '@/dashboards/ModelContributions';

export type FilterNode = {
    name: string;
    type: 'category' | 'variable';
};

export type FilterRef = {
    focus?: TreeSelectRef['focus'];
};

type FilterProps = {
    nodes: TreeNodeLike[];
    onSetSelectedNodes: (nodes: FilterNode[]) => void;
    onSetSearch: (search: Partial<ModelContributionsSearch>) => void;
    searchFilter: string[] | undefined;
};

export const Filter = forwardRef(
    (
        { nodes, onSetSelectedNodes, searchFilter, onSetSearch }: FilterProps,
        ref: Ref<FilterRef>,
    ) => {
        const initialFiltersRef = useRef<string[] | undefined>(searchFilter);

        useEffect(() => {
            initialFiltersRef.current = searchFilter;
        }, [searchFilter]);

        const shouldRun = useRef(false);

        const treeRef = useRef<TreeSelectRef>(null);

        useImperativeHandle(ref, () => treeRef.current!, []);

        useEffect(() => {
            if (!nodes?.length) return;
            const parsedFilter =
                initialFiltersRef.current?.reduce((acc, key) => {
                    acc[key] = true;
                    return acc;
                }, {}) || undefined;

            treeRef.current?.setNodes?.(nodes, parsedFilter);

            shouldRun.current = true;
        }, [nodes]);

        useEffect(() => {
            if (shouldRun.current) {
                const parsedFilter =
                    searchFilter?.reduce((acc, key) => {
                        acc[key] = true;
                        return acc;
                    }, {}) || undefined;

                if (parsedFilter) {
                    treeRef.current?.setChecked?.(parsedFilter);
                }
            }
        }, [searchFilter]);

        return (
            <TreeSelect
                dataNodes={nodes}
                placement={'bottom-end'}
                ref={treeRef}
                compact
                label={'Filter'}
                onCheckedChange={(checkedNodes, checked) => {
                    onSetSelectedNodes(
                        checkedNodes.map((node) => {
                            return {
                                name: node.label,
                                type: node.parent ? 'variable' : 'category',
                            };
                        }),
                    );

                    const keys = Object.keys(checked);

                    const checkedKeys = keys.filter((key) => checked[key]);

                    const filter =
                        checkedKeys.length === keys.length
                            ? undefined
                            : checkedKeys;

                    onSetSearch({
                        filter,
                    });
                }}
            />
        );
    },
);

Filter.displayName = 'Filter';
