export const roundToDecimals = (value: number, decimals: number = 2) =>
    Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);

export const formatNumber = (value: number | string) => {
    return Intl.NumberFormat('et-EE').format(Number(value));
};

/**
 * Round to the nearest
 * @param value - number to round
 * @param digitsFromLeft - digits from left to round to - default 2
 * @returns rounded number
 * @example roundToNearest(123456) => 130000
 * @example roundToNearest(123456, 4) => 123500
 * @example roundToNearest(123456, 5) => 123460
 */
export const roundToNearest = (value: number, digitsFromLeft = 2) => {
    const num = Math.abs(roundToDecimals(value, 0));

    const divider = Math.pow(
        10,
        num.toString().length > digitsFromLeft
            ? num.toString().length - digitsFromLeft
            : 1,
    );

    const roundedNum = Math.ceil((num + 1) / divider) * divider;

    return value < 0 ? -roundedNum : roundedNum;
};
