import { BudgetRowValues } from '@/components/ModifyBudgetRow/ModifyBudgetRow';
import { datasheetVariablesQueries } from '@/duckdb/datasheet-variables';
import { modelContributionsQueries } from '@/duckdb/model-contributions';
import { spendsHistoryQueries } from '@/duckdb/spends-history';
import { optimizerService, optimizerServiceManager } from '@/optimizer';
import { Prettify } from '@analytical-alley/ui/types';
import moment from 'moment';

export async function getRoiCurves() {
    const { xAxis, variables } = optimizerService.getRoiCurvesData();

    const variablesAndCategories =
        await datasheetVariablesQueries.getVariablesAndCategories(
            Object.keys(variables),
        );

    return {
        curves: variables,
        xAxis: xAxis,
        variablesAndCategories,
    };
}

export type GetBudgetOptimizationReturn = Awaited<
    ReturnType<typeof getBudgetOptimization>
>;

export async function getBudgetOptimization({
    variables,
    budget,
    startDate,
    endDate,
}: {
    budget: number | undefined;
    variables?: { [key: string]: BudgetRowValues };
    startDate?: string;
    endDate?: string;
}) {
    const parsedVariables = Object.entries(variables || {}).reduce(
        (acc, [key, value]) => {
            if (!value.locked || value.investment == null) {
                return acc;
            }
            acc[key] = value.investment;
            return acc;
        },
        {} as Record<string, number>,
    );

    return await optimizerServiceManager.getOptimizedPlan({
        budget,
        startDate,
        endDate,
        variables: parsedVariables,
    });
}

export type GetPreviousDataReturn = Prettify<
    Awaited<ReturnType<typeof getPreviousData>>
>;

export async function getPreviousData({
    startDate: startDateArg,
    endDate: endDateArg,
    interval,
}: {
    interval: 'daily' | 'weekly';
    startDate?: string;
    endDate?: string;
}) {
    const startDate = startDateArg
        ? moment(startDateArg, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : undefined;
    const endDate = endDateArg
        ? moment(endDateArg, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : undefined;

    const totalContributionPromise =
        modelContributionsQueries.getTotalContribution({
            period: interval,
            startDate,
            endDate,
        });

    const mediaContributionPromise =
        modelContributionsQueries.getTotalContribution({
            period: interval,
            startDate,
            endDate,
            onlyMedia: true,
        });

    const contributionByCategoryPromise =
        modelContributionsQueries.getContributionsByCategory({
            startDate,
            endDate,
        });

    const [
        totals,
        rows,
        roiCurves,
        totalContribution,
        mediaContribution,
        contributionsByCategory,
    ] = await Promise.all([
        spendsHistoryQueries.getTotals({
            interval,
            startDate,
            endDate,
        }),
        spendsHistoryQueries.getRows({
            interval,
            period: 'month',
            startDate,
            endDate,
        }),
        getRoiCurves(),
        totalContributionPromise,
        mediaContributionPromise,
        contributionByCategoryPromise,
    ]);

    return {
        totals,
        rows,
        roiCurves,
        totalContribution,
        mediaContribution,
        contributionsByCategory,
    };
}
