import { colors } from '@/components/ECharts/colors';
import { CompetitorsMediaInvestmentSearch } from '@/dashboards/CompetitorsMediaInvestment';
import { CompetitorsMediaInvestmentDataKeys } from '@/dashboards/CompetitorsMediaInvestment/api';
import { useCompetitorsMediaInvestmentQuery } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentQuery';
import { useCompetitorsMediaInvestmentUrl } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentUrl';
import { RowValueType } from '@/dashboards/ModelContributions/types';
import { makeDateFormatter } from '@/utils/dateUtils';
import { memoize } from 'lodash';
import moment from 'moment';
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { createContext } from 'use-context-selector';

export type CompetitorImpact = 'negative' | 'positive' | 'neutral';

type FilterTypes = {
    name: string;
    label: string;
    category?: string;
    selected: boolean;
};

export type FormattedCompetitorsMediaInvestmentData = {
    investmentDataByVariable: Map<
        string,
        {
            data: { [key: string]: number };
            color: string;
            competitorImpactToClient: CompetitorImpact;
        }
    >;
    investmentDataByCategory: Map<
        string,
        {
            data: { [key: string]: number };
            color: string;
        }
    >;
    totalInvestment: number;
    totalInvestmentByCategory: Map<string, number>;
    totalInvestmentByVariable: Map<string, number>;
    variableToCategory: Map<string, string>;
    categoryFilters: FilterTypes[];
    competitorFilters: FilterTypes[];
    variableData: {
        variable: string;
        category: string;
        color: string;
        competitorImpactToClient: CompetitorImpact;
    }[];
    categoryData: {
        category: string;
        color: string;
    }[];
    dates: string[];
};

export const CompetitorsMediaInvestmentContext = createContext<{
    competitorsMediaInvestmentData: FormattedCompetitorsMediaInvestmentData;
    handleSelectedChange: Dispatch<SetStateAction<string>>;
    selectedCategoryFilter: FilterTypes[];
    getVisibleInvestmentData: () => {
        [key: string]: {
            data: { [key: string]: number };
            color: string;
            competitorImpactToClient: string;
        };
    };
    selected: string;
    setSelectedCategory: (name: string, checked: boolean) => void;
    selectedCompetitorFilter: FilterTypes[];
    setSelectedCompetitor: (name: string, checked: boolean) => void;
    period: CompetitorsMediaInvestmentSearch['period'];
}>({
    competitorsMediaInvestmentData:
        {} as FormattedCompetitorsMediaInvestmentData,
    handleSelectedChange: () => {},
    getVisibleInvestmentData: () => ({}),
    selected: '',
    setSelectedCategory: () => {},
    selectedCategoryFilter: [],
    selectedCompetitorFilter: [],
    setSelectedCompetitor: () => {},
    period: 'weekly',
});

function getColor(index: number) {
    return colors[index % colors.length]!;
}

const competitorsImpact: CompetitorImpact[] = [
    'neutral',
    'positive',
    'negative',
];

function getRandomImpact(index: number) {
    return competitorsImpact[index % competitorsImpact.length] || 'neutral';
}

const groupCompetitorsMediaInvestmentData = memoize(
    (
        rows: RowValueType,
        fieldKeys: CompetitorsMediaInvestmentDataKeys,
        {
            formatDate,
            startDate,
            endDate,
        }: {
            formatDate: ((date: string) => string) | undefined;
            startDate: string | undefined;
            endDate: string | undefined;
            cacheKey: string;
        },
    ): FormattedCompetitorsMediaInvestmentData => {
        let categoryColorIndex = 0;

        const categoryData: FormattedCompetitorsMediaInvestmentData['categoryData'] =
            [];
        const variableData: FormattedCompetitorsMediaInvestmentData['variableData'] =
            [];
        const dates: Set<string> = new Set();

        const usedCategoryVariableCombinations = new Set<string>();

        const startDateUtc = moment.utc(startDate, 'DD-MM-YYYY');
        const endDateUtc = moment.utc(endDate, 'DD-MM-YYYY');

        const data = rows.reduce(
            (acc, item, currentIndex) => {
                const variable = item[fieldKeys.variable]!.value.formatted;
                const category = item[fieldKeys.category]!.value.formatted;
                const dateString = item[fieldKeys.date]!.value.formatted;

                const dateStringUtc = moment(dateString, 'DD-MM-YYYY');

                if (
                    startDate &&
                    !dateStringUtc.isSame(startDateUtc, 'day') &&
                    dateStringUtc.isBefore(startDateUtc)
                ) {
                    return acc;
                }

                if (
                    endDate &&
                    !dateStringUtc.isSame(endDateUtc, 'day') &&
                    dateStringUtc.isAfter(endDateUtc)
                ) {
                    return acc;
                }

                const date = formatDate ? formatDate(dateString) : dateString;

                dates.add(date);

                const investment = isNaN(
                    Number(item[fieldKeys.investment]!.value.raw),
                )
                    ? 0
                    : Number(item[fieldKeys.investment]!.value.raw);

                if (!acc.investmentDataByVariable.has(variable)) {
                    const color = getColor(currentIndex);
                    const competitorImpactToClient: CompetitorImpact =
                        getRandomImpact(currentIndex);
                    variableData.push({
                        variable: item[fieldKeys.variable]!.value.formatted,
                        category: item[fieldKeys.category]!.value.formatted,
                        color,
                        competitorImpactToClient,
                    });
                    acc.investmentDataByVariable.set(variable, {
                        data: {},
                        color,
                        competitorImpactToClient,
                    });
                    acc.competitorFilters.push({
                        name: variable,
                        label: variable,
                        category: category,
                        selected: true,
                    });
                }

                if (!acc.investmentDataByCategory.has(category)) {
                    const color = getColor(categoryColorIndex);
                    categoryData.push({
                        category: item[fieldKeys.category]!.value.formatted,
                        color,
                    });
                    acc.investmentDataByCategory.set(category, {
                        data: {},
                        color,
                    });
                    acc.totalInvestmentByCategory.set(category, 0);
                    acc.categoryFilters.push({
                        name: category,
                        label: category,
                        selected: true,
                    });
                    categoryColorIndex++;
                }

                acc.investmentDataByVariable.get(variable)!.data[date] =
                    (acc.investmentDataByVariable.get(variable)!.data[date] ||
                        0) + investment || investment;

                acc.investmentDataByCategory.get(category)!.data[date] =
                    (acc.investmentDataByCategory.get(category)!.data[date] ||
                        0) + investment || investment;

                acc.totalInvestment += investment;
                acc.totalInvestmentByCategory.set(
                    category,
                    acc.totalInvestmentByCategory.get(category)! + investment,
                );
                acc.totalInvestmentByVariable.set(
                    variable,
                    (acc.totalInvestmentByVariable.get(variable) || 0) +
                        investment,
                );

                acc.variableToCategory.set(variable, category);

                if (
                    !usedCategoryVariableCombinations.has(
                        `${category}-${variable}`,
                    )
                ) {
                    usedCategoryVariableCombinations.add(
                        `${category}-${variable}`,
                    );
                }

                return acc;
            },
            {
                investmentDataByVariable: new Map<
                    string,
                    {
                        data: { [key: string]: number };
                        color: string;
                        competitorImpactToClient: CompetitorImpact;
                    }
                >(),
                investmentDataByCategory: new Map<
                    string,
                    {
                        data: { [key: string]: number };
                        color: string;
                    }
                >(),
                totalInvestment: 0,
                totalInvestmentByCategory: new Map<string, number>(),
                totalInvestmentByVariable: new Map<string, number>(),
                variableToCategory: new Map<string, string>(),
                variableData: [],
                categoryData: [],
                categoryFilters: [] as FilterTypes[],
                competitorFilters: [] as FilterTypes[],
            },
        );

        return Object.assign(data, {
            variableData,
            categoryData,
            dates: Array.from(dates),
        });
    },
    (_0, _1, options) => JSON.stringify({ options }),
);

export const CompetitorsMediaInvestmentProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [selected, setSelected] = useState<string>('');
    const [selectedCategoryFilter, setSelectedCategoryFilter] = useState<
        FilterTypes[]
    >([]);
    const [selectedCompetitorFilter, setSelectedCompetitorFilter] = useState<
        FilterTypes[]
    >([]);
    const { search, params } = useCompetitorsMediaInvestmentUrl();

    const { queryResult } = useCompetitorsMediaInvestmentQuery();

    const { data } = queryResult;

    const competitorsMediaInvestmentData = useMemo(() => {
        if (!data?.rows || !data?.fieldKeys) {
            return {
                investmentDataByVariable: new Map(),
                investmentDataByCategory: new Map(),
                totalInvestment: 0,
                totalInvestmentByCategory: new Map(),
                totalInvestmentByVariable: new Map(),
                variableToCategory: new Map(),
                variableData: [],
                categoryData: [],
                categoryFilters: [],
                competitorFilters: [],
                dates: [],
            };
        }
        return groupCompetitorsMediaInvestmentData(data.rows, data.fieldKeys, {
            formatDate: makeDateFormatter(search.period),
            startDate: search.startDate,
            endDate: search.endDate,
            cacheKey: `${params.projectId}-${search.period}`,
        });
    }, [
        data?.fieldKeys,
        data?.rows,
        params.projectId,
        search.endDate,
        search.period,
        search.startDate,
    ]);

    const {
        investmentDataByVariable,
        variableToCategory,
        categoryFilters,
        competitorFilters,
    } = competitorsMediaInvestmentData;

    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        setSelectedCategoryFilter(categoryFilters);
        setSelectedCompetitorFilter(competitorFilters);
        setSelected('');
    }, [categoryFilters, competitorFilters, search.period]);

    const setSelectedCategory = (name: string, checked: boolean) => {
        const updatedCategoryFilters = selectedCategoryFilter.map(
            (categoryObj) => {
                if (name === 'all') {
                    return { ...categoryObj, selected: checked };
                }
                if (categoryObj.name === name) {
                    return { ...categoryObj, selected: checked };
                }
                return categoryObj;
            },
        );

        //Remove competitors from the filters list if media category is unselected
        const updatedCompetitorFilters = competitorFilters.filter(
            (competitorObj) => {
                return updatedCategoryFilters.some(
                    (categoryObj) =>
                        competitorObj.category === categoryObj.name &&
                        categoryObj.selected,
                );
            },
        );

        setSelectedCategoryFilter(updatedCategoryFilters);
        setSelectedCompetitorFilter(updatedCompetitorFilters);
    };

    const setSelectedCompetitor = (name: string, checked: boolean) => {
        const filters = selectedCompetitorFilter.map((competitorObj) => {
            if (name === 'all') {
                return { ...competitorObj, selected: checked };
            }
            if (competitorObj.name === name) {
                return { ...competitorObj, selected: checked };
            }
            return competitorObj;
        });

        setSelectedCompetitorFilter(filters);
    };

    const getVisibleInvestmentData = useCallback(() => {
        return Object.fromEntries(
            Array.from(investmentDataByVariable).filter(([name, _]) => {
                return (
                    selectedCategoryFilter.some(
                        (filterObj) =>
                            filterObj.name === variableToCategory.get(name)! &&
                            filterObj.selected,
                    ) &&
                    selectedCompetitorFilter.some(
                        (filterObj) =>
                            filterObj.name === name && filterObj.selected,
                    )
                );
            }),
        );
    }, [
        investmentDataByVariable,
        selectedCategoryFilter,
        selectedCompetitorFilter,
        variableToCategory,
    ]);

    return (
        <CompetitorsMediaInvestmentContext.Provider
            value={{
                getVisibleInvestmentData,
                competitorsMediaInvestmentData: competitorsMediaInvestmentData,
                selected,
                handleSelectedChange: setSelected,
                setSelectedCategory,
                selectedCategoryFilter,
                selectedCompetitorFilter,
                setSelectedCompetitor,
                period: search.period,
            }}
        >
            {children}
        </CompetitorsMediaInvestmentContext.Provider>
    );
};
