import {
    RegisteredRouter,
    RouteApi,
    RouteIds,
    useNavigate,
} from '@tanstack/react-router';
import { useCallback } from 'react';

export const useRouteUrl = <
    TId extends RouteIds<RegisteredRouter['routeTree']>,
>(
    route: RouteApi<TId>,
) => {
    const routeSearch = route.useSearch();

    const navigate = useNavigate();

    const setSearch = useCallback(
        (
            search: Partial<ReturnType<(typeof route)['useSearch']>>,
            options?: { replace?: boolean },
        ) => {
            navigate({
                ...(options ? options : {}),
                search: {
                    ...routeSearch,
                    ...search,
                },
                replace: true,
            });
        },
        [navigate, routeSearch],
    );

    const params = route.useParams();

    return { params, search: routeSearch, setSearch };
};
