import {
    IconArrowsDiagonal,
    IconArrowsDiagonalMinimize2,
} from '@tabler/icons-react';
import React from 'react';

export const FullScreenButton = ({
    isFullScreen,
    onClick,
    ...props
}: {
    isFullScreen: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
} & Omit<
    React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >,
    'onClick'
>) => {
    return (
        <button
            className="glass-tile w-8 h-8 flex justify-center items-center p-2 rounded-md shadow-lg hover:bg-opacity-30"
            onMouseDown={onClick}
            {...props}
        >
            {isFullScreen ? (
                <IconArrowsDiagonalMinimize2 />
            ) : (
                <IconArrowsDiagonal />
            )}
        </button>
    );
};
