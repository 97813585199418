import {
    ApiErrorDetail,
    DashboardApiResponse,
    DashboardApiResults,
} from '@/api/types';
import { axiosAuthInterceptor, getAuthorizationHeader } from '@/config/auth';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
const apiPrefix = `/v1`;

const defaultHeaders = {
    'Content-Type': 'application/json',
};

const handleError = (errors: ApiErrorDetail) => {
    return errors;
};

const axiosInstance = axios.create({
    baseURL: apiPrefix,
    headers: {
        ...defaultHeaders,
    },
});

axiosInstance.interceptors.response.use(...axiosAuthInterceptor);

export const dashboardApi = async <T extends DashboardApiResults>({
    method,
    url,
    data,
    headers,
    params,
}: AxiosRequestConfig): Promise<NonNullable<DashboardApiResponse<T>>> => {
    try {
        const { data: responseData } = await axiosInstance<
            DashboardApiResponse<T> | ApiErrorDetail
        >({
            method,
            url,
            data,
            headers: {
                ...defaultHeaders,
                ...headers,
                Authorization: getAuthorizationHeader(),
            },
            params,
        });
        return responseData as unknown as T;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError<ApiErrorDetail>;
            if (axiosError.response) {
                throw handleError(
                    axiosError.response.data || {
                        error: axiosError.response.statusText,
                        message: axiosError.message,
                        statusCode: axiosError.response.status,
                    },
                );
            }
        }
        throw error;
    }
};
