import { PercentItem } from '@/components/PercentItem';
import {
    ShadowBar,
    ShadowBarChart,
    ShadowBarGroup,
} from '@/components/ShadowBarChart';
import { useConfigContext } from '@/context/configContext';
import { useBudgetOptimizationContext } from '@/dashboards/BudgetOptimization/context/BudgetOptimizationContext';
import { useBudgetOptimizationUrl } from '@/dashboards/BudgetOptimization/hooks/useBudgetOptimizationUrl';
import { Typography } from '@analytical-alley/ui';
import moment from 'moment';
import React from 'react';

function getMonthName(month: number) {
    return moment()
        .month(month - 1)
        .format('MMM');
}

function getFullMonthName(month: number) {
    return moment()
        .month(month - 1)
        .format('MMMM');
}

export const MonthlyMediaInvestment = () => {
    const {
        search: { budget },
    } = useBudgetOptimizationUrl();
    const {
        data: {
            monthlyMediaInvestment: { monthlyMediaData, highestValue },
        },
    } = useBudgetOptimizationContext();

    const { formatNumber } = useConfigContext();
    const height = 300;

    return (
        <div className="glass-tile p-6">
            <div className="flex mb-4">
                <Typography variant="titleS">
                    Monthly Media Investment
                </Typography>
                <span className="grow" />
                <div>
                    <div className="flex items-center">
                        <div className="w-2.5 h-2.5 bg-[#2B67FF] rounded-full mr-1.5 mb-0.5" />
                        <Typography className="m-0" variant="bodyXS">
                            Recommended budget
                        </Typography>
                    </div>
                    <div className="flex items-center">
                        <div className="w-2.5 h-2.5 bg-indigo-300 bg-opacity-50 rounded-full mr-1.5 mb-0.5" />
                        <Typography className="m-0" variant="bodyXS">
                            Previous budget
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="relative">
                <div className="flex justify-between">
                    <ShadowBarChart
                        showTickMarks
                        yAxis={'auto'}
                        axisLabelFormatter={formatNumber}
                        height={height}
                        maxValue={highestValue}
                    >
                        <ShadowBarChart.Content>
                            {monthlyMediaData.map(
                                (
                                    { month, simulatedBudget, prevBudget },
                                    index,
                                ) => {
                                    const isNegative =
                                        simulatedBudget < prevBudget;
                                    const simulatedBudgetVsActualPercent =
                                        prevBudget
                                            ? Math.round(
                                                  (simulatedBudget /
                                                      prevBudget) *
                                                      100,
                                              )
                                            : 0;
                                    const simulatedBudgetVsActualPercentDisplay =
                                        isNegative
                                            ? -(
                                                  100 -
                                                  simulatedBudgetVsActualPercent
                                              )
                                            : simulatedBudgetVsActualPercent;

                                    const monthName = getMonthName(month);
                                    const fullMonthName =
                                        getFullMonthName(month);
                                    return (
                                        <ShadowBarGroup
                                            key={index}
                                            title={monthName}
                                            tooltipContent={
                                                <div className="tooltip-content">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="pr-2">
                                                                    <Typography className="text-black dark:text-black">
                                                                        Month:
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography className="text-dark dark:text-dark">
                                                                        {
                                                                            fullMonthName
                                                                        }
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">
                                                                    <Typography className="text-black dark:text-black">
                                                                        Recommended
                                                                        media
                                                                        budget:
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography className="text-dark dark:text-dark">
                                                                        {formatNumber(
                                                                            simulatedBudget,
                                                                        )}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">
                                                                    <Typography className="text-black dark:text-black">
                                                                        Previous
                                                                        media
                                                                        budget:
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography className="text-dark dark:text-dark">
                                                                        {formatNumber(
                                                                            prevBudget,
                                                                        )}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">
                                                                    <Typography className="text-black dark:text-black">
                                                                        Recommended
                                                                        budget
                                                                        change:
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography className="text-dark dark:text-dark">
                                                                        {
                                                                            simulatedBudgetVsActualPercentDisplay
                                                                        }
                                                                        %
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">
                                                                    <Typography className="text-black dark:text-black">
                                                                        Share of
                                                                        the
                                                                        total
                                                                        budget:
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography className="text-dark dark:text-dark">
                                                                        {budget
                                                                            ? Math.round(
                                                                                  (simulatedBudget *
                                                                                      100) /
                                                                                      budget,
                                                                              )
                                                                            : ' - '}
                                                                        %
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        >
                                            <ShadowBar
                                                height={prevBudget}
                                                variant="secondary"
                                            />
                                            <ShadowBar
                                                height={simulatedBudget}
                                                variant="primary"
                                            >
                                                <ShadowBar.Title>
                                                    <PercentItem
                                                        percent={
                                                            simulatedBudgetVsActualPercentDisplay
                                                        }
                                                    />
                                                </ShadowBar.Title>
                                            </ShadowBar>
                                        </ShadowBarGroup>
                                    );
                                },
                            )}
                        </ShadowBarChart.Content>
                    </ShadowBarChart>
                </div>
            </div>
        </div>
    );
};
