import { modelContributionQueryOptions } from '@/dashboards/ModelContributions/api';
import { useModelContributionUrl } from '@/dashboards/ModelContributions/hooks';
import { GetModelContributionResponse } from '@/dashboards/ModelContributions/types';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { getPeriodOptionsForModel } from '@/utils/model';
import { DefaultError, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

export interface UseModelContributionQueryReturn {
    queryResult: UseQueryResult<GetModelContributionResponse, DefaultError>;
    activeTabIndex: number;
    tabsToRender: string[];
    onActiveTabChange: (activeTab: number) => void;
}

export const useModelContributionQuery =
    (): UseModelContributionQueryReturn => {
        const { search, params } = useModelContributionUrl();

        const { period } = search;

        const navigate = useNavigate();

        const queryResult = useQuery(
            modelContributionQueryOptions({
                period,
                endDate: search.endDate,
                startDate: search.startDate,
            }),
        );

        const {
            project: { interval },
        } = useDashboardGlobalContext();

        const availablePeriodOptions = getPeriodOptionsForModel(interval);

        const activeTabIndex = period
            ? Math.max(availablePeriodOptions.indexOf(period) || 0, 0)
            : 0;

        const onActiveTabChange = (activeTab: number) => {
            navigate({
                search: {
                    ...search,
                    period: availablePeriodOptions[activeTab] || '',
                },
                params,
            });
        };

        return {
            queryResult,
            activeTabIndex,
            tabsToRender: availablePeriodOptions,
            onActiveTabChange,
        };
    };
