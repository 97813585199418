import { FullScreenProvider } from '@/components/FullScreen';
import { Header } from '@/components/Header';
import React, { useRef } from 'react';

import { ActionBar } from '@/dashboards/CampaignOptimization/components/ActionBar';
import { CampaignOptimizationSection } from '@/dashboards/CampaignOptimization/components/CampaignOptimizationSection';
import { CampaignOptimizationContextProvider } from '@/dashboards/CampaignOptimization/context';

export const CampaignOptimization = () => {
    const portalRef = useRef<HTMLDivElement>(null);

    return (
        <CampaignOptimizationContextProvider>
            <Header className="pb-6 px-16 pt-6" title="Campaign Optimization">
                <ActionBar />
            </Header>
            <div className="pb-8 px-16">
                <div className="transition-all duration-300" ref={portalRef} />
                <FullScreenProvider portalRef={portalRef}>
                    <CampaignOptimizationSection />
                </FullScreenProvider>
            </div>
        </CampaignOptimizationContextProvider>
    );
};
