import type { useInteractions } from '@floating-ui/react';
import { createContext, useContext } from 'react';

import { SelectTheme } from '@/components/Select/theme';

type SelectContext<T extends number | string | undefined | null = undefined> = {
    value?: string | null | number | undefined;
    theme: SelectTheme;
    activeIndex: number | null;
    dismissOnClick?: boolean;
    getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
    handleSelect: (index: number | null) => void;
    onSelectChange: (value: T) => void;
};

export const SelectContext = createContext<SelectContext | undefined>(
    undefined,
);

export const useSelectContext = <
    T extends number | string | undefined | null,
>(): SelectContext<T> => {
    const context = useContext(SelectContext);

    if (!context) {
        throw new Error(
            'useSelectContext should be used within the SelectContext provider!',
        );
    }

    return context as SelectContext<T>;
};
