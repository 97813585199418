export const getChartsTheme = ({ mode }: { mode: 'light' | 'dark' }) => {
    const themeColor = mode === 'light' ? '#0D0884' : '#FDFDFF';

    return {
        color: [
            '#fc97af',
            '#87f7cf',
            '#f7f494',
            '#72ccff',
            '#f7c5a0',
            '#d4a4eb',
            '#d2f5a6',
            '#76f2f2',
        ],
        backgroundColor: 'rgba(255,255,255,0)',
        textStyle: {
            fontFamily: 'DM Mono',
            fontSize: 12,
            color: themeColor,
        },
        title: {
            textStyle: {
                color: themeColor,
            },
            subtextStyle: {
                color: '#dddddd',
            },
        },
        radar: {
            itemStyle: {
                borderWidth: '4',
            },
            lineStyle: {
                width: '3',
            },
            symbolSize: '0',
            symbol: 'circle',
            smooth: true,
        },
        graph: {
            itemStyle: {
                borderWidth: 0,
                borderColor: '#ccc',
            },
            lineStyle: {
                width: '1',
                color: themeColor,
            },
            symbolSize: '0',
            symbol: 'circle',
            smooth: true,
            color: [
                '#fc97af',
                '#87f7cf',
                '#f7f494',
                '#72ccff',
                '#f7c5a0',
                '#d4a4eb',
                '#d2f5a6',
                '#76f2f2',
            ],
            label: {
                color: '#293441',
            },
        },
        categoryAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: themeColor,
                },
            },
            axisTick: {
                show: false,
                lineStyle: {
                    color: themeColor,
                },
            },
            axisLabel: {
                show: true,
                color: themeColor,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: [themeColor],
                },
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
                },
            },
        },
        valueAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: themeColor,
                },
            },
            axisTick: {
                show: false,
                lineStyle: {
                    color: themeColor,
                },
            },
            axisLabel: {
                show: true,
                color: themeColor,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: [themeColor],
                },
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
                },
            },
        },
        logAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: themeColor,
                },
            },
            axisTick: {
                show: false,
                lineStyle: {
                    color: themeColor,
                },
            },
            axisLabel: {
                show: true,
                color: themeColor,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: [themeColor],
                },
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
                },
            },
        },
        timeAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: themeColor,
                },
            },
            axisTick: {
                show: false,
                lineStyle: {
                    color: themeColor,
                },
            },
            axisLabel: {
                show: true,
                color: themeColor,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: [themeColor],
                },
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
                },
            },
        },
        legend: {
            textStyle: {
                color: themeColor,
            },
        },
        timeline: {
            lineStyle: {
                color: '#87f7cf',
                width: 1,
            },
            itemStyle: {
                color: '#87f7cf',
                borderWidth: 1,
            },
            controlStyle: {
                color: '#87f7cf',
                borderColor: '#87f7cf',
                borderWidth: 0.5,
            },
            checkpointStyle: {
                color: '#fc97af',
                borderColor: '#fc97af',
            },
            label: {
                color: '#87f7cf',
            },
            emphasis: {
                itemStyle: {
                    color: '#f7f494',
                },
                controlStyle: {
                    color: '#87f7cf',
                    borderColor: '#87f7cf',
                    borderWidth: 0.5,
                },
                label: {
                    color: '#87f7cf',
                },
            },
        },
        dataZoom: {
            backgroundColor: 'rgba(255,255,255,0)',
            dataBackgroundColor: 'rgba(114,204,255,1)',
            fillerColor: 'rgba(114,204,255,0.2)',
            handleColor: '#72ccff',
            handleSize: '100%',
            textStyle: {
                color: '#333333',
            },
        },
        markPoint: {
            label: {
                color: '#293441',
            },
            emphasis: {
                label: {
                    color: '#293441',
                },
            },
        },
    };
};

export const chartsTheme = getChartsTheme({ mode: 'dark' });
