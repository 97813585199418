import { useRef as s, useState as l, useMemo as i, useEffect as m } from "react";
const E = ({ disabled: n } = { disabled: !1 }) => {
  const u = s(null), r = s(null), [f, o] = l(!1), t = i(() => n ? null : new ResizeObserver(() => {
    if (u.current && r.current) {
      const e = u.current.offsetWidth, c = r.current.offsetWidth;
      o(c <= e);
    }
  }), [n]);
  return m(() => {
    const e = r.current;
    return e && !n && (t == null || t.observe(e)), () => {
      e && !n && (t == null || t.unobserve(e));
    };
  }, [n, t]), { isOverflowing: f, measureRef: u, targetElementRef: r };
};
export {
  E as useEllipsis
};
