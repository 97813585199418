import { GetPreviousDataReturn } from '@/dashboards/BudgetOptimization/api';
import { UseVariableDataReturn } from '@/dashboards/BudgetOptimization/hooks/useVariableData';
import { ContributionAndSpendByPeriod } from '@/dashboards/types';
import { PeriodPlan } from '@/optimizer/createOptimizerServiceManager';

export type StructureMediaSpendSeasonalityPeriod = 'monthly' | 'weekly';

export type StructureMediaSpendSeasonalityReturn = ReturnType<
    typeof structureMediaSpendSeasonality
>;

export const structureMediaSpendSeasonality = (
    period: StructureMediaSpendSeasonalityPeriod,
    {
        variablesAndCategories,
        optimizedData,
        variableColorMap,
        categoryColorMap,
    }: Pick<UseVariableDataReturn, 'optimizedData'> &
        Pick<GetPreviousDataReturn['roiCurves'], 'variablesAndCategories'> & {
            variableColorMap: Record<string, string>;
            categoryColorMap: Record<string, string>;
        },
) => {
    const dataByCategory: ContributionAndSpendByPeriod = {};
    const dataByVariable: ContributionAndSpendByPeriod = {};

    const dates: string[] = [];
    let datesFilled = false;

    Object.entries(variablesAndCategories.byCategory).forEach(
        ([categoryName, variables]) => {
            dataByCategory[categoryName] = {
                color: categoryColorMap[categoryName]!,
                data: {},
            };

            const categoryData: {
                [key: string]: { contribution: number; spend: number };
            } = {};

            let planToIterateOn: PeriodPlan[] = [];

            if (period === 'monthly') {
                planToIterateOn = optimizedData?.monthlyPlan || [];
            } else if (period === 'weekly') {
                planToIterateOn = optimizedData?.weeklyPlan || [];
            }

            planToIterateOn.forEach((plan) => {
                const periodKey = `${plan['periodIndex']}-${plan['Year']}`;

                if (!datesFilled) {
                    dates.push(periodKey as string);
                }

                variables.forEach((variable) => {
                    if (!dataByVariable[variable]) {
                        dataByVariable[variable] = {
                            color: variableColorMap[variable]!,
                            data: {},
                        };
                    }
                    if (!dataByVariable[variable]!.data[periodKey]) {
                        dataByVariable[variable]!.data[periodKey] = {
                            contribution: 0,
                            spend: 0,
                        };
                    }

                    if (!categoryData[periodKey]) {
                        categoryData[periodKey] = {
                            contribution: 0,
                            spend: 0,
                        };
                    }

                    dataByVariable[variable]!.data[periodKey]!.spend +=
                        plan[variable]!;

                    categoryData[periodKey]!.spend += plan[variable]!;
                });
            });

            datesFilled = true;
            dataByCategory[categoryName]!.data = categoryData;
        },
    );

    return {
        dates,
        dataByCategory,
        dataByVariable,
    };
};
