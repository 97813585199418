import { WithLoader } from '@/components/WithLoader';
import { statisticsQueryOptions } from '@/dashboards/TitleDashboard/api';
import { ModelVsActual } from '@/dashboards/TitleDashboard/components';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { useDuckDBQuery } from '@/duckdb';
import { useDashboardLinks } from '@/hooks/useDashboardLinks';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import {
    AnalyticalAlleyLogo,
    Button,
    Spinner,
    Typography,
} from '@analytical-alley/ui';
import { useMatchMedia } from '@analytical-alley/ui/hooks';
import { IconArrowRight } from '@tabler/icons-react';
import { MatchRoute } from '@tanstack/react-router';
import moment from 'moment';
export const TitleDashboard = () => {
    const {
        project: {
            kpiDefinition,
            projectInfo,
            projectLead,
            dataScientist,
            interval,
            name,
        },
        startDate,
        endDate,
    } = useDashboardGlobalContext();

    const formattedStartDate = startDate
        ? moment(startDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
        : null;
    const formattedEndDate = endDate
        ? moment(endDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
        : null;

    const { isLg } = useMatchMedia(['lg']);

    const {
        data: statisticsData,
        isLoading,
        isFetching,
    } = useDuckDBQuery(statisticsQueryOptions());

    const { getFirstAccessibleLink } = useDashboardLinks();

    const firstAccessibleLink = getFirstAccessibleLink(['titleDashboard']);

    return (
        <div className="relative flex flex-col justify-center items-start px-40 p-16 lg:p-36 pb-48 dark:text-white max-lg:px-5">
            <AnalyticalAlleyLogo
                size={isLg ? 'large' : 'normal'}
                className="absolute top-5 left-5 lg:top-11 lg:left-11"
            >
                <Typography className="font-light" variant="bodyL">
                    hello@analyticalalley.com
                </Typography>

                <span className="p-1 bg-red-400 font-bold rounded-md absolute -top-20 left-40 rotate-12">
                    Beta
                </span>
            </AnalyticalAlleyLogo>
            <div className="self-center mt-0 w-full max-w-[1600px] max-lg:max-w-full">
                <div className="flex gap-16 max-2xl:flex-col max-lg:gap-0">
                    <div className="flex flex-col w-6/12 max-2xl:w-full">
                        <div className="flex flex-col mt-5 max-lg:mt-10 max-lg:max-w-full">
                            <div className="glass-tile flex flex-col p-8 mt-11 max-lg:px-5 max-lg:mt-10 max-lg:max-w-full">
                                <Typography as="h1" variant="titleXXL">
                                    {name}
                                </Typography>
                                <Typography className="font-mono font-light mt-2">
                                    Client
                                </Typography>
                                <Typography
                                    as="h5"
                                    variant="titleS"
                                    className="mt-8 max-lg:max-w-full"
                                >
                                    {projectInfo}
                                </Typography>
                                <div className="justify-between mt-16 max-lg:mt-10 max-lg:max-w-full">
                                    <div className="flex flex-wrap gap-5">
                                        {dataScientist || projectLead ? (
                                            <div className="flex flex-col flex-grow">
                                                <div className="flex flex-col font-mono grow dark:text-white max-lg:max-w-full">
                                                    <Typography
                                                        as="h5"
                                                        variant="titleS"
                                                        className="max-lg:max-w-full font-sans"
                                                    >
                                                        For any questions and
                                                        help contact:
                                                    </Typography>
                                                    {projectLead && (
                                                        <>
                                                            <Typography
                                                                as="h6"
                                                                variant="bodyXL"
                                                                className="mt-8 max-lg:max-w-full font-medium"
                                                            >
                                                                {
                                                                    projectLead.displayName
                                                                }
                                                                , Project Lead
                                                            </Typography>
                                                            <Typography
                                                                variant="bodyL"
                                                                className="mt-2 max-lg:max-w-full font-light"
                                                            >
                                                                {
                                                                    projectLead.email
                                                                }
                                                            </Typography>
                                                        </>
                                                    )}
                                                    {dataScientist && (
                                                        <>
                                                            <Typography
                                                                as="h6"
                                                                variant="bodyXL"
                                                                className="mt-8 max-lg:max-w-full font-medium"
                                                            >
                                                                {
                                                                    dataScientist.displayName
                                                                }
                                                                , Data Scientist
                                                            </Typography>
                                                            <Typography
                                                                variant="bodyL"
                                                                className="mt-2 max-lg:max-w-full font-light"
                                                            >
                                                                {
                                                                    dataScientist.email
                                                                }
                                                            </Typography>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="flex flex-col ml-auto mt-auto">
                                            {firstAccessibleLink ? (
                                                <firstAccessibleLink.Link>
                                                    {(state) => {
                                                        return (
                                                            <Button color="light">
                                                                {
                                                                    firstAccessibleLink.title
                                                                }
                                                                <MatchRoute
                                                                    to={
                                                                        state.to
                                                                    }
                                                                    includeSearch={
                                                                        false
                                                                    }
                                                                    pending
                                                                >
                                                                    {(
                                                                        match,
                                                                    ) => {
                                                                        if (
                                                                            match
                                                                        ) {
                                                                            return (
                                                                                <Spinner />
                                                                            );
                                                                        }
                                                                        return (
                                                                            <IconArrowRight />
                                                                        );
                                                                    }}
                                                                </MatchRoute>
                                                            </Button>
                                                        );
                                                    }}
                                                </firstAccessibleLink.Link>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-6/12 max-2xl:w-full">
                        <div className="glass-tile flex flex-col p-8 w-full border-0 border-solid max-lg:px-5 max-lg:mt-10 max-lg:max-w-full">
                            <Typography
                                as="h1"
                                variant="titleM"
                                className="max-lg:max-w-full"
                            >
                                Data used in this model
                            </Typography>
                            <div className="font-mono justify-between mt-8 max-lg:max-w-full">
                                <div className="flex gap-5 flex-wrap sm-min-lg:flex-col max-lg:gap-0 min-h-40 max-2xl:min-h-0">
                                    <div className="flex max-lg:ml-0">
                                        <div className="flex flex-col grow min-lg:mt-10">
                                            <div className="font-thin flex gap-5 justify-between text-xl leading-6 dark:text-white">
                                                <div className="flex flex-col whitespace-nowrap">
                                                    <Typography
                                                        as="div"
                                                        variant="bodyXL"
                                                        className="font-light"
                                                    >
                                                        KPI:
                                                    </Typography>
                                                    <Typography
                                                        as="div"
                                                        variant="bodyXL"
                                                        className="font-light mt-3"
                                                    >
                                                        Period:
                                                    </Typography>
                                                    <Typography
                                                        as="div"
                                                        variant="bodyXL"
                                                        className="font-light mt-3"
                                                    >
                                                        Periodicity:
                                                    </Typography>
                                                </div>
                                                <div className="flex flex-col flex-1">
                                                    <Typography
                                                        as="div"
                                                        variant="bodyXL"
                                                        className="font-light"
                                                    >
                                                        {kpiDefinition || '-'}
                                                    </Typography>
                                                    <Typography
                                                        as="div"
                                                        variant="bodyXL"
                                                        className="font-light mt-3 whitespace-nowrap"
                                                    >
                                                        {formattedStartDate &&
                                                        formattedEndDate
                                                            ? `${formattedStartDate} - ${formattedEndDate}`
                                                            : null}
                                                    </Typography>
                                                    <Typography
                                                        as="div"
                                                        variant="bodyXL"
                                                        className="font-light mt-3"
                                                    >
                                                        {interval
                                                            ? capitalizeFirstLetter(
                                                                  interval,
                                                              )
                                                            : '-'}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex z-10 self-end ml-auto flex-col">
                                        <div className="glass-tile rounded-xl font-thin flex flex-col ml-auto min-w-max px-5 py-3 text-base leading-6 whitespace-nowrap max-lg:mt-10">
                                            <WithLoader
                                                isLoading={isLoading}
                                                isFetching={isFetching}
                                            >
                                                <Typography
                                                    as="div"
                                                    className="self-end font-light"
                                                >
                                                    Adj R2{' '}
                                                    {statisticsData?.adjustedR2
                                                        ? statisticsData.adjustedR2.toFixed(
                                                              2,
                                                          )
                                                        : ' - '}
                                                    %
                                                </Typography>
                                                <Typography
                                                    as="div"
                                                    className="mt-3 self-end font-light"
                                                >
                                                    Durbin Watson{' '}
                                                    {statisticsData?.durbinWatson ??
                                                        ' - '}
                                                </Typography>
                                            </WithLoader>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ModelVsActual />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
