import { ToggleSelect } from '@/components/ToggleSelect';
import { CompetitorsHorizontalBarGraph } from '@/dashboards/CompetitorsMediaInvestment/components/CompetitorsHorizontalBarGraph';
import { useCompetitorsMediaInvestmentContext } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentContext';
import { roundToDecimals } from '@/utils/numberUtils';
import React, { RefObject, useMemo } from 'react';
import { createPortal } from 'react-dom';

const formatter = new Intl.NumberFormat('fr-FR', {
    useGrouping: true,
    minimumFractionDigits: 0,
});

export const CompetitorMediaInvestmentGraph = ({
    filterContainerRef,
}: {
    filterContainerRef: RefObject<HTMLDivElement>;
}) => {
    const {
        competitorsMediaInvestmentData: {
            totalInvestment,
            totalInvestmentByVariable,
            variableData,
        },
        selectedCategoryFilter,
        setSelectedCategory,
    } = useCompetitorsMediaInvestmentContext();

    const filteredVariableData = useMemo(() => {
        return variableData
            .filter((dataObj) => {
                return selectedCategoryFilter.some(
                    (filterObj) =>
                        filterObj.name === dataObj.category &&
                        filterObj.selected,
                );
            })
            .sort(
                (a, b) =>
                    totalInvestmentByVariable.get(b.variable)! -
                    totalInvestmentByVariable.get(a.variable)!,
            );
    }, [selectedCategoryFilter, totalInvestmentByVariable, variableData]);

    const maxValue = useMemo(() => {
        return Array.from(totalInvestmentByVariable.values()).reduce(
            (max, value) => {
                const percentage = Math.floor((value / totalInvestment) * 100);
                return Math.max(max, percentage);
            },
            0,
        );
    }, [totalInvestment, totalInvestmentByVariable]);

    return (
        <>
            {filterContainerRef.current
                ? createPortal(
                      <ToggleSelect
                          items={selectedCategoryFilter.map((item) => ({
                              label: item.name,
                              value: item.name,
                              checked: item.selected,
                          }))}
                          placement={'bottom-end'}
                          compact
                          label={'Media Category'}
                          showAllCheckbox={true}
                          onChange={(name, checked) => {
                              setSelectedCategory(name, checked);
                          }}
                      />,
                      filterContainerRef.current,
                  )
                : null}
            <CompetitorsHorizontalBarGraph maxValue={maxValue}>
                {filteredVariableData.map((value) => {
                    const investment = totalInvestmentByVariable.get(
                        value.variable,
                    )!;
                    const percent = roundToDecimals(
                        (investment / totalInvestment) * 100,
                    );

                    return (
                        <CompetitorsHorizontalBarGraph.Row
                            activeTitle={'IKEA'} //TODO: replace hardcoded value with the actual client name
                            color={value.color}
                            key={`${value.variable}-${value.variable}`}
                            title={value.variable}
                            impact={
                                value.variable === 'IKEA'
                                    ? 'neutral'
                                    : value.competitorImpactToClient
                            } //TODO: Remove condition if client name is known
                            maxValue={maxValue}
                            percent={percent}
                            value={formatter.format(investment)}
                        />
                    );
                })}
            </CompetitorsHorizontalBarGraph>
        </>
    );
};
