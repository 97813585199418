import { throttle } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

export type DetectOverflowCallback = (
    isOverflowing: boolean,
    context: {
        contentWidth: number;
        containerElement: HTMLElement;
        mainContentElement: HTMLElement;
        targetContentElement: HTMLElement;
    },
) => void;

export const useDetectOverflow = <T extends DetectOverflowCallback>(
    cb: T,
    options?: {
        throttleWait?: number;
        contentWidthOffset?: number;
    },
    deps: unknown[] = [],
) => {
    const funcRef = useRef(cb);

    const containerRef = useRef<HTMLDivElement>(null);
    const mainContentRef = useRef<HTMLSpanElement>(null);
    const targetContentRef = useRef<HTMLSpanElement>(null);

    const wait = options?.throttleWait ?? 250;
    const contentOffset = options?.contentWidthOffset ?? 0;

    useEffect(() => {
        funcRef.current = cb;
    }, [cb]);

    const calculateOverflow = useCallback(
        (
            containerElement: HTMLDivElement | null,
            mainContentElement: HTMLSpanElement | null,
            targetContentElement: HTMLSpanElement | null,
        ) => {
            if (
                containerElement &&
                targetContentElement &&
                mainContentElement
            ) {
                const containerWidth = containerElement.offsetWidth;
                const textWidth = targetContentElement.offsetWidth;
                const barWidth = mainContentElement.offsetWidth;

                const availableWidth =
                    containerWidth - barWidth - contentOffset;

                funcRef.current(
                    barWidth + contentOffset < textWidth
                        ? false
                        : textWidth >= availableWidth,
                    {
                        contentWidth: textWidth,
                        containerElement,
                        mainContentElement,
                        targetContentElement,
                    },
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [contentOffset, ...deps],
    );

    const calculateOverflowWithThrottle = useCallback(() => {
        throttle(calculateOverflow, wait)(
            containerRef.current,
            mainContentRef.current,
            targetContentRef.current,
        );
    }, [calculateOverflow, wait]);

    useEffect(() => {
        calculateOverflowWithThrottle();

        window.addEventListener('resize', calculateOverflowWithThrottle);

        return () => {
            window.removeEventListener('resize', calculateOverflowWithThrottle);
        };
    }, [calculateOverflowWithThrottle]);

    return { containerRef, mainContentRef, targetContentRef };
};
