import type { FloatingTheme } from '@/components/Floating';
import {
    FlowbiteDropdownDividerTheme,
    FlowbiteDropdownHeaderTheme,
    FlowbiteDropdownItemTheme,
} from 'flowbite-react';

export interface DropdownFloatingTheme
    extends FloatingTheme,
        FlowbiteDropdownDividerTheme,
        FlowbiteDropdownHeaderTheme {
    item: FlowbiteDropdownItemTheme;
}

export interface DropdownTheme {
    floating: DropdownFloatingTheme;
    content: string;
    inlineWrapper: string;
    arrowIcon: string;
}

export const dropdownTheme: DropdownTheme = {
    arrowIcon: 'ml-2 h-4 w-4',
    content: 'focus:outline-none flex flex-col max-h-[500px] overflow-y-auto',
    floating: {
        animation: 'transition-opacity',
        arrow: {
            base: 'absolute z-10 h-2 w-2 rotate-45',
            style: {
                dark: 'bg-gray-900 dark:bg-gray-700',
                light: 'bg-white',
                auto: 'bg-white dark:bg-gray-700',
            },
            placement: '-4px',
        },
        base: 'z-20 font-sans px-4 py-2 leading-10 rounded-lg border-0 border-white border-solid shadow-xl backdrop-blur-[30px] bg-white bg-opacity-10 max-w-[561px] max-md:px-5 focus:outline-none',
        content: 'py-1 text-sm text-gray-700 dark:text-gray-200',
        divider: 'my-1 h-px bg-gray-100 dark:bg-gray-600',
        header: 'block py-2 px-4 text-sm text-gray-700 dark:text-gray-200',
        hidden: 'invisible opacity-0',
        item: {
            container: '',
            base: 'text-dark w-full text-start px-6 py-5 dark:text-white rounded-2xl bg-white hover:bg-white bg-opacity-40 hover:bg-opacity-30 max-md:px-5 max-md:max-w-full focus:outline-none',
            icon: 'mr-2 h-4 w-4',
        },
        style: {
            dark: 'glass-tile',
            light: 'border border-gray-200 bg-white text-gray-900',
            auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:text-white',
        },
        target: 'w-fit',
    },
    inlineWrapper: 'flex items-center',
};
