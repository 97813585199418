import { PercentItem } from '@/components/PercentItem';
import { Table, TableRef } from '@/components/Table';
import { WithLoader } from '@/components/WithLoader';
import { useConfigContext } from '@/context/configContext';
import { SuggestedBudgetCellRender } from '@/dashboards/BudgetOptimization/components/SuggestedBudgetTable/SuggestedBudgetCellRender';
import { useBudgetOptimizationContext } from '@/dashboards/BudgetOptimization/context/BudgetOptimizationContext';
import { roundToDecimals } from '@/utils/numberUtils';
import { Typography } from '@analytical-alley/ui';
import { IconDownload } from '@tabler/icons-react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';

export interface SuggestedBudgetTableData {
    category: string;
    variable: string;
    previousPeriodInvestments: number;
    recommendedBudget: number;
    contribution: number;
}

const columnHelper = createColumnHelper<SuggestedBudgetTableData>();

export const SuggestedBudgetTable = () => {
    const {
        data: { suggestedBudgetData },
    } = useBudgetOptimizationContext();
    const { formatNumber } = useConfigContext();

    const isLoading = false;
    const isFetching = false;

    const columns = useMemo(
        () => [
            columnHelper.accessor('category', {
                header: () => 'Category',
                cell: (info) => {
                    return <SuggestedBudgetCellRender {...info} disabled />;
                },
                footer: () => 'Total',
                meta: {
                    header: {
                        className: 'w-[1px]',
                    },
                    footer: {
                        className: 'text-right',
                    },
                },
            }),
            columnHelper.accessor('variable', {
                cell: (info) => {
                    return <SuggestedBudgetCellRender {...info} disabled />;
                },
                header: () => <span>Variable</span>,
            }),
            columnHelper.accessor('previousPeriodInvestments', {
                header: () => <span>Previous&nbsp;period investments</span>,
                cell: (info) => {
                    return (
                        <SuggestedBudgetCellRender
                            {...info}
                            className="justify-center"
                            value={formatNumber(info.getValue())}
                        >
                            {formatNumber(info.getValue())}
                        </SuggestedBudgetCellRender>
                    );
                },
                meta: {
                    header: {
                        className: 'w-[1px] text-center',
                    },
                    cell: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
                footer: (info) => {
                    return (
                        <span className="text-center">
                            {formatNumber(
                                info.table
                                    .getRowModel()
                                    .rows.reduce(
                                        (acc, row) =>
                                            acc +
                                            row.original
                                                .previousPeriodInvestments,
                                        0,
                                    ),
                            )}
                        </span>
                    );
                },
            }),
            columnHelper.accessor('recommendedBudget', {
                header: 'Recommended budget',
                cell: (info) => {
                    const previousPeriodInvestments =
                        info.row.original.previousPeriodInvestments;
                    const recommendedBudget = info.getValue();

                    const diff = recommendedBudget - previousPeriodInvestments;
                    const percent = roundToDecimals(
                        (diff / previousPeriodInvestments) * 100,
                        0,
                    );

                    return (
                        <SuggestedBudgetCellRender
                            {...info}
                            className="justify-center"
                            value={formatNumber(recommendedBudget)}
                        >
                            <div className="flex">
                                {formatNumber(recommendedBudget)}
                                <span className="w-12 ml-1 self-center flex justify-end">
                                    <PercentItem percent={percent} />
                                </span>
                            </div>
                        </SuggestedBudgetCellRender>
                    );
                },
                meta: {
                    header: {
                        className: 'w-[1px] text-center',
                    },
                    cell: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
                footer: (info) => {
                    const totalRecommendedBudget = info.table
                        .getRowModel()
                        .rows.reduce(
                            (acc, row) => acc + row.original.recommendedBudget,
                            0,
                        );
                    const totalPreviousPeriodInvestment = info.table
                        .getRowModel()
                        .rows.reduce(
                            (acc, row) =>
                                acc + row.original.previousPeriodInvestments,
                            0,
                        );

                    const diff =
                        totalRecommendedBudget - totalPreviousPeriodInvestment;

                    const percent = roundToDecimals(
                        (diff / totalPreviousPeriodInvestment) * 100,
                        0,
                    );

                    return (
                        <span className="text-center flex">
                            {formatNumber(
                                info.table
                                    .getRowModel()
                                    .rows.reduce(
                                        (acc, row) =>
                                            acc +
                                            row.original.recommendedBudget,
                                        0,
                                    ),
                            )}
                            <span className="w-12 ml-1 self-center flex justify-end">
                                <PercentItem percent={percent} />
                            </span>
                        </span>
                    );
                },
            }),
            columnHelper.accessor('contribution', {
                header: () => 'Contribution number',
                meta: {
                    header: {
                        className: 'w-[1px] text-center',
                    },
                    cell: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
                cell: (info) => {
                    return (
                        <SuggestedBudgetCellRender
                            {...info}
                            className="justify-center"
                            value={formatNumber(info.getValue())}
                        >
                            {formatNumber(info.getValue())}
                        </SuggestedBudgetCellRender>
                    );
                },
                footer: (info) => {
                    const totalContribution = info.table
                        .getRowModel()
                        .rows.reduce(
                            (acc, row) => acc + row.original.contribution,
                            0,
                        );
                    return (
                        <span className="text-center">
                            {formatNumber(totalContribution)}
                        </span>
                    );
                },
            }),
        ],
        [formatNumber],
    );

    const tableRef = React.useRef<TableRef>(null);

    const downloadCsv = () => {
        tableRef.current?.downloadCsv('suggested-budget-allocation');
    };

    return (
        <div className="glass-tile p-6">
            <div className="flex mb-4">
                <Typography variant="titleS">
                    Suggested budget allocation
                </Typography>
                <span className="grow" />
                <button
                    className="glass-tile w-8 h-8 flex justify-center items-center p-2 rounded-md shadow-lg hover:bg-opacity-30"
                    onClick={downloadCsv}
                >
                    <IconDownload />
                </button>
            </div>
            <WithLoader
                className={isLoading ? 'min-h-96' : undefined}
                isLoading={isLoading}
                isFetching={isFetching}
            >
                <Table
                    tableRef={tableRef}
                    className="overflow-x-auto"
                    isLoading={isLoading}
                    data={suggestedBudgetData}
                    columns={columns}
                    shouldSpanRow
                    colSpan={{
                        footer: {
                            value: 2,
                            index: 0,
                        },
                    }}
                />
            </WithLoader>
        </div>
    );
};
