import { duckDB } from '@analytical-alley/duckdb-react';
import { DatasheetVariablesTable } from './index';

async function getRows() {
    const query = DatasheetVariablesTable.select('*').done();
    return await duckDB.query(query);
}

async function getVariablesAndCategories(variables?: string[]) {
    const query = DatasheetVariablesTable.rawQuery(
        ({ columns, table }) => {
            const whereClause = variables
                ? `WHERE "${columns.variable}" IN (${variables.map((v) => `'${v}'`).join(',')})`
                : '';
            return `SELECT "${columns.variable}", "${columns.category}" FROM "${table}" ${whereClause} GROUP BY "${columns.variable}", "${columns.category}"`;
        },
        (data: { variable: string; category: string }[]) => {
            return data.reduce(
                (acc, row) => {
                    if (!acc.byVariable[row.variable]) {
                        acc.byVariable[row.variable] = row.category;
                    }

                    if (!acc.byCategory[row.category]) {
                        acc.byCategory[row.category] = [];
                    }
                    acc.byCategory[row.category]!.push(row.variable);

                    return acc;
                },
                {
                    byVariable: {} as Record<string, string>,
                    byCategory: {} as Record<string, string[]>,
                },
            );
        },
    );
    return await duckDB.query(query);
}

export const datasheetVariablesQueries = {
    getRows,
    getVariablesAndCategories,
};
