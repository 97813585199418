import { Router } from '@/router';
import { joinPaths, RoutesByPath } from '@tanstack/react-router';

type JoinStrings<T extends string[]> = T extends [infer F, ...infer R]
    ? F extends string
        ? R extends string[]
            ? `${F}${JoinStrings<R>}`
            : never
        : never
    : '';

export function toPath<T extends string[]>(
    ...args: T
): JoinStrings<T> extends keyof RoutesByPath<Router['routeTree']>
    ? JoinStrings<T>
    : never {
    return joinPaths(args) as JoinStrings<T>;
}

export function getRoutePath<
    T extends keyof RoutesByPath<Router['routeTree']>,
    E extends string | undefined = undefined,
>(
    path: T,
    extra?: E,
): E extends undefined
    ? T
    : `${T}${E}` extends keyof RoutesByPath<Router['routeTree']>
      ? `${T}${E}`
      : never {
    return joinPaths([path, extra || '']) as any;
}

export const dashboardRootRoute = toPath('/project/$projectId');
export const previewRootRoute = toPath(
    '/preview/$projectId/dashboard/$dashboardId',
);
