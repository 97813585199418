import { Typography } from '@analytical-alley/ui';
import React from 'react';
import { ContributionSeasonalityTooltipParams } from './renderTooltip';

export const KpiTooltip = ({
    selected,
    formatter,
}: {
    selected: ContributionSeasonalityTooltipParams | undefined;
    formatter?: (value: string | undefined | number) => string;
}) => {
    if (!selected || !selected.name) {
        return null;
    }

    const isOptimizedKpi = selected.data.isOptimized;
    const kpi = selected.data.value;

    return (
        <div className="tooltip-content">
            <Typography className="tooltip-header text-dark dark:text-dark">
                {isOptimizedKpi ? 'Optimized' : 'Actual'} KPI
            </Typography>
            <table>
                <tbody>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Period:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {selected.name}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2">
                            <Typography className="text-black dark:text-black">
                                Total Contribution:
                            </Typography>
                        </td>
                        <td>
                            <Typography className="text-dark dark:text-dark">
                                {formatter ? formatter(kpi) : kpi}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
