import {
    getPreviousData,
    GetPreviousDataReturn,
} from '@/dashboards/BudgetOptimization/api';
import { useVariableData } from '@/dashboards/BudgetOptimization/hooks/useVariableData';
import { useCampaignOptimizationUrl } from '@/dashboards/CampaignOptimization/hooks/useCampaignOptimizationUrl';
import { modelContributionQueryOptions } from '@/dashboards/ModelContributions/api';
import { GetModelContributionResponse } from '@/dashboards/ModelContributions/types';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import {
    structureBudgetData,
    structureMediaBudget,
    structureMonthlyMediaInvestmentData,
} from '@/dashboards/utils/data';
import { StructureMediaBudgetPeriod } from '@/dashboards/utils/data/structureMediaBudget';
import { validateDateParams } from '@/dashboards/utils/validateDateAndPeriodSearchParams';
import { useDuckDBQuery } from '@/duckdb';
import { useOptimizer } from '@/optimizer/optimizerContext';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useCampaignOptimizationData = ({
    minDate,
    maxDate,
    totalContributionGraphPeriod,
}: {
    minDate: string;
    maxDate: string;
    totalContributionGraphPeriod: 'monthly' | 'weekly';
}) => {
    const [validated, setValidated] = useState(false);

    const { search, setSearch } = useCampaignOptimizationUrl();

    const { budget, startDate, endDate } = search;

    const {
        project: { interval },
    } = useDashboardGlobalContext();
    const { getInitialAllocation, getDiminishingPoints } = useOptimizer();

    const setBudget = useCallback(
        (budget: number) => setSearch({ budget }),
        [setSearch],
    );

    const {
        variableValues,
        setVariableValues,
        isLoading,
        isFetching,
        optimizedData,
    } = useVariableData({
        budget,
        setBudget,
        startDate,
        endDate,
        enabled: validated,
    });

    const navigate = useNavigate();

    useEffect(() => {
        const validatedDates = validateDateParams({
            boundaryStartDate: minDate,
            boundaryEndDate: maxDate,
            endDate,
            startDate,
        });

        if (validatedDates) {
            navigate({
                search: { ...search, ...validatedDates },
                replace: true,
            });
        }

        setValidated(true);
    }, [endDate, maxDate, minDate, navigate, search, startDate]);

    const prevMinDate = startDate
        ? moment(startDate, 'DD-MM-YYYY')
              .subtract(1, 'year')
              .format('DD-MM-YYYY')
        : '';

    const prevMaxDate = endDate
        ? moment(endDate, 'DD-MM-YYYY').subtract(1, 'year').format('DD-MM-YYYY')
        : '';

    const prevYearStartDate = prevMaxDate
        ? moment(prevMaxDate, 'DD-MM-YYYY')
              .subtract(1, 'year')
              .format('DD-MM-YYYY')
        : '';

    const {
        data: contributionData,
        isLoading: isContributionLoading,
        isFetching: isContributionFetching,
    } = useQuery(
        modelContributionQueryOptions(
            {
                period: totalContributionGraphPeriod,
                endDate: prevMinDate,
                startDate: prevMaxDate,
            },
            { enabled: validated },
        ),
    );

    const {
        data: contributionDataLastYear,
        isLoading: isContributionDataLastYearLoading,
        isFetching: isContributionDataLastYearFetching,
    } = useQuery(
        modelContributionQueryOptions(
            {
                period: totalContributionGraphPeriod,
                endDate: prevMaxDate,
                startDate: prevYearStartDate,
            },
            { enabled: validated },
        ),
    );

    const {
        data: prevData,
        isFetching: prevDataIsFetching,
        isLoading: prevDataIsLoading,
    } = useDuckDBQuery({
        queryKey: ['campaign-previous-data', interval, startDate, endDate],
        queryFn: () =>
            getPreviousData({
                endDate: prevMaxDate,
                startDate: prevMinDate,
                interval: interval as 'weekly' | 'daily',
            }),
        enabled: validated && !!optimizedData && !!startDate && !!endDate,
        placeholderData: keepPreviousData,
    });

    const monthlyMediaInvestment = useMemo(
        () =>
            structureMonthlyMediaInvestmentData({
                optimizedData,
                rows: prevData?.rows || {},
            }),
        [optimizedData, prevData?.rows],
    );

    const structuredBudgetData = useMemo(
        () =>
            structureBudgetData({
                optimizedData,
                variablesAndCategories: prevData?.roiCurves
                    .variablesAndCategories || {
                    byCategory: {},
                    byVariable: {},
                },
                totals: prevData?.totals || {},
            }),
        [
            optimizedData,
            prevData?.roiCurves.variablesAndCategories,
            prevData?.totals,
        ],
    );

    const getMediaBudgetTableData = useCallback(
        (period: StructureMediaBudgetPeriod) => {
            return structureMediaBudget(period, {
                optimizedData,
                variablesAndCategories: prevData?.roiCurves
                    .variablesAndCategories || {
                    byCategory: {},
                    byVariable: {},
                },
            });
        },
        [optimizedData, prevData?.roiCurves.variablesAndCategories],
    );

    const initialAllocation = useMemo(() => {
        return getInitialAllocation();
    }, [getInitialAllocation]);

    const diminishingPoints = useMemo(() => {
        return getDiminishingPoints();
    }, [getDiminishingPoints]);

    const { totalContribution, mediaContribution } = prevData || {};

    const contributionTotalWithNewMedia =
        (totalContribution || 0) -
        (mediaContribution || 0) +
        structuredBudgetData.contributionTotal;

    return {
        setVariableValues,
        variableValues,
        data: {
            contributionLastYear: {
                data:
                    contributionDataLastYear ||
                    ({ rows: [] } as GetModelContributionResponse),
                isLoading: isContributionDataLastYearLoading,
                isFetching: isContributionDataLastYearFetching,
            },
            contributionData,
            optimized: optimizedData,
            previous:
                prevData ||
                ({
                    roiCurves: {
                        curves: {},
                        xAxis: [],
                        variablesAndCategories: {
                            byCategory: {},
                            byVariable: {},
                        },
                    },
                    contributionsByCategory: {},
                    totals: {},
                    rows: {},
                    totalContribution: 0,
                    mediaContribution: 0,
                } as GetPreviousDataReturn),
            monthlyMediaInvestment,
            initialAllocation,
            diminishingPoints,
            getMediaBudgetTableData,
            contributionTotalWithNewMedia,
            ...structuredBudgetData,
        },
        isFetching: prevDataIsFetching || isFetching || isContributionFetching,
        isLoading: prevDataIsLoading || isLoading || isContributionLoading,
    };
};

export type UseCampaignOptimizationQueryResponse = ReturnType<
    typeof useCampaignOptimizationData
>;
