import type { DBTable } from '@analytical-alley/duckdb-react/types';
import { z } from 'zod';

export const modelVsActualSchema = z.object({
    'Period name': z.string(),
    residual: z.number(),
    kpi: z.number(),
});

export const modelVsActualColumnsMap = {
    'Period name': 'period',
    residual: 'residual',
    kpi: 'kpi',
} as const;

type SchemaType = z.infer<typeof modelVsActualSchema>;

export type ModelOrActual = 'model' | 'actual';

export type TModelVsActualTableRow = DBTable<
    SchemaType,
    typeof modelVsActualColumnsMap
>;
