import { HighlightLegend } from '@/components/ECharts';
import { ToggleSelect } from '@/components/ToggleSelect';
import { WithLoader } from '@/components/WithLoader';
import { CompetitorsMediaSeasonalityGraph } from '@/dashboards/CompetitorsMediaInvestment/components/CompetitorsMediaSeasonalityGraph';
import { useCompetitorsMediaInvestmentContext } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentContext';
import { useCompetitorsMediaInvestmentQuery } from '@/dashboards/CompetitorsMediaInvestment/hooks/useCompetitorsMediaInvestmentQuery';
import { Typography } from '@analytical-alley/ui';
import EChartsReact from 'echarts-for-react';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

type SeriesData = {
    name: string;
    color: string;
};

type ChartOptions = Array<SeriesData>;

export const CompetitorsMediaSeasonalitySection = () => {
    const filterContainerRef = useRef<HTMLDivElement>(null);

    const {
        queryResult: { isLoading, isFetching },
    } = useCompetitorsMediaInvestmentQuery();
    const {
        selectedCategoryFilter,
        setSelectedCategory,
        selectedCompetitorFilter,
        setSelectedCompetitor,
    } = useCompetitorsMediaInvestmentContext();

    const chartRef = useRef<EChartsReact>(null);
    const [selected, setSelected] = React.useState<string>('');
    const [seriesData, setSeriesData] = React.useState<SeriesData[]>([]);

    const onSelected = (value: string) => {
        setSelected((prev: string) => {
            if (prev || prev === value) {
                return '';
            }
            return value;
        });
    };

    const getChartOptions = (chartOptions: ChartOptions) => {
        setSeriesData(chartOptions);
    };

    return (
        <div className="glass-tile p-6">
            <WithLoader isLoading={isLoading} isFetching={isFetching}>
                <div className="flex justify-between mb-4">
                    <Typography variant="titleS">
                        Competitor’s Media Seasonality
                    </Typography>
                    <div className="flex gap-3" ref={filterContainerRef} />
                    {filterContainerRef.current
                        ? createPortal(
                              <>
                                  <ToggleSelect
                                      items={selectedCategoryFilter.map(
                                          (item) => ({
                                              label: item.name,
                                              value: item.name,
                                              checked: item.selected,
                                          }),
                                      )}
                                      placement={'bottom-end'}
                                      compact
                                      label={'Media Category'}
                                      showAllCheckbox={true}
                                      onChange={(name, checked) => {
                                          setSelectedCategory(name, checked);
                                      }}
                                  />
                                  <ToggleSelect
                                      items={selectedCompetitorFilter?.map(
                                          (item) => ({
                                              label: item.name,
                                              value: item.name,
                                              checked: item.selected,
                                          }),
                                      )}
                                      placement={'bottom-end'}
                                      compact
                                      label={'Competitors'}
                                      showAllCheckbox={true}
                                      onChange={(name, checked) => {
                                          setSelectedCompetitor(name, checked);
                                      }}
                                  />
                              </>,
                              filterContainerRef.current,
                          )
                        : null}
                </div>
                <CompetitorsMediaSeasonalityGraph
                    chartRef={chartRef}
                    selected={selected}
                    onSelected={onSelected}
                    getChartOptions={getChartOptions}
                />
                <div className="relative mt-5 pl-14 pr-4 z-20">
                    <HighlightLegend
                        highlighted={selected}
                        legendItems={seriesData?.map((s) => ({
                            name: String(s.name),
                            highlighted: true,
                            color: s.color,
                        }))}
                        onClick={(name) => {
                            if (selected === name) {
                                setSelected('');
                                return;
                            }
                            setSelected(name);
                        }}
                    />
                </div>
            </WithLoader>
        </div>
    );
};
