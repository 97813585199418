import { dashboardApi } from '@/api';
import { queryOptions } from '@tanstack/react-query';

interface DuckDBSignedUrlQueryArgs {
    projectId: string;
    dashboardId: string | undefined;
    fileType?: 'input' | 'output';
}

export interface DuckDBSignedUrlResponse {
    signedUrl: string;
}

const getDuckDBSignedUrl = async ({
    projectId,
    fileType = 'output',
    dashboardId,
}: DuckDBSignedUrlQueryArgs) => {
    return dashboardApi<DuckDBSignedUrlResponse>({
        method: 'GET',
        url: `/front-office/project/${projectId}/data/${fileType}`,
        params: {
            dashboardId,
        },
    });
};

export const duckDBSignedUrlQueryOptions = (args: DuckDBSignedUrlQueryArgs) =>
    queryOptions({
        queryKey: ['duckdb-signed-url', args],
        queryFn: () => getDuckDBSignedUrl(args),
        staleTime: Infinity,
    });
