import { ApiErrorDetail } from '@/api/types';
import { Button, Card } from '@analytical-alley/ui';
import { useEffect } from 'react';

interface ErrorScreenProps {
    error?: Partial<ApiErrorDetail> & { customMessage?: string };
    reset?: () => void;
    children?: React.ReactNode;
}

export const ErrorScreen = (props: ErrorScreenProps) => {
    useEffect(() => {
        if (import.meta.env.DEV) {
            // Handle error logging here
            console.error(props.error);
        }
    }, [props.error]);

    const errorType = props.error?.error || '';
    const message = props.error?.message;
    const statusCode = props.error?.statusCode;

    return (
        <Card className="m-14">
            <div className="grid-cols-2 gap-8 content-center py-8 px-4 mx-auto max-w-screen-xl md:grid lg:py-16 lg:px-6">
                <div className="self-center">
                    <h1 className="mb-4 text-2xl font-bold text-primary-600 dark:text-primary-500">
                        {statusCode ? `${statusCode} ` : ''}
                        {errorType}
                    </h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 lg:mb-10 md:text-4xl dark:text-white">
                        {props.error?.customMessage ||
                            'Whoops! Something went wrong!'}
                    </p>
                    {message && (
                        <p className="mb-4 text-gray-500 dark:text-gray-400">
                            {message}
                        </p>
                    )}
                    {props.children || (
                        <Button
                            size="sm"
                            color="blue"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Reload
                        </Button>
                    )}
                </div>
            </div>
        </Card>
    );
};
