import {
    backofficeUserManagerSettings,
    frontofficeUserManagerSettings,
    updateUserManager,
} from '@/config/auth';
import { previewSearchParamName } from '@/config/commonSearch';
import { AuthProvider as UIAuthProvider } from '@analytical-alley/ui/auth';
import { UserManager } from 'oidc-client-ts';
import { ReactNode, useEffect, useState } from 'react';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [userManager, setUserManager] = useState<UserManager>();

    useEffect(() => {
        const hasDashboardIdSearchParam = new URLSearchParams(
            window.location.search,
        ).has(previewSearchParamName);

        if (hasDashboardIdSearchParam) {
            setUserManager(updateUserManager(backofficeUserManagerSettings));
        } else {
            setUserManager(updateUserManager(frontofficeUserManagerSettings));
        }
    }, []);

    if (!userManager) {
        return null;
    }

    return (
        <UIAuthProvider userManager={userManager}>{children}</UIAuthProvider>
    );
};
