import { CampaignOptimizationContext } from '@/dashboards/CampaignOptimization/context/CampaignOptimizationContext';
import { useContext } from 'use-context-selector';

export const useCampaignOptimizationContext = () => {
    const context = useContext(CampaignOptimizationContext);

    if (!context) {
        throw new Error(
            'useCampaignOptimizationContext must be used within a CampaignOptimizationContextProvider',
        );
    }

    return context;
};
