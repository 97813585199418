import { PercentSimpleItem } from '@/components/PercentISimpletem';
import { KEY_EVENT_KEYS } from '@/config/constants';
import {
    DetectOverflowCallback,
    useDetectOverflow,
} from '@/hooks/useDetectOverflow';
import { cn } from '@/utils';
import { Typography } from '@analytical-alley/ui';
import React, { ReactNode, useState } from 'react';

import { leftColumnClassName, rightColumnClassName } from './consts';

export const Row = ({
    title,
    value,
    percent,
    color,
    activeTitle,
    onSelect,
}: {
    onSelect: (title: string) => void;
    activeTitle: string | undefined;
    title: string;
    percent: number;
    value: ReactNode;
    color: string;
}) => {
    const isNegative = percent <= 0;
    const [overflow, setOverflow] = useState(false);
    const onTextOverflow: DetectOverflowCallback = (
        isOverflowing: boolean,
        { contentWidth, targetContentElement },
    ) => {
        if (isOverflowing) {
            targetContentElement.style.left = isNegative
                ? `calc(${percent}% + ${contentWidth}px)`
                : `calc(${percent}% - ${contentWidth}px)`;
        } else {
            targetContentElement.style.left = `${percent}%`;
        }
        setOverflow(isOverflowing);
    };

    const { containerRef, mainContentRef, targetContentRef } =
        useDetectOverflow(onTextOverflow, { throttleWait: 0 }, [percent]);

    const left = percent >= 0 ? '0%' : 'auto';
    const right = percent < 0 ? '100%' : 'auto';

    const barWidth = Math.abs(percent);

    const handleKeyPress = (event: React.KeyboardEvent, title: string) => {
        if (
            event.key === KEY_EVENT_KEYS.ENTER ||
            event.key === KEY_EVENT_KEYS.SPACE
        ) {
            onSelect(title);
        }
    };

    return (
        <div
            className={cn(
                'flex flex-row h-12 align-middle w-full bg-gray-100 dark:bg-white dark:bg-opacity-10 rounded-lg',
                {
                    'bg-opacity-20 dark:bg-opacity-30': activeTitle === title,
                },
            )}
        >
            <span
                ref={isNegative ? containerRef : undefined}
                className={cn(
                    'flex self-center h-full pl-4 font-mono text-dark dark:text-white text-base leading-normal',
                    leftColumnClassName,
                )}
            >
                <span
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        onSelect(title);
                    }}
                    onKeyDown={(event) => handleKeyPress(event, title)}
                    className="h-full cursor-pointer flex"
                >
                    <Typography
                        ref={isNegative ? mainContentRef : undefined}
                        variant="bodyM"
                        className="inline-block z-20 self-center text-wrap"
                    >
                        {title}
                    </Typography>
                </span>
            </span>
            <div
                ref={!isNegative ? containerRef : undefined}
                className={cn('relative', rightColumnClassName)}
            >
                <span
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        onSelect(title);
                    }}
                    onKeyDown={(event) => handleKeyPress(event, title)}
                    ref={!isNegative ? mainContentRef : undefined}
                    className="absolute h-full cursor-pointer"
                    style={{
                        opacity:
                            !activeTitle || activeTitle === title ? 0.9 : 0.1,
                        backgroundColor: color,
                        width: `${Math.max(barWidth, 0.5)}%`,
                        left,
                        right,
                    }}
                />
                <span
                    role="button"
                    tabIndex={0}
                    ref={targetContentRef}
                    onClick={() => {
                        onSelect(title);
                    }}
                    onKeyDown={(event) => handleKeyPress(event, title)}
                    className="flex z-20 h-full absolute text-white text-nowrap px-4 cursor-pointer"
                >
                    <div className="flex gap-3 font-mono z-20 relative">
                        <Typography
                            variant="bodyS"
                            className={cn(
                                'self-center text-dark dark:text-white',
                                {
                                    'text-white': overflow,
                                },
                            )}
                        >
                            {value}
                        </Typography>
                        <PercentSimpleItem percent={percent} />
                    </div>
                </span>
            </div>
        </div>
    );
};
