import { Tooltip } from '@analytical-alley/ui';
import React, { ReactNode } from 'react';
import { useBarChartContext } from './context';

export interface BarGroupProps {
    active?: boolean;
    children: ReactNode;
    title: ReactNode;
    tooltipContent?: ReactNode;
    onClick?: () => void;
    activeDotColor?: string;
}

export const ShadowBarGroup = ({ children, tooltipContent }: BarGroupProps) => {
    const { showTickMarks } = useBarChartContext();

    return (
        <div className="relative group flex flex-col flex-1 justify-between min-w-10 max-w-16 mx-1">
            {tooltipContent ? (
                <>
                    <Tooltip placement={'right-end'}>
                        <Tooltip.Trigger asChild>
                            <div>{children}</div>
                        </Tooltip.Trigger>
                        <Tooltip.Content>{tooltipContent}</Tooltip.Content>
                    </Tooltip>
                </>
            ) : (
                children
            )}
            {showTickMarks && (
                <span className="absolute -bottom-[2px] w-px h-[8px] bg-dark dark:bg-[#BED4FF7F] self-center translate-y-full"></span>
            )}
        </div>
    );
};

ShadowBarGroup.displayName = 'ShadowBarGroup';
