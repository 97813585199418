import { DB_NAME } from '@/duckdb';
import { createDBConnection } from '@analytical-alley/duckdb-react';
import prepareScriptFile from './prepare.sql?url';

export type Query = Awaited<ReturnType<typeof createDBConnection>>['query'];

async function fetchAndExecuteScripts(query: Query, files: string[]) {
    const scripts = await Promise.all(
        files.map((url) => fetch(url).then((res) => res.text())),
    );
    return await Promise.all(scripts.map((script) => query(script)));
}

export async function runScripts() {
    const { close, query } = await createDBConnection();

    // Need to do following to be able to edit DB, other solutions to open file in edit mode didn't work so far,
    // temporary views are created in different temp schema, and switching to this all the time
    // is a hassle - When time look into it and refactor if needed!

    // Copy the analytical-alley database to memory
    await query(`COPY FROM DATABASE "${DB_NAME}" TO memory;`);
    // Switch to the memory database
    await query('USE memory;');
    // Detach the analytical-alley database
    await query(`DETACH "${DB_NAME}";`);

    await fetchAndExecuteScripts(query, [prepareScriptFile]);

    await close();
}
