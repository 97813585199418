function a(e, {
  enabled: c = !0,
  onTime: s,
  message: u
}) {
  return c ? (console.log(u), e.constructor.name === "AsyncFunction" ? async (...n) => {
    const r = performance.now(), t = await e(...n), o = performance.now();
    return s({ timeMs: o - r }), t;
  } : (...n) => {
    const r = performance.now(), t = e(...n), o = performance.now();
    return s({ timeMs: o - r }), t;
  }) : e;
}
export {
  a as measureExecutionTime
};
