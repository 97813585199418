import { previewDashboardSearchSchema } from '@/config/commonSearch';
import { z } from 'zod';

export const titleDashboardSearchSchema = previewDashboardSearchSchema.extend({
    period: z
        .union([
            z.literal('daily'),
            z.literal('weekly'),
            z.literal('monthly'),
            z.literal('quarterly'),
            z.literal('yearly'),
        ])
        .catch('monthly'),
    dashboardId: z.string().optional(),
});
