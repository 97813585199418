var E = Object.defineProperty;
var s = (r, n, i) => n in r ? E(r, n, { enumerable: !0, configurable: !0, writable: !0, value: i }) : r[n] = i;
var u = (r, n, i) => (s(r, typeof n != "symbol" ? n + "" : n, i), i);
var e = /* @__PURE__ */ ((r) => (r[r.NONE = 0] = "NONE", r[r.RUNNING = 1] = "RUNNING", r[r.FAILED = 2] = "FAILED", r[r.COMPLETED = 3] = "COMPLETED", r))(e || {});
class t {
  constructor(n, i, h, N) {
    u(this, "status");
    u(this, "value");
    u(this, "error");
    u(this, "progress");
    this.status = n || 0, this.value = i || null, this.error = h || null, this.progress = N || null;
  }
  resolving() {
    return this.status != 0;
  }
  completeWith(n, i = null) {
    return new t(
      3,
      n,
      this.error,
      i
    );
  }
  failWith(n, i = null) {
    return new t(
      2,
      this.value,
      n,
      i
    );
  }
  updateRunning(n = null) {
    return new t(
      1,
      this.value,
      this.error,
      n
    );
  }
}
export {
  t as Resolvable,
  e as ResolvableStatus
};
