import type { DBTable } from '@analytical-alley/duckdb-react/types';
import { z } from 'zod';

export const statisticsIndicatorsTableSchema = z.object({
    R2: z.number(),
    adjustedR2: z.number(),
    durbinWatson: z.number(),
});

export const statisticsIndicatorsTableColumnsMap = {
    R2: 'R2',
    adjustedR2: 'adjustedR2',
    durbinWatson: 'durbinWatson',
} as const;

type SchemaType = z.infer<typeof statisticsIndicatorsTableSchema>;

export type TStatisticsIndicatorsTable = DBTable<
    SchemaType,
    typeof statisticsIndicatorsTableColumnsMap
>;
