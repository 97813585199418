import React, { createContext, ReactNode, useContext } from 'react';

export type YAxis = {
    '25'?: ReactNode;
    '50'?: ReactNode;
    '75'?: ReactNode;
    '100'?: ReactNode;
};

export type ShadowBarChartContext = {
    maxValue: number;
    height: number;
    showTickMarks?: boolean;
    yAxisTitle?: ReactNode;
    shadowBarRef?: any;
} & (
    | { yAxis?: YAxis; axisLabelFormatter?: never }
    | { yAxis: 'auto'; axisLabelFormatter?: (value: number) => ReactNode }
);

export type ShadowBarOptions = {
    yAxisLinesStyle: 'dotted' | 'solid' | 'none';
    labelVariant: 'rotated' | 'default';
};

export type ShadowBarChartContextExtended = ShadowBarChartContext & {
    showYAxis: boolean;
    containerRef?: React.RefObject<HTMLDivElement>;
    options: ShadowBarOptions;
};

export const DEFAULT_OPTIONS: ShadowBarOptions = {
    yAxisLinesStyle: 'solid',
    labelVariant: 'default',
};

export const BarChartContext = createContext<ShadowBarChartContextExtended>({
    height: 300,
    maxValue: 100,
    showTickMarks: false,
    yAxis: undefined,
    showYAxis: false,
    containerRef: undefined,
    options: DEFAULT_OPTIONS,
});

export const useBarChartContext = () => {
    const context = useContext(BarChartContext);
    if (!context) {
        throw new Error('useBarChartContext must be used within a BarChart');
    }
    return context;
};
