import { jsx as r } from "react/jsx-runtime";
import { Checkbox as m } from "flowbite-react";
import { forwardRef as t } from "react";
import { checkboxTheme as c } from "./theme.js";
const h = t(
  (o, e) => /* @__PURE__ */ r(m, { ref: e, theme: c, ...o })
);
h.displayName = "Checkbox";
export {
  h as Checkbox
};
